import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "./FunnelDrilldownBody.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  countOrACVFormatter,
  _isNaN,
  getCSVFileNameString,
  agGridDateFormatter,
} from "../../util/customFunctions";
import GetApp from "@material-ui/icons/GetApp";
import { numberSort } from "../../util/customSortings";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const FunnelDrilldownBody = (props) => {
  const classes = useStyle();
  const [body, setBody] = useState([]);

  useEffect(() => {
    if (props.body.opportunities !== "" && props.body !== "") {
      let formattedData = props.body.opportunities.map((item) => ({
        ...item,
        Projected_Close_Date: item.Projected_Close_Date.replace("T", " ").slice(
          0,
          -1
        ),
        closed_date:
          item.closed_date !== null
            ? item.closed_date.replace("T", " ").slice(0, -1)
            : null,
      }));
      setBody(formattedData);
    }
    return () => {
      // props.clearDrilldownData()
    };
  }, [props.body]);
  const containerHeight = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  // console.log(props.body)

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    params.api.resetRowHeights();
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };
  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };
  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        if (
          (cellParams && rowIndex === "OppName") ||
          rowIndex === "Account_Name" ||
          rowIndex === "Owner_Name"
        ) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if ((cellParams && rowIndex === "ACV") || rowIndex === "acv") {
          return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };

    gridApi.exportDataAsCsv(params);
  };
  // console.log(props.body.count_acv)
  return (
    <Grid container className={classes.root}>
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:
                <span data-test="count">
                  {" "}
                  {d3.format(",")(props.body.opportunities.length)}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: ${" "}
                <span data-test="acv">
                  {d3.format(",")(
                    Math.round(
                      props.body.opportunities
                        .map((it) => it.ACV)
                        .filter((item) => item !== undefined)
                        .reduce((a, b) => a + b, 0)
                    )
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
              style={{ paddingBottom: "1rem" }}
            >
              {/* <Button variant="contained"
                                color="primary" onClick={exportCSV}><GetApp /></Button> */}
              <button style={{ border: "none" }} onClick={exportCSV}>
                <HoverableIcon />
              </button>
            </Grid>
          </Grid>

          <div
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={
                props.company !== "C0001"
                  ? [
                      {
                        headerName: "#",
                        field: "OppNo",
                        type: "rightAligned",
                        sortable: false,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "3px",
                        },
                        headerClass: classes.tableHeader,
                        maxWidth: 45,
                      },
                      {
                        headerName: "Opportunity Name",
                        autoHeight: true,
                        field: "OppName",
                        flex: 2.3,
                        suppressSizeToFit: true,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Account ",
                        autoHeight: true,
                        field: "Account_Name",
                        flex: 1.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      // props.company === "C0003" ? { headerName: "Vertical", field: "Solution_Cat", flex: 0.7, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader } : { headerName: "Solution Category", field: "Solution_Cat", flex: 0.7, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                      {
                        headerName: "Customer Type",
                        field: "Cust_Type",
                        flex: 0.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "ACV",
                        field: "ACV",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "5px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                        valueFormatter: (x) => {
                          return `$${d3.format(",")(Math.round(x.value))}`;
                        },
                      },

                      {
                        headerName: "Created Date",
                        field: "Created_Date",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Projected Close Date",
                        field: "Projected_Close_Date",
                        flex: 0.7,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Age",
                        field: "Age",
                        flex: 0.5,
                        type: "rightAligned",
                        sortable: true,
                        comparator: numberSort,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "20px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                      },
                      {
                        headerName: "Current Stage",
                        field: "Stage",
                        flex: 0.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Owner",
                        field: "Owner_Name",
                        flex: 0.9,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Team",
                        field: "Team",
                        flex: 1,
                        resizable: false,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                    ]
                  : [
                      {
                        headerName: "#",
                        field: "OppNo",
                        type: "rightAligned",
                        sortable: false,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "3px",
                        },
                        headerClass: classes.tableHeader,
                        maxWidth: 45,
                      },
                      {
                        headerName: "Opportunity Name",
                        autoHeight: true,
                        field: "OppName",
                        flex: 2.3,
                        suppressSizeToFit: true,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Account ",
                        autoHeight: true,
                        field: "Account_Name",
                        flex: 1.6,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      // props.company === "C0003" ? { headerName: "Vertical", field: "Solution_Cat", flex: 0.7, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader } : { headerName: "Solution Category", field: "Solution_Cat", flex: 0.7, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                      {
                        headerName: "Customer Type",
                        field: "Cust_Type",
                        flex: 0.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "ACV",
                        field: "ACV",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "5px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                        valueFormatter: (x) => {
                          return `$${d3.format(",")(Math.round(x.value))}`;
                        },
                      },
                      {
                        headerName: "Services",
                        field: "Professional_Services_Amt_c",
                        flex: 0.8,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "5px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                        valueFormatter: (x) => {
                          return `$${d3.format(",")(Math.round(x.value))}`;
                        },
                      },

                      {
                        headerName: "Created Date",
                        field: "Created_Date",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Projected Close Date",
                        field: "Projected_Close_Date",
                        flex: 0.7,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Age",
                        field: "Age",
                        flex: 0.5,
                        type: "rightAligned",
                        sortable: true,
                        comparator: numberSort,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "20px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                      },
                      {
                        headerName: "Current Stage",
                        field: "Stage",
                        flex: 0.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Owner",
                        field: "Owner_Name",
                        flex: 0.9,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Team",
                        field: "Team",
                        flex: 1,
                        resizable: false,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                    ]
              }
              sizeColumnsToFit={true}
              domLayout={body.length <= 10 ? domLayout : null}
              headerHeight="55"
              rowData={body.map((item) => ({
                ...item,
                ACV: _isNaN(item.ACV) ? 0 : item.ACV,
              }))}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={body.length <= 10 ? onColumnResized : null}
              enableCellTextSelection={true}
              //autoHeight={true}
              colResizeDefault={"shift"}
              //rowBuffer={30}
              debounceVerticalScrollbar={true}
              onGridReady={gridReady}

              // cellClass='cell-wrap-text'
            ></AgGridReact>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default FunnelDrilldownBody;
