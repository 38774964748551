import * as d3 from 'd3'
import { max } from 'moment';

export function computeDomainLimits(minVal, maxVal) {
    let upper_bound, lower_bound;
    // The bounds() function below is copied from the nice(start,stop,count) at https://github.com/d3/d3-array/blob/master/src/nice.js
    // For some reason d3.nice is not recognized as a function. Renaming to avoid any naming collisions.
    // All the console.log calls and iteration loop counts have to be removed before we actually deploy this implementation.
    function get_bounds(start, stop, count) {
        let prestep;

        var iter = 1;

        while (true) {
            const step = d3.tickIncrement(start, stop, count);
            //console.log(new Date().getTime());






            if (step === prestep || step === 0 || !isFinite(step)) {

                return [start, stop];
            }
            else if (step > 0) {
                start = Math.floor(start / step) * step;
                stop = Math.ceil(stop / step) * step;



            }
            else if (step < 0) {
                start = Math.ceil(start * step) / step;
                stop = Math.floor(stop * step) / step;



            }

            prestep = step;

            iter = iter + 1;
        }
    }

    var smallest_val = minVal
    var largest_val = maxVal
    var count = 5

    let bounds = get_bounds(smallest_val, largest_val, count)
    lower_bound = bounds[0]
    upper_bound = bounds[1]








    let incr = d3.tickIncrement(lower_bound, upper_bound, count)
    // Account for the possibility that incr is negative. 
    // If it is, get the inverse of the absolute value
    if (incr < 0) {
        let incr_abs = Math.abs(incr)
        incr = 1 / incr_abs
    }





    return { upper_bound, lower_bound }
} //end of computeBounds()


export function computeDomainLimitsDate(minVal, maxVal) {
    let upper_bound_date, lower_bound_date;
    // The bounds() function below is copied from the nice(start,stop,count) at https://github.com/d3/d3-array/blob/master/src/nice.js
    // For some reason d3.nice is not recognized as a function. Renaming to avoid any naming collisions.
    // All the console.log calls and iteration loop counts have to be removed before we actually deploy this implementation.
    function get_bounds(start, stop, count) {
        let prestep;

        var iter = 1;

        while (true) {
            const step = d3.tickIncrement(start, stop, count);
            //console.log(new Date().getTime());






            if (step === prestep || step === 0 || !isFinite(step)) {

                return [start, stop];
            }
            else if (step > 0) {
                start = Math.floor(start / step) * step;
                stop = Math.ceil(stop / step) * step;



            }
            else if (step < 0) {
                start = Math.ceil(start * step) / step;
                stop = Math.floor(stop * step) / step;



            }

            prestep = step;

            iter = iter + 1;
        }
    }

    var smallest_val = minVal
    var largest_val = maxVal
    var count = 6

    let bounds = get_bounds(smallest_val, largest_val, count)
    lower_bound_date = bounds[0]
    upper_bound_date = bounds[1]








    let incr = d3.tickIncrement(lower_bound_date, upper_bound_date, count)
    // Account for the possibility that incr is negative. 
    // If it is, get the inverse of the absolute value
    if (incr < 0) {
        let incr_abs = Math.abs(incr)
        incr = 1 / incr_abs
    }





    return { upper_bound_date, lower_bound_date }
} //end of computeBounds()

