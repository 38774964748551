import { Grid, makeStyles, Typography } from "@material-ui/core";
import { LegendPosition } from "ag-grid-community";
import React from "react";
const useStyle = makeStyles((theme) => ({
  square: {
    height: 20,
    width: 20,
  },
}));
export default function Legend(props) {
  const { legends } = props;
  const classes = useStyle();
  return (
    <Grid
      container
      justify={
        ["AsOfDate", "segment", "pcd", "BS"].includes(props.type) ? "center" : "left"
      }
    >
      {legends.map((item, i) =>
        props.type === "AsOfDate" ? (
          <Grid
            key={i}
            item
            xs={12 / legends.length}
            container
            justify={props.just !== undefined ? props.just : "center"}
            alignItems="center"
          >
            <Grid
              item
              style={{
                border: item.border,
                height: props.square,
                width: props.square,
                background: item.color,
                opacity:
                  item.color === "#BF9000"
                    ? 0.3
                    : props.legendOpacity === undefined
                    ? 1
                    : props.legendOpacity,
              }}
            ></Grid>
            <Grid item>
              <Typography align="center" style={{ margin: "1rem" }}>
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            key={i}
            item
            xs={
              legends.length > 6
                ? 4
                : (props.type === "qfq" || props.type === 'BS')
                ? 2
                : props.chartName ===
                  "Source of Qualified pipe that went to Won"
                ? 6
                : 3
            }
            container
            justify="left"
            alignItems="center"
            style={{ paddingLeft: "4rem" }}
          >
            <Grid
              item
              style={{
                height: props.square,
                width: props.square,
                background: item.color,
                opacity:
                  item.color === "#BF9000"
                    ? 0.3
                    : props.legendOpacity === undefined
                    ? 1
                    : props.legendOpacity,
              }}
            ></Grid>
            <Grid item>
              <Typography align="center" style={{ margin: "1rem" }}>
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
}
