import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import React from 'react'
import { customSortedSegments } from '../util/customSortings';

export default function Region(props) {
    const sortedSegemnt = customSortedSegments
    const missingSegemnt = props.funnelCompareFilters.filter(y => y.type === "Acct_Segment" && y["Acct_Segment"] !== '').map(item => item.Acct_Segment).filter(function (obj) { return sortedSegemnt.indexOf(obj) == -1; })
    const finalSegments = [...sortedSegemnt, ...missingSegemnt]
    return (

        <Grid item xs={12} >
            <FormControl fullWidth >
                <InputLabel style={{ color: !props.selectedAccount_Segment.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Segment:</InputLabel>
                <Select
                    labelId="select-vRegion_Rollup-label"
                    id="select-cRegion_Rollup"
                    value={props.selectedAccount_Segment}
                    onChange={props.handleChange}
                    label="Acct_Segment"
                    name='Acct_Segment'
                    data-test='Acct_Segment'
                    multiple
                >
                    <MenuItem value={'All'} >All</MenuItem>
                    {finalSegments.map(segment => <MenuItem key={segment} value={segment}>{segment}</MenuItem>)}

                </Select>

            </FormControl>
        </Grid>

    )
}
