import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Button,
  makeStyles,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaintainenenceTable from "./MaintainenenceTable";
import * as d3 from "d3";
import {
  clearManagementSlice,
  selectCompanyQuotaData,
  selectQuotaData,
  selectQuotaFiscalQuarters,
  selectRampStatus,
  selectTeamQuotaData,
  selectTeams,
  selectUpdatedRecords,
  selectUpdateFailed,
} from "./redux/management.slice";
import {
  fetchCompanyQuotaData,
  fetchQuotaData,
  fetchQuotaFiscalQuartersFilters,
  fetchTeamQuotaData,
  updateCompanyQuotaData,
  updateQuotaData,
  updateTeamQuotaData,
} from "./redux/management.thunk";
import { repsColumnDefs } from "./repsColumnDefs";
import "./MaintainenenceTable.scss";
import TooltipAGGrid from "./TooltipAgGrid";
import { useSnackbar } from "notistack";
import LoadingData from "./LoadingData";
import NumericEditor from "./NumericEditor";
import { _isNaN } from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";
const useStyle = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "0.85rem",
    background: "#4472c4",
    paddingRight: "10px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    width: "7rem",
    fontWeight: 600,
    color: "white",
  },
  tableCell: {
    fontSize: "1rem",
    whiteSpace: "normal",
    // paddingRight: "10px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontSize: "0.85rem",
    fontWeight: 400,
    width: "7rem",
  },
  tablerow: {
    background: "red",
  },
}));
const QuotaMaintainenceV3 = () => {
  const classes = useStyle();
  const [selected, setSelected] = useState([]);
  const [touched, setTouched] = useState(false);
  const [tab, setTab] = useState("rep");
  const [isDryRun, setIsDryRun] = useState(false);
  const dispatch = useDispatch();
  const quotaFiscalQuarters = useSelector(selectQuotaFiscalQuarters);
  const quotaData = useSelector(selectQuotaData);
  const teamQuota = useSelector(selectTeamQuotaData);
  const companyQuota = useSelector(selectCompanyQuotaData);
  const teams = useSelector(selectTeams);
  const rampStatus = useSelector(selectRampStatus);
  const updateFailed = useSelector(selectUpdateFailed);

  const udpatedRecords = useSelector(selectUpdatedRecords);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  console.log(udpatedRecords, quotaData);
  useEffect(() => {
    dispatch(fetchQuotaFiscalQuartersFilters());
    return () => {
      dispatch(clearManagementSlice());
    };
  }, []);
  useEffect(() => {
    if (quotaFiscalQuarters !== null) {
      enqueueSnackbar("Filters Loaded", { variant: "success" });
    }
    if (quotaFiscalQuarters === null) {
      enqueueSnackbar("Filters Loading", { variant: "info" });
    }
  }, [quotaFiscalQuarters]);
  useEffect(() => {
    if (quotaFiscalQuarters !== null) {
      enqueueSnackbar("Filters Loaded");
    }
    if (quotaFiscalQuarters === null) {
      enqueueSnackbar("Filters Loading");
    }
  }, [quotaFiscalQuarters]);
  useEffect(() => {
    if (updateFailed === true) {
      enqueueSnackbar("Update Failed", { variant: "success" });
      handleGo();
    }
  }, [updateFailed]);

  useEffect(() => {
    if (quotaFiscalQuarters !== null) {
      setSelected([quotaFiscalQuarters.currentFiscalQuarter]);
      dispatch(
        fetchQuotaData({
          filters: [
            {
              name: "Fiscal_Quarter",
              value: [quotaFiscalQuarters.currentFiscalQuarter],
            },
          ],
          isDryRun,
        })
      );
      dispatch(
        fetchTeamQuotaData({
          filters: [
            {
              name: "Fiscal_Quarter",
              value: [quotaFiscalQuarters.currentFiscalQuarter],
            },
          ],
          isDryRun,
        })
      );
      dispatch(
        fetchCompanyQuotaData({
          filters: [
            {
              name: "Fiscal_Quarter",
              value: [quotaFiscalQuarters.currentFiscalQuarter],
            },
          ],
          isDryRun,
        })
      );
    }
  }, [quotaFiscalQuarters]);
  const handleChange = (event) => {
    const e = event;
    const value =
      e.target.value.length === 0
        ? ["All"]
        : selected.length === 1 && selected.includes("All")
        ? e.target.value.filter((v) => v !== "All").length > 0
          ? e.target.value.filter((v) => v !== "All")
          : ["All"]
        : e.target.value.includes("All")
        ? ["All"]
        : e.target.value;

    setSelected(value);
    console.log("values", value);
  };
  const sendApiRequest = () => {
    let dispatchAction;
    const dispatchActions = [
      fetchQuotaData,
      fetchTeamQuotaData,
      fetchCompanyQuotaData,
    ];

    for (let dispatchAction of dispatchActions) {
      if (selected.includes("All")) {
        dispatch(
          dispatchAction({
            filters: [{ name: "Fiscal_Quarter", value: [] }],
            isDryRun,
          })
        );
      } else {
        dispatch(
          dispatchAction({
            filters: [{ name: "Fiscal_Quarter", value: selected }],
            isDryRun,
          })
        );
      }
    }
  };
  const handleGo = (event) => {
    if (touched) {
      const isConfirmed = window.confirm(
        "You have unsaved changes. Do you want to discard these changes and reload?"
      );
      if (isConfirmed) {
        sendApiRequest();
      }
    } else {
      sendApiRequest();
    }
  };
  const handleChangeTab = (e) => {
    if (touched) {
      const isConfirmed = window.confirm(
        "You have unsaved changes. Do you want to discard these changes and switch to another tab?"
      );
      if (isConfirmed) {
        setTab(e.target.value);
      }
    } else {
      setTab(e.target.value);
    }
  };
  const handleSubmit = (udpatedRecords, tab) => {
    console.log(udpatedRecords);
    dispatch(updateQuotaData({ updatedObjects: udpatedRecords, isDryRun }));
  };
  const handleTeamSubmit = (udpatedRecords, tab) => {
    console.log(udpatedRecords);
    dispatch(updateTeamQuotaData({ updatedObjects: udpatedRecords, isDryRun }));
  };
  const handleCompanySubmit = (udpatedRecords, tab) => {
    console.log(udpatedRecords);
    dispatch(
      updateCompanyQuotaData({ updatedObjects: udpatedRecords, isDryRun })
    );
  };
  const handleChangeDryRun = (event) => {
    setIsDryRun(!isDryRun);
  };
  const editBackgroundTeam_Assignment = {
    "edit-bg": (params) => {
      return params.data.Team_Assignment_Updated;
    },
  };
  const editBackgroundQuota = {
    "edit-bg": (params) => {
      return params.data.Quota_Updated;
    },
  };
  const editBackgroundRamp_Status = {
    "edit-bg": (params) => {
      return params.data.Ramp_Status_Updated;
    },
  };
  const editBackgroundNQP_GOAL = {
    "edit-bg": (params) => {
      return params.data.NQP_GOAL_Updated;
    },
  };
  return (
    <Grid container>
      <Grid container item style={{ margin: "1rem 0rem" }}>
        {/* <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Quota Maintenance
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Maintain Rep, Team and Company quota
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Quota Maintenance",
          "Maintain Rep, Team and Company quota"
        )}
      </Grid>
      <Grid item container>
        {quotaFiscalQuarters !== null ? (
          <Grid item container>
            <Grid
              item
              xs={4}
              sm={2}
              style={{ marginRight: "2rem", padding: 5 }}
              component={Paper}
            >
              <FormControl fullWidth>
                <InputLabel id="select-team-label">Fiscal Quarter:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected}
                  label="Fiscal Quarter"
                  onChange={handleChange}
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {quotaFiscalQuarters.fiscalQuarters.map((d) => (
                    <MenuItem value={d[d.type]}>{d[d.type]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Dry Run button */}
            {/* <Grid item xs={12} sm={3} container justifyContent="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={isDryRun}
                    onChange={handleChangeDryRun}
                    inputProps={{ "aria-label": "controlled" }}
                    color="primary"
                  />
                }
                label="Dry Run"
              />
            </Grid> */}
            <Grid
              xs={2}
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{ padding: "0.25rem" }}
            >
              <Button onClick={handleGo} color="primary" variant="contained">
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container item justifyContent="center">
            <Skeleton width={"95%"} height={150} />
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12} md={12} style={{ margin: "1rem 0", marginRight: 0 }}>
          <Paper>
            <ToggleButtonGroup
              color="primary"
              indicatorColor="primary"
              value={tab}
              exclusive
              size="large"
              onChange={handleChangeTab}
            >
              <ToggleButton
                style={{
                  background: tab === "rep" ? "#4472C4" : "",
                  color: tab === "rep" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="rep"
              >
                Rep
              </ToggleButton>
              <ToggleButton
                style={{
                  background: tab === "team" ? "#4472C4" : "",
                  color: tab === "team" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="team"
              >
                Team
              </ToggleButton>
              <ToggleButton
                style={{
                  background: tab === "company" ? "#4472C4" : "",
                  color: tab === "company" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="company"
              >
                Company
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
        </Grid>
      </Grid>
      <Grid item container>
        {quotaData !== null || teamQuota !== null || companyQuota !== null ? (
          <Grid item container>
            {tab === "rep" && (
              <>
                {quotaData !== null ? (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{ margin: "1rem 0rem" }}
                  >
                    <MaintainenenceTable
                      setTouched={setTouched}
                      title={"Reps Quota"}
                      tab={tab}
                      data={[...quotaData].map((it, idx) => ({
                        ...it,
                        id: idx,
                      }))}
                      handleSubmit={handleSubmit}
                      udpatedRecords={udpatedRecords}
                      // domLayout={"autoHeight"}
                      columnDefs={[
                        {
                          field: "FullName",
                          sortable: true,
                          filter: true,
                          cellStyle: { textAlign: "left" },
                          cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          flex: 2.4,
                          // cellRenderer: "deltaIndicator",
                        },
                        {
                          field: "FiscalQuarter",
                          sortable: true,
                          filter: true,
                          type: "rightAligned",
                          headerClass: classes.tableHeader,
                          flex: 1,
                        },
                        {
                          field: "Team_Assignment",
                          headerName: "Team",
                          sortable: true,
                          filter: true,
                          cellStyle: { textAlign: "left" },
                          cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true, // remove this because changing the team has a lot of other changes in the system. so disabling it currently.
                          cellClassRules: editBackgroundTeam_Assignment,
                          cellEditor: "agSelectCellEditor",
                          cellEditorParams: { values: teams },
                          flex: 2.2,
                        },
                        {
                          field: "Quota",
                          sortable: true,
                          filter: true,
                          tooltipField: "Quota",
                          // cellEditor: NumericEditor,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a number");
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a positive number");
                              return true;
                            }
                            params.data.Quota = params.newValue;
                            return true;
                          },
                          cellEditorPopup: true,
                          headerClass: classes.tableHeader,
                          editable: true,
                          type: "rightAligned",
                          flex: 1.3,
                          cellClassRules: editBackgroundQuota,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                        {
                          field: "NQP_GOAL",
                          headerName: "New Qualified Pipeline Goal",
                          sortable: true,
                          filter: true,
                          headerClass: classes.tableHeader,
                          editable: true,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal must be a number"
                              );
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal must be a positive number"
                              );
                              return true;
                            }
                            params.data.NQP_GOAL = params.newValue;
                            return true;
                          },
                          cellClassRules: editBackgroundNQP_GOAL,
                          type: "rightAligned",
                          flex: 1.8,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                        {
                          field: "Ramp_Status",
                          headerName: "Ramp Status",
                          sortable: true,
                          filter: true,
                          cellStyle: { textAlign: "left" },
                          cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true,
                          cellClassRules: editBackgroundRamp_Status,
                          cellEditor: "agSelectCellEditor",
                          flex: 1.3,
                          cellEditorParams: { values: rampStatus },
                          resizable: false,
                        },
                      ]}
                    />
                  </Grid>
                ) : (
                  <LoadingData />
                )}
              </>
            )}
            {tab === "team" && (
              <>
                {teamQuota !== null ? (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{ margin: "1rem 0.25rem" }}
                  >
                    <MaintainenenceTable
                      title={"Team Quota"}
                      setTouched={setTouched}
                      tab={tab}
                      data={teamQuota}
                      handleSubmit={handleTeamSubmit}
                      udpatedRecords={udpatedRecords}
                      // domLayout={"autoHeight"}
                      columnDefs={[
                        {
                          field: "Team",
                          sortable: true,
                          filter: true,
                          cellStyle: { textAlign: "left" },
                          cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          flex: 2,

                          // cellRenderer: "deltaIndicator",
                        },
                        {
                          field: "FiscalQuarter",
                          sortable: true,
                          filter: true,
                          type: "rightAligned",
                          flex: 1.3,
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                        },

                        {
                          field: "Quota",
                          sortable: true,
                          filter: true,
                          type: "rightAligned",
                          flex: 1.3,
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a number");
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a positive number");
                              return true;
                            }
                            params.data.Quota = params.newValue;
                            return true;
                          },
                          cellClassRules: editBackgroundQuota,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                        {
                          field: "NQP_GOAL",
                          headerName: "New Qualified Pipeline Goal",
                          sortable: true,
                          filter: true,
                          type: "rightAligned",
                          flex: 1.3,
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal Value should be in numeric values"
                              );
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal must be a positive number"
                              );
                              return true;
                            }
                            params.data.NQP_GOAL = params.newValue;
                            return true;
                          },
                          cellClassRules: editBackgroundNQP_GOAL,
                          resizable: false,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                      ]}
                    />
                  </Grid>
                ) : (
                  <LoadingData />
                )}
              </>
            )}
            {tab === "company" && (
              <>
                {companyQuota !== null ? (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    style={{ margin: "1rem 0rem" }}
                  >
                    <MaintainenenceTable
                      title={"Company Quota"}
                      setTouched={setTouched}
                      data={companyQuota}
                      tab={tab}
                      handleSubmit={handleCompanySubmit}
                      udpatedRecords={udpatedRecords}
                      domLayout={"autoHeight"}
                      columnDefs={[
                        {
                          field: "FiscalQuarter",
                          sortable: true,
                          filter: true,
                          flex: 1,
                          cellStyle: { textAlign: "right" },
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                        },

                        {
                          field: "Quota",
                          sortable: true,
                          filter: true,
                          flex: 1,
                          type: "rightAligned",
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a number");
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.Quota = params.oldValue;
                              window.alert("Quota must be a positive number");
                              return true;
                            }
                            params.data.Quota = params.newValue;
                            return true;
                          },
                          cellClassRules: editBackgroundQuota,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                        {
                          field: "NQP_GOAL",
                          headerName: "New Qualified Pipeline Goal",
                          sortable: true,
                          filter: true,
                          flex: 1,
                          type: "rightAligned",
                          // cellClass: classes.tableCell,
                          headerClass: classes.tableHeader,
                          editable: true,
                          valueSetter: (params) => {
                            if (_isNaN(params.newValue)) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal Value should be in numeric values"
                              );
                              return true;
                            }
                            if (parseInt(params.newValue) < 0) {
                              params.data.NQP_GOAL = params.oldValue;
                              window.alert(
                                "New Qualified Pipeline Goal must be a positive number"
                              );
                              return true;
                            }
                            params.data.NQP_GOAL = params.newValue;
                            return true;
                          },
                          cellClassRules: editBackgroundNQP_GOAL,
                          resizable: false,
                          valueFormatter: (x) => {
                            return `$ ${d3.format(",")(Math.round(x.value))}`;
                          },
                        },
                      ]}
                    />
                  </Grid>
                ) : (
                  <LoadingData />
                )}
              </>
            )}
          </Grid>
        ) : (
          <Grid item container justifyContent="center">
            <Skeleton width={"90%"} height={150} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default QuotaMaintainenceV3;
