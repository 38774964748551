import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import {
  getCSVFileNameString,
  csvDateFormatter,
  dateFormatterv2,
  dateFormatterv3,
} from "../../util/customFunctions";
import GetApp from "@material-ui/icons/GetApp";
import "./WinAcvDrillDown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const currencyFormatter = (params) => {
  if (_isNaN(params.value)) return 0;
  return `$ ${d3.format(",")(Math.round(params.value))}`;
};
const dateAndTimeFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(params.value));
};
const dateFormatter = (params) => {
  if ([null, undefined, ""].includes(params.value)) {
    return "";
  } else {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(new Date(params.value));
  }
};

const newOppDisplayValue = {
  new: "New",
  new_resurrected: "Resurrected",
  new_resurrected_won_to_lost: "Resurrected Won to Lost",
  new_resurrected_won_to_won: "Resurrected Won to Won",
  new_resurrected_lost_to_lost: "Resurrected Lost to Lost",
  new_resurrected_lost_to_won: "Resurrected Lost to Won",
};
const newOwpFormatter = (params) => {
  return newOppDisplayValue[params.value];
};

const percentageDFormatter = (params) => {
  if (!["", null, undefined].includes(params.value)) {
    return `${params.value}%`;
  } else {
    return "";
  }
};

const WinAcvDrillDownDrilldownBody = (props) => {
  // console.log(props)
  const classes = useStyle();

  const containerHeight = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    filter: true,
    cellClass: classes.tableCell,
    headerClass: classes.tableHeader,

    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onCellClicked = (e) =>
    props.handleSecondLevelDrilldown
      ? props.handleSecondLevelDrilldown("drilldownOppId", e.data.Opp_ID)
      : "";

  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        // const rowIndex = cellParams.column.colId;
        // if (cellParams && rowIndex === "projected_close_date") {
        //   return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        // } else if (
        //   (cellParams && rowIndex === "OppName") ||
        //   rowIndex === "Account_Name" ||
        //   rowIndex === "Owner_Name"
        // ) {
        //   return `"${cellParams.value}"`; //apply your string formatter
        // } else if ((cellParams && rowIndex === "ACV") || rowIndex === "acv") {
        //   return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        // } else return cellParams.value; // no formatting
        const rowIndex = cellParams.column.colId;
        // if(cellParams && rowIndex === "projected_close_date" ) {
        //     return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        //   } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name" || rowIndex === "Owner_Name") {
        //     return `"${cellParams.value}"`; //apply your string formatter
        //   } else if(cellParams && rowIndex === "ACV" || rowIndex === "acv"){
        //     return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
        //   }
        const dateTypes = props.body.columns
          .filter((f) => f.type === "date")
          .map((f) => f.field);
        const dateTimeTypes = props.body.columns
          .filter((f) => f.type === "datetime")
          .map((f) => f.field);
        const stringTypes = props.body.columns
          .filter((f) => f.type === "string")
          .map((f) => f.field);
        const integerTypes = props.body.columns
          .filter((f) => f.type === "integer")
          .map((f) => f.field);
        const currencyTypes = props.body.columns
          .filter((f) => f.type === "currency")
          .map((f) => f.field);
        // console.log(dateTypes,dateTimeTypes,stringTypes,integerTypes,currencyTypes, 'BODY123')
        if (cellParams && dateTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv2(cellParams.value) : null; //apply your timestamp formatter
        } else if (cellParams && stringTypes.includes(rowIndex)) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && dateTimeTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv3(cellParams.value) : null; //apply your timestamp formatter
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  //   console.log(props.body.columns)

  return (
    <Grid container className={classes.root}>
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:{" "}
                <span data-test="count">
                  {d3.format(",")(props.body.count_acv[0].count)}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {props.chart === "GAAP" ? "GAAP ACV: $" : "ACV: $"}
                <span data-test="acv">
                  {d3.format(",")(
                    Math.round(props.body.count_acv[0].acv).toString()
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                            color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  sortable: false,
                  filter: false,
                  cellClass: classes.tableCell,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    direction: "rtl",
                    "padding-right": "3px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                ...props.body.columns.map((c) => {
                  return {
                    headerName: c.label,
                    field: c.field,
                    filter:
                      c.type === "currency" ? "agNumberColumnFilter" : true,
                    sortingOrder:
                      c.type === "currency" ? ["desc", "asc"] : ["asc", "desc"],
                    valueFormatter:
                      (c.type === "currency" && currencyFormatter) ||
                      (c.type === "datetime" && dateAndTimeFormatter) ||
                      (c.type === "date" && dateFormatter) ||
                      (c.field === "createEventType" && newOwpFormatter) ||
                      (c.type === "percent" && percentageDFormatter),
                    type:
                      c.type === "currency" ||
                      c.type === "percent" ||
                      c.type === "integer" ||
                      c.type === "datetime" ||
                      c.type === "date"
                        ? "rightAligned"
                        : "leftAligned",
                    cellStyle:
                      c.type === "currency" || c.type === "integer"
                        ? { "padding-right": "5px" }
                        : { "padding-left": "5px" },
                    headerClass: classes.tableHeader,
                    filterParams:
                      c.type === "currency"
                        ? { defaultOption: "greaterThanOrEqual" }
                        : {},
                    // minWidth: c.field === 'acvAtStage' || c.field === 'acvOld' || c.field === 'acvNew' ?
                    //     60
                    //     :
                    //     c.field === "OppName" || c.field === "Account_Name" ?
                    //         100
                    //         :
                    //         c.field === 'stage_change_date' ?
                    //             50
                    //             :
                    //             80,

                    flex:
                      c.field === "acvAtStage" ||
                      c.field === "acvOld" ||
                      c.field === "acvNew" ||
                      c.field === "ACV" ||
                      c.field === "Stage" ||
                      c.field === "acv"
                        ? 0.5
                        : c.field === "Acct_Vertical" ||
                          c.field === "Owner_Name" ||
                          c.field === "Region" ||
                          c.field === "Booking_Type_NL_CS_US" ||
                          c.field === "Acct_Segment" ||
                          c.field === "projected_close_date" ||
                          c.field === "Acct_Size_Segment"
                        ? 0.7
                        : c.field === "closed_date" ||
                          c.field === "Created_Date"
                        ? 0.6
                        : c.field === "Team" ||
                          c.field === "Cust_Type" ||
                          c.field === "Acct_Industry"
                        ? 1
                        : c.field === "OppName"
                        ? 1.5
                        : c.field === "Account_Name"
                        ? 1.4
                        : c.field === "cycle_time_closed_orders"
                        ? 0.42
                        : 0.5,
                  };
                }),
              ]}
              sizeColumnsToFit={true}
              headerHeight="55"
              rowData={props.body.opportunities}
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={onCellClicked}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              domLayout={domLayout}
              onColumnResized={
                props.body.opportunities.length <= 10 ? onColumnResized : null
              }
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              debounceVerticalScrollbar={true}
              onGridReady={gridReady}
            ></AgGridReact>
          </Grid>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default WinAcvDrillDownDrilldownBody;
