import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";
import {
  countOrACVFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../../util/customFunctions";

const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PCTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const uniqueCols = props.cols;
  let uniqeRows = props.data.map((l) => l.subject).filter(getUniqueValues);
  const total = uniqeRows.filter(f => ['Team', 'Company', 'Rep'].includes(f))[0]
  uniqeRows = [total, ...uniqeRows.filter(f => !['Team', 'Company', 'Rep'].includes(f)).sort()];
  const tr = uniqeRows.filter(f => !['Team', 'Company', 'Rep'].includes(f)).sort()
  return (
    <div>
      <TableContainer
        style={{
          padding: "1rem",
          paddingBottom: "3rem",
          margin: "auto",
          width: "65%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
          align="center"
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: 100, height: 50 }}
                colSpan={1}
                className={classes.headerCell}
              >
                Team / Rep
              </TableCell>
              {uniqueCols.map((l, i) => {
                return (
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{ background: l.bg, color: l.cl, width: 100 }}
                  >
                    {l.label}
                  </TableCell>
                );
              })}
            </TableRow>
            {uniqeRows.map((row) => {
              const d = props.data.filter((f) => f.subject === row);
              let won = "-";
              let quotaLeft = "-";
              let quota = "-";
              let pipeline = "-";
              let coverage = "-";
              let quarter = null
              if (d.length > 0) {
                won = countOrACVFormatter(
                  d.map((f) => f.won).reduce((a, b) => a + b, 0),
                  true
                );
                quotaLeft = countOrACVFormatter(
                  d
                    .map((f) => f.remainingCurrentQuarter)
                    .reduce((a, b) => a + b, 0),
                  true
                );
                quota = countOrACVFormatter(
                  d.map((f) => f.quota).reduce((a, b) => a + b, 0),
                  true
                );
                pipeline = countOrACVFormatter(
                  d.map((f) => f.acv).reduce((a, b) => a + b, 0),
                  true
                );
                coverage = d
                  .map((f) => f.pipelineCoverageMultiple)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2);
                quarter = d[0].quarter  
              }
              return (
                <StyledTableRow key={row}>
                  <TableCell
                    className={classes.headerCell}
                    align="left"
                    style={ { paddingLeft: "1.75rem" } }
                  >
                    {row}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right" >
                    {quota}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right" style={{cursor: 'pointer'}} onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [row],
                            quarter,
                            "won",
                           props.drilldownHeader2,
                            row,
                            tr
                          )
                        }>
                    {won}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {quotaLeft}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right" style={{cursor: "pointer"}} onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [row],
                            quarter,
                            "qualified",
                            props.drilldownHeader,
                            row,
                            tr
                          )
                        }>
                    {pipeline}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {coverage}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}