import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import AccountExpansionTable from "./AccountExpansionTable";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import AccountExpansionSummaryTable from "./AccountExpansionSummaryTable";
import AccountExpansionContractSummary from "./AccountExpansionContractSummary";
import AccountSummaryActiveAccountTable from "./AccountSummaryActiveAccountTable";
const AccountExpansionTableContainer = (props) => {
  // console.log(props.data, "accountExpansionDebug");
  const priorYearOppHighlight = "#ff9900";
  const priorColor = "#ffd965";
  const currentColor = "#c5e0b3";
  // const classes = useStyles();
  return (
    <Grid container item>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          style={{ padding: "2rem", paddingLeft: 0 }}
          justifyContent="center"
        >
          <Card>
            <Grid
              item
              container
              xs={12}
              style={{ padding: "2rem", paddingLeft: 0 }}
              justifyContent="center"
            >
              <Typography align="center" variant="h2">
                Account Value Expansion - Summary
              </Typography>
            </Grid>
            <Grid item container xs={12} style={{ padding: "2rem" }}>
              <Grid
                container
                item
                xs={12}
                style={{ width: "80%", margin: "auto" }}
                alignItems="center"
                justifyContent="center"
              >
                <AccountExpansionSummaryTable
                  priorColor={priorColor}
                  currentColor={currentColor}
                  // priorYearOppHighlight={priorYearOppHighlight}
                  // handleSecondLevelDrilldown={props.handleSecondLevelDrilldown}
                  data={props.data}
                />
              </Grid>

              <Grid item container xs={12} style={{ padding: "2rem" }}>
                <Grid
                  container
                  item
                  // xs={12}
                  style={{ width: "70%", margin: "auto" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <AccountExpansionContractSummary
                    priorColor={priorColor}
                    currentColor={currentColor}
                    // priorYearOppHighlight={priorYearOppHighlight}
                    // handleSecondLevelDrilldown={props.handleSecondLevelDrilldown}
                    data={props.data}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ padding: "2rem" }}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center">
                  Summary by Active Account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AccountSummaryActiveAccountTable
                  priorColor={priorColor}
                  currentColor={currentColor}
                  data={props.data}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item container xs={12} style={{ padding: "2rem" }}>
          <Card>
            <Grid
              item
              container
              xs={12}
              style={{ padding: "2rem" }}
              justifyContent="center"
            >
              <Typography align="center" variant="h2">
                Account Value Expansion - Detail
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <AccountExpansionTable
                priorColor={priorColor}
                currentColor={currentColor}
                priorYearOppHighlight={priorYearOppHighlight}
                handleSecondLevelDrilldown={props.handleSecondLevelDrilldown}
                data={props.data}
              />
            </Grid>
            <Grid item container xs={12}>
              <Legend
                square={15}
                type="AsOfDate"
                legends={[
                  {
                    text: "Prior year opp that is not a parent of any current year Won or Open opp.",
                    color: priorYearOppHighlight,
                  },
                ]}
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountExpansionTableContainer;
