import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
} from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import {
  StyledTableRow,
  dateFormatterMomentV3,
} from "../../util/customFunctions";
import { replaceDynamicValueLabel } from "../../util/utils";
// import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const styles = makeStyles((theme) => ({
  root: {},
  content: {
    width: "40%",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
    fontSize: "0.85rem",
    fontFamily: "Roboto" / "Helvetica" / "Arial" / "sans-serif",
  },

  colHeader: {
    fontWeight: 600,
    borderRight: "0.5px solid lightgrey",
    paddingLeft: "5px",
    color: "inherit",
    background: "#4472C4",
  },
  colHeader1: {
    fontWeight: 600,
    borderRight: "0.5px solid lightgrey",
    color: "inherit",
    background: "#4472C4",
  },
  tablerows: {
    borderRight: "0.5px solid lightgrey",
    padding: "5px",
    fontWeight: "inherit",
  },
}));

// const tabledata=[
//     {Change_Date : "1/18/2022 12:54:48",Stage:"Targeted",ACV:null,PCD:'4/1/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"None"},
//     {Change_Date : "1/19/2022 11:28:09",Stage:"Targeted",ACV:'65000',PCD:'5/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'Forward',PCD_Change_Direction:"Backward"},
//     {Change_Date : "1/19/2022 11:34:35",Stage:"Qualified",ACV:'65000',PCD:'4/1/2022',Stage_Change_Direction:"Forward",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward"},
//     {Change_Date : "1/19/2022 11:39:09",Stage:"Qualified",ACV:'65000',PCD:'5/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Backward"},
//     {Change_Date : "2/2/2022 13:54:01",Stage:"Qualified",ACV:'65000',PCD:'3/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward"},
//     {Change_Date : "2/10/2022 13:22:44",Stage:"Proposed",ACV:'75600',PCD:'3/31/2022',Stage_Change_Direction:"Forward",ACV_Change_Direction:'Forward',PCD_Change_Direction:"None"},
//     {Change_Date : "2/11/2022 10:31:59",Stage:"Proposed",ACV:'75600',PCD:'3/18/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward"},
//     {Change_Date : "3/14/2022 12:36:17",Stage:"Proposed",ACV:'75600',PCD:'3/25/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Backward"},
//     {Change_Date : "3/18/2022 12:49:37",Stage:"Proposed",ACV:'48000',PCD:'3/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'Backward',PCD_Change_Direction:"Backward"},

// ]

const NewWaterfallManagerTabel = (props) => {
  const tableRef = React.createRef();

  const tabledatas = props.data;

  const tabledata = tabledatas.map((i) => {
    return {
      Stage: i.Stage,
      ACV: i.ACV,
      PCD: dateFormatterMomentV3(i.PCD),
      Change_Date: new Date(i.Change_Date)
        .toISOString()
        .replace("T", " ")
        .substring(0, 19),
      Stage_Change_Direction: i.Stage_Change_Direction,
      ACV_Change_Direction: i.ACV_Change_Direction,
      PCD_Change_Direction: i.PCD_Change_Direction,
      colors: i.colors,
      colors_direction: {
        1: "rgb(226 240 217)",
        2: "rgb(169 209 142)",
        3: "rgb(84 103 53)",
        "-1": "rgb(244 177 131)",
        "-2": "rgb(237 125 49)",
        "-3": "rgb(197 90 17)",
        0: "rgb(166 166 166)",
        none: "white",
      },
      text_color: {
        Forward: "rgb(112, 173, 71)",
        Backward: "rgb(237, 124, 49)",
      },
    };
  });

  const classes = styles();
  const selectColor = {
    Backward: "rgb(237, 124, 49)",
    Forward: "rgb(112, 173, 71)",
    None: "",
  };

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  var formatClosedDate = (closedDate) =>
    new Date(closedDate).toLocaleDateString("en-US");

  var formatClosedDateTime = (closedDate) =>
    new Date(closedDate).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content}>
        <Typography
          align="center"
          style={{
            fontSize: "1.2rem",
            fontWeight: 500,
            fontFamily: "Roboto/Helvetica/Arial/ sans-serif",
            lineHeight: "2rem",
          }}
        >
          Change Events
        </Typography>
        <Grid container justify="center" style={{ marginBottom: "-1rem" }}>
          <TableContainer
            className={classes.mainTable}
            component={Paper}
            style={{ maxHeight: 500, borderRadius: "0rem" }}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div> */}
            <Table ref={tableRef} stickyHeader data-test="ChangeEvents-Details">
              <TableHead
                style={{
                  fontWeight: 600,
                  color: "white ",
                  background: "#4472C4",
                }}
              >
                <TableRow>
                  <TableCell align="center" className={classes.colHeader1}>
                    {" "}
                    #{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    Date & Time
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    Stage
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {props.Value_Label}
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    Proj. Close Dt.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tabledata.map((item, index) => {
                  return (
                    <StyledTableRow>
                      <TableCell
                        align="right"
                        className={classes.tablerows}
                        style={{ paddingRight: "5px" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tablerows}
                        style={{ paddingRight: "5px", width: "13rem" }}
                      >
                        {" "}
                        {formatClosedDate(item.Change_Date)},{" "}
                        <span>{formatClosedDateTime(item.Change_Date)}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tablerows}
                        style={{
                          paddingLeft: "5px",
                          background: selectColor[item.Stage_Change_Direction],
                          color:
                            item.Stage_Change_Direction !== "None"
                              ? "white"
                              : "inherit",
                          fontWeight:
                            item.Stage_Change_Direction !== "None"
                              ? 600
                              : "inherit",
                        }}
                      >
                        {item.Stage}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tablerows}
                        style={{
                          paddingRight: "5px",
                          width: "6rem",
                          background: selectColor[item.ACV_Change_Direction],
                          color:
                            item.ACV_Change_Direction !== "None"
                              ? "white"
                              : "inherit",
                          fontWeight:
                            item.ACV_Change_Direction !== "None"
                              ? 600
                              : "inherit",
                        }}
                      >
                        {item.ACV !== null
                          ? `${formatter.format(item.ACV)}`
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tablerows}
                        style={{
                          paddingRight: "5px",
                          width: "8rem",
                          background: selectColor[item.PCD_Change_Direction],
                          color:
                            item.PCD_Change_Direction !== "None"
                              ? "white"
                              : "inherit",
                          fontWeight:
                            item.PCD_Change_Direction !== "None"
                              ? 600
                              : "inherit",
                        }}
                      >
                        {item.PCD}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
};

export default NewWaterfallManagerTabel;
