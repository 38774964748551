import { Card, Grid, Typography, withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import DrilldownBody from "./DrilldownBody";
import NQPDrilldown from "./NQPDrilldown";

import QualifiedPipelineTable from "./QualifiedPipelineTable";
import { weekEndingMapping } from "./utils";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import { styled } from "@mui/material/styles";
import Drilldown from "../GenericScorecard/Drilldown";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class NewQualifiedPipeline extends Component {
  state = {
    location: null,
    secondLevelLocation: null,
    toggleValue: "CurrentAcv",
    num: 2,
  };
  componentDidMount = () => {
    this.props.getNewQualifiedPipelineData();
  };
  drilldown = (rep, weekEnding, avgDrilldownFlag, metaData) => {
    console.log(rep, weekEnding);
    let selectNum = 2;
    var filters = [],
      filtersForDrilldown = [],
      heading = "";
    if (["Twelve", "Eight", "Four"].includes(weekEnding)) {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineData.timeFrameEnding,
          value: this.props.NewQualifiedPipelineData.trailingTwelveWeeks.slice(
            0,
            weekEndingMapping[weekEnding]
          ),
          string: true,
        },
      ];
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value:
            weekEnding === "Twelve"
              ? this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 6 months"
                : "Trailing 12 weeks"
              : weekEnding === "Eight"
              ? this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 4 months"
                : "Trailing 8 weeks"
              : this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
              ? "Trailing 2 months"
              : "Trailing 4 weeks",
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineData.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline Trends";

      if (
        this.props.NewQualifiedPipelineData.timeFrameEnding ===
        "Half_Month_Ending"
      ) {
        if (weekEnding === "Twelve") {
          selectNum = 6;
        }

        if (weekEnding === "Eight") {
          selectNum = 4;
        }

        if (weekEnding === "Four") {
          selectNum = 2;
        }
      }
    } else {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineData.timeFrameEnding,
          value: weekEnding,
          string: true,
        },
      ];
      const timeEnded =
        this.props.NewQualifiedPipelineData.timeFrameEnding === "Week_Ending"
          ? "Week"
          : "Half-Month";
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value: `${timeEnded} ended ${customDateFormatter(weekEnding)}`,
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineData.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline";

      if (
        this.props.NewQualifiedPipelineData.timeFrameEnding ===
        "Half_Month_Ending"
      ) {
        if (weekEnding === "Twelve") {
          selectNum = 6;
        }

        if (weekEnding === "Eight") {
          selectNum = 4;
        }

        if (weekEnding === "Four") {
          selectNum = 2;
        }
      }
    }
    this.props.getNewQualifiedPipelineDrilldownData(filters);

    this.setState({
      ...this.state,
      isMetaData: avgDrilldownFlag,
      metaData,
      drilldownLabelString:
        this.props.NewQualifiedPipelineData.timeFrameEnding === "Week_Ending"
          ? "week"
          : "half-month",
      heading,
      location: "drilldown",
      filtersForDrilldown: filtersForDrilldown,
      num: selectNum,
    });
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = () => {
    this.setState({ ...this.state, location: "Home", filtersForDrilldown: [] });
    // this.props.clearDrilldownData()
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center">
            New Qualified pipeline
          </Typography>
        </Grid>
        <Typography variant="body1">
          Opportunities that came to, or went past the Qualified stage, moving
          in the forward direction, and for the first time.
        </Typography> */}
        {addHeaderdescription(
          "New Qualified pipeline",
          "Opportunities that came to, or went past the Qualified stage, moving in the forward direction, and for the first time."
        )}
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      New Qualified Opportunities by rep (grouped by team)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip
                        title={`${this.props.Value_Label}`}
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title={`Current ${this.props.Value_Label}`}
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                <QualifiedPipelineTable
                  thresholds={this.props.NewQualifiedPipelineData.threshold}
                  drilldown={this.drilldown}
                  isColDate={true}
                  name="trailing-week"
                  columns={this.props.detailsColumns.sort()}
                  isAverage={false}
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACVAverage"
                      : "Average_Acv"
                  }
                  data={this.props.NewQualifiedPipelineData.detail}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : ["# of Opps", "ACV at Qualified", "Avg. ACV"]
                  }
                  col="week"
                  tableHeading={
                    this.props.NewQualifiedPipelineData.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineData.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineData.timeFrameEnding
                  }
                  teamNames={this.props.detailsTeams}
                  dataID={"NQP_Created_table"}
                  Value_Label={this.props.Value_Label}
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{ width: "100%", margin: "auto", padding: "1rem" }}
                      align="center"
                    >
                      {this.props.NewQualifiedPipelineData.traillingtableHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip
                        title={`${this.props.Value_Label}`}
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title={`Current ${this.props.Value_Label}`}
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                <QualifiedPipelineTable
                  name="opp-by-rep"
                  thresholds={this.props.NewQualifiedPipelineData.threshold}
                  drilldown={this.drilldown}
                  isColDate={false}
                  isAverage={true}
                  col="Week_Ending"
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol2={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksAverageCurrentACV"
                      : "WeeksAverageACV"
                  }
                  avgCol3={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksCurrentAverage"
                      : "WeeksAverage"
                  }
                  columns={this.props.columns}
                  traillingColumns={
                    this.props.NewQualifiedPipelineData.endingAvgColNames
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineData.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  data={this.props.NewQualifiedPipelineData.summary}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : ["# of Opps", "ACV at Qualified", "Avg. ACV"]
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineData.timeFrameEnding
                  }
                  tableHeading={
                    this.props.NewQualifiedPipelineData.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  teamNames={this.props.teams}
                  Value_Label={this.props.Value_Label}
                  dataID={"NQP_Created_table_v2"}
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>

        {this.state.location === "drilldown" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.heading}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
              Value_Label={this.props.Value_Label}
            >
              <Drilldown
                header={"New Qualified Pipeline"}
                body={this.props.NewQualifiedPipelineDrilldownData}
                nqpScoreCard={true}
                showCurrentACV={this.state.toggleValue}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                trailingAvarage={this.state.metaData}
                showPointer={true}
                sideCol={`Add. in the past ${this.state.num}-Months`}
                Value_Label={this.props.Value_Label}
              />
              {/* <Drilldown body={this.props.NewQualifiedPipelineDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
               company={this.props.company} handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
               />   */}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
  } = state.app;
  var transformedNewQualifiedPipeline,
    teams,
    columns,
    detailsTeams,
    detailsColumns,
    timeframe;
  if (NewQualifiedPipelineData !== "") {
    teams = NewQualifiedPipelineData.summary
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    timeframe = NewQualifiedPipelineData.timeFrameEnding;
    columns = NewQualifiedPipelineData.summary
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    detailsTeams = NewQualifiedPipelineData.detail
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    detailsColumns = NewQualifiedPipelineData.detail
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    NewQualifiedPipelineData.summary = NewQualifiedPipelineData.summary.map(
      (item) => {
        if (item[timeframe] === "Trailing 8 Week") {
          return {
            ...item,
            weekEnding: "Eight",
          };
        } else if (item[timeframe] === "Trailing 12 Week") {
          return {
            ...item,
            weekEnding: "Twelve",
          };
        } else if (item[timeframe] === "Trailing 4 Week") {
          return {
            ...item,
            weekEnding: "Four",
          };
        }
      }
    );
  }

  return {
    company: user.company,
    Value_Label: user.Value_Label,
    columns,
    teams,
    detailsTeams,
    detailsColumns,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_NewQualifiedPipeline_drilldown_data" });
  },
  getNewQualifiedPipelineDrilldownData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_drilldown_data_request" });
    appService.getNewQualifiedPipelineDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({
          type: "get_NewQualifiedPipeline_drilldown_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error,
          });
      }
    );
  },
  getNewQualifiedPipelineData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_data_request" });
    appService.getNewQualifiedPipelineData(filters).then(
      (json) => {
        dispatch({ type: "get_NewQualifiedPipeline_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_NewQualifiedPipeline_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedNewQualifiedPipeline = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewQualifiedPipeline));
export default connectedNewQualifiedPipeline;
