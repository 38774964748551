import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import Tooltip from "@material-ui/core/Tooltip";
import {
  countOrACVFormatter,
  _isNaN,
  StyledTableRow,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import Link from "@mui/material/Link";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function NQPTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableRef2 = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.4rem",
    },
  }))(Tooltip);

  const nqpGoalTooltip = (team, goal) => {
    return (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471c4",
                  borderRadius: "1rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.6rem",
                }}
              >
                <div
                  className="tooltip-box"
                  style={{ padding: "0px", fontSize: "1rem" }}
                >
                  <h3 className="heading-style" style={{ padding: "0.5rem" }}>
                    {team}
                  </h3>
                  <div
                    style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}
                  >
                    <hr />
                  </div>
                  <div
                    className="container"
                    style={{ padding: "0.5rem", fontSize: "0.8rem" }}
                  >
                    <h3 className="result-style">
                      {" "}
                      Goal :{" "}
                      <span style={{ color: "rgb(31,119,180)" }}>
                        {countOrACVFormatter(goal, true)}
                      </span>{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </>
          ))}
      </>
    );
  };

  props.trailingFourWeeks.sort();
  //unique reps
  let reps = props.trailingFourWeeksData
    .map((rep) => rep[props.colName])
    .filter(getUniqueValues)
    .sort();

  let noOfGoalWeeks = props.noOfGoalWeeks ? props.noOfGoalWeeks - 1 : 1;
  let uniqueReps = []; //unique reps
  //sorting timeframe in a order
  let timeFrameSort = props.trailingFourWeeks.sort();
  //recent timeframe
  let recentTimeFrame = timeFrameSort[timeFrameSort.length - 1];

  reps.forEach((rep) => {
    //count is to number of times goal not reaches continuously and prevElement to check wheather prevvvious data is reaching goal or not
    let count = 0,
      prevElement = false;
    //each reps timeframe data will be stored in timeFrameSortData
    const timeFrameSortData = timeFrameSort.map((week) => ({
      [props.frequencyChurnedStageChangeColumn]: week,
      [props.colName]: rep,
      currentACV:
        props.trailingFourWeeksData.filter(
          (d) =>
            d[props.colName] === rep &&
            d[props.frequencyChurnedStageChangeColumn] === week
        ).length >= 1
          ? props.trailingFourWeeksData.filter(
              (d) =>
                d[props.colName] === rep &&
                d[props.frequencyChurnedStageChangeColumn] === week
            )[0].currentACV
          : 0,
      nqpGoal:
        props.trailingFourWeeksData.filter(
          (d) =>
            d[props.colName] === rep &&
            d[props.frequencyChurnedStageChangeColumn] === week
        ).length >= 1
          ? props.trailingFourWeeksData.filter(
              (d) =>
                d[props.colName] === rep &&
                d[props.frequencyChurnedStageChangeColumn] === week
            )[0].nqpGoal
          : 0,
    }));
    //to find which timeframe is reaching the goal
    timeFrameSortData.forEach((item) => {
      if (count == noOfGoalWeeks && item["currentACV"] < item["nqpGoal"]) {
        let recentWeekGoal = timeFrameSortData.filter(
          (s) => s[props.frequencyChurnedStageChangeColumn] == recentTimeFrame
        )[0]["nqpGoal"];
        if (
          timeFrameSortData.filter(
            (s) => s[props.frequencyChurnedStageChangeColumn] == recentTimeFrame
          )[0]["currentACV"] < recentWeekGoal
        ) {
          //pushing rep to uniqueReps array
          uniqueReps.push(rep);
        }
      }
      //  else if (count == noOfGoalWeeks && item["nqpGoal"] <= 0) {
      //   //if count is reaches and nqpGoal is zero it pushes rep to uniqueReps
      //   uniqueReps.push(rep);
      // }
      else {
        // if (item["nqpGoal"] <= 0) {
        //   //if goal is zero prevElement will be true and count will be increased by 1
        //   prevElement = true;
        //   count = count + 1;
        // }
        // else {
        //if acv less than goal prevElement will be true and count will be count + 1
        prevElement = item["currentACV"] < item["nqpGoal"] ? true : false;
        count =
          prevElement == true
            ? item["currentACV"] < item["nqpGoal"]
              ? count + 1
              : 0
            : 0;
      }
      // }
    });
  });

  uniqueReps = uniqueReps.filter(getUniqueValues).sort();

  const thresholdHandler = (acv, nqpGoal) => {
    var color = "";
    if (nqpGoal !== undefined && acv !== undefined && nqpGoal > 0) {
      color = nqpGoal <= acv ? "#E2EFDA" : "";
    }
    return color;
  };

  const [tableLimit, setTableLimit] = useState(2);
  const setdataLimit = (val) => {
    if (val === "showMore") {
      setTableLimit(uniqueReps.length);
    } else {
      setTableLimit(2);
    }
  };

  return uniqueReps.length > 0 ? (
    <>
      <Typography
        variant="h2"
        style={{ paddingTop: "1rem", textAlign: "center" }}
      >
        Reps / Teams at risk of missing their New Qualified Pipeline creation
        goals{" "}
        <>
          {" "}
          {![null, undefined].includes(props.noOfGoalWeeks)
            ? props.noOfGoalWeeks
            : ""}{" "}
        </>{" "}
        time frames in a row
      </Typography>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "2rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer style={{ paddingTop: "1rem" }} id="myTableContainer">
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          id={"New_Qualified_Pipeline_table"}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                className={classes.colHeader}
                style={{ background: "white", color: "black", minWidth: 160 }}
              >
                {props.firstColumn}
              </TableCell>
              {props.trailingFourWeeks.map((week, index) => (
                <TableCell
                  colSpan={3}
                  style={
                    index % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {customDateFormatter(week)}
                </TableCell>
              ))}
              {props.firstColumn === "Half-Month Ending" ? (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                  <br />
                  <span>(per half-month)</span>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.colHeader}
                style={{ background: "white", color: "black" }}
              ></TableCell>
              {props.trailingFourWeeks.map((week, index) => (
                <>
                  <TableCell align="center" className={classes.colHeader}>
                    # of Opps
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {`Current ${props.Value_Label}`}
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {/* Avg. ACV */}
                    {`Avg. ${props.Value_Label}`}
                  </TableCell>
                </>
              ))}
              {/* 4 week avg col */}
              <>
                <TableCell align="center" className={classes.colHeader}>
                  # of Opps
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {`Current ${props.Value_Label}`}
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {/* Avg. ACV */}
                  {`Avg. ${props.Value_Label}`}
                </TableCell>
              </>
            </TableRow>

            {uniqueReps.slice(0, tableLimit).map((rep) => {
              let averageData = props.trailingEightWeeksAverage.filter(
                (item) => item[props.colName] === rep
              )[0];
              let averageDataEightWeeks;

              averageData =
                averageData === undefined
                  ? { count: 0, currentACV: 0, nqpGoal: 0 }
                  : averageData;

              return (
                <StyledTableRow key={rep} style={{ fontWeight: 600 }}>
                  <TableCell
                    style={{ minWidth: 160, textIndent: "1rem" }}
                    align="left"
                    className={classes.colHeader}
                  >
                    {rep}
                  </TableCell>
                  {props.trailingFourWeeks.map((week, idx) => {
                    let cellData = props.trailingFourWeeksData.filter(
                      (item) =>
                        item[props.frequencyChurnedStageChangeColumn] ===
                          week && item[props.colName] === rep
                    );
                    cellData =
                      cellData.length === 0
                        ? { currentACV: 0, count: 0, nqpGoal: 0 }
                        : cellData[0];
                    return (
                      <>
                        <TableCell align="right" className={classes.dataCell}>
                          {countOrACVFormatter(cellData.count, false)}
                        </TableCell>
                        {cellData.nqpGoal > 0 ? (
                          <HtmlTooltip
                            title={nqpGoalTooltip(rep, cellData.nqpGoal)}
                          >
                            <TableCell
                              align="right"
                              className={classes.dataCell}
                              style={{
                                background: thresholdHandler(
                                  cellData.currentACV,
                                  cellData.nqpGoal
                                ),
                              }}
                            >
                              {countOrACVFormatter(cellData.currentACV, true)}
                            </TableCell>
                          </HtmlTooltip>
                        ) : (
                          <TableCell
                            align="right"
                            className={classes.dataCell}
                            style={{
                              background: thresholdHandler(
                                cellData.currentACV,
                                cellData.nqpGoal
                              ),
                            }}
                          >
                            {countOrACVFormatter(cellData.currentACV, true)}
                          </TableCell>
                        )}

                        <TableCell align="right" className={classes.dataCell}>
                          {countOrACVFormatter(
                            _isNaN(cellData.currentACV / cellData.count)
                              ? 0
                              : cellData.currentACV / cellData.count,
                            true
                          )}
                        </TableCell>
                      </>
                    );
                  })}
                  {/* 4 week avg col */}
                  {
                    <>
                      <TableCell align="right" className={classes.dataCell}>
                        {averageData.count.toFixed(1)}
                      </TableCell>
                      {averageData.nqpGoal > 0 ? (
                        <HtmlTooltip
                          title={nqpGoalTooltip(rep, averageData.nqpGoal)}
                        >
                          <TableCell
                            align="right"
                            className={classes.dataCell}
                            style={{
                              background: thresholdHandler(
                                averageData.currentACV,
                                averageData.nqpGoal
                              ),
                            }}
                          >
                            {countOrACVFormatter(averageData.currentACV, true)}
                          </TableCell>
                        </HtmlTooltip>
                      ) : (
                        <TableCell
                          align="right"
                          className={classes.dataCell}
                          style={{
                            background: thresholdHandler(
                              averageData.currentACV,
                              averageData.nqpGoal
                            ),
                          }}
                        >
                          {countOrACVFormatter(averageData.currentACV, true)}
                        </TableCell>
                      )}

                      <TableCell align="right" className={classes.dataCell}>
                        {countOrACVFormatter(
                          _isNaN(averageData.currentACV / averageData.count)
                            ? 0
                            : averageData.currentACV / averageData.count,
                          true
                        )}
                      </TableCell>
                    </>
                  }
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {uniqueReps.length > 2 ? (
        <p>
          {uniqueReps.length > tableLimit ? (
            <>
              <Link
                id="New_Qualified_Pipeline_showmore"
                onClick={() => setdataLimit("showMore")}
                style={{ cursor: "pointer" }}
              >
                Show More
              </Link>
            </>
          ) : (
            <>
              <Link
                id="New_Qualified_Pipeline_showless"
                onClick={() => setdataLimit("showLess")}
                style={{ cursor: "pointer" }}
              >
                Show Less
              </Link>
            </>
          )}
        </p>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <Typography
        variant="h2"
        style={{ paddingTop: "1rem", textAlign: "center" }}
      >
        Reps / Teams at risk of missing their New Qualified Pipeline creation
        goals{" "}
        <>
          {" "}
          {![null, undefined].includes(props.noOfGoalWeeks)
            ? props.noOfGoalWeeks
            : ""}{" "}
        </>{" "}
        time frames in a row
      </Typography>
      <TableContainer style={{ paddingTop: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef2} />
        </div>
        <Table
          ref={tableRef2}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          id={"New_Qualified_Pipeline_table"}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                className={classes.colHeader}
                style={{ background: "white", color: "black", minWidth: 160 }}
              >
                {props.firstColumn}
              </TableCell>
              {props.trailingFourWeeks.map((week, index) => (
                <TableCell
                  colSpan={3}
                  style={
                    index % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {customDateFormatter(week)}
                </TableCell>
              ))}
              {props.firstColumn === "Half-Month Ending" ? (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                  <br />
                  <span>(per half-month)</span>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.colHeader}
                style={{ background: "white", color: "black" }}
              ></TableCell>
              {props.trailingFourWeeks.map((week, index) => (
                <>
                  <TableCell align="center" className={classes.colHeader}>
                    # of Opps
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.colHeader}
                  >{`Current ${props.Value_Label}`}</TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {/* Avg. ACV */}
                    {`Avg. ${props.Value_Label}`}
                  </TableCell>
                </>
              ))}
              {/* 4 week avg col */}
              <>
                <TableCell align="center" className={classes.colHeader}>
                  # of Opps
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                >{`Current ${props.Value_Label}`}</TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {/* Avg. ACV */}
                  {`Avg. ${props.Value_Label}`}
                </TableCell>
              </>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                className={classes.colHeader}
                colSpan={16}
              >
                {`No ${props.colName === 'Owner_Name' ? 'Rep' : 'Team'} has missed their New Qualified Pipeline creation goals`}
                <>
                  {" "}
                  {![null, undefined].includes(props.noOfGoalWeeks)
                    ? props.noOfGoalWeeks
                    : ""}{" "}
                </>{" "}
                time frames in a row
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
