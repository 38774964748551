import React from "react";
import {
  Box,
  Paper,
  Modal,
  Dialog,
  Hidden,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SingleLevelDrilldownForAreaOfFocus = ({
  header,
  description,
  isOpen,
  onClose,
  handleRadioChange,
  selectedValue,
  handleContinue,
  handleCancel,
}) => {
  // Function to render the content inside the modal
  const renderContent = () => (
    <>
      <RadioGroup
        value={selectedValue}
        onChange={handleRadioChange}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          mb: 2,
        }}
      >
        {header.map(item => 
          <FormControlLabel
          value={item.value}
          control={<Radio color="primary" />}
          label={item.label}
        />
        )}
      </RadioGroup>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Align buttons to the right
          width: "100%", // Ensure it stretches to the container width
          // mt: 2,
        }}
      >
          <Button variant="contained" color="primary" onClick={handleContinue}  disabled={!selectedValue}>
         Confirm
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCancel}
          sx={{ ml: 2 }}
        >
          Cancel
        </Button>
      
      </Box>
    </>
  );

  return (
    <>
      <Hidden mdUp>
        <Dialog
          fullScreen
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          {renderContent()}
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Modal open={isOpen} onClose={onClose} style={{ overflow: "auto" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
              // maxWidth: "600px",
              // minWidth: "300px",
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              border: "none",
              outline: "none", // Disable focus outline
            }}
          >
            {renderContent()}
          </Box>
        </Modal>
      </Hidden>
    </>
  );
};

export default SingleLevelDrilldownForAreaOfFocus;
