import {Typography } from '@material-ui/core'
import React from 'react'


const DimensionTableTooltip=(props)=> {
  const toolTipData=(dimension,code)=> {
        if(dimension === "Rep Win Rate") {
          return "Historical win rate for reps with sufficient history, for opportunities from each stage for a specific customer type and ACV range."
        } else if (dimension === "Industry Win Rate") {
          return "Historical company win rate for opportunities in this industry from each stage for a specific customer type and ACV range."
        } else if (dimension === "Days at Stage") {
          return "The number of days at each stage (for a specific customer type and ACV range) is one of many factors used to compute the momentum score."
        } else if(dimension === "Days since Qualified") {
          return "The number of days since this opportunity was Qualified (for a specific customer type and ACV range) is one of many factors used to compute the momentum score."
        } else if(dimension === "Time Left") {
          return code === 'close' ? "The number of days left till the Projected Close Date." : "The number of days left till the end of the quarter when the opportunity is projected to close."
        }
    }

    return (
        <div style={{borderTop:props.isLimitingFactor ? 'solid #fbe5d6ff' : 'white',borderRadius:"1rem",padding:'0px',margin:0,borderWidth:"0.6rem"}}>
            <div className="tooltip-box" style={{padding:'0px',fontSize:'1rem',fontFamily:'roboto'}}>
                <h4 className="heading-style" style={{padding:"0.5rem"}}>{props.dimension}</h4>
                    <div style={{paddingLeft:'0.8rem',paddingRight:'0.8rem'}}>
                      <hr  />
                    </div>
                    <div className="tooltip-box" style={{padding:'0px',fontFamily:'roboto'}}>
                      <Typography align="left" style={{padding:"0.5rem"}}>{toolTipData(props.dimension,props.code)}</Typography>
                    </div>
            </div>
        </div>
    )
}

export default DimensionTableTooltip