import React,{useEffect} from 'react';
import {
  Card, CardHeader, CardContent, Divider, Button, Typography, Grid, Radio, RadioGroup, FormControlLabel, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel, Select, MenuItem, capitalize
} from '@material-ui/core';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { appService } from '../../../../App/app.service'
import Skeleton from '@material-ui/lab/Skeleton'
import TopRepsTable from '../../../FunnelCompare/TopRepsTable'
import AllAesPerformance from './AllAesPerformance';

const styles = theme => ({

})

function AllAesPerformanceTable(props) {
useEffect (()=>{
  if(props.AllAEsData===""){
    props.getAllAEsData();

  }

},[props.ScoreCardData])
console.log(props)
const { classes } = props;
  return (
  
    <div>
      {console.log(props.AllAEsData)}
      <Grid item xs={12} container className={classes.tableCard}>
          {
            props.AllAEsData !== "" ?
              <AllAesPerformance
                title="All AEs Performance YTD"
                benchmarkReps={props.AllAEsData.benchmarkReps}
                data={props.AllAEsData.topRepsTable}
                firstStage={props.AllAEsData.firstStage}
                secondStage={props.AllAEsData.secondStage}
                wonStage={props.AllAEsData.wonStage}
                Win_Rate_Prefix={props.Win_Rate_Prefix}
              /> : <Grid item container justify="center" component={Card}>
                <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
              </Grid>
          }
        </Grid>

       {/* <Card
              style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
              raised={true}
            >
              <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
              <Divider style={{ width: '100%' }} />
              <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
            </Card> */}
    </div>


  );
}

const mapStateToProps=(state) =>{
  const {AllAEsData,ScoreCardData,user}=state.app
  return {AllAEsData,ScoreCardData,Win_Rate_Prefix: user.Win_Rate_Prefix}
}

const mapDispatchToProps = (dispatch) =>({
getAllAEsData : () =>{
  dispatch({type: 'get_allAEs_data_request'} )
  appService.getAllAEsData()
  .then(json => {
    console.log(json)
    dispatch({ type: 'get_allAEs_data_success', json })
  }, error => {
    if (typeof error === 'object') dispatch({ type: 'get_allAEs_data_failure', error: 'Something went wrong' })
    else
      dispatch({ type: 'get_allAEs_data_failure', error })
  })
}
})
const connectAllAesPerformanceTable = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AllAesPerformanceTable))
export default connectAllAesPerformanceTable
