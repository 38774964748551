import {Card, Grid, Snackbar,IconButton,SnackbarContent} from '@material-ui/core';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { appService } from '../../App/app.service'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useCallback, useMemo, useRef, useEffect,useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as d3 from 'd3'
import QuotaMaintenanceTable from './QuotaMaintenanceTable';

const styles = theme => ({
    

})



/*QuotaMaintenance component */

const QuotaMaintenance = (props) => {
    const { classes } = props;
const [initialData,setInitialData]=useState()
const [sentUpdate,setSentUpdate]=useState()
const [updatedQuotaRecords,setUpdatedQuotaRecords]=useState()
const [status,setStatus]=useState()
const [open, setOpen] = React.useState(false);
    // Makinng api call for getting the data from DB
  useEffect (()=>{
    if(props.QuotaData===undefined||props.QuotaData===''){
      props.getQuotaData();
 }
 setInitialData(props.QuotaData)
 setUpdatedQuotaRecords(props.updatedQuotaRecords)
 handleClickEvent(updatedQuotaRecords,sentUpdate)
    console.log(props)
},[props])

const handleClose = (event, reason) => {
  if ("clickaway" == reason) return;
  setOpen(false);
};
const handleClickEvent=(updatedQuotaRecords,sentUpdate)=>{

  if(updatedQuotaRecords!==undefined&&updatedQuotaRecords!==''){
    setOpen(true);
    if(updatedQuotaRecords.length===sentUpdate.length){
      updatedQuotaRecords.map(d=>{
        sentUpdate.map(u=>{
(u.UserId===d.UserID&&u.FiscalQuarter===d.FiscalQuarter&&updatedQuotaRecords!==[])?setStatus("Data updated"):setStatus("partialy updated") 
})
      })
    }
    // updatedQuotaRecords.length===sentUpdate.length?setStatus("Data Updated"):setStatus("Data Partialy Updated")
  }
}
// Making api call for updating the data in DB on click of a button
 const updateData =(newData,rowData)=>{
  console.log(props.QuotaData)
   console.log(newData)
setUpdatedQuotaRecords(props.updatedQuotaRecords)
setSentUpdate(newData)
props.updateQuotaData(newData)

}





    return (
      <Grid container justify="flex-start" >
            <Grid item xs={12} >
                   { props.QuotaData!==undefined&&props.QuotaData!==''?<Card style={{ padding: '0.5rem 0.5rem' }}>
                     <QuotaMaintenanceTable 
                     title={"Quota Maintenance"}
                     quotaData={props.QuotaData} 
                     initialData={initialData}
                     updateData={updateData}
                     updatedQuotaRecords={updatedQuotaRecords}
                     sentUpdate={sentUpdate}
                     />
                     </Card>:''}
                              </Grid>
                              {console.log(status)}
                              {/* { <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
                              <SnackbarContent style={{
      backgroundColor:status==="Data updated"?"lightgreen":"orange"
    }}
    message={<span id="client-snackbar">{status}</span>}
  />
       
      </Snackbar>
      
      } */}
        </Grid>
        
    );
};

const mapStateToProps=(state) =>{
    const {QuotaData,updatedQuotaRecords}=state.app
    return {QuotaData,updatedQuotaRecords}
  }
  
  const mapDispatchToProps = (dispatch) =>({
    getQuotaData : () =>{
    dispatch({type: 'get_quota_data_request'} )
    appService.getQuotaData()
    .then(json => {
      console.log(json)
      dispatch({ type: 'get_quota_data_success', json })
    }, error => {
      if (typeof error === 'object') dispatch({ type: 'get_quta_data_failure', error: 'Something went wrong' })
      else
        dispatch({ type: 'get_quota_data_failure', error })
    })
  },

  updateQuotaData : (updatedata) =>{
    dispatch({type: 'update_quota_data_update_request'} )
    console.log(updatedata)
    appService.updateQuotaData(updatedata)
    .then(json => {
      console.log(json)
      dispatch({ type: 'update_quota_data_update_success', json })
    }, error => {
      if (typeof error === 'object') dispatch({ type: 'update_quota_data_update_failure', error: 'Something went wrong' })
      else
        dispatch({ type: 'update_quota_data_update_failure', error })
    })
  }



  })
  const connectQuotaMaintenance = connect(mapStateToProps, mapDispatchToProps)((QuotaMaintenance))
  export default connectQuotaMaintenance