import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  capitalize,
} from "@material-ui/core";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
// import RepExcelExportButton from "../../components/xlsx-export/RepTable-xlsx";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function TopRepsTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [parentWidth, setparentWidth] = useState();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const benchmarkReps = props.benchmarkReps.map((rep) => rep.name);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerReps");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);
  return (
    <Grid container item xs={12} component={Card} justify="flex-end">
      <Grid item xs={12} style={{ padding: "1rem" }}>
        <Typography align="center" variant="h2" style={{ margin: "1rem" }}>
          {props.title}
        </Typography>
      </Grid>
      {parentWidth !== undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "0.2rem",
            // marginRight: "1rem",
            width: `${
              tableContainerRef.current.getBoundingClientRect().width
            }px`,
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <Grid container item xs={12} justify="center" style={{ padding: "0rem" }}>
        <TableContainer
          style={{ width: "auto", maxHeight: "86vh" }}
          id="myTableContainerReps"
          ref={tableContainerRef}
        >
          {/* {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null} */}
          <Table ref={tableRef} data-test={props.title}>
            <TableHead
              style={{
                position: "sticky",
                top: -1,
                zIndex: 555,
                outline: "lightgrey solid 0.9px",
                outlineOffset: "-1.5px",
              }}
            >
              <TableRow className={classes.primaryColumnColor}>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ width: "1%", background: "#4471c4", color: "white" }}
                >
                  Rank
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    minWidth: 250,
                    background: "#4471c4",
                    color: "white",
                  }}
                  className={classes.colHeader}
                >
                  Rep
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {props.wonStage} {`${props.Value_Label}`}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {props.wonStage} {`${props.Value_Label} %`}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  Cumulative % of Total
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  Pursued {`${props.Value_Label}`}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {/* Overall Win Rate From {props.firstStage} */}

                 {`Overall ${props.Win_Rate_Prefix} From ${props.firstStage}`}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {props.firstStage} to {props.secondStage} Conversion Rate
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {props.secondStage} to {props.wonStage} Conversion Rate
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {props.wonStage} # of Opps
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  Pursued # of Opps
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  Quota
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  Attain. %
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((item, index) => {
                const rowStyle = item.highlight
                  ? { background: "#e2efda" }
                  : {};

                return (
                  <StyledTableRow
                    className={
                      item.rep.Full_Name === "total" ? classes.fontBold : ""
                    }
                    key={capitalize(item.rep.Full_Name)}
                    style={rowStyle}
                  >
                    <TableCell align="right" className={classes.dataCell}>
                      {item.rep.Full_Name === "total"
                        ? ""
                        : countOrACVFormatter(index + 1, false)}
                    </TableCell>
                    <TableCell align="left" className={classes.dataCell}>
                      {capitalize(item.rep.Full_Name)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          props.wonStage,
                          false,
                          "cameTo",
                          item.rep.Full_Name === "total"
                            ? props.data
                                .map((f) => f.rep.Full_Name)
                                .filter((f) => f !== "total")
                            : item.rep.Full_Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {countOrACVFormatter(item.wonACV, true)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                    >{`${Math.round(item.winPercOfTotal * 100)}%`}</TableCell>
                    <TableCell align="right" className={classes.dataCell}>
                      {item.rep.Full_Name === "total"
                        ? ""
                        : `${Math.round(item.cumulativePercOfWinTotal * 100)}%`}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          props.firstStage,
                          false,
                          "cameTo",
                          item.rep.Full_Name === "total"
                            ? props.data
                                .map((f) => f.rep.Full_Name)
                                .filter((f) => f !== "total")
                            : item.rep.Full_Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {countOrACVFormatter(item.pursued, true)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                    >{`${Math.round(item.overAllWinRate * 100)}%`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                    >{`${Math.round(
                      item.firstToSecondConvRate * 100
                    )}%`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                    >{`${Math.round(
                      item.secondToWinConvRate * 100
                    )}%`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          props.wonStage,
                          false,
                          "cameTo",
                          item.rep.Full_Name === "total"
                            ? props.data
                                .map((f) => f.rep.Full_Name)
                                .filter((f) => f !== "total")
                            : item.rep.Full_Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {countOrACVFormatter(item.wonCount, false)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.dataCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          props.firstStage,
                          false,
                          "cameTo",
                          item.rep.Full_Name === "total"
                            ? props.data
                                .map((f) => f.rep.Full_Name)
                                .filter((f) => f !== "total")
                            : item.rep.Full_Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {countOrACVFormatter(item.pursuedOpps, false)}
                    </TableCell>
                    <TableCell align="right" className={classes.dataCell}>
                      {countOrACVFormatter(item.Quota, true)}
                    </TableCell>
                    <TableCell align="right" className={classes.dataCell}>
                      {Math.round(item.attainment * 100)}%
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default TopRepsTable;