import React from "react";
import {
  Grid,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  capitalize,
} from "@material-ui/core";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  StyledTableRow,
} from "../../../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../../../components/xlsx-export/CopyTablesObject";
// import RepExcelExportButton from "../../../../components/xlsx-export/RepTable-xlsx";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function AllAesPerformance(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();

  const benchmarkReps = props.benchmarkReps.map((rep) => rep.name);
  return (
    <Grid container item xs={12} component={Card} justify="center">
      <Grid item xs={12} style={{ padding: "1rem" }}>
        <Typography align="center" variant="h2" style={{ margin: "1rem" }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="center" style={{ padding: "0rem" }}>
        <TableContainer style={{ padding: "1rem 2rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>

          <Table ref={tableRef} data-test={props.title}>
            <TableHead>
              <TableRow className={classes.primaryColumnColor}>
                <TableCell
                  align="center"
                  style={{ width: "1%" }}
                  className={classes.colHeader}
                >
                  Rank
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 150 }}
                  className={classes.colHeader}
                >
                  Rep
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.wonStage} ACV
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.wonStage} ACV %
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  Cumulative % of Total
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  Pursued ACV
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {/* Overall Win Rate From {props.firstStage} */}
                  {`
                  Overall ${props.Win_Rate_Prefix} From ${props.firstStage}
                  `}
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.firstStage} to {props.secondStage} Conversion Rate
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.secondStage} to {props.wonStage} Conversion Rate
                </TableCell>

                <TableCell align="center" className={classes.colHeader}>
                  {props.wonStage} # of Opps ACV ≥ $30K
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.secondStage} Pipeline with projected close this year ≥ $30K
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                SkyGeni's projected attainment (midpoint) for current qtr. / Quota Attain.
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                SkyGeni's projected attainment (midpoint) for next qtr. / Quota Attain.
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                Trailing 8 week Qualified Pipeline weekly avg.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((item, index) => (
                <StyledTableRow
                  className={
                    item.rep.Full_Name === "total" ? classes.fontBold : ""
                  }
                  key={capitalize(item.rep.Full_Name)}
                  style={
                    benchmarkReps.includes(item.rep.Full_Name)
                      ? { background: "#e2efda" }
                      : {}
                  }
                >
                  <TableCell align="right" className={classes.dataCell}>
                    {item.rep.Full_Name === "total"
                      ? ""
                      : countOrACVFormatter(index + 1, false)}
                  </TableCell>
                  <TableCell align="left" className={classes.dataCell}>
                    {capitalize(item.rep.Full_Name)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.wonACV, true)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.dataCell}
                  >{`${Math.round(item.winPercOfTotal * 100)}%`}</TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {item.rep.Full_Name === "total"
                      ? ""
                      : `${Math.round(item.cumulativePercOfWinTotal * 100)}%`}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.pursued, true)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.dataCell}
                  >{`${Math.round(item.overAllWinRate * 100)}%`}</TableCell>
                  <TableCell
                    align="right"
                    className={classes.dataCell}
                  >{`${Math.round(
                    item.firstToSecondConvRate * 100
                  )}%`}</TableCell>
                  <TableCell
                    align="right"
                    className={classes.dataCell}
                  >{`${Math.round(
                    item.secondToWinConvRate * 100
                  )}%`}</TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.closedWonCountGT20K, false)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.qualifiedPipelineACV, true)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {`${countOrACVFormatter(item.cfqAttainment, true)} (${Math.round(item.cfqAttPerc * 100)}%)`}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {`${countOrACVFormatter(item.nfqAttainment, true)} (${Math.round(item.nfqAttPerc * 100)}%)`}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.trailingEightWeeksAverage, true)}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default AllAesPerformance;
