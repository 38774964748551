import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { countOrACVFormatter } from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(255,255,255,0.8)",
    color: "black",
    fontFamily: "roboto",
    fontSize: theme.typography.pxToRem(14),
    borderRadius: "1rem",
    padding: "0px",
    boxShadow: "0 0.1rem 0.1rem 0",
    marginTop: "0.4rem",
  },
}))(Tooltip);

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
  datacell: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "5px",
    paddingRight: "0px",
    border: "none",
  },
  headerCell: {
    border: "0.5px solid lightgrey",
    fontWeight: 600,
    padding: "6px 13px 6px 16px",
  },
  tableSpacer: {
    //   margin: "1rem",
    marginTop: "0rem",
  },
}));
export default function DrilldownByStageTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const uniqueCols = props.cols;
  return (
  
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          overflow: "hidden",
        }}
      >
        <TableContainer
          className={classes.mainTable}
          style={{ flex: "1 1 auto" }}
        >
          <Table
            ref={tableRef}
            size="small"
            aria-label="html table"
            style={{ tableLayout: "auto", width: "100%" }}
            data-test="pipeline-projection-detail-by-stage-table"
          >
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={4}
                  style={
                    2 % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                        }
                  }
                >
                  Projected Yield (Mid)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                ></TableCell>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                >
                  # of Opps
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                >
                  ACV
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                >
                  Expected Yield
                </TableCell>
              </TableRow>
              {uniqueCols.map((s) => {
                const d = props.data.filter((f) => f.stage === s);
                let acv = "-";
                let count = "-";
                let expectedACV = "-";
                if (d.length > 0) {
                  if (![null, undefined, ""].includes(d[0].acv)) {
                    acv = countOrACVFormatter(
                      d.map((f) => f.acv).reduce((a, b) => a + b, 0),
                      true
                    );
                  }
                  if (![null, undefined, ""].includes(d[0].count)) {
                    count = countOrACVFormatter(
                      d.map((f) => f.count).reduce((a, b) => a + b, 0),
                      false
                    );
                  }
                  if (![null, undefined, ""].includes(d[0].expectedYeild)) {
                    expectedACV = countOrACVFormatter(
                      d.map((f) => f.expectedYeild).reduce((a, b) => a + b, 0),
                      true
                    );
                  }
                }
                return (
                  <TableRow key={s}>
                    <TableCell
                      align="left"
                      className={classes.headerCell}
                      colSpan={1}
                    >
                      {s}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.headerCell}
                      colSpan={1}
                    >
                      {count}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.headerCell}
                      colSpan={1}
                    >
                      {acv}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.headerCell}
                      colSpan={1}
                    >
                      {expectedACV}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ flexShrink: 0 }}>
          <CopyTablesObject tableRef={tableRef} />
        </div>
      </div>
    </>
  );
}
