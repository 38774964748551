export const getResponsiveWidth = (browserInnerWidth, pageXPosition) => {
  if (browserInnerWidth > 4000) { // 4000 and above

    return `calc(${pageXPosition - 180 + "px"} - 73%)`
  } else if (browserInnerWidth > 3700) {// 4000 px to 3700px
    return `calc(${pageXPosition - 180 + "px"} - 63%)`
  } else if (browserInnerWidth > 3400) { //  3700 to 3400
    return `calc(${pageXPosition - 180 + "px"} - 57%)`
  } else if (browserInnerWidth > 3250) { // 3400 to  3250 
    return `calc(${pageXPosition - 180 + "px"} - 50%)`
  } else if (browserInnerWidth > 2900) { // 3250 to 2900
    return `calc(${pageXPosition - 180 + "px"} - 40%)`
  } else if (browserInnerWidth > 2600) { // 2900 to 2600
    return `calc(${pageXPosition - 180 + "px"} - 30%)`
  } else if (browserInnerWidth > 2300) { // 2600 to 2300
    return `calc(${pageXPosition - 180 + "px"} - 20%)`
  } else if (browserInnerWidth > 2100) { // 1900 to 2300
    return `calc(${pageXPosition - 180 + "px"} - 10%)`
  } else if (browserInnerWidth > 1900) { // 1900 to 2300
    return `calc(${pageXPosition - 180 + "px"} - 5%)`
  } else { // less than 1900
    return `calc(${pageXPosition - 180 + "px"})`
  }
}