import { Card, Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

export default function Loading(props) {
    return (
        <Grid container component={Card} raised={props.raised} justify="center">
            <Skeleton animation="wave" variant="rect" width='80%' height={280} style={{ margin: '20px 0px' }} />
        </Grid>
    )
}
