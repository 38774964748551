import * as d3 from "d3"
// import './D3RepPerformanceBar.scss'
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import { customTickFormat, getUniqueValues, stageFormattter } from '../../util/customFunctions'
const D3RepPerformanceBarQT = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3RepPerformanceBarQT.create = (el, config) => {
  d3.selection.prototype.last = function () { return d3.select(this.nodes()[this.size() - 1]) };

  if (config.data) {
    // config.data = config.data.filter(item => item.Name !== "total")
    config.barColor = '#70ad47';
    config.barHeight = 10;
    var marginLeftCalc = config.data;

    // console.log(config.data)

    config.margin = {
      top: 20,
      right: 70,
      bottom: 60,
      left: 120
      // config.type === "Region" || config.type === "Segment" ? marginLeftCalc.sort((a, b) => b.Display_Name.length - a.Display_Name.length)[0]['Display_Name'].length * 9 : marginLeftCalc.sort((a, b) => b['Sales Rep'].length - a['Sales Rep'].length)[0]['Sales Rep'].length * 7
    };
    // if (!config.width) {
    //   config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 50;
    // }

    config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 100;
        config.height = 500 - config.margin.top - config.margin.bottom;

    const barData = config.data.filter(item => item[config.quarters] === "total")
    // console.log(barData.map(item => item.value).reduce((a,b) => a+b, 0))
const Zdata = barData.filter(x => x.label === "0%")
    const totalValue = barData.map(item => item.value).reduce((a,b) => a+b, 0)

    

      // barData.push({label: ">300%", quarter: "total", value: 38})

    // console.log(barData)


    //  config.height = 400;
    //  config.type === "Region" || config.type === "Segment" ? config.data.length * 90 : config.data.length * 30 

    var data = [...Zdata, ...barData,];



    var x = d3.scaleBand()
            .rangeRound([0, config.width])
            .padding(0.6)
            // .align(0.1);
        // set y scale
        var y = d3.scaleLinear()
            .range([config.height, 0]);

    const xAxis = d3.axisBottom(x)


    var formatyAxis = d3.format('');
    const yAxis = d3.axisLeft(y).tickFormat(d => d !== 0 ? `${d3.format(formatyAxis)(d)}` : 0).ticks(d3.max(barData, d => d.value) < 8 ? 5 : 10);

    x.domain(data.map((d) => {
      return d.label;
    }));
    
    const { lower_bound, upper_bound } = computeDomainLimits(d3.min(barData, d => d.value ), d3.max(barData, d => d.value + 2 ))
    y.domain([
      lower_bound, upper_bound
    ]);
        

    // y.domain([
    //   0, d3.max(data, (d) => d.value + 2 )
    // ]);

    function make_x_gridlines() {
      return d3.axisBottom(x).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(10);
    }

    var xAxisHeight = config.height 
   
    const chart = d3
      .select(el)
      .append('svg')
      .attr('width', config.width + config.margin.left + config.margin.right)
      .attr('height', config.height + config.margin.top + config.margin.bottom)
      .append('g')
      .attr('transform', `translate(${config.margin.left},${config.margin.top})`);
   
    // chart
    //   .append("g")
    //   .attr("class", "grid")
    //   .attr("transform", "translate(0," + config.barHeight + ")")
    //   .attr("z-index", -1)
    //   .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    // add the Y gridlines
    chart
        .append("g")
        .attr("class", "grid")
        .attr("z-index", -1)
        .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart.append("g")
      .attr("class", "xaxis")
       .style('font-size', "0.75rem")
      .attr("transform", "translate(" + ((0)) + "," + (config.height) + ")")
      .call(xAxis);

    chart.append("g")
      .attr("class", "yaxis")
      .call(yAxis);


// console.log(data)

      var bars = chart.selectAll(".bar")
      .data(data)
      .enter()
      .append("g")

      bars.append("rect")
    //   .attr("class", "bar")
    //   .attr("stroke", "black")
    .style('transform', "scale(1, -1)")
    .style("cursor", "pointer")
    .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: "total", x: config.quarters, z:d.value} ))
      .attr("fill", "#1f77b4")
    //   .attr("opacity", 0.3)
      .attr('x', d => x(d.label))
        .attr('y', -config.height)
        .attr("width", (x.bandwidth()))
        .attr("height", function (d) {
            return config.height - y(d.value)})
            // .style("cursor", "pointer")   
        // .transition()
        // .style("fill","#1f77b4")

        bars.append("text")
      // .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("x", function (d) {
        return x(d.label) + x.bandwidth() / 2;
      })
      .style("cursor", "pointer")
      .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: "total", x: config.quarters, z: d.value} ))
      //x position is 3 pixels to the right of the bar
      .attr("y", d => y(d.value) - 5)
      .text(d => `(${_isNaN(d.value/totalValue) ? (0) : d3.format(",")(Math.round(d.value/totalValue * 100))}%)`)
      // .style("font-weight", 400)
       .style("font-size", "0.7rem")
      .attr("fill", 'black')
      // .style("cursor", "pointer")   
      .attr("text-anchor", "middle");

      bars.append("text")
      // .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("x", function (d) {
        return x(d.label) + x.bandwidth() / 2;
      })
      //x position is 3 pixels to the right of the bar
      .style("cursor", "pointer")
      .attr("y", d => y(d.value) - 16)
      .style("font-size", "0.85rem")
      .attr("fill", 'black')
      // .style("cursor", "pointer")   
      .attr("text-anchor", "middle")
      .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: "total", x: config.quarters, z:d.value} ))
      .text(d => `${_isNaN(d.value) ? (0) : d3.format(",")(Math.round(d.value))}`)
      // .style("font-weight", 500)

      

      chart
      .append("text")
      .attr("y", config.height + 40)
      .attr("x", (config.width / 2) - 90)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("% of Quota")

      chart
      .append("text")
      .attr("y", config.margin.left - 190)
      .attr("x",  -(config.height / 2))
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      .attr("transform", "rotate(-90)") // although axis is rotated, text is not
      .attr("fill", "#00000")
      // .style('text-transform', 'capitalize')
      .text("# of Reps");


    
  }
}
D3RepPerformanceBarQT.destroy = el => {
  d3.select(el).selectAll('svg').remove();
  // d3.select('.legend-flex').remove();
}
export default D3RepPerformanceBarQT;