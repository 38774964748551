import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import {
  customDateFormatterNew,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";
import DataTable from "./DataTable";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class POcsSummary extends Component {
  state = {
    POC_Start_Fiscal_Quarter: ["All"],
    POC_End_Fiscal_Quarter: ["All"],
    location: null,
    secondLevelLocation: null,
    toggleValue: "Acv",
    showWeeklyAvg: false,
    header: "POCs Summary",
  };
  componentDidMount = () => {
    this.props.getfunnelCompareFilters();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const defaultStartFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters.Earliest_Start_Quarter,
        "POC_Start_Fiscal_Quarter"
      );

      const defaultEndFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters.Latest_Start_Quarter,
        "POC_End_Fiscal_Quarter"
      );

      this.setState({
        ...this.state,
        POC_Start_Fiscal_Quarter: defaultStartFQuarter,
        POC_End_Fiscal_Quarter: defaultEndFQuarter,
      });
    }
  }

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters = [];

    if (this.state.POC_Start_Fiscal_Quarter[0] !== "All") {
      const obj = {
        name: "POC_Start_Fiscal_Quarter",
        value:
          typeof this.state.POC_Start_Fiscal_Quarter === "string"
            ? [this.state.POC_Start_Fiscal_Quarter]
            : this.state.POC_Start_Fiscal_Quarter,
      };
      filters = [...filters, obj];
    }

    if (this.state.POC_End_Fiscal_Quarter[0] !== "All") {
      const obj = {
        name: "POC_End_Fiscal_Quarter",
        value:
          typeof this.state.POC_End_Fiscal_Quarter === "string"
            ? [this.state.POC_End_Fiscal_Quarter]
            : this.state.POC_End_Fiscal_Quarter,
      };
      filters = [...filters, obj];
    }

    this.setState({ ...this.state });
    this.props.getCallsCompletedData(filters);
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = (location, quarter, key) => {
    if (location !== "overview") {
      let filters = [];
      let drildownFilters = [];

      let key2 = "active";
      if (key === "Pocs Active at Qtr. Start") {
        key2 = `active`;
      }

      if (key === "POCs Started during Qtr.") {
        key2 = `start`;
      }

      if (key === "POCs Ended during Qtr.") {
        key2 = `end`;
      }

      if (quarter) {
        const obj = {
          name: "quarter",
          value: typeof quarter === "string" ? [quarter] : quarter,
        };
        filters = [obj];
      }

      filters = [...filters];

      drildownFilters = [
        ...drildownFilters,
        {
          title: "Earliest Start Quarter",
          value: this.state.POC_Start_Fiscal_Quarter,
        },
        {
          title: "Latest Start Quarter",
          value: this.state.POC_End_Fiscal_Quarter,
        },
        {
          title: "Fiscal Quarter",
          value: quarter,
        },
      ];

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        // header:key1 === 'Won' ? 'Won' : key1 === 'Open' ? `Open Pipeline (${this.state.Stage[0]} or later)` : `Lost or Disqualified (${this.state.Stage[0]} or later)`,
        filtersForDrilldown: [...drildownFilters],
        header: key,
      });

      this.props.getCallsCompletedDrilldownData(filters, key2);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
            <Typography variant='h2' align='center'>Calls Completed</Typography>
          </Grid>
          <Typography variant='body1'>
          Analyze the cadence and consistency of customer calls completed
          </Typography> */}
        {addHeaderdescription(
          "POCs Summary",
          "Examine POCs started and ended over time"
        )}

        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.POC_Start_Fiscal_Quarter.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Earliest Start Quarter:
                    </InputLabel>
                    <Select
                      data-test="POC_Start_Fiscal_Quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.POC_Start_Fiscal_Quarter}
                      onChange={this.handleChange}
                      label="Earliest Start Quarter"
                      name="POC_Start_Fiscal_Quarter"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.Earliest_Start_Quarter.filter(
                          (item) => item.POC_Start_Fiscal_Quarter !== ""
                        ).map((y) => (
                          <MenuItem
                            key={y.POC_Start_Fiscal_Quarter}
                            value={y.POC_Start_Fiscal_Quarter}
                          >
                            {y.POC_Start_Fiscal_Quarter}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.POC_End_Fiscal_Quarter.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Latest Start Quarter:
                    </InputLabel>
                    <Select
                      data-test="POC_End_Fiscal_Quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.POC_End_Fiscal_Quarter}
                      onChange={this.handleChange}
                      label="Latest Start Quarter"
                      name="POC_End_Fiscal_Quarter"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.Latest_Start_Quarter.filter(
                          (item) => item.POC_End_Fiscal_Quarter !== ""
                        ).map((y) => (
                          <MenuItem
                            key={y.POC_End_Fiscal_Quarter}
                            value={y.POC_End_Fiscal_Quarter}
                          >
                            {y.POC_End_Fiscal_Quarter}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}

        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.CallsCompletedData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      POCs Summary
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right"></Grid>
                </Grid>
                <DataTable
                  data={this.props.CallsCompletedData.data}
                  // dataTotal={this.props.CallsCompletedData.totalData}
                  fiscalQuarters={this.props.CallsCompletedData.fiscalQuarters}
                  labels={this.props.CallsCompletedData.labels}
                  handleChangeLocation={this.handleChangeLocation}
                  dataTest="POCsSummary-data-table"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        {/* <Grid item xs={12} style={{ padding: '1rem', paddingLeft: 0 }}>
            {
              this.props.CallsCompletedData !== "" ?
                <Grid item container xs={12} justify="flex-start">
                  <Card style={{ padding: '1rem 2rem' }}>
                  <Grid item container xs={12}justify="center">
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={10}>
                        <Typography variant='h2' style={{ width: '100%', margin: 'auto', padding: '1rem' }} align="center">Calls Completed - Not Linked to Existing Opp (grouped by team)</Typography>
                     </Grid>
                     <Grid item xs={1} align='right'>
  
                        
                        </Grid>
                     </Grid>
                    
                     <DataTable data={this.props.CallsCompletedData.dataNotLinkedtoExistingOpp} teamRep={this.props.CallsCompletedData.repTeamObj} timeFrames={this.props.CallsCompletedData.uniqueTimeFrames}/>
  
                  </Card>
                </Grid>
                :
                <Grid item container component={Card} justify="center"><Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} /></Grid>
            }
          </Grid> */}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={this.state.header}
                body={this.props.CallsCompletedDrilldownData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
              {/* <Drilldown body={this.props.CallsCompletedDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
                 company={this.props.company} 
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                 />   */}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    POCSSummaryData,
    drilldownOnGraphData,
    ExpectedAcvData,
    POCSSummaryFilters,
    currentFilters,
  } = state.app;

  return {
    company: user.company,
    CallsCompletedData: POCSSummaryData,
    CallsCompletedDrilldownData: drilldownOnGraphData,
    funnelCompareFilters: POCSSummaryFilters,
    ExpectedAcvData,
    currentFilters,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_POCSSummary_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_POCSSummary_filters_request" });
    appService.getPocsSummaryFilters().then(
      (json) => {
        dispatch({ type: "get_POCSSummary_filters_success", json });
        dispatch({ type: "get_POCSSummary_data_request" });
        const defaultStartFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.Earliest_Start_Quarter,
          "POC_Start_Fiscal_Quarter"
        );
        const defaultEndFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.Latest_Start_Quarter,
          "POC_End_Fiscal_Quarter"
        );
        let filters = [];
        if (defaultStartFiscalQuarter.length > 0) {
          filters = [
            {
              name: "POC_Start_Fiscal_Quarter",
              value: defaultStartFiscalQuarter,
            },
          ];
        }
        if (defaultEndFiscalQuarter.length > 0) {
          filters = [
            ...filters,
            { name: "POC_End_Fiscal_Quarter", value: defaultEndFiscalQuarter },
          ];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService.getPocsSummaryData(filters).then(
          (json) => {
            dispatch({ type: "get_POCSSummary_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_POCSSummary_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_POCSSummary_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_POCSSummary_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_POCSSummary_filters_failure", error });
      }
    );
  },
  getCallsCompletedDrilldownData: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPocsSummarydDrilldownData(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getCallsCompletedData: (filters) => {
    dispatch({ type: "get_POCSSummary_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPocsSummaryData(filters).then(
      (json) => {
        dispatch({ type: "get_POCSSummary_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_POCSSummary_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_POCSSummary_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedPOcsSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(POcsSummary));
export default connectedPOcsSummary;
