import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  // import tableStyle from '../../CustomerSuccess/styles/tableStyle'
  import {
    countOrACVFormatter,
    customDateFormatter,
    getUniqueValues,
    StyledTableRow,
  } from "../../util/customFunctions";
  
  import { makeStyles } from "@material-ui/core";
  import isThisQuarter from "date-fns/esm/isThisQuarter/index";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  // import { boolean } from "mathjs/lib/cjs/entry/pureFunctionsAny.generated";
  // import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
  import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";
  
  const tableStyle = makeStyles(
    (theme) => ({
      root: {
        margin: "0.5rem",
        padding: "0.5rem",
      },
      sticky: {
        position: "sticky",
        left: -1,
        background: "white",
        // outline: "0.5px solid lightgrey",
        // outlineOffset: "-1.5px",
        // Create a pseudo-element for the fixed right border
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          width: "0.5px", // Adjust the thickness as needed
          background: "lightgrey", // Adjust the color as needed
        },
      },
      colHeaderNew: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        backgroundColor: "inherit",
      },
      colHeader: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: 600,
      },
      dataCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
      },
      drilldownCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        cursor: "pointer",
      },
      drilldownCellNQP: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: "3%",
        fontWeight: "inherit",
        cursor: "pointer",
      },
      cellMargin: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        paddingLeft: "2rem",
      },
      fontBold: {
        fontWeight: 600,
      },
      indentName: {
        paddingLeft: 30,
      },
      noBorder: {
        border: "none",
      },
      primaryColumnColor: {
        backgroundColor: "#4472C4",
        color: "white",
      },
    }),
    { index: 1 }
  );
  
  function DataTable(props) {
    const tableRef = React.createRef();
    const tableContainerRef = useRef();
  
    const [isSticky, setIsSticky] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [globalExpandArrow, setglobalExpandArrow] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [parentWidth, setparentWidth] = useState();
    const [rowPin, setrowPin] = useState(false);
  
    let globalExpand = [];
  
    const handleExpandClick = (rowId) => {
      setExpandedRows((prevExpandedRows) => {
        const isRowExpanded = prevExpandedRows.includes(rowId);
        if (isRowExpanded) {
          return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
        } else {
          return [...prevExpandedRows, rowId];
        }
      });
    };
  
    useEffect(() => {
      setExpandedRows(globalExpand);
    }, []);
  
    const globalExpandData = (con) => {
      if (con === true) {
        setExpandedRows(globalExpand);
        // setglobalExpandArrow(true);
      } else {
        setExpandedRows([]);
        // setglobalExpandArrow(false);
      }
    };
  
    let countinc = 0;
  
    useEffect(() => {
      const tableContainer = document.getElementById("myTableContainer-Second");
  
      const handleOverflowCheck = () => {
        if (tableContainer) {
          setIsOverflowing(
            tableContainer.scrollWidth > tableContainer.offsetWidth
          );
        }
      };
  
      // Check for overflow initially and whenever the component updates
      handleOverflowCheck();
  
      // Attach a resize event listener to check for overflow when the window is resized
      window.addEventListener("resize", handleOverflowCheck);
  
      return () => {
        // Clean up the event listener when the component unmounts
        window.removeEventListener("resize", handleOverflowCheck);
      };
    }, []);
  
    const uniqueTimeFrames = props.fiscalQuarters
    const uniqueSubjects = props.labels
    const classes = tableStyle();
  
    useEffect(() => {
      const tableContainer = tableContainerRef.current;
  
      const handleScroll = () => {
        if (tableContainer.scrollLeft > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        if (tableContainer.scrollTop > 0) {
          setrowPin(true);
        } else {
          setrowPin(false);
        }
      };
  
      // Attach a scroll event listener to the TableContainer
      tableContainer.addEventListener("scroll", handleScroll);
  
      return () => {
        // Clean up the event listener when the component unmounts
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }, [classes.sticky]);
  
    useEffect(() => {
      // Get the width of the parent TableContainer
      const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
      setparentWidth(parentWidth);
    }, [parentWidth]);
  
    return (
      <div
        style={{ maxWidth: "100%", justifyContent: "center", padding: "1rem" }}
      >
        <Grid xs={12}>
          {/* {isOverflowing ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null} */}
          {parentWidth !== undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
                width: `${
                  tableContainerRef.current.getBoundingClientRect().width
                }px`,
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
        </Grid>
        <TableContainer
          className={classes.mainTable}
          style={{
            width: "auto",
            borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            borderTop: rowPin ? "0.5px solid lightgrey" : "",
            maxHeight: "85vh",
          }}
          id="myTableContainer-Second"
          ref={tableContainerRef}
        >
          {/* {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null} */}
          <Table ref={tableRef} data-test={props.dataTest}>
            <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
              <TableRow
                style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
              >
                <TableCell
                  // className={classes.colHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.colHeader}`
                      : `${classes.colHeader}`
                  }
                  style={{ minWidth: 250, background: "white" }}
                >
                  {""}
                </TableCell>
                {uniqueTimeFrames.map((col, index) => (
                  <TableCell
                    colSpan={2}
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            minWidth: "7rem",
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            minWidth: "7rem",
                          }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                   {col}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : `${classes.colHeader}`
                }
                style={{ background: "white" }}
              >
                </TableCell>
                {uniqueTimeFrames.map((col) =>
                ["#", "ACV"].map((item) => (
                  <TableCell
                    align="center"
                    style={{ background: "white" }}
                    className={classes.colHeader}
                  >
                    {item}
                  </TableCell>
                ))
              )}
                </TableRow>
            </TableHead>
            <TableBody>
            {uniqueSubjects.map((subject, idx) => (
              <StyledTableRow key={idx}>
                <TableCell
                colSpan={1}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : `${classes.colHeader}`
                }
                style={
                  isSticky && idx % 2 === 0
                    ? { background: "#f5f5f5" }
                    : {}
                }
                  align="left"
                >
                  {subject}
                </TableCell>
                {uniqueTimeFrames.map((qtr, index) => {
                    const d = props.data.filter(f => f.quarter === qtr && f.subject === subject)
                    let count = '-'
                    let acv = '-'
                    if(d.length > 0){
                        count = countOrACVFormatter(d.map(f => f.POC_Opp_Count).reduce((a,b) => a+b, 0), false)
                        acv = countOrACVFormatter(d.map(f => f.POC_ACV).reduce((a,b) => a+b, 0), true)
                    }
                    return <>
                        <TableCell align="right" className={classes.dataCell}
                         style={{ cursor: "pointer" }}
                         onClick={() =>
                           props.handleChangeLocation(
                             "drilldownOnGraph",
                            qtr,
                            subject
                           )
                         }
                        >
                            {count}
                          </TableCell>
                          <TableCell align="right" className={classes.dataCell}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                             qtr,
                             subject
                            )
                          }
                          >
                            {acv}
                          </TableCell>
                    </>
                })}
                </StyledTableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  
  export default DataTable;
  