import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
} from "../../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    // color: "inherit",
    background: "white",
    // backgroundColor: "inherit",
    // boxShadow: "5px 2px 5px grey",
    // borderRight: "2px solid black",
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  tablecontent: {
    tableLayout: "auto",
    width: "initial",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function QTTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  // console.log(props.data)
  const [isSticky, setIsSticky] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  let total;

  const quarter = props.data.filter(
    (item) => item[props.quarters] !== "total" && item[props.quarters] !== null
  );

  let uniqueQuarters = quarter
    .map((item) => item[props.quarters])
    .filter(getUniqueValues);
  if (props.toggleValue) {
    uniqueQuarters =
      props.groupby !== "None"
        ? props.toggleValue === "PercSort"
          ? uniqueQuarters
          : props.logicalTeams
              .filter((e) => uniqueQuarters.indexOf(e) !== -1)
              .filter(Boolean)
        : uniqueQuarters;
  } else {
    uniqueQuarters = uniqueQuarters;
  }
  // console.log(props.toggleValue,uniqueQuarters, props.logicalTeams, props.logicalTeams.filter(e => uniqueQuarters.indexOf(e) !== -1))

  const ZLabel = props.data.filter((x) => x.label === "0%");

  const Label = [...ZLabel, ...props.data];
  const uniqueLabel = Label.map((item) => item.label)
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  // console.log(uniqueLabel)

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-first");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            ref={tableContainerRef}
            id="myTableContainer-first"
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}

            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="quota-attainment-bin-table"
            >
              <TableHead>
                {" "}
                <TableRow
                  style={{ paddingLeft: 5 }}
                  className={classes.fontBold}
                >
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader} `}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ width: 150, padding: "10px 5px" }}
                  >
                    Quota Attainment
                  </TableCell>
                  {uniqueLabel.map((i, index) => {
                    return (
                      <>
                        <TableCell
                          key={i}
                          align="center"
                          style={
                            index % 2 === 0
                              ? { background: "#4471c4", color: "white" }
                              : {
                                  background: "#5b9bd5",
                                  color: "white",
                                  padding: "10px 0",
                                }
                          }
                          className={`${classes.repHeader} ${classes.tableColWidth}`}
                          colSpan={1}
                        >
                          {i}
                        </TableCell>
                      </>
                    );
                  })}
                  {/* {uniqueQuarters.map((i, index) => {

                      return (<>
                        <TableCell key={i} align='center' style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white', padding: "10px 0" }} className={`${classes.repHeader} ${classes.tableColWidth}`} colSpan={1} >{i}</TableCell>
                      </>)
                    })
                    } */}
                  {uniqueLabel.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                              }
                        }
                        align="center"
                        className={`${classes.repHeader} ${classes.tableColWidth}`}
                        colSpan={1}
                      >
                        {"Total"}
                      </TableCell>
                      {/* <TableCell style={uniqueQuarters.length > 1 && uniqueQuarters.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white', padding: "10px 0" }} align='center' className={`${classes.repHeader} ${classes.tableColWidth}`} colSpan={1} >{'% of Total'}</TableCell> */}
                    </React.Fragment>
                  )}
                  {uniqueLabel.length === 0 && (
                    <TableCell
                      rowSpan={12}
                      className={`${classes.repHeader} ${classes.tableColWidth}`}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueQuarters.map((qtr, i) => {
                  total = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.table}
                        className={
                          isSticky
                            ? `${classes.sticky} ${classes.table}`
                            : classes.table
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {qtr}
                      </TableCell>
                      {uniqueLabel.map((label, j) => {
                        const cellData = props.data
                          .filter(
                            (item) =>
                              item[props.quarters] === qtr &&
                              item.label === label
                          )
                          .map((item) => item.value);

                        return (
                          <TableCell
                            style={{ cursor: "pointer" }}
                            align="right"
                            className={classes.table}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row: label,
                                cell: qtr,
                                x: props.quarters,
                                z: cellData,
                              })
                            }
                          >
                            {cellData}
                          </TableCell>
                        );
                      })}

                      {uniqueLabel.length >= 1 && (
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          className={classes.table}
                          onClick={() =>
                            props.handleChangeLocation("drilldownOnGraph", {
                              row: uniqueLabel,
                              cell: qtr,
                              x: props.quarters,
                              y: "total",
                              z: props.data
                                .filter((item) => item[props.quarters] === qtr)
                                .map((item) => item.value)
                                .reduce((a, b) => a + b, 0),
                            })
                          }
                        >
                          {props.data
                            .filter((item) => item[props.quarters] === qtr)
                            .map((item) => item.value)
                            .reduce((a, b) => a + b, 0)}
                        </TableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader} ${classes.tableColWidth}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader} ${classes.tableColWidth}`
                        : `${classes.repHeader} ${classes.tableColWidth}`
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                  >
                    Total
                  </TableCell>
                  {uniqueLabel.map((cell, j) => {
                    const total = props.data
                      .filter(
                        (item) =>
                          item.label === cell &&
                          item[props.quarters] === "total"
                      )
                      .map((item) => item.value)
                      .reduce((a, b) => a + b, 0);
                    // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation("drilldownOnGraph", {
                              row: cell,
                              cell: "total",
                              x: props.quarters,
                              z: total,
                              uniqueQuarters: uniqueQuarters,
                            })
                          }
                        >
                          {total}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueLabel.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation("drilldownOnGraph", {
                            row: uniqueLabel,
                            cell: "total",
                            x: props.quarters,
                            y: "total",
                            z: props.data
                              .filter(
                                (item) => item[props.quarters] === "total"
                              )
                              .map((i) => i.value)
                              .reduce((a, b) => a + b, 0),
                          })
                        }
                      >
                        {" "}
                        {_isNaN(
                          props.data
                            .filter((item) => item[props.quarters] === "total")
                            .map((i) => i.value)
                            .reduce((a, b) => a + b, 0)
                        )
                          ? 0
                          : props.data
                              .filter(
                                (item) => item[props.quarters] === "total"
                              )
                              .map((i) => i.value)
                              .reduce((a, b) => a + b, 0)}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>

                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader} ${classes.tableColWidth}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader} ${classes.tableColWidth}`
                        : `${classes.repHeader} ${classes.tableColWidth}`
                    }
                    style={
                      isSticky && (total + 1) % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                  >
                    % of Total
                  </TableCell>
                  {uniqueLabel.map((cell, j) => {
                    const total = props.data
                      .filter(
                        (item) =>
                          item.label === cell &&
                          item[props.quarters] === "total"
                      )
                      .map((item) => item.value)
                      .reduce((a, b) => a + b, 0);
                    const totalValue = props.data
                      .filter((item) => item[props.quarters] === "total")
                      .map((item) => item.value)
                      .reduce((a, b) => a + b, 0);
                    // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                    const totalPerc = total > 0 ? total / totalValue : 0;
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation("drilldownOnGraph", {
                              row: cell,
                              cell: "total",
                              x: props.quarters,
                              z: total,
                              uniqueQuarters: uniqueQuarters,
                            })
                          }
                        >{`${Math.round(totalPerc * 100)}%`}</TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueLabel.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation("drilldownOnGraph", {
                            row: uniqueLabel,
                            cell: "total",
                            x: props.quarters,
                            y: "total",
                            z: props.data
                              .filter(
                                (item) => item[props.quarters] === "total"
                              )
                              .map((i) => i.value)
                              .reduce((a, b) => a + b, 0),
                          })
                        }
                      >
                        100%
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
