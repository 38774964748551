import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom';

const RepBenchmarkingComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem('skygeni-user-page', 'Rep Performance')
      await localStorage.setItem('skygeni-user-repPage', "Rep Benchmarking Analysis")
      await localStorage.removeItem('skygeni-user-enhancedPage')
      await localStorage.setItem('params', JSON.stringify(props.match.params.filters))
      history.push('/')
      window.location.reload()
    })();
  }, [])

  return (
    <div style={{ margin: "1rem auto" }}>Loading...</div>)
}
let routerAddedRepBenchmarkingComponent = withRouter(RepBenchmarkingComponent)
export default routerAddedRepBenchmarkingComponent
