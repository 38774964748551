import {
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import AverageDealSizeFirstTable from "./AverageDealSizeFirstTable";
import AvarageDealsTable from "./AverageDealsTable";
import * as d3 from "d3";
import D3WonStackBar from "../../components/D3Components/D3WonStackBar";
import D3LineChart from "./D3LineChart";
import { deSnake, getLineChartFormatedData } from "../../util/dataFormatter";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  getUniqueValues,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getCustomSortedColoredLegends,
  getDefaultSalesType,
  getDefaultSelectedStage,
} from "../../util/customFunctions";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import { updatedScheme10 } from "../../util/chartColorScheme";
import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import Drilldown from "../../components/Drilldown/DrilldownV4";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import { getMixKey } from "../../util/generateHeadings";
import addHeaderdescription from "../../components/HeaderDescription";
import NumberFormat from "react-number-format";
import YearMonthFilterCombo from "../../Filters/QuarterMonthYearFilter";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});
class AveargeDealSize extends Component {
  filters = [
    "closed_fiscal_quarter",
    "closed_fiscal_year",
    "Closed_Year_Month",
    "Acct_Size_Segment",
    "Cust_Type",
    "Acct_Industry",
    "rep",
    "team",
    "Solution_Cat",
    "ACV_Range",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
  ];
  state = {
    closed_fiscal_quarter: ["All"],
    Acct_Vertical: ["All"],
    Apps_Cluster: ["All"],
    closed_fiscal_year: ["All"],
    Closed_Year_Month: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: 3,
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    formattedData: "",
    appsCluster: "",
    vertical: "",
    filtersForDrilldown: [],
    filters: [],
    rep: ["All"],
    team: ["All"],
    Solution_Cat: ["All"],
    ACV_Range: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    secondLevelLocation: "",
    cycle_time_from_stage: "",
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    acv: 0,
    acvlt: 0,
  };
  componentDidMount() {
    this.props.getfunnelCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      var salesTypes = ["All"];
      var dealsTypes = ["All"];

      const stageSequence = getDefaultSelectedStage(
        this.props.funnelCompareFilters.filter((f) => f.type === "stage")
      );
      let stage_names = [];
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= stageSequence)
            stage_names = [...stage_names, i.stage_name];
        }
      });

      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }
      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        cycle_time_from_stage: stageSequence,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      });
    }

    if (this.props.winACVMixAnalysisData !== "") {
      let quarter =
        this.props.winACVMixAnalysisData.data.filter(
          (item) => item.type !== undefined
        )[0] !== undefined
          ? this.props.winACVMixAnalysisData.data.filter(
              (item) => item.type !== undefined
            )[0].type
          : "closed_fiscal_quarter";
      D3WonStackBar.destroy(this._rootNode1);
      D3LineChart.destroy(this._rootNode2);
      D3LineChart.destroy(this._rootNode3);
      D3LineChart.destroy(this._rootNode4);
      const uniqueQuarters = this.props.winACVMixAnalysisData.data[0]
        .map((item) => item[quarter])
        .filter(getUniqueValues);
      const avg_cycle_time_string = `Avg. ${this.props.winACVMixAnalysisData.data[4][0].stage_name} to ${this.props.winACVMixAnalysisData.data[4][1].stage_name} Cycle Time (days) :`;
      this._chart2 = D3LineChart.create(this._rootNode2, {
        data: getLineChartFormatedData(
          this.props.winACVMixAnalysisData.data,
          "acv",
          "averageDealSize"
        ),
        type: "avg_deal_size",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
        chart: "averageDealSize",
        key: "Acct_Size_Segment",
        avg_cycle_time_string,
        sortData: this.props.winACVMixAnalysisData.data[2],
      });
      this._chart3 = D3LineChart.create(this._rootNode3, {
        data: getLineChartFormatedData(
          this.props.winACVMixAnalysisData.data,
          "duration",
          "averageDealSize"
        ),
        type: "avg_cycle_time",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
        chart: "averageDealSize",
        key: "Acct_Size_Segment",
        avg_cycle_time_string,
        sortData: this.props.winACVMixAnalysisData.data[2],
      });
      this._chart4 = D3LineChart.create(this._rootNode4, {
        data: getLineChartFormatedData(
          this.props.winACVMixAnalysisData.dataACV_Range,
          "duration",
          "acvRange"
        ),
        type: "avg_cycle_time",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
        chart: "averageDealSize",
        key: "ACV_Range",
        avg_cycle_time_string,
        sortData: this.props.winACVMixAnalysisData.dataACV_Range[2],
      });
    }
  }

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.stage_name !== undefined) {
        if (i.sequence >= this.state.cycle_time_from_stage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filters = [
      ...filters,
      {
        name: "cycle_time_from_stage",
        value: stage_names,
        string: true,
      },
    ];

    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    this.props.getWinACVMixAnalysisData(filters, this.state.activeTimeFilter);
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  _setRef3(componentNode) {
    this._rootNode3 = componentNode;
  }
  _setRef4(componentNode) {
    this._rootNode4 = componentNode;
  }
  onResize = async () => {
    D3WonStackBar.destroy(this._rootNode1);
    D3LineChart.destroy(this._rootNode2);
    D3LineChart.destroy(this._rootNode3);
    D3LineChart.destroy(this._rootNode4);
    let quarter =
      this.props.winACVMixAnalysisData.data.filter(
        (item) => item.type !== undefined
      )[0] !== undefined
        ? this.props.winACVMixAnalysisData.data.filter(
            (item) => item.type !== undefined
          )[0].type
        : "closed_fiscal_quarter";

    const uniqueQuarters = this.props.winACVMixAnalysisData.data[1]
      .map((item) => item[quarter])
      .filter(getUniqueValues);
    const avg_cycle_time_string = `Avg. ${this.props.winACVMixAnalysisData.data[4][0].stage_name} to ${this.props.winACVMixAnalysisData.data[4][1].stage_name} Cycle Time (days) :`;

    this._chart2 = D3LineChart.create(this._rootNode2, {
      data: getLineChartFormatedData(
        this.props.winACVMixAnalysisData.data,
        "acv",
        "averageDealSize"
      ),
      type: "avg_deal_size",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
      chart: "averageDealSize",
      key: "Acct_Size_Segment",
      avg_cycle_time_string,
      sortData: this.props.winACVMixAnalysisData.data[2],
    });
    this._chart3 = D3LineChart.create(this._rootNode3, {
      data: getLineChartFormatedData(
        this.props.winACVMixAnalysisData.data,
        "duration",
        "averageDealSize"
      ),
      type: "avg_cycle_time",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
      chart: "averageDealSize",
      key: "Acct_Size_Segment",
      avg_cycle_time_string,
      sortData: this.props.winACVMixAnalysisData.data[2],
    });
    this._chart4 = D3LineChart.create(this._rootNode4, {
      data: getLineChartFormatedData(
        this.props.winACVMixAnalysisData.dataACV_Range,
        "duration",
        "acvRange"
      ),
      type: "avg_cycle_time",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
      chart: "averageDealSize",
      key: "ACV_Range",
      avg_cycle_time_string,
      sortData: this.props.winACVMixAnalysisData.dataACV_Range[2],
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    this.filters
      .filter((s) => s !== "Closed_Year_Month")
      .map((s) => {
        if (!this.state[s].includes("All")) {
          let obj = {
            title: getMixKey(s, this.props.Value_Label, this.props.company),
            value: this.state[s],
          };
          filtersArray.push(obj);
        }
      });

    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.stage_name !== undefined) {
        if (i.sequence >= this.state.cycle_time_from_stage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    let cycleTimeObj = {
      title: "Cycle time from Stage",
      value: stage_names[0],
    };

    return [...filtersArray, cycleTimeObj];
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    const processValue = (currentValue, newValue) => {
      if (newValue.length === 0) {
        return ["All"];
      }
      if (currentValue.length === 1 && currentValue.includes("All")) {
        const filteredValue = newValue.filter((v) => v !== "All");
        return filteredValue.length > 0 ? filteredValue : ["All"];
      }
      if (newValue.includes("All")) {
        return ["All"];
      }
      return newValue;
    };

    const activeTimeFilter = [
      "closed_fiscal_year",
      "closed_fiscal_quarter",
      "Closed_Year_Month",
    ].includes(name)
      ? name
      : this.state.activeTimeFilter;

    this.setState({
      ...this.state,
      [name]: name === "rep" ? [value] : processValue(this.state[name], value),
      activeTimeFilter,
    });
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          Apps_Cluster: "Apps_Cluster",
          ACV_Range: "ACV_Range",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Opportunity_Record_Type: "Opportunity_Record_Type",
          Record_Type_Name__c: "Record_Type_Name__c",
        };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
      drilldownTable: "",
      filtersForDrilldown: [],
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, data, type, quarter) => {
    console.log("selected", type);

    if (location !== "overview") {
      const uniqueStages =
        type === "ACV_Range"
          ? this.props.winACVMixAnalysisData.dataACV_Range[0]
              .map((item) => item[type])
              .filter(getUniqueValues)
          : this.props.winACVMixAnalysisData.data[0]
              .map((item) => item[type])
              .filter(getUniqueValues);
      const uniqueQuarters =
        type === "ACV_Range"
          ? this.props.winACVMixAnalysisData.dataACV_Range[0]
              .map((item) => item[quarter])
              .filter(getUniqueValues)
          : this.props.winACVMixAnalysisData.data[0]
              .map((item) => item[quarter])
              .filter(getUniqueValues);
      let localFilters = this.props.currentFilters;
      let filters = [
        {
          name: quarter,
          value: data.cell === "Total" ? uniqueQuarters : [data.cell],
        },
        { name: type, value: data.row === "Total" ? uniqueStages : [data.row] },
      ];
      filters = [...filters, ...localFilters];
      this.props.getWinACVDrilldown(filters, quarter);
      let filterValues = [
        "Customer Segment",
        "Closed Fiscal Quarter",
        "Closed Fiscal Year",
      ];
      let filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !filterValues.includes(i.title)
      );
      const t =
        type === "ACV_Range"
          ? `${this.props.Value_Label} Range`
          : "Customer Segment";

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        filtersForDrilldown: [
          {
            title:
              quarter === "Closed_Year_Month"
                ? "Closed Month"
                : deSnake(quarter),
            value: data.cell !== "Total" ? [data.cell] : "All",
          },
          {
            title: t,
            value: data.row !== "Total" ? [data.row] : uniqueStages,
          },
          ...filtersForDrilldown,
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };
  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    const timeline =
      this.props.winACVMixAnalysisData !== "" &&
      this.props.winACVMixAnalysisData.data[5] !== undefined
        ? this.props.winACVMixAnalysisData.data[5].type
        : null;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid
          className={classes.grid}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Average Deal Size and Cycle Time
          </Typography>
          <Typography variant="body1">
            Explore trends in average deal sizes and cycle times across regions
            and opportunity types.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Average Deal Size and Cycle Time",
          "Explore trends in average deal sizes and cycle times across regions and opportunity types."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid
            className={classes.grid}
            item
            container
            justify="flex-start"
            xs={12}
          >
            <Grid container item style={{ padding: 10 }} xs={4}>
              <YearMonthFilterCombo
                activeTimeFilter={this.state.activeTimeFilter}
                closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                closedFiscalYear={this.state.closed_fiscal_year}
                Closed_Year_Month={this.state.Closed_Year_Month}
                handleChange={this.handleChange}
                funnelCompareFilters={this.props.funnelCompareFilters}
              />
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {y[this.variableCols.Acct_Vertical]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 150 }}
                    >
                      Cycle time from Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.cycle_time_from_stage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label=" Cycle time from Stage:"
                      name="cycle_time_from_stage"
                      data-test="cycle_time_from_stage"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map(
                          (y) =>
                            y.stage_name && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.ACV_Range &&
                              y[this.variableCols.ACV_Range] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.ACV_Range]}
                              value={y[this.variableCols.ACV_Range]}
                            >
                              {y[this.variableCols.ACV_Range]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/*ACV >= and ACV <= */}

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 10 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {"Deal Category:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        data-test={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Opportunity_Record_Type
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-opp-record-label"
                      >
                        {"Opportunity Record Type:"}
                      </InputLabel>
                      <Select
                        value={
                          this.state[this.variableCols.Opportunity_Record_Type]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Opportunity_Record_Type}
                        data-test={this.variableCols.Opportunity_Record_Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols.Opportunity_Record_Type &&
                                y[this.variableCols.Opportunity_Record_Type] !==
                                  ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={
                                  y[this.variableCols.Opportunity_Record_Type]
                                }
                                value={
                                  y[this.variableCols.Opportunity_Record_Type]
                                }
                              >
                                {y[this.variableCols.Opportunity_Record_Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* C0010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Record_Type_Name__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-opp-record-label"
                      >
                        {"Opp Record Type:"}
                      </InputLabel>
                      <Select
                        value={
                          this.state[this.variableCols.Record_Type_Name__c]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Record_Type_Name__c}
                        data-test={this.variableCols.Record_Type_Name__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols.Record_Type_Name__c &&
                                y[this.variableCols.Record_Type_Name__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Record_Type_Name__c]}
                                value={y[this.variableCols.Record_Type_Name__c]}
                              >
                                {y[this.variableCols.Record_Type_Name__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0017" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-opp-record-label"
                      >
                        {"Opp. Type:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        data-test={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10, marginTop: 10 }}
                justify="space-between"
                xs={12}
                md={12}
                lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.cross_sell1__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {"Sales Type:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.cross_sell1__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.cross_sell1__c}
                        data-test={this.variableCols.cross_sell1__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {"Deal Type:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        data-test={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {/* Content */}
        <Grid className={classes.grid} item xs={12}>
          <Grid>
            {this.props.winACVMixAnalysisData !== "" ? (
              <>
                {this.props.winACVMixAnalysisData.data.filter(
                  (item) =>
                    item.type === "closed_fiscal_year" ||
                    item.type === "closed_fiscal_quarter" ||
                    item.type === "Closed_Year_Month"
                )[0] !== undefined && (
                  <>
                    <Grid container>
                      <Grid className={classes.grid} item xs={12}>
                        <DataVisualizer
                          title={`Number of deals Won by Customer Segment`}
                          type={"Acct_Size_Segment"}
                          chartType="number"
                          chartName="AverageDealSize"
                          winACVMixAnalysisData={
                            this.props.winACVMixAnalysisData.data[0]
                          }
                          handleChangeLocation={this.handleChangeLocation}
                          quarter={timeline}
                          unit="deals"
                          page="AverageDealSize"
                          sortableKey={"deals"}
                          sortByTotal={true}
                          colors={updatedScheme10}
                          doughnutNoNeeded={true}
                          clusterTotal={[]}
                          tableComponent={
                            <AvarageDealsTable
                              prop1={"deals"}
                              tableType={"dealsTable"}
                              quarter={timeline}
                              winAcvData={this.props.winACVMixAnalysisData.data}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                              type={"Acct_Size_Segment"}
                            />
                          }
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average deal size for Won deals by Customer
                                Segment
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer"
                              className="svg-container"
                              ref={this._setRef2.bind(this)}
                            />
                            <Legend
                              square={15}
                              legends={getCustomSortedColoredLegends(
                                this.props.winACVMixAnalysisData.data[2],
                                "Acct_Size_Segment",
                                "avg_deal_size"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <AverageDealSizeFirstTable
                              subtitle="Avg. Deal Size"
                              tableType="AvgAcv"
                              prop1={"deals"}
                              prop2={"avg_deal_size"}
                              quarter={timeline}
                              winAcvData={this.props.winACVMixAnalysisData.data}
                              isAcv={true}
                              handleChangeLocation={this.handleChangeLocation}
                              dataTest={"avg deal size for won deals"}
                              type={"Acct_Size_Segment"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average cycle time (
                                {
                                  this.props.winACVMixAnalysisData.data[4][0]
                                    .stage_name
                                }{" "}
                                to{" "}
                                {
                                  this.props.winACVMixAnalysisData.data[4][
                                    this.props.winACVMixAnalysisData.data[4]
                                      .length - 1
                                  ].stage_name.split("','")[0]
                                }{" "}
                                days) for Won deals by Customer Segment{" "}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer3"
                              className="svg-container"
                              ref={this._setRef3.bind(this)}
                            />
                            <Legend
                              square={15}
                              legends={getCustomSortedColoredLegends(
                                this.props.winACVMixAnalysisData.data[2],
                                "Acct_Size_Segment",
                                "avg_cycle_time"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <AverageDealSizeFirstTable
                              subtitle={
                                <>
                                  Avg.
                                  {
                                    this.props.winACVMixAnalysisData.data[4][0]
                                      .stage_name
                                  }{" "}
                                  to{" "}
                                  {
                                    this.props.winACVMixAnalysisData.data[4][1].stage_name.split(
                                      "','"
                                    )[0]
                                  }{" "}
                                  Cycle Time
                                </>
                              }
                              tableType="dealsduration"
                              prop1={"deals"}
                              prop2={"avg_cycle_time"}
                              quarter={timeline}
                              winAcvData={this.props.winACVMixAnalysisData.data}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                              type={"Acct_Size_Segment"}
                              dataTest={"Avg Cycle Time - Customer Segment"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average cycle time (
                                {
                                  this.props.winACVMixAnalysisData
                                    .dataACV_Range[4][0].stage_name
                                }{" "}
                                to{" "}
                                {
                                  this.props.winACVMixAnalysisData.dataACV_Range[4][
                                    this.props.winACVMixAnalysisData
                                      .dataACV_Range[4].length - 1
                                  ].stage_name.split("','")[0]
                                }{" "}
                                days) for Won deals by {this.props.Value_Label}{" "}
                                Range
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer3"
                              className="svg-container"
                              ref={this._setRef4.bind(this)}
                            />
                            <Legend
                              square={15}
                              legends={getCustomSortedColoredLegends(
                                this.props.winACVMixAnalysisData
                                  .dataACV_Range[2],
                                "ACV_Range",
                                "avg_cycle_time"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <AverageDealSizeFirstTable
                              subtitle={
                                <>
                                  Avg.
                                  {
                                    this.props.winACVMixAnalysisData
                                      .dataACV_Range[4][0].stage_name
                                  }{" "}
                                  to{" "}
                                  {
                                    this.props.winACVMixAnalysisData.dataACV_Range[4][1].stage_name.split(
                                      "','"
                                    )[0]
                                  }{" "}
                                  Cycle Time
                                </>
                              }
                              tableType="dealsduration"
                              prop1={"deals"}
                              prop2={"avg_cycle_time"}
                              quarter={timeline}
                              winAcvData={
                                this.props.winACVMixAnalysisData.dataACV_Range
                              }
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                              type={"ACV_Range"}
                              dataTest={"Avg Cycle Time - ACV Range"}
                              label={`${this.props.Value_Label} Range`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Card>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Won Opportunities"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown.map((it) => ({
                ...it,
                title: deSnake(it.title),
              }))}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                Value_Label={this.props.Value_Label}
                fromStage={
                  this.props.winACVMixAnalysisData.data[4][0].stage_name
                }
                winStage={
                  this.props.winACVMixAnalysisData.data[4][1].stage_name
                }
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    averageDealSizeFilters,
    averageDealSizeData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters: averageDealSizeFilters,
    winACVMixAnalysisData: averageDealSizeData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getWinACVDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getAverageDealSizeDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_average_deal_size_filters_request" });
    appService.getAverageDealSizeFilters().then(
      (json) => {
        dispatch({ type: "get_average_deal_size_filters_success", json });
        dispatch({ type: "get_average_deal_size_data_request" });
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "stage")
        );
        const defaultStages = json.message
          .filter((item) => item.sequence >= stageSequence)
          .map((i) => i.stage_name)
          .filter(Boolean);
        console.log(stageSequence, defaultStages, "STAGESSS");
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
          { name: "cycle_time_from_stage", value: defaultStages },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getAverageDealSizeData(
            json.message.length === 0 ? [] : filters,
            "closed_fiscal_quarter"
          )
          .then(
            (json) => {
              dispatch({ type: "get_average_deal_size_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_average_deal_size_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_average_deal_size_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_average_deal_size_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_average_deal_size_filters_failure", error });
      }
    );
  },
  getWinACVMixAnalysisData: (filters = [], key) => {
    dispatch({ type: "get_average_deal_size_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getAverageDealSizeData(filters, key).then(
      (json) => {
        dispatch({ type: "get_average_deal_size_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_average_deal_size_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_average_deal_size_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedAveragedealSize = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AveargeDealSize));
export default connectedAveragedealSize;
