import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";

import "./PielineTrendsDrilldown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  _isNaN,
  getCSVFileNameString,
  csvDateFormatter,
} from "../../util/customFunctions";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const PipelineTrendsRollingDrilldown = (props) => {
  console.log(props);
  const classes = useStyle();
  const containerHeight = props.data
    ? props.data.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : window.innerHeight - 300;
  const domLayout = props.data
    ? props.data.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const exportCSV = (params) => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        if (
          (cellParams &&
            rowIndex === "Projected_Close_Date_At_Time_Fiscal_Quarter") ||
          rowIndex === "Created_Date" ||
          rowIndex === "Projected_Close_Date_At_Time"
        ) {
          return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        } else if (
          (cellParams && rowIndex === "OppName") ||
          rowIndex === "Account_Name" ||
          rowIndex === "Owner_Name"
        ) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (
          (cellParams && rowIndex === "ACV") ||
          rowIndex === "Acv_At_Time_T"
        ) {
          return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.data ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:{" "}
                <span data-test="count">
                  {d3.format(",")(props.data.count_acv[0].count)}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: ${" "}
                <span data-test="acv">
                  {d3.format(",")(Math.round(props.data.count_acv[0].acv))}
                </span>
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                    color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  type: "rightAligned",
                  sortable: false,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "3px",
                  },
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                {
                  headerName: "Opportunity Name",
                  field: "OppName",
                  flex: 2.3,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Account ",
                  field: "Account_Name",
                  flex: 1.8,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                // { headerName: "Account Segment", field: "Acct_Segment", minWidth: 100, flex: 1.1, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                {
                  headerName: "Customer Type",
                  field: "Cust_Type",
                  flex: 0.7,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                {
                  headerName: "ACV",
                  field: "Acv_At_Time_T",
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  filterParams: { defaultOption: "greaterThan" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    if (_isNaN(Math.round(x.value))) {
                      return "";
                    }
                    return "$ " + d3.format(",")(Math.round(x.value));
                  },
                },
                {
                  headerName: "Created Date",
                  field: "Created_Date",
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: (x) => {
                    if (x.value === null) {
                      return "";
                    } else {
                      let tparts = x.value.split("T");
                      let parts = tparts[0].split("-");
                      return (
                        parseInt(parts[1]) +
                        "/" +
                        parseInt(parts[2]) +
                        "/" +
                        parseInt(parts[0])
                      );
                    }
                  },
                },

                {
                  headerName: "Proj. Close Date",
                  field: "Projected_Close_Date_At_Time",
                  flex: 0.7,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: (x) => {
                    if (x.value === null) {
                      return "";
                    } else {
                      let tparts = x.value.split("T");
                      let parts = tparts[0].split("-");
                      return (
                        parseInt(parts[1]) +
                        "/" +
                        parseInt(parts[2]) +
                        "/" +
                        parseInt(parts[0])
                      );
                    }
                  },
                },
                {
                  headerName: "Age",
                  field: "Age",
                  flex: 0.5,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "20px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                },
                {
                  headerName: "Stage",
                  field: "Stage_At_Time_T",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Owner",
                  field: "Owner_Name",
                  flex: 0.7,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Team",
                  field: "Team",
                  flex: 0.6,
                  resizable: false,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
              ]}
              sizeColumnsToFit={true}
              headerHeight="55"
              rowData={props.data.opportunities
                .sort((a, b) => b.Acv_At_Time_T - a.Acv_At_Time_T)
                .map((item, i) => {
                  let temp = {
                    ...item,
                    OppNo: i + 1,
                  };
                  return temp;
                })}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={props.data.length <= 10 ? onColumnResized : null}
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              onGridReady={gridReady}
              debounceVerticalScrollbar={true}
              domLayout={domLayout}
            ></AgGridReact>
          </Grid>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default PipelineTrendsRollingDrilldown;
