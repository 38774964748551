export const styles = theme => ({
    root: {},
    content: {},
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    inner: {
        height: 375,
        minWidth: 500
    },
    chart: {
        height: '100%'
    },
    new: {
    },
    formControl: {
        maxWidth: 250
    },
    tableHeader: {
        whiteSpace: 'normal',
        textAlign: 'center'
    },
    table: {
        border: '0.5px solid lightgrey', padding: '2px 10px', color: 'inherit'
    },
    tableHeader: {
        border: '0.5px solid lightgrey', padding: '2px 10px', color: 'white', background: '#4472C4'
    },
    gridItem: {
        padding: '1rem'
    },
    inputFieldContainers: {
        marginBottom: 10,
        padding: 10,
        [theme.breakpoints.up('lg')]: {
            paddingRight: 0
        }
    }
})
