import { metrics, stopWordsSet, value_types, calcuation_types } from "./data";
import { findMatchingTemplate, normalizeText, word_tokenize } from "./utils";
/**
 *
 * @param {Set<string>} userInput
 * @param {Set<string>} pipelineStages
 */
const getPipelineStages = (userInput, pipelineStages) => {
  const pipelineStagesResult = new Set();
  const regexPattern = new RegExp([...userInput].join("|"));
  userInput = [...userInput];
  pipelineStages = [...pipelineStages];
  userInput = userInput
    .map((str) =>
      str
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, " ")
        .trim()
    )
    .filter(Boolean);
  for (let token of userInput) {
    for (let stage of pipelineStages) {
      if (stage.includes(token)) {
        pipelineStagesResult.add(stage);
      }
    }
  }

  return pipelineStagesResult;
};
/**
 *
 * @param {string} userInput
 * @param {boolean} hasMetric
 * @param {Array} lkpStages
 */
export const metric_extraction = (
  userInput,
  hasMetric,
  lkpStages,
  customerMetrics
) => {
  const pipelineStages = new Set(
    lkpStages.map((it) => it.stage_name.toLowerCase())
  );
  let metric = null;
  let raw_user_input = normalizeText(userInput);
  let word_tokens = word_tokenize(raw_user_input);
  let userKeyWords = word_tokens.filter((it) => !stopWordsSet.has(it));
  const userKeyWordsSet = new Set(userKeyWords);

  const winrateFoundTemplate = findMatchingTemplate(
    userInput,
    customerMetrics.winrateKeywords
  );
  const conversionRateFoundTemplate = findMatchingTemplate(
    userInput,
    customerMetrics.conversionRateKeywords
  );
  const cameToFoundTemplate = findMatchingTemplate(
    userInput,
    customerMetrics.cameToKeywords
  );
  console.log(
    userKeyWordsSet,
    userInput,
    hasMetric,
    lkpStages,
    customerMetrics,
    cameToFoundTemplate,
    conversionRateFoundTemplate,
    winrateFoundTemplate,
    value_types,
    pipelineStages,
    calcuation_types,
    userKeyWordsSet.intersection(pipelineStages).size > 0,
    userKeyWordsSet.intersection(value_types).size > 0,
    userKeyWordsSet.intersection(calcuation_types).size > 0,
    "metric_extraction"
  );
  const pipelineStages2 = getPipelineStages(userKeyWordsSet, pipelineStages);
  const nativelySupportedMetrics =
    cameToFoundTemplate || conversionRateFoundTemplate || winrateFoundTemplate;
  if (nativelySupportedMetrics) {
    if (cameToFoundTemplate) {
      metric = {
        ...cameToFoundTemplate.metric,
        calculation_type: cameToFoundTemplate.calculation_type,
        value_type: cameToFoundTemplate.value_type,
      };
    } else if (conversionRateFoundTemplate) {
      metric = {
        ...conversionRateFoundTemplate.metric,
        calculation_type: conversionRateFoundTemplate.calculation_type,
        value_type: conversionRateFoundTemplate.value_type,
      };
    } else if (winrateFoundTemplate) {
      metric = {
        ...winrateFoundTemplate.metric,
        calculation_type: winrateFoundTemplate.calculation_type,
        value_type: winrateFoundTemplate.value_type,
      };
    }
    hasMetric = true;
  } else if (
    pipelineStages2.size > 0 &&
    userKeyWordsSet.intersection(value_types).size > 0 &&
    userKeyWordsSet.intersection(calcuation_types).size > 0
  ) {
    console.log("intersection is performed", "metric_extraction");
    const pipelineStagesList = [...pipelineStages];
    // has some issue
    // Create a regular expression pattern using the elements from the second array
    const regexPattern = new RegExp([...userKeyWordsSet].join("|"));
    const pipelineStagesOptions = [...pipelineStages2];
    metric = {
      pipeline_stage_1: pipelineStagesOptions[0],
      pipeline_stage_2:
        pipelineStagesOptions.length === 1 ? null : pipelineStagesOptions[1],
      value_type: [...userKeyWordsSet.intersection(value_types)].join(" "),
      calculation_type: [
        ...userKeyWordsSet.intersection(calcuation_types),
      ].join(" "),
    };
    hasMetric = true;
    console.log(pipelineStagesOptions);
  } else {
    console.log("performing conventional calulation", "metric_extraction");
    const wonStageIdx = lkpStages.findIndex(
      (it) => it.opp_status === "won" && it.stage_type === "closed"
    );
    const wonStage = lkpStages[wonStageIdx].stage_name;
    for (let m of [
      `${wonStage.toLowerCase()}`,
      `${wonStage.toLowerCase()} acv`,
      ...metrics,
    ]) {
      if (userInput.includes(m)) {
        metric = m;
        hasMetric = true;
        break;
      }
    }
  }

  console.log(word_tokens, metric, "metric_extraction");
  return { text: userInput, metric, hasMetric };
};
