import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";

const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PipelineTrendsTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let totalindex;

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-rolling");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  const uniqueDate = props.data
    .map((d) => d[props.date])
    .filter(getUniqueValues)
    .sort();

  var uniqueStages = props.data
    .map((d) => d[props.type])
    .filter(getUniqueValues)
    .filter((x) => x !== "Total");

  var uniqueStagesTotal = props.total
    .map((d) => d[props.type])
    .filter(getUniqueValues)
    .filter((x) => x === "Total");
  if (!["Stage_At_Time_T", "Booking_Type_NL_CS_US"].includes(props.type)) {
    uniqueStages = uniqueStages.sort();
  }

  const drillUniqueStages = uniqueStages;

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{ borderLeft: isSticky ? "0.5px solid lightgrey" : "" }}
            id="myTableContainer-rolling"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}

            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test={props.data_test}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150 }}
                  >
                    {"Date"}
                  </TableCell>
                  {uniqueDate.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 210,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {customDateFormatter(i)}
                      </TableCell>
                    );
                  })}
                  {uniqueDate.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {props.label}
                  </TableCell>
                  {uniqueDate.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60 }}
                      >
                        {" "}
                        # of Opps{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90 }}
                      >
                        ACV
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60 }}
                      >
                        % of Total
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>

                {uniqueStages.map((owner, i) => {
                  totalindex = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={
                        //   owner === "Total"
                        //     ? `${classes.fontBold} ${classes.table}`
                        //     : `${classes.table}`
                        // }
                        className={
                          owner === "Total"
                            ? isSticky
                              ? `${stickyClasses.sticky} ${classes.fontBold} ${classes.table}`
                              : isSticky
                              ? `${stickyClasses.sticky} ${classes.fontBold} ${classes.table}`
                              : `${classes.fontBold} ${classes.table}`
                            : isSticky
                            ? `${stickyClasses.sticky} ${classes.table}`
                            : isSticky
                            ? `${stickyClasses.sticky} ${classes.table}`
                            : `${classes.table}`
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {owner === "Total" ? "Total" : owner}
                      </TableCell>
                      {uniqueDate.map((qtr, j) => {
                        const acv = props.data.filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )[0]
                          ? props.data.filter(
                              (item) =>
                                item[props.date] === qtr &&
                                item[props.type] === owner
                            )[0].acv
                          : 0;
                        const opps = props.data.filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )[0]
                          ? props.data.filter(
                              (item) =>
                                item[props.date] === qtr &&
                                item[props.type] === owner
                            )[0].count
                          : 0;
                        const perc = props.data.filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )[0]
                          ? props.data.filter(
                              (item) =>
                                item[props.date] === qtr &&
                                item[props.type] === owner
                            )[0].percent
                          : 0;
                        const stage = props.data
                          .filter(
                            (item) =>
                              item[props.date] === qtr &&
                              item[props.type] === owner
                          )
                          .map((item) => item[props.type]);
                        const date = props.data
                          .filter(
                            (item) =>
                              item[props.date] === qtr &&
                              item[props.type] === owner
                          )
                          .map((item) => item[props.date]);

                        return (
                          <>
                            <TableCell
                              align="right"
                              className={
                                owner === "Total"
                                  ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                  : `${classes.repNumber} ${classes.drilldownCell}`
                              }
                              colSpan={1}
                              onClick={
                                !["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                ) &&
                                (owner === "Total"
                                  ? () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: drillUniqueStages,
                                          cell: date,
                                          x: props.type,
                                          y: "All",
                                        }
                                      )
                                  : () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: [owner],
                                          cell: [qtr],
                                          x: props.type,
                                          y: stage,
                                        }
                                      ))
                              }
                              style={
                                ["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                )
                                  ? { cursor: "auto" }
                                  : { cursor: "pointer" }
                              }
                            >
                              {opps === "undefiend" ||
                              opps === null ||
                              _isNaN(opps)
                                ? "-"
                                : opps}{" "}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={
                                owner === "Total"
                                  ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                  : `${classes.repNumber} ${classes.drilldownCell}`
                              }
                              colSpan={1}
                              onClick={
                                !["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                ) &&
                                (owner === "Total"
                                  ? () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: drillUniqueStages,
                                          cell: date,
                                          x: props.type,
                                          y: "All",
                                        }
                                      )
                                  : () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: [owner],
                                          cell: [qtr],
                                          x: props.type,
                                          y: stage,
                                        }
                                      ))
                              }
                              style={
                                ["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                )
                                  ? { cursor: "auto" }
                                  : { cursor: "pointer" }
                              }
                            >
                              {acv === "undefiend" ||
                              acv === null ||
                              _isNaN(acv)
                                ? "-"
                                : countOrACVFormatter(acv, true)}{" "}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={`${classes.repNumber} ${classes.drilldownCell}`}
                              colSpan={1}
                              onClick={
                                !["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                ) &&
                                (owner === "Total"
                                  ? () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: drillUniqueStages,
                                          cell: date,
                                          x: props.type,
                                          y: "All",
                                        }
                                      )
                                  : () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          row: [owner],
                                          cell: [qtr],
                                          x: props.type,
                                          y: stage,
                                        }
                                      ))
                              }
                              style={
                                ["Apps_Cluster", "Product_Line"].includes(
                                  props.type
                                )
                                  ? { cursor: "auto" }
                                  : { cursor: "pointer" }
                              }
                            >
                              {perc === "undefiend" ||
                              perc === null ||
                              _isNaN(perc)
                                ? "-"
                                : Math.round(perc * 100) + "%"}{" "}
                            </TableCell>
                          </>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}

                {uniqueStagesTotal.map((owner, i) => (
                  <StyledTableRow key={i}>
                    <TableCell
                      align="left"
                      // className={
                      //   owner === "Total"
                      //     ? `${classes.fontBold} ${classes.table}`
                      //     : `${classes.table}`
                      // }
                      className={
                        owner === "Total"
                          ? isSticky
                            ? `${stickyClasses.sticky} ${classes.fontBold} ${classes.table}`
                            : isSticky
                            ? `${stickyClasses.sticky} ${classes.fontBold} ${classes.table}`
                            : `${classes.fontBold} ${classes.table}`
                          : isSticky
                          ? `${stickyClasses.sticky} ${classes.table}`
                          : isSticky
                          ? `${stickyClasses.sticky} ${classes.table}`
                          : `${classes.table}`
                      }
                      style={
                        isSticky && totalindex % 2 === 1
                          ? { background: "#f5f5f5" }
                          : {}
                      }
                      colSpan={1}
                    >
                      {owner === "Total" ? "Total" : owner}
                    </TableCell>
                    {uniqueDate.map((qtr, j) => {
                      const acv = props.total
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )
                        .map((item) => item.acv);
                      const opps = props.total
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )
                        .map((item) => item.count);
                      const perc = props.total
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )
                        .map((item) => item.percent);
                      const stage = props.total
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )
                        .map((item) => item[props.type]);
                      const date = props.total
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.type] === owner
                        )
                        .map((item) => item[props.date]);
                      return (
                        <>
                          <TableCell
                            align="right"
                            className={
                              owner === "Total"
                                ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                : `${classes.repNumber} ${classes.drilldownCell}`
                            }
                            colSpan={1}
                            onClick={
                              !["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              ) &&
                              (owner === "Total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: props.type,
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: props.type,
                                        y: stage,
                                      }
                                    ))
                            }
                            style={
                              ["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              )
                                ? { cursor: "auto" }
                                : { cursor: "pointer" }
                            }
                          >
                            {opps === "undefiend" ||
                            opps === null ||
                            _isNaN(opps)
                              ? "-"
                              : opps}{" "}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={
                              owner === "Total"
                                ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                : `${classes.repNumber} ${classes.drilldownCell}`
                            }
                            colSpan={1}
                            onClick={
                              !["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              ) &&
                              (owner === "Total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: props.type,
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: props.type,
                                        y: stage,
                                      }
                                    ))
                            }
                            style={
                              ["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              )
                                ? { cursor: "auto" }
                                : { cursor: "pointer" }
                            }
                          >
                            {acv === "undefiend" || acv === null || _isNaN(acv)
                              ? "-"
                              : countOrACVFormatter(acv, true)}{" "}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={
                              !["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              ) &&
                              (owner === "Total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: props.type,
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: props.type,
                                        y: stage,
                                      }
                                    ))
                            }
                            style={
                              ["Apps_Cluster", "Product_Line"].includes(
                                props.type
                              )
                                ? { cursor: "auto" }
                                : { cursor: "pointer" }
                            }
                          >
                            {perc[0] === "undefiend" ||
                            perc[0] === null ||
                            _isNaN(perc[0])
                              ? " "
                              : Math.round(perc * 100) + "%"}{" "}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
