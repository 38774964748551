import React, { useState } from "react";
import {
  Grid,
  Card,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  styled,
} from "@material-ui/core";
import {
  StyledTableRow,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getNumOfCols } from "./utils";
import { tooltipClasses } from "@mui/material";
import D3SankeyContainer from "./SankeyContainer";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "center",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "0.75rem",
  },
}));
const renderToolTipCellForClosedStages = (cell, stage, openStages, stages) => {
  if (new Set(Object.values(openStages)).has(stage)) {
    return cell;
  }
  return <LightTooltip title={stages.join(",")}>{cell}</LightTooltip>;
};
const CurrentStageOfQualifiedPipelineTable = (props) => {
  const classes = useStyles();

  //Converting objects to array of objects to add legends
  const legendsColor = Object.keys(props.data.colorValue).map((key) => ({
    text:
      key === "1"
        ? "Highest"
        : key === "2"
        ? "2nd Highest"
        : key === "3"
        ? "3rd Highest"
        : key === "openInPast"
        ? "Open In Past"
        : key,
    color: props.data.colorValue[key],
  }));
  const [metric, setMetric] = useState("acv");
  // const res = legendsColor.filter((legend) => legend.text !== "Won" || "Lost");
  const handleChangeToggle = (e) => {
    setMetric(e.target.value);
  };
  const legends = legendsColor.filter(
    (legend) => legend.text !== "Won" && legend.text !== "Lost"
  );

  const { rowLength, maxHalvesStagesNow } = getNumOfCols(props.data.result, {
    distinctHalvesNow: props.data.distinctHalvesNow,
    distinctHalvesThen: props.data.distinctHalvesThen,
    distinctStagesThen: props.data.distinctStagesThen,
  });

  const nowHalves = [...new Set(maxHalvesStagesNow.map((it) => it[0][1]))];
  const nowStagesMatrix = maxHalvesStagesNow
    .map((it) => ({
      nowHalf: it[0][1],
      nowStages: it,
    }))
    .sort((a, b) => {
      const yearA = parseInt(a.nowHalf.split("-")[0]);
      const yearB = parseInt(b.nowHalf.split("-")[0]);
      const halfA = a.nowHalf.split("-")[1];
      const halfB = b.nowHalf.split("-")[1];

      if (yearA !== yearB) {
        return yearA - yearB;
      }
      return halfA.localeCompare(halfB);
    });
  const grandTotal = Object.values(
    Object.entries(props.data.totalMap)
      .filter(([key, value]) => /^%\|\d{4}-H[12]\|\*\|/.test(key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {})
  )
    .map((it) => it[metric])
    .reduce((a, b) => a + b, 0);
  return (
    <Grid
      item
      xs={12}
      container
      style={{ width: "100%", justifyContent: "center" }}
      component={Card}
    >
      {/* <Card style={{ width: "100%" }}> */}
      <Grid container item xs={12}>
        <D3SankeyContainer
          id={"Current_State_of_QP"}
          stageGroupingColorMap={props.data.stageGroupingColorMap}
          stagePriority={props.data.stageGroupSequenceMap}
          tableData={props.data.result}
          handleChangeToggle={handleChangeToggle}
          toggleValue={metric}
          Value_Label={props.Value_Label}
        />
      </Grid>
      <Grid item container xs={11}>
        <CopyToClipboardTableContainer
          style={{ padding: "1rem" }}
          datatest="current_state_of_qualified_pipeline_table"
        >
          <React.Fragment style={{ padding: "1rem" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  style={{ width: 100 }}
                ></TableCell>

                {nowStagesMatrix.map(({ nowHalf, nowStages }, index) => {
                  const nowStagesList = [
                    ...new Set(
                      nowStages
                        .sort(
                          (a, b) =>
                            props.data.stageGroupSequenceMap[a[3]] -
                            props.data.stageGroupSequenceMap[b[3]]
                        )
                        .map((stage) => `${stage[1]}|${stage[3]}`)
                    ),
                  ].map((stage) => stage.split("|")[1]);
                  return (
                    <TableCell
                      style={
                        index % 2 === 0
                          ? {
                              background: "#4471c4",
                              color: "white",
                              width: 100,
                            }
                          : {
                              background: "#5b9bd5",
                              color: "white",
                              padding: "10px 0",
                              width: 100,
                            }
                      }
                      colSpan={nowStagesList.length}
                      className={[classes.repHeader, classes.fontBold].join(
                        " "
                      )}
                    >
                      {nowHalf}
                    </TableCell>
                  );
                })}

                <TableCell
                  style={
                    nowStagesMatrix.length % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          width: 100,
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          padding: "10px 0",
                          width: 100,
                        }
                  }
                  rowSpan={2}
                  className={[classes.repHeader, classes.fontBold].join(" ")}
                >
                  Grand Total
                </TableCell>
                {/* Add other headers as needed */}
              </TableRow>

              <TableRow
                className={classes.fontBold}
                style={{ textAlign: "center" }}
              >
                <TableCell
                  align="center"
                  style={{ width: 100 }}
                  className={classes.repHeader}
                >
                  From <span style={{ fontSize: "16px" }}> &#8595; </span> / To{" "}
                  <span style={{ fontSize: "16px" }}> &#8594; </span>{" "}
                </TableCell>
                {nowStagesMatrix.map(({ nowHalf, nowStages }) => {
                  return [
                    ...new Set(
                      nowStages
                        .sort(
                          (a, b) =>
                            props.data.stageGroupSequenceMap[a[3]] -
                            props.data.stageGroupSequenceMap[b[3]]
                        )
                        .map((stage) => `${stage[1]}|${stage[3]}`)
                    ),
                  ]
                    .map((stage) => stage.split("|")[1])
                    .map((stage) => {
                      const backgroundValue = props.data.colorValue[stage];
                      const hasBackground = backgroundValue !== undefined;
                      console.log(nowHalf, stage, "current_qp_fixes");
                      return renderToolTipCellForClosedStages(
                        <TableCell
                          style={
                            hasBackground
                              ? { background: backgroundValue, width: 100 }
                              : { width: 100 }
                          }
                          className={classes.repHeader}
                        >
                          {stage}
                        </TableCell>,
                        stage,
                        props.data.openStagesGroup,
                        props.data.groupedStageToStage[stage]
                      );
                    });
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.distinctHalvesThen
                .sort((a, b) => {
                  const yearA = parseInt(a.split("-")[0]);
                  const yearB = parseInt(b.split("-")[0]);
                  const halfA = a.split("-")[1];
                  const halfB = b.split("-")[1];

                  if (yearA !== yearB) {
                    return yearA - yearB;
                  }
                  return halfA.localeCompare(halfB);
                })
                .map((halfThen) => {
                  const stagesThen = props.data.distinctStagesThen.sort(
                    (a, b) =>
                      props.data.stageGroupSequenceMap[a] -
                      props.data.stageGroupSequenceMap[b]
                  );
                  return (
                    <>
                      <StyledTableRow className={classes.fontBold}>
                        <TableCell className={classes.repText}>
                          {halfThen}
                        </TableCell>
                        {nowStagesMatrix.map(({ nowHalf, nowStages }) => {
                          const nowStagesList = [
                            ...new Set(
                              nowStages
                                .sort(
                                  (a, b) =>
                                    props.data.stageGroupSequenceMap[a[3]] -
                                    props.data.stageGroupSequenceMap[b[3]]
                                )
                                .map((stage) => `${stage[1]}|${stage[3]}`)
                            ),
                          ].map((stage) => stage.split("|")[1]);
                          return nowStagesList.map((stage) => (
                            <TableCell
                              className={classes.repNumber}
                            ></TableCell>
                          ));
                        })}
                        <TableCell
                          className={`${classes.repHeader} ${classes.fontBold}`}
                        ></TableCell>
                      </StyledTableRow>
                      {stagesThen.map((stageThen) => {
                        const totalValKey = `${halfThen}|%|${stageThen}|*`;
                        const totalVal = props.data.totalMap[totalValKey];

                        return (
                          <StyledTableRow>
                            {renderToolTipCellForClosedStages(
                              <TableCell className={classes.repText}>
                                {" "}
                                {stageThen}{" "}
                              </TableCell>,
                              stageThen,
                              props.data.openStagesGroup,
                              props.data.groupedStageToStage[stageThen]
                            )}

                            {nowStagesMatrix.map(({ nowHalf, nowStages }) => {
                              const nowStagesList = [
                                ...new Set(
                                  nowStages
                                    .sort(
                                      (a, b) =>
                                        props.data.stageGroupSequenceMap[a[3]] -
                                        props.data.stageGroupSequenceMap[b[3]]
                                    )
                                    .map((stage) => `${stage[1]}|${stage[3]}`)
                                ),
                              ].map((stage) => stage.split("|")[1]);
                              return nowStagesList.map((nowStage) => {
                                const currNowStage = nowStage;
                                const key = `${halfThen}|${nowHalf}|${stageThen}|${currNowStage}`;
                                const value = props.data.result[key];

                                let top3Style = {};
                                const top3LookupValue =
                                  props.data.top3Lookup[key];
                                const isTop3 = top3LookupValue !== undefined;
                                if (isTop3) {
                                  top3Style = {
                                    background:
                                      props.data.colorValue[top3LookupValue],
                                  };
                                }
                                if (
                                  value !== undefined &&
                                  value.openInPast === true
                                ) {
                                  top3Style = {
                                    ...top3Style,
                                    background:
                                      props.data.colorValue.openInPast,
                                  };
                                }
                                let cellClass = [`${classes.repNumber}`];
                                if (value) {
                                  cellClass.push(`${classes.drilldownCell}`);
                                }
                                return (
                                  <TableCell
                                    className={cellClass.join(" ")}
                                    style={top3Style}
                                    onClick={() =>
                                      value
                                        ? props.handleChangeLocation(
                                            {
                                              fromStages: [stageThen],
                                              toStages: [currNowStage],
                                            },
                                            {
                                              fromHalves: [halfThen],
                                              toHalves: [nowHalf],
                                            }
                                          )
                                        : null
                                    }
                                  >
                                    {value
                                      ? countOrACVFormatter(
                                          value[metric],
                                          metric === "acv"
                                        )
                                      : ""}
                                  </TableCell>
                                );
                              });
                            })}
                            <TableCell
                              className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                              onClick={() =>
                                props.handleChangeLocation(
                                  {
                                    fromStages: [stageThen],
                                    toStages: props.data.distinctStagesNow,
                                  },
                                  {
                                    fromHalves: [halfThen],
                                    toHalves: nowHalves,
                                  }
                                )
                              }
                            >
                              {totalVal
                                ? countOrACVFormatter(
                                    totalVal[metric],
                                    metric === "acv"
                                  )
                                : ""}
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </>
                  );
                })}
              <StyledTableRow className={classes.fontBold}>
                <TableCell className={`${classes.repText} ${classes.fontBold}`}>
                  Grand Total
                </TableCell>
                {nowStagesMatrix.map(({ nowHalf, nowStages }) => {
                  const nowStagesList = [
                    ...new Set(
                      nowStages
                        .sort(
                          (a, b) =>
                            props.data.stageGroupSequenceMap[a[3]] -
                            props.data.stageGroupSequenceMap[b[3]]
                        )
                        .map((stage) => `${stage[1]}|${stage[3]}`)
                    ),
                  ].map((stage) => stage.split("|")[1]);
                  return nowStagesList.map((nowStage) => {
                    const totalKeyCol = `%|${nowHalf}|*|${nowStage}`;
                    const totalVal = props.data.totalMap[totalKeyCol];
                    const fromStages = props.data.distinctStagesThen.sort(
                      (a, b) =>
                        props.data.stageGroupSequenceMap[a] -
                        props.data.stageGroupSequenceMap[b]
                    );
                    const fromHalves = props.data.distinctHalvesThen.sort(
                      (a, b) => {
                        const yearA = parseInt(a.split("-")[0]);
                        const yearB = parseInt(b.split("-")[0]);
                        const halfA = a.split("-")[1];
                        const halfB = b.split("-")[1];

                        if (yearA !== yearB) {
                          return yearA - yearB;
                        }
                        return halfA.localeCompare(halfB);
                      }
                    );
                    return (
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            {
                              fromStages: fromStages,
                              toStages: [nowStage],
                            },
                            {
                              fromHalves,
                              toHalves: [nowHalf],
                            }
                          )
                        }
                        className={`${classes.repNumber} ${classes.drilldownCell}`}
                      >
                        {totalVal
                          ? countOrACVFormatter(
                              totalVal[metric],
                              metric === "acv"
                            )
                          : ""}
                      </TableCell>
                    );
                  });
                })}
                <TableCell
                  onClick={() =>
                    props.handleChangeLocation(
                      {
                        fromStages: props.data.distinctStagesThen,
                        toStages: props.data.distinctStagesNow,
                      },
                      {
                        fromHalves: props.data.distinctHalvesThen,
                        toHalves: props.data.distinctHalvesNow,
                      }
                    )
                  }
                  className={`${classes.repNumber} ${
                    props.data.distinctStagesThen.length > 0
                      ? classes.drilldownCell
                      : ""
                  }`}
                >
                  {countOrACVFormatter(grandTotal, metric === "acv")}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </React.Fragment>
        </CopyToClipboardTableContainer>
      </Grid>
      <Grid item xs={11} container style={{ margin: "1rem" }}>
        <Legend square={15} legends={legends} legendOpacity={0.9} type="BS" />
      </Grid>
      {/* </Card> */}
    </Grid>
  );
};

export default CurrentStageOfQualifiedPipelineTable;
