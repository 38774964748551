import initialState from "./initialState"
// const initialState =  {
//   ScoreCardData:''
// }
const ScoreCardReducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState
  }
  switch (action.type) {
    case 'drilldownOnGraph':
      return {
        ...state,
        location: "drilldownOnGraph"
      }
    case 'set_location_back':
      return {
        ...state,
        location: '',
        YTDScorecardDrilldown: '',
        QTDScorecardDrilldown: '',
        Current_QuarterDrilldown: '',
        Next_QuarterDrilldown:''
      }
    case 'get_ScoreCard_NEW_MQO_Data_request':
      return {
        ...state,
        MQODataCustType: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_MQO_Data_success':
      return {
        ...state,
        MQODataCustType: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_MQO_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',
      }
    case 'get_ScoreCard_NEW_Current_Quarter_Data_request':
      return {
        ...state,
        CurrentQuarterScorecardData: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_Current_Quarter_Data_success':
      return {
        ...state,
        CurrentQuarterScorecardData: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_Current_Quarter_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',
      }
    case 'get_ScoreCard_NEW_New_Qualified_Pipeline_Data_request':
      return {
        ...state,
        NewQualifiedData: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_New_Qualified_Pipeline_Data_success':
      return {
        ...state,
        NewQualifiedData: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_New_Qualified_Pipeline_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',
      }
    case 'get_ScoreCard_NEW_Next_Quarter_Data_request':
      return {
        ...state,
        NextQuarterScorecardData: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_Next_Quarter_Data_success':
      return {
        ...state,
        NextQuarterScorecardData: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_Next_Quarter_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_QTD_Data_request':
      return {
        ...state,
        QTDScorecardData: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_QTD_Data_success':
      return {
        ...state,
        QTDScorecardData: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: ''

      }
    case 'get_ScoreCard_NEW_QTD_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_YTD_Data_request':
      return {
        ...state,
        YTDScorecardData: '',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_YTD_Data_success':
      return {
        ...state,
        YTDScorecardData: action.json.message,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',


      }
    case 'get_ScoreCard_NEW_YTD_Data_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_QTD_Drilldown_request':
      return {
        ...state,
        ScorecardDrilldown: '',
        chartName:'',
        filtersForDrilldown:'',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_QTD_Drilldown_success':
      return {
        ...state,
        ScorecardDrilldown: action.json.message,
        chartName:action.json.message.chartName,
        filtersForDrilldown:action.json.message.filtersForDrilldown,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: ''

      }
    case 'get_ScoreCard_NEW_QTD_Drilldown_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',

      }
    case 'get_ScoreCard_NEW_YTD_Drilldown_request':
      return {
        ...state,
        ScorecardDrilldown: '',
        chartName:'',
        filtersForDrilldown:'',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
    case 'get_ScoreCard_NEW_YTD_Drilldown_success':
      return {
        ...state,
        ScorecardDrilldown: action.json.message,
        chartName:action.json.message.chartName,
        filtersForDrilldown:action.json.message.filtersForDrilldown,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Data updated'
        },
        persistentAlert: '',


      }
    case 'get_ScoreCard_NEW_YTD_Drilldown_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error updating data'
        },
        persistentAlert: '',

      }
    case 'reset_scorecard_reducer':
      return initialState

    case `get_ScoreCard_NEW_filters_request`:
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'info',
          message: 'Updating Filters...'
        },
        ScoreCardFilters:'',
      }

    case `get_ScoreCard_NEW_filters_success`:
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'success',
          message: 'Filters updated'
        },
        ScoreCardFilters: { teams: [...action.json.message.map(y => y.Team)] }
      }
    case 'current_selected_filters':
      return {
        ...state,
        currentFilters: action.filters
      }
    case 'get_ScoreCard_NEW_filters_failure':
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: 'error',
          message: 'Error getting filters'
        },
      }

    case 'get_ScoreCard_NEW_data':
      return {
        ...state,
        filters: action.filters,
      }
      case 'get_ScoreCard_NEW_Current_Quarter_Drilldown_request':
      return {
        ...state,
        ScorecardDrilldown: '',
        chartName:'',
        filtersForDrilldown:'',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
      case 'get_ScoreCard_NEW_Current_Quarter_Drilldown_success':
        return {
          ...state,
          ScorecardDrilldown: action.json.message,
          chartName:action.json.message.chartName,
          filtersForDrilldown:action.json.message.filtersForDrilldown,
          alert: {
            id: state.alert.id + 1,
            type: 'success',
            message: 'Data updated'
          },
          persistentAlert: ''
  
        }
      case 'get_ScoreCard_NEW_Current_Quarter_Drilldown_failure':
        return {
          ...state,
          alert: {
            id: state.alert.id + 1,
            type: 'error',
            message: 'Error updating data'
          },
          persistentAlert: '',
  
        }
        case 'get_ScoreCard_NEW_Next_Quarter_Drilldown_request':
      return {
        ...state,
        ScorecardDrilldown: '',
        chartName:'',
        filtersForDrilldown:'',
        persistentAlert: {
          type: 'info',
          message: 'Updating data...'
        }

      }
        case 'get_ScoreCard_NEW_Next_Quarter_Drilldown_success':
          return {
            ...state,
            ScorecardDrilldown: action.json.message,
            chartName:action.json.message.chartName,
            filtersForDrilldown:action.json.message.filtersForDrilldown,
            alert: {
              id: state.alert.id + 1,
              type: 'success',
              message: 'Data updated'
            },
            persistentAlert: ''
    
          }
        case 'get_ScoreCard_NEW_Next_Quarter_Drilldown_failure':
          return {
            ...state,
            alert: {
              id: state.alert.id + 1,
              type: 'error',
              message: 'Error updating data'
            },
            persistentAlert: '',
    
          }
  

    default:
      return state
  }
}
export default ScoreCardReducer