import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

export default function PipelineMovementSummaryTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();

  const stages = props.StageProgressionData.fromStages;
  const toStages = props.StageProgressionData.tostages;
  return (
    <TableContainer style={{ margin: "2rem", width: "80%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <Table
        ref={tableRef}
        size="small"
        aria-label="html table"
        style={{ tableLayout: "auto" }}
        data-test={props.dataTest}
      >
        <TableHead>
          <TableRow
            style={{
              fontWeight: 600,
              background: "#4472c4",
              color: "white",
              paddingLeft: 5,
              height: 60,
            }}
          >
            <TableCell
              rowSpan={2}
              className={classes.colHeader}
              style={{ background: "white", color: "black", minWidth: 120 }}
            >
              Stage
            </TableCell>
            {["From", "To", "Net"].map((stage, index) => (
              <TableCell
                colSpan={2}
                style={
                  index % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 60,
                        maxWidth: 60,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        minWidth: 60,
                        maxWidth: 60,
                      }
                }
                align="center"
                className={classes.colHeader}
              >
                {stage}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {["From", "To", "Net"].map((stage, index) => (
              <>
                <TableCell align="center" className={classes.colHeader}>
                  # of Opps
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {/* ACV at Move */}
                  {`${props.Value_Label} at Move`}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stages.map((stage, idx) => {
            let rowData =
              props.StageProgressionData.pipelineMovementSummaryTable.filter(
                (item) => item.stage === stage
              );
            return (
              <StyledTableRow key={stage}>
                <TableCell
                  style={{ minWidth: 120 }}
                  align="left"
                  className={classes.colHeader}
                >
                  {stage}
                </TableCell>
                {["From", "To", "Net"].map((s, idx) => {
                  let cellData = rowData.filter(
                    (item) => item.stage === stage && item.label === s
                  );
                  cellData =
                    cellData.length === 0 ? { acv: 0, count: 0 } : cellData[0];
                  return (
                    <>
                      {s !== "Net" ? (
                        <>
                          <TableCell
                            align="right"
                            className={classes.drilldownCell}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                s === "From" ? [stage] : stages,
                                s === "From" ? toStages : [stage]
                              )
                            }
                          >
                            {cellData.count == 0
                              ? "-"
                              : countOrACVFormatter(cellData.count, false)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.drilldownCell}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                s === "From" ? [stage] : stages,
                                s === "From" ? toStages : [stage]
                              )
                            }
                          >
                            {cellData.count == 0
                              ? "-"
                              : countOrACVFormatter(cellData.acv, true)}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align="right" className={classes.dataCell}>
                            {cellData.count == 0
                              ? "-"
                              : countOrACVFormatter(cellData.count, false)}
                          </TableCell>
                          <TableCell align="right" className={classes.dataCell}>
                            {cellData.count == 0
                              ? "-"
                              : countOrACVFormatter(cellData.acv, true)}
                          </TableCell>
                        </>
                      )}
                    </>
                  );
                })}
              </StyledTableRow>
            );
          })}
          <StyledTableRow>
            <TableCell
              style={{ minWidth: 120 }}
              align="left"
              className={classes.colHeader}
            >
              Grand Total
            </TableCell>
            {["From", "To", "Net"].map((s, idx) => {
              let cellData =
                props.StageProgressionData.pipelineMovementSummaryTable.filter(
                  (item) => item.stage === "Total" && item.label === s
                );
              cellData =
                cellData.length === 0 ? { acv: 0, count: 0 } : cellData[0];
              return (
                <>
                  {s !== "Net" ? (
                    <>
                      <TableCell
                        align="right"
                        className={classes.drilldownCell}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            stages,
                            toStages
                          )
                        }
                      >
                        {cellData.count == 0
                          ? "-"
                          : countOrACVFormatter(cellData.count, false)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.drilldownCell}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            stages,
                            toStages
                          )
                        }
                      >
                        {cellData.count == 0
                          ? "-"
                          : countOrACVFormatter(cellData.acv, true)}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="right" className={classes.dataCell}>
                        {cellData.count == 0
                          ? "-"
                          : countOrACVFormatter(cellData.count, false)}
                      </TableCell>
                      <TableCell align="right" className={classes.dataCell}>
                        {cellData.count == 0
                          ? "-"
                          : countOrACVFormatter(cellData.acv, true)}
                      </TableCell>
                    </>
                  )}
                </>
              );
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
