import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ExpectedAcvDescriptionTable from "../../RepPerformance/ScoreCard/ExpectedAcv/ExpectedAcvDescriptionTable";
import { D3MockupGraphsWaterfall } from "./D3GraphsWaterfall";
import LoadingSkeleton from "./LoadingSkeleton";
import NewWaterfallManagerTabel from "./NewWaterfallManagerTable";
const ExpectedAcvPage = (props) => {
  console.log(props, "props");
  let acvLabel = "ACV";
  if (props.Value_Label) {
    acvLabel = props.Value_Label;
  }
  return (
    <Grid item container justify="flex-start">
      <Grid style={{}} item xs={12} container>
        <Grid item container>
          {props.body.otherDetails !== undefined ? (
            <ExpectedAcvDescriptionTable data={props.body.otherDetails} />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
        <Grid item xs={12}>
          {props.body.oppChangeEvent !== undefined ? (
            <D3MockupGraphsWaterfall data={props.body.oppChangeEvent} />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "-2.5rem" }} item xs={12} container>
        <Grid item xs={12}>
          {props.body.oppChangeEvent !== undefined ? (
            <NewWaterfallManagerTabel
              Value_Label={acvLabel}
              data={props.body.oppChangeEvent}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExpectedAcvPage;
