import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../../EnhancedInsights/NewFunnel/FunnelStyles";
import Loading from "../../components/Loading";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultACV,
  dateFormatterMomentV4,
  getDefaultThreshold,
  getDefaultSalesType,
  countOrACVFormatter,
} from "../../util/customFunctions";
import Drilldown from "../../components/Drilldown/Drilldown";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import DataTable from "./DataTable";
import EnablementTable from "./EnablementTable";
import DrilldownV2 from "../RepCertification/Drilldown";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

class EnablementEffectiveness extends React.Component {
  state = {
    closed_year: ["All"],
    closed_month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    fiscal_quarter: ["All"],
    Cust_Type: ["All"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    custType: ["All"],
    deal_category__c: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: null,
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    toggleValue: "AcvSort",
    secondLevelLocation: null,
    THRESHOLDS_VALUES: 0,
    exclude_large_deals: "Yes",
    lookback_quarter: 2,
    lookahead_quarter: 2,
    offset_days: 0,
    INCLUDE_ALL_CQ_WON: "Yes",
    SeriesName: ["All"],
    ModuleName: ["All"],
    Metric: ["All"],
    drilldownType: "Won ACV",
  };

  // filters = ['closed_year', 'closed_month', 'quarter', 'rep', 'fiscal_quarter', 'team', 'Cust_Type', 'Acct_Industry', 'custType', "Booking_Type_NL_CS_US", 'Acct_Size_Segment','Acct_Segment', 'Lead_Source', 'Opp_Created_By', 'Solution_Cat', 'Region_Rollup','deal_category__c']

  filters = [
    "fiscal_quarter",
    "lookback_quarter",
    "lookahead_quarter",
    "rep",
    "team",
    "Metric",
    "Acct_Industry",
    "Acct_Size_Segment",
    "Acct_Segment",
    "Cust_Type",
    "Acct_Vertical",
    "SeriesName",
    "ModuleName",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  componentDidMount() {
    this.props.getFunnelFilters();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.fiscalQuarters,
        "fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];
      const defaultOffsetDays = this.props.funnelFilters.defaultOffsetDays;
      let days = 0;
      if (defaultOffsetDays) {
        days = defaultOffsetDays;
      }

      this.setState({
        ...prevState,
        fiscal_quarter: defaultQuarter,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Quarter",
            value: defaultQuarter,
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Closed Fiscal Quarter"
          ),
        ],
        activeFilters: [
          { name: "closed_fiscal_quarter", value: defaultQuarter },
        ],
      });
    }
  }

  handleChange = (e) => {
    let noOfDays = this.state.noOfDaysInQUarter;
    let offsetDays = this.state.offset_days;
    if (e.target.name === "fiscal_quarter") {
      const quarters =
        [e.target.value].filter((f) => f !== "All").length > 0
          ? [e.target.value].filter((f) => f !== "All")
          : [];
    }

    if (
      e.target.name === "closed_year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        noOfDaysInQUarter: noOfDays,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        closed_month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : [e.target.value],
      });
    }
  };

  handleChangeFilter = (e) => {
    // console.log(e, "xxyss");
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  handleChangeFilterSM = (e) => {
    // console.log(e, "xxyss");
    let result;

    if (
      this.state.SeriesName.length === 1 &&
      this.state.SeriesName.includes("All")
    ) {
      result =
        e.target.value.filter((v) => v !== "All").length > 0
          ? e.target.value.filter((v) => v !== "All")
          : ["All"];
    } else if (e.target.value.includes("All")) {
      result = ["All"];
    } else {
      result = e.target.value;
    }

    console.log(result, "XXX!23");

    if (result[0] === "All") {
      this.setState({
        ...this.state,
        SeriesName: ["All"],
        ModuleName: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeOffsetDays = (e) => {
    this.setState({
      ...this.state,
      offset_days: e.target.value,
    });
  };

  handleIncludeCQWon = (e) => {
    this.setState({
      ...this.state,
      INCLUDE_ALL_CQ_WON: e.target.value,
    });
  };

  handleChangeLookbackQtr = (e) => {
    this.setState({
      ...this.state,
      lookback_quarter: e.target.value,
    });
  };

  handleChangeLookAheadQtr = (e) => {
    this.setState({
      ...this.state,
      lookahead_quarter: e.target.value,
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    // if (this.state.acv !== null && this.state.acv !== 0) {
    //   filters = [
    //     ...filters,
    //     {
    //         name: 'acv',
    //         value: this.state.acv && typeof (this.state.acv) === 'string' ?

    //           this.state.acv.match(/,/g) ?
    //             this.state.acv.match(/,/g).length === 1 ?
    //               parseInt(this.state.acv) * 1000
    //               :
    //               this.state.acv.match(/,/g).length === 2 ?
    //                 parseInt(this.state.acv) * 1000000
    //                 :
    //                 parseInt(this.state.acv)
    //             :
    //             parseInt(this.state.acv)

    //           : this.state.acv,
    //         string: false
    //       }
    //   ]
    // }
    this.props.getFunnelDataNew(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) =>
                  f !== "closed_year" && f !== "closed_month" && f !== "quarter"
              ),
              "closed_fiscal_quarter",
            ]
          : [
              ...this.state.disabled.filter(
                (f) => f !== "closed_fiscal_quarter"
              ),
              "closed_year",
              "closed_month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, qtr) => {
    if (location !== "overview") {
      var quarter = typeof qtr === "string" ? [qtr] : qtr;
      var drilldownFilters = [
        { title: "Closed Fiscal Quarter", value: quarter },
      ];

      var filters = this.props.currentFilters.filter(
        (s) =>
          ![
            "fiscal_quarter",
            "Metric",
            "lookahead_quarter",
            "lookback_quarter",
            "SeriesName",
            "ModuleName",
          ].includes(s.name)
      );
      filters = [...filters, { name: "cfq", value: quarter }];
      var keyNames = ["Closed Fiscal Quarter"];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        header: "Won Opportunities",
        drildownACVName: "ACV",
        filtersForDrilldown: [...filtersForDrilldown, ...drilldownFilters],
        drilldownType: "Won ACV",
      });
      this.props.getConversionEffecienceyDrilldown(filters, undefined);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationEnablement = (
    location,
    series,
    module,
    startDate,
    endDate
  ) => {
    if (location !== "overview") {
      var ser = typeof series === "string" ? [series] : series;
      var stDate = typeof startDate === "string" ? [startDate] : startDate;
      var edDate = typeof endDate === "string" ? [endDate] : endDate;
      var drilldownFilters = [{ title: "Series", value: ser }];

      drilldownFilters = [
        ...drilldownFilters,
        {
          title: "Module",
          value: typeof module === "string" ? [module] : module,
        },
        { title: "Start Date >=", value: stDate },
        { title: "End Date >=", value: edDate },
      ];

      var filters = this.props.currentFilters.filter((s) =>
        ["rep", "team", "fiscal_quarter"].includes(s.name)
      );
      filters = [
        ...filters,
        { name: "SeriesName", value: ser },
        { name: "startDate", value: stDate },
        { name: "endDate", value: edDate },
      ];
      if (module !== "All") {
        filters = [
          ...filters,
          {
            name: "ModuleName",
            value: typeof module === "string" ? [module] : module,
          },
        ];
      }
      var keyNames = ["Closed Fiscal Quarter"];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        header: "Enablement",
        drildownACVName: "ACV",
        filtersForDrilldown: [...filtersForDrilldown, ...drilldownFilters],
        drilldownType: "Enablement",
      });
      this.props.getEnablementEffectivenessDrilldown(filters);
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.fiscal_quarter.sort(),
        },
      ];
    }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year.sort() },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    // if (!this.state.acv !== null && this.state.acv !== 0)
    //   filtersArray = [...filtersArray, {
    //     title: "ACV Greater Than", value: '$ ' + d3.format(",")(this.state.acv && typeof (this.state.acv) === 'string' ?

    //       this.state.acv.match(/,/g) ?
    //         this.state.acv.match(/,/g).length === 1 ?
    //           parseInt(this.state.acv) * 1000
    //           :
    //           this.state.acv.match(/,/g).length === 2 ?
    //             parseInt(this.state.acv) * 1000000
    //             :
    //             parseInt(this.state.acv)
    //         :
    //         parseInt(this.state.acv)

    //       : this.state.acv)
    //   }]

    if (!this.state.Acct_Industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.Acct_Industry,
        },
      ];
    if (!this.state.Acct_Size_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Industry
              : this.state.Acct_Size_Segment,
        },
      ];
    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region_Rollup
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onThresholdChange = (event, value) => {
    console.log(
      this.state.exclude_large_deals,
      this.state.THRESHOLDS_VALUES,
      value
    );
    this.setState({
      ...this.state,
      THRESHOLDS_VALUES: [null, ""].includes(value)
        ? d3.format(",")(
            String(
              getDefaultThreshold(this.props.funnelFilters.large_deal_threshold)
            )
          )
        : value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
      filtersForDrilldown: [],
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Enablement Effectiveness
          </Typography>
          <Typography variant="body1" align="center">
            Measure the actual impact of enablement on revenue outcomes.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Enablement Effectiveness",
          "Measure the actual impact of enablement on revenue outcomes."
        )}

        {this.props.funnelFilters ? (
          <>
            {/* rep & team */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
            >
              {this.props.funnelFilters !== "" ? (
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.funnelFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      data-test="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChangeFilter}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container component={Paper} justify="center">
                  {" "}
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width={290}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-SeriesName-label"
                      style={{
                        color: !this.state.SeriesName.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Series:
                    </InputLabel>
                    <Select
                      data-test="SeriesName"
                      labelId="select-SeriesName-label"
                      id="select-SeriesName"
                      value={this.state.SeriesName}
                      onChange={this.handleChangeFilterSM}
                      label="Series"
                      name="SeriesName"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.series.map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.SeriesName[0] === "All"}
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.ModuleName.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Modules:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.ModuleName}
                      onChange={this.handleChangeFilter}
                      label="Modules"
                      name="ModuleName"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.moduleNameWithSeries
                          .filter((item) =>
                            this.state.SeriesName.includes(item.SeriesName)
                          )
                          .map((y) => y.ModuleName)
                          .flat()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Enablement Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.fiscal_quarter}
                      onChange={this.handleChange}
                      label="Closed Fiscal Quarter"
                      name="fiscal_quarter"
                    >
                      {/* <MenuItem value={'All'}>All</MenuItem> */}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.fiscalQuarters
                          .filter((item) => item.fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.fiscal_quarter}
                              value={y.fiscal_quarter}
                            >
                              {y.fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              justify="space-between"
              xs={1}
              lg={1}
            >
              <Grid item xs={6} xl={6} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{
                        color: !this.state.Metric.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Metric:
                    </InputLabel>
                    <Select
                      data-test="metric"
                      labelId="select-metric-label"
                      id="select-metric"
                      value={this.state.Metric}
                      onChange={this.handleChangeFilter}
                      label="Metric"
                      name="Metric"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.metrics
                          .filter((item) => item.Metric !== "")
                          .map((y) => (
                            <MenuItem key={y.Metric} value={y.Metric}>
                              {y.Metric}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{ color: "#4472c4" }}
                    >
                      Look back qtrs.:
                    </InputLabel>
                    <Select
                      data-test="lookback_quarter"
                      labelId="select-lookback_quarter-label"
                      id="select-lookback_quarter"
                      value={this.state.lookback_quarter}
                      onChange={this.handleChangeLookbackQtr}
                      label="lookback_quarter"
                      name="lookback_quarter"
                    >
                      {Array(4)
                        .fill(0)
                        .map((_, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{ color: "#4472c4" }}
                    >
                      Look ahead qtrs.:
                    </InputLabel>
                    <Select
                      data-test="lookahead_quarter"
                      labelId="select-lookahead_quarter-label"
                      id="select-lookahead_quarter"
                      value={this.state.lookahead_quarter}
                      onChange={this.handleChangeLookAheadQtr}
                      label="lookahead_quarter"
                      name="lookahead_quarter"
                    >
                      {Array(4)
                        .fill(0)
                        .map((_, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={6}
              md={6}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={4} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{
                        color:
                          this.state.Acct_Vertical[0] !== "All" ||
                          this.state.Acct_Industry[0] !== "All"
                            ? "#4472c4"
                            : "#000",
                      }}
                    >
                      Industry:
                    </InputLabel>
                    <Select
                      data-test="induatry"
                      labelId="select-industry-label"
                      id="select-industry"
                      value={
                        this.props.company === "C0003"
                          ? this.state.Acct_Vertical
                          : this.state.Acct_Industry
                      }
                      onChange={this.handleChangeFilter}
                      label="Industry"
                      name={
                        this.props.company === "C0003"
                          ? "Acct_Vertical"
                          : "Acct_Industry"
                      }
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.industries.map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{
                        color:
                          this.state.Acct_Segment[0] !== "All" ||
                          this.state.Acct_Size_Segment[0] !== "All"
                            ? "#4472c4"
                            : "#000",
                      }}
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      data-test="induatry"
                      labelId="select-industry-label"
                      id="select-industry"
                      value={
                        this.props.company === "C0003"
                          ? this.state.Acct_Segment
                          : this.state.Acct_Size_Segment
                      }
                      onChange={this.handleChangeFilter}
                      label="Customer Segment"
                      name={
                        this.props.company === "C0003"
                          ? "Acct_Segment"
                          : "Acct_Size_Segment"
                      }
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.custSeg.map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-metric-label"
                      style={{
                        color:
                          this.state.Cust_Type[0] !== "All"
                            ? "#4472c4"
                            : "#000",
                      }}
                    >
                      Customer Type:
                    </InputLabel>
                    <Select
                      data-test="Cust_Type"
                      labelId="select-Cust_Type-label"
                      id="select-industry"
                      value={this.state.Cust_Type}
                      onChange={this.handleChangeFilter}
                      label="Customer Type"
                      name={"Cust_Type"}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.custType
                          .filter((f) => f.Cust_Type !== "")
                          .map((y) => (
                            <MenuItem key={y.Cust_Type} value={y.Cust_Type}>
                              {y.Cust_Type}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.grid}
          container
          justify="center"
          style={{ marginTop: "1rem" }}
        >
          <Grid item xs={12} style={{ paddingBottom: "2rem" }}>
            <Card style={{ margin: "auto", width: "100%" }} raised={false}>
              {this.props.funnelData !== "" ? (
                <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                  <Typography
                    variant="h2"
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    Enablement
                  </Typography>
                  <DataTable
                    data={this.props.funnelData.data}
                    handleChangeLocation={this.handleChangeLocationEnablement}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.grid}
          container
          justify="center"
          style={{}}
        >
          <Grid item xs={12} style={{ paddingBottom: "2rem" }}>
            <Card style={{ margin: "auto", width: "100%" }} raised={false}>
              {this.props.funnelData !== "" ? (
                <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                  <Typography
                    variant="h2"
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    Metrics
                  </Typography>
                  <EnablementTable
                    data={this.props.funnelData.metricData}
                    enablementQuarter={this.props.funnelData.enablementQuarter}
                    handleChangeLocation={this.handleChangeLocation}
                    Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              {this.state.drilldownType === "Enablement" ? (
                <DrilldownV2
                  body={this.props.drilldownOnGraphData}
                  showKey={"Certification"}
                  company={this.props.company}
                  header={"CertificationVsPerformance"}
                />
              ) : (
                <Drilldown
                  header={this.state.header}
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  acvName={"ACV"}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              )}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    EnablementEffictivenessData,
    EnablementEffectivenessFilters,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters: EnablementEffectivenessFilters,
    funnelData: EnablementEffictivenessData,
    persistentAlert,
    company: user.company,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_enablement_filters_request" });
    appService.getEnablementEffectivenessFilters().then(
      (json) => {
        dispatch({ type: "get_enablement_filters_success", json });
        dispatch({ type: "get_enablement_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.fiscalQuarters,
          "fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [{ name: "fiscal_quarter", value: defaultFiscalQuarter }];
        }
        filters = [...filters];

        filters = [
          ...filters,
          { name: "lookback_quarter", value: 2 },
          { name: "lookahead_quarter", value: 2 },
        ];

        // const xyAxis=json.message.Dimensions.filter(i=>i.type === 'Ch_Opp_Column_Name')
        // const primary=xyAxis.filter(i=>i.default_axis === 'y')[0].Ch_Opp_Column_Name
        // const secondary=xyAxis.filter(i=>i.default_axis === 'x')[0].Ch_Opp_Column_Name
        dispatch({ type: "current_selected_filters", filters });

        appService.getEnablementEffectivenessData(filters).then(
          (json) => {
            dispatch({ type: "get_enablement_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_enablement_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_enablement_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_enablement_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_enablement_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, primary, secondary) => {
    dispatch({ type: "get_enablement_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getEnablementEffectivenessData(filters).then(
      (json) => {
        dispatch({ type: "get_enablement_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_enablement_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_enablement_data_failure", error });
      }
    );
  },
  getConversionEffecienceyDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getEnablementEffectivenessDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getEnablementEffectivenessDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getEnablementEffectivenessDrilldownv2(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedEnablementEffectiveness = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EnablementEffectiveness));
export { connectedEnablementEffectiveness as EnablementEffectiveness };
