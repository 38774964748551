import { Button, Card, Grid, Paper, Typography, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import React from 'react';
import AttainmentSection from './C0001_AttainmentSection';


const  C0001_Attainmnet = (props) => {
    console.log(props, 'Attainment')
  return     (  
   <Grid component={Card} style={{ padding: '0.5rem 1rem' }} item xs={12} >
              <Typography variant='h2' style={{ width: '100%', marginTop: '1rem' }} align="center">Attainment</Typography>
              <Grid item xs={12} id='grid-container' style={{ margin: '1rem', overflow: 'auto' }}>
                <AttainmentSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"YTD"} tableName={"YTD"} tableHeader={"YTD"} goal={"Annual Goal"} />
              </Grid >
              <Grid item xs={12} id='grid-container' style={{ margin: '1rem', overflow: 'auto' }}>
                <AttainmentSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"CFQ"} tableName={"Current Quarter"} tableHeader={"QTD"} goal={"Goal"} />
              </Grid >
    </Grid>          
);
};



export default C0001_Attainmnet;
