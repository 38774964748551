import { capitalize, ListItemAvatar } from "@material-ui/core";
import * as d3 from "d3";
import { getUniqueValues } from "./customFunctions";

export const funnelWhatIfDataFormatter = (data, filterSelected) => {
  let funnelData;
  if (filterSelected === true) {
    funnelData = data.map((d) => ({
      acv: d.whatifAcv,
      acvRate: d.acvRate,
      count: d.count,
      diffRate: d.diffRate,
      diffacvRate: d.diffacvRate,
      label: d.label,
      rate: d.rate,
      diffacvRateCompany: d.diffacvRateCompany,
      acvCompay: d.acvCompay,

      // diffRateCompany:d.diffRateCompany
      // acv:d.label !== 'Suspect' ? d.acvCompay : d.acv,
      // acvRate:d.label !== 'Suspect' ? d.acvRateCompany : d.acvRate,
      // diffacvRate:d.label !== 'Suspect' ? d.diffacvRateCompany : d.diffacvRate
    }));
  }
  if (filterSelected === false) {
    funnelData = data.map((d) => ({
      acv: d.acv,
      acvRate: d.acvRate,
      count: d.count,
      diffRate: d.diffRate,
      diffacvRate: d.diffacvRate,
      label: d.label,
      rate: d.rate,

      // diffRateCompany:d.diffRateCompany
      // acv:d.label !== 'Suspect' ? d.acvCompay : d.acv,
      // acvRate:d.label !== 'Suspect' ? d.acvRateCompany : d.acvRate,
      // diffacvRate:d.label !== 'Suspect' ? d.diffacvRateCompany : d.diffacvRate
    }));
  }
  console.log(funnelData);
  return funnelData;
};

export const funnelWhatIfDataFormatterFilter = (data) => {
  console.log(data);
  const funnelData = data.map((d) => ({
    acv: d.acv,
    acvRate: d.acvRate,
    count: d.count,
    diffRate: d.diffRate,
    diffacvRate: d.diffacvRate,
    label: d.label,
    rate: d.rate,

    // diffRateCompany:d.diffRateCompany
    // acv:d.label !== 'Suspect' ? d.acvCompay : d.acv,
    // acvRate:d.label !== 'Suspect' ? d.acvRateCompany : d.acvRate,
    // diffacvRate:d.label !== 'Suspect' ? d.diffacvRateCompany : d.diffacvRate
  }));
  console.log(funnelData);
  return funnelData;
};

export const funnelDataFormatter = (data, wonLabel, showTeamData) => {
  console.log(data, wonLabel);
  const funnelData = data.map((d, i) => ({
    ...d,
    lostFromStage:
      d.label !== wonLabel ? (data[i + 1] ? d.acv - data[i + 1].acv : 0) : "-",
    movedToNextStage:
      d.label !== wonLabel ? (data[i + 1] ? data[i + 1].acv : 0) : "-",
    stageToStageConversion:
      d.label !== wonLabel
        ? data[i + 1]
          ? Math.round(data[i + 1].diffacvRate * 100)
          : 0
        : "-",
    winRatePercentage:
      Math.min(...data.map((s) => s.acv)) === 0 || d.acv === 0
        ? 0
        : Math.round((Math.min(...data.map((s) => s.acv)) / d.acv) * 100),
    companyAvgWinRate: showTeamData
      ? Math.min(...data.map((s) => s.acvCompay)) === 0 || d.acvCompay === 0
        ? 0
        : Math.round(
            (Math.min(...data.map((s) => s.acvCompay)) / d.acvCompay) * 100
          )
      : Math.min(...data.map((s) => s.acv)) === 0 || d.acv === 0
      ? 0
      : Math.round((Math.min(...data.map((s) => s.acv)) / d.acv) * 100),
    companyAvgAcv:
      d.label !== wonLabel && showTeamData === true
        ? data[i + 1]
          ? Math.round(data[i + 1].diffacvRateCompany * 100)
          : 0
        : data[i + 1]
        ? Math.round(data[i + 1].diffacvRate * 100)
        : "-",
  }));
  return funnelData;
};
export const formatDataForMekkoChart = (mekkoData) => {
  var formattedData = [];
  var industries = [];
  var properties;
  mekkoData.forEach((item, i) => {
    var temp = {};
    industries.push(item.Vertical);
    properties = Object.keys(item).filter(
      (item) => !(item === "Vertical" || item === "Total")
    );
    var tempArr = properties.map((clust) => ({
      x: item.Vertical,
      y: clust,
      value: Number(item[clust]),
    }));
    formattedData.push(...tempArr);
  });
  return {
    properties,
    formattedData,
    industries,
  };
};
export const formatDataForMekkoChartV2 = (
  mekkoData,
  xAxisValues,
  yAxisValues,
  xyValue
) => {
  let xValue = xyValue.xValue;
  let yValue = xyValue.yValue;
  var formattedData = [];
  console.log(xValue, yValue);
  // var industries = []
  // var properties;
  // industries=mekkoData.map(i=>i.Acct_Industry).filter(getUniqueValues) //x-axis
  // properties=mekkoData.map(i=>i.Acct_Size_Segment).filter(getUniqueValues) //y-axis

  //formattedData.push(...tempArr)
  yAxisValues.forEach((item, i) => {
    var temp = {};
    var tempArr = xAxisValues.map((clust) => ({
      x: item,
      y: clust,
      value: mekkoData
        .filter(
          (i) => i[xyValue.yValue] === item && i[xyValue.xValue] === clust
        )
        .map((a) => a.acv)
        .reduce((a, b) => a + b, 0),
    }));

    formattedData.push(...tempArr);
  });

  return formattedData;
};
export const preFormatMekkoData = (data) => {
  const tableHeaders =
    data !== "" && data !== undefined
      ? Object.keys(data[0])
          .filter((item) => item !== "Acct_Vertical")
          .map((item) => item.split("_")[item.split("_").length - 2])
      : null;
  const verticals = data.map((item) => item.Acct_Vertical);
  const preFormattedData = tableHeaders.map((item) => {
    const temp = data.reduce(
      (obj, it) =>
        Object.assign(obj, {
          [it.Acct_Vertical]: it[`SG_Apps_Cluster_${item}_ACV`],
        }),
      {}
    );
    temp.Vertical = item;
    return temp;
  });
  return preFormattedData;
};
export const formatDataValues = (data, isAcv) => {
  if (isAcv) {
    if (data > 1000) {
      return `$${d3.format(",")(Math.round(data / 1000))}K`;
    } else {
      return `$${d3.format(",")(Math.round(data))}`;
    }
  } else {
    return `${Math.round(data)}%`;
  }
};
export const formatDataValuesNew = (data, type) => {
  if (type === "acv") {
    if (data > 1000) {
      return `$${d3.format(",")(Math.round(data / 1000))}K`;
    } else {
      return `$${d3.format(",")(Math.round(data))}`;
    }
  } else if (type === "percent") {
    return `${Math.round(data)}%`;
  } else if (type === "count") {
    return `${d3.format(",")(Math.round(data))}`;
  }
};
export const formatDataValuesNewForSummary = (data, type) => {
  if (type === "acv") {
    return `$${d3.format(",")(Math.round(data))}`;
  } else if (type === "percent") {
    return `${Math.round(data)}%`;
  } else if (type === "count") {
    return `${d3.format(",")(Math.round(data))}`;
  }
};
export const formatDataValuesonCompany = (data, type, company) => {
  if (data === undefined) {
    return "-";
  } else if (type === "acv") {
    return company === "C0003"
      ? `$${d3.format(",")(Math.round(data / 1000))}K`
      : `$${d3.format(",")(Math.round(data))}`;
  } else if (type === "percent") {
    return `${Math.round(data)}%`;
  } else {
    return `${Math.round(data)}`;
  }
};
export const formatDataValuesonCompanyForCleoNewFunnel = (
  data,
  type,
  company
) => {
  if (data === undefined) {
    return "-";
  } else if (type === "acv") {
    return company === "C0001"
      ? `$${d3.format(",")(Math.round(data / 1000))}K`
      : `$${d3.format(",")(Math.round(data))}`;
  } else if (type === "percent") {
    return `${Math.round(data)}%`;
  } else {
    return `${d3.format(",")(Math.round(data))}`;
  }
};
export const formatSummaryViewData = (data, tableHeaders, isAcv) => {
  const highColors = ["#548235", "#70ad47", "#c6e0b4"];
  const totalAcv = Math.round(
    tableHeaders
      .map((header) =>
        data.map((item) => item[header]).reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0)
  );
  const totalColumn = tableHeaders
    .map((header) => ({
      key: header,
      value: data.map((item) => item[header]).reduce((a, b) => a + b, 0),
    }))
    .reduce((obj, item) => ((obj[item.key] = item.value), obj), {});

  const totalRow = tableHeaders
    .map((header) => ({
      key: header,
      value: data.map((item) => item[header]).reduce((a, b) => a + b, 0),
    }))
    .map((item) => ({
      ...item,
      value: isAcv ? item.value : 100 * (item.value / totalAcv),
    }))
    .reduce((obj, item) => ((obj[item.key] = item.value), obj), {});

  const totalAcvVertical = data.map((item) => ({
    vertical: item.Acct_Vertical,
    value: Math.round(
      Object.keys(item)
        .filter((key) => key !== "Acct_Vertical")
        .map((key) => item[key])
        .reduce((a, b) => a + b, 0)
    ),
  }));
  const percentData = data.map((item) => ({
    ...item,
    SG_Apps_Cluster_Audit_ACV:
      (item.SG_Apps_Cluster_Audit_ACV / totalColumn.SG_Apps_Cluster_Audit_ACV) *
      100,
    SG_Apps_Cluster_Compliance_ACV:
      (item.SG_Apps_Cluster_Compliance_ACV /
        totalColumn.SG_Apps_Cluster_Compliance_ACV) *
      100,
    SG_Apps_Cluster_Cyber_ACV:
      (item.SG_Apps_Cluster_Cyber_ACV / totalColumn.SG_Apps_Cluster_Cyber_ACV) *
      100,
    SG_Apps_Cluster_Platform_ACV:
      (item.SG_Apps_Cluster_Platform_ACV /
        totalColumn.SG_Apps_Cluster_Platform_ACV) *
      100,
    SG_Apps_Cluster_Others_ACV:
      (item.SG_Apps_Cluster_Others_ACV /
        totalColumn.SG_Apps_Cluster_Others_ACV) *
      100,
    SG_Apps_Cluster_Risk_ACV:
      (item.SG_Apps_Cluster_Risk_ACV / totalColumn.SG_Apps_Cluster_Risk_ACV) *
      100,
    SG_Apps_Cluster_TPM_ACV:
      (item.SG_Apps_Cluster_TPM_ACV / totalColumn.SG_Apps_Cluster_TPM_ACV) *
      100,
  }));
  let allRows = [];
  data.map((item) => {
    allRows = [
      ...allRows,
      ...Object.keys(item)
        .filter((key) => key !== "Acct_Vertical")
        .map((it) => ({
          vertical: item.Acct_Vertical,
          key: it,
          value: item[it],
        })),
    ];
  });
  const highestValues = allRows
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));
  const highestValuesByPercent = totalAcvVertical
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));

  const highestValuesByPercentRow = tableHeaders
    .map((header) => ({
      key: header,
      value: data.map((item) => item[header]).reduce((a, b) => a + b, 0),
    }))
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));
  return isAcv
    ? {
        PBISummaryData: data,
        totalAcvVertical: totalAcvVertical.reduce(
          (obj, item) => ((obj[item.vertical] = item.value), obj),
          {}
        ),
        totalRow,
        highestValues: highestValues,
        totalAcv,
      }
    : {
        PBISummaryData: percentData,
        totalAcvVertical: totalAcvVertical
          .map((item) => ({ ...item, value: (item.value / totalAcv) * 100 }))
          .reduce((obj, item) => ((obj[item.vertical] = item.value), obj), {}),
        totalRow,
        highestValues: highestValuesByPercent,
        highestValuesByPercentRow,
        totalAcv: (totalAcv / totalAcv) * 100,
      };
};

export const formatSummaryViewDataV2 = (data, tableHeaders, isAcv) => {
  const highColors = ["#548235", "#70ad47", "#c6e0b4"];
  const totalAcv = Math.round(
    tableHeaders
      .map((header) =>
        data
          .filter((item) => item.Acct_Industry === header)
          .reduce((a, b) => a + b.acv, 0)
      )
      .reduce((a, b) => a + b, 0)
  );
  const totalColumn = tableHeaders
    .map((header) => ({
      key: header,
      value: data
        .filter((item) => item.Acct_Industry === header)
        .reduce((a, b) => a + b.acv, 0),
    }))
    .reduce((obj, item) => ((obj[item.key] = item.value), obj), {});

  const totalRow = tableHeaders.map((header) => ({
    Acct_Industry: header,
    acv: data
      .filter((item) => item.Acct_Industry === header)
      .reduce((a, b) => a + b.acv, 0),
    percentage:
      (data
        .filter((item) => item.Acct_Industry === header)
        .reduce((a, b) => a + b.acv, 0) /
        totalAcv) *
      100,
  }));

  const properties = data
    .map((i) => i.Acct_Size_Segment)
    .filter(getUniqueValues);

  const totalAcvVertical = properties.map((i) => ({
    vertical: i,
    value: data
      .filter((item) => item.Acct_Size_Segment === i)
      .reduce((a, b) => a + b.acv, 0),
  }));

  const percentData = data.map((item) => ({
    ...item,
    percentage: (item.acv / totalColumn[item.Acct_Industry]) * 100,
  }));

  const allRows = data.map((item) => ({
    vertical: item.Acct_Size_Segment,
    key: item.Acct_Industry,
    value: item.acv,
  }));

  const highestValues = allRows
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));
  const highestValuesByPercent = totalAcvVertical
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));

  const highestValuesByPercentRow = tableHeaders
    .map((header) => ({
      key: header,
      value: data
        .filter((item) => item.Acct_Industry === header)
        .reduce((a, b) => a + b.acv, 0),
    }))
    .sort((x, y) => (x.value > y.value ? -1 : x.value < y.value ? 1 : 0))
    .slice(0, 3)
    .map((item, i) => ({
      ...item,
      background: highColors[i],
      color: highColors[i] !== "#c6e0b4" ? "white" : "black",
    }));
  return isAcv
    ? {
        PBISummaryData: data,
        totalAcvVertical: totalAcvVertical.reduce(
          (obj, item) => ((obj[item.vertical] = item.value), obj),
          {}
        ),
        totalRow,
        highestValues: highestValues,
        totalAcv,
      }
    : {
        PBISummaryData: percentData,
        totalAcvVertical: totalAcvVertical
          .map((item) => ({ ...item, value: (item.value / totalAcv) * 100 }))
          .reduce((obj, item) => ((obj[item.vertical] = item.value), obj), {}),
        totalRow,
        highestValues: highestValuesByPercent,
        highestValuesByPercentRow,
        totalAcv: (totalAcv / totalAcv) * 100,
      };
};
export const getLineChartFormatedData = (
  data,
  toBeFormated,
  type,
  uniqueFQMoves
) => {
  if (type === "Booking_Type_NL_CS_US") {
    let lineChartData = data;
    console.log(lineChartData);
    let totalRow = data.filter((i) => i[type] === "Total");
    console.log(totalRow);
    return [...lineChartData, ...totalRow];
  } else if (type === "averageDealSize") {
    let [lineChartData, totalRow] = data;
    totalRow = totalRow.map((item) => ({
      ...item,
      Acct_Size_Segment: "Total",
    }));
    return [...lineChartData, ...totalRow];
  }else if (type === "acvRange") {
    let [lineChartData, totalRow] = data;
    totalRow = totalRow.map((item) => ({
      ...item,
      ACV_Range: "Total",
    }));
    return [...lineChartData, ...totalRow];
  } else if (type === "pcdMovedAnalysis") {
    let uniqueQuarters = data
      .map((item) => item["fiscal_quarter"])
      .filter(getUniqueValues)
      .sort();
    var finalData = [];
    let totalData = data.filter(
      (item) => item["qtrsMoved"] !== "Grand Total" && item["qtrsMoved"] >= 1
    );
    if (totalData.length >= 1) {
      finalData = uniqueQuarters.map((item) => ({
        qtrsMoved: ">= 1 Quarter",
        quarter: item,
        count: totalData
          .filter((i) => i["fiscal_quarter"] === item)
          .reduce((a, b) => a + b.opps, 0),
        acv: totalData
          .filter((i) => i["fiscal_quarter"] === item)
          .reduce((a, b) => a + b.acv, 0),
      }));
    }
    // return [...data, ...totalRow]
    return [...finalData];
  } else {
    let [allData, lineChartData, totalRow] = data;

    totalRow = totalRow.map((item) => ({
      ...item,
      Acct_Segment_Rollup: "Total",
    }));
    return [...lineChartData, ...totalRow];
  }
};
export const formatAcv = (acv) => `$${d3.format(",")(Math.round(acv))}`;

export const dataFormatterForDonutChart = (
  donutData,
  type,
  quarter,
  uniqueStages,
  unit
) => {
  const formattedDonutData = uniqueStages.map((stage) => ({
    [unit]: donutData
      .filter((item) => item[type] === stage)
      .map((item) => item[unit])
      .reduce((a, b) => a + b, 0),
    [quarter]: "Total",
    [type]: stage,
  }));
  return formattedDonutData;
};
export const dataFormatterForDonutChartV2 = (
  donutData,
  type,
  quarter,
  uniqueStages,
  unit
) => {
  const q = type === 'qfm' ? 'Qualified_Year_Month' : 'qualified_fiscal_quarter'
  const formattedDonutData = uniqueStages.map((stage) => ({
    [unit]: donutData
      .filter((item) => item[type] === stage)
      .map((item) => item[unit])
      .reduce((a, b) => a + b, 0),
    [quarter]: "Total",
    [type]: stage,
    [q]:
      donutData.filter((item) => item[type] === stage).length >= 1
        ? donutData
            .filter((item) => item[type] === stage)
            .map((s) => s[q])
        : "",
  }));
  return formattedDonutData;
};
export const renewalsDataFormatter = (data, quarter) => {
  const columns = data.map((item) => item[quarter]).filter(getUniqueValues);
  let formattedData = [];
  columns.map((col) => {
    const completedOnTime = {
      [quarter]: col,
      type: "CompletedOnTime",
      label: "Completed On Time",
      CARR: data
        .filter(
          (item) =>
            item[quarter] === col &&
            item.type === "Completed" &&
            item.Renewal_Overdue === "N"
        )
        .map((item) => item.CARR)
        .reduce((a, b) => a + b, 0),
    };
    const completedDelayed = {
      [quarter]: col,
      type: "CompletedDelayed",
      label: "Completed Delayed",
      CARR: data
        .filter(
          (item) =>
            item[quarter] === col &&
            item.type === "Completed" &&
            item.Renewal_Overdue === "Y"
        )
        .map((item) => item.CARR)
        .reduce((a, b) => a + b, 0),
    };
    const open = {
      [quarter]: col,
      type: "open",
      label: "Open",
      CARR: data
        .filter(
          (item) =>
            item[quarter] === col &&
            item.type === "Open" &&
            item.Renewal_Overdue === "N"
        )
        .map((item) => item.CARR)
        .reduce((a, b) => a + b, 0),
    };
    const openOverDue = {
      [quarter]: col,
      type: "openOverDue",
      label: "Open Overdue",
      CARR: data
        .filter(
          (item) =>
            item[quarter] === col &&
            item.type === "Open" &&
            item.Renewal_Overdue === "Y"
        )
        .map((item) => item.CARR)
        .reduce((a, b) => a + b, 0),
    };
    formattedData = [
      ...formattedData,
      completedOnTime,
      completedDelayed,
      open,
      openOverDue,
    ];
  });

  return formattedData;
};
export function camelize(text) {
  return text.replace(
    /^([A-Z])|[\s-_]+(\w)/g,
    function (match, p1, p2, offset) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
    }
  );
}
export function decamelize(str, separator) {
  separator = typeof separator === "undefined" ? " " : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
}
export const deSnake = (str, sep) =>
  str
    .split("_")
    .map((s) => capitalize(s))
    .join(sep === undefined ? " " : sep);
