import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  customDateFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import isThisQuarter from "date-fns/esm/isThisQuarter/index";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      // outline: "0.5px solid lightgrey",
      // outlineOffset: "-1.5px",
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        background: "lightgrey", // Adjust the color as needed
      },
    },
    stickyHeader: {
      position: "sticky",
      top: -1,
      background: "white",
      outline: "0.2px solid lightgrey",
      outlineOffset: "-1.5px",
      zIndex: 5,
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        // background: "lightgrey", // Adjust the color as needed
      },
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      // cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function DataTable(props) {
  const tableRef = React.createRef();

  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  // const [globalExpandArrow, setglobalExpandArrow] = useState(false);
  const [rowPin, setrowPin] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  let globalExpand = [];

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  let countinc = 0;

  const classes = tableStyle();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  return (
    <div
      style={{
        maxWidth: "100%",
        justifyContent: "center",
        padding: "2.5rem",
        paddingTop: 0,
      }}
    >
      <Grid xs={12}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${
                tableContainerRef.current.getBoundingClientRect().width
              }px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
          borderTop: rowPin ? "0.5px solid lightgrey" : "",
          maxHeight: "85vh",
        }}
        id="myTableContainer-Second"
        ref={tableContainerRef}
      >
        {/* {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null} */}
        <Table ref={tableRef} data-test={props.dataTest} id={props.tableID}>
          <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
            <TableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={{
                  minWidth: 150,
                  whiteSpace: "nowrap",
                  background: "white",
                }}
              >
                {/* Team / Rep ↓ / Week Ending → */}
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "2.5px" }}>
                    Team / Rep ↓ / Week Ending →
                  </div>

                  <CustomWidthTooltip
                    title={"Expand All"}
                    placement="top-start"
                    style={{ marginTop: "13rem" }}
                  >
                    <KeyboardArrowDownIcon
                      onClick={() => globalExpandData(true)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                  <CustomWidthTooltip
                    title={"Collapse All"}
                    placement="top-start"
                  >
                    <KeyboardArrowUpIcon
                      onClick={() => globalExpandData(false)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                </div>
              </TableCell>
              {props.columns.map((col, index) => (
                <TableCell
                  colSpan={props.length}
                  style={
                    index % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          minWidth: "7rem",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          minWidth: "7rem",
                        }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {["", null, undefined, "Total"].includes(col)
                    ? col
                    : customDateFormatter(col)}{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((team, i) => {
              globalExpand.push(i);
              const teampData = props.data.filter((c) => c[props.gb] === team);
              const splits = props.splits;

              return (
                <>
                  {" "}
                  <StyledTableRow
                    key={i}
                    className={classes.fontBold}
                    style={{}}
                  >
                    <TableCell
                      // className={classes.colHeader}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.colHeader}`
                          : `${classes.colHeader}`
                      }
                      style={
                        isSticky && countinc % 2 === 1
                          ? { background: "#f5f5f5" }
                          : { background: "#f5f5f5" }
                      }
                      onClick={() => handleExpandClick(i)}
                    >
                      {/* {team} */}
                      <div style={{ display: "flex" }}>
                        {team !== "Grand Totalt" ? (
                          expandedRows.includes(i) ? (
                            <KeyboardArrowUpIcon style={{ color: "gray" }} />
                          ) : (
                            <KeyboardArrowDownIcon style={{ color: "gray" }} />
                          )
                        ) : null}{" "}
                        <div style={{ marginTop: "3px" }}>{team}</div>
                      </div>
                    </TableCell>
                    {props.columns.map((it) => {
                      return (
                        <TableCell
                          align="right"
                          // className={classes.colHeader}
                          className={classes.drilldownCell}
                          style={{}}
                        ></TableCell>
                      );
                    })}
                  </StyledTableRow>
                  {["Goal Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.colHeader} `
                              : `${classes.colHeader} `
                          }
                          // style={
                          //   isSticky && countinc % 2 === 1
                          //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                          //     : { paddingLeft: "25px" }
                          // }
                          style={
                            isSticky && countinc % 2 === 1
                              ? {
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                  whiteSpace: "nowrap",
                                }
                              : {
                                  whiteSpace: "nowrap",
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                }
                          }
                        >
                          {it}
                        </TableCell>
                        {props.columns.map((col) => {
                          countinc = countinc + 1;
                          // console.log(col, teampData.filter(f => f[props.timeframe]), teampData, 'TTTTTT')
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].totalGoal)
                            ) {
                              g = countOrACVFormatter(
                                goal
                                  .map((t) => t.totalGoal)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              style={{ background: "#dae3f3" }}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {expandedRows.includes(i) &&
                    splits.map((rep, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            // style={
                            //   isSticky && countinc % 2 === 1
                            //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                            //     : { paddingLeft: "25px" }
                            // }
                            style={
                              isSticky && i % 2 === 0
                                ? {
                                    background: "#f5f5f5",
                                    paddingLeft: "25px",
                                    whiteSpace: "nowrap",
                                  }
                                : { paddingLeft: "25px", whiteSpace: "nowrap" }
                            }
                          >
                            {rep}
                          </TableCell>
                          {props.columns.map((col) => {
                            countinc = countinc + 1;
                            let goal = teampData.filter(
                              (f) => f[props.timeframe] === col
                            );
                            let g = "-";
                            if (goal.length > 0) {
                              goal = goal[0].goalSplits.filter(
                                (f) => f[props.type] === rep
                              );
                              if (
                                goal.length > 0 &&
                                ![null, undefined, ""].includes(goal[0].Goal)
                              ) {
                                g = countOrACVFormatter(
                                  goal
                                    .map((t) => t.Goal)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                );
                              }
                            }
                            return (
                              <TableCell
                                align="right"
                                // className={classes.colHeader}
                                className={classes.drilldownCell}
                                style={
                                  {
                                    /* add any necessary styles here */
                                  }
                                }
                              >
                                {g}
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  {["Actual Total"].map((it, k) => {
                    countinc = countinc + 1;
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.colHeader} `
                              : `${classes.colHeader} `
                          }
                          // style={
                          //   isSticky && countinc % 2 === 1
                          //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                          //     : { paddingLeft: "25px" }
                          // }
                          style={
                            isSticky && countinc % 2 === 1
                              ? {
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                  whiteSpace: "nowrap",
                                }
                              : {
                                  whiteSpace: "nowrap",
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                }
                          }
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          countinc = countinc + 1;
                          // console.log(col, teampData.filter(f => f[props.timeframe]), teampData, 'TTTTTT')
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let bg = "#dae3f3";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(
                                goal[0].totalActual
                              )
                            ) {
                              g = countOrACVFormatter(
                                goal
                                  .map((t) => t.totalActual)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );

                              const target = goal
                                .map((t) => t.totalGoal)
                                .reduce((a, b) => a + b, 0);
                              const act = goal
                                .map((t) => t.totalActual)
                                .reduce((a, b) => a + b, 0);
                              if (target > 0 && act >= target) {
                                bg = "#E2EFDA";
                              }
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              style={{ background: bg, cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: splits,
                                    cell:
                                      col === "Total"
                                        ? props.columns.filter(
                                            (f) => f !== "Total"
                                          )
                                        : [col],
                                    repOrTeam:
                                      team === "Grand Total"
                                        ? props.rows.filter(
                                            (f) => f !== "Grand Total"
                                          )
                                        : [team],
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {expandedRows.includes(i) &&
                    splits.map((rep, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            // style={
                            //   isSticky && countinc % 2 === 1
                            //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                            //     : { paddingLeft: "25px" }
                            // }
                            style={
                              isSticky && i % 2 === 0
                                ? {
                                    background: "#f5f5f5",
                                    paddingLeft: "25px",
                                    whiteSpace: "nowrap",
                                  }
                                : { paddingLeft: "25px", whiteSpace: "nowrap" }
                            }
                          >
                            {rep}
                          </TableCell>

                          {props.columns.map((col) => {
                            countinc = countinc + 1;
                            let goal = teampData.filter(
                              (f) => f[props.timeframe] === col
                            );
                            let g = "-";
                            let bg = "";
                            let t = goal;
                            if (goal.length > 0) {
                              // console.log(goal, 'TTTTTTTGGGG')
                              goal = goal[0].actualSplits.filter(
                                (f) => f[props.type] === rep
                              );
                              t = t[0].goalSplits.filter(
                                (f) => f[props.type] === rep
                              );
                              if (
                                goal.length > 0 &&
                                ![null, undefined, ""].includes(goal[0].acv)
                              ) {
                                g = countOrACVFormatter(
                                  goal
                                    .map((t) => t.acv)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                );

                                const target = t
                                  .map((t) => t.Goal)
                                  .reduce((a, b) => a + b, 0);
                                const act = goal
                                  .map((t) => t.acv)
                                  .reduce((a, b) => a + b, 0);
                                // console.log(target, act, 'ACTT')
                                if (target > 0 && act >= target) {
                                  bg = "#E2EFDA";
                                }
                              }
                            }
                            return (
                              <TableCell
                                align="right"
                                // className={classes.colHeader}
                                className={classes.drilldownCell}
                                style={{
                                  cursor: "pointer",
                                  background: bg,
                                }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      row: [rep],
                                      cell:
                                        col === "Total"
                                          ? props.columns.filter(
                                              (f) => f !== "Total"
                                            )
                                          : [col],
                                      repOrTeam:
                                        team === "Grand Total"
                                          ? props.rows.filter(
                                              (f) => f !== "Grand Total"
                                            )
                                          : [team],
                                    },
                                    props.type,
                                    props.timeframe,
                                    props.gb
                                  )
                                }
                              >
                                {g}
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  {["Delta (Under -ve / Over +ve) Total"].map((it, k) => {
                    countinc = countinc + 1;
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.colHeader} `
                              : `${classes.colHeader} `
                          }
                          // style={
                          //   isSticky && countinc % 2 === 1
                          //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                          //     : { paddingLeft: "25px" }
                          // }
                          style={
                            isSticky && countinc % 2 === 1
                              ? {
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                  whiteSpace: "nowrap",
                                }
                              : {
                                  whiteSpace: "nowrap",
                                  background: "#dae3f3",
                                  paddingLeft: "25px",
                                }
                          }
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          countinc = countinc + 1;
                          // console.log(col, teampData.filter(f => f[props.timeframe]), teampData, 'TTTTTT')
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let cl = "inherit";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].Delta)
                            ) {
                              g = countOrACVFormatter(
                                goal
                                  .map((t) => t.Delta)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }

                            if (
                              ![null, undefined, ""].includes(goal[0].Delta) &&
                              goal
                                .map((t) => t.Delta)
                                .reduce((a, b) => a + b, 0) < 0
                            ) {
                              cl = "red";
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              style={{ background: "#dae3f3", color: cl }}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {expandedRows.includes(i) &&
                    splits.map((rep, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            // style={
                            //   isSticky && countinc % 2 === 1
                            //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                            //     : { paddingLeft: "25px" }
                            // }
                            style={
                              isSticky && i % 2 === 0
                                ? {
                                    background: "#f5f5f5",
                                    paddingLeft: "25px",
                                    whiteSpace: "nowrap",
                                  }
                                : { paddingLeft: "25px", whiteSpace: "nowrap" }
                            }
                          >
                            {rep}
                          </TableCell>

                          {props.columns.map((col) => {
                            let goal = teampData.filter(
                              (f) => f[props.timeframe] === col
                            );
                            let g = "-";
                            let cl = "inherit";
                            if (goal.length > 0) {
                              goal = goal[0].deltaSplits.filter(
                                (f) => f[props.type] === rep
                              );
                              if (
                                goal.length > 0 &&
                                ![null, undefined, ""].includes(goal[0].Delta)
                              ) {
                                g = countOrACVFormatter(
                                  goal
                                    .map((t) => t.Delta)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                );

                                if (
                                  ![null, undefined, ""].includes(
                                    goal[0].Delta
                                  ) &&
                                  goal
                                    .map((t) => t.Delta)
                                    .reduce((a, b) => a + b, 0) < 0
                                ) {
                                  cl = "red";
                                }
                              }
                            }
                            return (
                              <TableCell
                                align="right"
                                // className={classes.colHeader}
                                className={classes.drilldownCell}
                                style={{
                                  color: cl /* add any necessary styles here */,
                                }}
                              >
                                {g}
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  {team !== "Grand Total" &&
                    [""].map((it, k) => {
                      {
                        console.log("teasData", team);
                      }
                      return (
                        <StyledTableRow key={k} style={{}}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            // style={
                            //   isSticky && countinc % 2 === 1
                            //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                            //     : { paddingLeft: "25px" }
                            // }
                            style={
                              isSticky && countinc % 2 === 1
                                ? {
                                    padding: "18px",
                                    whiteSpace: "nowrap",
                                  }
                                : { padding: "18px" }
                            }
                          ></TableCell>
                          {props.columns.map((col) => {
                            return (
                              <TableCell
                                align="right"
                                // className={classes.colHeader}
                                className={classes.drilldownCell}
                                style={{ padding: "18px" }}
                              ></TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DataTable;
