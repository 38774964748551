import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  Card,
  TableBody,
  TableCell,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "13px 17px 18px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "35px",
    },
  },
}));

const FiltersDD = (props) => {
  console.log(props);
  const classes = useStyle();
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      spacing={4}
      className={classes.root}
    >
      {props.filters.map((item, index) => {
        console.log("item.value", item.value);

        return (
          <Card
            raised
            style={{
              marginBottom: props.count === "2" ? "25px" : "18px",
              padding: "10px 10px 10px",
              marginRight: "40px",
              marginTop: props.count === "2" ? "-25px" : null,
            }}
            key={index}
          >
            <Typography style={{ fontSize: "0.9rem" }} display="inline">
              {item.title + " : "}
            </Typography>
            <Typography variant="body1" display="inline">
              {Array.isArray(item.value)
                ? item.value.length > 1
                  ? item.value.map((i, index) => (index === 0 ? i : ", " + i))
                  : item.value
                : item.value}
            </Typography>
          </Card>
        );
      })}
    </Grid>
  );
};

export default FiltersDD;
