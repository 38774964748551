import React from "react";
import ReactGA from "react-ga";
import NavBar from "../util/NavBar3";
import { Dashboard } from "../Dashboard/_Dashboard";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "./app.service";
import { LoginView } from "../Login/Login";
import { withSnackbar } from "notistack";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  CssBaseline,
} from "@material-ui/core";
//import Cookies from 'universal-cookie'
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { EnhancedInsights } from "../EnhancedInsights/EnhancedInsights";
import { RepPerformance } from "../RepPerformance/RepPerformance";
import { ProductInsights } from "../ProductInsights/ProductInsights";
import { Settings } from "../Settings/Settings";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomerSuccess from "../CustomerSuccess/CustomerSuccess";
import { EnhancedInsightsV2 } from "../EnhancedInsights/EnhancedInsightsV2";
import { Favourites } from "../Favourites/Favourites";
import { RepPerformanceV2 } from "../RepPerformance/RepPerformanceV2";
import { QuotaLetters } from "../GenerateQuotaLetters/GenerateQuotas";
import AutoUpdateAlert from "../components/Containers/AutoUpdateAlert";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "1rem",
      height: "1rem",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.15)",
      borderRadius: "1rem",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "hsl(0deg, 0%, 78%)",
      // outline: '1px solid slategrey',
      borderRadius: 40,
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "hsl(0deg, 0%, 68%)",
      // outline: '1px solid slategrey',
      borderRadius: 40,
    },
    /* Up */
    "::-webkit-scrollbar-button:single-button:vertical:decrement": {
      backgroundColor: "rgba(200, 200, 200,1)",
      // color: 'blue'
      // backgroundColor: 'red',
      // borderBottomLeftRadius: '1rem'
      borderBottomLeftRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
    },
    "::-webkit-scrollbar-button:single-button:vertical:increment": {
      backgroundColor: "rgba(200, 200, 200,1)",
      // color: 'blue'
      // backgroundColor: 'red',
      // borderTopLeftRadius: '1rem'

      borderTopLeftRadius: "0.5rem",
      borderTopRightRadius: "0.5rem",
    },
    "::-webkit-scrollbar-button:single-button:horizontal:decrement": {
      backgroundColor: "rgba(200, 200, 200,1)",
      // color: 'blue'
      // backgroundColor: 'red',
      // borderBottomLeftRadius: '1rem'
      borderRadius: "0.5rem",
    },
    "::-webkit-scrollbar-button:single-button:horizontal:increment": {
      backgroundColor: "rgba(200, 200, 200,1)",
      // color: 'blue'
      // backgroundColor: 'red',
      // borderTopLeftRadius: '1rem'
      borderRadius: "0.5rem",
    },
  },
});
// const cookies = new Cookies();
// cookies.set('jack', 'Loo', { path: '/' });
console.log("GA", process.env.REACT_APP_GA_ID);
ReactGA.initialize(process.env.REACT_APP_GA_ID);
const callback = (list) => {
  list.getEntries().forEach((entry) => {
    console.log(entry, entry.responseStart - entry.requestStart);
    ReactGA.timing({
      category: "Load Performace",
      variable: "Server Latency",
      value: entry.responseStart - entry.requestStart,
    });
  });
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

var observer = new PerformanceObserver(callback);
observer.observe({ entryTypes: ["navigation"] });

class App extends React.Component {
  persistentAlert = null;
  hidden = null;

  state = {
    open: this.props.user.new === 1 ? true : false,
  };

  // storageChange = (event) => {
  //     if (event.key === 'skygeni-user' && document[this.hidden]) {
  //         if (JSON.parse(localStorage.getItem('skygeni-user')) === null) {
  //             this.props.signout()
  //         }
  //         if (JSON.parse(localStorage.getItem('skygeni-user'))) {
  //             this.props.softLogin()
  //         }
  //     }
  // }

  storageChange = (event) => {
    console.log(localStorage.getItem("skygeni-user"));
    if (event.key === "skygeni-user" && document[this.hidden]) {
      if (localStorage.getItem("skygeni-user") === null) {
        this.props.signout();
      }
      if (localStorage.getItem("skygeni-user")) {
        this.props.softLogin();
      }
    }
  };

  componentDidMount = () => {
    console.log("app loaded");

    console.log(this.props.user, "user");
    if (this.props.user === "") {
      this.props.getUser();
    }
    if (this.props.user !== "") {
      //this.props.loadNavItems(this.props.user.userName)
    }
    window.addEventListener(
      "storage",
      (event) => this.storageChange(event),
      false
    );

    if (typeof document.hidden !== "undefined") {
      this.hidden = "hidden";
      this.visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden";
      this.visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden";
      this.visibilityChange = "webkitvisibilitychange";
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    console.log(this.props, "props");
    ReactGA.set({ userId: this.props.user.UserID });
    if (this.props.alert.id !== prevProps.alert.id) {
      console.log(this.props.alert);
      this.props.enqueueSnackbar(
        this.props.alert.message || "Something went wrong",
        {
          variant: this.props.alert.type || "error",
        }
      );
    }
    if (this.props.persistentAlert !== "" && prevProps.persistentAlert === "") {
      this.persistentAlert = this.props.enqueueSnackbar(
        this.props.persistentAlert.message,
        {
          variant: this.props.persistentAlert.type,
          persist: true,
        }
      );
    }
    if (this.props.persistentAlert === "" && prevProps.persistentAlert !== "") {
      this.props.closeSnackbar(this.persistentAlert);
    }

    if (this.props.passwordUpdated !== prevProps.passwordUpdated) {
      if (this.props.passwordUpdated) {
        this.setState({ open: false });
      }
    }
  };

  changePage = (text) => {
    this.props.changePage(text);
  };

  handleClose = () => {
    var userNew = 0;

    this.props.changeUser(userNew);
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ErrorBoundary>
          <CssBaseline />
          <AutoUpdateAlert />
          {console.log(this.props.loggedIn)}
          {this.props.loggedIn ? (
            <NavBar
              currentPage={this.props.currentPage}
              firstName={this.props.user.firstName}
              lastName={this.props.user.lastName}
              changePage={this.changePage}
              signout={this.props.signout}
              title={this.props.title}
              company={this.props.user.company}
            >
              {this.props.currentPage === "Home" && (
                <ErrorBoundary>
                  <Dashboard />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Enhanced Insights" && (
                <ErrorBoundary>
                  {/* <EnhancedInsights company={this.props.user.company} /> */}
                  <EnhancedInsightsV2 company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Favorites" && (
                <ErrorBoundary>
                  {/* <EnhancedInsights company={this.props.user.company} /> */}
                  <Favourites company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Rep Performance" && (
                /* this.props.user.company === 'C0002' && */ <ErrorBoundary>
                  <RepPerformanceV2 company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Product Insights" && (
                /* this.props.user.company === 'C0002' && */ <ErrorBoundary>
                  <ProductInsights company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Customer Success" && (
                /* this.props.user.company === 'C0002' && */ <ErrorBoundary>
                  <CustomerSuccess company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Sales Compensation" && (
                <ErrorBoundary>
                  <QuotaLetters company={this.props.user.company} />
                </ErrorBoundary>
              )}
              {this.props.currentPage === "Settings" && (
                <ErrorBoundary>
                  <Settings />
                </ErrorBoundary>
              )}
            </NavBar>
          ) : (
            <ErrorBoundary>
              <LoginView />
            </ErrorBoundary>
          )}
          <Dialog
            onClose={this.handleClose}
            open={this.state.open}
            aria-labelledby="reset-password"
          >
            <DialogTitle onClose={this.handleClose} id="reset-password">
              Reset your password
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Welcome to the SkyGeni platform. Please change your password to
                proceed.
              </DialogContentText>
              <Typography variant="caption">
                Note: Password must contain at least 8 characters, at least 1
                number, at least 1 special character (*, @, !, #, %, &, ^, ~, $,
                -, _), at least 1 upper case character and at least 1 lower case
                character.
              </Typography>
              <Box mt={2} mb={3}>
                <Formik
                  initialValues={{
                    password: "",
                    confirm: "",
                    visible1: false,
                    visible2: false,
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .max(255)
                      .required("Password is required.")
                      .min(
                        8,
                        "Password is too short - should be a minimum of 8 characters."
                      )
                      .matches(
                        /.*[0-9].*/,
                        "Password must contain at least 1 number."
                      )
                      .matches(
                        /[*@!#%&^~$-_]+/,
                        "Password must contain at least 1 special character."
                      )
                      .matches(
                        /[A-Z]+/,
                        "Password must contain at least 1 upper case character."
                      )
                      .matches(
                        /[a-z]+/,
                        "Password must contain at least 1 lower case character."
                      ),
                    confirm: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      "Passwords must match."
                    ),
                  })}
                  onSubmit={(values) => {
                    this.props.updatePass(values.password);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="New Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        type={values.visible1 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.visible1 ? (
                                <IconButton
                                  onClick={() =>
                                    setFieldValue("visible1", false, false)
                                  }
                                >
                                  {" "}
                                  <OffIcon />{" "}
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    setFieldValue("visible1", true, false)
                                  }
                                >
                                  <ViewIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        error={Boolean(touched.confirm && errors.confirm)}
                        fullWidth
                        helperText={touched.confirm && errors.confirm}
                        label="Confirm New Password"
                        margin="normal"
                        name="confirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirm}
                        variant="outlined"
                        type={values.visible2 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.visible2 ? (
                                <IconButton
                                  onClick={() =>
                                    setFieldValue("visible2", false, false)
                                  }
                                >
                                  {" "}
                                  <OffIcon />{" "}
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    setFieldValue("visible2", true, false)
                                  }
                                >
                                  <ViewIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box mt={2}>
                        <Button
                          color="primary"
                          disabled={
                            (this.props.persistentAlert !== "" &&
                              this.props.persistentAlert.message ===
                                "Updating password...") ||
                            Boolean(touched.password && errors.password) ||
                            Boolean(touched.confirm && errors.confirm)
                          }
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Change Password
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </DialogContent>
          </Dialog>
        </ErrorBoundary>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    currentPage,
    loggedIn,
    persistentAlert,
    alert,
    user,
    passwordUpdated,
  } = state.app;
  console.log(JSON.parse(localStorage.getItem("skygeni-user")), "localstorage");
  return {
    currentPage,
    loggedIn,
    persistentAlert,
    alert,
    user,
    passwordUpdated,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUser: () => {
    dispatch({ type: "get_user_data_request" });
    appService.getUser().then(
      (json) => {
        dispatch({ type: "get_user_data_success", user: json.user });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_user_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },

  loadNavItems: (userName) => {
    dispatch({ type: "get_nav_items_check_request" });
    appService.getNavData(userName).then(
      (json) => {
        dispatch({ type: "get_nav_items_check_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nav_items_check_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_nav_items_check_failure", error });
      }
    );
  },
  changeUser: (userNew) => {
    dispatch({ type: "change_user_new_value", userNew });
  },
  updatePass: (password) => {
    dispatch({ type: "update_pass_request" });
    appService.updatePass(password).then(
      (json) => {
        dispatch({ type: "update_pass_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "update_pass_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "update_pass_failure", error });
      }
    );
  },
  changePage: (text) => {
    console.log("GA ->", text);
    ReactGA.pageview(text);
    dispatch({ type: "change_page", text });
    localStorage.setItem("skygeni-user-page", text);
    localStorage.removeItem("skygeni-user-enhancedPage");
    localStorage.removeItem("skygeni-user-SettingsPage");
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
    localStorage.removeItem("skygeni-user-customerSuccessPage");
    localStorage.removeItem("skygeni-user-quotaGenerationPage");
  },
  signout: () => {
    appService.logout().then(
      (json) => {
        dispatch({ type: "signout", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({ type: "login_failure", error: "Something went wrong" });
        else dispatch({ type: "login_failure", error });
      }
    );
  },

  softLogin: () => {
    dispatch({ type: "soft_login" });
  },
});

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(App)));
export { connectedApp as App };
