import { Card, Typography, Grid, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import MockupTable from "./Table";
import NQPTable from "./NQPTable";
import clsx from "clsx";

const ManagerSummaryDataSection = (props) => {
  useEffect(() => {
    if (![undefined, null, ""].includes(props.data)) {
      let filters = [];
      if (props.data.length > 0) {
        filters = [
          { name: "team", value: props.data.map((s) => s.Display_Name) },
        ];
      }

      const closedFiscalQuarterFilter = [
        { name: "cfq", value: props.currentAndPriorFiscalQuarters },
      ];
      props.getPipelineCoverageData(filters, "CFQ");
      props.getNQPData(filters, "SQL");
      // props.getFunnelDataNew([...closedFiscalQuarterFilter,...filters])
    }
  }, [props.data]);
  return (
    <Grid>
      <Grid item xs={12} style={{ marginTop: "1rem", padding: "1rem" }}>
        <Card raised={true}>
          <CardContent>
            {props.PipelineCoverageCFQ !== "" &&
            props.PipelineCoverageCFQ !== undefined ? (
              props.PipelineCoverageCFQ.filter(
                (d) =>
                  d.high < d.remainingCurrentQuarter &&
                  d.name !== (props.data.length > 0 ? "team" : "company")
              ).length > 0 ? (
                <>
                  <Typography
                    variant="h2"
                    style={{
                      padding: "1rem",
                      textAlign: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    Reps / Teams without enough, high-quality pipeline for the
                    current quarter
                  </Typography>
                  <MockupTable
                    data={props.PipelineCoverageCFQ.filter(
                      (f) => f.name !== "company"
                    )}
                    company={props.company}
                    Value_Label={props.Value_Label}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant="h2"
                    style={{
                      padding: "1rem",
                      textAlign: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    Reps / Teams without enough, high-quality pipeline for the
                    current quarter
                  </Typography>
                  <MockupTable
                    data={props.PipelineCoverageCFQ}
                    company={props.company}
                    colName={props.data.length > 0 ? "team" : "company"}
                    Value_Label={props.Value_Label}
                    text={
                      "All Reps / Teams have enough, high-quality pipeline for the current quarter"
                    }
                  />
                </>
              )
            ) : (
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="100%"
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1rem", padding: "1rem" }}>
        <Card raised={true}>
          <CardContent>
            {props.NQPDataSQL !== "" ? (
              <NQPTable
                trailingFourWeeks={props.NQPDataSQL.trailingFourWeeks}
                trailingFourWeeksAverage={
                  props.NQPDataSQL.trailingFourWeeksAverage
                }
                trailingEightWeeksAverage={
                  props.NQPDataSQL.frequencyChurnedStageChangeColumn ===
                    "Week_Ending" ||
                  props.NQPDataSQL.UILabelLastColumn === "Trailing 4-Month Avg."
                    ? props.NQPDataSQL.trailingEightWeeksAverage
                    : props.NQPDataSQL.trailingFourWeeksAverage
                }
                trailingFourWeeksData={props.NQPDataSQL.trailingFourWeeksData}
                frequencyChurnedStageChangeColumn={
                  props.NQPDataSQL.frequencyChurnedStageChangeColumn
                }
                dataTest={`new-qualified-pipeline-table`}
                traillingFourWeeksDataSegment={
                  props.NQPDataSQL.traillingFourWeeksDataSegment
                }
                traillingFourWeeksSegmentAverage={
                  props.NQPDataSQL.traillingFourWeeksSegmentAverage
                }
                traillingEightWeeksSegmentAverage={
                  props.NQPDataSQL.frequencyChurnedStageChangeColumn ===
                  "Week_Ending"
                    ? props.NQPDataSQL.trailingEightWeeksAverageSegment
                    : props.NQPDataSQL.traillingFourWeeksSegmentAverage
                }
                stageName={props.NQPDataSQL.stageName}
                firstColumn={props.NQPDataSQL.UILabelFirstColumn}
                lastColumn={props.NQPDataSQL.UILabelLastColumn}
                uniqueWeeks={
                  props.NQPDataSQL.frequencyChurnedStageChangeColumn ===
                  "Week_Ending"
                    ? props.NQPDataSQL.trailingEightWeeks
                    : props.NQPDataSQL.trailingFourWeeks
                }
                thresholds={props.NQPDataSQL.nqpThresholds}
                noOfGoalWeeks={props.noOfGoalWeeks}
                colName={props.data.length > 0 ? "Owner_Name" : "Team"}
                Value_Label={props.Value_Label}
              />
            ) : (
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="100%"
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      {/* {
                      props.funnelData !== '' ? <Grid container item xs={12} justify="space-between" style={{paddingTop:'0.5rem'}}>
                       <Grid item xs={12} md={6}>
                              <FunnelTableClosed data={props.funnelData} firstStage={props.funnelData[0].label} wonLabel={props.funnelData[props.funnelData.length-1].label} isAcv={false} type={"count"} company={props.company}  data_test="closed-funnel_acv_table" showTeamData={true} showTotal={true}   /> 
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <FunnelTableClosed  data={props.funnelData} firstStage={props.funnelData[0].label} wonLabel={props.funnelData[props.funnelData.length-1].label} isAcv={true} type={"acv"} company={props.company}  data_test="closed-funnel_acv_table" showTeamData={true} showTotal={true} />  
                        </Grid>
                  </Grid> : <Skeleton variant="react" animation="wave" height={120} width='100%' />

                    } */}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const { PipelineCoverageCFQV2, user, NQPDataSQLV2, funnelData } = state.app;

  return {
    PipelineCoverageCFQ: PipelineCoverageCFQV2.data,
    user,
    NQPDataSQL: NQPDataSQLV2,
    funnelData,
    company: user.company,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPipelineCoverageData: (filters, type) => {
    dispatch({ type: `get_generic_pipeline_coverage_data_${type}_v2_request` });
    appService.getGenericPipelineCoverageData(filters, type).then(
      (json) => {
        dispatch({
          type: `get_generic_pipeline_coverage_data_${type}_v2_success`,
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_generic_pipeline_coverage_data_${type}_v2_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getNQPData: (filters, type) => {
    dispatch({ type: `get_msnqp_data_${type}_v2_request` });
    appService.getSQLCreatedData(filters, type).then(
      (json) => {
        dispatch({ type: `get_msnqp_data_${type}_v2_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_msnqp_data_${type}_v2_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getFunnelDataNew(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
});
const ManagerSummarySection = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerSummaryDataSection);
export default ManagerSummarySection;
