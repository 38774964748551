import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { useState } from "react";

export default function MomentumAnalysisTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const {qualifed, closed, qualifedM, closedM} = props.timeFrames

  let totaindex;

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  var uniqueQuarters = props.data
    .map((s) => s[closed])
    .filter(getUniqueValues)
    .filter((s) => s !== "Total")
    .sort();
  const cfqs = props.data
    .map((s) => s[closed])
    .filter(getUniqueValues)
    .filter((s) => s !== "Total");
  if (uniqueQuarters.length > 1) {
    uniqueQuarters = [...uniqueQuarters, "Total"];
  }
  const uniqueQfqs = qualifedM === 'qfm' ? ["CFM-0", "CFM-1", "CFM-2", "CFM-3", ">=CFM-4"] : ["CFQ-0", "CFQ-1", "CFQ-2", "CFQ-3", ">=CFQ-4"];

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <Grid item xs={12}>
      {isOverflowing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "10px",
            marginRight: "2rem",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <TableContainer
        style={{
          margin: "2rem",
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainer"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}

        {uniqueQuarters.length >= 1 && (
          <Table
            ref={tableRef}
            size="small"
            aria-label="html table"
            style={{ tableLayout: "auto" }}
            data-test={props.dataTest}
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  // className={classes.colHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.colHeader}`
                      : classes.colHeader
                  }
                  rowSpan={2}
                ></TableCell>
                <TableCell
                  className={classes.colHeader}
                  colSpan={uniqueQuarters.length * 4}
                  style={{
                    textAlign: "center",
                    background: "#70AD47",
                    color: "white",
                  }}
                >
                  {qualifedM === 'qfm' ? 'Closed Month' : 'Closed Fiscal Quarter'}
                </TableCell>
              </TableRow>
              <TableRow>
                {uniqueQuarters.map((s, index) => {
                  return (
                    <>
                      <TableCell
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                textAlign: "center",
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                textAlign: "center",
                              }
                        }
                        className={classes.colHeader}
                        colSpan={4}
                      >
                        {s}
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
              <TableRow>
                <TableCell
                  // className={classes.colHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.colHeader}`
                      : classes.colHeader
                  }
                  style={{ textAlign: "center" }}
                >
                  {qualifedM === 'qfm' ? 'Qualified Month' : 'Qualified Fiscal Quarter'}
                </TableCell>
                {uniqueQuarters.map((s) => {
                  return (
                    <>
                      <TableCell
                        style={{ textAlign: "center", minWidth: 50 }}
                        className={classes.colHeader}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", minWidth: 50 }}
                        className={classes.colHeader}
                      >
                        % of Tot.
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", minWidth: 100 }}
                        className={classes.colHeader}
                      >
                        {/* ACV */}
                        {`${props.Value_Label}`}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", minWidth: 50 }}
                        className={classes.colHeader}
                      >
                        % of Tot.
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
              {uniqueQfqs.map((s, i) => {
                totaindex = i + 1;
                return (
                  <StyledTableRow>
                    <TableCell
                      // className={classes.colHeader}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.colHeader}`
                          : classes.colHeader
                      }
                      // className={classes.colHeader}
                      style={
                        isSticky && i % 2 === 1 ? { background: "#f5f5f5" } : {}
                      }
                    >
                      {s}
                    </TableCell>
                    {uniqueQuarters.map((q) => {
                      const rowData = props.data.filter(
                        (i) => i[closed] === q && i[qualifedM] === s
                      );
                      const count = rowData.length >= 1 ? rowData[0].count : 0;
                      const acv = rowData.length >= 1 ? rowData[0].acv : 0;
                      const acvPercentage =
                        rowData.length >= 1 ? rowData[0].perc : 0;
                      const countPercentage =
                        rowData.length >= 1 ? rowData[0].countPerc : 0;
                      var qfqs =
                        rowData.length >= 1
                          ? rowData[0][qualifed]
                          : "";
                      const quarter =
                        rowData.length >= 1
                          ? rowData[0][`${closed}v2`]
                          : cfqs;
                      // console.log(cfqs, qfqs)
                      var qfqsTotal =
                        rowData.length >= 1
                          ? rowData[0][qualifed]
                          : cfqs.map((c) => ({ x: "" })).map((c) => c.x);

                      if (qfqs.length <= 0) {
                        qfqs = "";
                      }
                      return (
                        <>
                          <TableCell
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                q === "Total"
                                  ? {
                                      cell: quarter,
                                      row: qfqsTotal,
                                      rowKey: "rowTotal",
                                    }
                                  : { cell: [q], row: qfqs }
                              )
                            }
                            className={classes.colHeader}
                            style={{ textAlign: "right", cursor: "pointer" }}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                q === "Total"
                                  ? {
                                      cell: quarter,
                                      row: qfqsTotal,
                                      rowKey: "rowTotal",
                                    }
                                  : { cell: [q], row: qfqs }
                              )
                            }
                            className={classes.colHeader}
                            style={{ textAlign: "right", cursor: "pointer" }}
                          >
                            {![null, undefined, ""].includes(countPercentage)
                              ? Math.round(countPercentage * 100)
                              : "0"}
                            %
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                q === "Total"
                                  ? {
                                      cell: quarter,
                                      row: qfqsTotal,
                                      rowKey: "rowTotal",
                                    }
                                  : { cell: [q], row: qfqs }
                              )
                            }
                            className={classes.colHeader}
                            style={{ textAlign: "right", cursor: "pointer" }}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(acv, true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                q === "Total"
                                  ? {
                                      cell: quarter,
                                      row: qfqsTotal,
                                      rowKey: "rowTotal",
                                    }
                                  : { cell: [q], row: qfqs }
                              )
                            }
                            className={classes.colHeader}
                            style={{ textAlign: "right", cursor: "pointer" }}
                          >
                            {![null, undefined, ""].includes(acvPercentage)
                              ? Math.round(acvPercentage * 100)
                              : "0"}
                            %
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
              <StyledTableRow>
                <TableCell
                  // className={classes.colHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.colHeader}`
                      : classes.colHeader
                  }
                  style={
                    isSticky && totaindex % 2 === 1
                      ? { background: "#f5f5f5" }
                      : {}
                  }
                >
                  {"Total"}
                </TableCell>
                {uniqueQuarters.map((q) => {
                  const rowData = props.totalRow.filter(
                    (i) => i[closed] === q
                  );
                  const count = rowData.length >= 1 ? rowData[0].count : 0;
                  const acv = rowData.length >= 1 ? rowData[0].acv : 0;
                  const acvPercentage =
                    rowData.length >= 1 ? rowData[0].perc : 0;
                  const countPercentage =
                    rowData.length >= 1 ? rowData[0].countPerc : 0;
                  return (
                    <>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            q === "Total" ? { cell: cfqs } : { cell: [q] }
                          )
                        }
                        className={classes.colHeader}
                        style={{ textAlign: "right", cursor: "pointer" }}
                      >
                        {![null, undefined, ""].includes(count)
                          ? countOrACVFormatter(count, false)
                          : "-"}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            q === "Total" ? { cell: cfqs } : { cell: [q] }
                          )
                        }
                        className={classes.colHeader}
                        style={{ textAlign: "right", cursor: "pointer" }}
                      >
                        {![null, undefined, ""].includes(countPercentage)
                          ? Math.round(countPercentage * 100)
                          : "0"}
                        %
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            q === "Total" ? { cell: cfqs } : { cell: [q] }
                          )
                        }
                        className={classes.colHeader}
                        style={{ textAlign: "right", cursor: "pointer" }}
                      >
                        {![null, undefined, ""].includes(acv)
                          ? countOrACVFormatter(acv, true)
                          : "-"}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            q === "Total" ? { cell: cfqs } : { cell: [q] }
                          )
                        }
                        className={classes.colHeader}
                        style={{ textAlign: "right", cursor: "pointer" }}
                      >
                        {![null, undefined, ""].includes(acvPercentage)
                          ? Math.round(acvPercentage * 100)
                          : "0"}
                        %
                      </TableCell>
                    </>
                  );
                })}
              </StyledTableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Grid>
  );
}
