import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { color } from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

const useStyle = makeStyles((theme) => ({
  root: {},
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "5%",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  indentName: {
    paddingLeft: 30,
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  stickyColumn: {
    minWidth: 200,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}));
export default function AttainmnetThresholdTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let totalindex;

  const quarter = props.data.filter(
    (item) => item[props.quarters] !== "total" && item[props.quarters] !== null
  );

  const attainmentThreshold = props.Threshold > 0 ? props.Threshold / 100 : 0;
  console.log(attainmentThreshold);

  // const uniqueQuarters = quarter.map(item => item[props.quarters]).filter(getUniqueValues)
  const uniqueQuarters = props.uniqueQuarters;

  const uniqueAttainment = props.data
    .filter((item) => item[props.quarters] === "total")
    .sort((a, b) =>
      b.attainment === a.attainment
        ? b.acv - a.acv
        : b.attainment - a.attainment
    );

  const uniqueTeamAttainment = props.teamData
    .filter((item) => item[props.quarters] === "total")
    .sort((a, b) =>
      b.attainment === a.attainment
        ? b.acv - a.acv
        : b.attainment - a.attainment
    );

  let uniqueOwner = uniqueAttainment
    .map((item) => item.Owner_Name)
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  uniqueOwner =
    props.toggleValue === "PercSort" ? uniqueOwner : uniqueOwner.sort();

  let uniqueTeams = uniqueTeamAttainment
    .map((item) => item[props.groupbyColumn])
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  uniqueTeams =
    props.toggleValue === "PercSort"
      ? uniqueTeams
      : props.logicalTeams
          .filter((e) => uniqueTeams.indexOf(e) !== -1)
          .filter(Boolean);

  // console.log((uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ) ))

  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );
  const ast2 = (x) => (
    <>
      <span style={{ color: "#ed7d31" }}>*</span>&nbsp; <span>{x}</span>{" "}
    </>
  );
  //     const xyz  = uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length &&  owner).filter(x => x !== false)
  //  console.log(xyz)

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-three");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            className={classes.mainTable}
            id="myTableContainer-three"
            ref={tableContainerRef}
            style={{ borderLeft: isSticky ? "0.5px solid lightgrey" : "" }}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="quota-attainment-detail-threshold-table"
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150 }}
                  >
                    {props.quarters === "closed_fiscal_quarter"
                      ? "Closed Fiscal Quarter"
                      : "Closed Fiscal Year"}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length >= 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={4}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {"Rep"}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                        >{`# of Reps >= ${props.Threshold}%`}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {"Total # of QC Reps"}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                        >{`% of Team >= ${props.Threshold}%`}</TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >{`# of Reps >= ${props.Threshold}%`}</TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {"Total # of QC Reps"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >{`% of Team >= ${props.Threshold}%`}</TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueTeams.map((team, i) => {
                  totalindex = i + 1;
                  const teamData = props.teamData;
                  const repData = props.data
                    .filter(
                      (item) =>
                        item[props.groupbyColumn] === team && item.Quota >= 0
                    )
                    .sort((a, b) =>
                      b.attainment === a.attainment
                        ? b.acv - a.acv
                        : b.attainment - a.attainment
                    );
                  // const teamForDrilldown = props.groupby === 'Team' ? team : props.data.filter(item=> item[props.groupbyColumn] === team).map(c => c.team)[0]
                  // const reps = props.data.filter(item =>  item[props.groupbyColumn] === team && (!["team","total"].includes(item.Owner_Name))).sort((a, b) => b.attainment === a.attainment ? b.acv - a.acv : b.attainment - a.attainment).map(item=> item.Owner_Name).filter(getUniqueValues)
                  const totalRepData = props.data
                    .filter(
                      (item) =>
                        item[props.groupbyColumn] === team &&
                        item[props.quarters] === "total" &&
                        item.Quota >= 0
                    )
                    .sort((a, b) =>
                      b.attainment === a.attainment
                        ? b.acv - a.acv
                        : b.attainment - a.attainment
                    );
                  const totalReps = totalRepData.length;
                  const totalrepsAboveThreshold = totalRepData.filter(
                    (item) => item.attainment >= attainmentThreshold
                  ).length;
                  const totalPercTeam =
                    totalReps > 0 ? totalrepsAboveThreshold / totalReps : 0;
                  return (
                    <>
                      {" "}
                      <StyledTableRow key={i}>
                        <TableCell
                          align="left"
                          // className={`${classes.table} ${classes.repHeader}`}
                          className={
                            isSticky
                              ? `${stickyClasses.sticky} ${classes.table} ${classes.repHeader}}`
                              : `${classes.table} ${classes.repHeader}`
                          }
                          style={
                            isSticky && i % 2 === 1
                              ? { background: "#f5f5f5", fontWeight: 600 }
                              : { fontWeight: 600 }
                          }
                          colSpan={1}
                        >
                          {team}
                        </TableCell>

                        {uniqueQuarters.map((qtr, j) => {
                          const repsData = repData.filter(
                            (item) =>
                              item[props.quarters] === qtr &&
                              item[props.groupbyColumn] === team
                          );
                          // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                          const noOfReps = repsData.length;
                          const noOfRepsAboveThreshold = repsData.filter(
                            (item) => item.attainment >= attainmentThreshold
                          ).length;
                          const percOfteams =
                            repsData.length > 0
                              ? noOfRepsAboveThreshold / noOfReps
                              : 0;
                          const acvData = teamData
                            .filter(
                              (item) =>
                                item[props.quarters] === qtr &&
                                item[props.groupbyColumn] === team
                            )
                            .map((item) => item.acv)[0];
                          const attainmentData = teamData
                            .filter(
                              (item) =>
                                item[props.quarters] === qtr &&
                                item[props.groupbyColumn] === team
                            )
                            .map((item) => item.attainment)[0];
                          return (
                            <>
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                colSpan={1}
                              >
                                {noOfRepsAboveThreshold === "undefiend" ||
                                noOfRepsAboveThreshold === null ||
                                _isNaN(noOfRepsAboveThreshold)
                                  ? "-"
                                  : countOrACVFormatter(
                                      noOfRepsAboveThreshold,
                                      false
                                    )}{" "}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                colSpan={1}
                              >
                                {noOfReps === "undefiend" ||
                                noOfReps === null ||
                                _isNaN(noOfReps)
                                  ? "-"
                                  : countOrACVFormatter(noOfReps, false)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.repHeader}
                                colSpan={1}
                              >
                                {percOfteams === "undefiend" ||
                                percOfteams === null ||
                                _isNaN(percOfteams)
                                  ? "-"
                                  : Math.round(percOfteams * 100) + "%"}
                              </TableCell>
                            </>
                          );
                        })}

                        {uniqueQuarters.length >= 1 && (
                          <React.Fragment>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {totalrepsAboveThreshold === "undefiend" ||
                              totalrepsAboveThreshold === null
                                ? "-"
                                : countOrACVFormatter(
                                    totalrepsAboveThreshold,
                                    false
                                  )}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {totalReps === "undefiend" || totalReps === null
                                ? "-"
                                : countOrACVFormatter(totalReps, false)}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {totalPercTeam === "undefiend" ||
                              totalPercTeam === null ||
                              _isNaN(totalPercTeam)
                                ? "-"
                                : Math.round(totalPercTeam * 100) + "%"}
                            </TableCell>
                          </React.Fragment>
                        )}
                      </StyledTableRow>
                    </>
                  );
                })}

                {props.groupby === "Team, Ramp Status" && (
                  <>
                    <StyledTableRow className={classes.fontBold}>
                      <TableCell
                        align="left"
                        className={`${classes.repHeader}`}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        Ramped Total
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        // const totalQuota = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Quota).reduce((a, b) => a + b, 0)
                        // const totalActual = props.repData.filter(item => item[props.quarters] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)
                        // const totalAttainment = props.repData.filter(item => item[props.quarters] === cell).map(item => item.attainment).reduce((a, b) => a + b, 0)
                        // const uniqueRep = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Owner_Name)
                        const repsData = props.repData.filter(
                          (item) =>
                            item[props.quarters] === cell &&
                            item.rampStatus === "Ramped" &&
                            item.Quota >= 0
                        );
                        const noOfReps = repsData.length;
                        const noOfRepsAboveThreshold = repsData.filter(
                          (item) => item.attainment >= attainmentThreshold
                        ).length;
                        const percOfteams =
                          repsData.length > 0
                            ? noOfRepsAboveThreshold / noOfReps
                            : 0;
                        // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {noOfRepsAboveThreshold === "undefiend" ||
                              noOfRepsAboveThreshold === null ||
                              _isNaN(noOfRepsAboveThreshold)
                                ? "-"
                                : countOrACVFormatter(
                                    noOfRepsAboveThreshold,
                                    false
                                  )}{" "}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {noOfReps === "undefiend" ||
                              noOfReps === null ||
                              _isNaN(noOfReps)
                                ? "-"
                                : countOrACVFormatter(noOfReps, false)}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {percOfteams === "undefiend" ||
                              percOfteams === null ||
                              _isNaN(percOfteams)
                                ? "-"
                                : Math.round(percOfteams * 100) + "%"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length >= 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {props.repData.filter(
                              (item) =>
                                item[props.quarters] === "total" &&
                                item.rampStatus === "Ramped" &&
                                item.Quota >= 0 &&
                                item.attainment >= attainmentThreshold
                            ).length === undefined
                              ? "-"
                              : countOrACVFormatter(
                                  props.repData.filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.rampStatus === "Ramped" &&
                                      item.Quota >= 0 &&
                                      item.attainment >= attainmentThreshold
                                  ).length,
                                  false
                                )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {props.repData
                              .filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.rampStatus === "Ramped"
                              )
                              .map((item) => item.acv && item.Quota >= 0)
                              .length === undefined
                              ? "-"
                              : countOrACVFormatter(
                                  props.repData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item.rampStatus === "Ramped" &&
                                        item.Quota >= 0
                                    )
                                    .map((item) => item.acv).length,
                                  false
                                )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {_isNaN(
                              props.repData.filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.rampStatus === "Ramped" &&
                                  item.Quota >= 0 &&
                                  item.attainment >= attainmentThreshold
                              ).length /
                                props.repData.filter(
                                  (item) =>
                                    item[props.quarters] === "total" &&
                                    item.rampStatus === "Ramped" &&
                                    item.Quota >= 0
                                ).length
                            )
                              ? 0
                              : Math.round(
                                  (props.repData.filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.rampStatus === "Ramped" &&
                                      item.Quota >= 0 &&
                                      item.attainment >= attainmentThreshold
                                  ).length /
                                    props.repData.filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item.rampStatus === "Ramped" &&
                                        item.Quota >= 0
                                    ).length) *
                                    100
                                )}
                            %
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                    <StyledTableRow className={classes.fontBold}>
                      <TableCell
                        align="left"
                        className={`${classes.repHeader}`}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        Ramping Total
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        // const totalQuota = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Quota).reduce((a, b) => a + b, 0)
                        // const totalActual = props.repData.filter(item => item[props.quarters] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)
                        // const totalAttainment = props.repData.filter(item => item[props.quarters] === cell).map(item => item.attainment).reduce((a, b) => a + b, 0)
                        // const uniqueRep = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Owner_Name)
                        const repsData = props.repData.filter(
                          (item) =>
                            item[props.quarters] === cell &&
                            item.rampStatus === "Ramping" &&
                            item.Quota >= 0
                        );
                        const noOfReps = repsData.length;
                        const noOfRepsAboveThreshold = repsData.filter(
                          (item) => item.attainment >= attainmentThreshold
                        ).length;
                        const percOfteams =
                          repsData.length > 0
                            ? noOfRepsAboveThreshold / noOfReps
                            : 0;
                        // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {noOfRepsAboveThreshold === "undefiend" ||
                              noOfRepsAboveThreshold === null ||
                              _isNaN(noOfRepsAboveThreshold)
                                ? "-"
                                : countOrACVFormatter(
                                    noOfRepsAboveThreshold,
                                    false
                                  )}{" "}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {noOfReps === "undefiend" ||
                              noOfReps === null ||
                              _isNaN(noOfReps)
                                ? "-"
                                : countOrACVFormatter(noOfReps, false)}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {percOfteams === "undefiend" ||
                              percOfteams === null ||
                              _isNaN(percOfteams)
                                ? "-"
                                : Math.round(percOfteams * 100) + "%"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length >= 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {props.repData.filter(
                              (item) =>
                                item[props.quarters] === "total" &&
                                item.rampStatus === "Ramping" &&
                                item.Quota >= 0 &&
                                item.attainment >= attainmentThreshold
                            ).length === undefined
                              ? "-"
                              : countOrACVFormatter(
                                  props.repData.filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.rampStatus === "Ramping" &&
                                      item.Quota >= 0 &&
                                      item.attainment >= attainmentThreshold
                                  ).length,
                                  false
                                )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {props.repData
                              .filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.rampStatus === "Ramping"
                              )
                              .map((item) => item.acv && item.Quota >= 0)
                              .length === undefined
                              ? "-"
                              : countOrACVFormatter(
                                  props.repData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item.rampStatus === "Ramping" &&
                                        item.Quota >= 0
                                    )
                                    .map((item) => item.acv).length,
                                  false
                                )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{ fontWeight: 600 }}
                          >
                            {_isNaN(
                              props.repData.filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.rampStatus === "Ramping" &&
                                  item.Quota >= 0 &&
                                  item.attainment >= attainmentThreshold
                              ).length /
                                props.repData.filter(
                                  (item) =>
                                    item[props.quarters] === "total" &&
                                    item.rampStatus === "Ramping" &&
                                    item.Quota >= 0
                                ).length
                            )
                              ? 0
                              : Math.round(
                                  (props.repData.filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.rampStatus === "Ramping" &&
                                      item.Quota >= 0 &&
                                      item.attainment >= attainmentThreshold
                                  ).length /
                                    props.repData.filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item.rampStatus === "Ramping" &&
                                        item.Quota >= 0
                                    ).length) *
                                    100
                                )}
                            %
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  </>
                )}

                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    style={
                      isSticky && totalindex % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                  >
                    {props.groupby === "Team, Ramp Status"
                      ? "Grand Total"
                      : "Total"}
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    // const totalQuota = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Quota).reduce((a, b) => a + b, 0)
                    // const totalActual = props.repData.filter(item => item[props.quarters] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)
                    // const totalAttainment = props.repData.filter(item => item[props.quarters] === cell).map(item => item.attainment).reduce((a, b) => a + b, 0)
                    // const uniqueRep = props.repData.filter(item => item[props.quarters] === cell).map(item => item.Owner_Name)
                    const repsData = props.repData.filter(
                      (item) => item[props.quarters] === cell && item.Quota >= 0
                    );
                    const noOfReps = repsData.length;
                    const noOfRepsAboveThreshold = repsData.filter(
                      (item) => item.attainment >= attainmentThreshold
                    ).length;
                    const percOfteams =
                      repsData.length > 0
                        ? noOfRepsAboveThreshold / noOfReps
                        : 0;
                    // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {noOfRepsAboveThreshold === "undefiend" ||
                          noOfRepsAboveThreshold === null ||
                          _isNaN(noOfRepsAboveThreshold)
                            ? "-"
                            : countOrACVFormatter(
                                noOfRepsAboveThreshold,
                                false
                              )}{" "}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {noOfReps === "undefiend" ||
                          noOfReps === null ||
                          _isNaN(noOfReps)
                            ? "-"
                            : countOrACVFormatter(noOfReps, false)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {percOfteams === "undefiend" ||
                          percOfteams === null ||
                          _isNaN(percOfteams)
                            ? "-"
                            : Math.round(percOfteams * 100) + "%"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {props.repData.filter(
                          (item) =>
                            item[props.quarters] === "total" &&
                            item.Quota >= 0 &&
                            item.attainment >= attainmentThreshold
                        ).length === undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.repData.filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.Quota >= 0 &&
                                  item.attainment >= attainmentThreshold
                              ).length,
                              false
                            )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {props.repData
                          .filter((item) => item[props.quarters] === "total")
                          .map((item) => item.acv && item.Quota >= 0).length ===
                        undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.repData
                                .filter(
                                  (item) =>
                                    item[props.quarters] === "total" &&
                                    item.Quota >= 0
                                )
                                .map((item) => item.acv).length,
                              false
                            )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {_isNaN(
                          props.repData.filter(
                            (item) =>
                              item[props.quarters] === "total" &&
                              item.Quota >= 0 &&
                              item.attainment >= attainmentThreshold
                          ).length /
                            props.repData.filter(
                              (item) =>
                                item[props.quarters] === "total" &&
                                item.Quota >= 0
                            ).length
                        )
                          ? 0
                          : Math.round(
                              (props.repData.filter(
                                (item) =>
                                  item[props.quarters] === "total" &&
                                  item.Quota >= 0 &&
                                  item.attainment >= attainmentThreshold
                              ).length /
                                props.repData.filter(
                                  (item) =>
                                    item[props.quarters] === "total" &&
                                    item.Quota >= 0
                                ).length) *
                                100
                            )}
                        %
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/*               
                {
                    ((uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ? quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length : 0).reduce((a, b) => a + b, 0)  !== 0 && uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ? quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length : 0).reduce((a, b) => a + b, 0) !== 0) )
                    &&
                    <Grid item container xs={10} justify="flex-start" alignItems="flex-end" >
                        <Typography variant="body2" style={{ paddingLeft: "1rem" }}>
                            <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded from analysis for time periods where no quota data is available.
                        </Typography>
                    </Grid>
                } */}
      </CardContent>
    </div>
  );
}
