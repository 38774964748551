import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {
  customDateFormatter,
  StyledTableRow,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function MQOTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const timeFrames = [
    {
      header: "Last 31 Days",
      dateRange: `(${customDateFormatter(
        props.MQOData.last31days[0].last31days
      )} to ${customDateFormatter(props.MQOData.currentDate[0].today)})`,
    },
    {
      header: "Last 60 Days",
      dateRange: `(${customDateFormatter(
        props.MQOData.last60days[0].last60days
      )} to ${customDateFormatter(props.MQOData.currentDate[0].today)})`,
    },
    {
      header: "Last 90 Days",
      dateRange: `(${customDateFormatter(
        props.MQOData.last90days[0].last90days
      )} to ${customDateFormatter(props.MQOData.currentDate[0].today)})`,
    },
  ];

  const timeDurations = [31, 60, 90].map((item) => `${item}`);
  const typesOfCustomers = props.typesOfCustomers;
  const uniqueValues = props.MQOData["MQOLast31Days"]
    .map(
      (s) =>
        s[
          props.selectedTeam.includes("All") &&
          props.selectedRep.includes("All")
            ? "Team"
            : "Owner_Name"
        ]
    )
    .filter(getUniqueValues)
    .sort();
  const compare = (a, b) => (a >= b ? "#E2EFDA" : null);
  return (
    <div>
      <TableContainer style={{ padding: "1rem", paddingBottom: "3rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ minWidth: 100, height: 50, border: "none" }}
                colSpan={1}
              ></TableCell>
              {timeFrames.map((timeFrame, i) => (
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={5}
                  style={
                    i % 2 === 0
                      ? { background: "#FFE699", color: "Black", width: "100%" }
                      : { background: "#FFF4D1", color: "Black", width: "100%" }
                  }
                >
                  <p>{timeFrame.header}</p>
                  <p>{timeFrame.dateRange}</p>
                </TableCell>
              ))}
            </TableRow>
            <StyledTableRow s>
              <TableCell
                align="center"
                style={{ background: "#FFE699", minWidth: 230 }}
                className={classes.headerCell}
                colSpan={1}
                rowSpan={2}
              >
                MQOs generated
              </TableCell>
              {timeFrames.map((timeFrames, i) => (
                <>
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{
                      background: "#4472C4",
                      color: "white",
                      width: "6.25%",
                      height: 90,
                    }}
                    rowSpan={2}
                  >
                    Total
                  </TableCell>
                  {typesOfCustomers.map((customer) => (
                    <TableCell
                      align="center"
                      className={classes.headerCell}
                      style={{
                        background: "#4472C4",
                        color: "white",
                        width: "7%",
                      }}
                      colSpan={2}
                    >
                      {customer}
                    </TableCell>
                  ))}
                </>
              ))}
            </StyledTableRow>
            <StyledTableRow>
              {timeFrames.map((timeFrames, i) =>
                typesOfCustomers.map((customer) => (
                  <>
                    <TableCell
                      align="center"
                      className={classes.dataCell}
                      colSpan={1}
                      style={{
                        background: "#4472C4",
                        color: "white",
                        width: "6.25%",
                      }}
                    >
                      Goal
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.dataCell}
                      colSpan={1}
                      style={{
                        background: "#4472C4",
                        color: "white",
                        width: "6.25%",
                      }}
                    >
                      Actual
                    </TableCell>
                  </>
                ))
              )}
            </StyledTableRow>

            {!props.isrepScoreCard && (
              <StyledTableRow key={"Total"}>
                <TableCell className={classes.headerCell} align="left">
                  {props.selectedTeam.includes("All") &&
                  props.selectedRep.includes("All")
                    ? "Company"
                    : !props.selectedTeam.includes("All")
                    ? props.selectedTeam.length > 1
                      ? "Teams"
                      : "Team"
                    : "Total"}
                </TableCell>
                {timeDurations.map((timeDuration) => (
                  <>
                    {/* Total Cell */}
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          "Total",
                          undefined,
                          timeDuration
                        )
                      }
                      className={`${classes.dataCell} ${classes.drilldownCell}`}
                      align="right"
                    >
                      {
                        props.MQOData[`segmentLast${timeDuration}Days`].filter(
                          (item) => item.Cust_Type === "Total"
                        )[0].count
                      }
                    </TableCell>
                    {typesOfCustomers.map((custType) => {
                      return (
                        <>
                          {/* Goal Cell */}
                          <TableCell className={classes.dataCell} align="right">
                            {
                              props.MQOData[
                                `segmentLast${timeDuration}Days`
                              ].filter((item) => item.Cust_Type === custType)[0]
                                .goal
                            }
                          </TableCell>
                          {/* Actual Cell */}
                          <TableCell
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                custType,
                                undefined,
                                timeDuration
                              )
                            }
                            className={`${classes.dataCell} ${classes.drilldownCell}`}
                            align="right"
                            style={{
                              background: compare(
                                props.MQOData[
                                  `segmentLast${timeDuration}Days`
                                ].filter(
                                  (item) => item.Cust_Type === custType
                                )[0].count,
                                props.MQOData[
                                  `segmentLast${timeDuration}Days`
                                ].filter(
                                  (item) => item.Cust_Type === custType
                                )[0].goal
                              ),
                            }}
                          >
                            {
                              props.MQOData[
                                `segmentLast${timeDuration}Days`
                              ].filter((item) => item.Cust_Type === custType)[0]
                                .count
                            }
                          </TableCell>
                        </>
                      );
                    })}
                  </>
                ))}
              </StyledTableRow>
            )}
            {uniqueValues.map((rep) => (
              <StyledTableRow key={rep}>
                <TableCell
                  className={classes.headerCell}
                  align="left"
                  style={{ paddingLeft: "1.75rem" }}
                >
                  {rep}
                </TableCell>
                {timeDurations.map((timeDuration) => {
                  const repData = props.MQOData[
                    `MQOLast${timeDuration}Days`
                  ].filter(
                    (item) =>
                      item[
                        props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Team"
                          : "Owner_Name"
                      ] === rep
                  );
                  const total = repData.filter(
                    (item) => item.Cust_Type === "Total"
                  )[0].count;
                  const repLength = repData.filter(
                    (item) => item.Cust_Type === "Total"
                  )[0].repsLength;
                  return (
                    <>
                      {/* Total Cell */}
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            "Total",
                            [rep],
                            timeDuration
                          )
                        }
                        className={`${classes.dataCell} ${classes.drilldownCell}`}
                        align="right"
                      >
                        {total}
                      </TableCell>
                      {typesOfCustomers.map((custType) => {
                        console.log(repData);
                        // let goal=repData.filter(item=>item.Cust_Type === custType).length >=1 ? repData.filter(item=>item.Cust_Type === custType)[0].goal : 0
                        let goal = props.MQOData.mqoGoals.filter(
                          (item) =>
                            item.Category === custType &&
                            item.TimeFrame === timeDuration
                        )[0].Goal;
                        let actual =
                          repData.filter((item) => item.Cust_Type === custType)
                            .length >= 1
                            ? repData.filter(
                                (item) => item.Cust_Type === custType
                              )[0].count
                            : 0;
                        return (
                          <>
                            {/* Goal Cell 31 days*/}
                            <TableCell
                              className={classes.dataCell}
                              align="right"
                            >
                              {goal * repLength}
                            </TableCell>

                            {/* Actual Cell New Customer */}
                            <TableCell
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  custType,
                                  [rep],
                                  timeDuration
                                )
                              }
                              className={`${classes.dataCell} ${classes.drilldownCell}`}
                              align="right"
                              style={{
                                background: compare(actual, goal * repLength),
                              }}
                            >
                              {actual}
                            </TableCell>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
