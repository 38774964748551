import { Card, CardContent, Typography } from "@material-ui/core";
import React, { Component } from "react";

class D3ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    console.log("chart error boundry");
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Card style={{ padding: "1rem" }}>
          <CardContent>
            <Typography variant="h2">Chart Cannot be loaded</Typography>
          </CardContent>
        </Card>
      );
    }

    return this.props.children;
  }
}
export default D3ErrorBoundary;
