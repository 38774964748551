import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
  getTimingArr,
  dateFormatterMoment,
  csvDateFormatter,
} from "../../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { map } from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StickyStyledTableHeader from "../../components/Containers/StickyStyledTableHeader";
import StickyStyledTableRow from "../../components/Containers/StickyStyledTableRow";

const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  parentTableContainer: {
    width: "auto", // Set the width of the parent TableContainer to auto
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PipelineTrendsDetailTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const [rowPin, setrowPin] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [parentWidth, setparentWidth] = useState();

  let globalExpand = [];

  let totalindex = 0;

  let countinc = 0;

  let prevReps = [];
  let prevLength = 0;

  let tempidex = 0;

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  var uniqueDate = props.data
    .map((d) => d[props.date])
    .filter(getUniqueValues)
    .sort((a, b) => (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1));
  const uniqueStages = props.data
    .map((d) => d[props.stage])
    .filter(getUniqueValues);
  const uniqueRep = props.data
    .filter((d) => d[props.owner] !== "total")
    .map((d) => d[props.owner])
    .filter(getUniqueValues);
  const uniqueTeam = props.data.map((d) => d.team).filter(getUniqueValues);
  const uniqueTeams = uniqueTeam.filter((d) => d !== "total");
  const reps = props.data.filter((d) => d.team && d[props.owner] !== "total");
  // const Rep = uniqueTeams.map(team => reps.filter(x => x.team === team).map(x => x[props.owner]))

  const total = props.data.filter(
    (d) => d.team === "total" && d[props.owner] === "total"
  );
  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  console.log("Over_flow", isOverflowing);
  console.log("parentWidth", parentWidth);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {/* {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null} */}
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
              borderTop: rowPin ? "0.5px solid lightgrey" : "",
              maxHeight: "85vh",
            }}
            id="myTableContainer-Second"
            ref={tableContainerRef}
            className={classes.parentTableContainer}
          >
            {/* {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null} */}
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="pipeline-trends-detail-table"
            >
              <StickyStyledTableHeader>
                <StickyStyledTableRow>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150, background: "white" }}
                  >
                    {"Date"}
                  </TableCell>
                  {uniqueDate.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 140,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={2}
                      >
                        {customDateFormatter(i)}
                      </TableCell>
                    );
                  })}

                  {uniqueDate.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </StickyStyledTableRow>

                <StickyStyledTableRow>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{
                      // outline: "lightgrey solid 0.5px",
                      // outlineOffset: "-1.5px",
                      background: "white",
                    }}
                  >
                    {/* {`Team/Rep`} */}
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "2.5px" }}>{`Team/Rep`} </div>

                      <CustomWidthTooltip
                        title={"Expand All"}
                        placement="top-start"
                        style={{ marginTop: "13rem" }}
                      >
                        <KeyboardArrowDownIcon
                          onClick={() => globalExpandData(true)}
                          style={{ color: "gray" }}
                        />
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title={"Collapse All"}
                        placement="top-start"
                      >
                        <KeyboardArrowUpIcon
                          onClick={() => globalExpandData(false)}
                          style={{ color: "gray" }}
                        />
                      </CustomWidthTooltip>
                    </div>
                  </TableCell>
                  {uniqueDate.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60, background: "white" }}
                      >
                        {" "}
                        # of Opps{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90, background: "white" }}
                      >
                        {/* ACV */}
                        {`${props.Value_Label}`}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </StickyStyledTableRow>
              </StickyStyledTableHeader>
              <TableBody>
                {uniqueTeams.map((team, i) => {
                  globalExpand.push(i);
                  countinc = countinc + 1;
                  const teamData = props.data.filter(
                    (item) => item.team === team
                  );
                  const reps = props.data
                    .filter((item) => item.team === team)
                    .map((item) => item[props.owner])
                    .filter(getUniqueValues);

                  // const prevTeam = i === 0 ? team : uniqueTeams[i - 1];
                  // // console.log("prevTeam", prevTeam);
                  // if (i > 0) {
                  //   prevReps.push(
                  //     ...props.data
                  //       .filter((item) => item.team === prevTeam)
                  //       .map((item) => item[props.owner])
                  //       .filter(getUniqueValues)
                  //   );
                  //   prevLength = prevReps.length + i;
                  //   // console.log("if_prevLength", prevLength, "index", i);
                  // } else {
                  //   prevLength = 0;
                  //   // console.log("else_prevLength", prevLength, "index", i);
                  // }

                  // if (i > 0) {
                  //   if (prevLength % 2 === 0) {
                  //     tempidex = 0;
                  //   } else {
                  //     tempidex = 1;
                  //   }
                  // }
                  return (
                    <>
                      <StyledTableRow key={i} style={{ background: "#dae3f3" }}>
                        <TableCell
                          align="left"
                          // className={`${classes.repText} ${classes.fontBold}`}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.repText} ${classes.fontBold}`
                              : `${classes.repText} ${classes.fontBold}`
                          }
                          style={
                            isSticky && tempidex % 2 === 1
                              ? { background: "#dae3f3" }
                              : { background: "#dae3f3" }
                          }
                          colSpan={1}
                          onClick={() => handleExpandClick(i)}
                        >
                          {/* {team} */}
                          <div style={{ display: "flex" }}>
                            {team !== "Grand Total" ? (
                              expandedRows.includes(i) ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "gray" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "gray" }}
                                />
                              )
                            ) : null}
                            <div style={{ marginTop: "3px" }}>{team}</div>
                          </div>
                        </TableCell>
                        {uniqueDate.map((qtr, i) => {
                          const acv = teamData
                            .filter((item) => item[props.date] === qtr)
                            .reduce((a, b) => a + b.acv, 0);
                          const count = teamData
                            .filter((item) => item[props.date] === qtr)
                            .reduce((a, b) => a + b.count, 0);
                          const reps = teamData
                            .filter((item) => item[props.date] === qtr)
                            .map((x) => x[props.owner]);
                          return (
                            <>
                              <TableCell
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      row: reps,
                                      cell: [qtr],
                                      x: "Owner_Name",
                                      y: team,
                                    }
                                  )
                                }
                              >
                                {count === "undefiend" ||
                                count === null ||
                                _isNaN(count)
                                  ? "-"
                                  : count}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      row: reps,
                                      cell: [qtr],
                                      x: "Owner_Name",
                                      y: team,
                                    }
                                  )
                                }
                              >
                                {acv === "undefiend" ||
                                acv === null ||
                                _isNaN(acv)
                                  ? "-"
                                  : countOrACVFormatter(acv, true)}
                              </TableCell>
                            </>
                          );
                        })}
                      </StyledTableRow>

                      {expandedRows.includes(i) &&
                        reps.map((rep, j) => {
                          countinc = countinc + 1;
                          let k =
                            i > 0 ? (prevLength % 2 === 0 ? j : j + 1) : j;
                          return (
                            <StyledTableRow key={i}>
                              <TableCell
                                align="left"
                                // style={{ paddingLeft: "25px" }}
                                // className={`${classes.table}`}
                                className={
                                  isSticky
                                    ? `${classes.sticky} ${classes.table}`
                                    : classes.table
                                }
                                style={
                                  isSticky && countinc % 2 === 0
                                    ? {
                                        background: "#f5f5f5",
                                        paddingLeft: "2.5rem",
                                      }
                                    : { paddingLeft: "2.5rem" }
                                }
                                colSpan={1}
                              >
                                {rep}
                              </TableCell>
                              {uniqueDate.map((qtr, i) => {
                                const acv = teamData
                                  .filter(
                                    (item) =>
                                      item[props.date] === qtr &&
                                      item[props.owner] === rep
                                  )
                                  .reduce((a, b) => a + b.acv, 0);
                                const count = teamData
                                  .filter(
                                    (item) =>
                                      item[props.date] === qtr &&
                                      item[props.owner] === rep
                                  )
                                  .reduce((a, b) => a + b.count, 0);
                                return (
                                  <>
                                    <TableCell
                                      align="left"
                                      className={`${classes.repNumber} ${classes.drilldownCell}`}
                                      colSpan={1}
                                      onClick={() =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          {
                                            row: [rep],
                                            cell: [qtr],
                                            x: "Owner_Name",
                                            y: rep,
                                          }
                                        )
                                      }
                                    >
                                      {count === "undefiend" ||
                                      count === null ||
                                      _isNaN(count)
                                        ? "-"
                                        : count}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={`${classes.repNumber} ${classes.drilldownCell}`}
                                      colSpan={1}
                                      onClick={() =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          {
                                            row: [rep],
                                            cell: [qtr],
                                            x: "Owner_Name",
                                            y: rep,
                                          }
                                        )
                                      }
                                    >
                                      {acv === "undefiend" ||
                                      acv === null ||
                                      _isNaN(acv)
                                        ? "-"
                                        : countOrACVFormatter(acv, true)}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </StyledTableRow>
                          );
                        })}
                    </>
                  );
                })}

                <StyledTableRow
                  key={"Total"}
                  className={classes.fontBold}
                  style={{ background: "#dae3f3" }}
                >
                  <TableCell
                    align="left"
                    // className={`${classes.repText} ${classes.fontBold}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repText} ${classes.fontBold}`
                        : `${classes.repText} ${classes.fontBold}`
                    }
                    // style={
                    //   isSticky && (tempidex + 1) % 2 === 1
                    //     ? { background: "#f5f5f5" }
                    //     : {}
                    // }
                    style={{ background: "#dae3f3" }}
                    colSpan={1}
                  >
                    {"Total"}
                  </TableCell>

                  {total
                    .sort((a, b) =>
                      new Date(a[props.date]).getTime() <
                      new Date(b[props.date]).getTime()
                        ? -1
                        : 1
                    )
                    .map((cell, j) => {
                      const dat = cell[props.date];

                      return (
                        <React.Fragment>
                          {/* {console.log(dat)} */}
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row: uniqueRep,
                                cell: [cell[props.date]],
                                x: "Owner_Name",
                                y: "All",
                              })
                            }
                          >
                            {cell.count === "undefiend" ||
                            cell.count === null ||
                            _isNaN(cell.count)
                              ? "-"
                              : cell.count}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={() =>
                              props.handleChangeLocation("drilldownOnGraph", {
                                row: uniqueRep,
                                cell: [cell[props.date]],
                                x: "Owner_Name",
                                y: "All",
                              })
                            }
                          >
                            {cell.acv === "undefiend" ||
                            cell.acv === null ||
                            _isNaN(cell.acv)
                              ? "-"
                              : countOrACVFormatter(cell.acv, true)}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
