import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import ACVCreatedTable from "./ACVCreatedTable";
import Drilldown from "./Drilldown";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import { customDateFormatter } from "../../util/customFunctions";
import { replaceAll } from "../../components/xlsx-export/LabelCange";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
    padding: "1rem",
  },
});

const classes = styles();

const ACVCreated = (props) => {
  const [location, setlLocation] = useState("");
  const [header, setHeader] = useState("");
  const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
  const [time, setTime] = useState("");

  useEffect(() => {
    console.log(
      props.filters,
      props.filters === undefined || props.filters === null,
      ![undefined, null].includes(props.filters)
    );
    if (![undefined, null, ""].includes(props.filters)) {
      props.getACVCreatedData(props.filters);
    }
  }, [props.filters]);

  const handleChangeLocationBack = () => {
    setlLocation("");
  };
  const handleSecondLevelDrilldown = (location, oppId) => {
    console.log("scorecard_oppID", oppId);
    setlLocation(location);

    props.getOpenOppData(oppId);
  };

  const stepBack = () => {
    setlLocation("");
    // this.props.clearExpectedAcvData();
  };

  const handleChangeLocation = (location, timeFrame, team, key, dateRange) => {
    var filters = [...props.filters];
    var drilldownfilter = [{ title: "Time Frame", value: dateRange }];
    if (team !== undefined) {
      var filter =
        props.selectedTeam.includes("All") && props.selectedRep.includes("All")
          ? { name: "Team", value: team }
          : { name: "Owner_Name", value: team };
      filters = [...filters, filter];
      drilldownfilter = [
        {
          title:
            props.selectedTeam.includes("All") &&
            props.selectedRep.includes("All")
              ? "Team"
              : "Rep",
          value: team.length > 1 ? ["All"] : team,
        },
        ...drilldownfilter,
      ];
    } else {
      drilldownfilter = [
        {
          title:
            (props.selectedTeam.includes("All") &&
              props.selectedRep.includes("All")) ||
            (!props.selectedTeam.includes("All") &&
              props.selectedRep.includes("All"))
              ? "Team"
              : "Rep",
          value:
            !props.selectedTeam.includes("All") &&
            props.selectedRep.includes("All")
              ? props.selectedTeam
              : props.selectedTeam.includes("All") &&
                !props.selectedRep.includes("All")
              ? props.selectedRep
              : ["All"],
        },
        ...drilldownfilter,
      ];
    }
    if (key === "ACVCreated") {
      setHeader("Created");
    } else if (key === "ACVQualified") {
      setHeader("Created and Qualified");
    }
    setTime(timeFrame);
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    props.getACVCreatedDrilldown(filters, timeFrame, key);
  };
  console.log(props);
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.title} */}
              {replaceAll(
                props.ACVCreatedData.title,
                "acv",
                `${props.Value_Label}`
              )}
            </Typography>
            <ACVCreatedTable
              selectedTeam={props.selectedTeam}
              selectedRep={props.selectedRep}
              handleChangeLocation={handleChangeLocation}
              dataTest={props.title}
              ACVCreatedData={props.ACVCreatedData}
              isrepScoreCard={props.isrepScoreCard}
              Value_Label={props.Value_Label}
            />
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>
      {location === "drilldownOnGraph" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={`${header} in the last ${time} days`}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
          >
            <Drilldown
              header={`Created in the last ${time} days`}
              body={props.drilldownOnGraphData}
              handleSecondLevelDrilldown={handleSecondLevelDrilldown}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
      {location === "drilldownOppId" && (
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            width: "100%",
            marginTop: "30px",
          }}
        >
          <SingleLevelDrilldown
            header={"Opportunity Change Events"}
            back={handleChangeLocationBack}
            stepBack={stepBack}
            filters={[]}
          >
            <ExpectedAcvPage body={props.ExpectedAcvData} />
          </SingleLevelDrilldown>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const { ACVCreatedData, user, drilldownOnGraphData, ExpectedAcvData } =
    state.app;

  return {
    ACVCreatedData,
    user,
    drilldownOnGraphData,
    ExpectedAcvData,
    Value_Label: user.Value_Label,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getACVCreatedData: (filters) => {
    dispatch({ type: `get_scorecard_ACVCreated_data_request` });
    appService.getACVCreatedData(filters).then(
      (json) => {
        dispatch({ type: `get_scorecard_ACVCreated_data_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_scorecard_ACVCreated_data_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_scorecard_ACVCreated_data_failure", error });
      }
    );
  },
  getACVCreatedDrilldown: (filters, timeFrame, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getACVCreatedDrilldown(filters, timeFrame, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
});
const ACVCreatedSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(ACVCreated);
export default ACVCreatedSection;
