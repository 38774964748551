import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import SendIcon from "@material-ui/icons/Send";
import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    padding: 0,
    margin: 0,
    background: "white",
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
    // position: "sticky",
  },
  input: {
    flexGrow: 1,
    margin: 0,
    // marginRight: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      margin: 0,
      padding: 0,
      lineHeight: "1.75rem",
    },
    "& .MuiInputBase-multiline": {
      // padding:0,
      margin: 0,
      padding: "5px 10px 2px 10px",
      lineHeight: "1.75rem",
    },
  },
  sendButton: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

const ChatInput = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  const handleSendClick = () => {
    if (message.trim() !== "") {
      // Implement your logic to send the message
      console.log("Sending message:", message);
      props.keyPress({ text: message, botMessage: false });
      setMessage("");
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");
    const sanitizedText = pastedText.replace(/[\r\n]+/g, " "); // Replace carriage returns and newlines with a space
    setMessage((prevMessage) => sanitizedText);
  };

  return (
    <div className={classes.inputContainer}>
      <TextField
        multiline={true}
        autoFocus={true}
        rows={Math.max(
          1,
          Math.ceil(
            message.length /
              (props.convAIConstants.TEXT_INPUT_WIDTH_FORMULA_CONSTANT || 65)
          )
        )} // Adjust the number of rows based on text length
        className={classes.input}
        onPaste={handlePaste} // Add this line
        placeholder="Ask me a question..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSendClick();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SendIcon
                className={classes.sendButton}
                onClick={handleSendClick}
              />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default ChatInput;
