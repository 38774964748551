import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import StyledTableRow from "../../components/StyledTableRow";
import { getUniqueValues } from "../../util/customFunctions";
import { formatAcv } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
    padding: "5px 10px",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "10px 10px",
    color: "white",
    background: "#4472C4",
    width: "7.8%",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
}));
function YTDTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const uniqueQuarters = props.data
    .map((item) => item.Quarter)
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  const uniqueRegions = props.data
    .map((item) => item.Display_Name)
    .filter(getUniqueValues)
    .filter((item) => item !== null);

  return (
    <div style={{ display: "flex" }}>
      <TableContainer className={classes.mainTable} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto", width: "99.8%" }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell
                colSpan={uniqueQuarters.length + 1}
                style={{
                  background: "#70ad47",
                  color: "white",
                  fontWeight: 600,
                }}
                align="center"
              >
                Actual
              </TableCell>
              <TableCell colSpan={1} className={classes.table1}></TableCell>
              {/* <TableCell colSpan={1} className={classes.table1}></TableCell> */}
              <TableCell
                colSpan={uniqueQuarters.length + 1}
                style={{
                  background: "#BF8F00",
                  color: "white",
                  fontWeight: 600,
                }}
                align="center"
              >
                Quota
              </TableCell>
              <TableCell
                colSpan={1}
                className={classes.table1}
                style={{ background: "white" }}
              ></TableCell>
              <TableCell
                colSpan={uniqueQuarters.length + 1}
                style={{ background: "none" }}
                align="center"
              ></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableHeader} align="center">
                Region
              </TableCell>
              {uniqueQuarters.map((qtr) => (
                <TableCell className={classes.tableHeader} align="center">
                  {qtr}
                </TableCell>
              ))}
              <TableCell className={classes.tableHeader} align="center">
                Total FY 21 Win
              </TableCell>
              <TableCell className={classes.table1}></TableCell>
              {uniqueQuarters.map((qtr) => (
                <TableCell className={classes.tableHeader} align="center">
                  {qtr}
                </TableCell>
              ))}
              <TableCell className={classes.tableHeader} align="center">
                Total FY 21 Quota
              </TableCell>
              <TableCell className={classes.table1}></TableCell>
              <TableCell className={classes.tableHeader} align="center">
                % Attainment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueRegions.map((region, i) => (
              <StyledTableRow key={i}>
                <TableCell align="left" className={classes.table} colSpan={1}>
                  {region}
                </TableCell>
                {uniqueQuarters.map((qtr, j) => {
                  const cellData = props.data
                    .filter(
                      (item) =>
                        item.Quarter === qtr && item.Display_Name === region
                    )
                    .map((item) => item.acv)
                    .reduce((a, b) => a + b, 0);
                  return (
                    <TableCell align="right" className={classes.table}>
                      {formatAcv(cellData)}
                    </TableCell>
                  );
                })}
                <TableCell align="right" className={classes.table}>
                  {formatAcv(
                    props.data
                      .filter((item) => item.Display_Name === region)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0)
                  )}
                </TableCell>
                <TableCell className={classes.table1}></TableCell>
                {uniqueQuarters.map((qtr, j) => {
                  const cellData = props.data
                    .filter(
                      (item) =>
                        item.Quarter === qtr && item.Display_Name === region
                    )
                    .map((item) => item.Quota)
                    .reduce((a, b) => a + b, 0);
                  return (
                    <TableCell align="right" className={classes.table}>
                      {formatAcv(cellData)}
                    </TableCell>
                  );
                })}
                <TableCell align="right" className={classes.table}>
                  {formatAcv(
                    props.data
                      .filter((item) => item.Display_Name === region)
                      .map((item) => item.Quota)
                      .reduce((a, b) => a + b, 0)
                  )}
                </TableCell>
                <TableCell className={classes.table1}></TableCell>
                <TableCell align="right" className={classes.table}>
                  {props.data
                    .filter((item) => item.Display_Name === region)
                    .map((item) => item.Quota)
                    .reduce((a, b) => a + b, 0) === null ||
                  props.data
                    .filter((item) => item.Display_Name === region)
                    .map((item) => item.Quota)
                    .reduce((a, b) => a + b, 0) === undefined ||
                  props.data
                    .filter((item) => item.Display_Name === region)
                    .map((item) => item.Quota)
                    .reduce((a, b) => a + b, 0) === 0
                    ? "N/A"
                    : `${
                        Math.round(
                          (props.data
                            .filter((item) => item.Display_Name === region)
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0) /
                            props.data
                              .filter((item) => item.Display_Name === region)
                              .map((item) => item.Quota)
                              .reduce((a, b) => a + b, 0)) *
                            100
                        ) === 0
                          ? "0%"
                          : `${Math.round(
                              (props.data
                                .filter((item) => item.Display_Name === region)
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                props.data
                                  .filter(
                                    (item) => item.Display_Name === region
                                  )
                                  .map((item) => item.Quota)
                                  .reduce((a, b) => a + b, 0)) *
                                100
                            )}%`
                      }`}
                </TableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <TableCell align="left" className={classes.table}>
                Total
              </TableCell>
              {uniqueQuarters.map((qtr) => (
                <TableCell align="right" className={classes.table}>
                  {formatAcv(
                    props.data
                      .filter((item) => item.Quarter === qtr)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0)
                  )}
                </TableCell>
              ))}
              <TableCell align="right" className={classes.table}>
                {formatAcv(
                  props.data.map((item) => item.acv).reduce((a, b) => a + b, 0)
                )}
              </TableCell>
              <TableCell className={classes.table1}></TableCell>
              {uniqueQuarters.map((qtr) => (
                <TableCell align="right" className={classes.table}>
                  {formatAcv(
                    props.data
                      .filter((item) => item.Quarter === qtr)
                      .map((item) => item.Quota)
                      .reduce((a, b) => a + b, 0)
                  )}
                </TableCell>
              ))}
              <TableCell align="right" className={classes.table}>
                {formatAcv(
                  props.data
                    .map((item) => item.Quota)
                    .reduce((a, b) => a + b, 0)
                )}
              </TableCell>
              <TableCell className={classes.table1}></TableCell>
              <TableCell align="right" className={classes.table}>{`${
                Math.round(
                  (props.data
                    .map((item) => item.acv)
                    .reduce((a, b) => a + b, 0) /
                    props.data
                      .map((item) => item.Quota)
                      .reduce((a, b) => a + b, 0)) *
                    100
                ) === 0
                  ? "0%"
                  : `${Math.round(
                      (props.data
                        .map((item) => item.acv)
                        .reduce((a, b) => a + b, 0) /
                        props.data
                          .map((item) => item.Quota)
                          .reduce((a, b) => a + b, 0)) *
                        100
                    )}%`
              }`}</TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default YTDTable;
