import React, { Fragment } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { appService } from '../App/app.service';
import { Paper, Grid, Typography, Button, Card } from '@material-ui/core';
import InsightsCard from '../components/InsightsCard';
import { Bar } from './Bar/Bar';
import { MultiXAxisBarWithMarker } from './MultiXAxisBarWithMarker/MultiXAxisBarWithMarker';
import { ConnectedManagerSummary } from './ManagerSummary/ManagerSummary';
import { PerformanceBySalesStage } from './PerformanceBySalesStage/PerformanceBySalesStage';
import NewQualifiedPipeline from './NewQualifiedPipelineHTML/NewQualifiedPipeline';
import { AsOfDate } from './AsOfDate/AsOfDate';
import { FunnelCompare } from './FunnelCompare/FunnelCompare';
import { RepPerformanceAnalysis } from './RepPerformanceAnalysis/RepPerformanceAnalysis';
import { version } from '../../package.json'
import Alert from '@material-ui/lab/Alert';
import RegionPerformance from './RegionPerformance/RegionPerformance';
import SegmentPerformance from './RegionPerformance/SegmentPerformance';
import TeamAttainment from './TeamAttainment/TeamAttainment'
import { Suspense } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import QuotaAttainment from './QuotaAttainment/QuotaAttainment';
import QuotaAttainmentV2 from './QuotaAttainment/QuotaAttainmentV2';
import RampAnalysis from "./RampAnalysis/RampAnalysis";
import RepBenchmarking from './FunnelCompare/RepBenchmarking';
import ShowPlotly from '../EnhancedInsights/OppotunityPyramid/ShowPlotly';
import RepCertification from './RepCertification/RepCertification';
import {TimeSeriesPrediction}  from './TimeSeriesPrediction/TimeSeriesPrediction';
import { ConversionEfficiencyBySource } from './TimeSeriesPrediction/ConversionEfficiencyBySource';
const C0001_ScoreCard = React.lazy(() => import('./ScoreCard/ScoreCard'));
const C0002_ScoreCard = React.lazy(() => import('./ScoreCard/C0002_ScoreCard'));
const C0004_ScoreCard = React.lazy(() => import('./ScoreCard/C0004_ScoreCard'));
const ScoreCardNew = React.lazy(() => import('./ScoreCard/ScoreCardNew'))
const ScoreCardNew_v2 = React.lazy(() => import('./ScoreCard/Performance_Scorecard_v2'))
const GenericScorecard = React.lazy(() => import('./GenericScorecard/PerformanceScorecard'))

const styles = theme => ({
  dashOuter: {
    display: 'flex',
    flexGrow: 1,
    margin: 'auto'
  },
  dashInner: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10
  }
})


class RepPerformance extends React.Component {

  handleLocationChange = (loc) => {
    this.props.changeRepPerformancePage(loc)
  }

  render() {
    const { classes } = this.props
    const baseURL = 'https://skygeniwebapp.blob.core.windows.net/thumbnails/'
    var charts = [
      {
        title: 'Factors driving top reps',
        component: <InsightsCard header='Factors driving top reps'
          text='Discover what factors are driving the performance of your top reps.'
          image={`${baseURL}factors.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Rep Level Scorecard',
        component: <InsightsCard header='Rep Level Scorecard'
          text='Examine how each rep is doing on the factors that drive the success of your top reps.'
          image={`${baseURL}scorecard.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Manager Summary',
        component: <InsightsCard header='Manager Summary'
          text='Examine how each rep is doing on the factors that drive the success of your top reps.'
          image={`${baseURL}scorecard.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Performance by sales stage',
        component: <InsightsCard header='Performance by sales stage'
          text='Benchmark conversion rates by sales stage for each rep with the cohort of top reps, and spot rep level training needs.'
          image={`${baseURL}benchmark.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'New Qualified pipeline',
        component: <InsightsCard header='New Qualified pipeline'
          text='Track new qualified pipeline creation by week at a rep / team level.'
          image={`${baseURL}New Qual Pipeline.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Rep Performance Analysis',
        component: <InsightsCard header='Rep Performance Analysis'
          text='Deep dive into rep performance stage with this Pareto analysis.'
          image={`${baseURL}Rep Performance.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Rep Performance YTD',
        component: <InsightsCard header='Rep Performance YTD'
          text='Explore the YTD performance for each rep along with the adequacy of the open pipeline.'
          image={`${baseURL}Rep and Region Perf YTD.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Region Performance YTD',
        component: <InsightsCard header='Region Performance YTD'
          text='Explore the YTD performance by region.'
          image={`${baseURL}Rep and Region Perf YTD.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Segment Historical Performance',
        component: <InsightsCard header='Segment Historical Performance'
          text='Explore the historical performance by segment teams.'
          image={`${baseURL}Rep and Region Perf YTD.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Team Attainment',
        component: <InsightsCard header='Team Attainment'
          text='Explore the historical performance by segment teams.'
          image={`${baseURL}Rep and Region Perf YTD.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Rep Benchmarking Analysis',
        component: <InsightsCard header='Rep Benchmarking Analysis'
          text='Benchmark each rep against the cohort of top reps to identify blind spots.'
          image={`${baseURL}Rep Benchmarking.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Performance Scorecard',
        component: <InsightsCard header='Performance Scorecard'
          text='Analyze YTD / QTD performance and adequacy of qualified pipeline'
          image={`${baseURL}Rep Benchmarking.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Quota Attainment Distribution',
        component: <InsightsCard header="Quota Attainment Distribution"
          text="Examine the quota attainment distribution across your entire team."
          image={`${baseURL}Quota Attainment.PNG`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Quota Attainment Distribution V2',
        component: <InsightsCard header="Quota Attainment Distribution V2"
          text="Examine the quota attainment distribution across your entire team."
          image={`${baseURL}Quota Attainment.PNG`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Show Plotly',
        component: <InsightsCard header="Show Plotly"
          text="Analyze the cycle time for Won and Lost opportunities by ACV band"
          image={`${baseURL}Cycle Time Analysis.png`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Performance Scorecard V2',
        component: <InsightsCard header="Performance Scorecard V2"
          text="Analyze the cycle time for Won and Lost opportunities by ACV band"
          image={`${baseURL}Rep Benchmarking.png`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Certification vs. Performance Analysis',
        component: <InsightsCard header="Certification vs. Performance Analysis"
          text="Analyze the impact of certification on performance"
          image={`${baseURL}Rep Benchmarking.png`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Performance Scorecard (RC)',
        component: <InsightsCard header="Performance Scorecard (RC)"
          text="Get a comprehensive view of lagging, leading and emerging performance indicators."
          image={`${baseURL}Rep Benchmarking.png`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Performance Scorecard (New)',
        component: <InsightsCard header="Performance Scorecard (New)"
          text="Get a comprehensive view of lagging, leading and emerging performance indicators."
          image={`${baseURL}Rep Benchmarking.png`}
          changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Time Series based prediction of range of Closed Won',
        component: <InsightsCard header='Time Series based prediction of range of Closed Won'
          text='Time Series based prediction of range of Closed Won.'
          image={`${baseURL}Rep Performance.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: 'Conversion Efficiency by Source',
        component: <InsightsCard header='Conversion Efficiency by Source'
          text='Examine how much of the various sources of qualified pipe were converted to Won in each time frame.'
          image={`${baseURL}Rep Performance.png`} changeLocation={this.handleLocationChange} />
      },
      {
        title: "Ramp Analysis",
        component: (
          <InsightsCard
            header="Ramp Analysis"
            text="Analyze ramp velocity by rep and team."
            image={`${baseURL}Rep Performance.png`}
            changeLocation={this.handleLocationChange}
          />
        ),
      },
    ];
    return (
      <Fragment>

        <div className={classes.dashOuter} style={this.props.location === 'home' ? { maxWidth: 1100 } : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }}>

          {this.props.location === 'home' &&
            <Grid
              container
              spacing={5}
              className={classes.grid}
              wrap='wrap'
              justify='flex-start'
              alignItems='center'
            >
              {
                charts !== undefined && (this.props.charts.map(it => charts.filter(chart => chart.title === it.Chart_Name)[0] !== undefined ? charts.filter(chart => chart.title === it.Chart_Name)[0].component : null))
              }
              {/* {
                charts !== undefined ? charts.filter(item=>this.props.charts.map(chart => chart.Chart_Name).includes(item.title)).map(item=>
                  item.component)
                :'Loading'
              }       */}
            </Grid>
          }
          {this.props.location === 'Rep Level Scorecard' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <MultiXAxisBarWithMarker />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Factors driving top reps' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <Bar />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Performance by sales stage' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <PerformanceBySalesStage />
              </Grid>
            </Grid>
          }
           {this.props.location === 'Manager Summary' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <ConnectedManagerSummary />
              </Grid>
            </Grid>
          }
          {this.props.location === 'New Qualified pipeline' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <NewQualifiedPipeline />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Rep Performance Analysis' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <RepPerformanceAnalysis />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Rep Performance YTD' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <AsOfDate />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Region Performance YTD' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <RegionPerformance />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Rep Benchmarking Analysis' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <RepBenchmarking />
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Performance Scorecard' && ['C0001'].includes(this.props.company)) &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <C0001_ScoreCard />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Performance Scorecard' && ['C0004'].includes(this.props.company)) &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <C0004_ScoreCard />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Performance Scorecard' && ['C0002'].includes(this.props.company)) &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <C0002_ScoreCard />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }

          {(this.props.location === 'Performance Scorecard' && this.props.company === 'C0003') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <ScoreCardNew />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {this.props.location === 'Segment Historical Performance' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <SegmentPerformance />
              </Grid>
            </Grid>
          }
          {this.props.location === 'Show Plotly' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <ShowPlotly />
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Quota Attainment Distribution') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <QuotaAttainment />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Quota Attainment Distribution V2') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <QuotaAttainmentV2 />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Performance Scorecard V2') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <ScoreCardNew_v2 />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Certification vs. Performance Analysis') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    <RepCertification />
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {(this.props.location === 'Performance Scorecard (Generic)') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <GenericScorecard />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
                   {(this.props.location === 'Performance Scorecard (RC)') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <GenericScorecard />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Performance Scorecard (New)') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <GenericScorecard />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Team Attainment') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <TeamAttainment />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Time Series based prediction of range of Closed Won') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <TimeSeriesPrediction />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
           {(this.props.location === 'Conversion Efficiency by Source') &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                {
                  <Suspense fallback={<Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
                    <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                  </Grid>}>
                    {
                      <ConversionEfficiencyBySource />
                    }
                    
                  </Suspense>
                }
              </Grid>
            </Grid>
          }
          {this.props.location === "Ramp Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {
                  <Suspense
                    fallback={
                      <Grid
                        item
                        container
                        component={Card}
                        style={{ margin: "1rem" }}
                        justify="center"
                      >
                        <Skeleton
                          variant="react"
                          animation="wave"
                          height={120}
                          width="90%"
                          style={{ margin: "1rem" }}
                        />
                      </Grid>
                    }
                  >
                    {<RampAnalysis />}
                  </Suspense>
                }
              </Grid>
            </Grid>
          )}
          
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { repPerformancePage, user } = state.app

  return {
    location: repPerformancePage,
    charts: user.elements.filter(item => item.navItem === "Rep Performance")[0].charts.sort((a, b) => a.Sequence - b.Sequence)
  }
}

const mapDispatchToProps = (dispatch) => ({

  logout: () => {
    localStorage.removeItem('skygeni-user')
  },
  changeRepPerformancePage: (loc) => {
    console.log('GA ->', loc)
    ReactGA.pageview(loc)
    dispatch({ type: 'change_repPerformancePage', loc })
    localStorage.setItem('skygeni-user-repPage', loc)
    localStorage.removeItem('skygeni-user-enhancedPage')
    localStorage.removeItem('skygeni-user-productPage')

  }


})


const connectedRepPerformance = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RepPerformance))
export { connectedRepPerformance as RepPerformance }