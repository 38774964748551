import React, { useEffect } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  useHistory,
  Redirect,
} from "react-router";

const ManagerSummaryComponent = () => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem("skygeni-user-page", "Rep Performance");
      await localStorage.setItem("skygeni-user-repPage", "Manager Summary");
      await localStorage.removeItem("skygeni-user-enhancedPage");
      history.push("/");
      window.location.reload();
    })();
  }, []);

  return <div style={{ margin: "1rem auto" }}>Loading...</div>;
};

export default ManagerSummaryComponent;
