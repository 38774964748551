import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import StyledTableRow from "../../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  dateFormatterMomentV2,
} from "../../../util/customFunctions";
import { color, gray } from "d3";
import tableStyle from "../../styles/tableStyle";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";

export default function DataTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let total = 0;

  const quarters = props.uniqueQuarters.filter((i) => i !== "Total");

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "10px",
              marginRight: "2rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          margin: "2rem",
          marginBottom: "1rem",
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainer"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              <TableCell
                align="center"
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                height={40}
                colSpan={1}
                rowSpan={1}
                style={{ width: "15%" }}
              >
                {}
              </TableCell>
              {props.uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    width={70}
                    key={i}
                    align="center"
                    height={40}
                    style={
                      index % 2 === 0
                        ? { background: "#4471c4", color: "white" }
                        : { background: "#5b9bd5", color: "white" }
                    }
                    className={classes.repHeader}
                    colSpan={1}
                  >
                    {i}
                  </TableCell>
                );
              })}
            </TableRow>
            <StyledTableRow
              key={"MajorEnterprise123"}
              className={classes.fontBold}
            >
              <TableCell
                height={40}
                align="left"
                // style={{
                //   border: "0.5px solid lightgrey",
                //   paddingRight: "10px",
                //   paddingLeft: "10px",
                // }}
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                style={
                  isSticky && total % 2 === 0
                    ? {
                        background: "#f5f5f5",
                        border: "0.5px solid lightgrey",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }
                    : {
                        border: "0.5px solid lightgrey",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }
                }
                colSpan={1}
              >
                {props.totalArr.title}
              </TableCell>
              <TableCell
                height={40}
                width={70}
                align="center"
                style={{
                  border: "0.5px solid lightgrey",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
                className={classes.repHeader}
                colSpan={props.uniqueQuarters.length}
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    props.totalArr.title,
                    quarters
                  )
                }
              >
                {[undefined, null, ""].includes(props.totalArr.data[0].acv)
                  ? "-"
                  : countOrACVFormatter(props.totalArr.data[0].acv, true)}
              </TableCell>
              {/* {
                             props.qtr.map(type => 
                                props.cat.map(cat => {
                                    const data = props.data.filter(d => d.team === team && d.Type === type && d.Solution_Cat === cat)
                                    const acv = data.length > 0 ?  data[0][props.colum] : ''
                                    return <TableCell width={70} align='right' style={props.showDrilldown === true ? {border: '0.5px solid lightgrey',paddingRight:'3px', paddingLeft: '3px', cursor: 'pointer'} : {border: '0.5px solid lightgrey',paddingRight:'3px', paddingLeft: '3px'}} className={classes.repHeader} colSpan={1} 
                                    onClick={() => props.showDrilldown === true && props.handleChangeLocation('drilldownOnGraph', type, cat, team) }
                                    >{[undefined, null, ''].includes(acv) ? '-' : props.colum === 'Conversion_Rate' ?  `${acv}%` : countOrACVFormatter(acv, true)}</TableCell>
                                })
                                )
                          } */}
            </StyledTableRow>

            {props.uniqueLabels.map((label, i) => (
              <StyledTableRow
                key={"MajorEnterprise"}
                className={classes.fontBold}
              >
                <TableCell
                  height={40}
                  align="left"
                  // style={{
                  //   border: "0.5px solid lightgrey",
                  //   paddingRight: "10px",
                  //   paddingLeft: "10px",
                  // }}
                  // className={classes.repHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.repHeader}`
                      : classes.repHeader
                  }
                  style={
                    isSticky && i % 2 === 1
                      ? {
                          background: "#f5f5f5",
                          border: "0.5px solid lightgrey",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }
                      : {
                          border: "0.5px solid lightgrey",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }
                  }
                  colSpan={1}
                >
                  {label}
                </TableCell>
                {props.uniqueQuarters.map((qtr) => {
                  const data = props.data.filter(
                    (d) => d.quarter === qtr && d.label === label
                  );
                  const acv = data.length > 0 ? data[0]["acv"] : "";
                  const textColor = acv < 0 ? "#ED7C31" : "";
                  const showDrilldown =
                    label !== "Total Net Retention" ? true : false;
                  return (
                    <TableCell
                      height={40}
                      width={70}
                      align="right"
                      style={
                        showDrilldown === true
                          ? {
                              border: "0.5px solid lightgrey",
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              cursor: "pointer",
                              color: textColor,
                            }
                          : {
                              border: "0.5px solid lightgrey",
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              color: textColor,
                            }
                      }
                      className={classes.repHeader}
                      colSpan={1}
                      onClick={() =>
                        label !== "Total Net Retention" &&
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          label,
                          qtr === "Total" ? quarters : qtr
                        )
                      }
                    >
                      {[undefined, null, ""].includes(acv)
                        ? "-"
                        : acv > 0
                        ? countOrACVFormatter(acv, true)
                        : `(${countOrACVFormatter(Math.abs(acv), true)})`}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
