import React, { useRef, useState } from "react";
// import { useTable } from "react-table";

import CopyObjectIcon from "../Buttons/CopyObjectIcon";

function CopyTablesObjectv2({ tableRef }) {
  const [isCopied, setIsCopied] = useState(false);
  const [text, setText] = useState("Copy table to clipbaord");
  const handleCopyClick = () => {
    const tableNode = tableRef.current;
    console.log("copy_object_curent_ref", tableNode);

    if (tableNode) {
      const range = document.createRange();
      range.selectNode(tableNode);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      // Update state to show the "Copied!" message
      setIsCopied(true);
      setText("Table copied");
      // Reset the message after a certain time (e.g., 2 seconds)
      setTimeout(() => {
        setIsCopied(false);
        setText("Copy table to clipboard");
      }, 3000); // 2000 milliseconds = 2 seconds
    }
  };

  return (
    <div style={{ }}>
      {/* <div
        style={{
          marginRight: "5px",
          width: "auto", // Always reserve space for the message
          overflow: "hidden",
          zIndex: 10,
          color: "#A9A9A9",
          opacity: isCopied ? 1 : 0, // Use opacity to hide/show the message
          transition: "opacity 0.3s ease", // Add smooth transition for opacity
        }}
      >
        Table copied
      </div> */}
      <button
        onClick={handleCopyClick}
        style={{ border: "none", background: "transparent", cursor: "pointer" }}
      >
        <CopyObjectIcon text={text} />
      </button>
    </div>
  );
}

export default CopyTablesObjectv2;
