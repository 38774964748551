import { Button, Grid,Paper, Typography} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'
import * as d3 from 'd3'
import { makeStyles } from '@material-ui/core/styles'
import './QuotaMaintenance.scss'

const useStyle = makeStyles((theme) => ({
    tableHeader: {
      fontSize: '0.85rem',
      background:"#4472c4",
      paddingRight:"5rem",
      fontFamily:"Roboto/ Helvetica/ Aria/ sans-serif",
    width:"15rem",
    fontWeight:600,
color:'white',


  },
  tableCell: {
      fontSize: '1rem',
      whiteSpace: 'normal',
      paddingRight:"10px",
      fontFamily:"Roboto/ Helvetica/ Aria/ sans-serif",
      fontSize:"0.85rem",
      fontWeight:400,
    width:"15rem"
  },
  tablerow:{
   background:"red"
  }
  
  }))
  
  
  let updatedObjects=[]
// QuotaMaintenanceTable Component

const QuotaMaintenanceTable = (props) => {
let initialData=props.initialData
const [rowData,setRowData]=useState()
    const classes = useStyle()
  // Defining the columnn for AGGRID
  const [columnDefs, setColumnDefs] = useState([
    { field: 'FullName', sortable: true, filter: true, cellStyle: { textAlign: 'left',},cellClass: classes.tableCell, headerClass: classes.tableHeader, 
    cellRenderer:"deltaIndicator"},
    { field: 'FiscalQuarter',sortable: true, filter: true, cellStyle: { textAlign: 'right', },cellClass: classes.tableCell, headerClass: classes.tableHeader,},
    { field: 'Team', sortable: true, filter: true, cellStyle: {textAlign: 'left', },cellClass: classes.tableCell, headerClass: classes.tableHeader,},
    { field: 'Quota',sortable: true, filter: true, cellStyle: { textAlign: 'right'},cellClass: classes.tableCell, headerClass: classes.tableHeader,editable:true,
    valueFormatter: x => {
      return '$ ' + d3.format(",")(Math.ceil(x.value))},

  },
    
  ]);
 
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      cellClass: 'cell-wrap-text',

    };
  }, []);
//  To get the indivisual RowIds dynamicaly
  const getRowId = useCallback((params) => params.data.id, []);
  // Setting the Row data and assigning a variable with data for comparing the inndivisual data
   useEffect(() => {
    setRowData((props.quotaData));
    },[]);
  // Aggrid inbuilt method used to get the data wheneverer the cellvalues are changed
  const onCellValueChanged =
    (event) => {

      // For setting the background color whenever the cell value is changed
     
     
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const rowIndex= event.rowIndex
      const newItem = { ...data,rowIndex}
      const newUpdatedData=newItem
      newItem[field] = event.newValue;
      let dateTime =new Date(Date.now())
console.log(event)
// event.column.colDef.cellStyle={'background-color':"lightgrey"}
initialData.map((d,i)=>{
  console.log(parseInt(newItem.Quota)===d.Quota)
if(i===event.rowIndex&&d.Quota!== parseInt(newItem.Quota)){
  event.colDef.cellStyle ={'background-color': 'lightgrey'}
  event.api.refreshCells({
              force: true,
              columns: [event.column.getId()],
              rowNodes: [event.node]
          });
}

if(i===event.rowIndex&&d.Quota=== parseInt(newItem.Quota)){
  event.colDef.cellStyle ={'background-color': 'inherit'}
  event.api.refreshCells({
              force: true,
              columns: [event.column.getId()],
              rowNodes: [event.node]
          });
}

  // if (i===event.rowIndex&&d.Quota!== parseInt(newItem.Quota)) {
  //   event.colDef.cellStyle = (p) =>
  //   console.log(( typeof newItem.Quota))

  //   // console.log(p)
  //           // p.rowIndex.toString() === event.node.id ? {'background-color': 'red'} : "";

  //           event.api.refreshCells({
  //           force: true,
  //           columns: [event.column.getId()],
  //           rowNodes: [event.node]
  //       });
  //   }
})


   
     }

     
 const handleSave = ()=>{

let updateData=[]
console.log(initialData,rowData)
console.log("ud",updateData)
initialData.filter((d,i)=>{
  rowData.filter((rd,index)=>
    i===index&&(d.Quota)!==parseInt(rd.Quota)?updateData=[...updateData,{...rd,oldQuota:d.Quota}]:null
  )
})
updatedObjects=updateData
props.updateData(updateData,rowData)
 }

 const deltaIndicator = (params) => {
   let updatedQuotaRecords =props.updatedQuotaRecords
 
 const element = document.createElement('span');
const imageElement = document.createElement('span');
let uniqueData
console.log(params)

if (updatedQuotaRecords!==undefined&&updatedQuotaRecords.length!==updatedObjects.length) {
  if(updatedQuotaRecords.length===0){
    updatedObjects.map(k=>{
if(k.UserId===params.data.UserId&&k.FiscalQuarter===params.data.FiscalQuarter){
  params.colDef.cellStyle={"background-color":"red"}
  imageElement.appendChild(document.createTextNode("*"));
      imageElement.style.color = "white";
      element.style.background="#ed7d31";
      element.style.color="white";

}
    })
  }else{
    updatedQuotaRecords.map(d=>{
      updatedObjects.map(u=>{
        if(d.UserID!==u.UserId){
          if(u.UserId===params.data.UserId&&u.FiscalQuarter===params.data.FiscalQuarter){
            imageElement.appendChild(document.createTextNode("*"));
                imageElement.style.color = "white";
                element.style.background="#ed7d31";
                element.style.color="white";
          }        }
      })
    })
  }
    
   
} 
else {
  element.appendChild(document.createTextNode(""))
}
element.appendChild(document.createTextNode(params.value))
element.appendChild(imageElement);
return element;
};




  return (
      <Grid item xs={12} justify='flex-start'  style={{ height: '100%',width:'auto',marginBottom:'1rem'}}>
<Grid item style={{ padding: '1rem' }} >
                <Typography align="center"  style={{ margin: '1rem',fontSize:'1.2rem',fontFamily: "Roboto/Helvetica/Arial/sans-serif",fontWeight: 500,}}>{props.title}</Typography>
                <Grid item align='right' style={{ padding: '1rem',marginTop:'-4.3rem' }} >
                <Button  onClick={()=>(handleSave())} data-test="go" variant='contained' color='primary'   align="center" >Submit</Button>
            </Grid>
            </Grid>            
       <Grid
                                className="quota_maintance"
                                style={{
                                    width: 'auto',
                                    height: '54rem',
                                    display:"flex",
                                 justifyContent:"center",

                                }}
                            >
                              <AgGridReact
                               components={{
                                deltaIndicator: deltaIndicator,
                                // daysSunshineRenderer: daysSunshineRenderer,
                                // rainPerTenMmRenderer: rainPerTenMmRenderer
                            }}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          readOnlyEdit={true}
          // onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          // enableCellTextSelection={true}
          headerHeight={55}
          colResizeDefault={'shift'}
                  ></AgGridReact>

                              </Grid>
                              {(props.updatedQuotaRecords!==undefined&&props.updatedQuotaRecords.length!==updatedObjects.length&&
                              <Grid item container xs={10} justify="flex-center" alignItems="flex-center" style={{marginTop: "1rem",display:"flex",justifyContent:"center",alignItems:"center",}} >
                        <Typography align="center" variant="body2" style={{ paddingLeft: "8rem", }}>
                            <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Quota is not updated in the Backend as someone has alredy updated the data While you were Updating.
                        </Typography>
                    </Grid> 
                              )}
        </Grid>
        
     
  )
};

export default QuotaMaintenanceTable;