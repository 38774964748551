import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import StyledTableRow from '../../components/StyledTableRow'
import { getUniqueValues } from '../../util/customFunctions'
import { formatAcv } from '../../util/dataFormatter'

const useStyle = makeStyles(theme => ({
    root: {},
    content: {},
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            marginLeft: theme.spacing(1)
        }
    },
    table: {
        border: '0.5px solid lightgrey', padding: '5px 10px', color: 'inherit'
    },
    table1: {
        background: '#FAFAFA', border: 'none', width: 5
    },
    rep: {
        border: '0.5px solid lightgrey', padding: '0px 10px', width: '20%', color: 'inherit'
    },
    tableHeader: {
        fontWeight: 600, border: '0.5px solid lightgrey', padding: '10px 10px', color: 'white', background: '#4472C4', width: '15%'
    },
    repHeader: {
        fontWeight: 600, border: '0.5px solid lightgrey', padding: '0px 5px', width: '20%', color: 'inherit'
    },
    mainTable: {
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up(1250)]: {
            width: 1000,
        },
        [theme.breakpoints.up(1900)]: {
            width: 1400,
        }
    },
    mainTable2: {
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up(1250)]: {
            width: 800,
        },
        [theme.breakpoints.up(1900)]: {
            width: 1200,
        }
    }
}))
function YTDSegmentTable(props) {
    const classes = useStyle()

    const uniqueQuarters = props.data.map(item => item.Quarter).filter(getUniqueValues).filter(item => item !== null)
    let uniqueRegions = props.data.map(item => item.Display_Name).filter(getUniqueValues).sort()

    return (
        <div style={{ display: 'flex' }}>
            <TableContainer className={classes.mainTable} style={{ width: '100%' }}>
                <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', width: '99.8%' }}>
                    <TableHead>

                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">Segment</TableCell>
                            <TableCell className={classes.tableHeader} style={{ background: '#70ad47' }} align="center">Actual ACV</TableCell>
                            <TableCell className={classes.tableHeader} style={{ background: '#70ad47' }} align="center">Actual Services</TableCell>
                            <TableCell className={classes.tableHeader} style={{ background: '#70ad47' }} align="center"><p>Actual ELV</p><p>(3*ACV + Services)</p></TableCell>
                            <TableCell className={classes.table1}></TableCell>
                            <TableCell className={classes.tableHeader} style={{ background: '#BF8F00' }} align="center">Quota ELV</TableCell>
                            <TableCell className={classes.table1}></TableCell>
                            <TableCell className={classes.tableHeader} align="center">% Attainment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            uniqueRegions.map((row, i) =>
                                <StyledTableRow key={i}>
                                    <TableCell align='left' className={classes.table} >{row}</TableCell>
                                    <TableCell align='right' className={classes.table} >{
                                        formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.acv).reduce((a, b) => a + b, 0))
                                    }</TableCell>
                                    <TableCell align='right' className={classes.table} >{
                                        formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.services).reduce((a, b) => a + b, 0))
                                    }</TableCell>
                                    <TableCell align='right' className={classes.table} >{
                                        formatAcv(3 * props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.acv).reduce((a, b) => a + b, 0) +
                                            props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.services).reduce((a, b) => a + b, 0))
                                    }</TableCell>
                                    <TableCell className={classes.table1}></TableCell>
                                    <TableCell align='right' className={classes.table} >{
                                        formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.Quota).reduce((a, b) => a + b, 0))
                                    }</TableCell>
                                    <TableCell className={classes.table1}></TableCell>
                                    <TableCell align='right' className={classes.table} >{
                                        props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.Quota).reduce((a, b) => a + b, 0) === 0 ? "N/A" :
                                            `${Math.round(((3 * props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.acv).reduce((a, b) => a + b, 0) +
                                                props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.services).reduce((a, b) => a + b, 0)) /
                                                (props.data.filter(item => item.Display_Name !== null && item.acv !== null).filter(item => item.Display_Name === row).map(item => item.Quota).reduce((a, b) => a + b, 0))) * 100)}%`
                                    }</TableCell>


                                </StyledTableRow>)
                        }
                        <StyledTableRow>
                            <TableCell style={{ fontWeight: 600 }} align='left' className={classes.table} >Total</TableCell>
                            <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table} >{
                                formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.acv).reduce((a, b) => a + b, 0))
                            }</TableCell>
                            <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table} >{
                                formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.services).reduce((a, b) => a + b, 0))
                            }</TableCell>
                            <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table} >{
                                formatAcv(3 * props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.acv).reduce((a, b) => a + b, 0) +
                                    props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.services).reduce((a, b) => a + b, 0))
                            }</TableCell>
                            <TableCell className={classes.table1}></TableCell>
                            <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table} >{
                                formatAcv(props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.Quota).reduce((a, b) => a + b, 0))
                            }</TableCell>
                            <TableCell className={classes.table1}></TableCell>
                            <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table} >{
                                props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.Quota).reduce((a, b) => a + b, 0) === 0 ? "N/A" :
                                    `${Math.round(((3 * props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.acv).reduce((a, b) => a + b, 0) +
                                        props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.services).reduce((a, b) => a + b, 0)) /
                                        (props.data.filter(item => item.Display_Name !== null && item.acv !== null).map(item => item.Quota).reduce((a, b) => a + b, 0))) * 100)}%`
                            }</TableCell>

                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default YTDSegmentTable
