import React, { useEffect } from 'react'
import {Grid, Typography} from '@material-ui/core'
import { data } from './data'
// import { fullData, originalData } from './originalData'
import { D3SankeyChart } from './D3Sankey'
import * as d3Sankey from 'd3-sankey'
import * as d3Arr from 'd3-array'
import * as d3 from 'd3'
import d3SankeyChartBuilder from './D3SankeyChart'
import CountOpps from './CountOpps'

const SankeyPoc = () => {
  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  )
  // console.log(originalData)
 
  // console.table(sankeyFormattedData)

  // const originalDataGroupBy = groupBy(originalData,'FQ_Moved')
  // const groupByFQMoved = Object.keys(originalDataGroupBy) //originalDataGroupBy.map(item=> item.FQ_Moved)
  // const dataNew = groupByFQMoved.map(item => {
  //   const source = `2022-Q2`
  //   const target = `Q + (${item})`
  //   const value = originalDataGroupBy[item].length
  //   return {
  //     source,target,value
  //   }
  // })
  // console.log(originalData,originalDataGroupBy,groupByFQMoved,dataNew,data)
    
    // console.log(chart)
    
    useEffect(()=>{
      const svgContainer = document.getElementById("svgContainer")
      const svgContainer1 = document.getElementById("svgContainer1")
      // d3SankeyChartBuilder.create(data,svgContainer)
      // d3SankeyChartBuilder.create(sankeyFormattedData,svgContainer1)
    },[data])
    
  return (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant='h2'>
                Sankey Chart Poc
            </Typography>
            {d3Arr.union}
        </Grid>
       <Grid item xs={12}>
        <div id={"svgContainer1"}></div>
       </Grid>
       <Grid item xs={12}>
        <div id={"svgContainer"}></div>
       </Grid>
       <Grid>
       <CountOpps />
       </Grid>
    </Grid>
  )
}

export default SankeyPoc