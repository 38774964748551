import React, { useEffect, useState } from "react";
// import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { genericUseStyles } from "./style";
import {
  countOrACVFormatter,
  percentFormatter,
} from "../../util/customFunctions";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
import StickyStyledTableHeader from "../../components/Containers/StickyStyledTableHeader";
import SortingTableCell from "../../components/Containers/SortingTableCell";
import { sortAccountIdsByColumn } from "./utils";

const AccountSummaryActiveAccountTable = (props) => {
  const classes = genericUseStyles();

  console.log("FirstTable", props.data);

  const [sortColumn, setSortColumn] = useState("delta");
  const [order, setOrder] = useState("desc");
  const [sortedAccountId, setSortedAccountId] = useState([]);

  const sortedByAccountName = sortAccountIdsByColumn(
    props.data.accountInfo,
    "Account_Name",
    "asc"
  );
  const sortedByAccountDelta = sortAccountIdsByColumn(
    props.data.accountInfo,
    "delta",
    "desc"
  );

  const handleSort = (key) => {
    console.log("clickedkey", key);
    setSortColumn(key);
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  useEffect(() => {
    console.log("insideUseEffect");
    const ids = sortAccountIdsByColumn(
      props.data.accountInfo,
      sortColumn,
      order
    );
    setSortedAccountId(ids);
  }, [order, sortColumn]);

  useEffect(() => {
    const ids = sortAccountIdsByColumn(
      props.data.accountInfo,
      sortColumn,
      order
    );
    // console.log("outsidesortedAccountId", sortedAccountId);

    setSortedAccountId(ids);
  }, []);

  // console.log("Object_Info", Object.entries(props.data.accountInfo));
  return (
    <CopyToClipboardTableContainer data-test="active-account-summary">
      <StickyStyledTableHeader className={classes.fontBold} style={{ top: -1 }}>
        <TableRow>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{ width: 10 }}
            rowSpan={2}
          >
            #
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            rowSpan={2}
            onClick={() => {
              const isMoreThenOneRow = sortedAccountId.length > 1;
              console.log("cliked");
              if (isMoreThenOneRow) {
                handleSort("Account_Name");
                console.log("Insidecliked");
              }
            }}
          >
            Account
            <SortingTableCell
              col={"Account_Name"}
              sortColumn={sortColumn}
              order={order}
              isMoreThenOneRow={Object.keys(props.data.accountInfo).length > 1}
            />
          </TableCell>
          <TableCell
            style={{
              background: props.priorColor,
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Prior Fiscal Year (FY ${props.data.priorFiscalYear}) Won Opps`}</TableCell>
          <TableCell
            style={{
              background: props.currentColor,
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            colSpan={7}
          >{`Current Fiscal Year (FY ${props.data.currentFiscalYear}) Opps - Won + Active`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            rowSpan={2}
            onClick={() => {
              const isMoreThenOneRow = sortedAccountId.length > 1;
              if (isMoreThenOneRow) {
                handleSort("delta");
              }
            }}
          >
            {`FY ${props.data.currentFiscalYear} Delta`}
            <SortingTableCell
              col={"delta"}
              sortColumn={sortColumn}
              order={order}
              isMoreThenOneRow={Object.keys(props.data.accountInfo).length > 1}
            />
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            rowSpan={2}
            onClick={() => {
              const isMoreThenOneRow = sortedAccountId.length > 1;
              if (isMoreThenOneRow) {
                handleSort("deltaPercent");
              }
            }}
          >
            {`FY ${props.data.currentFiscalYear} Delta %`}
            <SortingTableCell
              col={"deltaPercent"}
              sortColumn={sortColumn}
              order={order}
              isMoreThenOneRow={Object.keys(props.data.accountInfo).length > 1}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
          >
            {`Won ACV`}
          </TableCell>
          {props.data.distinctOppTypes.map((oppType) => (
            <TableCell
              className={`${classes.repHeader} ${classes.fixedWidthCell}`}
              style={{
                outline: "lightgrey solid 0.5px",
                outlineOffset: "-1.5px",
              }}
            >
              {`${oppType} ACV`}
            </TableCell>
          ))}
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
          >
            {`Won ACV`}
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
          >
            {`Open ACV`}
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{
              outline: "lightgrey solid 0.5px",
              outlineOffset: "-1.5px",
            }}
          >
            {`Total ACV`}
          </TableCell>
        </TableRow>
      </StickyStyledTableHeader>
      <TableBody>
        {sortedAccountId.map((it, idx) => {
          console.log("first_sortedAccountId", sortedAccountId);
          const key = it[0];
          const account = props.data.accountInfo[it];
          const wonStageKey = `${props.data.wonStage
            .split(" ")
            .join("_")}_Total`;
          const parentWonStageKey = `parent_${wonStageKey}`;
          const currentWonStageKey = `current_${wonStageKey}`;
          const openACVKey = `open_ACV`;
          const totalACVKey = `current_Total_ACV`;
          const accountDeltaPercentKey = `deltaPercent`;
          console.log(
            account,
            wonStageKey,
            parentWonStageKey,
            currentWonStageKey
          );
          return (
            <TableRow>
              <TableCell
                style={{
                  textAlign: "right",
                  fontWeight: "inherit",
                  border: "0.5px solid lightgrey",
                }}
              >
                {idx + 1}
              </TableCell>
              <TableCell
                className={classes.repNumber}
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {account.Account_Name}
              </TableCell>
              <TableCell
                className={classes.repNumber}
                style={{
                  fontWeight: "bold",
                }}
              >
                {countOrACVFormatter(account[parentWonStageKey], true)}
              </TableCell>
              {props.data.distinctOppTypes.map((oppType) => {
                const oppTypeKey = `${oppType.split(" ").join("_")}_Total`;
                return (
                  <TableCell className={classes.repNumber}>
                    {countOrACVFormatter(account[oppTypeKey], true)}
                  </TableCell>
                );
              })}
              <TableCell className={classes.repNumber}>
                {countOrACVFormatter(account[currentWonStageKey], true)}
              </TableCell>
              <TableCell className={classes.repNumber}>
                {countOrACVFormatter(account[openACVKey], true)}
              </TableCell>
              <TableCell
                className={classes.repNumber}
                style={{
                  fontWeight: "bold",
                }}
              >
                {countOrACVFormatter(account[totalACVKey], true)}
              </TableCell>
              <TableCell
                className={classes.repNumber}
                style={{
                  fontWeight: "bold",
                }}
              >
                {countOrACVFormatter(account["delta"], true)}
              </TableCell>
              <TableCell
                className={classes.repNumber}
                style={{
                  fontWeight: "bold",
                }}
              >
                {percentFormatter(account[accountDeltaPercentKey])}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CopyToClipboardTableContainer>
  );
};

export default AccountSummaryActiveAccountTable;
