import {
  Button,
  capitalize,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import FiscalQuarterFilter from "../../../Filters/FiscalQuarterFilter";
import AccountSegmentFilter from "../../../Filters/AccountSegment";
import {
  getHeaderForDrilldown,
  orderRepsByLastName,
  getDefaultSelectedFiscalQuarters,
} from "../../../util/customFunctions";
import { renewalsDataFormatter } from "../../../util/dataFormatter";
import QuarterTrendsTable from "../../components/QuarterTrendsTable";
import RenewalTrendsTable from "./components/RenewalsTrendsTable";
import RenewalTrendsTableSegment from "./components/RenewalsTrendsTableSegment";
import { colorsForRenewal, renewalsRow } from "./components/utils";
import RenewalTypeFilter from "../../../Filters/RenewalType";
import RegionFilter from "../../../Filters/Region";
import GoButton from "../../../Filters/FilterHandlers/GoButton";
import RenewalDrilldownBody from "./components/RenewalDrilldownBody";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import RepTeamFilter from "../../../Filters/RepTeamFilter";
import addHeaderdescription from "../../../components/HeaderDescription";

const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});

class RenewalsCompleted extends Component {
  filters = [
    "Renewal_Contract_Eff_Dt_Fiscal_Quarter",
    "Renewal_Type_c",
    "Region_Rollup",
    "Account_Segment",
    "team",
    "rep",
    "projected_close_fiscal_quarter",
  ];
  state = {
    Renewal_Contract_Eff_Dt_Fiscal_Quarter: ["All"],
    projected_close_fiscal_quarter: ["All"],
    Renewal_Type_c: ["All"],
    Region_Rollup: ["All"],
    team: ["All"],
    rep: ["All"],
    key: "renewal",
    Account_Segment: ["All"],
    accountSegment: [...renewalsRow],
    disabled: ["year", "month", "quarter"],
    filtersForDrilldownRFQ: ["All"],
    filtersForDrilldownRep: "",
    filtersForDrilldown2: [],
  };
  componentDidMount() {
    this.props.getRenewalsCompletedFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      let defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
      );
      if (defaultFiscalQuarter.length <= 0) {
        defaultFiscalQuarter = ["All"];
      }

      const missingSegemnt = this.props.funnelCompareFilters
        .filter(
          (y) => y.type === "Account_Segment" && y["Account_Segment"] !== ""
        )
        .map((item) => item.Account_Segment)
        .filter(function (obj) {
          return renewalsRow.indexOf(obj) == -1;
        });

      this.setState({
        ...this.state,
        Renewal_Contract_Eff_Dt_Fiscal_Quarter: defaultFiscalQuarter,
        filtersForDrilldownRFQ: defaultFiscalQuarter,
        accountSegment: [...renewalsRow, ...missingSegemnt],
      });
    }
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter,
      ];
    }

    if (this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter.includes("All")) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  passFiltersForDrilldownRep = () => {
    let filters = [];
    //  if (!this.state.Region_Rollup.includes('All')) {
    //     filters = [...filters, { title: 'Region', value: this.state.Region_Rollup, string: true }]
    // }
    if (!this.state.Renewal_Type_c.includes("All")) {
      filters = [
        ...filters,
        {
          title: "Renewal Type",
          value: this.state.Renewal_Type_c,
          string: true,
        },
      ];
    }
    // if (!this.state.Account_Segment.includes('All')) {
    //     filters = [...filters, { title: 'Account Segment', value: this.state.Account_Segment, string: true }]
    // }
    if (!this.state.rep.includes("All")) {
      filters = [
        ...filters,
        { title: "Rep", value: this.getSelectedRepIDs(), string: true },
      ];
    }
    if (!this.state.team.includes("All")) {
      filters = [
        ...filters,
        { title: "Team", value: this.state.team, string: true },
      ];
    }
    return filters;
  };

  passFiltersForDrilldownReq = () => {
    let filters = [];
    if (!this.state.Region_Rollup.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Region_Rollup",
          value: this.state.Region_Rollup,
          string: true,
        },
      ];
    }
    if (!this.state.Renewal_Type_c.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Renewal_Type_c",
          value: this.state.Renewal_Type_c,
          string: true,
        },
      ];
    }
    if (!this.state.Account_Segment.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Account_Segment",
          value: this.state.Account_Segment,
          string: true,
        },
      ];
    }
    if (!this.state.rep.includes("All")) {
      filters = [
        ...filters,
        { name: "rep", value: this.getSelectedRepIDs(), string: true },
      ];
    }
    if (!this.state.team.includes("All")) {
      filters = [
        ...filters,
        { name: "team", value: this.state.team, string: true },
      ];
    }

    return filters;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
      // disabled: this.state.disabled.includes('team') ? [...this.state.disabled] : [...this.state.disabled.filter(f => f !== 'rep'), 'team']
    });
  };
  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      if (
        e.target.name === "Renewal_Contract_Eff_Dt_Fiscal_Quarter" ||
        e.target.name === "projected_close_fiscal_quarter"
      ) {
        this.setState({
          ...this.state,
          [e.target.name]:
            e.target.name === "rep"
              ? [e.target.value]
              : e.target.value.length === 0
              ? ["All"]
              : this.state[e.target.name].length === 1 &&
                this.state[e.target.name].includes("All")
              ? e.target.value.filter((v) => v !== "All").length > 0
                ? e.target.value.filter((v) => v !== "All")
                : ["All"]
              : e.target.value.includes("All")
              ? ["All"]
              : e.target.value,
          key:
            e.target.name === "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
              ? "renewal"
              : "projected",
          // disabled: this.state.disabled.includes('rep') ? [...this.state.disabled] : [...this.state.disabled.filter(f => f !== 'team'), 'rep']
        });
      } else {
        this.setState({
          ...this.state,
          [e.target.name]:
            e.target.name === "rep"
              ? [e.target.value]
              : e.target.value.length === 0
              ? ["All"]
              : this.state[e.target.name].length === 1 &&
                this.state[e.target.name].includes("All")
              ? e.target.value.filter((v) => v !== "All").length > 0
                ? e.target.value.filter((v) => v !== "All")
                : ["All"]
              : e.target.value.includes("All")
              ? ["All"]
              : e.target.value,
          // disabled: this.state.disabled.includes('rep') ? [...this.state.disabled] : [...this.state.disabled.filter(f => f !== 'team'), 'rep']
        });
      }
    }
  };
  orderRepsByLastName = () => {
    let reps = this.props.funnelCompareFilters
      .filter((item) => item.type === "rep")
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };
  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let filterTitles = filters.map((item) => item.name);
    let key =
      filterTitles.includes("Renewal_Contract_Eff_Dt_Fiscal_Quarter") &&
      !filterTitles.includes("projected_close_fiscal_quarter")
        ? "renewal"
        : filterTitles.includes("projected_close_fiscal_quarter") &&
          !filterTitles.includes("Renewal_Contract_Eff_Dt_Fiscal_Quarter")
        ? "projected"
        : "";
    this.setState({
      ...this.state,
      filtersForDrilldownRFQ: this.passFiltersForDrilldown(),
      filtersForDrilldown2: this.passFiltersForDrilldownReq(),
    });
    this.props.getRenewalsCompletedData(
      this.props.company,
      filters,
      this.state.key
    );
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, filters, type, countType) => {
    if (
      type === "Open" ||
      type === "Open Overdue" ||
      type === "Completed Delayed" ||
      type === "Completed On Time" ||
      type === "label"
    ) {
      this.props.setTable(type === "label" ? "" : type);
    } else {
      this.props.setTable(filters.row);
    }

    if (countType === "rawValsTotalDonutOverAll") {
      let newFilters = [
        { name: "current_status", value: ["Open", "MQL", "Win"], string: true },
        {
          name: `${
            this.state.key === "renewal"
              ? "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
              : "projected_close_fiscal_quarter"
          }`,
          value: filters.cell,
          string: true,
        },
        { name: "Renewal_Overdue", value: ["N", "Y"], string: true },
      ];
      filters = newFilters;
    }
    if (type === "rawVals" || countType === "rawVals") {
      let newFilters;
      if (filters.row === "Open") {
        newFilters = [
          { name: "current_status", value: ["Open", "MQL"], string: true },
          { name: "Renewal_Overdue", value: ["N"], string: true },
        ];
      } else if (filters.row === "Open Overdue") {
        newFilters = [
          { name: "current_status", value: ["Open", "MQL"], string: true },
          { name: "Renewal_Overdue", value: ["Y"], string: true },
        ];
      } else if (filters.row === "Completed On Time") {
        newFilters = [
          { name: "current_status", value: ["Win"], string: true },
          { name: "Renewal_Overdue", value: ["N"], string: true },
        ];
      } else if (filters.row === "Completed Delayed") {
        newFilters = [
          { name: "current_status", value: ["Win"], string: true },
          { name: "Renewal_Overdue", value: ["Y"], string: true },
        ];
      }

      newFilters = [
        ...newFilters,
        {
          name: `${
            this.state.key === "renewal"
              ? "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
              : "projected_close_fiscal_quarter"
          }`,
          value: [filters.cell],
          string: true,
        },
      ];
      filters = newFilters;
    }
    if (countType === "rawValsTotal") {
      let newFilters = [
        { name: "current_status", value: ["Open", "MQL", "Win"], string: true },
        {
          name: `${
            this.state.key === "renewal"
              ? "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
              : "projected_close_fiscal_quarter"
          }`,
          value: [filters.cell],
          string: true,
        },
        { name: "Renewal_Overdue", value: ["N", "Y"], string: true },
      ];
      filters = newFilters;
    }
    if (countType === "rawValsTotalDonut") {
      let newFilters;
      if (filters.row === "Open") {
        newFilters = [
          { name: "current_status", value: ["Open", "MQL"], string: true },
          { name: "Renewal_Overdue", value: ["N"], string: true },
        ];
      } else if (filters.row === "Open Overdue") {
        newFilters = [
          { name: "current_status", value: ["Open", "MQL"], string: true },
          { name: "Renewal_Overdue", value: ["Y"], string: true },
        ];
      } else if (filters.row === "Completed On Time") {
        newFilters = [
          { name: "current_status", value: ["Win"], string: true },
          { name: "Renewal_Overdue", value: ["N"], string: true },
        ];
      } else if (filters.row === "Completed Delayed") {
        newFilters = [
          { name: "current_status", value: ["Win"], string: true },
          { name: "Renewal_Overdue", value: ["Y"], string: true },
        ];
      }
      newFilters = [
        ...newFilters,
        {
          name: `${
            this.state.key === "renewal"
              ? "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
              : "projected_close_fiscal_quarter"
          }`,
          value: filters.cell,
          string: true,
        },
      ];
      filters = newFilters;
    }
    // let drillLabel = [...filters]

    // if (!this.state.Region_Rollup.includes('All')) {
    //     filters = [...filters, { name: 'Region_Rollup', value: this.state.Region_Rollup, string: true }]
    // }
    // if (!this.state.Renewal_Type_c.includes('All')) {
    //     filters = [...filters, { name: 'Renewal_Type_c', value: this.state.Renewal_Type_c, string: true }]
    // }
    // if (!this.state.Account_Segment.includes('All')) {
    //     filters = [...filters, { name: 'Account_Segment', value: this.state.Account_Segment, string: true }]
    // }
    // if (!this.state.rep.includes('All')) {
    //     filters = [...filters, { name: 'rep', value: this.getSelectedRepIDs(), string: true }]
    // }
    // if (!this.state.team.includes('All')) {
    //     filters = [...filters, { name: 'team', value: this.state.team, string: true }]
    // }

    filters = [...filters, ...this.state.filtersForDrilldown2];

    filters = filters.map((item) => ({
      ...item,
      name:
        item.name === "Renewal_Fiscal_Quarter"
          ? "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
          : item.name === "Close_Fiscal_Quarter"
          ? "projected_close_fiscal_quarter"
          : item.name,
    }));

    this.props.getChartDrilldown(this.state.key, filters);
    this.setState({
      ...this.state,
      location: location,

      filtersForDrilldown: [
        ...filters
          .map((item) => ({
            ...item,
            value: item.name === "rep" ? this.state.rep : item.value,
          }))
          .filter(
            (item) =>
              item.name !== "current_status" && item.name !== "Renewal_Overdue"
          )
          .map((item) => ({
            ...item,
            name:
              item.name === "Renewal_Type_c"
                ? "Renewal_Type"
                : item.name === "Region_Rollup"
                ? "Region"
                : item.name,
            value:
              item.name === "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
                ? this.state.filtersForDrilldownRFQ
                : item.value,
          }))
          .map((item) => ({
            ...item,
            title: capitalize(item.name.split("_").join(" ")),
          })),
      ],
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        {/* <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Renewals Summary
          </Typography>
          <Typography variant="body1">
            Explore completed and open renewals by region, account segment over
            time.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Renewals Summary",
          "Explore completed and open renewals by region, account segment over time."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              container
              justify="flex-start"
            >
              <Grid container item xs={12} md={5}>
                <Grid container item style={{ padding: 10 }} xs={12}>
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        disabled={
                          !this.state.projected_close_fiscal_quarter.includes(
                            "All"
                          )
                        }
                      >
                        <InputLabel
                          style={{
                            color:
                              !this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-vfiscalQuarter-label"
                        >
                          Renewal Eff. Dt. Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          labelId="select-vRenewal_Contract_Eff_Dt_Fiscal_Quarter-label"
                          id="select-cRenewal_Contract_Eff_Dt_Fiscal_Quarter"
                          value={
                            this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter
                          }
                          onChange={this.handleChange}
                          label="Renewal_Contract_Eff_Dt_Fiscal_Quarter"
                          name="Renewal_Contract_Eff_Dt_Fiscal_Quarter"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>

                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    "Renewal_Contract_Eff_Dt_Fiscal_Quarter" &&
                                  y[
                                    "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
                                  ] !== "" &&
                                  y[
                                    "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
                                  ] !== null
                              )
                              .map((y) => (
                                <MenuItem
                                  key={
                                    y["Renewal_Contract_Eff_Dt_Fiscal_Quarter"]
                                  }
                                  value={
                                    y["Renewal_Contract_Eff_Dt_Fiscal_Quarter"]
                                  }
                                >
                                  {y["Renewal_Contract_Eff_Dt_Fiscal_Quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        disabled={
                          !this.state.Renewal_Contract_Eff_Dt_Fiscal_Quarter.includes(
                            "All"
                          )
                        }
                      >
                        <InputLabel
                          style={{
                            color:
                              !this.state.projected_close_fiscal_quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-vfiscalQuarter-label"
                        >
                          Close Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          labelId="select-vprojected_close_fiscal_quarter-label"
                          id="select-cprojected_close_fiscal_quarter"
                          value={this.state.projected_close_fiscal_quarter}
                          onChange={this.handleChange}
                          label="projected_close_fiscal_quarter"
                          name="projected_close_fiscal_quarter"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>

                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === "projected_close_fiscal_quarter" &&
                                  y["projected_close_fiscal_quarter"] !== "" &&
                                  y["projected_close_fiscal_quarter"] !== null
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["projected_close_fiscal_quarter"]}
                                  value={y["projected_close_fiscal_quarter"]}
                                >
                                  {y["projected_close_fiscal_quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} md={5}>
                <Grid container item style={{ padding: 10 }} xs={12}>
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={
                          this.props.funnelCompareFilters !== "" &&
                          this.orderRepsByLastName()
                        }
                        getOptionLabel={(option) => option}
                        value={this.state.rep[0]}
                        onChange={this.onRepChange}
                        name="rep"
                        disabled={!this.state.team.includes("All")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              style: {
                                color: !this.state.rep.includes("All")
                                  ? "#4472c4"
                                  : "#000",
                              },
                            }}
                            label="Rep:"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={!this.state.rep.includes("All")}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.team.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-team-label"
                        >
                          Team:
                        </InputLabel>
                        <Select
                          value={this.state.team}
                          onChange={this.handleChange}
                          name="team"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === "team" && y.Display_Name !== ""
                              )
                              .sort((a, b) =>
                                a.Display_Name < b.Display_Name
                                  ? -1
                                  : a.Display_Name > b.Display_Name
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y.Display_Name}
                                  value={y.Display_Name}
                                >
                                  {y.Display_Name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={1}>
                <GoButton handleGo={this.handleGo} />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              xs={12}
              container
              justify="flex-start"
            >
              <Grid container item xs={6} md={2} justify="center">
                <RegionFilter
                  Region_RollupValue={this.state.Region_Rollup}
                  handleChange={this.handleChange}
                  funnelCompareFilters={this.props.funnelCompareFilters}
                />
              </Grid>
              <Grid container item xs={6} md={2} justify="center">
                <AccountSegmentFilter
                  selectedAccount_Segment={this.state.Account_Segment}
                  handleChange={this.handleChange}
                  funnelCompareFilters={this.props.funnelCompareFilters}
                  accountSegment={this.state.accountSegment}
                />
              </Grid>
              <Grid container item xs={6} md={2} justify="center">
                <RenewalTypeFilter
                  Renewal_Type_cValue={this.state.Renewal_Type_c}
                  handleChange={this.handleChange}
                  funnelCompareFilters={this.props.funnelCompareFilters}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid className={classes.gridItem} item container justify="center">
          {this.props.RenewalsCompletedData !== "" ? (
            <DataVisualizer
              title={`Renewals Summary`}
              type={"label"}
              chartType="renewalsCompleted"
              winACVMixAnalysisData={renewalsDataFormatter(
                this.props.RenewalsCompletedData,
                `${
                  this.props.RenewalsCompletedData[0] !== undefined
                    ? Object.keys(this.props.RenewalsCompletedData[0]).filter(
                        (item) =>
                          item === "Renewal_Fiscal_Quarter" ||
                          item === "Close_Fiscal_Quarter"
                      )[0]
                    : ""
                }`
              ).map((item) => ({ ...item, acv: item.CARR }))}
              handleChangeLocation={this.handleChangeLocation}
              quarter={`${
                this.props.RenewalsCompletedData[0] !== undefined
                  ? Object.keys(this.props.RenewalsCompletedData[0]).filter(
                      (item) =>
                        item === "Renewal_Fiscal_Quarter" ||
                        item === "Close_Fiscal_Quarter"
                    )[0]
                  : ""
              }`}
              unit="acv"
              page="Renewals"
              colors={colorsForRenewal}
              isDrilldown={true}
              chartName="renewalsCompleted"
              tableComponent={
                <RenewalTrendsTableSegment
                  title="Fiscal Quarter"
                  data={this.props.RenewalDataCombinedAccountSegment}
                  row="type"
                  type="Account_Segment"
                  typeLabel="Account_Segment"
                  column={`${
                    this.props.RenewalDataCombinedAccountSegment[0] !==
                    undefined
                      ? Object.keys(
                          this.props.RenewalDataCombinedAccountSegment[0]
                        ).filter(
                          (item) =>
                            item === "Renewal_Fiscal_Quarter" ||
                            item === "Close_Fiscal_Quarter"
                        )[0]
                      : ""
                  }`}
                  handleChangeLocation={this.handleChangeLocation}
                />
              }
            />
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridItem} container justify="flex-start">
          {this.props.RenewalsCompletedData !== "" ? (
            <Grid
              container
              component={Card}
              style={{ padding: "1rem" }}
              justify="center"
            >
              <Grid item xs={12} justify="center">
                <Typography align="center" variant="h2">
                  Renewals Summary by Region
                </Typography>
              </Grid>
              <Grid
                item
                container
                className={classes.gridItem}
                style={{ padding: "1rem" }}
              >
                <RenewalTrendsTableSegment
                  title="Fiscal Quarter"
                  data={this.props.RenewalDataCombinedRegionRollup}
                  row="type"
                  typeLabel="Region"
                  type="Region_Rollup"
                  column={`${
                    this.props.RenewalDataCombinedRegionRollup[0] !== undefined
                      ? Object.keys(
                          this.props.RenewalDataCombinedRegionRollup[0]
                        ).filter(
                          (item) =>
                            item === "Renewal_Fiscal_Quarter" ||
                            item === "Close_Fiscal_Quarter"
                        )[0]
                      : ""
                  }`}
                  handleChangeLocation={this.handleChangeLocation}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
        {/* <Grid className={classes.gridItem} container justify="flex-start" >
                    {
                        this.props.RenewalsCompletedData !== "" ?
                            <Grid container component={Card} style={{ padding: '1rem' }} justify="center">
                                <Grid item xs={12} justify="center">
                                    <Typography align="center" variant="h2">Renewals Summary by Region</Typography>
                                </Grid>
                                <Grid item container className={classes.gridItem} style={{ padding: '1rem' }}>
                                 <RenewalTrendsTable title="Fiscal Quarter" data={this.props.RenewalsCompletedData} row="Account_Segment" column={`${this.props.RenewalsCompletedData[0] !== undefined ? Object.keys(this.props.RenewalsCompletedData[0]).filter(item => item === "Renewal_Fiscal_Quarter" || item === "Close_Fiscal_Quarter")[0] : ""}`} handleChangeLocation={this.handleChangeLocation} />   
                                </Grid>
                            </Grid> :
                            <Grid item container xs={12} className={classes.gridItem} component={Card}>

                                <Skeleton animation="wave" variant="rect" width='95%' height={230} style={{ margin: '2rem' }} />

                            </Grid>
                    }
                </Grid> */}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Renewals ${
                this.props.tableType !== undefined ? this.props.tableType : ""
              }`}
              back={this.handleChangeLocationBack}
              filters={[...this.state.filtersForDrilldown]}
            >
              <RenewalDrilldownBody
                header={`Renewals ${
                  this.props.tableType !== undefined ? this.props.tableType : ""
                }`}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    RenewalsCompletedFilters,
    RenewalsCompletedData,
    RenewalsCompletedDataRegion,
    RenewalDataCombinedAccountSegment,
    RenewalDataCombinedRegionRollup,
    drilldownOnGraphData,
    tableType,
    user,
  } = state.app;

  return {
    funnelCompareFilters: RenewalsCompletedFilters,
    RenewalsCompletedData,
    RenewalsCompletedDataRegion,
    RenewalDataCombinedAccountSegment,
    RenewalDataCombinedRegionRollup,
    drilldownOnGraphData,
    tableType,
    company: user.company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChartDrilldown: (key, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getChartDrilldown("C0003.CHID00000022", key, filters).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1];
        // console.log(tempOpportunities)
        tempOpportunities = tempOpportunities.sort((a, b) =>
          a.CARR > b.CARR ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let x = {
            ...item,
            OppNo: index + 1,
          };
          return x;
        });
        // tempOpportunities.sort((a, b) => a.ACV > b.ACV ? -1 : 1)

        // tempOpportunities = tempOpportunities.map((item, index) => {
        //   let createdDateString = (item.Created_Date.split('T')[0])
        //   var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
        //   let temp = {
        //     ...item,
        //     OppNo: index + 1,
        //     age: Math.round(
        //       (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
        //       (1000 * 60 * 60 * 24)
        //     ),
        //   }
        //   return temp
        // })

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            // columns: json.columns
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getRenewalsCompletedFilters: (company) => {
    dispatch({ type: "get_RenewalsCompleted_filters_request" });
    appService.getChartFilters("C0003.CHID00000022").then(
      (json) => {
        dispatch({ type: "get_RenewalsCompleted_filters_success", json });
        dispatch({ type: "get_RenewalsCompleted_data_request" });
        let filters = [];
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message,
          "Renewal_Contract_Eff_Dt_Fiscal_Quarter"
        );
        if (defaultFiscalQuarter.length > 0) {
          filters = [
            ...filters,
            {
              name: "Renewal_Contract_Eff_Dt_Fiscal_Quarter",
              value: defaultFiscalQuarter,
              string: true,
            },
          ];
        }
        appService.getChartData("C0003.CHID00000022", filters, "renewal").then(
          (json) => {
            dispatch({ type: "get_RenewalsCompleted_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_RenewalsCompleted_data_failure",
                error: "Something went wrong",
              });
            else
              dispatch({ type: "get_RenewalsCompleted_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_RenewalsCompleted_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_RenewalsCompleted_filters_failure", error });
      }
    );
  },
  getRenewalsCompletedData: (company, filters = [], key) => {
    dispatch({ type: "get_RenewalsCompleted_data_request" });
    appService.getChartData("C0003.CHID00000022", filters, key).then(
      (json) => {
        dispatch({ type: "get_RenewalsCompleted_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_RenewalsCompleted_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_RenewalsCompleted_data_failure", error });
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RenewalsCompleted));
