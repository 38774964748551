import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import React from 'react'
import { nearestCompletedFiscalQuarter } from '../util/fiscalQuarterFunctions'

function YearQuarterFilterCombov2(props) {
    return (
        <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

            <Grid item xs={6} style={{ paddingTop: 0 }}>
                <FormControl fullWidth disabled={!props.closedFiscalYear.includes('All')} id="filter_cfq">
                    <InputLabel style={{ color: props.activeTimeFilter !== "closed_fiscal_year" ? '#4472c4' : '#000' }} id='slect_cfq_label'>Fiscal Quarter:</InputLabel>
                    <Select
                        labelId="select-vRenewal_Contract_Eff_Dt_Fiscal_Quarter-label"
                        // id="select-cclosed_fiscal_quarter"
                        value={props.closedFiscalQuarterState}
                        onChange={props.handleChange}
                        label="closed_fiscal_quarter"
                        name='closed_fiscal_quarter'
                        data-test='closed-fiscal-quarter'
                        multiple
                        id={'select-closed_fiscal_quarter'}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            PaperProps: {
                              id: "filter_cfq_values", // Add id to the generated div element
                            },
                          }}
                    >
                        <MenuItem value={'All'} >All</MenuItem>
                        {props.funnelCompareFilters !== '' && props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '' && y["closed_fiscal_quarter"] !== null)
                            .map(y => <MenuItem key={y['closed_fiscal_quarter']} value={y['closed_fiscal_quarter']}>{y['closed_fiscal_quarter']}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 0 }}>
                <FormControl fullWidth disabled={!props.closedFiscalQuarterState.includes('All')}>
                    <InputLabel style={{ color: props.activeTimeFilter === "closed_fiscal_year" ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Fiscal Year:</InputLabel>
                    <Select
                        labelId="select-vprojected_close_fiscal_quarter-label"
                        id="select-cclosed_fiscal_year"
                        value={props.closedFiscalYear}
                        onChange={props.handleChange}
                        label="closed_fiscal_year"
                        name='closed_fiscal_year'
                        data-test='closed-fiscal-year'
                        multiple
                    >
                        <MenuItem value={'All'} >All</MenuItem>

                        {props.funnelCompareFilters === '' && <MenuItem value={nearestCompletedFiscalQuarter()} >{nearestCompletedFiscalQuarter()}</MenuItem>}
                        {props.funnelCompareFilters !== '' && props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_year" && y["closed_fiscal_year"] !== '' && y["closed_fiscal_year"] !== null)
                            .map(y => <MenuItem key={y['closed_fiscal_year']} value={y['closed_fiscal_year']}>{y['closed_fiscal_year']}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default YearQuarterFilterCombov2
