import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import { coloredCells } from '../../AccountHealth/utils'
import NoDataCell from '../../AccountHealth/components/NoDataCell'

export default function SummaryTrendsTeamTable(props) {
    const classes = tableStyle()

    const rows = props.rows !== undefined ? props.rows : props.data.map(item => item[props.row]).filter(getUniqueValues)
    let columns = props.columns !== undefined ? props.columns : props.data.map(item => item[props.col]).filter(getUniqueValues)
    columns = props.weekOverWeekTrends ? ["Churned", 'Red', 'Yellow', 'Orange', 'Green', 'Green +', null] : columns
    const columns2ndLevel = ['# of Accounts', 'CARR']
    let missingRows = (props.data.map(item => item[props.col]).filter(getUniqueValues)).filter(function (obj) { return props.columns.indexOf(obj) == -1; })
    columns = [...columns, ...missingRows]


    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            {
                                props.statusByPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell style={{ background: 'white' }}></TableCell>


                                    <TableCell align="center" colSpan={columns.length} style={{ backgroundColor: '#bf8f00' }} className={classes.colHeader}>Status Prior Week</TableCell>
                                </TableRow> : null
                            }
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Health</TableCell>

                                {columns.map(item => <TableCell align="center" colSpan={2} style={coloredCells[item] !== undefined ? { backgroundColor: coloredCells[item], color: 'black' } : {}} className={classes.colHeader}>{item}</TableCell>)}
                                <TableCell align="center" colSpan={2} className={classes.colHeader}>Total</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>{props.rowHeading}</TableCell>

                                {columns.map(item => columns2ndLevel.map(item => <TableCell className={classes.colHeader} align="center">{item}</TableCell>))}
                                {columns2ndLevel.map(item => <TableCell className={classes.colHeader} align="center">{item}</TableCell>)}
                            </TableRow>
                            {
                                rows.map(row =>
                                    <StyledTableRow>
                                        <TableCell align="left" className={classes.dataCell} style={{ backgroundColor: coloredCells[row], color: 'black' }}>{row}</TableCell>
                                        {columns.map(col => {
                                            const cellData = props.data.filter(item => item[props.col] === col && item[props.row] === row)[0] !== undefined ? props.data.filter(item => item[props.col] === col && item[props.row] === row)[0] : "-"
                                            return <>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col, row }, props.row)} className={classes.drilldownCell}>{cellData === "-" ? cellData : countOrACVFormatter(cellData.SFDC, false)}</TableCell>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col, row }, props.row)} className={classes.drilldownCell}>{cellData === "-" ? cellData : countOrACVFormatter(cellData.CARR, true)}</TableCell></>
                                        })}
                                        {
                                            <>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col: columns, row }, props.row, 'totalCol')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item.SFDC).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col: columns, row }, props.row, 'totalCol')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                            </>
                                        }
                                    </StyledTableRow>)
                            }
                            <StyledTableRow key={'total'}>
                                <TableCell align="left" className={classes.dataCell}>Total</TableCell>
                                {
                                    columns.map(col => <>
                                        <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col, row: rows }, props.row, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.col] === col).map(item => item.SFDC).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col, row: rows }, props.row, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.col] === col).map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                    </>)
                                }
                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col: columns, row: rows }, props.row, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item.SFDC).reduce((a, b) => a + b, 0), false)}</TableCell>
                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", { col: columns, row: rows }, props.row, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
