const punctuation = /[.,!?;:'"]/g;
export const word_tokenize = (text) => {
  return text.split(" ");
};
export const get_highest_score_and_longest_string = (
  ex_team,
  { CONV_AI_CONSTANTS_THRESHOLD }
) => {
  let highest_score = ex_team[0][1];
  let c = 0;
  let multiple_matches = false;

  for (let x of ex_team) {
    if (x[1] === highest_score) {
      c += 1;
    }
    if (c > 1) {
      multiple_matches = true;
      break;
    }
  }
  let highest_length_of_words = 0;
  let team = null;
  console.log(
    highest_score,
    multiple_matches,
    highest_score,
    ex_team[0][1] > 50 ? ex_team[0][0] : "",
    ex_team,
    CONV_AI_CONSTANTS_THRESHOLD,
    "metric_extraction"
  );
  if (multiple_matches && highest_score >= CONV_AI_CONSTANTS_THRESHOLD) {
    for (let x of ex_team) {
      if (x[1] === highest_score) {
        team = x[0];
        highest_length_of_words = x[0].length;
      }
    }
  } else {
    team = ex_team[0][1] >= CONV_AI_CONSTANTS_THRESHOLD ? ex_team[0][0] : "";
  }
  return team;
};
export const get_highest_score_and_longest_string_for_dashboards = (
  dashboards,
  { CONV_AI_CONSTANTS_THRESHOLD }
) => {
  return dashboards.filter((x) => x[1] >= CONV_AI_CONSTANTS_THRESHOLD);
};
const nativelySupportedStopWords = ["by", "of"];
export const normalizeText = (text) => {
  return text.replace(punctuation, "").toLowerCase();
};
export const findMatchingTemplate = (question, templates) => {
  const lowercaseQuestion = question.toLowerCase();

  for (const template of templates) {
    const lowercaseMetricText = template.metricText.toLowerCase();
    let matchingScore = 0;
    let lmtArr = lowercaseMetricText.split(" ");
    lmtArr = lmtArr.filter((it) => !nativelySupportedStopWords.includes(it));
    for (let lmt of lmtArr) {
      if (lowercaseQuestion.includes(lmt)) {
        matchingScore += 1;
      }
    }
    if (matchingScore === lmtArr.length) {
      return template;
    }

    // if (lowercaseQuestion.includes(lowercaseMetricText)) {
    //   return template;
    // }
  }

  return null;
};
