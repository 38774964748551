import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";
import "./RenewalDrilldownBody.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  getCSVFileNameString,
  csvDateFormatter,
  removeQuotes,
  _isNaN,
  agGridDateFormatter,
} from "../../../../util/customFunctions";
import HoverableIcon from "../../../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const RenewalDrilldownBody = (props) => {
  const classes = useStyle();
  const containerHeight = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        if (
          (cellParams && rowIndex === "Renewal_Contract_Effective_Date_c") ||
          rowIndex === "closed_date"
        ) {
          return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        } else if (
          (cellParams && rowIndex === "OppName") ||
          rowIndex === "Account_Name" ||
          rowIndex === "Owner_Name" ||
          "Account_Segment"
        ) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && rowIndex === "ACV") {
          return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        } else if (
          cellParams &&
          (rowIndex === "Account_CARR" || rowIndex === "CARR")
        ) {
          return cellParams.value ? Math.round(cellParams.value) : 0;
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:{" "}
                {d3.format(",")(props.body.count_acv[0].count)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Annual Renewal Value:
                ${" "}
                {d3.format(",")(
                  Math.round(
                    props.body.count_acv[0].acv === undefined
                      ? props.body.count_acv[0].total_acv
                      : props.body.count_acv[0].acv
                  )
                )}
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <div
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  type: "rightAligned",
                  sortable: false,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "3px",
                  },
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                {
                  headerName: "Opportunity Name",
                  field: "OppName",
                  flex: 1.5,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Account ",
                  field: "Account_Name",
                  flex: 1.4,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Account Segment ",
                  field: "Account_Segment",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                {
                  headerName: `Account CARR`,
                  field: `Account_CARR`,
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    return "$ " + d3.format(",")(Math.round(x.value));
                  },
                },

                {
                  headerName: `Annual Renewal Value`,
                  field: `CARR`,
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    return "$ " + d3.format(",")(Math.round(x.value));
                  },
                },
                {
                  headerName: "Renewal Type",
                  field: "Renewal_Type_c",
                  flex: 0.7,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Stage",
                  field: "Stage",
                  flex: 0.5,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                {
                  headerName: "Renewal Contract Eff. Dt.",
                  field: "Renewal_Contract_Effective_Date_c",
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: agGridDateFormatter,
                },
                {
                  headerName: "Renewal Completed Date",
                  field: "closed_date",
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: agGridDateFormatter,
                },
                {
                  headerName: "Region",
                  field: "Region",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Owner",
                  field: "Owner_Name",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Team",
                  field: "Team",
                  flex: 0.8,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
              ]}
              sizeColumnsToFit={true}
              domLayout={
                props.body.opportunities.length <= 10 ? domLayout : null
              }
              headerHeight="55"
              rowData={props.body.opportunities}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={
                props.body.opportunities.length <= 10 ? onColumnResized : null
              }
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              onGridReady={gridReady}

              // cellClass='cell-wrap-text'
            ></AgGridReact>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default RenewalDrilldownBody;
