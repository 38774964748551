import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router';


export default function ProtectedRoute({ isAuthenticated, authenticationPath, ...routeProps }) {
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
};