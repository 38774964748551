import { store } from "../util/store";
const auth_url = process.env.REACT_APP_AUTH_URL;
const viz_url = process.env.REACT_APP_VIZ_URL;
const app_url = process.env.REACT_APP_URL;

function authHeader() {
  let user = JSON.parse(localStorage.getItem("skygeni-user"))
    ? JSON.parse(localStorage.getItem("skygeni-user"))
    : "";

  if (user && user.token) {
    return {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
      // "authorization": user.token
    };
  } else {
    return {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    };
  }
}

export const appService = {
  getPipelineProgressionAnalysis,
  getPipelineProgressionAnalysisDrilldown,
  getPipelineProgressionAnalysisFilters,
  login,
  forgotPass,
  changePass,
  changePassLoggedIn,
  updatePass,
  getWaterfallData,
  getWaterfallMinDate,
  getFunnelDataNew,
  getFunnelFiltersNew,
  getCreatedFunnelDataNew,
  getCreatedFunnelFiltersNew,
  getPerformanceByIndustryData,
  getPerformanceByIndustryFilters,
  closedDrilldownOnGraph,
  createdDrilldownOnGraph,
  askQuestion,
  getSettings,
  waterfallDrilldownOnGraph,
  homepageData,
  createdDrilldownOnOpen,
  createdDrilldownOnOpenNew,
  createdDrilldownOnOpen2,
  createdDrilldownOnOpen2New,
  getNewQualifiedPipelineData,
  getCurrentStateData,
  getCurrentStateFilters,
  getClosedLostOppAnalysisData,
  getClosedLostOppAnalysisFilters,
  getWinRateAnalysisData,
  getWinRateAnalysisFilters,
  getProjectedPipelineAnalysisFilters,
  getProjectedPipelineAnalysisData,
  getChartData,
  getChartFilters,
  getSegmentHistoricalPerformanceData,
  getSegmentHistoricalPerformanceFilters,
  getRepPerformanceAnalysisFilters,
  getRepPerformanceAnalysisData,
  getCreatedFunnelFilters,
  getCreatedFunnelData,
  getWinACVMixAnalysisFilters,
  getWinAcvMixAnalysisData,
  getWinAcvMixAnalysisdrilldown,
  getWinACVMixGaapAnalysisFilters,
  getWinACVMixGaapAnalysisData,
  getWinAcvMixGaapAnalysisdrilldown,
  getPyramidDrilldown,
  getPipelineMixAnalysisFilters,
  getPipelineCreatedMixAnalysisFilters,
  getPipelineCreatedAnalysisFilters,
  getPipelineCreatedMixAnalysisFilters,
  getBuildingPipelineFilters,
  getPipelineMixAnalysisData,
  getPipelineCreatedMixAnalysisData,
  getBuildingPipelineData,
  getPipelineCreatedMixAnalysisDrilldown,
  getPipelineMixAnalysisDrilldown,
  getPipelineCreatedAnalysisData,
  getPipelineMixAnalysisDrilldown,
  getPipelineCreatedAnalysisDrilldown,
  getStrengthOfSalesData,
  getStrengthOfSalesFilters,
  getWinRateAnalysisDrilldownData,
  getNewQualifiedPipelineDrilldownData,
  getScoreCardDrilldownData,
  getVersion,
  getScoreCardData,
  getWinACVDrilldown,
  getWinAcvAvgDealSizeDrilldown,
  getPbiSummaryDrilldown,
  getChartDrilldown,
  getCreatedFunnelDrilldown,
  getNewWaterfallData,
  getNewWaterfallMinDate,
  waterfallNewDrilldownOnGraph,
  getQuotaAttainmentData,
  getQuotaAttainmentDataV2,
  getQuotaAttainmentFilters,
  getRepPerformanceYTD,
  getRepPerformanceYTDFilters,
  getRepBenchmarkingFilters,
  getRepBenchmarkingData,
  getRepBenchmarkingDrilldown,
  getCompetitorAnalysisData,
  getCompetitorAnalysisFilters,
  getExpectedAcvData,
  getOpenOppData,
  getPipelineAnalysisDrilldownOnGraph,
  getPipelineTrendsRollingDrilldownOnGraph,
  getPipelineAnalysisData,
  getPipelineTrendsRollingData,
  getPipelineAnalysisFilters,
  getPipelineTrendsRollingFilters,
  getQuotaAttainmentDrilldownData,
  getNavData,
  getOppotunityPyramidData,
  getPlotlyFilters,
  getUser,
  logout,
  getNewQualifiedPipelineAccountSegmentDrilldownData,
  getScoreCardFilters,
  getAttainmentNqpData,
  getAttainmentDrilldown,
  getNQPDrilldown,
  getMQODrilldown,
  getACVCreatedDrilldown,
  getNQPData,
  getSQLCreatedData,
  getAttainmentData,
  getAttainmentDataACVRange,
  getAttainmentIFCCData,
  getPipelineCoverageData,
  getPipelineCoverageDrilldown,
  getPipelineCoverageGenericDrilldown,
  getPipelineCoverageGenericIFCCDrilldown,
  getStageProgressionData,
  getStageProgressionFilters,
  getStageProgressionDrilldown,
  getPipelineCoverageRollingDrilldown,
  getCertificationData,
  getCertificationFilters,
  getGenericScoreCardFilters,
  getAttainmentSectionDrilldown,
  getAttainmentSectionRepDrilldown,
  getClosedLostOppAnalysisDrilldown,
  getClosedLostOppAnalysisLostResonDrilldown,
  getCertificationDrilldown,
  getTeamAttainmentFilters,
  getTeamAttainmentData,
  getTeamAttainmentDrilldown,
  getWinACVGAAPAppsClusterDrilldown,
  getGenericPipelineCoverageData,
  getGenericPipelineCoverageRepData,
  getGenericPipelineCoverageDeals20KData,
  getMQOData,
  getACVCreatedData,
  getAllAEsData,
  getQuotaData,
  updateQuotaData,
  getQTDGreaterThan20kData,
  getMekkoChartFilters,
  getMekkoChartData,
  getMekkoChartDrilldown,
  getPipelineMekkoChartDrilldown,
  getPipelineMekkoChartFilters,
  getAverageDealSizeFilters,
  getAverageDealSizeData,
  getAverageDealSizeDrilldown,
  getDemandGenFilters,
  getDemandGenData,
  getDemandGenDrilldown,
  getPipelineCoverageCustTypeData,
  getPipelineCoverageDealCategoryData,
  getPipelineCoverageCustTypeRepData,
  getWaterfallOppData,
  getMomentumAnalysisFilters,
  getMomentumAnalysisData,
  getMomentumAnalysisDrilldown,
  getPipelineMekkoChartData,
  getKeyMetricsFilters,
  getKeyMetricsData,
  getKeyMetricsDrilldown,
  getAttachRateFilters,
  getAttachRateData,
  getAttchRateDrilldown,
  getManagerSummaryCustomData,
  getManagerSummaryWaterfallData,
  getTimeSeriesPredictionFilters,
  getTimeSeriesPredictionData,
  getTimeSeriesPredictionv2Filters,
  getTimeSeriesPredictionv2Data,
  getPCDMovedAnalysisData,
  getPCDMovedAnalysisFilters,
  getConversionEffecienceyDrilldown,
  getPcdMovedAnalysisDrilldown,
  getPcdMovedAnalysisQtrsMovedDrilldown,
  getRampAnalysisFilters,
  getRampAnalysisData,
  getRampAnalysisDrilldown,
  homeDrilldownOnGraph,
  getChurnAnalysisData,
  getChurnAnalysisFilters,
  getChurnAnalysisDrilldown,
  putFavourite,
  deleteFavourite,
  updateChartPosition,
  getLKPStages,
  getDashboardKeywords,
  logConvAI,
  getExecutionGuidenceData,
  getCallsCompletedData,
  getCallsCompletedDrilldownData,
  getCallsCompletedFilters,
  getCustomerInteractionData,
  getNewQualifiedPipelineDatav2,
  getNewQualifiedPipelineFiltersv2,
  getNewQualifiedPipelineDrilldownDatav2,
  getConversionEffecienceyDrilldownv2,
  generatePDFAPI,
  getURLS,
  deleteQuotaDocs,
  getPCDMovedAnalysisTrendsData,
  getEnablementEffectivenessFilters,
  getEnablementEffectivenessData,
  getEnablementEffectivenessDrilldown,
  getEnablementEffectivenessDrilldownv2,
  getPCSAData,
  getAttainmentDataWRCR,
  getNewQualifiedPipelineDatav3,
  getNewQualifiedPipelineFiltersv3,
  getPipelineDealCategoryDrilldown,
  getAccountExpansionData,
  getCurrentStateOfQualifiedPipeline,
  getCurrentStateOfQualifiedPipelineDrilldown,
  getCurrentStateOfQualifiedPipelineFilters,
  getBalancedScorecardData,
  getGrowthModelData,
  getBalancedDrilldown,
  getBalancedProgressionDrilldown,
  getPocsSummaryFilters,
  getPocsSummaryData,
  getPocsSummarydDrilldownData,
  getOpenOppToDealsData,
  getTopDealsSummaryData,
  getProjectedPipelineAnalysisFiltersv2,
  getAccountExpansionFilters,
  getAttainmentNRRData,
  getBeginningOftheQuarterQuotaAttainmentFilters,
  getBeginningOftheQuarterQuotaAttainmentData,
  getBeginningOftheQuarterAttainmentDrilldown,
  getProductsByAccountFilters,
  getProductsByAccountData,
  getProductsByAccountDataByRegion,
  getProductsByAccountUpdateData,
  getTimeSeriesPredictionByMonthData,
  getTimeSeriesPredictionByMonthFilters,
  getConversionEffecienceyDrilldownByMonth
};

function homeDrilldownOnGraph() {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/homepageDrilldown`, requestOptions).then(
    handleResponse
  );
}

function getRampAnalysisData(from, to, filters, groupbyFilters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      groupbyFilters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/rampAnalysis/data?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}
function getAccountExpansionFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(
    `${viz_url}/api/viz/account-expansion/filters`,
    requestOptions
  ).then(handleResponse);
}
function getAccountExpansionData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/account-expansion/data`,
    requestOptions
  ).then(handleResponse);
}

function getRampAnalysisDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/rampAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}
function getPipelineProgressionAnalysis(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipeline-progression/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}
function getCurrentStateOfQualifiedPipeline(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/current-state-of-qualified-pipeline/data`,
    requestOptions
  ).then(handleResponse);
}
function getCurrentStateOfQualifiedPipelineDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/current-state-of-qualified-pipeline/drilldown`,
    requestOptions
  ).then(handleResponse);
}
function getPipelineProgressionAnalysisDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipeline-progression/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}
function getPipelineProgressionAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(
    `${viz_url}/api/viz/pipeline-progression/filters?defaultQtrs=1&prior=1`,
    requestOptions
  ).then(handleResponse);
}

function getRampAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/rampAnalysis/filters`, requestOptions).then(
    handleResponse
  );
}

function getPcdMovedAnalysisDrilldown(
  { filters, drilldownFilters },
  key,
  isUnion
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({ filters, drilldownFilters }),
  };
  return fetch(
    `${viz_url}/api/viz/pcdMovedOut/drilldown?key=${key}${
      isUnion === true ? `&drilldownType=union` : ""
    }`,
    requestOptions
  ).then(handleResponse);
}

function getPcdMovedAnalysisQtrsMovedDrilldown(
  { filters, drilldownFilters },
  key,
  isUnion
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({ filters, drilldownFilters }),
  };
  return fetch(
    `${viz_url}/api/viz/pcdMovedOut/qtrsMovedDrilldown?key=${key}${
      isUnion === true ? `&drilldownType=union` : ""
    }`,
    requestOptions
  ).then(handleResponse);
}

function getConversionEffecienceyDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPCDMovedAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/pcdMovedOut/filters`, requestOptions).then(
    handleResponse
  );
}

function getPCDMovedAnalysisData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(`${viz_url}/api/viz/pcdMovedOut/data`, requestOptions).then(
    handleResponse
  );
}
function getPCDMovedAnalysisTrendsData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pcdMovedOut/trendsData`,
    requestOptions
  ).then(handleResponse);
}

function getTimeSeriesPredictionFilters(key) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/filters?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getTimeSeriesPredictionData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getTimeSeriesPredictionv2Filters(key) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/v2/filters?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getTimeSeriesPredictionv2Data(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/v2/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getManagerSummaryWaterfallData() {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/managerSummary/movedOutData`,
    requestOptions
  ).then(handleResponse);
}

function getManagerSummaryCustomData() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/managerSummary/filters`,
    requestOptions
  ).then(handleResponse);
}

function getAttchRateDrilldown(filters, key, rowName, tableType) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/productInsights/drilldown?key=${key}&rowName=${rowName}&tableType=${tableType}`,
    requestOptions
  ).then(handleResponse);
}

function getAttachRateFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/productInsights/filters`,
    requestOptions
  ).then(handleResponse);
}

function getAttachRateData(filters, key, key2) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(`${viz_url}/api/viz/productInsights/data`, requestOptions).then(
    handleResponse
  );
}

function getKeyMetricsFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/keyMetric/filters`, requestOptions).then(
    handleResponse
  );
}

function getKeyMetricsData(filters, key, key2) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/keyMetric/data?key=${key}&qualifiedOut=${key2}`,
    requestOptions
  ).then(handleResponse);
}

function getKeyMetricsDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/keyMetric/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMekkoChartData(filters, key, x, y) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/mekko/pipelineMekko/data?opp_status=${key}&xAxis=${x}&yAxis=${y}`,
    requestOptions
  ).then(handleResponse);
}

function getMomentumAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/momentum/filters`, requestOptions).then(
    handleResponse
  );
}

function getMomentumAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/momentum/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getMomentumAnalysisDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/momentum/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getWaterfallOppData(oppId) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/waterfall/oppDetails?ID=${oppId}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageCustTypeData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/data/custType?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageDealCategoryData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/data/dealCategory?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageCustTypeRepData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/rep/data/custType?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getDemandGenDrilldown(filters, key1, key2) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/demandGen/drilldown?key=${key1}&custType=${key2}`,
    requestOptions
  ).then(handleResponse);
}

function getDemandGenFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/demandGen/filters`, requestOptions).then(
    handleResponse
  );
}

function getDemandGenData(filters, primary, secondary) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/demandGen/data?primary=${primary}&secondary=${secondary}`,
    requestOptions
  ).then(handleResponse);
}

function getAverageDealSizeDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/averageDealSize/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getAverageDealSizeData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/averageDealSize/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getAverageDealSizeFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/averageDealSize/filters`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMekkoChartFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/mekko/pipelineMekko/filters`,
    requestOptions
  ).then(handleResponse);
}

function getMekkoChartFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/mekko/filters`, requestOptions).then(
    handleResponse
  );
}

function getMekkoChartData(filters, key, x, y) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/mekko/data?opp_status=${key}&xAxis=${x}&yAxis=${y}`,
    requestOptions
  ).then(handleResponse);
}

function getMekkoChartDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/mekko/drilldown?opp_status=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMekkoChartDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/mekko/pipelineMekko/drilldown?opp_status=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentDataACVRange(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/acv-range/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentIFCCData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/rep/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getQTDGreaterThan20kData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/qtdGreaterThan20K`,
    requestOptions
  ).then(handleResponse);
}

function getAllAEsData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/scorecard/allAEsYTDPerformance`,
    requestOptions
  ).then(handleResponse);
}

function getQuotaData() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/manage/quota`, requestOptions).then(
    handleResponse
  );
}

function updateQuotaData(filters) {
  console.log(filters);
  const requestOptions = {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      updatedObjects: filters,
    }),
  };

  return fetch(`${viz_url}/api/manage/quota`, requestOptions).then(
    handleResponse
  );
}

function getMQOData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/MQOCreated/generic/data`,
    requestOptions
  ).then(handleResponse);
}

function getACVCreatedData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/ACVCreated/generic/data`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentSectionDrilldown(filters, type, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/drilldown?timeFrame=${type}&qtd20=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentSectionRepDrilldown(filters, type, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/rep/drilldown?timeFrame=${type}&qtd20=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getNQPData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/nqp/v2/data?tableType=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getSQLCreatedData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/SQL_Created/data?tableType=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getNQPDrilldown(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/nqp/v2/drilldown?tableType=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getMQODrilldown(filters, type, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/MQOCreated/generic/drilldown?timeFrame=${key}&custType=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getACVCreatedDrilldown(filters, timeFrame, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/ACVCreated/generic/drilldown?timeFrame=${timeFrame}&key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/data?isProjectedCloseRolling=${true}`,
    requestOptions
  ).then(handleResponse);
}

function getCertificationData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/certificationVsPerformance/data?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getCertificationDrilldown(filters, key, from, to) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/certificationVsPerformance/drilldown?key=${key}&from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageRollingDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/getRollingDrilldown?isProjectedCloseRolling=${true}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/drilldown?current_status=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageGenericDrilldown(
  filters,
  key,
  timeFrame,
  deals20K,
  tableType
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/drilldown?current_status=${key}&timeFrame=${timeFrame}&deals20K=${deals20K}&tableType=${tableType}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineDealCategoryDrilldown(
  filters,
  key,
  timeFrame,
  deals20K,
  tableType
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/drilldown/dealCategory?current_status=${key}&timeFrame=${timeFrame}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCoverageGenericIFCCDrilldown(
  filters,
  key,
  timeFrame,
  deals20K,
  tableType
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/rep/drilldown?current_status=${key}&timeFrame=${timeFrame}&deals20K=${deals20K}&tableType=${tableType}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentNqpData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/attainment/data`, requestOptions).then(
    handleResponse
  );
}

function getAttainmentDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getGenericPipelineCoverageData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getGenericPipelineCoverageRepData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/rep/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getGenericPipelineCoverageDeals20KData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/dealsGreterThan20K?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getScoreCardFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/attainment/filters`, requestOptions).then(
    handleResponse
  );
}

function getGenericScoreCardFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/attainment/generic/filters`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineDrilldownData(filters = []) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/drilldown`,
    requestOptions
  ).then(handleResponse);
}
function getRepPerformanceYTD(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/repPerformanceYTD/data`,
    requestOptions
  ).then(handleResponse);
}

function getRepPerformanceYTDFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/repPerformanceYTD/filters`,
    requestOptions
  ).then(handleResponse);
}

function getScoreCardDrilldownData(key, filters = [], company) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/drilldown?id=${company}.CHID00000018&key=${key}`,
    requestOptions
  ).then(handleResponse);
}
function getChartDrilldown(id, key, filters = []) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/drilldown?id=${id}&key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getCreatedFunnelDrilldown(filters = [], key, closedStage) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      finalStageForClosed: closedStage,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/createdFunnel/drilldown?key=${key}&finalStageForClosed=${closedStage}`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineAccountSegmentDrilldownData(
  filters = [],
  team,
  tableType
) {
  console.log(team);
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/acctSegmentDrilldown?Team=${team}&tableType=${tableType}`,
    requestOptions
  ).then(handleResponse);
}

function getWinACVDrilldown(filters = [], { company, closed }) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/drilldown?id=C0003.CHID00000011&company=${company}&key=${closed}`,
    requestOptions
  ).then(handleResponse);
}

function getWinAcvAvgDealSizeDrilldown(filters = [], { company, closed }) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/drilldown/v2?id=C0003.CHID00000011&company=${company}&key=${closed}`,
    requestOptions
  ).then(handleResponse);
}

function getPbiSummaryDrilldown(
  filters = [],
  { appsCluster, oppStatus, type }
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/appsCluster/drilldown?${
      type ? type : "appsCluster"
    }=${appsCluster}&oppStatus=${oppStatus}`,
    requestOptions
  ).then(handleResponse);
}

function getWinACVGAAPAppsClusterDrilldown(
  filters = [],
  { appsCluster, oppStatus, type }
) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/appsClusterGAAP/drilldown?${type}=${appsCluster}&oppStatus=${oppStatus}`,
    requestOptions
  ).then(handleResponse);
}

function getCurrentStateFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/crossedStage/filters`, requestOptions).then(
    handleResponse
  );
}

function getVersion() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
  };

  return fetch(`${app_url}/version`, requestOptions).then(handleResponse);
}

function getCurrentStateData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/crossedStage/data`, requestOptions).then(
    handleResponse
  );
}

function getNewQualifiedPipelineData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/data`,
    requestOptions
  ).then(handleResponse);
}

function waterfallDrilldownOnGraph(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/waterfallRange/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getSettings() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/settings`, requestOptions).then(
    handleResponse
  );
}
function getNewWaterfallData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/waterfall/data?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getNewWaterfallMinDate() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/waterfall/dataRange`, requestOptions).then(
    handleResponse
  );
}

function waterfallNewDrilldownOnGraph(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/waterfall/drilldown`, requestOptions).then(
    handleResponse
  );
}
function getPerformanceByIndustryData(filters, solutionCategory) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      solutionCats: solutionCategory,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/performanceByIndustry/data`,
    requestOptions
  ).then(handleResponse);
}

function getPerformanceByIndustryFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/performanceByIndustry/filters`,
    requestOptions
  ).then(handleResponse);
}

function getCreatedFunnelDataNew(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/funnelNew/created`, requestOptions).then(
    handleResponse
  );
}

function getCreatedFunnelFiltersNew() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/funnelNew/created/filters`,
    requestOptions
  ).then(handleResponse);
}

function getFunnelDataNew(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/closedFunnel/data`, requestOptions).then(
    handleResponse
  );
}

function getFunnelFiltersNew() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/closedFunnel/filters`, requestOptions).then(
    handleResponse
  );
}

function getWaterfallMinDate() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/waterfallRange/dataRange`,
    requestOptions
  ).then(handleResponse);
}

function getWaterfallData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/waterfallRange?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function updatePass(password) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      token: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).token
        : "",
      password: password,
    }),
  };

  return fetch(`${viz_url}/api/auth/v2/change-password`, requestOptions).then(
    (response) => {
      return response.json().then((data) => {
        if (response.status !== 200) {
          const error = data.message || "Something went wrong";
          return Promise.reject(error);
        } else {
          if (data.message.token) {
            localStorage.setItem("skygeni-user", JSON.stringify(data.message));
          }
          return data;
        }
      });
    }
  );
}

function changePass(code, password, token, email) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      email: email,
      code,
      token: token,
      password: password,
    }),
  };

  return fetch(`${viz_url}/api/auth/v2/reset-password`, requestOptions).then(
    handleResponse
  );
}

function changePassLoggedIn(oldpassword, newpassword) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      token: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).token
        : "",
      oldpassword: oldpassword,
      newpassword: newpassword,
    }),
  };

  return fetch(`${viz_url}/api/auth/v2/update-password`, requestOptions).then(
    handleResponse
  );
}

function forgotPass(email) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      email: email,
    }),
  };

  return fetch(`${viz_url}/api/auth/v2/forgot-password`, requestOptions).then(
    handleResponse
  );
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Credentials": true,
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  };

  return fetch(`${viz_url}/api/auth/nativeLogin`, requestOptions).then(
    (response) => {
      return response.json().then((data) => {
        if (data.message.oldVersionDetected || response.status === 409) {
          const newVersion = data.message.version;
          localStorage.setItem("skygeni-viz-version", newVersion);
          console.log("version_mismatch");
          return Promise.reject({
            isLoggedIn: false,
            message: "Loading updated app",
          });
        }
        if (response.status !== 200) {
          let error = "";

          if (
            data.returnCode === 603 ||
            data.returnCode === 600 ||
            data.returnCode === 602 ||
            data.returnCode === 600
          ) {
            error = data;
          } else if (data.returnCode) {
            error = data || "Something went wrong";
          }
          return Promise.reject(error);
        } else {
          if (data.message.token) {
            localStorage.setItem("skygeni-user", JSON.stringify(data.message));
          }
          return data;
        }
      });
    }
  );
}

export function handleResponse(response) {
  return response.json().then((json) => {
    console.log(response.status, 2552);
    if (json.oldVersionDetected || response.status === 409) {
      const newVersion = json.version;
      store.dispatch({ type: "udpating_app" });
      localStorage.setItem("skygeni-viz-version", newVersion);
      localStorage.setItem("skygeni-auto-update", true);
      console.log("version_mismatch");
      setTimeout(() => {
        window.location.reload();
      }, 500);
      console.log("dispatched udpating app action to the redux");

      return Promise.resolve("Updating app");
    }
    // here we wont be setting up the auto update state, so it is perfectly normal to remove the auto update state.
    if (![200, 201].includes(response.status)) {
      if (
        json.errorCode === 600 ||
        json.errorCode === 601 ||
        json.errorCode === 602 ||
        json.errorCode === 603
      ) {
        store.dispatch({ type: "signout_inactivity", payload: json });
      } else if (json.errorCode === 604) {
        store.dispatch({ type: "unprovisioned_user", payload: json });
      } else if (response.status === 403 || response.status === 401) {
        store.dispatch({ type: "signout_inactivity", payload: json });
      } else if (response.status === 406) {
        store.dispatch({ type: "change_pass_loggedin_failure", payload: json });
      } else if (response.status === 400) {
        store.dispatch({ type: "quota_letter_failure", payload: json });
      }

      // if(response.status === 403 || response.status === 401 ){
      //   console.log("sign_out", response.status)
      //   store.dispatch({ type: 'signout_inactivity', payload: json })
      // }

      const queryParams = new URLSearchParams(window.location.search);
      const errorParams = queryParams.get("error");
      const errorObject = JSON.parse(errorParams);

      if (errorObject && errorObject.errorCode) {
        store.dispatch({ type: "signout_inactivity", payload: errorObject });
      }

      //  console.log(response.status, 635)
      // if(json.errorCode === 600 || json.errorCode === 601 || json.errorCode === 602 || json.errorCode === 603 ){
      //   store.dispatch({ type: 'signout_inactivity', payload: json })
      // }
      // else if (response.status === 403 || response.status === 401  ) {
      //   store.dispatch({ type: 'signout_inactivity', payload: json })
      // }
      const error = (json && json) || "Something went wrong";
      return Promise.reject(error);
    } else {
      return json;
    }
  });
}

// export function handleResponse(response) {
//   return response.json().then(json => {
//     if (response.status !== 200) {
//       if (response.status === 403 || response.status === 401) {
//         store.dispatch({ type: 'signout', payload: json.message })
//         setTimeout(() => {
//           // window.location.reload()
//         }, 1000);
//       }
//       const error = (json && json.message) || 'Something went wrong';
//       return Promise.reject(error);
//     } else {
//       return json
//     }
//   });
// }
function getCertificationFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/certificationVsPerformance/filters`,
    requestOptions
  ).then(handleResponse);
}

function getStageProgressionFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/stageProgression/filters`,
    requestOptions
  ).then(handleResponse);
}

function getStageProgressionData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/stageProgression/data?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getStageProgressionDrilldown(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/stageProgression/drilldown?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function createdDrilldownOnGraph(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/funnelNew/created/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function closedDrilldownOnGraph(key, filters) {
  console.log(key, filters);
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/closedFunnel/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function askQuestion(question) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "X-Client-Version": String(localStorage.getItem("skygeni-viz-version")),
      "x-client-name": "skygeni-webapp",
    },
    body: JSON.stringify({
      viz_url,
      message: question,
      authenticationKey: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).token
        : "",
      first_name: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).firstName
        : "",
      last_name: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).lastName
        : "",
      UserID: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).userID
        : "",
      Display_Name: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).displayName
        : "",
      Role_ID: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).RoleID
        : "",
      Company_Name: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).companyName
        : "",
      Company_ID: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).company
        : "",
      Role_Name: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).roleName
        : "",
      Role_Type: JSON.parse(localStorage.getItem("skygeni-user"))
        ? JSON.parse(localStorage.getItem("skygeni-user")).roleType
        : "",
    }),
  };
  return fetch(
    `${viz_url}/api/viz/conversationalAI/query`,
    requestOptions
  ).then(handleResponse);
}

function homepageData() {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(`${viz_url}/api/viz/homepageData`, requestOptions).then(
    handleResponse
  );
}

function createdDrilldownOnOpen(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/funnelNew/created/openDrillDown`,
    requestOptions
  ).then(handleResponse);
}
function createdDrilldownOnOpenNew(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/createdFunnel/openDrilldown`,
    requestOptions
  ).then(handleResponse);
}
function createdDrilldownOnOpen2New(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/createdFunnel/openDrilldown2`,
    requestOptions
  ).then(handleResponse);
}

function createdDrilldownOnOpen2(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/funnelNew/created/openDrillDown2`,
    requestOptions
  ).then(handleResponse);
}

function getClosedLostOppAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/lostOppsAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getClosedLostOppAnalysisDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/lostOppsAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getClosedLostOppAnalysisLostResonDrilldown(filters, drilldownFilters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters,
      drilldownFilters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/lostOppsAnalysis/drilldown/lostReason`,
    requestOptions
  ).then(handleResponse);
}

function getClosedLostOppAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/lostOppsAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getStrengthOfSalesData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/chart?id=C0003.CHID0000002`,
    requestOptions
  ).then(handleResponse);
}

function getStrengthOfSalesFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/filters?id=C0003.CHID0000002`,
    requestOptions
  ).then(handleResponse);
}

function getWinRateAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/winrateAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}
function getWinRateAnalysisDrilldownData(filters, stage, quarter, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      stage: stage,
      quarter: quarter,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/winrateAnalysis/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getChartData(id, filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/chart?id=${id}&key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getSegmentHistoricalPerformanceData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/segmentHistoricalPerformance/data`,
    requestOptions
  ).then(handleResponse);
}

function getTeamAttainmentData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/teamAttainment/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getTeamAttainmentDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/teamAttainment/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getRepPerformanceAnalysisData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/repPerformanceAnalysis/data`,
    requestOptions
  ).then(handleResponse);
}

function getCreatedFunnelData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",

    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  console.log(authHeader());

  return fetch(`${viz_url}/api/viz/createdFunnel/data`, requestOptions).then(
    handleResponse
  );
}

function getWinAcvMixAnalysisData(key, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/winAcvMixAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getWinACVMixGaapAnalysisData(key, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/winAcvMixGAAPAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getWinAcvMixAnalysisdrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/winAcvMixAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getWinAcvMixGaapAnalysisdrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/winAcvMixGAAPAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPyramidDrilldown(filters, key, qtr) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/opportunityPyramid/drilldown?key=${key}&qtr=${qtr}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMixAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineMixAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCreatedMixAnalysisData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCreatedMixAnalysis/data`,
    requestOptions
  ).then(handleResponse);
}

function getBuildingPipelineData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/buldingPipeline/data`, requestOptions).then(
    handleResponse
  );
}

function getPipelineCreatedAnalysisData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCreatedAnalysis/data`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMixAnalysisDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineMixAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCreatedMixAnalysisDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineCreatedMixAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCreatedAnalysisDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pipelineCreatedAnalysis/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getScoreCardData(id, key, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/chart?id=${id}&key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getChartFilters(id) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/filters?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getSegmentHistoricalPerformanceFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/segmentHistoricalPerformance/filters`,
    requestOptions
  ).then(handleResponse);
}

function getTeamAttainmentFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/teamAttainment/filters`,
    requestOptions
  ).then(handleResponse);
}

function getRepPerformanceAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/repPerformanceAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getCreatedFunnelFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/createdFunnel/filters`, requestOptions).then(
    handleResponse
  );
}

function getWinACVMixAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/winAcvMixAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getWinACVMixGaapAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/winAcvMixGAAPAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineMixAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/pipelineMixAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}
function getCurrentStateOfQualifiedPipelineFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/current-state-of-qualified-pipeline/filters`,
    requestOptions
  ).then(handleResponse);
}
function getPipelineCreatedMixAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCreatedMixAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getBuildingPipelineFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/buldingPipeline/filters`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineCreatedAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCreatedAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getWinRateAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/winrateAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getProjectedPipelineAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/projectedPipelineAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}

function getProjectedPipelineAnalysisData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/projectedPipelineAnalysis/data?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getQuotaAttainmentFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/filters`,
    requestOptions
  ).then(handleResponse);
}

function getQuotaAttainmentData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getQuotaAttainmentDataV2(filters, key, groupbyFilters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      groupbyFilters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/v2/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getRepBenchmarkingFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/repBenchmarking/filters`,
    requestOptions
  ).then(handleResponse);
}

function getQuotaAttainmentDrilldownData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/drilldown`,
    requestOptions
  ).then(handleResponse);
}
//adding getfunnelCompareData function

function getfunnelCompareData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/repBenchmarking/data?closed_fiscal_quarter=${filters[0].value}`,
    requestOptions
  ).then(handleResponse);
}

// function getRepBenchmarkingData(filters, key) {
//   const requestOptions = {
//     method: "POST",
//     mode: "cors",
//     cache: "no-cache",
//     credentials: "include",
//     headers: authHeader(),
//     body: JSON.stringify({
//       filters: filters
//     })
//   };

//   return fetch(`${viz_url}/api/viz/repBenchmarking/data?key=${key}`, requestOptions)
//     .then(handleResponse)
// }

function getRepBenchmarkingData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/repBenchmarking/data?closed_fiscal_quarter=${filters[0].value}`,
    requestOptions
  ).then(handleResponse);
}

function getRepBenchmarkingDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/repBenchmarking/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getCompetitorAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/competitorAnalysis/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getCompetitorAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/competitorAnalysis/filters`,
    requestOptions
  ).then(handleResponse);
}
function getExpectedAcvData(oppId) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/oppDetails?ID=${oppId}`,
    requestOptions
  ).then(handleResponse);
}

function getOpenOppData(oppId) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/openOppDetails?ID=${oppId}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineAnalysisData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrends/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineTrendsRollingFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrendsRolling/filters`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineTrendsRollingData(filters, meta) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrendsRolling/data?isProjectedCloseRolling=${meta.isRolling}`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrends/dataRange`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineAnalysisDrilldownOnGraph(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrends/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPipelineTrendsRollingDrilldownOnGraph(filters, { isRolling }) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineTrendsRolling/drilldown?isProjectedCloseRolling=${isRolling}`,
    requestOptions
  ).then(handleResponse);
}

function getNavData(userName) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${viz_url}/api/viz/nav?username=${userName}`,
    requestOptions
  ).then(handleResponse);
}

function getOppotunityPyramidData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/opportunityPyramid/data?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getPlotlyFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/opportunityPyramid/filters`,
    requestOptions
  ).then(handleResponse);
}

function getUser() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/auth/getUser`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/auth/logout`, requestOptions).then(
    handleResponse
  );
}

function getChurnAnalysisFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/churnAnalysis/filters`, requestOptions).then(
    handleResponse
  );
}
function getLKPStages() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/viz/lkp-stages`, requestOptions).then(
    handleResponse
  );
}
function getDashboardKeywords() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/conversationalAI/keywords`,
    requestOptions
  ).then(handleResponse);
}

function getChurnAnalysisData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/churnAnalysis/data`, requestOptions).then(
    handleResponse
  );
}

function getChurnAnalysisDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/churnAnalysis/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}
function updateChartPosition(payload) {
  const requestOptions = {
    method: "PATCH",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      updated: payload,
    }),
  };
  console.log("payload_check", JSON.stringify({ updated: payload }));
  return fetch(`${viz_url}/api/manage/profile/favourite`, requestOptions).then(
    handleResponse
  );
}

function getExecutionGuidenceData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/executionGuidence/data`,
    requestOptions
  ).then(handleResponse);
}

function getCallsCompletedData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(`${viz_url}/api/viz/callsCompleted/data`, requestOptions).then(
    handleResponse
  );
}

function getCallsCompletedDrilldownData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/callsCompleted/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function putFavourite(chartID) {
  const requestOptions = {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(
    `${viz_url}/api/manage/profile/favourite?dashboard_id=${chartID}`,
    requestOptions
  ).then(handleResponse);
}
function deleteFavourite(chartID) {
  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(
    `${viz_url}/api/manage/profile/favourite?dashboard_id=${chartID}`,
    requestOptions
  ).then(handleResponse);
}
function logConvAI(conversation) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      ...conversation,
    }),
  };

  return fetch(`${viz_url}/api/viz/conversationalAI/log`, requestOptions).then(
    handleResponse
  );
}

function getCallsCompletedFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/callsCompleted/filters`,
    requestOptions
  ).then(handleResponse);
}

function getCustomerInteractionData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/customerInteraction/data`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineDatav2(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/v2/data`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineDatav3(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/v3/data`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineFiltersv2() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/v2/filters`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineFiltersv3() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/v3/filters`,
    requestOptions
  ).then(handleResponse);
}

function getNewQualifiedPipelineDrilldownDatav2(filters = []) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/newQualifiedPipeline/v2/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getConversionEffecienceyDrilldownv2(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/v2/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

//Generate PDF API
function generatePDFAPI() {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: "",
  };

  return fetch(`${viz_url}/api/manage/updatedoctemplate`, requestOptions).then(
    handleResponse
  );
}

//GET URLS for QuotaLetters
function getURLS() {
  console.log("api_called");
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(`${viz_url}/api/manage/geturls`, requestOptions).then(
    handleResponse
  );
}

//Delete DOCS
function deleteQuotaDocs() {
  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: "",
  };
  console.log("apiCalled");

  return fetch(`${viz_url}/api/manage/deletedocs`, requestOptions);
}

function getEnablementEffectivenessFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/enablementEffectiveness/filters`,
    requestOptions
  ).then(handleResponse);
}

function getEnablementEffectivenessData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/enablementEffectiveness/data`,
    requestOptions
  ).then(handleResponse);
}

function getEnablementEffectivenessDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/enablementEffectiveness/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getEnablementEffectivenessDrilldownv2(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/enablementEffectiveness/v2/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPCSAData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverageAndShapeAnalysis/data`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentDataWRCR(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic/wrcr/data?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getBalancedScorecardData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/balancedScorecard/data`,
    requestOptions
  ).then(handleResponse);
}

function getGrowthModelData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/growth-model`,
    requestOptions
  ).then(handleResponse);
}

function getBalancedDrilldown(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/balancedScorecard/drilldown?table=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getBalancedProgressionDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/pipelineCoverage/generic/balancedScorecard/progression/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getPocsSummaryFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(`${viz_url}/api/viz/pocsSummary/filters`, requestOptions).then(
    handleResponse
  );
}

function getPocsSummaryData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(`${viz_url}/api/viz/pocsSummary/data`, requestOptions).then(
    handleResponse
  );
}

function getPocsSummarydDrilldownData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/pocsSummary/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}

function getOpenOppToDealsData(oppId) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/openOppDetails/topDeals?ID=${oppId}`,
    requestOptions
  ).then(handleResponse);
}

function getProjectedPipelineAnalysisFiltersv2() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/projectedPipelineAnalysis/filters/topDeals`,
    requestOptions
  ).then(handleResponse);
}

function getTopDealsSummaryData(from, to, filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/projectedPipelineAnalysis/data/topDeals?from=${from}&to=${to}`,
    requestOptions
  ).then(handleResponse);
}

function getAttainmentNRRData(filters, type) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/attainment/generic//data/Non-Renewals_Renewals?timeFrame=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getBeginningOftheQuarterQuotaAttainmentFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/Beginning_Of_Quarter_Qualified_Pipe_Attainment/filters`,
    requestOptions
  ).then(handleResponse);
}

function getBeginningOftheQuarterQuotaAttainmentData(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/quotaAttainment/Beginning_Of_Quarter_Qualified_Pipe_Attainment/data`,
    requestOptions
  ).then(handleResponse);
}

function getBeginningOftheQuarterAttainmentDrilldown(filters) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/quotaAttainment/Beginning_Of_Quarter_Qualified_Pipe_Attainment/drilldown`,
    requestOptions
  ).then(handleResponse);
}

function getProductsByAccountFilters() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };

  return fetch(
    `${viz_url}/api/viz/productsByAccount/filters`,
    requestOptions
  ).then(handleResponse);
}

function getProductsByAccountData(filters = []) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/productsByAccount/data`,
    requestOptions
  ).then(handleResponse);
}

function getProductsByAccountUpdateData(filters = [], updateFilters = []) {
  const requestOptions = {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
      updateFilters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/productsByAccount/data`,
    requestOptions
  ).then(handleResponse);
}

function getProductsByAccountDataByRegion(filters = []) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };

  return fetch(
    `${viz_url}/api/viz/productsByAccount/dataByRegion`,
    requestOptions
  ).then(handleResponse);
}



function getTimeSeriesPredictionByMonthFilters(key) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: null,
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/month/filters`,
    requestOptions
  ).then(handleResponse);
}

function getTimeSeriesPredictionByMonthData(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/month/data`,
    requestOptions
  ).then(handleResponse);
}

function getConversionEffecienceyDrilldownByMonth(filters, key) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify({
      filters: filters,
    }),
  };
  return fetch(
    `${viz_url}/api/viz/timeseriesBasedPrediction/month/drilldown?key=${key}`,
    requestOptions
  ).then(handleResponse);
}
