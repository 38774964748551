import React, { Fragment } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button, Card } from "@material-ui/core";
import InsightsCard from "../components/InsightsCard";
import AttachRate from "./AttachRate/AttachRate";

const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});

class ProductInsights extends React.Component {
  handleLocationChange = (loc) => {
    this.props.changeRepPerformancePage(loc);
  };

  render() {
    const { classes } = this.props;
    const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
    var charts = [
      {
        title: "Attach rate for Product Add-ons",
        component: (
          <InsightsCard
            header="Attach rate for Product Add-ons"
            text="Examine attach rates for various Add-on products for both won and lost opportunities."
            image={`${baseURL}factors.png`}
            changeLocation={this.handleLocationChange}
          />
        ),
      },
    ];
    return (
      <Fragment>
        <div
          className={classes.dashOuter}
          style={
            this.props.location === "home"
              ? { maxWidth: 1100 }
              : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }
          }
        >
          {this.props.location === "home" && (
            <Grid
              container
              spacing={5}
              className={classes.grid}
              wrap="wrap"
              justify="flex-start"
              alignItems="center"
            >
              {charts !== undefined &&
                this.props.charts.map((it) =>
                  charts.filter((chart) => chart.title === it.Chart_Name)[0] !==
                  undefined
                    ? charts.filter((chart) => chart.title === it.Chart_Name)[0]
                        .component
                    : null
                )}
            </Grid>
          )}
          {this.props.location === "Attach rate for Product Add-ons" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <AttachRate />
              </Grid>
            </Grid>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { productInsightsPage, user } = state.app;

  return {
    location: productInsightsPage,
    charts: user.elements
      .filter((item) => item.navItem === "Product Insights")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    localStorage.removeItem("skygeni-user");
  },
  changeRepPerformancePage: (loc) => {
    console.log("GA ->", loc);
    ReactGA.pageview(loc);
    dispatch({ type: "change_productInsightsPage", loc });
    localStorage.setItem("skygeni-user-productPage", loc);
    localStorage.removeItem("skygeni-user-enhancedPage");
    localStorage.removeItem("skygeni-user-repPage");
  },
});

const connectedProductInsights = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductInsights));
export { connectedProductInsights as ProductInsights };
