import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  customDateFormatter,
  customDateFormatterNew,
  getDefaultSelectedStage,
  getUniqueValues,
} from "../../util/customFunctions";
import DrilldownBody from "./DrilldownBody";
import NQPDrilldown from "./NQPDrilldown";
import DatePicker from "../../components/DatePicker_v2";

import QualifiedPipelineTable from "./QualifiedPipelineTable";
import { weekEndingMapping } from "./utils";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import { styled } from "@mui/material/styles";
import Drilldown from "../GenericScorecard/Drilldown";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class NewQualifiedPipelinev2 extends Component {
  state = {
    last: 60,
    activelast: 60,
    timeFrame: "window",
    from: "",
    to: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    location: null,
    secondLevelLocation: null,
    toggleValue: "CurrentAcv",
    milestone_stage: "",
    stageFilter: [],
    drilldownStage: "",
    Opportunity_Record_Type: ["All"],
  };
  componentDidMount = () => {
    this.props.getfunnelCompareFilters(this.props.company);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      this.setState({
        ...this.state,
      });
    }
  }

  drilldown = (rep, weekEnding, avgDrilldownFlag, metaData) => {
    console.log(rep, weekEnding);

    var filters = [],
      filtersForDrilldown = [],
      heading = "";
    if (["Twelve", "Eight", "Four"].includes(weekEnding)) {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineDatav2.timeFrameEnding,
          value:
            this.props.NewQualifiedPipelineDatav2.trailingTwelveWeeks.slice(
              0,
              weekEndingMapping[weekEnding]
            ),
          string: true,
        },
      ];
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value:
            weekEnding === "Twelve"
              ? this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 6 months"
                : "Trailing 12 weeks"
              : weekEnding === "Eight"
              ? this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 4 months"
                : "Trailing 8 weeks"
              : this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
              ? "Trailing 2 months"
              : "Trailing 4 weeks",
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineDatav2.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline Trends";
    } else {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineDatav2.timeFrameEnding,
          value: weekEnding,
          string: true,
        },
      ];
      const timeEnded =
        this.props.NewQualifiedPipelineDatav2.timeFrameEnding === "Week_Ending"
          ? "Week"
          : "Half-Month";
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value: `${timeEnded} ended ${customDateFormatter(weekEnding)}`,
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineDatav2.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];
      heading = `New Qualified Pipeline`;
    }

    if (!this.state.Opportunity_Record_Type.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Opportunity_Record_Type",
          value: this.state.Opportunity_Record_Type,
          string: true,
        },
      ];
    }

    this.props.getNewQualifiedPipelineDrilldownData(filters);

    this.setState({
      ...this.state,
      isMetaData: avgDrilldownFlag,
      metaData,
      drilldownLabelString:
        this.props.NewQualifiedPipelineDatav2.timeFrameEnding === "Week_Ending"
          ? "week"
          : "half-month",
      heading,
      location: "drilldown",
      filtersForDrilldown: filtersForDrilldown,
    });
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChangev1 = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = () => {
    this.setState({ ...this.state, location: "Home", filtersForDrilldown: [] });
    // this.props.clearDrilldownData()
  };

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        30: this.props.funnelCompareFilters.dateFilters.prior1Month.split("-"),
        60: this.props.funnelCompareFilters.dateFilters.prior2Month.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.funnelCompareFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters = [];

    let stage_names = [];

    if (!this.state.Opportunity_Record_Type.includes("All")) {
      filters = [
        ...filters,
        {
          name: "Opportunity_Record_Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];
    }

    this.setState({
      ...this.state,
    });
    this.props.getNewQualifiedPipelineDatav2(filters);
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId, "OPPID");
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>New Pipeline Creation and Cadence</Typography>
        </Grid>
        <Typography variant='body1'>
        Examine the cadence and consistency of new pipeline creation by milestone stage.
        </Typography> */}
        {addHeaderdescription(
          "New Qualified pipeline",
          "Opportunities that came to, or went past the Qualified stage, moving in the forward direction, and for the first time."
        )}

        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={2} md={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state["Opportunity_Record_Type"].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Opportunity Record Type:
                    </InputLabel>
                    <Select
                      data-test="Type"
                      value={this.state["Opportunity_Record_Type"]}
                      onChange={this.handleChangev1}
                      name={"Opportunity_Record_Type"}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.oppRecordType.map(
                          (y) => (
                            <MenuItem
                              key={y["Opportunity_Record_Type"]}
                              value={y["Opportunity_Record_Type"]}
                            >
                              {y["Opportunity_Record_Type"]}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineDatav2 !== "" &&
          this.funnelCompareFilters !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >{`New Qualified Opportunities (came to ${this.props.NewQualifiedPipelineDatav2.stage} or later) by rep (grouped by team)`}</Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip title={`ACV`} placement="bottom">
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title="Current ACV"
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <QualifiedPipelineTable
                  thresholds={this.props.NewQualifiedPipelineDatav2.threshold}
                  drilldown={this.drilldown}
                  isColDate={true}
                  name="trailing-week"
                  columns={this.props.detailsColumns.sort()}
                  isAverage={false}
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACVAverage"
                      : "Average_Acv"
                  }
                  data={this.props.NewQualifiedPipelineDatav2.detail}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : ["# of Opps", `ACV at Qualified`, "Avg. ACV"]
                  }
                  col="week"
                  tableHeading={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding
                  }
                  teamNames={this.props.detailsTeams}
                  dataID={"NQP_Created_table"}
                  Value_Label={this.props.Value_Label}
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineDatav2 !== "" &&
          this.funnelCompareFilters !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{ width: "100%", margin: "auto", padding: "1rem" }}
                      align="center"
                    >
                      {
                        this.props.NewQualifiedPipelineDatav2
                          .traillingtableHeader
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip title={`ACV`} placement="bottom">
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title="Current ACV"
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                <QualifiedPipelineTable
                  name="opp-by-rep"
                  thresholds={this.props.NewQualifiedPipelineDatav2.threshold}
                  drilldown={this.drilldown}
                  isColDate={false}
                  isAverage={true}
                  col="Week_Ending"
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol2={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksAverageCurrentACV"
                      : "WeeksAverageACV"
                  }
                  avgCol3={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksCurrentAverage"
                      : "WeeksAverage"
                  }
                  columns={this.props.columns}
                  traillingColumns={
                    this.props.NewQualifiedPipelineDatav2.endingAvgColNames
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  data={this.props.NewQualifiedPipelineDatav2.summary}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : ["# of Opps", `ACV at Qualified`, "Avg. ACV"]
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding
                  }
                  tableHeading={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  teamNames={this.props.teams}
                  dataID={"NQP_Created_table_v2"}
                  Value_Label={this.props.Value_Label}
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>

        {this.state.location === "drilldown" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.heading}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={`New Qualified Pipeline`}
                body={this.props.NewQualifiedPipelineDrilldownData}
                nqpScoreCard={true}
                showCurrentACV={this.state.toggleValue}
                trailingAvarage={this.state.metaData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                showPointer={true}
                sld={true}
              />
              {/* <Drilldown body={this.props.NewQualifiedPipelineDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
               company={this.props.company} handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
               />   */}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    NewQualifiedPipelineDatav2,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    NewQualifiedPipelineFiltersv2,
    currentFilters,
  } = state.app;
  var transformedNewQualifiedPipeline,
    teams,
    columns,
    detailsTeams,
    detailsColumns,
    timeframe;
  if (NewQualifiedPipelineDatav2 !== "") {
    teams = NewQualifiedPipelineDatav2.summary
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    timeframe = NewQualifiedPipelineDatav2.timeFrameEnding;
    columns = NewQualifiedPipelineDatav2.summary
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    detailsTeams = NewQualifiedPipelineDatav2.detail
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    detailsColumns = NewQualifiedPipelineDatav2.detail
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    NewQualifiedPipelineDatav2.summary = NewQualifiedPipelineDatav2.summary.map(
      (item) => {
        if (item[timeframe] === "Trailing 8 Week") {
          return {
            ...item,
            weekEnding: "Eight",
          };
        } else if (item[timeframe] === "Trailing 12 Week") {
          return {
            ...item,
            weekEnding: "Twelve",
          };
        } else if (item[timeframe] === "Trailing 4 Week") {
          return {
            ...item,
            weekEnding: "Four",
          };
        }
      }
    );
  }

  return {
    company: user.company,
    Value_Label: user.Value_Label,
    columns,
    teams,
    detailsTeams,
    detailsColumns,
    NewQualifiedPipelineDatav2,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    funnelCompareFilters: NewQualifiedPipelineFiltersv2,
    currentFilters,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_NewQualifiedPipeline_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_nqp_nop_filters_request" });
    appService.getNewQualifiedPipelineFiltersv3().then(
      (json) => {
        dispatch({ type: "get_nqp_nop_filters_success", json });
        dispatch({ type: "get_nqp_nop_data_request" });
        let filters = [];

        dispatch({ type: "current_selected_filters", filters });
        appService.getNewQualifiedPipelineDatav3(filters).then(
          (json) => {
            dispatch({ type: "get_nqp_nop_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_nqp_nop_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_nqp_nop_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nqp_nop_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_nqp_nop_filters_failure", error });
      }
    );
  },
  getNewQualifiedPipelineDrilldownData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_drilldown_data_request" });
    appService.getNewQualifiedPipelineDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({
          type: "get_NewQualifiedPipeline_drilldown_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error,
          });
      }
    );
  },
  getNewQualifiedPipelineDatav2: (filters) => {
    dispatch({ type: "get_nqp_nop_data_request" });
    appService.getNewQualifiedPipelineDatav3(filters).then(
      (json) => {
        dispatch({ type: "get_nqp_nop_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nqp_nop_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_nqp_nop_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedNewQualifiedPipelinev3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewQualifiedPipelinev2));
export default connectedNewQualifiedPipelinev3;
