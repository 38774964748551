import React from "react";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
import { accountExpansionSummaryData } from "./utils";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { countOrACVFormatter } from "../../util/customFunctions";
import { useStyles } from "./style";

const AccountExpansionSummaryTable = (props) => {
  const classes = useStyles();
  console.log("accountExpansionSummary", props.data);
  const summary = props.data.summaryInfo;
  console.log("accountExpansionSummary", summary);
  return (
    <CopyToClipboardTableContainer datatest="Summary-Accounts">
      <TableHead className={classes.fontBold}>
        <TableRow>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            rowSpan={2}
          >
            Number of Active Accounts
          </TableCell>
          <TableCell
            style={{ background: props.priorColor }}
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            colSpan={2}
          >{`Prior Fiscal Year (FY ${props.data.priorFiscalYear}) Won Opps`}</TableCell>
          <TableCell
            style={{ background: props.currentColor }}
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            colSpan={6}
          >{`Current Fiscal Year (FY ${props.data.currentFiscalYear}) Opps - Won + Active`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            rowSpan={2}
          >
            Delta
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`# of Opps`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`ACV`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Won # of Opps`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Won ACV`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Active # of Opps`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Active ACV`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
          >{`Total # of Opps`}</TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.fixedWidthCell}`}
            style={{ width: "120px" }}
          >{`Total ACV`}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.fontBold}>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.numActiveAccounts, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.totalPriorOpps, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.totalPriorACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.wonOpps, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.wonACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.activeOpps, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.activeACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.totalOpps, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.totalACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.totalDelta, true)}
          </TableCell>
        </TableRow>
      </TableBody>
    </CopyToClipboardTableContainer>
  );
};

export default AccountExpansionSummaryTable;
