import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function NoDataComponent() {
    return (
        <Grid item container justify="center">
            <Typography align="center" variant='h2' style={{ margin: '1rem' }}>No Data Available</Typography>
        </Grid>
    )
}
