import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const autoUpdateKey = "skygeni-auto-update";
    const message = localStorage.getItem(autoUpdateKey);
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return message ? (
        <h1>Loading Updated version of SkyGeni.</h1>
      ) : (
        <h1>Something went wrong. Please refresh the page.</h1>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
