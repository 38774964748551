import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ExpectedAcvDescriptionTable from "../../RepPerformance/ScoreCard/ExpectedAcv/ExpectedAcvDescriptionTable";
import { D3MockupGraphsCycleTime } from "./D3GraphoppChange";
import LoadingSkeleton from "../NewWaterFall/LoadingSkeleton";
import NewWaterfallManagerTabel from "../NewWaterFall/NewWaterfallManagerTable";
const ExpectedCycleTime = (props) => {
  console.log(props, "props");
  return (
    <Grid item container justify="flex-start">
      <Grid style={{}} item xs={12} container>
        <Grid item container>
          {props.body.otherDetails !== undefined ? (
            <ExpectedAcvDescriptionTable data={props.body.otherDetails} />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
        <Grid item xs={12}>
          {props.body.oppChangeEvent !== undefined ? (
            <D3MockupGraphsCycleTime data={props.body.oppChangeEvent} />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "-2.5rem" }} item xs={12} container>
        <Grid item xs={12}>
          {props.body.oppChangeEvent !== undefined ? (
            <NewWaterfallManagerTabel
              Value_Label={props.Value_Label}
              data={props.body.oppChangeEvent}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExpectedCycleTime;
