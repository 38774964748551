import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow, _isNaN } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import NoDataCell from './NoDataCell'
export default function TrendsofTeamTable(props) {
    const classes = tableStyle()

    const rows = props.data.map(item => item.AH_Team).filter(getUniqueValues).map(item => item === null ? "-" : item)
    const drilldownHandler = (col, row, meta) => {
        if (props.chartName === "GetWellByAccountsByTeam") {
            props.handleChangeLocation("drilldownOnGraph", { col, row }, meta)
        }
    }
    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            <TableRow className={classes.colHeader} style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Team</TableCell>
                                <TableCell align="center" className={classes.colHeader}># of Accounts</TableCell>
                                <TableCell align="center" className={classes.colHeader}>% of Total</TableCell>
                                <TableCell align="center" className={classes.colHeader}>CARR</TableCell>
                                <TableCell align="center" className={classes.colHeader}>% of Total</TableCell>
                            </TableRow>
                            {
                                props.data.map((item, i) => <StyledTableRow key={i}>
                                    <TableCell align="left" className={classes.dataCell}>{item.AH_Team}</TableCell>
                                    <TableCell align="right" onClick={() => drilldownHandler(null, item.AH_Team, 'byTeamTable')} className={classes.drilldownCell}>{countOrACVFormatter(item.SFDC, false)}</TableCell>
                                    <TableCell align="right" onClick={() => drilldownHandler(null, item.AH_Team, 'byTeamTable')} className={classes.drilldownCell}>{_isNaN(Math.round((item.SFDC / props.data.map(item => item.SFDC).reduce((a, b) => a + b, 0)) * 100)) ? 0 : Math.round((item.SFDC / props.data.map(item => item.SFDC).reduce((a, b) => a + b, 0)) * 100)}%</TableCell>
                                    <TableCell align="right" onClick={() => drilldownHandler(null, item.AH_Team, 'byTeamTable')} className={classes.drilldownCell}>{countOrACVFormatter(item.CARR, true)}</TableCell>
                                    <TableCell align="right" onClick={() => drilldownHandler(null, item.AH_Team, 'byTeamTable')} className={classes.drilldownCell}>{_isNaN(Math.round((item.CARR / props.data.map(item => item.CARR).reduce((a, b) => a + b, 0)) * 100)) ? 0 : Math.round((item.CARR / props.data.map(item => item.CARR).reduce((a, b) => a + b, 0)) * 100)}%</TableCell>
                                </StyledTableRow>)
                            }
                            <StyledTableRow key={'Total'}>
                                <TableCell align="left" onClick={() => drilldownHandler(null, props.data.map(item => item.AH_Team), 'byTeamTableTotal')} className={classes.drilldownCell}>Total</TableCell>
                                <TableCell align="right" onClick={() => drilldownHandler(null, props.data.map(item => item.AH_Team), 'byTeamTableTotal')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item.SFDC).reduce((a, b) => a + b, 0), false)}</TableCell>
                                <TableCell align="right" onClick={() => drilldownHandler(null, props.data.map(item => item.AH_Team), 'byTeamTableTotal')} className={classes.drilldownCell}>{`100%`}</TableCell>
                                <TableCell align="right" onClick={() => drilldownHandler(null, props.data.map(item => item.AH_Team), 'byTeamTableTotal')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                <TableCell align="right" onClick={() => drilldownHandler(null, props.data.map(item => item.AH_Team), 'byTeamTableTotal')} className={classes.drilldownCell}>{`100%`}</TableCell>
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
