import React from "react";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel"; // Ensure this import matches your setup

const SortingTableCell = (props) => {
  console.log("");
  return (
    <>
      {props.sortColumn === props.col && props.isMoreThenOneRow ? (
        <TableSortLabel active={true} direction={props.order}></TableSortLabel>
      ) : null}
    </>
  );
};

SortingTableCell.propTypes = {
  sortColumn: PropTypes.string.isRequired, //Dynamic UseState component come from parent
  col: PropTypes.string.isRequired,
  isMoreThenOneRow: PropTypes.bool.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
};

export default SortingTableCell;
