import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
  getTimingArr,
  dateFormatterMoment,
  csvDateFormatter,
} from "../../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { map } from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
// import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function OpportunityStageChangeTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  return (
    <div className={classes.root}>
      {props.data.length >= 1 ? (
        <CardContent className={classes.content} style={{ padding: "0.2rem" }}>
          <Grid container justifyContent="center" style={{ padding: "0.2rem" }}>
            <TableContainer className={classes.mainTable}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
              <Table
                ref={tableRef}
                size="small"
                aria-label="html table"
                style={{ tableLayout: "auto", width: "initial" }}
                data-test="cycle-time-stage-change-table"
              >
                <TableHead>
                  <TableRow
                    style={{ fontWeight: 600, paddingLeft: 5, height: 40 }}
                  >
                    <TableCell
                      align="center"
                      className={`${classes.repHeader}`}
                      colSpan={1}
                      style={{ minWidth: 200 }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      style={{ background: "#70AD47", color: "white" }}
                      className={classes.repHeader}
                      colSpan={2}
                    >
                      Won
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ background: "#C65911", color: "white" }}
                      className={classes.repHeader}
                      colSpan={2}
                    >
                      Lost
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ background: "#4471C4", color: "white" }}
                      className={classes.repHeader}
                      colSpan={2}
                    >
                      Closed
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ fontWeight: 600 }}>
                    <TableCell
                      align="center"
                      className={`${classes.repHeader}`}
                      style={{ minWidth: 200 }}
                    ></TableCell>
                    {Array(3)
                      .fill()
                      .map((item) => (
                        <>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            style={{
                              border: "0.5px solid lightgrey",
                              minWidth: 120,
                              maxWidth: 120,
                            }}
                          >
                            Avg.
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            style={{
                              border: "0.5px solid lightgrey",
                              minWidth: 120,
                              maxWidth: 120,
                            }}
                          >
                            Median
                          </TableCell>
                        </>
                      ))}
                  </TableRow>

                  {props.data.map((d, i) => {
                    return (
                      <>
                        <StyledTableRow key={i} style={{ fontWeight: 600 }}>
                          <TableCell
                            align="left"
                            className={`${classes.repText} ${classes.fontBold}`}
                            colSpan={1}
                          >
                            {d.label}
                          </TableCell>
                          {["won", "lost", "closed"].map((v) => (
                            <>
                              <TableCell
                                align="right"
                                className={`${classes.repHeader}`}
                                style={{ border: "0.5px solid lightgrey" }}
                              >
                                {![null, undefined, ""].includes(d[v].average)
                                  ? Math.round(d[v].average)
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={`${classes.repHeader}`}
                                style={{ border: "0.5px solid lightgrey" }}
                              >
                                {![null, undefined, ""].includes(d[v].median)
                                  ? Math.round(d[v].median)
                                  : "-"}
                              </TableCell>
                            </>
                          ))}
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      ) : (
        <></>
      )}
    </div>
  );
}
