import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../Drilldown";
import { replaceAll } from "../../../components/xlsx-export/LabelCange";
import PipelineMetricTable from "./PipelineMetricTable";
import ErrorBoundary from "../../../components/ErrorBoundary";
import PipelineMetricTablev2 from "./PipelineMetricsTablev2";
import PCTable from "./PCTable";
import PCTablev2 from "./PCTablev2";
import Legend from "../../AsOfDate/Legend";
import { Button, IconButton } from "@mui/material";
import HelpTextContainer from "./HelpTextContainer";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExpectedAcvPage from "../../ScoreCard/ExpectedAcv/ExpectedAcvPage";
import ExpectedAcvPagev2 from "../../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import MetricsTable from "./MetricsRable";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
    padding: "1rem",
  },
});

const classes = styles();

const BalancedScorecard = (props) => {
  const [open, setOpen] = React.useState(false);
  const [location, setlLocation] = useState("");
  const [header, setHeader] = useState("");
  const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
  const [time, setTime] = useState("");
  const [isMl, setIsMl] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [secondlevellocation, setsecondlevellocation] = useState("");

  useEffect(() => {
    console.log(
      props.filters,
      props.filters === undefined || props.filters === null,
      ![undefined, null].includes(props.filters)
    );
    if (![undefined, null, ""].includes(props.filters)) {
      props.getACVCreatedData(props.filters);
    }
  }, [props.filters]);

  const handleChangeLocationBack = () => {
    setlLocation("");
    setsecondlevellocation("");
  };

  const handleChangeLocation = (
    location,
    teamOrRep,
    quarter,
    table,
    drilldownHeader,
    segementRow,
    teams
  ) => {
    var filters = [];
    let title = "";
    // console.log(segementRow, 'FILTERS')

    const t =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? "team"
        : "rep";

    const t2 =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? "Team"
        : "Rep";

    filters = [{ name: t, value: teamOrRep }];
    let drilldownfilter = [{ title: t2, value: teamOrRep }];

    if (segementRow === "Company") {
      title = "Team";
      filters = [{ name: "team", value: teams }];
      drilldownfilter = [{ title: title, value: ["All"] }];
    } else if (segementRow === "Team") {
      title = "Team";
      filters = [...props.filters];
      const teams = props.filters.filter((f) => f.name === "team")[0].value;
      drilldownfilter = [{ title: title, value: teams }];
    } else if (segementRow === "Rep") {
      filters = [...props.filters];
      console.log(filters, "FILTERS");
      const teams = props.filters.filter((f) => f.name === "rep")[0].value;
      drilldownfilter = [{ title: "Rep", value: teams }];
    }

    setHeader(drilldownHeader);
    if (table === "quality") {
      setIsMl(true);
    } else {
      setIsMl(false);
    }

    // setTime(timeFrame);
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    filters = [
      ...filters,
      {
        name: "fiscal_quarter",
        value: typeof quarter === "string" ? [quarter] : quarter,
      },
    ];
    props.getACVCreatedDrilldown(filters, table);
  };

  const handleChangeProgressionLocation = (
    location,
    teamOrRep,
    drilldownHeader,
    segementRow,
    teams
  ) => {
    var filters = [];
    let title = "";
    // console.log(segementRow, 'FILTERS')

    const t =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? "team"
        : "rep";

    const t2 =
      props.selectedTeam.includes("All") && props.selectedRep.includes("All")
        ? "Team"
        : "Rep";

    filters = [{ name: t, value: teamOrRep }];
    let drilldownfilter = [{ title: t2, value: teamOrRep }];

    if (segementRow === "Company") {
      title = "Team";
      filters = [{ name: "team", value: teams }];
      drilldownfilter = [{ title: title, value: ["All"] }];
    } else if (segementRow === "Team") {
      title = "Team";
      filters = [...props.filters];
      const teams = props.filters.filter((f) => f.name === "team")[0].value;
      drilldownfilter = [{ title: title, value: teams }];
    } else if (segementRow === "Rep") {
      filters = [...props.filters];
      console.log(filters, "FILTERS");
      const teams = props.filters.filter((f) => f.name === "rep")[0].value;
      drilldownfilter = [{ title: "Rep", value: teams }];
    }

    setHeader(drilldownHeader);

    // setTime(timeFrame);
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    filters = [...filters];
    props.getProgressionDrilldown(filters);
  };


  const handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    setsecondlevellocation(location);
    console.log(isMl, 'MLLLL')
    if(isMl){
      props.getExpectedAcvData(oppId);
    }else{
      props.getOpenOppData(oppId)
    }
  };

  const stepBack = () => {
    setsecondlevellocation("");
    props.clearExpectedAcvData();
  };
  // console.log(props);
  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        component={Card}
        // style={{ marginTop: "1rem" }}
        item
        xs={12}
      >
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            {props.ACVCreatedData.dashboard.helpTextFile !== null && (
              <Grid container item justifyContent="flex-end">
                <HelpTextContainer
                  open={open}
                  handleClose={handleClose}
                  helpTextFile={props.ACVCreatedData.dashboard.helpTextFile}
                />
              </Grid>
            )}
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              Pipeline Metrics Composite Score Summary
              {props.ACVCreatedData.dashboard.helpTextFile !== null && (
                <IconButton
                  onClick={handleOpen}
                  aria-label="help"
                  size="medium"
                >
                  <HelpOutlineIcon fontSize="inherit" />
                </IconButton>
              )}
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              Summary of composite scores for pipeline coverage, quality,
              creation and progression
            </Typography>
            <ErrorBoundary>
              <MetricsTable
                data={props.ACVCreatedData.dashboard.pipelineMetricsCompositeScoreSummary.compositeScore}
                dataTest="pipline-metric-table"
                tabletype="Summary"
                id="Pipeline_Metrics_Summary_table"
                legends={props.ACVCreatedData.dashboard.pipelineMetricsCompositeScoreSummary.colorsForLegends}
                annotations={props.ACVCreatedData.annotations}
              />
            </ErrorBoundary>
            {/* <Grid item xs={12} container style={{ margin: "1rem" }}>
              <Legend
                square={15}
                legends={props.ACVCreatedData.dashboard.colorsForLegends}
                legendOpacity={0.9}
                type='BS'
                
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>
      <Grid component={Card} style={{ marginTop: "3rem" }} item xs={12}>
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              Pipeline Coverage Composite Score
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                ? `Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`
                : `${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`}
            </Typography>
            <ErrorBoundary>
              <PipelineMetricTable
                data={props.ACVCreatedData.dashboard.pipelineCoverage.compositeScore}
                dataTest="pipline-coverage-compsite-table"
                tabletype="Pipeline Coverage"
                id="Pipeline_Coverage_table"
                legends={props.ACVCreatedData.dashboard.pipelineCoverage.colorsForLegends}
              />
              {/* <Grid item xs={12} container style={{ margin: "1rem" }}>
                <Legend
                  square={15}
                  legends={props.ACVCreatedData.dashboard.colorsForLegends}
                  legendOpacity={0.9}
                  type="BS"
                />
              </Grid> */}
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              {props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                ? `Pipeline Coverage (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter`
                : `Pipeline Coverage (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                ? `Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`
                : `${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`}
            </Typography>
            <ErrorBoundary>
              <PCTable
                data={props.ACVCreatedData.dashboard.pipelineCoverage.detail.currentQuarter}
                cols={[
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Non Renewals"
                      : "Quota",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Won QTD Non Renewals"
                      : "Won QTD",
                    cl: "white",
                    bg: "#70ad47",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Left Non Renewals"
                      : "Quota Left",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                      : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`,
                    cl: "black",
                    bg: "none",
                  },
                  { label: "Coverage Multiple", cl: "black", bg: "none" },
                ]}
                dataTest="pipline-progression-current-table"
                drilldownHeader={
                  props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                    ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                    : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                }
                drilldownHeader2={
                  props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                    ? "Won QTD Non Renewals"
                    : "Won QTD"
                }
                handleChangeLocation={handleChangeLocation}
              />
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              {props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                ? `Pipeline Coverage (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter + 1`
                : `Pipeline Coverage (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter + 1`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.isNonRenewals
                ? `Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`
                : `${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`}
            </Typography>
            <ErrorBoundary>
              <PCTable
                data={props.ACVCreatedData.dashboard.pipelineCoverage.detail.currentQuarterPlusOne}
                cols={[
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Non Renewals"
                      : "Quota",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Won Curr. Qtr. + 1 Non Renewals"
                      : "Won Curr. Qtr. + 1",
                    cl: "white",
                    bg: "#70ad47",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Left Non Renewals"
                      : "Quota Left",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                      : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`,
                    cl: "black",
                    bg: "none",
                  },
                  { label: "Coverage Multiple", cl: "black", bg: "none" },
                ]}
                dataTest="pipline-progression-currentplus1-table"
                drilldownHeader={
                  props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                    ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                    : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                }
                drilldownHeader2={
                  props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                    ? "Won Curr. Qtr. + 1 Non Renewals"
                    : "Won Curr. Qtr. + 1"
                }
                handleChangeLocation={handleChangeLocation}
              />
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} titel changed */}
              {props.ACVCreatedData.dashboard.isNonRenewals
                ? `Pipeline Coverage (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter + 2`
                : `Pipeline Coverage (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above) - Current Quarter + 2`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                ? `Non Renewals - ${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`
                : `${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} and above (0 - lowest; Coverage ratio of ${props.ACVCreatedData.dashboard.pipelineCoverage.coverageRatioGetsFive}x gets a 5.0)`}
            </Typography>
            <ErrorBoundary>
              <PCTable
                data={props.ACVCreatedData.dashboard.pipelineCoverage.detail.currentQuarterPlusTwo}
                cols={[
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Non Renewals"
                      : "Quota",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Won Curr. Qtr. + 2 Non Renewals"
                      : "Won Curr. Qtr. + 2",
                    cl: "white",
                    bg: "#70ad47",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? "Quota Left Non Renewals"
                      : "Quota Left",
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                      ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                      : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`,
                    cl: "black",
                    bg: "none",
                  },
                  { label: "Coverage Multiple", cl: "black", bg: "none" },
                ]}
                dataTest="pipline-progression-currentplus2-table"
                drilldownHeader={
                  props.ACVCreatedData.dashboard.isNonRenewals
                    ? `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                    : `Qualified (${props.ACVCreatedData.dashboard.pipelineCoverage.mileStoneStage} & Above) Pipeline Non-Renewals`
                }
                drilldownHeader2={
                  props.ACVCreatedData.dashboard.pipelineCoverage.isNonRenewals
                    ? "Won Curr. Qtr. + 2 Non Renewals"
                    : "Won Curr. Qtr. + 2"
                }
                handleChangeLocation={handleChangeLocation}
              />
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>

      <Grid component={Card} style={{ marginTop: "3rem" }} item xs={12}>
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              Pipeline Quality Composite Score
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineQuality.isNonRenewals
                ? `Non Renewals - ${props.ACVCreatedData.dashboard.pipelineQuality.mileStoneStage} and above (${props.ACVCreatedData.dashboard.pipelineQuality.subHeading})`
                : `${props.ACVCreatedData.dashboard.pipelineQuality.mileStoneStage} and above (${props.ACVCreatedData.dashboard.pipelineQuality.subHeading})`}
            </Typography>
            <ErrorBoundary>
              <PipelineMetricTablev2
                data={props.ACVCreatedData.dashboard.pipelineQuality.compositeScore}
                dataTest="pipline-quality-compsite-table"
                legends={props.ACVCreatedData.dashboard.pipelineQuality.colorsForLegends}
                handleChangeLocation={handleChangeLocation}
                drilldownHeader={
                  props.ACVCreatedData.dashboard.pipelineQuality.isNonRenewals
                    ? `Pipeline Quality Composite Score (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineQuality.mileStoneStage} and above)`
                    : `Pipeline Quality Composite Score ${props.ACVCreatedData.dashboard.pipelineQuality.mileStoneStage} and above`
                }
                isDrilldown={true}
                table="quality"
              />
            </ErrorBoundary>
            {/* <Grid item xs={12} container style={{ margin: "1rem" }}>
              <Legend
                square={15}
                legends={props.ACVCreatedData.dashboard.colorsForLegends}
                legendOpacity={0.9}
                type="BS"
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>

      <Grid component={Card} style={{ marginTop: "3rem" }} item xs={12}>
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              Pipeline Creation Composite Score
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineCreation.isNonRenewals
                ? `Non Renewals - All pipeine created in the past 4 ${props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                  "Half_Month_Ending"
                    ? "half-months"
                    : "weeks"}`
                : `All pipeine created in the past 4 ${props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                  "Half_Month_Ending"
                    ? "half-months"
                    : "weeks"}`}
            </Typography>
            <ErrorBoundary>
              <PipelineMetricTablev2
                data={props.ACVCreatedData.dashboard.pipelineCreation.compositeScore}
                dataTest="pipline-creation-compsite-table"
                legends={props.ACVCreatedData.dashboard.pipelineCreation.colorsForLegends}
              />
            </ErrorBoundary>
            {/* <Grid item xs={12} container style={{ margin: "1rem" }}>
              <Legend
                square={15}
                legends={props.ACVCreatedData.dashboard.colorsForLegends}
                legendOpacity={0.9}
                type="BS"
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              {props.ACVCreatedData.dashboard.pipelineCreation.isNonRenewals
                ? `Pipeline Creation - Non Renweals (All pipeline)`
                : `Pipeline Creation (All pipeline)`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                margin: "auto",
                paddingTop: "0.1rem",
                fontWeight: 500,
              }}
              align="center"
            >
              {props.ACVCreatedData.dashboard.pipelineCreation.isNonRenewals
                ? `Non Renewals - All pipeine created in the past 4 ${
                    props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                    "Half_Month_Ending"
                      ? "half-months"
                      : "weeks"
                  }`
                : `All pipeine created in the past 4 ${
                    props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                    "Half_Month_Ending"
                      ? "half-months"
                      : "weeks"
                  }`}
            </Typography>
            <ErrorBoundary>
              <PCTablev2
                data={props.ACVCreatedData.dashboard.pipelineCreation.detail}
                cols={[
                  {
                    label: `All pipeline created over the past 4 ${
                      props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                      "Half_Month_Ending"
                        ? "half-months"
                        : "weeks"
                    } periods`,
                    cl: "white",
                    bg: "#70ad47",
                  },
                  {
                    label: `Goal for all pipeline creation over the past 4 ${
                      props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                      "Half_Month_Ending"
                        ? "half-months"
                        : "weeks"
                    } periods`,
                    cl: "white",
                    bg: "#BF8F00",
                  },
                  {
                    label: "Pipeline Creation % of Goal Goal = 100%",
                    cl: "black",
                    bg: "none",
                  },
                  {
                    label: "% of periods where pipeline creation goal met",
                    cl: "black",
                    bg: "none",
                  },
                  {
                    label:
                      "Consistency - % of periods where pipeline creation goal met ",
                    cl: "black",
                    bg: "none",
                  },
                  {
                    label: `Avg. of best 5 pipe creators in the company over the past 4 ${
                      props.ACVCreatedData.dashboard.pipelineCreation.frequencyChurnedStageChangeColumn ===
                      "Half_Month_Ending"
                        ? "half-months"
                        : "weeks"
                    } periods (NA for teams & company)`,
                    cl: "black",
                    bg: "none",
                  },
                  {
                    label:
                      "% of best pipe creators in the company Goal = 80% (NA for teams & company)",
                    cl: "black",
                    bg: "none",
                  },
                ]}
                dataTest="pipline-creation-detail-table"
              />
            </ErrorBoundary>
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>

      <Grid component={Card} style={{ marginTop: "3rem" }} item xs={12}>
        {props.ACVCreatedData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {/* {props.ACVCreatedData.dashboard.title} */}
              {props.ACVCreatedData.dashboard.pipelineProgression.isNonRenewals
                ? `Pipeline Progression composite score (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineProgression.mileStoneStage} and above)`
                : `Pipeline Progression composite score (${props.ACVCreatedData.dashboard.pipelineProgression.mileStoneStage} and above)`}
            </Typography>
            <ErrorBoundary>
              <PipelineMetricTablev2
                data={props.ACVCreatedData.dashboard.pipelineProgression.compositeScore}
                dataTest="pipline-progression-compsite-table"
                handleChangeLocation={handleChangeProgressionLocation}
                isDrilldown={true}
                legends={props.ACVCreatedData.dashboard.pipelineProgression.colorsForLegends}
                drilldownHeader={
                  props.ACVCreatedData.dashboard.pipelineProgression.isNonRenewals
                    ? `Pipeline Progression composite score (Non Renewals - ${props.ACVCreatedData.dashboard.pipelineProgression.mileStoneStage} and above)`
                    : `Pipeline Progression composite score (${props.ACVCreatedData.dashboard.pipelineProgression.mileStoneStage} and above)`
                }
              />
            </ErrorBoundary>

            {/* <Grid item xs={12} container style={{ margin: "1rem" }}>
              <Legend
                square={15}
                legends={props.ACVCreatedData.dashboard.colorsForLegends}
                legendOpacity={0.9}
                type="BS"
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>

      {location === "drilldownOnGraph" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={header}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
          >
            <Drilldown
              header={header}
              body={props.drilldownOnGraphData}
              Value_Label={props.Value_Label}
              isMl={isMl}
              // showLowMidHigh={isMl ? true : false}
              handleSecondLevelDrilldown={handleSecondLevelDrilldown}
              showPointer={true}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
       {secondlevellocation === "drilldownOppId" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={header}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
            stepBack={stepBack}
            count="2"
          >
            {
              isMl ?  <ExpectedAcvPage
              data={props.ExpectedAcvData}
              opp={false}
              company={props.company}
              lowConfidenceKey={props.lowConfidenceKey}
              Value_Label={props.Value_Label}
            /> : 
            <ExpectedAcvPagev2
                      body={props.ExpectedAcvData}
                      Value_Label={props.Value_Label}
                    />
            }
           
          </SingleLevelDrilldown>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const { BSData, user, drilldownOnGraphData, ExpectedAcvData } = state.app;

  return {
    ACVCreatedData: BSData,
    user,
    drilldownOnGraphData,
    Value_Label: user.Value_Label,
    ExpectedAcvData
  };
};
const mapDispatchToProps = (dispatch) => ({
  getACVCreatedData: (filters) => {
    dispatch({ type: `get_scorecard_BS_data_request` });
    appService.getBalancedScorecardData(filters).then(
      (json) => {
        dispatch({ type: `get_scorecard_BS_data_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_scorecard_BS_data_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_scorecard_BS_data_failure", error });
      }
    );
  },
  getACVCreatedDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getBalancedDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getProgressionDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getBalancedProgressionDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getExpectedAcvData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getExpectedAcvData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearOpenOppData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
});
const BalancedScorecardSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(BalancedScorecard);
export default BalancedScorecardSection;