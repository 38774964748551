import React from "react";
import D3Funnel from "./D3Bar";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import DatePicker from "../../components/DatePicker";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
});

class Bar extends React.Component {
  componentDidMount = () => {
    this._chart = D3Funnel.create(this._rootNode, {
      data: [
        {
          category: "Opportunities with product bundles %",
          influenceFactor: 0.92,
        },
        { category: "Opportunities from Healthcare %", influenceFactor: 0.9 },
        { category: "Account size (# of users)", influenceFactor: 0.86 },
        { category: "Deals from new customers %", influenceFactor: 0.83 },
        { category: "Pipeline from new products %", influenceFactor: 0.81 },
      ].sort((a, b) => a.influenceFactor - b.influenceFactor),
    });
  };

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        {/* <Grid item style={{ marginBottom: 30 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Factors driving top reps</Typography>
          <Typography variant='body1'>
          Discover what factors are driving the performance of your top reps.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Factors driving top reps",
          "Discover what factors are driving the performance of your top reps."
        )}
        <Grid item container spacing={2} justify="space-around">
          <Grid item xs={12}>
            <Card className={clsx(classes.root)} raised={true}>
              <CardHeader title="Factors driving top reps" />
              <Divider />
              <CardContent className={classes.content}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{ width: "100%", height: 350 }}
                    className="waterfall-container"
                    ref={this._setRef.bind(this)}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
  } = state.app;

  return {
    funnelFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
  };
}

const mapDispatchToProps = (dispatch) => ({});

const connectedBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Bar));
export { connectedBar as Bar };
