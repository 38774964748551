import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function DemandGenTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();

  const [parentWidth, setparentWidth] = useState();
  const tableContainerRef = useRef();

  const industries = props.totalColumnData
    .sort((a, b) => b.acvPursued - a.acvPursued)
    .map((s) => s[props.primary]);
  let segments =
    props.toggleValue === "AcvSort"
      ? props.acv_acct_size_segmets
      : props.logic_acct_size_segmets;
  //segments=props.acct_size_segmets.filter(i=>segments.includes(i))
  console.log(segments);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  return (
    <>
      {parentWidth !== undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "0.2rem",
            // marginRight: "1rem",
            width: `${
              tableContainerRef.current.getBoundingClientRect().width
            }px`,
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <TableContainer
        style={{
          margin: "2rem",
          width: "100%",
          maxHeight: "86vh",
          borderTop: "0.5px solid lightgrey",
        }}
        ref={tableContainerRef}
      >
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead
            style={{
              position: "sticky",
              top: -1,
              zIndex: 5,
              outline: "lightgrey solid 0.9px",
              outlineOffset: "-1.5px",
            }}
          >
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                position: "sticky",
                // outline: "lightgrey solid 0.9px",
                // outlineOffset: "-1.0px",
              }}
            >
              <TableCell
                className={classes.colHeader}
                style={{ background: "#4471C4", color: "white", minWidth: 220 }}
                align="center"
              >
                {props.primaryLabel} <span>&#8595;</span> /{" "}
                {props.secondaryLabel} <span>&#8594;</span>
              </TableCell>
              {segments.map((segment) => (
                <TableCell
                  c
                  style={{
                    background: "#4471C4",
                    color: "white",
                    minWidth: 60,
                    maxWidth: 60,
                  }}
                  align="center"
                  className={classes.colHeader}
                >
                  {segment}
                </TableCell>
              ))}
              <TableCell
                style={{
                  background: "#4471C4",
                  color: "white",
                  minWidth: 60,
                  maxWidth: 60,
                }}
                align="center"
                className={classes.colHeader}
              >
                {"Grand Total"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {industries.map((industry, idx) => {
              const columnData = props.totalColumnData.filter(
                (i) => i[props.primary] === industry
              );
              let totalpursued =
                columnData.length !== 0 ? columnData[0].acvPursued : "";
              let totalpursuedCount =
                columnData.length !== 0 ? columnData[0].countPursued : "";
              let totalwon = columnData.length !== 0 ? columnData[0].acv : "";
              let totalAvgCycleTime =
                columnData.length !== 0 ? columnData[0].avgCycleTime : "";
              let totalMedianCycleTime =
                columnData.length !== 0 ? columnData[0].medianCycleTime : "";
              let totalCount =
                columnData.length !== 0 ? columnData[0].count : "";
              let totallost =
                columnData.length !== 0 ? columnData[0].lostAcv : "";
              let totallostCount =
                columnData.length !== 0 ? columnData[0].lostCount : "";
              let totalwinRate =
                columnData.length !== 0 ? columnData[0].winRatePercentage : "";
              let totalpipelineAcv =
                columnData.length !== 0 ? columnData[0].pipelineAcv : "";

              return (
                <>
                  <StyledTableRow>
                    <TableCell
                      style={{ background: "#5b9bd5", color: "white" }}
                      align="left"
                      className={classes.colHeader}
                    >
                      {industry} ({`${props.Value_Label}`} Pursued)
                    </TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let pursued =
                        rowData.length !== 0 ? rowData[0].acvPursued : "";
                      let pursuedCount =
                        rowData.length !== 0 ? rowData[0].countPursued : "";
                      let maxbgColor = props.highlightData.filter(
                        (i) =>
                          i[props.primary] === industry &&
                          i.maxAcvPursuedSegment === segment
                      );
                      let minbgColor = props.highlightData.filter(
                        (i) =>
                          i[props.primary] === industry &&
                          i.minACVPursuedSegment === segment
                      );
                      return (
                        <TableCell
                          style={
                            maxbgColor.length >= 1
                              ? {
                                  background: maxbgColor[0].maxBgColor,
                                  color: "white",
                                }
                              : minbgColor.length >= 1
                              ? {
                                  background: minbgColor[0].minBgColor,
                                  color: "white",
                                }
                              : { background: "#5b9bd5", color: "white" }
                          }
                          align="right"
                          className={classes.colHeader}
                        >
                          <span align="right">
                            {![null, undefined, "", 0, "0"].includes(pursued)
                              ? countOrACVFormatter(pursued, true)
                              : "-"}
                          </span>
                          <span
                            align="right"
                            style={{ marginLeft: "1rem" }}
                          >{`(${
                            ![null, undefined, "", 0, "0"].includes(pursued)
                              ? countOrACVFormatter(pursuedCount, false)
                              : "-"
                          })`}</span>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      style={{ background: "#5b9bd5", color: "white" }}
                      align="right"
                      className={classes.colHeader}
                    >
                      <span>
                        {![null, undefined, "", 0, "0"].includes(totalpursued)
                          ? countOrACVFormatter(totalpursued, true)
                          : "-"}
                      </span>
                      <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                        ![null, undefined, "", 0, "0"].includes(
                          totalpursuedCount
                        )
                          ? countOrACVFormatter(totalpursuedCount, false)
                          : "-"
                      })`}</span>
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.colHeader}
                    >{`Lost or Disqualified (${props.lostStageFrom} or later)`}</TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let lost = rowData.length !== 0 ? rowData[0].lostAcv : "";
                      let lostCount =
                        rowData.length !== 0 ? rowData[0].lostCount : "";
                      return (
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { industry: [industry], segment: [segment] },
                              "Lost",
                              props.type
                            )
                          }
                          style={{ cursor: "pointer" }}
                          align="right"
                          className={classes.colHeader}
                        >
                          <span>
                            {![null, undefined, "", 0, "0"].includes(lost)
                              ? countOrACVFormatter(lost, true)
                              : "-"}
                          </span>
                          <span
                            align="right"
                            style={{ marginLeft: "1rem" }}
                          >{`(${
                            ![null, undefined, "", 0, "0"].includes(lostCount)
                              ? countOrACVFormatter(lostCount, false)
                              : "-"
                          })`}</span>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          { industry: [industry], segment: segments },
                          "Lost",
                          props.type
                        )
                      }
                      style={{ cursor: "pointer" }}
                      align="right"
                      className={classes.colHeader}
                    >
                      <span>
                        {![null, undefined, "", 0, "0"].includes(totallost)
                          ? countOrACVFormatter(totallost, true)
                          : "-"}
                      </span>
                      <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                        ![null, undefined, "", 0, "0"].includes(totallostCount)
                          ? countOrACVFormatter(totallostCount, false)
                          : "-"
                      })`}</span>
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell align="left" className={classes.colHeader}>
                      Won
                    </TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let won = rowData.length !== 0 ? rowData[0].acv : "";
                      let count = rowData.length !== 0 ? rowData[0].count : "";
                      return (
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { industry: [industry], segment: [segment] },
                              "Won",
                              props.type
                            )
                          }
                          style={{ cursor: "pointer" }}
                          align="right"
                          className={classes.colHeader}
                        >
                          <span>
                            {![null, undefined, "", 0, "0"].includes(won)
                              ? countOrACVFormatter(won, true)
                              : "-"}
                          </span>
                          <span
                            align="right"
                            style={{ marginLeft: "1rem" }}
                          >{`(${
                            ![null, undefined, "", 0, "0"].includes(count)
                              ? countOrACVFormatter(count, false)
                              : "-"
                          })`}</span>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          { industry: [industry], segment: segments },
                          "Won",
                          props.type
                        )
                      }
                      style={{ cursor: "pointer" }}
                      align="right"
                      className={classes.colHeader}
                    >
                      <span>
                        {![null, undefined, "", 0, "0"].includes(totalwon)
                          ? countOrACVFormatter(totalwon, true)
                          : "-"}
                      </span>
                      <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                        ![null, undefined, "", 0, "0"].includes(totalCount)
                          ? countOrACVFormatter(totalCount, false)
                          : "-"
                      })`}</span>
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell align="left" className={classes.colHeader}>
                      Won % of Total
                    </TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let wonPercentage =
                        rowData.length !== 0
                          ? rowData[0].wonTotalPercentage
                          : "";
                      return (
                        <TableCell align="right" className={classes.colHeader}>
                          {" "}
                          {![null, undefined, ""].includes(wonPercentage)
                            ? `${Math.round(wonPercentage * 100)}%`
                            : "-"}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" className={classes.colHeader}>
                      100%
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.colHeader}
                    >{`Avg. / Median Cycle Time  (${props.lostStageFrom} to Won)`}</TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let averageCycleTime =
                        rowData.length !== 0 ? rowData[0].avgCycleTime : "";
                      let medianCycleTime =
                        rowData.length !== 0 ? rowData[0].medianCycleTime : "";
                      return (
                        <TableCell align="right" className={classes.colHeader}>
                          <span>
                            {![null, undefined, "", 0, "0"].includes(
                              averageCycleTime
                            )
                              ? countOrACVFormatter(averageCycleTime, false)
                              : "-"}
                          </span>
                          <span style={{ margin: "0.25rem" }}>/</span>
                          <span>{`${
                            ![null, undefined, "", 0, "0"].includes(
                              medianCycleTime
                            )
                              ? countOrACVFormatter(medianCycleTime, false)
                              : "-"
                          }`}</span>
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" className={classes.colHeader}>
                      <span>
                        {![null, undefined, "", 0, "0"].includes(
                          totalAvgCycleTime
                        )
                          ? countOrACVFormatter(totalAvgCycleTime, false)
                          : "-"}
                      </span>
                      <span style={{ margin: "0.25rem" }}>/</span>
                      <span>{`${
                        ![null, undefined, "", 0, "0"].includes(
                          totalMedianCycleTime
                        )
                          ? countOrACVFormatter(totalMedianCycleTime, false)
                          : "-"
                      }`}</span>
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.colHeader}
                    >{`${props.Win_Rate_Prefix} (from ${props.lostStageFrom}) %`}</TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let winRatePercent =
                        rowData.length !== 0
                          ? rowData[0].winRatePercentage
                          : "";
                      let maxbgColor = props.highlightData.filter(
                        (i) =>
                          i[props.primary] === industry &&
                          i.maxWinRateSegment === segment
                      );
                      let minbgColor = props.highlightData.filter(
                        (i) =>
                          i[props.primary] === industry &&
                          i.minWinRateSegment === segment
                      );
                      return (
                        <TableCell
                          style={
                            maxbgColor.length >= 1
                              ? {
                                  background: maxbgColor[0].maxBgColor,
                                  color: "white",
                                }
                              : minbgColor.length >= 1
                              ? {
                                  background: minbgColor[0].minBgColor,
                                  color: "white",
                                }
                              : {}
                          }
                          align="right"
                          className={classes.colHeader}
                        >
                          {" "}
                          {![null, undefined, ""].includes(winRatePercent)
                            ? `${Math.round(winRatePercent * 100)}%`
                            : "-"}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" className={classes.colHeader}>
                      {![null, undefined, ""].includes(totalwinRate)
                        ? `${Math.round(totalwinRate * 100)}%`
                        : "-"}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.colHeader}
                    >{`Open Pipeline (${props.lostStageFrom} or later)`}</TableCell>
                    {segments.map((segment) => {
                      let rowData = props.tableData.filter(
                        (item) =>
                          item[props.primary] === industry &&
                          item[props.secondary] === segment
                      );
                      let pipelineAcv =
                        rowData.length !== 0 ? rowData[0].pipelineAcv : "";
                      return (
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { industry: [industry], segment: [segment] },
                              "Open",
                              props.type
                            )
                          }
                          style={{ cursor: "pointer" }}
                          align="right"
                          className={classes.colHeader}
                        >
                          {![null, undefined, "", 0, "0"].includes(pipelineAcv)
                            ? countOrACVFormatter(pipelineAcv, true)
                            : "-"}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          { industry: [industry], segment: segments },
                          "Open",
                          props.type
                        )
                      }
                      align="right"
                      style={{ cursor: "pointer" }}
                      className={classes.colHeader}
                    >
                      {![null, undefined, "", 0, "0"].includes(totalpipelineAcv)
                        ? countOrACVFormatter(totalpipelineAcv, true)
                        : "-"}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    {Array(segments.length + 2)
                      .fill()
                      .map((s) => (
                        <TableCell className={classes.colHeader}>
                          &nbsp;
                        </TableCell>
                      ))}
                  </StyledTableRow>
                </>
              );
            })}
            {props.wonRowData.length >= 1 && (
              <>
                <StyledTableRow>
                  <TableCell
                    style={{ background: "#5b9bd5", color: "white" }}
                    align="left"
                    className={classes.colHeader}
                  >
                    {"Total"} ({`${props.Value_Label}`} Pursued)
                  </TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let pursued =
                      rowData.length !== 0 ? rowData[0].acvPursued : "";
                    let pursuedCount =
                      rowData.length !== 0 ? rowData[0].countPursued : "";
                    let maxbgColor = props.highlightData.filter(
                      (i) =>
                        i[props.primary] === "Won" &&
                        i.maxAcvPursuedSegment === segment
                    );
                    let minbgColor = props.highlightData.filter(
                      (i) =>
                        i[props.primary] === "Won" &&
                        i.minACVPursuedSegment === segment
                    );
                    return (
                      <TableCell
                        style={
                          maxbgColor.length >= 1
                            ? {
                                background: maxbgColor[0].maxBgColor,
                                color: "white",
                              }
                            : minbgColor.length >= 1
                            ? {
                                background: minbgColor[0].minBgColor,
                                color: "white",
                              }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="right"
                        className={classes.colHeader}
                      >
                        <span>
                          {![null, undefined, "", 0, "0"].includes(pursued)
                            ? countOrACVFormatter(pursued, true)
                            : "-"}
                        </span>
                        <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                          ![null, undefined, "", 0, "0"].includes(pursuedCount)
                            ? countOrACVFormatter(pursuedCount, false)
                            : "-"
                        })`}</span>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={{ background: "#5b9bd5", color: "white" }}
                    align="right"
                    className={classes.colHeader}
                  >
                    <span>
                      {![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.acvPursued
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.acvPursued,
                            true
                          )
                        : "-"}
                    </span>
                    <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                      ![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.countPursued
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.countPursued,
                            false
                          )
                        : "-"
                    })`}</span>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    className={classes.colHeader}
                  >{`Lost or Disqualified (${props.lostStageFrom} or later)`}</TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let lost = rowData.length !== 0 ? rowData[0].lostAcv : "";
                    let lostCount =
                      rowData.length !== 0 ? rowData[0].lostCount : "";
                    return (
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { industry: industries, segment: [segment] },
                            "Lost",
                            props.type
                          )
                        }
                        style={{ cursor: "pointer" }}
                        align="right"
                        className={classes.colHeader}
                      >
                        <span>
                          {![null, undefined, "", 0, "0"].includes(lost)
                            ? countOrACVFormatter(lost, true)
                            : "-"}
                        </span>
                        <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                          ![null, undefined, "", 0, "0"].includes(lostCount)
                            ? countOrACVFormatter(lostCount, false)
                            : "-"
                        })`}</span>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { industry: industries, segment: segments },
                        "Lost",
                        props.type
                      )
                    }
                    style={{ cursor: "pointer" }}
                    align="right"
                    className={classes.colHeader}
                  >
                    <span>
                      {![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.lostAcv
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.lostAcv,
                            true
                          )
                        : "-"}
                    </span>
                    <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                      ![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.lostCount
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.lostCount,
                            false
                          )
                        : "-"
                    })`}</span>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell align="left" className={classes.colHeader}>
                    Won
                  </TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let won = rowData.length !== 0 ? rowData[0].acv : "";
                    let count = rowData.length !== 0 ? rowData[0].count : "";
                    return (
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { industry: industries, segment: [segment] },
                            "Won",
                            props.type
                          )
                        }
                        style={{ cursor: "pointer" }}
                        align="right"
                        className={classes.colHeader}
                      >
                        <span>
                          {![null, undefined, "", 0, "0"].includes(won)
                            ? countOrACVFormatter(won, true)
                            : "-"}
                        </span>
                        <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                          ![null, undefined, "", 0, "0"].includes(count)
                            ? countOrACVFormatter(count, false)
                            : "-"
                        })`}</span>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { industry: industries, segment: segments },
                        "Won",
                        props.type
                      )
                    }
                    style={{ cursor: "pointer" }}
                    align="right"
                    className={classes.colHeader}
                  >
                    <span>
                      {![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.acv
                      )
                        ? countOrACVFormatter(props.grandTotalData.acv, true)
                        : "-"}
                    </span>
                    <span align="right" style={{ marginLeft: "1rem" }}>{`(${
                      ![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.count
                      )
                        ? countOrACVFormatter(props.grandTotalData.count, false)
                        : "-"
                    })`}</span>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell align="left" className={classes.colHeader}>
                    Won % of Total
                  </TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let wonPercentage =
                      rowData.length !== 0 ? rowData[0].wonTotalPercentage : "";
                    return (
                      <TableCell align="right" className={classes.colHeader}>
                        {" "}
                        {![null, undefined, ""].includes(wonPercentage)
                          ? `${Math.round(wonPercentage * 100)}%`
                          : "-"}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right" className={classes.colHeader}>
                    100%
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    className={classes.colHeader}
                  >{`Avg. / Median Cycle Time  (${props.lostStageFrom} to Won)`}</TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let averageCycleTime =
                      rowData.length !== 0 ? rowData[0].avgCycleTime : "";
                    let medianCycleTime =
                      rowData.length !== 0 ? rowData[0].medianCycleTime : "";
                    return (
                      <TableCell align="right" className={classes.colHeader}>
                        <span>
                          {![null, undefined, "", 0, "0"].includes(
                            averageCycleTime
                          )
                            ? countOrACVFormatter(averageCycleTime, false)
                            : "-"}
                        </span>
                        <span style={{ margin: "0.25rem" }}>/</span>
                        <span>{`${
                          ![null, undefined, "", 0, "0"].includes(
                            medianCycleTime
                          )
                            ? countOrACVFormatter(medianCycleTime, false)
                            : "-"
                        }`}</span>
                      </TableCell>
                    );
                  })}
                  <TableCell align="right" className={classes.colHeader}>
                    <span>
                      {![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.avgCycleTime
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.avgCycleTime,
                            false
                          )
                        : "-"}
                    </span>
                    <span style={{ margin: "0.25rem" }}>/</span>
                    <span>{`${
                      ![null, undefined, "", 0, "0"].includes(
                        props.grandTotalData.medianCycleTime
                      )
                        ? countOrACVFormatter(
                            props.grandTotalData.medianCycleTime,
                            false
                          )
                        : "-"
                    }`}</span>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    className={classes.colHeader}
                  >{`${props.Win_Rate_Prefix} (from ${props.lostStageFrom}) %`}</TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let winRatePercent =
                      rowData.length !== 0 ? rowData[0].winRatePercentage : "";
                    let maxbgColor = props.highlightData.filter(
                      (i) =>
                        i[props.primary] === "Won" &&
                        i.maxWinRateSegment === segment
                    );
                    let minbgColor = props.highlightData.filter(
                      (i) =>
                        i[props.primary] === "Won" &&
                        i.minWinRateSegment === segment
                    );
                    return (
                      <TableCell
                        style={
                          maxbgColor.length >= 1
                            ? {
                                background: maxbgColor[0].maxBgColor,
                                color: "white",
                              }
                            : minbgColor.length >= 1
                            ? {
                                background: minbgColor[0].minBgColor,
                                color: "white",
                              }
                            : {}
                        }
                        align="right"
                        className={classes.colHeader}
                      >
                        {" "}
                        {![null, undefined, ""].includes(winRatePercent)
                          ? `${Math.round(winRatePercent * 100)}%`
                          : "-"}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right" className={classes.colHeader}>
                    {![null, undefined, ""].includes(
                      props.grandTotalData.winRatePercentage
                    )
                      ? `${Math.round(
                          props.grandTotalData.winRatePercentage * 100
                        )}%`
                      : "-"}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    className={classes.colHeader}
                  >{`Open Pipeline (${props.lostStageFrom} or later)`}</TableCell>
                  {segments.map((segment) => {
                    let rowData = props.wonRowData.filter(
                      (item) => item[props.secondary] === segment
                    );
                    let pipelineAcv =
                      rowData.length !== 0 ? rowData[0].pipelineAcv : "";
                    return (
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { industry: industries, segment: [segment] },
                            "Open",
                            props.type
                          )
                        }
                        style={{ cursor: "pointer" }}
                        align="right"
                        className={classes.colHeader}
                      >
                        {![null, undefined, "", 0, "0"].includes(pipelineAcv)
                          ? countOrACVFormatter(pipelineAcv, true)
                          : "-"}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { industry: industries, segment: segments },
                        "Open",
                        props.type
                      )
                    }
                    style={{ cursor: "pointer" }}
                    align="right"
                    className={classes.colHeader}
                  >
                    {![null, undefined, "", 0, "0"].includes(
                      props.grandTotalData.pipelineAcv
                    )
                      ? countOrACVFormatter(
                          props.grandTotalData.pipelineAcv,
                          true
                        )
                      : "-"}
                  </TableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
