import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
    square: {
        height: 20, width: 20
    },
}))

export default function PBISummaryLegend() {
    const classes = useStyle()
    return (
        <Grid container justify="center">
            <Grid item xs={3} container justify="center" alignItems="center">
                <Grid item alignItems="center" className={classes.square} style={{ background: '#548235' }}></Grid>
                <Grid item alignItems="center">
                    <Typography align="center" variant='p' style={{ margin: '1rem' }}>Highest</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container justify="center" alignItems="center">
                <Grid item alignItems="center" className={classes.square} style={{ background: '#70ad47' }}></Grid>
                <Grid item alignItems="center">
                    <Typography align="center" variant='p' style={{ margin: '1rem' }}>2nd Highest</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container justify="center" alignItems="center">
                <Grid item alignItems="center" className={classes.square} style={{ background: '#c6e0b4' }}></Grid>
                <Grid item alignItems="center">
                    <Typography align="center" variant='p' style={{ margin: '1rem' }}>3rd Highest</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
