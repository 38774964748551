import {
  Card,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import YTDTable from "../RegionPerformance/YTDTable";
import D3RepPerformanceBarQT from "./D3RepPerformaceBarQT";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import QuotaAttainmentDrilldown from "./QuotaAttainmentDrilldown";
import {
  getUniqueValues,
  stageFormattter,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import Legend from "../AsOfDate/Legend";
import QTTable from "./QTTable";
import QTDetailTable from "./QTDetailTable";
import Drilldown from "../../components/Drilldown/Drilldown";

import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import { isThisISOWeek } from "date-fns";
import QuotaAttainmentDetailDrilldown from "./QuotaAttainmentDetailDrilldown";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
const styles = (theme) => ({
  root: {},

  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class QuotaAttainment extends Component {
  filters = ["closed_fiscal_quarter", "closed_fiscal_year"];
  state = {
    closed_fiscal_quarter: ["All"],
    closed_fiscal_year: ["All"],
    disabled: ["year", "month", "quarter"],
    activeTimeFilter: "closed_fiscal_quarter",
    selectedDrilldown: "",
    drilldownData: "",
    drilldownText: "",
    selectedDrilldownQY: "",
    selectDrillStagee: "",
    filtersForDrilldown: ["All"],
    secondLevelLocation: null,
  };

  componentDidMount() {
    this.props.getQuotaAttainmentCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.quotaAttainmentCompareFilters !== "" &&
      prevProps.quotaAttainmentCompareFilters === ""
    ) {
      const currentQuarter = getDefaultSelectedFiscalQuarters(
        this.props.quotaAttainmentCompareFilters,
        "closed_fiscal_quarter"
      );
      // console.log(currentQuarter)
      this.setState({
        ...prevState,
        closed_fiscal_quarter: currentQuarter,
        filtersForDrilldown: currentQuarter,
      });
    }
    if (this.props.QuotaAttainmentData !== "") {
      D3RepPerformanceBarQT.destroy(this._rootNode1);
      // const uniqueQuarters = this.props.QuotaAttainmentData1[0].map(item => item[quarter]).filter(getUniqueValues)
      // this._chart1 = D3WonStackBar.create(this._rootNode1, { data: this.props.QuotaAttainmentData1, quarter: 'closed_fiscal_quarter', handleChangeLocation: this.handleChangeLocation, uniqueQuarters: uniqueQuarters.length === 0 ? this.state.closed_fiscal_quarter : uniqueQuarters })
      this._chart1 = D3RepPerformanceBarQT.create(this._rootNode1, {
        data: this.props.newBins,
        quarters: this.props.fiscal_years !== undefined ? "year" : "quarter",
        handleChangeLocation: this.handleChangeLocation,
      });
    }
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_quarter];
    }

    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_year];
    }
    if (
      this.state.closed_fiscal_year.includes("All") &&
      this.state.closed_fiscal_quarter.includes("All")
    ) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    this.props.getQuotaAttainmentData(
      this.props.company,
      this.state.activeTimeFilter === "closed_fiscal_year"
        ? "closed_fiscal_year"
        : "",
      filters
    );
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  onResize = async () => {
    D3RepPerformanceBarQT.destroy(this._rootNode1);
    // const uniqueQuarters = this.props.QuotaAttainmentData1[0].map(item => item[quarter]).filter(getUniqueValues)
    // this._chart1 = D3WonStackBar.create(this._rootNode1, { data: this.props.QuotaAttainmentData1, quarter: 'closed_fiscal_quarter', handleChangeLocation: this.handleChangeLocation, uniqueQuarters: uniqueQuarters.length === 0 ? this.state.closed_fiscal_quarter : uniqueQuarters })
    this._chart1 = D3RepPerformanceBarQT.create(this._rootNode1, {
      data: this.props.newBins,
      quarters: this.props.fiscal_years !== undefined ? "year" : "quarter",
      handleChangeLocation: this.handleChangeLocation,
    });
  };
  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeLocation = (location, stage, Open_Datetime) => {
    const a = [].concat
      .apply(
        [],
        this.props.newBins
          .filter((x) => x[stage.x] === stage.cell)
          .map((x) => x.drilldown)
      )
      .sort((a, b) => b.attainment === a.attainment);
    const b = this.props.quota_attainment_with_perc.filter(
      (item) => item[this.state.activeTimeFilter] !== "total"
    );
    const uniqueOwner = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x.Owner_Name)
      .filter(getUniqueValues);
    const uniqueQtr = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x[this.state.activeTimeFilter])
      .filter(getUniqueValues);
    // console.log(uniqueQtr)
    // console.log(uniqueOwner.map(owner => b.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQtr.length &&  owner).filter(x => x !== false))
    const uniqueRep = uniqueOwner
      .map(
        (owner) =>
          b.filter((x) => x.Owner_Name === owner && x.Quota !== null).length <
            uniqueQtr.length && owner
      )
      .filter((x) => x !== false);

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldown:
        stage.cell !== "total" ? stage.cell : this.state.filtersForDrilldown,
      selectDrillStagee: stage.x,
      drilldownData: {
        uniqueRep: uniqueRep,
        quarters: stage.x,
        uniqueQuarters: stage.uniqueQuarters,
        cell: stage.cell,
        label: stage.y === "total" ? "All" : stage.row,
        repNo: stage.z,
        opportunities:
          stage.y === "total"
            ? [].concat
                .apply(
                  [],
                  this.props.newBins
                    .filter((x) => x[stage.x] === stage.cell)
                    .map((x) => x.drilldown)
                )
                .sort((a, b) =>
                  b.attainment === a.attainment
                    ? b.acv - a.acv
                    : b.attainment - a.attainment
                )
            : [].concat.apply(
                [],
                this.props.newBins
                  .filter(
                    (x) => x.label === stage.row && x[stage.x] === stage.cell
                  )
                  .map((x) => x.drilldown)
              ),
      },
    });
  };

  handleChangeLocationDrill = (location, stage) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
      selectedDrilldown:
        stage.cell !== "total" ? stage.cell : this.state.filtersForDrilldown,
      selectedDrilldownQY: stage.quarter,
    });
    if (location !== "overview") {
      let filters = [];
      filters = [
        //  ...this.props.currentFilters,
        {
          name: stage.quarter,
          value: stage.cell !== "total" ? [stage.cell] : stage.uniqueQuarters,
          // this.state[stage.quarter].includes("All") ?
          //     this.props.quotaAttainmentCompareFilters !== '' && this.props.quotaAttainmentCompareFilters.filter(y => y.type === stage.quarter && y[stage.quarter] !== '')
          //         .map(y => y[stage.quarter]).filter(item => item !== null)
          //     : this.state[stage.quarter],
          string: true,
        },
        {
          name: "Owner_Name",
          value: stage.row !== "total" ? [stage.row] : stage.uniqueRep,
          string: true,
        },
      ];

      this.props.getQuotaAttainmentDrilldown(filters);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Quota Attainment Distribution
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
              Examine the quota attainment distribution across your entire team.
            </Typography>
          </Grid>
          {this.props.quotaAttainmentCompareFilters !== undefined ? (
            <Grid
              className={classes.grid}
              item
              container
              justify="flex-start"
              xs={12}
            >
              <Grid container item style={{ padding: 10 }} xs={4}>
                <YearQuarterFilterCombo
                  activeTimeFilter={this.state.activeTimeFilter}
                  closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                  closedFiscalYear={this.state.closed_fiscal_year}
                  handleChange={this.handleChange}
                  funnelCompareFilters={
                    this.props.quotaAttainmentCompareFilters
                  }
                />
              </Grid>

              <Grid
                item
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  data-test="go"
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid item xs={12} style={{ margin: "2rem" }}>
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              <Typography
                variant="h2"
                style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem",
                }}
              >
                Quota Attainment Distribution
              </Typography>

              {this.props.newBins !== undefined ? (
                <Grid container>
                  {(this.props.newBins && this.props.fiscal_years) !==
                  undefined ? (
                    <>
                      <Grid item container xs={12} justify="center">
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: 20,
                            margin: "auto",
                            width: "95%",
                          }}
                          className="line-container"
                          ref={this._setRef1.bind(this)}
                        />
                      </Grid>
                      <Grid align="center" container>
                        {/* <Legend square={15} legends={[{ text: 'Total ', color: '#1f77b4' }]} /> */}
                        {/* <AverageDealSizeTable subtitle="Avg. Deal Size" type={"acv"} quarter={"closed_fiscal_year"} winAcvData={this.props.QuotaAttainmentData1} isAcv={true} /> */}
                        <QTTable
                          data={this.props.newBins}
                          quarters={"year"}
                          handleChangeLocation={this.handleChangeLocation}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item container xs={12}>
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: 20,
                            margin: "auto",
                            width: "95%",
                          }}
                          className="line-container"
                          ref={this._setRef1.bind(this)}
                        />
                      </Grid>
                      <Grid align="center" container>
                        {/* <Legend square={15} legends={[{ text: 'Total ', color: '#1f77b4' }]} /> */}
                        {/* <AverageDealSizeTable subtitle="Avg. Deal Size" type={"acv"} quarter={"closed_fiscal_year"} winAcvData={this.props.QuotaAttainmentData1} isAcv={true} /> */}
                        <QTTable
                          data={this.props.newBins}
                          quarters={"quarter"}
                          handleChangeLocation={this.handleChangeLocation}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              )}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.grid}
            style={{ margin: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }}>
              <Typography
                variant="h2"
                style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem",
                }}
              >
                Quota Attainment Detail
              </Typography>

              {this.props.quota_attainment_with_perc !== undefined ? (
                <Grid container>
                  {(this.props.quota_attainment_with_perc &&
                    this.props.fiscal_years) !== undefined ? (
                    <QTDetailTable
                      data={this.props.quota_attainment_with_perc}
                      quarters={"closed_fiscal_year"}
                      handleChangeLocation={this.handleChangeLocationDrill}
                    />
                  ) : (
                    <QTDetailTable
                      data={this.props.quota_attainment_with_perc}
                      quarters={"closed_fiscal_quarter"}
                      handleChangeLocation={this.handleChangeLocationDrill}
                    />
                  )}
                </Grid>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && this.props.newBins && (
          <Grid
            item
            xs={12}
            container
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Quota Attainment Distribution`}
              back={this.handleChangeLocation}
              // filters={this.state.filtersForDrilldown}
              filters={[
                {
                  title:
                    this.state.selectDrillStagee === "quarter"
                      ? "Closed Fiscal Quarter"
                      : "Closed Fiscal Year",
                  value: this.state.selectedDrilldown,
                },
              ]}
              //  filters={[{title: this.state.activeTimeFilter === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectDrillStagee}]}
            >
              <QuotaAttainmentDrilldown data={this.state.drilldownData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph2" &&
          this.props.quota_attainment_with_perc && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={`Quota Attainment Detail`}
                back={this.handleChangeLocationDrill}
                // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                filters={[
                  {
                    title:
                      this.state.selectedDrilldownQY === "closed_fiscal_quarter"
                        ? "Closed Fiscal Quarter"
                        : "Closed Fiscal Year",
                    value: this.state.selectedDrilldown,
                  },
                ]}
              >
                <Drilldown
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationDrill}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    quotaAttainmentCompareFilters,
    QuotaAttainmentData,
    user,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;
  const { fiscal_quarters, newBins, quota_attainment_with_perc, fiscal_years } =
    QuotaAttainmentData;

  return {
    quotaAttainmentCompareFilters: quotaAttainmentCompareFilters,
    QuotaAttainmentData: QuotaAttainmentData,
    company: user.company,
    Value_Label: user.Value_Label,
    fiscal_quarters,
    newBins,
    quota_attainment_with_perc,
    fiscal_years,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getQuotaAttainmentCompareFilters: () => {
    dispatch({ type: "get_quota_attainment_filters_request" });
    appService.getQuotaAttainmentFilters().then(
      (json) => {
        dispatch({ type: "get_quota_attainment_filters_success", json });
        dispatch({ type: "get_quota_attainment_data_request" });
        const filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        // console.log(filters)
        // console.log(filters)
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getQuotaAttainmentData(json.message.length === 0 ? [] : filters)
          .then(
            (json) => {
              dispatch({ type: "get_quota_attainment_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_quota_attainment_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_quota_attainment_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_filters_failure", error });
      }
    );
  },
  getQuotaAttainmentData: (company, key, filters) => {
    dispatch({ type: "get_quota_attainment_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getQuotaAttainmentData(filters, key).then(
      (json) => {
        dispatch({ type: "get_quota_attainment_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getQuotaAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getQuotaAttainmentDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedQuotaAttainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuotaAttainment));
export default connectedQuotaAttainment;
