import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp"; // Import the icon from the appropriate library

const HoverableIcon = () => {
  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = "#e6e3e3"; // Change to the desired hover background color
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = "#ffffff"; // Reset the background color when the mouse leaves
  };

  return (
    <div
      style={{
        width: "32px",
        height: "30px",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background-color 0.3s", // Add a smooth transition
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <GetAppIcon style={{ color: "#808080" }} />
    </div>
  );
};

export default HoverableIcon;
