import * as d3 from "d3"
import { countOrACVFormatter } from '../../util/customFunctions';
const D3TimeLine2 = {};
D3TimeLine2.create = (el, config) => {

    if (config.data) {        
       
        config.margin = {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
        };
        config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width : 100;
     



        config.height = 180 - config.margin.top - config.margin.bottom;
       
        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width', config.width)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
        var chart = chartContainer
            .append('g')
            .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");
        
        chart.append('line')
            .style("stroke", "black")
            .style("stroke-width", 1)
            .attr("x1", 80)
            .attr("y1", 110)
            .attr("x2", 960)
            .attr("y2", 110); 

            var  data = [{x1: 40, x2: 120, y1: 40, y2: 80,lx1:80,ly1:110,lx2:80,ly2:80,text:'11/1/2021',Stage:'Suspect',acv:10000,close_dt:'03/31/22',day:'Day 0',tx1:57,color:'#E2EFDA'},
            {x1: 150, x2: 230, y1: 40, y2: 80,lx1:190,ly1:110,lx2:190,ly2:80,text:'11/15/2021',Stage:'Qualify',acv:15000,close_dt:'03/31/22',day:'Day 10',tx1:169,color:'white'},
            {x1: 340, x2: 420, y1: 40, y2: 80,lx1:380,ly1:110,lx2:380,ly2:80,text:'12/10/2021',Stage:'Demo',acv:15000,close_dt:'03/31/22',day:'Day 30',tx1:364,color:'white'},
            {x1: 540, x2: 620, y1: 40, y2: 80,lx1:580,ly1:110,lx2:580,ly2:80,text:'12/30/2021',Stage:'Proposal',acv:15000,close_dt:'04/30/22',day:'Day 50',tx1:555,color:'white'},
            {x1: 800, x2: 880, y1: 40, y2: 80,lx1:840,ly1:110,lx2:840,ly2:80,text:'02/21/2022',Stage:'Negotiate',acv:15000,close_dt:'04/30/22',day:'Day 75',tx1:814,color:'white'},
            {x1: 920, x2: 1000, y1: 40, y2: 80,lx1:960,ly1:110,lx2:960,ly2:80,text:'02/21/2022',Stage:'Won',acv:15000,close_dt:'04/30/22',day:'Day 85',tx1:947,color:'#548135'}];
                
                var rects = chart.selectAll("foo")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("x", d=> d.x1)
                    .attr("y", d=> d.y1)
                    .attr("width", d=> d.x2 - d.x1)
                    .attr("height", d=> d.y2 - d.y1)
                    .attr('fill',d=> d.color)
                    .attr("border",2)
                    .style("stroke", '#7F7F7F');
                
                
               
                
                 var lines=chart.selectAll("lines")
                        .data(data)
                        .enter()
                        .append('line')
                        .style("stroke", '#7F7F7F')
                        .style("stroke-width", 1)
                        .attr("x1", d=> d.lx1)
                        .attr("y1", d=> d.ly1)
                        .attr("x2", d=> d.lx2)
                        .attr("y2", d=> d.ly2)
                        .style("stroke-dasharray","7,7");
                
                var texts=chart.selectAll('.text')
                            .data(data)
                            .enter()
                            .append('text')
                            .attr("x", (d,i)=> d.lx1 - 20)
                            .attr("y", (d,i)=>  d.ly1 + 10 )
                            .attr("dy", ".85rem")
                            .text(function(d) { return d.day ; })
                            .attr('fill','#7F7F7F')
                            .style("font-weight", "bold");
                var rectText=chart.selectAll('.recttext')
                            .data(data)
                            .enter()
                            .append('text')
                            .attr("x", (d,i)=> d.tx1)
                            .attr("y", (d,i)=>  d.y1+12)
                            .attr("dy", ".85rem")
                            .text(function(d) { return d.Stage })
                            .attr('fill', d=>d.Stage === 'Won' ? 'white' : 'black')
                            .style("font-weight", "bold");
            chart
                .append("text")
                .attr("y", 160)
                .attr("x", 80)
                .attr("dy", "1em")
                .attr("class", "axis-label")
                .attr("font-size", "0.85rem")
                .attr("fill", "#7F7F7F")
                .text('Company Average for similar  opportunities');

    }

}
D3TimeLine2.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
};
export default D3TimeLine2;