import {
  capitalize,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import { getUniqueValues } from "../util/customFunctions";
import StyledTableRow from "./StyledTableRow";
import * as d3 from "d3";
import { countOrACVFormatter } from "../util/customFunctions";
import { it } from "date-fns/locale";
import { deSnake } from "../util/dataFormatter";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";
import stickytableStyle from "../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "./xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function AverageDealSizeTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  let countinc = 0;

  const [
    Acct_Segment,
    Acct_Segment_Rollup,
    totalRow,
    totalColSegment,
    totalColSegmentRollup,
    totalAverage,
  ] = props.winAcvData;

  const uniqueQuarters = Acct_Segment.map((item) => item[props.quarter]).filter(
    getUniqueValues
  );

  const tierA = Acct_Segment.filter(
    (item) => item.Acct_Segment_Rollup === "Major / Enterprise"
  );
  const tierB = Acct_Segment.filter(
    (item) => item.Acct_Segment_Rollup === "Mid-Market / SMB"
  );
  const Acct_Segment_RollupTierA = Acct_Segment_Rollup.filter(
    (item) => item.Acct_Segment_Rollup === "Major / Enterprise"
  );
  const Acct_Segment_RollupTierB = Acct_Segment_Rollup.filter(
    (item) => item.Acct_Segment_Rollup === "Mid-Market / SMB"
  );
  const tierAUniqueStages = tierA
    .map((item) => item.Acct_Segment)
    .filter(getUniqueValues);
  let tierBUniqueStages = tierB
    .map((item) => item.Acct_Segment)
    .filter(getUniqueValues);
  let tierBUniqueStage = tierBUniqueStages.shift();
  tierBUniqueStages.push(tierBUniqueStage);
  let tierAUniqueStage = tierAUniqueStages.shift();
  tierAUniqueStages.push(tierAUniqueStage);
  console.log();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={props.dataTest}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead>
                <TableRow
                  style={{ fontWeight: 600, paddingLeft: 5 }}
                  className={classes.fontBold}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150, width: "5rem" }}
                  >
                    {deSnake(props.quarter)}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        colSpan={2}
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                              }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={2}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}
                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {`Segment`}
                  </TableCell>
                  {uniqueQuarters.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {props.subtitle}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ padding: "5px" }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {props.subtitle}
                      </TableCell>
                    </React.Fragment>
                  )}
                  {/* {uniqueQuarters.length > 1 &&
                                            <React.Fragment>
                                                <TableCell align='center' style={{ minWidth: 70 }} className={classes.repHeader} colSpan={1} ># of Opps</TableCell>
                                                <TableCell align='center' style={{ minWidth: 90 }} className={classes.repHeader} colSpan={1} >{props.type.toUpperCase()}</TableCell>                                                
                                            </React.Fragment>
                                        } */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      // className={classes.repHeader}
                      className={
                        isSticky
                          ? `${stickyClasses.sticky} ${classes.repHeader}`
                          : classes.repHeader
                      }
                      style={
                        isSticky && countinc % 2 === 1
                          ? { background: "#f5f5f5", fontWeight: 600 }
                          : { fontWeight: 600 }
                      }
                      // style={{ fontWeight: 600 }}
                      colSpan={1}
                    >
                      Major / Enterprise
                    </TableCell>
                    {uniqueQuarters.map((qtr) => (
                      <>
                        <TableCell
                          onClick={() => {
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Major / Enterprise", cell: qtr },
                              "Acct_Segment_Rollup",
                              props.quarter
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          key={qtr}
                          align="right"
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          colSpan={1}
                        >
                          {Acct_Segment_RollupTierA.filter(
                            (it) => it[props.quarter] === qtr
                          )[0] === undefined ||
                          Acct_Segment_RollupTierA.filter(
                            (it) => it[props.quarter] === qtr
                          )[0].deals === null
                            ? "-"
                            : d3.format(",")(
                                Math.round(
                                  Acct_Segment_RollupTierA.filter(
                                    (it) => it[props.quarter] === qtr
                                  )[0].deals
                                )
                              )}{" "}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Major / Enterprise", cell: qtr },
                              "Acct_Segment_Rollup",
                              props.quarter
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          key={qtr}
                          align="right"
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          colSpan={1}
                        >
                          {props.type === "acv" &&
                          !(
                            Acct_Segment_RollupTierA.filter(
                              (it) => it[props.quarter] === qtr
                            )[0] === undefined ||
                            Acct_Segment_RollupTierA.filter(
                              (it) => it[props.quarter] === qtr
                            )[0][props.type] === null
                          )
                            ? "$"
                            : ""}
                          {Acct_Segment_RollupTierA.filter(
                            (it) => it[props.quarter] === qtr
                          )[0] === undefined ||
                          Acct_Segment_RollupTierA.filter(
                            (it) => it[props.quarter] === qtr
                          )[0][props.type] === null
                            ? "-"
                            : d3.format(",")(
                                Math.round(
                                  Acct_Segment_RollupTierA.filter(
                                    (it) => it[props.quarter] === qtr
                                  )[0][props.type]
                                )
                              )}{" "}
                        </TableCell>
                      </>
                    ))}
                    {uniqueQuarters.length > 1 && (
                      <>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                row: "Major / Enterprise",
                                cell: uniqueQuarters,
                              },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          align="right"
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            totalColSegmentRollup.filter(
                              (item) =>
                                item.Acct_Segment_Rollup ===
                                "Major / Enterprise"
                            )[0].deals,
                            false
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                row: "Major / Enterprise",
                                cell: uniqueQuarters,
                              },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          align="right"
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            totalColSegmentRollup.filter(
                              (item) =>
                                item.Acct_Segment_Rollup ===
                                "Major / Enterprise"
                            )[0][props.type],
                            props.type === "acv" ? true : false
                          )}
                        </TableCell>
                      </>
                    )}
                  </StyledTableRow>
                }
                {tierA.length > 0
                  ? tierAUniqueStages.map((item, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            align="left"
                            // className={classes.repHeader}
                            // style={{ paddingLeft: 25 }}
                            className={
                              isSticky
                                ? `${stickyClasses.sticky} ${classes.repHeader}`
                                : classes.repHeader
                            }
                            style={
                              isSticky && countinc % 2 === 1
                                ? { background: "#f5f5f5", paddingLeft: "25px" }
                                : { paddingLeft: "25px" }
                            }
                            colSpan={1}
                          >
                            {item}
                          </TableCell>
                          {uniqueQuarters.map((qtr) => (
                            <>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: qtr },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                key={qtr}
                                style={{ cursor: "pointer" }}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {tierA.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0] === undefined ||
                                tierA.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0].deals === null
                                  ? "-"
                                  : d3.format(",")(
                                      Math.round(
                                        tierA.filter(
                                          (it) =>
                                            it.Acct_Segment === item &&
                                            it[props.quarter] === qtr
                                        )[0].deals
                                      )
                                    )}{" "}
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: qtr },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                key={qtr}
                                style={{ cursor: "pointer" }}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {props.type === "acv" &&
                                !(
                                  tierA.filter(
                                    (it) =>
                                      it.Acct_Segment === item &&
                                      it[props.quarter] === qtr
                                  )[0] === undefined ||
                                  tierA.filter(
                                    (it) =>
                                      it.Acct_Segment === item &&
                                      it[props.quarter] === qtr
                                  )[0][props.type] === null
                                )
                                  ? "$"
                                  : ""}
                                {tierA.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0] === undefined ||
                                tierA.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0][props.type] === null
                                  ? "-"
                                  : d3.format(",")(
                                      Math.round(
                                        tierA.filter(
                                          (it) =>
                                            it.Acct_Segment === item &&
                                            it[props.quarter] === qtr
                                        )[0][props.type]
                                      )
                                    )}{" "}
                              </TableCell>
                            </>
                          ))}
                          {uniqueQuarters.length > 1 && (
                            <>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: uniqueQuarters },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                key={"total"}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {countOrACVFormatter(
                                  totalColSegment.filter(
                                    (it) => it.Acct_Segment === item
                                  )[0].deals,
                                  false
                                )}
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: uniqueQuarters },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                key={"total"}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {countOrACVFormatter(
                                  totalColSegment.filter(
                                    (it) => it.Acct_Segment === item
                                  )[0][props.type],
                                  props.type === "acv" ? true : false
                                )}
                              </TableCell>
                            </>
                          )}
                        </StyledTableRow>
                      );
                    })
                  : null}

                {
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      // className={classes.repHeader}
                      // style={{ fontWeight: 600 }}
                      className={
                        isSticky
                          ? `${stickyClasses.sticky} ${classes.repHeader}`
                          : classes.repHeader
                      }
                      style={
                        isSticky && (countinc = countinc + 1) % 2 === 1
                          ? { background: "#f5f5f5", fontWeight: 600 }
                          : { fontWeight: 600 }
                      }
                      colSpan={1}
                    >
                      Mid-Market / SMB
                    </TableCell>
                    {uniqueQuarters.map((qtr, index2) => (
                      <>
                        <TableCell
                          key={qtr}
                          style={{ cursor: "pointer", fontWeight: 600 }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell: qtr },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {Acct_Segment_RollupTierB.filter(
                            (it) => it[props.quarter] === qtr
                          )[0] === undefined ||
                          Acct_Segment_RollupTierB.filter(
                            (it) => it[props.quarter] === qtr
                          )[0].deals === null
                            ? "-"
                            : d3.format(",")(
                                Math.round(
                                  Acct_Segment_RollupTierB.filter(
                                    (it) => it[props.quarter] === qtr
                                  )[0].deals
                                )
                              )}{" "}
                        </TableCell>
                        <TableCell
                          key={qtr}
                          style={{ cursor: "pointer", fontWeight: 600 }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell: qtr },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {props.type === "acv" &&
                          !(
                            Acct_Segment_RollupTierB.filter(
                              (it) => it[props.quarter] === qtr
                            )[0] === undefined ||
                            Acct_Segment_RollupTierB.filter(
                              (it) => it[props.quarter] === qtr
                            )[0][props.type] === null
                          )
                            ? "$"
                            : ""}
                          {Acct_Segment_RollupTierB.filter(
                            (it) => it[props.quarter] === qtr
                          )[0] === undefined ||
                          Acct_Segment_RollupTierB.filter(
                            (it) => it[props.quarter] === qtr
                          )[0][props.type] === null
                            ? "-"
                            : d3.format(",")(
                                Math.round(
                                  Acct_Segment_RollupTierB.filter(
                                    (it) => it[props.quarter] === qtr
                                  )[0][props.type]
                                )
                              )}{" "}
                        </TableCell>
                      </>
                    ))}
                    {uniqueQuarters.length > 1 && (
                      <>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell: uniqueQuarters },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          align="right"
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            totalColSegmentRollup.filter(
                              (item) =>
                                item.Acct_Segment_Rollup === "Mid-Market / SMB"
                            )[0] === undefined
                              ? 0
                              : totalColSegmentRollup.filter(
                                  (item) =>
                                    item.Acct_Segment_Rollup ===
                                    "Mid-Market / SMB"
                                )[0].deals,
                            false
                          )}{" "}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell: uniqueQuarters },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={`${classes.repNumber} ${classes.fontBold}`}
                          align="right"
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            totalColSegmentRollup.filter(
                              (item) =>
                                item.Acct_Segment_Rollup === "Mid-Market / SMB"
                            )[0] === undefined
                              ? 0
                              : totalColSegmentRollup.filter(
                                  (item) =>
                                    item.Acct_Segment_Rollup ===
                                    "Mid-Market / SMB"
                                )[0][props.type],
                            props.type === "acv" ? true : false
                          )}{" "}
                        </TableCell>
                      </>
                    )}
                  </StyledTableRow>
                }

                {tierB.length > 0
                  ? tierBUniqueStages.map((item, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            align="left"
                            // className={classes.repHeader}
                            // style={{ paddingLeft: 25 }}
                            className={
                              isSticky
                                ? `${stickyClasses.sticky} ${classes.repHeader}`
                                : classes.repHeader
                            }
                            style={
                              isSticky && countinc % 2 === 1
                                ? { background: "#f5f5f5", paddingLeft: "25px" }
                                : { paddingLeft: "25px" }
                            }
                            colSpan={1}
                          >
                            {item}
                          </TableCell>
                          {uniqueQuarters.map((qtr) => (
                            <>
                              <TableCell
                                key={qtr}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: qtr },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {tierB.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0] === undefined ||
                                tierB.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0].deals === null
                                  ? "-"
                                  : d3.format(",")(
                                      Math.round(
                                        tierB.filter(
                                          (it) =>
                                            it.Acct_Segment === item &&
                                            it[props.quarter] === qtr
                                        )[0].deals
                                      )
                                    )}{" "}
                              </TableCell>
                              <TableCell
                                key={qtr}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: qtr },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {props.type === "acv" &&
                                !(
                                  tierB.filter(
                                    (it) =>
                                      it.Acct_Segment === item &&
                                      it[props.quarter] === qtr
                                  )[0] === undefined ||
                                  tierB.filter(
                                    (it) =>
                                      it.Acct_Segment === item &&
                                      it[props.quarter] === qtr
                                  )[0][props.type] === null
                                )
                                  ? "$"
                                  : ""}
                                {tierB.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0] === undefined ||
                                tierB.filter(
                                  (it) =>
                                    it.Acct_Segment === item &&
                                    it[props.quarter] === qtr
                                )[0][props.type] === null
                                  ? "-"
                                  : d3.format(",")(
                                      Math.round(
                                        tierB.filter(
                                          (it) =>
                                            it.Acct_Segment === item &&
                                            it[props.quarter] === qtr
                                        )[0][props.type]
                                      )
                                    )}{" "}
                              </TableCell>
                            </>
                          ))}
                          {uniqueQuarters.length > 1 && (
                            <>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: uniqueQuarters },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                key={"total"}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {countOrACVFormatter(
                                  totalColSegment.filter(
                                    (it) => it.Acct_Segment === item
                                  )[0].deals,
                                  false
                                )}{" "}
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: item, cell: uniqueQuarters },
                                    "Acct_Segment",
                                    props.quarter
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                key={"total"}
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {countOrACVFormatter(
                                  totalColSegment.filter(
                                    (it) => it.Acct_Segment === item
                                  )[0][props.type],
                                  props.type === "acv" ? true : false
                                )}
                              </TableCell>
                            </>
                          )}
                        </StyledTableRow>
                      );
                    })
                  : null}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    // style={{ fontWeight: 600 }}
                    align="left"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    style={
                      isSticky && (countinc = countinc + 1) % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                  >
                    Total
                  </TableCell>
                  {totalRow.map((item, i) => (
                    <>
                      <TableCell
                        key={i}
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: item[props.quarter] },
                            "Total",
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {d3.format(",")(Math.round(item.deals))}
                      </TableCell>
                      <TableCell
                        key={i}
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: item[props.quarter] },
                            "Total",
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.type === "acv" && !(item[props.type] === null)
                          ? "$"
                          : ""}
                        {d3.format(",")(Math.round(item[props.type]))}
                      </TableCell>
                    </>
                  ))}
                  {uniqueQuarters.length > 1 && (
                    <>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: uniqueQuarters },
                            "Acct_Segment",
                            props.quarter
                          )
                        }
                        style={{ cursor: "pointer" }}
                        key={"total"}
                        align="right"
                        className={`${classes.repNumber} ${classes.fontBold}`}
                        colSpan={1}
                      >
                        {countOrACVFormatter(totalAverage[0].deals, false)}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: uniqueQuarters },
                            "Acct_Segment",
                            props.quarter
                          )
                        }
                        style={{ cursor: "pointer" }}
                        key={"total"}
                        align="right"
                        className={`${classes.repNumber} ${classes.fontBold}`}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          totalAverage[0][props.type],
                          props.type === "acv" ? true : false
                        )}
                      </TableCell>
                    </>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
