import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";

import "./QuotaAttainmentDrilldown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  _isNaN,
  getCSVFileNameString,
  csvDateFormatter,
  getUniqueValues,
  dateFormatterv2,
  dateFormatterv3,
} from "../../util/customFunctions";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const QuotaAttainmentDrilldown = (props) => {
  const classes = useStyle();
  const containerHeight = props.data
    ? props.data.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.data
    ? props.data.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const attainment = props.data.label;
  const uniqueReps = props.data.uniqueRep;
  //console.log(domLayout, containerHeight, (window.innerHeight-300))
  // console.log(props.data.uniqueQuarters)
  // console.log(uniqueReps.length)
  // console.log((uniqueReps.map(owner => props.data.opportunities.filter(x => x.Owner_Name === owner).length )).reduce((a,b) => a+b,0) > 0)

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const deltaIndicator = (params) => {
    const element = document.createElement("span");
    const imageElement = document.createElement("span");
    if (uniqueReps.includes(params.value) && props.data.cell === "total") {
      imageElement.appendChild(document.createTextNode("*"));
      // imageElement.src = 'https://www.ag-grid.com/example-assets/weather/fire-plus.png';
      imageElement.style.color = "#ed7d31";
    } else {
      element.appendChild(document.createTextNode(""));
    }
    element.appendChild(document.createTextNode(params.value));
    element.appendChild(imageElement);
    return element;
  };

  const exportCSV = (params) => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        // const rowIndex = cellParams.column.colId;
        // if (
        //   (cellParams &&
        //     rowIndex === "Projected_Close_Date_At_Time_Fiscal_Quarter") ||
        //   rowIndex === "Created_Date" ||
        //   rowIndex === "Projected_Close_Date_At_Time"
        // ) {
        //   return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        // } else if (
        //   (cellParams && rowIndex === "OppName") ||
        //   rowIndex === "Account_Name" ||
        //   rowIndex === "Owner_Name" ||
        //   rowIndex === "attainmentRange"
        // ) {
        //   return `"${cellParams.value}"`; //apply your string formatter
        // } else if (cellParams && rowIndex === "acv") {
        //   return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        // } else if (cellParams && rowIndex === "attainment") {
        //   return _isNaN(cellParams.value)
        //     ? 0
        //     : Math.round(cellParams.value * 100) + "%";
        // } else return cellParams.value; // no formatting
        const rowIndex = cellParams.column.colId;
        // if(cellParams && rowIndex === "projected_close_date" ) {
        //     return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        //   } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name" || rowIndex === "Owner_Name") {
        //     return `"${cellParams.value}"`; //apply your string formatter
        //   } else if(cellParams && rowIndex === "ACV" || rowIndex === "acv"){
        //     return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
        //   }
        const dateTypes = props.body.columns
          .filter((f) => f.type === "date")
          .map((f) => f.field);
        const dateTimeTypes = props.body.columns
          .filter((f) => f.type === "datetime")
          .map((f) => f.field);
        const stringTypes = props.body.columns
          .filter((f) => f.type === "string")
          .map((f) => f.field);
        const integerTypes = props.body.columns
          .filter((f) => f.type === "integer")
          .map((f) => f.field);
        const currencyTypes = props.body.columns
          .filter((f) => f.type === "currency")
          .map((f) => f.field);
        // console.log(dateTypes,dateTimeTypes,stringTypes,integerTypes,currencyTypes, 'BODY123')
        if (cellParams && dateTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv2(cellParams.value) : null; //apply your timestamp formatter
        } else if (cellParams && stringTypes.includes(rowIndex)) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && dateTimeTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv3(cellParams.value) : null; //apply your timestamp formatter
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.data ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
            style={{ padding: "0 8rem" }}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Attainment Range: <span data-test="count">{attainment}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Number of Reps:{" "}
                <span data-test="acv">{props.data.repNo}</span>
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              item
              className="ag-theme-custom-react_DB"
              style={{
                height: containerHeight,
                width: "100%",
                padding: "0 8rem",
              }}
            >
              <AgGridReact
                components={{
                  deltaIndicator: deltaIndicator,
                  // daysSunshineRenderer: daysSunshineRenderer,
                  // rainPerTenMmRenderer: rainPerTenMmRenderer
                }}
                columnDefs={[
                  {
                    headerName: "Number",
                    field: "number",
                    type: "rightAligned",
                    sortable: false,
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-right": "3%",
                    },
                    headerClass: classes.tableHeader,
                    maxWidth: 70,
                  },
                  {
                    headerName: "Attainment Range",
                    field: "attainmentRange",
                    flex: 1.3,
                    suppressSizeToFit: true,
                    sortable: true,
                    filter: true,
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-left": "3%",
                    },
                    cellClass: classes.tableCell,
                    headerClass: classes.tableHeader,
                  },
                  {
                    headerName: "Rep",
                    field: "Owner_Name",
                    flex: 3,
                    sortable: true,
                    filter: true,
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-left": "3%",
                    },
                    cellClass: classes.tableCell,
                    headerClass: classes.tableHeader,
                    cellRenderer: "deltaIndicator",
                  },
                  {
                    headerName: "Quota",
                    field: "Quota",
                    flex: 1,
                    type: "rightAligned",
                    sortable: true,
                    filter: "agNumberColumnFilter",
                    filterParams: { defaultOption: "greaterThanOrEqual" },
                    filterParams: { defaultOption: "greaterThan" },
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-right": "3%",
                    },
                    headerClass: classes.tableHeader,
                    sortingOrder: ["desc", "asc"],
                    valueFormatter: (x) => {
                      if (_isNaN(Math.round(x.value))) {
                        return "";
                      }
                      return "$ " + d3.format(",")(Math.round(x.value));
                    },
                  },
                  {
                    headerName: "Actual",
                    field: "acv",
                    flex: 1,
                    type: "rightAligned",
                    sortable: true,
                    filter: "agNumberColumnFilter",
                    filterParams: { defaultOption: "greaterThanOrEqual" },
                    filterParams: { defaultOption: "greaterThan" },
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-right": "3%",
                    },
                    headerClass: classes.tableHeader,
                    sortingOrder: ["desc", "asc"],
                    valueFormatter: (x) => {
                      if (_isNaN(Math.round(x.value))) {
                        return "";
                      }
                      return "$ " + d3.format(",")(Math.round(x.value));
                    },
                  },
                  {
                    headerName: "Attainment % ",
                    field: "attainment",
                    flex: 1,
                    type: "rightAligned",
                    sortable: true,
                    filter: "agNumberColumnFilter",
                    filterParams: { defaultOption: "greaterThanOrEqual" },
                    filterParams: { defaultOption: "greaterThan" },
                    cellStyle: {
                      "border-right-color": "lightgrey",
                      "padding-right": "3%",
                    },
                    headerClass: classes.tableHeader,
                    sortingOrder: ["desc", "asc"],
                    valueFormatter: (x) => {
                      if (_isNaN(Math.round(x.value))) {
                        return "";
                      }
                      return Math.round(x.value * 100) + "%";
                    },
                    // pinned: "right"
                    resizable: false,
                  },
                ]}
                sizeColumnsToFit={true}
                headerHeight="55"
                rowData={props.data.opportunities.map((item, index) => {
                  let temp = {
                    ...item,
                    attainmentRange:
                      attainment === "All"
                        ? Math.round(item.attainment * 100) === 0
                          ? "0%"
                          : Math.round(item.attainment * 100) > 0 &&
                            Math.round(item.attainment * 100) <= 25
                          ? "0-25%"
                          : Math.round(item.attainment * 100) > 25 &&
                            Math.round(item.attainment * 100) <= 50
                          ? "25-50%"
                          : Math.round(item.attainment * 100) > 50 &&
                            Math.round(item.attainment * 100) <= 75
                          ? "50-75%"
                          : Math.round(item.attainment * 100) > 75 &&
                            Math.round(item.attainment * 100) <= 100
                          ? "75-100%"
                          : Math.round(item.attainment * 100) > 100 &&
                            Math.round(item.attainment * 100) <= 125
                          ? "100-125%"
                          : Math.round(item.attainment * 100) > 125 &&
                            Math.round(item.attainment * 100) <= 150
                          ? "125-150%"
                          : Math.round(item.attainment * 100) > 150 &&
                            Math.round(item.attainment * 100) <= 175
                          ? "150-175%"
                          : Math.round(item.attainment * 100) > 175 &&
                            Math.round(item.attainment * 100) <= 200
                          ? "175-200%"
                          : Math.round(item.attainment * 100) > 200
                          ? ">200%"
                          : attainment
                        : attainment,
                    number: attainment === "All" ? index + 1 : item.number,
                    // Owner_Name: uniqueReps.includes(item.Owner_Name) ?  item.Owner_Name+"*": item.Owner_Name
                  };
                  return temp;
                })}
                onFirstDataRendered={onFirstDataRendered}
                // onCellClicked={this.onCellClicked.bind(this)}
                // pagination={true}
                // paginationPageSize={6}
                defaultColDef={defaultColDef}
                domLayout={domLayout}
                onColumnResized={
                  props.data.opportunities.length <= 5 ? onColumnResized : null
                }
                enableCellTextSelection={true}
                //    autoHeight={true}
                colResizeDefault={"shift"}
                debounceVerticalScrollbar={true}
                onGridReady={gridReady}
              ></AgGridReact>
            </Grid>
          </Grid>
          {props.data.cell === "total" &&
            uniqueReps.length > 0 &&
            props.data.opportunities.filter((x) => x.Owner_Name).length > 0 &&
            uniqueReps
              .map(
                (owner) =>
                  props.data.opportunities.filter((x) => x.Owner_Name === owner)
                    .length
              )
              .reduce((a, b) => a + b, 0) <= uniqueReps.length &&
            uniqueReps
              .map(
                (owner) =>
                  props.data.opportunities.filter((x) => x.Owner_Name === owner)
                    .length
              )
              .reduce((a, b) => a + b, 0) > 0 && (
              <Grid
                item
                container
                xs={10}
                justify="flex-start"
                alignItems="flex-end"
                style={{ marginTop: "1rem" }}
              >
                <Typography variant="body2" style={{ paddingLeft: "8rem" }}>
                  <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded
                  from analysis for time periods where no quota data is
                  available.
                </Typography>
              </Grid>
            )}
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default QuotaAttainmentDrilldown;
