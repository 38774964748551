import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  withStyles,
  Paper,
  InputLabel,
  Select,
  TextField,
  Card,
  TableRow,
  TableCell,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RepBenchmarkingTable from "./BenchmarkingTable";
import TopRepsTable from "./TopRepsTable";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../GenericScorecard/Drilldown";
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
    width: "3.6666%",
  },
  rankCol: {
    maxWidth: "1rem",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "white",
    background: "#4472C4",
    width: "3.6666%",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    width: "20%",
    color: "white",
    background: "#4472C4",
  },
  tableCard: {
    padding: "1rem 0rem",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  repBenchmarkingGrid: {
    // padding: `0 ${window.innerWidth < 1368 ? 4 : 5}rem`
  },
  colHeaderNew: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    fontWeight: "inherit",
    backgroundColor: "inherit",
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}
class RepBenchmarking extends PureComponent {
  state = {
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    closed_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    timeFrame: "quarter",
    topReps: [],
    topCalculated: false,
    topRepsTable: [],
    benchmarks: [],
    benchmarks_acv: [],
    acv: null,
    firstStage: null,
    location: "",
    filtersForDrilldown: "",
    selectedDrilldownStage: "",
  };
  componentDidMount = () => {
    // const params = localStorage.getItem('params')
    // this.props.getfunnelCompareFilters(JSON.parse(params))
    let filters = [
      {
        name: "closed_fiscal_quarter",
        value: this.props.funnelCompareFilters,
        string: "true",
      },
      { name: "acv", value: 30000, string: false },
    ];
    this.props.getfunnelCompareData(filters);
    //window.addEventListener('resize', this.onZoom)
  };

  //   componentDidUpdate (prevProps, prevState) {
  //     if (this.props.funnelCompareFilters !== '' ) {
  //       this.setState({ ...prevState, closed_fiscal_quarter: this.props.funnelCompareFilters})
  //   }

  //   if (this.props.currentFilters !== '' && prevProps.currentFilters === ''){
  //     const currentFilters=this.props.currentFilters
  //     this.setState({
  //       ...this.state,
  //       closed_fiscal_quarter:currentFilters.filter(f=>f.name=="closed_fiscal_quarter")[0].value,
  //       acv:currentFilters.filter(f=>f.name=="acv")[0] && d3.format(",")(String(currentFilters.filter(f=>f.name=="acv")[0].value))

  //     })
  //     console.log(this.acv)
  //   }
  //   console.log(this.state.closed_fiscal_quarter,this.state.acv)
  // }

  filters = ["closed_fiscal_quarter"];
  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  // handleChangeLocation = (location, stage,lastStage, reps, isTeam) => {
  //   // let filters = this.filters.map(f => {
  //   //     return {
  //   //         name: f,
  //   //         value: f === 'rep' ? this.state[f].includes('All') ? 'All' : this.getSelectedRepIDs() : this.state[f].map(item => typeof (item) !== "string" ? `${item}` : item),
  //   //         string: f === 'team' ? true : this.props.funnelFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0] ? this.props.funnelFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0].string === "Y" : ''
  //   //     }
  //   // }).filter(f => (!Array.isArray(f.value) && f.value !== 'All') || !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
  //   // if (this.state.acv !== null) {
  //     let  filters = []
  //     filters = [
  //           ...filters,
  //           {name: 'closed_fiscal_quarter', value: this.props.funnelCompareFilters, string: 'true'},
  //           {
  //               name: 'acv',
  //               value: 20000,
  //               string: false
  //           },
  //           {
  //             name: isTeam === true ? "team" : "Owner_Name",
  //             value: reps,
  //             string: true
  //           }, {
  //             name: "final_stage_for_closed",
  //             value: stage,
  //             string: true
  //           }
  //       ]

  //         filters = ['closed_fiscal_quarter']
  // getSelectedRepIDs = () => {
  //   let idArray = []
  //   this.state.rep.map(i => {
  //     this.props.funnelCompareFilters.map(j => {
  //       if (j.type === "rep" && j.Full_Name === i) {
  //         idArray = [...idArray, j.UserID]
  //       }
  //     })
  //   })
  //   return idArray
  // }

  handleChangeLocation = (location, stage, lastStage, reps, isTeam) => {
    let filters = [];
    filters = [
      ...filters,
      {
        name: "closed_fiscal_quarter",
        value: this.props.funnelCompareFilters,
        string: "true",
      },
      {
        name: "acv",
        value: 30000,
        string: false,
      },
      {
        name: isTeam === true ? "team" : "Owner_Name",
        value: reps,
        string: true,
      },
      {
        name: "final_stage_for_closed",
        value: stage,
        string: true,
      },
    ];

    const drilldownFilters = [
      {
        title: "Closed Fiscal Quarter",
        value: this.props.funnelCompareFilters,
      },
      {
        title: "ACV >=",
        value: 30000,
      },
      {
        title: isTeam === true || reps.length > 2 ? "Team" : "Rep",
        value: reps.length > 2 ? "All" : reps,
      },
      {
        title: "Stage",
        value: stage,
      },
    ];
    console.log(stage);
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage:
        stage[0] === "Closed Won"
          ? `${stage} Opportunities `
          : `Opportunities lost at ${stage} stage`,
      lastStage: lastStage,
      filtersForDrilldown: drilldownFilters,
    });

    if (location !== "overview" && location === "drilldownOnGraph") {
      this.props.getRepBenchmarkingDrilldown(filters);
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value: parseInt(this.state.acv),
          string: false,
        },
      ];
    }
    this.props.getfunnelCompareData(filters);
  };

  handleChangeLocationBack = () => {
    this.setState({
      ...this.state,
      location: "",
    });
  };

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.repBenchmarkingGrid} xs={12}>
        <Grid item xs={12} container className={classes.tableCard}>
          {console.log(this.props)}
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Benchmark vs Top Reps"
              titleLine2={`${this.props.funnelCompareFilters}`}
              isConversionTable={true}
              highlightOnThreshold={true}
              handleChangeLocation={this.handleChangeLocation}
              isDrilldown={true}
              benchmarkConversionRates={
                <>
                  <TableRow style={{ fontWeight: 600 }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convCount * 100)}%`}</TableCell>
                      )
                    )}
                    <TableCell align="center" className={classes.colHeaderNew}>
                      Top Reps
                    </TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convACV * 100)}%`}</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow style={{ border: "none" }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                    <TableCell align="center"></TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                  </TableRow>
                </>
              }
              benchmarkingCondition={(item) =>
                item >= 20
                  ? { background: "#70ad47", color: "white", fontWeight: 600 }
                  : item <= -20
                  ? { background: "#ed7c31", color: "white", fontWeight: 600 }
                  : {}
              }
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                { label: "By ACV", background: "#548235", color: "white" },
              ]}
              primaryColumns={this.props.stages
                .map((item, index) =>
                  index < this.props.stages.length - 1
                    ? {
                        from: this.props.stages[index],
                        to: this.props.stages[index + 1],
                      }
                    : undefined
                )
                .filter((item) => item !== undefined)}
              data={
                this.props.funnelCompareData.conversionRatesAgainstBenchmarks
              }
              teamData={
                this.props.funnelCompareData
                  .conversionRatesAgainstBenchmarksTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container className={classes.tableCard}>
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Historical conversion rates - All deals ACV &ge; $30K"
              titleLine2={`${this.props.funnelCompareFilters}`}
              isConversionTable={true}
              handleChangeLocation={this.handleChangeLocation}
              isDrilldown={true}
              benchmarkConversionRates={
                <>
                  <TableRow style={{ fontWeight: 600 }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convCount * 100)}%`}</TableCell>
                      )
                    )}
                    <TableCell align="center" className={classes.colHeaderNew}>
                      Top Reps
                    </TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell
                          align="center"
                          className={classes.colHeaderNew}
                        >{`${Math.round(item.convACV * 100)}%`}</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow style={{ border: "none" }}>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                    <TableCell align="center"></TableCell>
                    {this.props.funnelCompareData.benchmarkConversionRates.map(
                      (item) => (
                        <TableCell align="center"></TableCell>
                      )
                    )}
                  </TableRow>
                </>
              }
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                { label: "By ACV", background: "#548235", color: "white" },
              ]}
              primaryColumns={this.props.stages
                .map((item, index) =>
                  index < this.props.stages.length - 1
                    ? {
                        from: this.props.stages[index],
                        to: this.props.stages[index + 1],
                      }
                    : undefined
                )
                .filter((item) => item !== undefined)}
              data={
                this.props.funnelCompareData.oppsConversionRatesStageToStage
              }
              teamData={
                this.props.funnelCompareData.oppsConversionRatesStageToStageTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container className={classes.tableCard}>
          {this.props.funnelCompareData !== "" ? (
            <RepBenchmarkingTable
              title="Opportunities flowing from stage to stage - All deals ACV &ge; $30K"
              titleLine2={`Opps closed in ${this.props.funnelCompareFilters}`}
              titleColumns={[
                {
                  label: "By Opportunity Count",
                  background: "#bf8f00",
                  color: "white",
                },
                { label: "", background: "white", color: "white" },
                { label: "By ACV", background: "#548235", color: "white" },
              ]}
              primaryColumns={this.props.stages}
              isConversionTable={false}
              isDrilldown={true}
              handleChangeLocation={this.handleChangeLocation}
              data={this.props.funnelCompareData.oppsFlowingFromStagetoStage}
              teamData={
                this.props.funnelCompareData.oppsFlowingFromStagetoStageTeam
              }
            />
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.selectedDrilldownStage}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={this.state.selectedDrilldownStage}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}

        {/* <Grid item xs={12} container className={classes.tableCard}>
          {
            this.props.funnelCompareData !== "" ?
              <TopRepsTable
                title="Top Reps"
                benchmarkReps={this.props.funnelCompareData.benchmarkReps}
                data={this.props.funnelCompareData.topRepsTable.sort((a, b) => a.wonACV - b.wonACV ? 1 : -1)}
                firstStage={this.props.funnelCompareData.firstStage}
                secondStage={this.props.funnelCompareData.secondStage}
                wonStage={this.props.funnelCompareData.wonStage}
              /> : <Grid item container justify="center" component={Card}>
                <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
              </Grid>
          }
        </Grid> */}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    funnelCompareData,
    persistentAlert,
    repPerformanceData,
    user,
    currentFilters,
    drilldownOnGraphData,
  } = state.app;
  let lastStage = funnelCompareData.wonStage;
  var stages = null;
  if (funnelCompareData !== "") {
    stages = funnelCompareData.oppsFlowingFromStagetoStage
      .map((item) => item.stage)
      .filter(getUniqueValues)
      .reverse();
  }

  return {
    repPerformanceData,
    // funnelCompareFilters,
    funnelCompareData,
    persistentAlert,
    sortedReps: funnelCompareData[0]
      ? [
          ...new Set(
            funnelCompareData[0]
              .filter((y) => y.stage === lastStage)
              .sort((a, b) => b.acv - a.acv)
              .map((j) => j.rep)
          ),
        ]
      : "",
    company: user.company,
    stages,
    currentFilters,
    drilldownOnGraphData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getfunnelCompareData: (filters) => {
    dispatch({ type: "get_funnelCompare_data_request" });
    appService.getRepBenchmarkingData(filters).then(
      (json) => {
        dispatch({ type: "get_funnelCompare_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnelCompare_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnelCompare_data_failure", error });
      }
    );
  },
  getRepBenchmarkingDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getRepBenchmarkingDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acv > b.acv ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  //   getfunnelCompareFilters: (paramsFilters) => {
  //     dispatch({ type: 'get_funnelCompare_filters_request' })
  //     appService.getRepBenchmarkingFilters()
  //       .then(json => {
  //         dispatch({ type: 'get_funnelCompare_filters_success', json })
  //         let filters = [{ name: 'closed_fiscal_quarter', value: getDefaultSelectedFiscalQuarters(json.message,'closed_fiscal_quarter'), string: true }]
  //         if(paramsFilters){
  //           filters = JSON.parse(paramsFilters)
  //         }
  //     let filters = [{ name: 'closed_fiscal_quarter', value: this.props.funnelCompareFilters, string: true }]
  //         dispatch({ type: 'current_selected_filters', filters })
  //         dispatch({ type: 'get_funnelCompare_data_request' })
  //                 appService.getRepBenchmarkingData(filters)
  //           .then(json => {
  //             dispatch({ type: 'get_funnelCompare_data_success', json })
  //           }, error => {
  //             if (typeof error === 'object') dispatch({ type: 'get_funnelCompare_data_failure', error: 'Something went wrong' })
  //             else
  //               dispatch({ type: 'get_funnelCompare_data_failure', error })
  //           })
  //       },
  // //       error => {
  // //         if (typeof error === 'object') dispatch({ type: 'get_funnelCompare_filters_failure', error: 'Something went wrong' })
  // //         else
  // //           dispatch({ type: 'get_funnelCompare_filters_failure', error })
  // //       })
  // //   }
});
const connectedRepBenchmarking = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RepBenchmarking));
export default connectedRepBenchmarking;
