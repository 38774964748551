import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../../EnhancedInsights/NewFunnel/FunnelStyles";
import Loading from "../../components/Loading";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultACV,
  dateFormatterMomentV4,
  getDefaultThreshold,
  getDefaultSalesType,
} from "../../util/customFunctions";
import Drilldown from "../../components/Drilldown/Drilldown";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PipelineTable from "./PipelineTable";
import LowMIdHighTable from "./LowMidHighTable";
import DeviationTable from "./DeviationTable";
import NextQuarterConversionTable from "./NextQuarterConversionTable";
import ExpectedLowMidHighTable from "./ExpectedLowMidHighTable";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

class TimeSeriesPrediction extends React.Component {
  state = {
    closed_year: ["All"],
    closed_month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    fiscal_quarter: ["All"],
    Cust_Type: ["All"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Industry: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    custType: ["All"],
    deal_category__c: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: null,
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    toggleValue: "AcvSort",
    secondLevelLocation: null,
    THRESHOLDS_VALUES: 0,
    exclude_large_deals: "Yes",
  };

  // filters = ['closed_year', 'closed_month', 'quarter', 'rep', 'fiscal_quarter', 'team', 'Cust_Type', 'Acct_Industry', 'custType', "Booking_Type_NL_CS_US", 'Acct_Size_Segment','Acct_Segment', 'Lead_Source', 'Opp_Created_By', 'Solution_Cat', 'Region_Rollup','deal_category__c']

  filters = ["fiscal_quarter"];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
    this.props.getFunnelFilters();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.fiscalQuarters,
        "fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];

      let excludDeals = "Yes";
      if (this.props.funnelFilters.exclude_large_deals) {
        excludDeals =
          getDefaultSalesType(
            this.props.funnelFilters.exclude_large_deals,
            "exclude_large_deals"
          )[0] === "All"
            ? "No"
            : "Yes";
        // this.setState({
        //   ...prevState,
        //   [oppTypeColumn]:oppTypes,
        //   oppType : oppTypeColumn,
        // })
      }
      let thresholdValues = 0;
      if (excludDeals === "Yes") {
        thresholdValues =
          getDefaultThreshold(this.props.funnelFilters.large_deal_threshold) > 0
            ? d3.format(",")(
                String(
                  getDefaultThreshold(
                    this.props.funnelFilters.large_deal_threshold
                  )
                )
              )
            : getDefaultThreshold(
                this.props.funnelFilters.large_deal_threshold
              );
      }

      this.setState({
        ...prevState,
        fiscal_quarter: defaultQuarter,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Quarter",
            value: defaultQuarter,
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Closed Fiscal Quarter"
          ),
        ],
        activeFilters: [
          { name: "closed_fiscal_quarter", value: defaultQuarter },
        ],
        exclude_large_deals: excludDeals,
        THRESHOLDS_VALUES: thresholdValues,
      });
    }
  }

  handleChange = (e) => {
    if (
      e.target.name === "closed_year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        closed_month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : [e.target.value],
      });
    }
  };

  handleChangeFilter = (e) => {
    // console.log(e)
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    if (this.state.exclude_large_deals === "Yes") {
      filters = [
        ...filters,
        {
          name: "threshold",
          value:
            this.state.THRESHOLDS_VALUES &&
            typeof this.state.THRESHOLDS_VALUES === "string"
              ? this.state.THRESHOLDS_VALUES.match(/,/g)
                ? this.state.THRESHOLDS_VALUES.match(/,/g).length === 1
                  ? parseInt(this.state.THRESHOLDS_VALUES) * 1000
                  : this.state.THRESHOLDS_VALUES.match(/,/g).length === 2
                  ? parseInt(this.state.THRESHOLDS_VALUES) * 1000000
                  : parseInt(this.state.THRESHOLDS_VALUES)
                : parseInt(this.state.THRESHOLDS_VALUES)
              : this.state.THRESHOLDS_VALUES,
          string: false,
        },
      ];
    }
    // if (this.state.acv !== null && this.state.acv !== 0) {
    //   filters = [
    //     ...filters,
    //     {
    //         name: 'acv',
    //         value: this.state.acv && typeof (this.state.acv) === 'string' ?

    //           this.state.acv.match(/,/g) ?
    //             this.state.acv.match(/,/g).length === 1 ?
    //               parseInt(this.state.acv) * 1000
    //               :
    //               this.state.acv.match(/,/g).length === 2 ?
    //                 parseInt(this.state.acv) * 1000000
    //                 :
    //                 parseInt(this.state.acv)
    //             :
    //             parseInt(this.state.acv)

    //           : this.state.acv,
    //         string: false
    //       }
    //   ]
    // }
    this.props.getFunnelDataNew(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) =>
                  f !== "closed_year" && f !== "closed_month" && f !== "quarter"
              ),
              "closed_fiscal_quarter",
            ]
          : [
              ...this.state.disabled.filter(
                (f) => f !== "closed_fiscal_quarter"
              ),
              "closed_year",
              "closed_month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, obj, key, tableHeader) => {
    if (location !== "overview") {
      var stage = obj.row;
      var quarter = obj.cell;
      var drilldownFilters = [
        { title: "Fiscal Quarter", value: quarter },
        { title: "Stage", value: stage },
      ];

      var filters = this.props.currentFilters.filter(
        (s) => s.name !== "fiscal_quarter"
      );
      filters = [
        ...filters,
        { name: "fiscal_quarter", value: quarter },
        { name: "Stage_At_Time_T", value: stage },
      ];
      var keyNames = ["Fiscal Quarter", "Stage"];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );

      if (this.state.exclude_large_deals === "Yes") {
        filters = [
          ...filters,
          {
            name: "threshold",
            value:
              this.state.THRESHOLDS_VALUES &&
              typeof this.state.THRESHOLDS_VALUES === "string"
                ? this.state.THRESHOLDS_VALUES.match(/,/g)
                  ? this.state.THRESHOLDS_VALUES.match(/,/g).length === 1
                    ? parseInt(this.state.THRESHOLDS_VALUES) * 1000
                    : this.state.THRESHOLDS_VALUES.match(/,/g).length === 2
                    ? parseInt(this.state.THRESHOLDS_VALUES) * 1000000
                    : parseInt(this.state.THRESHOLDS_VALUES)
                  : parseInt(this.state.THRESHOLDS_VALUES)
                : this.state.THRESHOLDS_VALUES,
            string: false,
          },
        ];
      }

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        header: tableHeader,
        drildownACVName: key,
        filtersForDrilldown: [...filtersForDrilldown, ...drilldownFilters],
      });
      this.props.getConversionEffecienceyDrilldown(filters, key);
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Fiscal Quarter", value: this.state.fiscal_quarter.sort() },
      ];
    }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year.sort() },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    // if (!this.state.acv !== null && this.state.acv !== 0)
    //   filtersArray = [...filtersArray, {
    //     title: "ACV Greater Than", value: '$ ' + d3.format(",")(this.state.acv && typeof (this.state.acv) === 'string' ?

    //       this.state.acv.match(/,/g) ?
    //         this.state.acv.match(/,/g).length === 1 ?
    //           parseInt(this.state.acv) * 1000
    //           :
    //           this.state.acv.match(/,/g).length === 2 ?
    //             parseInt(this.state.acv) * 1000000
    //             :
    //             parseInt(this.state.acv)
    //         :
    //         parseInt(this.state.acv)

    //       : this.state.acv)
    //   }]

    if (!this.state.Acct_Industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.Acct_Industry,
        },
      ];
    if (!this.state.Acct_Size_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Industry
              : this.state.Acct_Size_Segment,
        },
      ];
    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region_Rollup
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onThresholdChange = (event, value) => {
    console.log(
      this.state.exclude_large_deals,
      this.state.THRESHOLDS_VALUES,
      value
    );
    this.setState({
      ...this.state,
      THRESHOLDS_VALUES: [null, ""].includes(value)
        ? d3.format(",")(
            String(
              getDefaultThreshold(this.props.funnelFilters.large_deal_threshold)
            )
          )
        : value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Time Series based prediction of range of Closed Won
          </Typography>
          <Typography variant="body1">
            Time Series based prediction of range of Closed Won.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Time Series based prediction of range of Closed Won",
          "Time Series based prediction of range of Closed Won."
        )}

        {this.props.funnelFilters ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Fiscal Quarter:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.fiscal_quarter}
                      onChange={this.handleChange}
                      label="Closed Fiscal Quarter"
                      name="fiscal_quarter"
                    >
                      {/* <MenuItem value={'All'}>All</MenuItem> */}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.fiscalQuarters
                          .filter((item) => item.fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.fiscal_quarter}
                              value={y.fiscal_quarter}
                            >
                              {y.fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl} disabled={this.state.closed_fiscal_quarter[0] !== 'All'}>
                    <InputLabel id="select-year-label" style={{ color: !this.state.closed_year.includes('All') ? '#4472c4' : '#000' }} >Closed Calendar Year:</InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-year"
                      value={this.state.closed_year}
                      onChange={this.handleChange}
                      label="Year"
                      name='closed_year'
                      data-test="year"
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.years.sort().reverse().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl disabled={this.state.closed_fiscal_quarter[0] !== 'All'} fullWidth className={classes.formControl}>
                    <InputLabel id="select-month-label" style={{ color: !this.state.closed_month.includes('All') ? '#4472c4' : '#000' }}>Closed Calendar Month:</InputLabel>
                    <Select
                      labelId="select-month-label"
                      id="select-month"
                      data-test="month"
                      value={this.state.closed_month}
                      onChange={this.handleChange}
                      label="Month"
                      name='closed_month'
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(y => <MenuItem key={y} value={y}>{this.months[y - 1]}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} style={{ paddingBottom: "4px" }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.exclude_large_deals.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-exclude-label"
                    >
                      Exclude large deals:
                    </InputLabel>
                    <Select
                      value={this.state.exclude_large_deals}
                      onChange={this.handleChangeFilter}
                      name="exclude_large_deals"
                      data-test="exclude_large_deals"
                    >
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.exclude_large_deals.map(
                          (y) => (
                            <MenuItem
                              key={y["exclude_large_deals"]}
                              value={y["exclude_large_deals"]}
                            >
                              {y["exclude_large_deals"]}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} style={{ paddingBottom: "4px" }}>
                  {console.log(
                    this.state.exclude_large_deals,
                    this.state.THRESHOLDS_VALUES
                  )}
                  {this.state.exclude_large_deals === "Yes" ? (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.large_deal_threshold.map((d) =>
                          d3.format(",")(String(d.THRESHOLDS_VALUES))
                        )
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onThresholdChange}
                      value={
                        [null, ""].includes(this.state.THRESHOLDS_VALUES)
                          ? d3.format(",")(
                              String(
                                getDefaultThreshold(
                                  this.props.funnelFilters.large_deal_threshold
                                )
                              )
                            )
                          : this.state.THRESHOLDS_VALUES
                      }
                      // value={this.state.acv}
                      data-test="THRESHOLDS_VALUES"
                      autoSelect={true}
                      disabled={this.state.exclude_large_deals !== "Yes"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Threshold > :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.THRESHOLDS_VALUES !== null &&
                                this.state.THRESHOLDS_VALUES !== 0 &&
                                this.state.exclude_large_deals === "Yes"
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={[]}
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onThresholdChange}
                      value={
                        this.state.exclude_large_deals !== "Yes"
                          ? ""
                          : this.state.THRESHOLDS_VALUES
                      }
                      // value={this.state.acv}
                      data-test="THRESHOLDS_VALUES"
                      autoSelect={true}
                      disabled={this.state.exclude_large_deals !== "Yes"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Threshold > :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.THRESHOLDS_VALUES !== null &&
                                this.state.THRESHOLDS_VALUES !== 0 &&
                                this.state.exclude_large_deals === "Yes"
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            {/* <Grid container item style={{ padding: 10,paddingTop:15 }} xs={12} md={4}>
              {this.props.funnelFilters !== "" ? <Grid item spacing={2} xs={12} container component={Paper} style={{paddingTop:8}}>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.props.funnelFilters !== '' && this.orderRepsByLastName()}
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name='rep'
                    data-test="rep"
                    disabled={!this.state.team.includes('All')}
                    renderInput={(params) => <TextField  {...params} label="Rep:" variant="standard" InputLabelProps={{ style: { color: !this.state.rep.includes('All') ? '#4472c4' : '#000' } }} />}
                  />

                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl} disabled={!this.state.rep.includes('All')}>
                    <InputLabel id="select-team-label" style={{ color: !this.state.team.includes('All') ? '#4472c4' : '#000' }}>Team:</InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      label="Team"
                      data-test="team"
                      name='team'
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.teams.map(t => t.Display_Name).sort().map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid> : <Grid item container component={Paper} justify="center"> <Skeleton variant="text" animation="wave" height={40} width={290} /></Grid>}
             </Grid> 
             <Grid container item style={{ padding: 10,paddingTop:15 }} justify='space-between' xs={12} md={12} lg={6}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl} >
                    <InputLabel id="select-Cust_Type-label" style={{ color: !this.state.Acct_Industry.includes('All') ? '#4472c4' : '#000' }}>{this.props.company === 'C0003' ? 'Vertical:' : 'Industry:'}</InputLabel>
                    <Select
                      labelId="select-Cust_Type-label"
                      id="select-Cust_Type"
                      value={this.state.Acct_Industry}
                      onChange={this.handleChange}
                      name='Acct_Industry'
                      data-test="Acct_Industry"
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.industries.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                 {
                   this.props.company === 'C0003' ?  <FormControl fullWidth className={classes.formControl}>
                   <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Acct_Segment.includes('All') ? '#4472c4' : '#000' }}>Customer Segment:</InputLabel>
                   <Select
                     labelId="select-Acct_Industry-label"
                     id="select-Acct_Industry"
                     value={this.state.Acct_Segment}
                     onChange={this.handleChange}
                     name='Acct_Segment'
                     data-test="customer_segment"
                     multiple
                   >
                     <MenuItem value={'All'}>All</MenuItem>
                     {this.props.funnelFilters !== '' && this.props.funnelFilters.custSeg.filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                   </Select>
                 </FormControl> : 
                  <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Acct_Size_Segment.includes('All') ? '#4472c4' : '#000' }}>Customer Segment:</InputLabel>
                  <Select
                    labelId="select-Acct_Industry-label"
                    id="select-Acct_Industry"
                    value={this.state.Acct_Size_Segment}
                    onChange={this.handleChange}
                    name='Acct_Size_Segment'
                    data-test="customer_segment"
                    multiple
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    {this.props.funnelFilters !== '' && this.props.funnelFilters.custSeg.filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                  </Select>
                </FormControl>
                 }
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  {
                    this.props.company === 'C0003' ? <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Region_Rollup.includes('All') ? '#4472c4' : '#000' }}>{'Region:'}</InputLabel>
                    <Select
                      labelId="select-Acct_Industry-label"
                      id="select-Acct_Industry"
                      value={this.state.Region_Rollup}
                      onChange={this.handleChange}
                      name='Region_Rollup'
                      data-test="Cust_Type"
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.regions.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl> :
                  <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Cust_Type.includes('All') ? '#4472c4' : '#000' }}>{this.props.company === 'C0003' ? 'Region:' : 'Customer Type:'}</InputLabel>
                  <Select
                    labelId="select-Acct_Industry-label"
                    id="select-Acct_Industry"
                    value={this.state.Cust_Type}
                    onChange={this.handleChange}
                    name='Cust_Type'
                    data-test="Cust_Type"
                    multiple
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    {this.props.funnelFilters !== '' && this.props.funnelFilters.regions.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                  </Select>
                </FormControl>
                  }
                </Grid>

              </Grid>
            </Grid>
            */}

            {/* extra filters 1 */}

            {/* extra filters 2 */}
            {/* <Grid container item style={{ padding: 10,paddingTop:15 }} justify='space-between' xs={12} md={12} lg={this.props.company === 'C0003' ? 6 : 6}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{paddingTop:8}}>
                <Grid item xs={12} md={12} lg={this.props.company === 'C0003' ? 4 : 4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl} >
                    <InputLabel id="select-region-label" style={{ color: !this.state.Lead_Source.includes('All') ? '#4472c4' : '#000' }}>Lead Source:</InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.Lead_Source}
                      onChange={this.handleChange}
                      name='Lead_Source'
                      data-test="leadsource"
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.leadSource.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={this.props.company === 'C0003' ? 4 : 4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Opp_Created_By.includes('All') ? '#4472c4' : '#000' }}>Opportunity Created By:</InputLabel>
                    <Select
                      labelId="select-Acct_Industry-label"
                      id="select-Acct_Industry"
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name='Opp_Created_By'
                      data-test="opp_created_by"
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.funnelFilters !== '' && this.props.funnelFilters.oppCreatedBy.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company !== 'C0003' &&
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel id="select-Acct_Industry-label" style={{ color: !this.state.Solution_Cat.includes('All') ? '#4472c4' : '#000' }}>Solution Category:</InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name='Solution_Cat'
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        {this.props.funnelFilters !== '' && this.props.funnelFilters.solutionCat.sort().filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                }
                  {
                                        this.props.company === "C0003" && 
                                       <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                              
                                             <FormControl fullWidth className={classes.formControl} >
                                                <InputLabel className={classes.filterLabel} style={{ color: !this.state.Booking_Type_NL_CS_US.includes('All') ? '#4472c4' : '#000' }} id="select-Acct_Industry-label">Opportunity Type:</InputLabel>
                                                <Select

                                                    value={this.state.Booking_Type_NL_CS_US}
                                                    onChange={this.handleChange}
                                                    name='Booking_Type_NL_CS_US'
                                                    data-test="Booking_Type_NL_CS_US"
                                                    multiple
                                                >
                                                    <MenuItem value={'All'} >All</MenuItem>
                                                    {this.props.funnelFilters !== '' && this.props.funnelFilters.Booking_Type_NL_CS_US.sort((a, b) => a["Booking_Type_NL_CS_US"] < b["Booking_Type_NL_CS_US"] ? -1 : a["Booking_Type_NL_CS_US"] > b["Booking_Type_NL_CS_US"] ? 1 : 0).filter(item => item !== "").map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                                                </Select>
                                            </FormControl> 
                                        </Grid>
                                    }

              </Grid>
              
            </Grid> */}
            {/* { 
                            this.props.company === "C0001" && <Grid container item style={{ padding: 10,paddingTop:15 }} xs={2}>
                            <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

                                 <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                                 <FormControl fullWidth className={classes.formControl} >
                                        <InputLabel className={classes.filterLabel} style={{ color: !this.state.deal_category__c.includes('All') ? '#4472c4' : '#000' }} id="select-region-label">Deal Category:</InputLabel>
                                        <Select
                                            data-test='Product_Category'
                                            value={this.state.deal_category__c}
                                            onChange={this.handleChange}
                                            name='deal_category__c'
                                            multiple
                                        >
                                             <MenuItem value={'All'} >All</MenuItem>
                                             {this.props.funnelFilters !== '' && this.props.funnelFilters.dealCat.map(y => <MenuItem key={y.deal_category__c} value={y.deal_category__c}>{y.deal_category__c}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                } */}
            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper} style={{paddingTop:8}} >

                <Grid item xs={12} sm={12} style={{ paddingTop: 0, height: '0px' }}>

                <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={this.props.funnelFilters !== '' && this.props.funnelFilters.acv}
                        // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                        //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                        onChange={this.onAcvChange}
                        value={this.state.acv}
                        data-test="acv"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField {...params} label="ACV >= :" margin="normal" variant="standard" style={{ marginTop: 4, }}
                            InputLabelProps={{ shrink: true, style: { color: this.state.acv !== null && this.state.acv !== 0 ? '#4472c4' : '#000' } }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />

                </Grid>

              </Grid> */}
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {this.props.funnelData !== "" && this.props.funnelData.text ? (
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.funnelData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Yield from Qualified pipeline at quarter start t1
                    </Typography>
                    <PipelineTable
                      winAcvData={[this.props.funnelData.text]}
                      splitType={"text"}
                      uniqueNames={["No Data Available"]}
                    />
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Projection for {this.props.funnelData.fiscalQuarter}
                      </Typography>
                      <LowMIdHighTable
                        Value_Label={this.props.Value_Label}
                        winAcvData={this.props.funnelData.expectedHighMidLow}
                        quarterLastDate={this.props.funnelData.quarterLastDate}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Source of Qualified pipe that went to Won
                      </Typography>
                      <PipelineTable
                        winAcvData={this.props.funnelData.combinedData}
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={this.props.funnelData.uniqueStrings}
                        uniqueStages={this.props.funnelData.uniqueNames.filter(
                          (c) => c !== "Total"
                        )}
                        firstCell="count"
                        secondCell="acv"
                        thirdCell="perc"
                        drilldownHeader="Summary view of Source of Qualified pipe"
                        tableHeader="Source of Qualified pipe"
                        cellHeader1="Opps"
                        cellHeader2="ACV"
                        Value_Label={this.props.Value_Label}
                        cellHeader3="% of Total"
                        handleChangeLocation={this.handleChangeLocation}
                        disableColumnTotal={true}
                        dataTest={"Source-of-Qualified-pipe"}
                        alphabetRequired={true}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Yield from Qualified pipeline at quarter start
                      </Typography>
                      <PipelineTable
                        winAcvData={this.props.funnelData.qualifiedAndPrjData}
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={this.props.funnelData.uniqueNames}
                        Value_Label={this.props.Value_Label}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                        firstCell="projectedACV"
                        secondCell="actualACV"
                        thirdCell="convPerc"
                        tableHeader="Stage at quarter start"
                        drilldownHeader="What was qualified and above and projected to be Won vs. what was Won"
                        cellHeader1="Projected"
                        cellHeader2="Actual"
                        cellHeader3="% Conv."
                        handleChangeLocation={this.handleChangeLocation}
                        dataTest={"Stage-at-quarter-start"}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        What was Qualified and Closed Won all in the same
                        quarter or pulled in from the set of opps Qualified or
                        later at Quarter start
                      </Typography>
                      <DeviationTable
                        winAcvData={
                          this.props.funnelData
                            .qualifiedandClosedWoninSameQuarterandPulledinFromNextQuarter
                        }
                        averageDevaitionCalculation={
                          this.props.funnelData.averageDevaitionCalculationV2
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} className={classes.grid} container justify="center" style= {{marginTop: "1rem"}}>
  <Grid item xs={12} style={{ marginBottom: '2rem', paddingBottom: '2rem' }} >
    <Card style={{ margin: "auto", width: "100%" }} raised={true}>
      {
        this.props.funnelData !== '' ?
          (<Grid item container xs={12} justify="center">
           
            <Typography variant='h2' style={{ margin: '1rem', display: 'flex', justifyContent: 'center', padding: '1rem' }}>{`Excpected from Qualified and above pipeline as of ${dateFormatterMomentV4(this.props.funnelData.quarterLastDate)}`}</Typography>
            <NextQuarterConversionTable 
              winAcvData={this.props.funnelData.nxtQuarterExpectedConversion}
              
              splitType={this.props.funnelData.splitType}
            />
           
          
          </Grid>) :
          (<Grid item container justify="center">
            <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
          </Grid>)
      }
    </Card>
  </Grid>
  </Grid> */}
            {/* <Grid item xs={12} className={classes.grid} container justify="center" style= {{marginTop: "1rem"}}>
  <Grid item xs={12} style={{ marginBottom: '2rem', paddingBottom: '2rem' }} >
    <Card style={{ margin: "auto", width: "100%" }} raised={true}>
      {
        this.props.funnelData !== '' ?
          (<Grid item container xs={12} justify="center">
           
            <Typography variant='h2' style={{ margin: '1rem', display: 'flex', justifyContent: 'center', padding: '1rem' }}>Expected from opps that will be Qualified and Closed Won in the same quarter and pulled in from future quarters</Typography>
            <ExpectedLowMidHighTable 
              winAcvData={this.props.funnelData.futureQuarterExpectedConversion}
            />
           
          
          </Grid>) :
          (<Grid item container justify="center">
            <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
          </Grid>)
      }
    </Card>
  </Grid>
  </Grid> */}
          </>
        )}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                Value_Label={this.props.Value_Label}
                header={this.state.header}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                acvName={
                  this.state.drildownACVName === "Projected" ||
                  this.state.drildownACVName === "Available"
                    ? "ACV at qtr. start"
                    : "ACV"
                }
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    Value_Label: user.Value_Label,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_funnel_filters_request" });
    appService.getTimeSeriesPredictionFilters().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.fiscalQuarters,
          "fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [{ name: "fiscal_quarter", value: defaultFiscalQuarter }];
        }
        filters = [...filters];
        const excludLargeDeals =
          getDefaultSalesType(
            json.message.exclude_large_deals,
            "exclude_large_deals"
          )[0] === "All"
            ? "No"
            : "Yes";
        // console.log(excludLargeDeals)
        if (excludLargeDeals === "Yes") {
          const thresholdFilters = getDefaultThreshold(
            json.message.large_deal_threshold
          );
          filters = [
            ...filters,
            { name: "threshold", value: thresholdFilters },
          ];
        }
        // const xyAxis=json.message.Dimensions.filter(i=>i.type === 'Ch_Opp_Column_Name')
        // const primary=xyAxis.filter(i=>i.default_axis === 'y')[0].Ch_Opp_Column_Name
        // const secondary=xyAxis.filter(i=>i.default_axis === 'x')[0].Ch_Opp_Column_Name
        dispatch({ type: "current_selected_filters", filters });

        appService.getTimeSeriesPredictionData(filters).then(
          (json) => {
            dispatch({ type: "get_funnel_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnel_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnel_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, primary, secondary) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getTimeSeriesPredictionData(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
  getConversionEffecienceyDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getConversionEffecienceyDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedTimeSeriesPrediction = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TimeSeriesPrediction));
export { connectedTimeSeriesPrediction as TimeSeriesPrediction };
