import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
} from "@material-ui/core";
import React from "react";
import * as d3 from "d3";
import {
  countOrACVFormatter,
  StyledTableRow,
  _isNaN,
} from "../../../../util/customFunctions";
import CopyTablesObject from "../../../../components/xlsx-export/CopyTablesObject";
const useStyle = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "10px 10px",
    color: "inherit",
  },
  nonScoreCell: {
    fontWeight: 400,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  repHeaderDrilldown: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    cursor: "pointer",
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
}));
function QTDDealsTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const uniqueNames = props.qtdDealsTableData.map((s) => s.name).sort();
  const compare = (a, b) => (a >= b ? "#e2efda" : null);
  return (
    <Grid item xs={6}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <Table ref={tableRef} data-test={props.dataTest}>
        <TableBody>
          {/* col headers row */}
          <StyledTableRow style={{ height: 60 }}>
            <TableCell
              align="center"
              className={`${classes.repHeader}`}
              colSpan={1}
              style={{ background: "#DDECB9", minWidth: 160 }}
            >
              QTD Deals &ge; $30K ACV
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              colSpan={1}
              style={{ background: "#4472C4", color: "white" }}
            >
              {"Goal # of deals"}
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              colSpan={1}
              style={{ background: "#70AD47", color: "white" }}
            >
              {"Actual # of deals"}
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              style={{ background: "#4472C4", color: "white" }}
            >
              {"Goal Avg. ACV"}
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              style={{ background: "#70AD47", color: "white" }}
            >
              {"Actual Avg. ACV"}
            </TableCell>
          </StyledTableRow>
          {/* Segment Row */}
          <StyledTableRow>
            <TableCell align="left" className={classes.repHeader} colSpan={1}>
              {props.selectedTeam.includes("All") ? "Company" : "Segment"}
            </TableCell>

            <TableCell align="right" className={classes.repHeader} colSpan={1}>
              {`${d3.format(",")(
                ![null, undefined, ""].includes(
                  props.qtdDealsSegmentRow[0].goal
                )
                  ? props.qtdDealsSegmentRow[0].goal
                  : 0
              )}`}
            </TableCell>
            <TableCell
              align="right"
              className={classes.repHeaderDrilldown}
              style={{
                backgroundColor: compare(
                  ![null, undefined, ""].includes(
                    props.qtdDealsSegmentRow[0].count
                  )
                    ? props.qtdDealsSegmentRow[0].count
                    : 0,
                  ![null, undefined, ""].includes(
                    props.qtdDealsSegmentRow[0].goal
                  )
                    ? props.qtdDealsSegmentRow[0].goal
                    : 0
                ),
              }}
              onClick={() =>
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  [],
                  uniqueNames,
                  "QTD20"
                )
              }
              colSpan={1}
            >
              {![null, undefined, ""].includes(
                props.qtdDealsSegmentRow[0].count
              )
                ? props.qtdDealsSegmentRow[0].count
                : 0}
            </TableCell>
            <TableCell align="right" className={classes.repHeader} colSpan={1}>
              $
              {countOrACVFormatter(
                ![null, undefined, ""].includes(
                  props.qtdDealsSegmentRow[0].goalACV
                )
                  ? props.qtdDealsSegmentRow[0].goalACV
                  : 0
              )}
            </TableCell>
            <TableCell
              align="right"
              className={classes.repHeaderDrilldown}
              onClick={() =>
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  [],
                  uniqueNames,
                  "QTD20"
                )
              }
              colSpan={1}
              style={{
                backgroundColor: compare(
                  ![null, undefined, ""].includes(
                    props.qtdDealsSegmentRow[0].acv
                  )
                    ? props.qtdDealsSegmentRow[0].acv
                    : 0,
                  ![null, undefined, ""].includes(
                    props.qtdDealsSegmentRow[0].goalACV
                  )
                    ? props.qtdDealsSegmentRow[0].goalACV
                    : 0
                ),
              }}
            >
              $
              {countOrACVFormatter(
                ![null, undefined, ""].includes(props.qtdDealsSegmentRow[0].acv)
                  ? props.qtdDealsSegmentRow[0].acv
                  : 0
              )}
            </TableCell>
          </StyledTableRow>

          {uniqueNames.map((rep) => {
            const rowData = props.qtdDealsTableData.filter(
              (item) => item.name === rep
            )[0];
            return (
              <>
                <StyledTableRow key={rep}>
                  <TableCell
                    align="left"
                    className={classes.repNameMargin}
                    colSpan={1}
                  >
                    {rep}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.repHeader}
                    colSpan={1}
                  >{`${d3.format(",")(
                    ![null, undefined, ""].includes(rowData.goal)
                      ? rowData.goal
                      : 0
                  )}`}</TableCell>
                  <TableCell
                    align="right"
                    style={{
                      cursor: "pointer",
                      backgroundColor: compare(
                        ![null, undefined, ""].includes(rowData.count)
                          ? rowData.count
                          : 0,
                        ![null, undefined, ""].includes(rowData.goal)
                          ? rowData.goal
                          : 0
                      ),
                    }}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        [],
                        [rep],
                        "QTD20"
                      )
                    }
                    className={classes.repHeader}
                    colSpan={1}
                  >
                    {![null, undefined, ""].includes(rowData.count)
                      ? rowData.count
                      : 0}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.repHeader}
                    colSpan={1}
                  >
                    {countOrACVFormatter(
                      ![null, undefined, ""].includes(rowData.goalACV)
                        ? rowData.goalACV
                        : 0
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      cursor: "pointer",
                      backgroundColor: compare(
                        ![null, undefined, ""].includes(rowData.acv)
                          ? rowData.acv
                          : 0,
                        ![null, undefined, ""].includes(rowData.goalACV)
                          ? rowData.goalACV
                          : 0
                      ),
                    }}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        [],
                        [rep],
                        "QTD20"
                      )
                    }
                    className={classes.repHeader}
                    colSpan={1}
                  >
                    $
                    {countOrACVFormatter(
                      ![null, undefined, ""].includes(rowData.acv)
                        ? rowData.acv
                        : 0
                    )}
                  </TableCell>
                </StyledTableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
}

export default QTDDealsTable;
