import { countOrAcvComparer } from "./comparator";
import { countOrACVFormatter } from "./customFunctions";

export const newFunnelTooltip = (
  OppStatus,
  stage,
  type,
  data,
  color,
  Value_Label
) => `
<div id="tooltip" class="tooltip-container">
  <div class="tooltip-box-scatter" style="border-top:10px solid ${color};border-radius:0.3rem;">              
    <div style="padding:0 0.5rem;"><h4 style="text-align:center;font-size:0.75rem;">${OppStatus} ${stage}</h4></div>
    <div class="tooltip-funeel-grid-container">
        <div class="flex-item" style="color:gray;font-size:0.75rem; text-align:center">
        ${
          type === "acv" ? `${Value_Label}` : "# of Opps"
        }: <span style="color:black;font-weight:600">${countOrACVFormatter(
  data,
  countOrAcvComparer(type)
)}</span>
        </div>      
    </div>
  </div>
</div>
`;

export const customHelperTooltip = (text, value) => `
<div id="tooltip" class="tooltip-container">
<div class="tooltip-box-scatter" style=border-radius:0.3rem;">              
<div style="padding:0 0.5rem;"><h4 style="font-size:0.75rem;">${text}</h4></div>
<div class="tooltip-funeel-grid-container">
    <div class="flex-item" style="color:gray;font-size:0.75rem; text-align:center">
     <span style="color:black;font-weight:600">${value}</span>
    </div>      
</div>
</div>
</div>
`;
export const customHelperTooltipUsingArray = (helperArray) => `
<div id="tooltip" class="new-tooltip-container">
  <div class="tooltip-box-scatter" style=border-radius:0.3rem;">
    <div  class="helper-tooltip-container helper-tooltip-array">
      ${helperArray
        .map(
          ({ helperText, value }, i) => `<div class="helper-item" style="${
            i + 1 !== helperArray.length
              ? "border-bottom:1px solid lightgrey"
              : ""
          }">
      <p style="font-size:0.75rem;">
        ${helperText} : <span class="font-bold">${value}</span>
      </p>
      </div>`
        )
        .join("")}
    </div>
  </div>
</div>
`;
export const customHelperTooltipV2 = (value) => `
<div id="tooltip" class="new-tooltip-container" style="box-hadow: 0 0.1rem 0.1rem 0;border-radius-1rem;padding:0px;margin-top:0.4rem;">
  <div class="tooltip-box-scatter" style="border-top:solid rgb(68, 113, 196);border-radius:0.5rem;width:290px;">
    <div  class="helper-tooltip-container helper-tooltip-array" style="width:290px;">
      <div class="helper-item" style="width:290px;">
      <p style="font-size:1.0rem;width:280px;">
         <span class="">${value}</span>
      </p>
      </div>
    </div>
  </div>
</div>
`;

export const customHelperTooltipUsingArrayWinRate = (helperArray) => `
<div id="tooltip" class="new-tooltip-container">
  <div class="tooltip-box-scatter" style=border-radius:0.3rem;">
    <div  class="helper-tooltip-container helper-tooltip-array-winrate">
      ${helperArray
        .map(
          ({ helperText, value }, i) => `<div class="helper-item" style="${
            i + 1 !== helperArray.length
              ? "border-bottom:1px solid lightgrey"
              : ""
          }">
      <p style="font-size:0.75rem;">
        ${helperText} : <span class="font-bold">${value}</span>
      </p>
      </div>`
        )
        .join("")}
    </div>
  </div>
</div>
`;
export const closedFunnelTooltip = (
  OppStatus,
  stage,
  type,
  data,
  color,
  Value_Label
) => `
<div id="tooltip" class="tooltip-container">
  <div class="tooltip-box-scatter" style="border-top:10px solid ${color};border-radius:0.3rem;">              
    <div style="padding:0 0.5rem;"><h4 style="text-align:center;font-size:0.75rem;">${OppStatus} ${stage}</h4></div>
    <div class="tooltip-closed-funeel-grid-container">
        <div class="flex-item" style="color:gray;font-size:0.75rem; text-align:center">
        ${
          type === "acv" ? `${Value_Label}` : "# of Opps"
        }: <span style="color:black;font-weight:600">${countOrACVFormatter(
  data,
  countOrAcvComparer(type)
)}</span>
        </div>      
    </div>
  </div>
</div>
`;
