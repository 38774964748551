import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
  dateFormatterMomentV2,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function RepCertificationTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const reps = props.data.map((s) => s.Rep).filter(getUniqueValues);
  //const toStages=props.StageProgressionData.tostages
  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  return (
    <>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "10px",
              marginRight: "2rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{ margin: "2rem", marginBottom: "1rem", width: "auto" }}
        id="myTableContainer"
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}

        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 120,
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Hire Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 140,
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Rep
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 90,
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Tenure <br /> (Mos. Days)
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                }}
                colspan={2}
                className={classes.colHeader}
              >
                SALs Assigned on or After Hire Dt.
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#70AD47",
                  border: "0.5px solid lightgrey",
                }}
                colspan={2}
                className={classes.colHeader}
              >
                Won from opps. assigned after Hire Dt.
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#C65911",
                  border: "0.5px solid lightgrey",
                }}
                colspan={2}
                className={classes.colHeader}
              >
                Lost from opps. assigned after Hire Dt.
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#BF8F00",
                  border: "0.5px solid lightgrey",
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Cumulative Quota <br /> since Hire dt.
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#70AD47",
                  border: "0.5px solid lightgrey",
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Quota <br /> Attainment
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                }}
                colspan={2}
                className={classes.colHeader}
              >
                {/* Win Rate from SQL */}
                {`${props.Win_Rate_Prefix} from SQL`}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                }}
                colspan={3}
                className={classes.colHeader}
              >
                Certification Completion
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
              }}
            >
              {Array(1)
                .fill()
                .map((stage, index) => (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      ACV
                    </TableCell>
                  </>
                ))}
              {Array(1)
                .fill()
                .map((stage, index) => (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        background: "#70AD47",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#70AD47",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      ACV
                    </TableCell>
                  </>
                ))}
              {Array(1)
                .fill()
                .map((stage, index) => (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        background: "#C65911",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#C65911",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      ACV
                    </TableCell>
                  </>
                ))}
              {Array(1)
                .fill()
                .map((stage, index) => (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      By Opp.
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      By ACV
                    </TableCell>
                  </>
                ))}
              {Array(1)
                .fill()
                .map((stage, index) => (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      # of modules
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      Avg. Completion
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        background: "#4472c4",
                        border: "0.5px solid lightgrey",
                      }}
                      className={classes.colHeader}
                    >
                      Avg. Score
                    </TableCell>
                  </>
                ))}
            </TableRow>
            {reps.map((rep, id) => {
              let repData = props.data.filter((s) => s.Rep === rep)[0];
              return (
                <StyledTableRow key={rep}>
                  <TableCell
                    align="right"
                    style={{ border: "0.5px solid lightgrey" }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.Hire_Date)
                      ? "-"
                      : dateFormatterMomentV2(repData.Hire_Date)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      border: "0.5px solid lightgrey",
                      paddingRight: "5px",
                    }}
                    className={classes.datacell}
                  >
                    {repData.showAsterisk ? ast(rep) : rep}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      border: "0.5px solid lightgrey",
                      paddingLeft: "2px",
                      paddingRight: "15px",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.tenure)
                      ? "-"
                      : repData.tenure}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "SAL"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(
                      repData.SALsAssignedonorafterHireDtOpps
                    )
                      ? "-"
                      : countOrACVFormatter(
                          repData.SALsAssignedonorafterHireDtOpps
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "SAL"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    $
                    {["", undefined, null].includes(
                      repData.SALsAssignedonorafterHireDtAcv
                    )
                      ? "0"
                      : countOrACVFormatter(
                          repData.SALsAssignedonorafterHireDtAcv
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Won"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(
                      repData.WonFromOppsAssignedAfterHireDtOpps
                    )
                      ? "0"
                      : countOrACVFormatter(
                          repData.WonFromOppsAssignedAfterHireDtOpps
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Won"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    $
                    {["", undefined, null].includes(
                      repData.WonFromOppsAssignedAfterHireDtAcv
                    )
                      ? "0"
                      : countOrACVFormatter(
                          repData.WonFromOppsAssignedAfterHireDtAcv
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "lost"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(
                      repData.LostFromOppsAssignedAfterHireDtOpps
                    )
                      ? "0"
                      : countOrACVFormatter(
                          repData.LostFromOppsAssignedAfterHireDtOpps
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "lost"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    $
                    {["", undefined, null].includes(
                      repData.LostFromOppsAssignedAfterHireDtAcv
                    )
                      ? "0"
                      : countOrACVFormatter(
                          repData.LostFromOppsAssignedAfterHireDtAcv
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Quota"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    $
                    {["", undefined, null].includes(
                      repData.cumulativeQuotasinceHireDt
                    )
                      ? "0"
                      : countOrACVFormatter(repData.cumulativeQuotasinceHireDt)}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ border: "0.5px solid lightgrey" }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.quotaAttainment)
                      ? "0"
                      : Math.round(repData.quotaAttainment * 100)}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ border: "0.5px solid lightgrey" }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.winRateByOpp)
                      ? "0"
                      : Math.round(repData.winRateByOpp * 100)}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ border: "0.5px solid lightgrey" }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.winRateByAcv)
                      ? "0"
                      : Math.round(repData.winRateByAcv * 100)}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Certification"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.noOfModules)
                      ? "0"
                      : Math.round(repData.noOfModules)}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Certification"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.avgCompletion)
                      ? "0"
                      : Math.round(repData.avgCompletion)}
                    %
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        repData.SFDC_Owner_ID,
                        rep,
                        repData.Hire_Date,
                        "Certification"
                      )
                    }
                    style={{
                      border: "0.5px solid lightgrey",
                      cursor: "pointer",
                      background: repData.thresholdColor,
                      color: ["#70AD47", "#FF0000"].includes(
                        repData.thresholdColor
                      )
                        ? "white"
                        : "",
                    }}
                    className={classes.datacell}
                  >
                    {["", undefined, null].includes(repData.avgScore)
                      ? "0"
                      : Math.round(repData.avgScore)}
                    %
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
