import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React from "react";
import { nearestCompletedFiscalQuarter } from "../util/fiscalQuarterFunctions";

function QuarterMonthFilterCombo(props) {
  return (
    <Grid
      item
      spacing={2}
      xs={12}
      container
      component={Paper}
      // style={{ props.dashboard_name === 'Cycle Time Analysis' ? paddingTop: 8 : '' }}
      style={props.dashboard_name && { paddingTop: 8 }}
    >
      {/* Closed Fiscal Quarter Filter */}
      <Grid item xs={6} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={!props.Closed_Year_Month.includes("All")}
          id="filter_cfq"
        >
          <InputLabel
            style={{
              color:
                props.activeTimeFilter !== "Closed_Year_Month"
                  ? "#4472c4"
                  : "#000",
            }}
            id="slect_cfq_label"
          >
            Closed Fiscal Quarter:
          </InputLabel>
          <Select
            labelId="select-vRenewal_Contract_Eff_Dt_Fiscal_Quarter-label"
            value={props.closedFiscalQuarterState}
            onChange={props.handleChange}
            label="closed_fiscal_quarter"
            name="closed_fiscal_quarter"
            data-test="closed-fiscal-quarter"
            multiple
            id={"select-closed_fiscal_quarter"}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                id: "filter_cfq_values",
              },
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "closed_fiscal_quarter" &&
                    y["closed_fiscal_quarter"] !== "" &&
                    y["closed_fiscal_quarter"] !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y["closed_fiscal_quarter"]}
                    value={y["closed_fiscal_quarter"]}
                  >
                    {y["closed_fiscal_quarter"]}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Closed Month Filter */}
      <Grid item xs={6} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={!props.closedFiscalQuarterState.includes("All")}
        >
          <InputLabel
            style={{
              color:
                props.activeTimeFilter === "Closed_Year_Month"
                  ? "#4472c4"
                  : "#000",
            }}
            id="select-vYearMonth-label"
          >
            Closed Month:
          </InputLabel>
          <Select
            labelId="select-vprojected_close_year_month-label"
            id="select-cClosed_Year_Month"
            value={props.Closed_Year_Month}
            onChange={props.handleChange}
            label="Closed_Year_Month"
            name="Closed_Year_Month"
            data-test="closed-year-month"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters === "" && (
              <MenuItem value={nearestCompletedFiscalQuarter()}>
                {nearestCompletedFiscalQuarter()}
              </MenuItem>
            )}
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "Closed_Year_Month" &&
                    y["Closed_Year_Month"] !== "" &&
                    y["Closed_Year_Month"] !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y["Closed_Year_Month"]}
                    value={y["Closed_Year_Month"]}
                  >
                    {y["Closed_Year_Month"]}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default QuarterMonthFilterCombo;
