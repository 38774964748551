import { TableRow, withStyles } from "@material-ui/core";

const StickyStyledTableRow = withStyles((theme) => ({
  root: {
    // position: "sticky",
    top: 0,
    // zIndex: 5,
    // backgroundColor: "#fff", // Ensure background color to avoid transparency issues
    fontWeight: 600,
    paddingLeft: 5,
    position: "sticky",
    outline: "lightgrey solid 0.5px",
  },
}))(TableRow);

export default StickyStyledTableRow;
