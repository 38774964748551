import { preprocessFilters } from "./preprocess-filters";

export const getFilters = (userInput, { closedFunnelFilters }) => {
  console.log(userInput, "metric_extraction");
  let metric = userInput.filters.metric;
  const filters = new Object(userInput.filters);
  // remove undefined properties from filters
  for (let prop in filters) {
    if (filters[prop] === undefined) {
      delete filters[prop];
    }
  }
  const lkpStages = userInput.lkpStages;
  if (!filters || !filters.hasOwnProperty("metric")) {
    return "I'm not sure what this means or how to respond.";
  } else {
    if (filters.hasOwnProperty("acvPreset")) {
      filters["acvPreset"] = parseInt(filters["acvPreset"]);
    }
    let filtersForAPI;
    try {
      filtersForAPI = preprocessFilters(filters);
    } catch (err) {
      throw new Error("Cannot construct filters");
    }
    const filtersArr = [];
    for (let filter in filtersForAPI) {
      const f = { name: filter, value: filtersForAPI[filter] };
      filtersArr.push(f);
    }
    const filtersBody = {
      filters: filtersArr,
    };
    return filtersBody;
  }
};
