import * as d3 from "d3"
import {layout, rgb, style} from 'd3'
import { countOrACVFormatter, getCustomSortedColoredLegends } from '../../util/customFunctions';
import { computeDomainLimits, computeDomainLimitsDate } from '../../Dashboard/OpenOpportunities/utils'

const D3oppChangeTimeLine = {};
D3oppChangeTimeLine.create = (el, config) => {
    console.log(config)
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  var formatClosedDate = (closedDate) =>
    new Date(closedDate).toLocaleDateString("en-US");

    var formatClosedDateTime = (closedDate) =>
    new Date(closedDate).toLocaleTimeString("en-US",{
        hour: '2-digit', 
        minute: '2-digit' ,
        second:'2-digit'
    });
    if (config.data) {        
        config.margin = {
            top: 0,
            right: 10,
            bottom: 0,
            left:15
        };

        config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width-config.margin.left-config.margin.right : 1000;       
        // config.width =  1000;

     
       config.height = 100 - config.margin.top - config.margin.bottom;
config.recheight =85
config.cirrds =10
config.smrecheight=config.recheight/3
config.eleheight=config.height-config.smrecheight
config.lineheight =30
console.log(config.eleheight)
const limit = 60
let j=0
let k=0
       console.log(config.data)
    config.data=  config.data.map(i=>{

 if (i.Change_Date !== undefined) {
        if (i.Change_Date !== null)
          var [closedYear, closedMonth, closedDate] = i.Change_Date.split("-")
      }

console.log(new Date(i.Change_Date).toISOString().replace("T"," ").substring(0, 19))       
    
     return{
             at_Date:(new Date(new Date(parseInt(closedYear), parseInt(closedMonth) - 1, parseInt(closedDate.substr(0, 2))).setHours(0)).setMinutes(0)),
             stage:i.Stage,
             acv:i.ACV,
             closed_date:i.PCD,
             text:new Date(i.Change_Date).toISOString().replace("T"," ").substring(0, 19) ,
             stage_direction: i.Stage_Change_Direction,
             acv_direction : i.ACV_Change_Direction,
             closed_direction : i.PCD_Change_Direction,
             change_date : i.Change_Date,
colors:i.colors,
colors_direction:{"1":"rgb(226 240 217)","2":"rgb(169 209 142)","3":"rgb(84 130 53)","-1":"rgb(244 177 131)","-2":"rgb(237 125 49)","-3":"rgb(197 90 17)","0":"rgb(166 166 166)",'none':"white"},
text_color: {"Forward":"rgb(112, 173, 71)", "Backward":'rgb(237, 124, 49)'}
            }

    })

    
    const circle_legands= [{color:"rgb(226 240 217)",name:"Green#1"},
    {color:"rgb(169 209 142)",name:"Green#2"},
    {color:"rgb(84 130 53)",name:"Green#3"},
    {color:"rgb(244 177 131)",name:"Orange#1"},
    {color:"rgb(237 125 49)",name:"Orange#2"},
    {color:"rgb(197 90 17)",name:"Orange#3"},
    {color:"rgb(166 166 166)",name:"Gray"},
    {color:"white",name:"White"},
]
    
   const getcolorcode=(d)=>{
    let s=0
    if(d.stage_direction==="Forward"){s++}
    if(d.acv_direction==="Forward"){s++}
    if(d.closed_direction==="Forward"){s++}
    if(d.stage_direction==="Backward"){s--}
    if(d.acv_direction==="Backward"){s--}
    if(d.closed_direction==="Backward"){s--}
    else{s=s}
   return s
   }
// Defining Chart container
       
        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width', config.width+40)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
            // defining the Chart
            var chart = chartContainer
            .append("g")
            .attr(
              "transform",
              "translate(" + config.margin.left + "," + config.margin.top + ")"
            );
// defining Tooltip
            var tooltip = d3
            .select(el)
            .append("div")
            .attr("class", "tooltip-scatter")
            .style("opacity", 0);      

     
// Getting Min and maximum Date Values for Xsacale
      const minValDate=d3.min(config.data,d=>d.at_Date)
      const maxValDate=d3.max(config.data,d=>d.at_Date)
      // Getting lowe and upper bound from the function
      let { lower_bound_date, upper_bound_date } = computeDomainLimitsDate(minValDate, maxValDate)
      console.log(minValDate,maxValDate)
      // console.log(new Date(lower_bound_date),new Date(upper_bound_date))
  
      console.log(new Date().getTime("11/01/2021"))
      // Defining Xscale
      var xScale
      xScale = d3
      .scaleTime()
      .domain([lower_bound_date, upper_bound_date])
      .range([config.margin.left,config.width-config.margin.right]);   
  console.log((config.data))
          // const date = (new Date(new Date(parseInt(closedYear), parseInt(closedMonth) - 1, parseInt(closedDate.substr(0, 2))).setHours(0)).setMinutes(0))
  
          // Drawing Xaxis
          chart
          .append("g")
          .attr("class", "xaxis")
          .attr("transform", "translate(0," + config.height/2 + ")")
          .call(d3.axisBottom(xScale).ticks(d3.select(el).node().getBoundingClientRect().width < 900 ? 2 : 6).tickFormat(d3.timeFormat("%d-%b-%y")));
    
          chart.selectAll(".xaxis text")
          .attr('font-size', '0.85rem')
          .attr('font-weight', 500)
          .attr('dy','1rem')

          // Adding Scatter Points
          chart
          .append("g")
          .selectAll("dot-scatter")
          .data(config.data)
          .enter()
          .append("circle")
          .attr("cx", function (d) {
            return xScale(d.at_Date)
          })
//           .attr("cy", (d,i)=>{
//             var current = xScale(d.at_Date)
// if(i>0){
//   const previous =xScale(config.data[i-1].at_Date)
//   console.log(current-previous)
//   if(current-previous<1){
//    j++
//     return config.height/2-(config.cirrds)*j
//   }else{
//     j=0
//   }

// }
//   return config.height   })
.attr('cy', config.height/2)
          .attr("r", config.cirrds)
          .attr("class", "dot-scatter")
          .attr("stroke", "black")
          .attr("stroke-width", '0.5px')
          .attr('fill',(d,i)=>i>0?(d.colors_direction[getcolorcode(d)]):'white')
          .style("cursor", "pointer")
          .on("mouseover", (d,i) => {
            tooltip.transition().duration(200).style("opacity", 1)
            tooltip
            .html(
//               `<div id="tooltip" class="tooltip-container" >
//               <div class="tooltip-box-scatter"  style="border-top:10px solid ${i>0?d.colors_direction[getcolorcode(d)]:"white"}  ;border-radius:0.3rem;  ">
            
//               <div>
//               <div>
//             <div style="border-bottom:1px solid lightgrey">
//               <div class="flex-item" style="font-size: 0.85rem;font-weight: 500; text-align:center;margin-top:-0.5rem;font-weight:400">${formatClosedDate(d.text)},  ${formatClosedDateTime(d.text)}  </div>
//               <div class="flex-item" style="font-size: 0.85rem;font-weight: 500; text-align:center;margin-top:-0.5rem;margin-bottom:-0.2rem"> </div>
//            </div>
           
//             <div  style="font-size: 0.85rem; margin: 0.05rem;
//             width: 12rem;  display: grid; grid-template-columns: 10rem auto; grid-gap: 1rem;">
//             <div style="display: grid; grid-template-columns: 6.5rem 5.8rem; ">
//             <div class="flex-item" style="color:gray;">Stage:</div>
//             <div class="flex-item" style="font-weight: 400;text-align: right;padding-right:15px;padding-left:7px ;background:${d.text_color[d.stage_direction]};color:${d.stage_direction==='None'?'iherit':'white'};font-weight:${d.stage_direction==='None'?'iherit':600};" >${d.stage}</div>
//             </div>
//             </div>
//            </div>

//             <div  style="font-size: 0.85rem; margin: 0.05rem;
//             width: 12rem;  display: grid; grid-template-columns: 10rem auto; grid-gap: 1rem;">
//             <div style="display: grid; grid-template-columns: 6.5rem 5.8rem; ">
//             <div class="flex-item" style="color:gray;">ACV:</div>
//             <div class="flex-item" style="font-weight: 400; text-align: right;padding-right:15px; padding-left:7px;background:${d.text_color[d.acv_direction]};color:${d.acv_direction==='None'?'iherit':'white'};font-weight:${d.acv_direction==='None'?'iherit':600};">${d.acv===null?"-":formatter.format(d.acv)} </div>
//             </div>
//             </div>
//             </div>
            

//             <div  style="font-size: 0.85rem; margin: 0.05rem;margin-bottom:-0.5rem
//             width: 12rem;  display: grid; grid-template-columns: 10rem auto; grid-gap: 1rem;">
//             <div style="display: grid; grid-template-columns: 6.5rem 5.8rem; ">
//             <div class="flex-item" style="color:gray;">Proj. Close Dt:</div>

//             <div class="flex-item" style="font-weight: 400; text-align: right;padding-right:20px;padding-left:7px; background:${d.text_color[d.closed_direction]};color:${d.closed_direction==='None'?'iherit':'white'};font-weight:${d.closed_direction==='None'?'iherit':600};">${formatClosedDate(d.closed_date)}</div>
//             </div>
//             </div>



//             <div>
// <div>
// <div>
// <div>`

`


<div style="text-align:center;border-top:10px solid ${i>0?d.colors_direction[getcolorcode(d)]:"white"}  ;border-radius:0.3rem;border-bottom:1px solid lightgrey;border-left:1px solid lightgrey;border-right:1px solid lightgrey">


<table>

<p style="text-align:center;border-bottom:1px solid lightgrey;padding:0.25rem;font-size: 0.85rem;">
${formatClosedDate(d.text)},  ${formatClosedDateTime(d.text)} 

</p>
  
  <tr style="border:1px solid black ">
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400;text-align: left;padding-right:15px;padding-left:7px;color:grey;">Stage:</td>
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400;text-align: right;padding-right:15px;padding-left:7px;background:${d.text_color[d.stage_direction]};color:${d.stage_direction==='None'?'iherit':'white'};font-weight:${d.stage_direction==='None'?'iherit':600};">${d.stage}</td>
  </tr>
  <tr>
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400;text-align: left;padding-right:15px;padding-left:7px;color:grey;">ACV:</td>
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400; text-align: right;padding-right:15px; padding-left:7px;background:${d.text_color[d.acv_direction]};color:${d.acv_direction==='None'?'iherit':'white'};font-weight:${d.acv_direction==='None'?'iherit':600};">${d.acv===null?"-":formatter.format(d.acv)} </td>
  </tr>
  <tr>
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400;text-align: left;padding-right:15px;padding-left:7px;color:grey">Proj. Close Dt:</td>
    <td style="font-size: 0.85rem;padding:0.2rem;font-weight: 400; text-align: right;padding-right:15px;padding-left:7px; background:${d.text_color[d.closed_direction]};color:${d.closed_direction==='None'?'iherit':'white'};font-weight:${d.closed_direction==='None'?'iherit':600};">${formatClosedDate(d.closed_date)}</td>
    </tr>
  
</table>
</div>`


            )
            .style('position','absolute')
            // .style("top",  (d-(d*0.5))+ "px")
        .style("left", d3.event.pageX<window.innerWidth * 0.55?(d3.event.pageX -100) + "px":(d3.event.pageX -230) + "px")
        .style("top",(d3.event.pageY)<window.innerHeight*0.45?(d3.event.pageY)+"px":(window.innerHeight-(0.55*window.innerHeight))+'px')

console.log(d3.event.pageY,window.innerHeight)
          })
    
          .on("mouseout", (d) => {
            tooltip.transition().duration(300).style("opacity", 0);
          });          
          


          // if (d3.select(el).node().getBoundingClientRect().width < 550) {
          //   let offset = config.width > 600 ? 110 : 70
          //   let offset2 = offset - (config.width > 600 ? 20 : 15)
          //   circle_legands.map((s, i) => chart.append("circle").attr("class", "dot-scatter-legend").attr("r", 10).attr("cx", ((config.width / circle_legands.length) * (i + 1)) - offset).attr("cy", config.height/2 + 75).style("fill",s.color))
          //   circle_legands.map((s, i) => chart.append("text").attr("x", ((config.width / circle_legands.length) * (i + 1)) - offset2).attr("y", config.height/2 + 80).text(s.name).style("font-size", "0.85rem").style('font-weight', 400))


          // }else {
          //   let offset = config.width > 600 ? 110 : 70
          //   let offset2 = offset - (config.width > 600 ? 20 : 15)
          //   circle_legands.map((s, i) => chart.append("circle").attr("class", "dot-scatter-legend").attr("r", 10).attr("cx", ((config.width / circle_legands.length) * (i + 1)) - offset).attr("cy", config.height/2 + 75).style("fill",s.color))
          //   circle_legands.map((s, i) => chart.append("text").attr("x", ((config.width / circle_legands.length) * (i + 1)) - offset2).attr("y", config.height/2 + 80).text(s.name).style("font-size", "0.85rem").style('font-weight', 400))

          // }






    }

}
D3oppChangeTimeLine.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
};
export default D3oppChangeTimeLine;