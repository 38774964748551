import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  root: {},
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader1: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function AverageDealSizeFirstTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [tableData, columnTotal, totalRow, totalAverage] = props.winAcvData;

  const uniqueQuarters = tableData
    .map((item) => item[props.quarter])
    .filter(getUniqueValues);

  let uniqueStages = totalRow
    .map((item) => item[props.type])
    .filter(getUniqueValues);

  const dataTotal = uniqueStages
    .map((segment) => {
      return {
        acv: totalRow
          .filter((item) => item[props.type] === segment)
          .reduce((a, b) => a + b[props.prop2], 0),
        segment,
      };
    })
    .sort((a, b) => (a.acv > b.acv ? -1 : 1));

  uniqueStages = dataTotal.map((item) => item.segment).filter(getUniqueValues);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  let totalindex;

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer-second"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={props.dataTest}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader1}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader1}`
                        : classes.repHeader1
                    }
                    colSpan={1}
                  >
                    {props.quarter === "Closed_Year_Month"
                      ? "Closed Month"
                      : deSnake(props.quarter)}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader1}
                        colSpan={props.tableType === "dealsTable" ? 1 : 2}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <TableCell
                      style={
                        uniqueQuarters.length > 1 &&
                        uniqueQuarters.length % 2 === 0
                          ? { background: "#4471c4", color: "white" }
                          : { background: "#5b9bd5", color: "white" }
                      }
                      align="center"
                      className={classes.repHeader1}
                      colSpan={props.tableType === "dealsTable" ? 1 : 2}
                    >
                      {"Total"}
                    </TableCell>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader1}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader1}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader1}`
                        : classes.repHeader1
                    }
                    colSpan={1}
                  >
                    {props.label !== undefined
                      ? props.label
                      : getMixKey(props.type)}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          className={classes.repHeader1}
                          colSpan={1}
                        >
                          # of Opps
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader1}
                          colSpan={1}
                          style={{ maxWidth: 100 }}
                        >
                          {props.subtitle}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment key={"total"}>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                        style={{ maxWidth: 100 }}
                      >
                        {props.subtitle}
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueStages.map((row, i) => {
                  totalindex = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        const cellData = tableData.filter(
                          (item) =>
                            item[props.quarter] === cell &&
                            item[props.type] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? countOrACVFormatter(
                                    cellData[props.prop1],
                                    false
                                  )
                                : "-"}
                            </TableCell>
                            {props.tableType !== "dealsTable" && (
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row, cell },
                                    props.type,
                                    props.quarter
                                  )
                                }
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {cellData !== undefined
                                  ? props.tableType === "AvgAcv"
                                    ? `$${countOrACVFormatter(
                                        Math.round(cellData[props.prop2])
                                      )}`
                                    : `${Math.round(cellData[props.prop2])}`
                                  : "-"}
                              </TableCell>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row, cell: "Total" },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {countOrACVFormatter(
                              totalRow
                                .filter((item) => item[props.type] === row)
                                .map((item) => item[props.prop1])
                                .reduce((a, b) => a + b, 0),
                              false
                            )}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row, cell: "Total" },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {props.tableType === "AvgAcv"
                              ? `$${countOrACVFormatter(
                                  Math.round(
                                    totalRow
                                      .filter(
                                        (item) => item[props.type] === row
                                      )
                                      .map((item) => item[props.prop2])
                                      .reduce((a, b) => a + b, 0)
                                  )
                                )}`
                              : `${Math.round(
                                  totalRow
                                    .filter((item) => item[props.type] === row)
                                    .map((item) => item[props.prop2])
                                    .reduce((a, b) => a + b, 0)
                                )}`}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow style={{ fontWeight: 600 }}>
                  <TableCell
                    // style={{ fontWeight: 600 }}
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && totalindex % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const columnValue = columnTotal
                      .filter((item) => item[props.quarter] === cell)
                      .map((item) => item[props.prop1])
                      .reduce((a, b) => a + b, 0);
                    const columnValue2 = columnTotal
                      .filter((item) => item[props.quarter] === cell)
                      .map((item) => item[props.prop2])
                      .reduce((a, b) => a + b, 0);

                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          style={{ cursor: "pointer", fontWeight: 600 }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {columnValue !== undefined
                            ? countOrACVFormatter(columnValue, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer", fontWeight: 600 }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {columnValue2 !== undefined
                            ? props.tableType === "AvgAcv"
                              ? `$${countOrACVFormatter(
                                  Math.round(columnValue2)
                                )}`
                              : `${Math.round(columnValue2)}`
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: "Total" },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          totalAverage
                            .map((item) => item[props.prop1])
                            .reduce((a, b) => a + b, 0),
                          false
                        )}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: "Total" },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.tableType === "AvgAcv"
                          ? `$${countOrACVFormatter(
                              Math.round(
                                totalAverage
                                  .map((item) => item[props.prop2])
                                  .reduce((a, b) => a + b, 0)
                              )
                            )}`
                          : `${Math.round(
                              totalAverage
                                .map((item) => item[props.prop2])
                                .reduce((a, b) => a + b, 0)
                            )}`}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
