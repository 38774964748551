import { Typography,capitalize } from '@material-ui/core'
import React from 'react'
import { countOrACVFormatter } from '../../util/customFunctions'

const MetaData = (props) => {
  return (
    
      <> <span >
        <Typography variant="body1" style={{ fontWeight: '600' }}> Expected Yield</Typography>
        
      </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {props.metaArr.map(item=> <Typography variant="body1" style={{ fontWeight: '600' }}>{capitalize(item.label)}: <span data-test={item.label}>{countOrACVFormatter(item.value,true)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>)}
      </>
    
  )
}

export default MetaData
