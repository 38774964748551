import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
let AccountSettings = (props) => {
  useEffect(() => {
    props.getSettings();
  }, []);
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      spacing={1}
      direction="column"
    >
      <Grid item md={7}>
        <Typography variant="h2">Change Password</Typography>
        <Typography variant="body1" style={{ marginTop: 5 }}>
          Note: Password must contain at least 8 characters, at least 1 number,
          at least 1 special character (*, @, !, #, %, &, ^, ~, $, -, _), at
          least 1 upper case character and at least 1 lower case character.
        </Typography>
        <Box mt={0} mb={2}>
          <Formik
            initialValues={{
              oldpassword: "",
              password: "",
              confirm: "",
              visible1: false,
              visible2: false,
              visible3: false,
            }}
            validationSchema={Yup.object().shape({
              oldpassword: Yup.string()
                .max(255)
                .required("Current Password is required."),
              password: Yup.string()
                .max(255)
                .required("Password is required.")
                .min(
                  8,
                  "Password is too short - should be a minimum of 8 characters."
                )
                .matches(
                  /.*[0-9].*/,
                  "Password must contain at least 1 number."
                )
                .matches(
                  /[*@!#%&^~$-_]+/,
                  "Password must contain at least 1 special character."
                )
                .matches(
                  /[A-Z]+/,
                  "Password must contain at least 1 upper case character."
                )
                .matches(
                  /[a-z]+/,
                  "Password must contain at least 1 lower case character."
                ),
              confirm: Yup.string().oneOf(
                [Yup.ref("password"), null],
                "Passwords must match."
              ),
            })}
            onSubmit={(values, { resetForm }) => {
              props.changePass(values.oldpassword, values.password);
              resetForm({});
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.oldpassword && errors.oldpassword)}
                  fullWidth
                  helperText={touched.oldpassword && errors.oldpassword}
                  label="Current Password"
                  margin="normal"
                  name="oldpassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.oldpassword}
                  variant="outlined"
                  type={values.visible1 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {values.visible1 ? (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible1", false, false)
                            }
                          >
                            {" "}
                            <OffIcon />{" "}
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible1", true, false)
                            }
                          >
                            <ViewIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  variant="outlined"
                  type={values.visible2 ? "text" : "password"}
                  style={{ marginTop: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {values.visible2 ? (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible2", false, false)
                            }
                          >
                            {" "}
                            <OffIcon />{" "}
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible2", true, false)
                            }
                          >
                            <ViewIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={Boolean(touched.confirm && errors.confirm)}
                  fullWidth
                  helperText={touched.confirm && errors.confirm}
                  label="Confirm New Password"
                  margin="normal"
                  name="confirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirm}
                  variant="outlined"
                  type={values.visible3 ? "text" : "password"}
                  style={{ marginTop: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {values.visible3 ? (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible3", false, false)
                            }
                          >
                            {" "}
                            <OffIcon />{" "}
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              setFieldValue("visible3", true, false)
                            }
                          >
                            <ViewIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Box mt={1}>
                  <Button
                    color="primary"
                    disabled={
                      (props.persistentAlert !== "" &&
                        props.persistentAlert.message ===
                          "Updating password...") ||
                      Boolean(touched.password && errors.password) ||
                      Boolean(touched.confirm && errors.confirm)
                    }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Change Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  const { persistentAlert, alert, settings, version, settingsPage } = state.app;

  return {
    persistentAlert,
    location: settingsPage,
    alert,
    settings,
    version,
  };
}
const mapDispatchToProps = (dispatch) => ({
  changePass: (oldpassword, newpassword) => {
    dispatch({ type: "change_pass_loggedin_request" });
    appService.changePassLoggedIn(oldpassword, newpassword).then(
      (json) => {
        dispatch({ type: "change_pass_loggedin_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "change_password_loggedin_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "change_password_loggedin_failure", error });
      }
    );
  },
  getSettings: () => {
    dispatch({ type: "get_settings_request" });
    appService.getSettings().then(
      (json) => {
        dispatch({ type: "get_settings_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_settings_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_settings_failure", error });
      }
    );
  },
});
AccountSettings = connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
export default AccountSettings;
