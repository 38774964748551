import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import d3SankeyChartBuilder from "../SankeyPoc/D3SankeyChart";
import D3SankeyContainer from "./D3SankeyContainer";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import { originalData } from "../SankeyPoc/originalData";
const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    cursor: "pointer",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));

export default function PCDMovedTableMetric(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableRef2 = React.createRef();

  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  let countinc = 0;

  const tableData = props.tableData.countOppsData;
  const nettedData = props.data;
  const uniqueNewQuarters = tableData
    .map((item) => item.Projected_Close_Date_New_Fiscal_Quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueOldQuarters = tableData
    .map((item) => item.Projected_Close_Date_Old_Fiscal_Quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueTargets = nettedData
    .map((item) => item.target)
    .filter(getUniqueValues)
    .sort();
  const uniqueSources = nettedData
    .map((item) => item.source)
    .filter(getUniqueValues)
    .sort();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "98%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        <Typography align="center" variant="h2" style={{ margin: "1.5rem" }}>
          {`Projected Close Date Moves (Net Moves) - ${props.title}`}
        </Typography>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef2} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer-Second"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef2} />
              </div>
            ) : null}

            <Table
              ref={tableRef2}
              data-test={"pcd_moved_table-net-flow"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    //   rowSpan={2}
                    style={{ minWidth: 100, maxWidth: 100 }}
                  >
                    From <span style={{ fontSize: "0.85rem" }}> &#8595; </span>{" "}
                    / To <span style={{ fontSize: "0.85rem" }}> &#8594; </span>{" "}
                  </TableCell>
                  {uniqueNewQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        //   colspan={2}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueNewQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueNewQuarters.length > 1 &&
                          uniqueNewQuarters.length % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                        }
                        align="center"
                        className={classes.repHeader}
                        //   colspan={2}
                      >
                        {"Grand Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueNewQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                {uniqueOldQuarters.map((row, i) => {
                  countinc = i;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && countinc % 2 === 0
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueNewQuarters.map((cell, j) => {
                        let cellData = nettedData.filter(
                          (item) => item.target === cell && item.source === row
                        );
                        cellData =
                          cellData.length <= 0
                            ? { count: 0, acv: 0 }
                            : cellData[0];
                        let cellValue = countOrACVFormatter(
                          _isNaN(cellData[props.metric])
                            ? 0
                            : cellData[props.metric],
                          props.metric === "acv"
                        );
                        console.log(cellData);
                        let styleObject = {};
                        if (row === cell) {
                          styleObject = { backgroundColor: "gray" };
                        } else if (
                          cellData.oldFiscalQuarters === undefined ||
                          cellData.newFiscalQuarters === undefined
                        ) {
                          styleObject = { cursor: "auto" };
                          cellValue = "-";
                        }
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                              style={
                                row === cell ? { backgroundColor: "gray" } : {}
                              }
                              onClick={() =>
                                cellData.oldFiscalQuarters === undefined ||
                                cellData.newFiscalQuarters === undefined
                                  ? null
                                  : props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        oldQtr: cellData.oldFiscalQuarters,
                                        newQtr: cellData.newFiscalQuarters,
                                      },
                                      "actual",
                                      true
                                    )
                              }
                            >
                              {row === cell ? "" : cellValue}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueNewQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            onClick={() =>
                              nettedData
                                .filter((item) => item.source === row)
                                .map((item) => item[props.metric])
                                .reduce((a, b) => a + b, 0) > 0
                                ? props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      oldQtr: nettedData
                                        .filter((item) => item.source === row)
                                        .reduce(
                                          (a, b) => [
                                            ...a,
                                            ...b.oldFiscalQuarters,
                                          ],
                                          []
                                        ),
                                      newQtr: nettedData
                                        .filter((item) => item.source === row)
                                        .reduce(
                                          (a, b) => [
                                            ...a,
                                            ...b.newFiscalQuarters,
                                          ],
                                          []
                                        ),
                                    },
                                    "actual",
                                    true
                                  )
                                : null
                            }
                          >
                            {countOrACVFormatter(
                              nettedData
                                .filter((item) => item.source === row)
                                .map((item) => item[props.metric])
                                .reduce((a, b) => a + b, 0),
                              props.metric === "acv"
                            )}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    // style={{ fontWeight: 600 }}
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && (countinc + 1) % 2 === 0
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                  >
                    Grand Total
                  </TableCell>
                  {uniqueNewQuarters.map((cell, j) => {
                    const columnValue = nettedData
                      .filter((item) => item.target === cell)
                      .map((item) => item[props.metric])
                      .reduce((a, b) => a + b, 0);
                    const columnAcv = nettedData
                      .filter((item) => item.target === cell)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0);

                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          className={classes.repNumber}
                          colSpan={1}
                          onClick={() =>
                            columnValue > 0
                              ? props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    oldQtr: nettedData
                                      .filter((item) => item.target === cell)
                                      .reduce(
                                        (a, b) => [
                                          ...a,
                                          ...b.oldFiscalQuarters,
                                        ],
                                        []
                                      ),
                                    newQtr: nettedData
                                      .filter((item) => item.target === cell)
                                      .reduce(
                                        (a, b) => [
                                          ...a,
                                          ...b.newFiscalQuarters,
                                        ],
                                        []
                                      ),
                                  },
                                  "actual",
                                  true
                                )
                              : null
                          }
                        >
                          {countOrACVFormatter(
                            columnValue,
                            props.metric === "acv"
                          )}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueNewQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              oldQtr: nettedData.reduce(
                                (a, b) => [...a, ...b.oldFiscalQuarters],
                                []
                              ),
                              newQtr: nettedData.reduce(
                                (a, b) => [...a, ...b.newFiscalQuarters],
                                []
                              ),
                            },
                            "actual",
                            true
                          )
                        }
                      >
                        {countOrACVFormatter(
                          nettedData
                            .map((item) => item[props.metric])
                            .reduce((a, b) => a + b, 0),
                          props.metric === "acv"
                        )}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
