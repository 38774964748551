import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow, _isNaN } from '../../util/customFunctions'
import tableStyle from '../styles/tableStyle'
import NoDataCell from '../Pages/AccountHealth/components/NoDataCell'
export default function QuarterTrendsTable(props) {
    const classes = tableStyle()

    let rows = props.data.map(item => item[props.row]).filter(getUniqueValues)
    const columns = props.data.map(item => item[props.column]).filter(getUniqueValues)
    if (props.type === "QBR") {
        rows = rows.filter(item => item !== "Prime")
        rows.unshift("Prime")
    }
    const drilldownHandler = (col, row, meta) => {
        if (props.chartName === "changeFromPriorWeek") {
            props.handleChangeLocation("drilldownOnGraph", { col, row }, meta)
        }
    }
    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            {
                                props.changeFromPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell style={{ background: 'white' }}></TableCell>


                                    <TableCell align="center" colSpan={3 * columns.length} className={classes.colHeader} style={{ backgroundColor: '#bf8f00' }}>Get Well by Prior Week</TableCell>
                                </TableRow> : null
                            }
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell className={classes.colHeader} style={{ background: 'white' }}></TableCell>
                                {columns.map((item, index) => <TableCell colSpan={3} style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} align="center" className={classes.colHeader}>{item}</TableCell>)}
                                {columns.length > 1 && <TableCell colSpan={3} align="center" style={columns.length > 1 && columns.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{`Total`}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.colHeader} style={{ background: 'white' }}></TableCell>
                                {columns.map(item => <>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Accounts`}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'CARR'}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`% of Total`}</TableCell>
                                </>)}
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Accounts`}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'CARR'}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`% of Total`}</TableCell>
                                    </>
                                }
                            </TableRow>
                            {
                                rows.map(row =>
                                    <>
                                        <StyledTableRow>
                                            <TableCell style={{ minWidth: 100 }} align="left" className={classes.dataCell}>{row}</TableCell>
                                            {columns.map(col => {
                                                const cellData = props.data.filter(item => item[props.column] === col && item[props.row] === row)[0]
                                                const totalCRR = props.data.filter(item => item[props.column] === col).map(item => item.CARR).reduce((a, b) => a + b, 0)
                                                return <>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{cellData === "-" ? cellData : cellData !== undefined ? countOrACVFormatter(cellData[props.countKey], false) : "- "}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{cellData === "-" ? cellData : cellData !== undefined ? countOrACVFormatter(cellData.CARR, true) : "- "}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{_isNaN((Math.round((props.data.filter(item => item[props.row] === row && item[props.column] === col).map(item => item.CARR).reduce((a, b) => a + b, 0) / totalCRR) * 100))) ? 0 : `${(Math.round((props.data.filter(item => item[props.row] === row && item[props.column] === col).map(item => item.CARR).reduce((a, b) => a + b, 0) / totalCRR) * 100))}%`}</TableCell>
                                                </>
                                            })}
                                            {
                                                columns.length > 1 &&
                                                <>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item[props.countKey]).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{
                                                        `${Math.round(props.data.filter(item => item[props.row] === row).map(item => item.CARR).reduce((a, b) => a + b, 0) / props.data.map(item => item.CARR).reduce((a, b) => a + b, 0) * 100)}%`
                                                    }</TableCell>
                                                </>
                                            }
                                        </StyledTableRow>

                                    </>
                                )
                            }
                            <StyledTableRow>
                                <TableCell align="left" className={classes.dataCell}>{`Total`}</TableCell>
                                {
                                    columns.map(col => {
                                        const totalCRR = props.data.filter(item => item[props.column] === col).map(item => item.CARR).reduce((a, b) => a + b, 0)

                                        return <>

                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.column] === col).map(item => item[props.countKey]).reduce((a, b) => a + b, 0), false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.column] === col).map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{`100%`}</TableCell>
                                        </>
                                    })
                                }
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item[props.countKey]).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item.CARR).reduce((a, b) => a + b, 0), true)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{`100%`}</TableCell>
                                    </>
                                }
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
