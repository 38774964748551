import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSalesType,
  getDefaultACV,
} from "../../util/customFunctions";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import Drilldown from "../../components/Drilldown/Drilldown";
import FiscalQuarterFilter from "../../Filters/FiscalQuarterFilter";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import AccountSegmentFilter from "../../Filters/AccountSegment";
import {
  colorsForRenewal,
  renewalsRow,
} from "../../CustomerSuccess/Pages/RenewalsCompleted/components/utils";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import { getMixKey } from "../../util/generateHeadings";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import { deSnake } from "../../util/dataFormatter";
import { updatedScheme10 } from "../../util/chartColorScheme";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import DynamictableV2 from "../../components/DynamicTableV2";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import addHeaderdescription from "../../components/HeaderDescription";
import PipelineProgressionTable from "./PipelineProgressionTable";
import PipelineProgressionTableContainer from "./PipelineProgressionContainer";
import D3SankeyContainer from "./SankeyContainer";
import { removeDateFromString, removeYearFromString } from "./utils";
const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});

const monthYearObj = {
  1: "Jan.",
  2: "Feb.",
  3: "Mar.",
  4: "Apr.",
  5: "May",
  6: "Jun.",
  7: "Jul.",
  8: "Aug.",
  9: "Sep.",
  10: "Oct.",
  11: "Nov.",
  12: "Dec.",
};

const monthFormmatter = (month) => {
  const [year, mon] = month.split("-");
  const monValue = monthYearObj[parseInt(mon)];
  return `${monValue} ${year}`;
};
class PipelineCreatedMixAnalysis extends Component {
  filters = [
    "Created_Year",
    "Projected_Close_Year_Month",
    "Open_Initial_Year_Month",
    "Qualified_Initial_Year_Month",
    "Created_Year_Month",
    "Created_Fiscal_Quarter",
    "Created_Month",
    "Projected_Close_Date_At_Time_Fiscal_Quarter",
    "Open_Date_Initial_Fiscal_Quarter",
    "Open_Date_Initial_Calendar_Year",
    "Open_Date_Initial_Calendar_Month",
    "Qualified_Date_Initial_Fiscal_Quarter",
    "Qualified_Date_Initial_Calendar_Month",
    "Qualified_Date_Initial_Calendar_Year",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
    "team",
    "rep",
    "Acct_Vertical",
    "Solution_Cat",
    "Acct_Segment",
    "Cust_Type",
    "Acct_Industry",
    "Acct_Size_Segment",
    "ACV_Range",
    "Lead_Source",
    "Record_Type_Name__c",
    "Type",
  ];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  state = {
    projected_close_fiscal_quarter: ["All"],
    Projected_Close_Year_Month: ["All"],
    Qualified_Date_Initial_Fiscal_Quarter: ["All"],
    Created_Year: ["All"],
    Created_Month: ["All"],
    Created_Fiscal_Quarter: ["All"],
    Created_Year_Month: ["All"],
    Qualified_Initial_Year_Month: ["All"],
    Open_Initial_Year_Month: ["All"],
    activeTimeFilter: "Projected_Close_Fiscal_Quarter",
    Open_Date_Initial_Fiscal_Quarter: ["All"],
    Open_Date_Initial_Calendar_Year: ["All"],
    Open_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Year: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldownPcfq: ["All"],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    ACV_Range: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Lead_Source: ["All"],
    Product_Line: ["All"],
    milestone_stage: "",
    selectCol: "Open_Date_Initial_Fiscal_Quarter",
    group_by: "group_by_quarter",
    isMonth: false,
    tab: "Summary",
    acv: "",
    acvlt: "",
    toggleValue: "count",
    limit4QuartersError: false,
    Record_Type_Name__c: ["All"],
  };

  oppTypeName = "Type";
  componentDidMount() {
    this.props.getQualifiedPipelineCreatedAnalysisFilters(this.props.company);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.PipelineProgressionFilters !== "" &&
      prevProps.PipelineProgressionFilters === ""
    ) {
      const stageSequence = getDefaultSelectedStage(
        this.props.PipelineProgressionFilters.filter(
          (f) => f.type === "milestone_stage"
        )
      );
      console.log("milestoneStages", stageSequence);
      let stage_names = [];
      const stageLength = this.props.PipelineProgressionFilters.filter(
        (f) => f.type === "milestone_stage"
      ).length;
      let col = "Open_Date_Initial_Fiscal_Quarter";
      if (stageLength === 1) {
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }
      this.props.PipelineProgressionFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence == stageSequence) stage_names = i.stage_name;
        }
      });
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let oppTypes = ["All"];
      let oppTypeColumn;
      if (
        this.props.PipelineProgressionFilters.filter((s) => s.oppType).length >
        0
      ) {
        oppTypeColumn = this.props.PipelineProgressionFilters.filter(
          (s) => s.oppType
        )[0].oppType;
        this.oppTypeName = oppTypeColumn;
        this.variableCols = {
          ...this.variableCols,
          [oppTypeColumn]: oppTypeColumn,
        };
        oppTypes = getDefaultSalesType(
          this.props.PipelineProgressionFilters.filter((s) => s.oppType),
          oppTypeColumn
        );
        this.setState({
          ...prevState,
          [oppTypeColumn]: oppTypes,
        });
      }
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.PipelineProgressionFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.PipelineProgressionFilters.filter(
            (s) => s.type === "Type"
          ),
          "Type"
        );
      }

      console.log(
        getDefaultACV(
          this.props.PipelineProgressionFilters.filter((s) => s.type === "acv")
        ),
        "ACV"
      );

      this.setState({
        ...prevState,
        selectCol: col,
        acv:
          getDefaultACV(
            this.props.PipelineProgressionFilters.filter(
              (s) => s.type === "acv"
            )
          ) !== ""
            ? d3.format(",")(
                String(
                  getDefaultACV(
                    this.props.PipelineProgressionFilters.filter(
                      (s) => s.type === "acv"
                    )
                  )
                )
              )
            : getDefaultACV(
                this.props.PipelineProgressionFilters.filter(
                  (s) => s.type === "acv"
                )
              ),
        Created_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.PipelineProgressionFilters,
          "Created_Fiscal_Quarter"
        ),
        Qualified_Date_Initial_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.PipelineProgressionFilters,
          "Qualified_Date_Initial_Fiscal_Quarter"
        ),
        Open_Date_Initial_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.PipelineProgressionFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        milestone_stage: stageSequence,
        filtersForDrilldownPcfq: getDefaultSelectedFiscalQuarters(
          this.props.PipelineProgressionFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      });
    }
  }

  onAcvChange = (e, value) => {
    console.log(value);
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (e, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeTab = (e) => {
    this.handleGo();
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  /**
   *
   * @param {string[]} stage
   * @param {string[]} pcfq
   */
  handleChangeLocation = (stages, pcfqArr, qifq, fqtype, pcfcq) => {
    const milestoneInfo = this.props.milestoneInfo;
    const selectedMilestoneStage = this.props.PipelineProgressionFilters.filter(
      (it) =>
        it.type === "milestone_stage" &&
        it.sequence === this.state.milestone_stage
    )[0].milestone_stage;
    let filters = [
      { name: "Stage", value: stages, string: false },
      { name: pcfcq, value: pcfqArr, string: false },
      {
        name: fqtype.endsWith("_Month")
          ? milestoneInfo[selectedMilestoneStage].month
          : milestoneInfo[selectedMilestoneStage].quarter,
        value: [qifq],
        string: false,
      },
    ];
    console.log(
      "drilldown_qualify_filters",
      milestoneInfo,
      selectedMilestoneStage,
      qifq,
      {
        name: this.state.activeTimeFilter.endsWith("_Month")
          ? milestoneInfo[selectedMilestoneStage].month
          : milestoneInfo[selectedMilestoneStage].quarter,
        value: [qifq],
        string: false,
      }
    );
    let currentFilters = this.props.currentFilters;
    console.log(
      currentFilters,
      this.state.activeTimeFilter,
      milestoneInfo,
      selectedMilestoneStage,
      "Pipeline_Progression_Filters"
    );
    currentFilters = currentFilters.filter(
      (it) =>
        ![
          "Projected_Close_Fiscal_Quarter",
          "Qualified_Date_Initial_Fiscal_Quarter",
          "Projected_Close_Year_Month",
          "Qualified_Initial_Year_Month",
        ].includes(it.name)
    );
    filters = [...filters, ...currentFilters];
    console.log(filters, "Pipeline_Progression_Filters");
    this.props.getPipelineProgressionDrilldown(
      filters,
      this.state.activeTimeFilter
    );
    const filtersForDrilldown = [
      {
        title: String(pcfcq).endsWith("_Month")
          ? "Projected Close Month"
          : deSnake("Projected_Close_Fiscal_Quarter"),
        value: pcfqArr,
      },
      {
        title: "Stage",
        value: stages,
      },
      {
        title: fqtype.endsWith("_Month")
          ? deSnake(
              removeYearFromString(milestoneInfo[selectedMilestoneStage].month)
            )
          : deSnake(
              removeDateFromString(
                milestoneInfo[selectedMilestoneStage].quarter
              )
            ),
        value: [qifq],
      },
    ];
    console.log(filtersForDrilldown, "filtersForDrilldown");
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
      filtersForDrilldown,
    });
  };
  handleChange = (e) => {
    console.log("e_target", e);
    console.log("e.target.name", e.target.name);
    console.log("e.target.value", e.target.value);

    const { name, value } = e.target;

    let newValue;
    let activeTimeFilter = "Projected_Close_Fiscal_Quarter";
    if (String(name).endsWith("_Month")) {
      activeTimeFilter = "Projected_Close_Year_Month";
    }
    console.log(activeTimeFilter, "activeTimeFilter");
    if (name === "rep") {
      newValue = [value];
    } else if (
      ["Open_Date_Initial_Fiscal_Quarter"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentOpenDateInitialYearMonth,
        "recentOpenDateInitialYearMonth"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Open_Initial_Year_Month: [this.props.recentOpenDateInitialYearMonth],
        activeTimeFilter: "Projected_Close_Year_Month",
      });
      return;
    } else if (
      ["Open_Initial_Year_Month"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentOpenDateInitialFiscalQuarter,
        "Open_Initial_Year_Month"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Open_Date_Initial_Fiscal_Quarter: [
          this.props.recentOpenDateInitialFiscalQuarter,
        ],
        activeTimeFilter,
      });
      return;
    } else if (
      ["Qualified_Date_Initial_Fiscal_Quarter"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentQualifiedYearMonth,
        "recentQualifiedYearMonth"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Qualified_Initial_Year_Month: [this.props.recentQualifiedYearMonth],
        activeTimeFilter: "Projected_Close_Year_Month",
      });
      return;
    } else if (
      ["Qualified_Initial_Year_Month"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentQualifiedInitialFiscalQuarter,
        "Open_Initial_Year_Month"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Qualified_Date_Initial_Fiscal_Quarter: [
          this.props.recentQualifiedInitialFiscalQuarter,
        ],
        activeTimeFilter,
      });
      return;
    } else if (
      ["Created_Fiscal_Quarter"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentCreatedYearMonth,
        "recentOpenDateInitialYearMonth"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Created_Year_Month: [this.props.recentCreatedYearMonth],
        activeTimeFilter: "Projected_Close_Year_Month",
      });
      return;
    } else if (
      ["Created_Year_Month"].includes(e.target.name) &&
      Array.isArray(value) &&
      value.includes("All")
    ) {
      // if it is quarter and it has none
      // then we have to set the first year month to the respective timeline filter.
      console.log(
        this.props.recentCreatedFiscalQuarter,
        "Open_Initial_Year_Month"
      );
      this.setState({
        ...this.state,
        [name]: ["All"],
        Created_Fiscal_Quarter: [this.props.recentCreatedFiscalQuarter],
        activeTimeFilter,
      });
      return;
    } else if (value.length === 0) {
      newValue = ["All"];
    } else if (
      this.state[name].length === 1 &&
      this.state[name].includes("All")
    ) {
      const filteredValues = value.filter((v) => v !== "All");
      newValue = filteredValues.length > 0 ? filteredValues : ["All"];
    } else if (value.includes("All")) {
      newValue = ["All"];
    } else {
      newValue = value;
    }

    this.setState({
      ...this.state,
      [name]: newValue,
      activeTimeFilter,
    });
  };

  processValue = (name, value) => {
    let processedValue;
    let limit4QuartersError = false;
    if (name === "rep") {
      processedValue = [value];
    } else if (
      [
        "Created_Fiscal_Quarter",
        "Open_Date_Initial_Fiscal_Quarter",
        "Qualified_Date_Initial_Fiscal_Quarter",
      ].includes(name)
    ) {
      // then limit the user selection by 4
      // if the user select 5th value, then ihe first selected in the state should be removed
      // Limit the selection to 4 items
      if (value.length > 4) {
        // Remove the first selected value if the user selects a fifth one
        processedValue = value;
        limit4QuartersError = true;
      } else {
        processedValue = value;
        limit4QuartersError = false;
      }
    } else if (value.length === 0) {
      processedValue = ["All"];
    } else if (
      this.state[name].length === 1 &&
      this.state[name].includes("All")
    ) {
      const filteredValue = value.filter((v) => v !== "All");
      processedValue = filteredValue.length > 0 ? filteredValue : ["All"];
    } else if (value.includes("All")) {
      processedValue = ["All"];
    } else {
      processedValue = value;
    }

    if (name.endsWith("_Quarter")) {
      processedValue = this.sortQuartersDescending(processedValue);
    }

    return { processedValue, limit4QuartersError };
  };

  sortQuartersDescending = (quarters) => {
    return quarters.sort((a, b) => {
      const [aYear, aQuarter] = a.split("-");
      const [bYear, bQuarter] = b.split("-");
      if (aYear === bYear) {
        return bQuarter.localeCompare(aQuarter);
      }
      return bYear - aYear;
    });
  };

  // handleChangeTimeFrame = e => {

  // }

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      activeTimeFilter: "Projected_Close_Fiscal_Quarter",
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
      secondLevelLocation: "",
    });
    this.props.setTable("");
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          Product_Line: "Product_Line",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : this.props.company === "C0008"
      ? {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Product_Line",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
          Record_Type_Name__c: "Record_Type_Name__c",
        };

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  orderRepsByLastName = () => {
    let temp = this.props.PipelineProgressionFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.PipelineProgressionFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    console.log(this.props.lkpFilters);
    let filters1 = this.filters;
    if (this.props.company === "C0001") {
      filters1.push("deal_category__c");
    } else if (this.props.company === "C0008") {
      filters1.push("cross_sell1__c");
      filters1.push("Type");
      console.log("api_call_filters", filters1);
    } else if (
      ["C0010", "C0016", "C0009", "C0013"].includes(this.props.company)
    ) {
      filters1.push("Type");
    } else if (this.props.company === "C0003") {
      filters1.push("Product_Line");
    }

    filters1.push("projected_close_fiscal_quarter");
    filters1 = [...new Set(filters1)];
    let col = "Open_Date_Initial_Fiscal_Quarter";
    // console.log(filters1)
    if (
      this.props.PipelineProgressionFilters.filter(
        (i) => i.type === "milestone_stage"
      ).length > 1
    ) {
      if (
        this.props.PipelineProgressionFilters.filter(
          (i) =>
            i.type === "milestone_stage" &&
            this.state.milestone_stage === i.sequence
        )[0].col === "qualified"
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Open_Date_Initial_Fiscal_Quarter",
              "Open_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Calendar_Month",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }

      if (
        this.props.PipelineProgressionFilters.filter(
          (i) =>
            i.type === "milestone_stage" &&
            this.state.milestone_stage === i.sequence
        )[0].col === "open"
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Qualified_Date_Initial_Fiscal_Quarter",
              "Qualified_Date_Initial_Calendar_Month",
              "Qualified_Date_Initial_Calendar_Year",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Open_Date_Initial_Fiscal_Quarter";
      }

      if (
        this.props.PipelineProgressionFilters.filter(
          (i) =>
            i.type === "milestone_stage" &&
            this.state.milestone_stage === i.sequence
        )[0].col === "created"
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Qualified_Date_Initial_Fiscal_Quarter",
              "Qualified_Date_Initial_Calendar_Month",
              "Qualified_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Fiscal_Quarter",
              "Open_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Calendar_Month",
            ].includes(item)
        );
        col = "Created_Fiscal_Quarter";
      }
    } else {
      filters1 = filters1.filter(
        (item) =>
          ![
            "Open_Date_Initial_Fiscal_Quarter",
            "Open_Date_Initial_Calendar_Year",
            "Open_Date_Initial_Calendar_Month",
            "Created_Fiscal_Quarter",
            "Created_Month",
            "Created_Year",
          ].includes(item)
      );
      col = "Qualified_Date_Initial_Fiscal_Quarter";
    }
    console.log(
      "filters1",
      this.state.milestone_stage,
      this.props.PipelineProgressionFilters.filter(
        (i) => i.type === "milestone_stage"
      ).map((i) => i.sequence)
    );
    // console.log(this.state.milestone_stage, filters1)
    // console.log(this.props.PipelineProgressionFilters.filter(i => i.type === 'Open_Date_Initial_Fiscal_Quarter' && i['Open_Date_Initial_Fiscal_Quarter'] === this.state['Open_Date_Initial_Fiscal_Quarter'][0])[0], this.state)
    let filters = filters1
      .map((f) => {
        console.log(f, "filters1");

        let value;
        if (f === "rep") {
          if (this.state[f].includes("All")) {
            value = "All";
          } else {
            value = this.getSelectedRepIDs();
          }
        } else {
          value = this.state[f];
        }

        let string = true;
        const filterItem = this.props.PipelineProgressionFilters.find(
          (i) => i.type === f && i[f] === this.state[f][0]
        );
        if (filterItem) {
          string = filterItem.string === "Y";
        }

        return {
          name: f,
          value: value,
          string: string,
        };
      })
      .filter((f) =>
        !Array.isArray(f.value) ? f.value !== "All" : !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.PipelineProgressionFilters.map((i) => {
      if (i.milestone_stage !== undefined) {
        if (i.sequence === this.state.milestone_stage)
          stage_names = [i.milestone_stage];
      }
    });
    console.log(filters, "filters1");
    filters = [
      ...filters,
      {
        name: "milestone_stage",
        value: stage_names,
        string: true,
      },
    ];
    if (this.state.group_by === "group_by_quarter") {
      filters = filters.filter((f) => f.name !== "group_by");
    }
    let ismonth = false;
    if (this.state.group_by === "group_by_month") {
      col = "month";
      ismonth = true;
    }
    const openDate = {
      name: "Open_Date",
      value: this.state.from,
      string: false,
    };
    filters = [...filters, openDate];
    console.log(filters, "api_call_filters");
    if (this.props.company === "C0008") {
      if (!this.state[this.variableCols.Acct_Vertical].includes("All")) {
        console.log(
          this.state[this.variableCols.Acct_Vertical],
          "api_call_filters"
        );
        filters.push({
          name: this.variableCols.Acct_Vertical,
          value: this.state[this.variableCols.Acct_Vertical],
          string: true,
        });
      }
    }
    filters = filters.filter((it) => {
      if (it.name === "Open_Date") {
        return it;
      }
      if (Array.isArray(it.value) && it.value.length > 0) {
        return it;
      }
      return it;
    });

    if (
      this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    } else if (this.state.acv === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (
      this.state.acvlt !== null &&
      this.state.acvlt !== 0 &&
      this.state.acvlt !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    } else if (this.state.acvlt === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldownPcfq: this.passFiltersForDrilldown(filters),
      selectCol: col,
      isMonth: ismonth,
    });
    // filters.push(

    // );

    this.props.getQualifiedPipelineCreatedAnalysis(
      filters,
      this.state.activeTimeFilter
    );
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.projected_close_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        this.state.projected_close_fiscal_quarter,
      ];
    }

    if (!this.state.Type.includes("All")) {
      filtersArray = [...filtersArray, this.state.Type];
    }

    if (this.state.projected_close_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    console.log(this.state.acv);
    let milestoneFilters, MileStoneComponent;
    if (
      this.props.PipelineProgressionFilters !== "" &&
      this.state.milestone_stage !== ""
    ) {
      milestoneFilters = {
        Created_Fiscal_Quarter: (
          <Grid
            container
            item
            style={{ padding: 5, paddingTop: 20 }}
            xs={6}
            sm={2}
          >
            <Grid
              item
              spacing={2}
              xs={12}
              container
              component={Paper}
              style={{}}
            >
              {/* Created Fiscal Quarter Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={
                    this.state.Created_Year[0] !== "All" ||
                    this.state.Created_Month[0] !== "All" ||
                    this.state.Created_Year_Month[0] !== "All"
                  }
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state[
                        this.variableCols.Created_Fiscal_Quarter
                      ].includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-created-fiscal-quarter-label"
                  >
                    Created Fiscal Qtr.:
                  </InputLabel>
                  <Select
                    value={this.state[this.variableCols.Created_Fiscal_Quarter]}
                    onChange={this.handleChange}
                    name={this.variableCols.Created_Fiscal_Quarter}
                    data-test="Created_Fiscal_Quarter"
                    multiple
                  >
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type === this.variableCols.Created_Fiscal_Quarter ? (
                        <MenuItem
                          key={y[this.variableCols.Created_Fiscal_Quarter]}
                          value={y[this.variableCols.Created_Fiscal_Quarter]}
                        >
                          {y[this.variableCols.Created_Fiscal_Quarter]}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {/* Created Year Month Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={this.state.Created_Fiscal_Quarter[0] !== "All"}
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state.Created_Year_Month.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-created-year-month-label"
                  >
                    Created Month:
                  </InputLabel>
                  <Select
                    value={this.state.Created_Year_Month}
                    onChange={this.handleChange}
                    name={"Created_Year_Month"}
                    data-test="Created_Year_Month"
                    multiple
                  >
                    {" "}
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type === "Created_Year_Month" ? (
                        <MenuItem
                          key={y["Created_Year_Month"]}
                          value={y["Created_Year_Month"]}
                        >
                          {y["Created_Year_Month"]}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ),
        Open_Date_Initial_Fiscal_Quarter: (
          <Grid
            container
            item
            style={{ padding: 5, paddingTop: 20 }}
            xs={6}
            sm={2}
          >
            <Grid
              item
              spacing={2}
              xs={12}
              container
              component={Paper}
              style={{}}
            >
              {/* Open Initial Fiscal Quarter Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={
                    this.state.Open_Date_Initial_Calendar_Year[0] !== "All" ||
                    this.state.Open_Date_Initial_Calendar_Month[0] !== "All" ||
                    this.state.Open_Initial_Year_Month[0] !== "All"
                  }
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state[
                        this.variableCols.Open_Date_Initial_Fiscal_Quarter
                      ].includes("All")
                        ? "#4472c4"
                        : "#000",
                      whiteSpace: "nowrap",
                    }}
                    id="select-open-fiscal-quarter-label"
                  >
                    Open Initial Fiscal Qtr.:
                  </InputLabel>
                  <Select
                    value={
                      this.state[
                        this.variableCols.Open_Date_Initial_Fiscal_Quarter
                      ]
                    }
                    onChange={this.handleChange}
                    name={this.variableCols.Open_Date_Initial_Fiscal_Quarter}
                    data-test="Open_Date_Initial_Fiscal_Quarter"
                    multiple
                  >
                    {" "}
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type ===
                      this.variableCols.Open_Date_Initial_Fiscal_Quarter ? (
                        <MenuItem
                          key={
                            y[
                              this.variableCols.Open_Date_Initial_Fiscal_Quarter
                            ]
                          }
                          value={
                            y[
                              this.variableCols.Open_Date_Initial_Fiscal_Quarter
                            ]
                          }
                        >
                          {
                            y[
                              this.variableCols.Open_Date_Initial_Fiscal_Quarter
                            ]
                          }
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {/* Open Initial Year Month Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={
                    this.state.Open_Date_Initial_Fiscal_Quarter[0] !== "All"
                  }
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state.Open_Initial_Year_Month.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-open-year-month-label"
                  >
                    Open Initial Month:
                  </InputLabel>
                  <Select
                    value={this.state.Open_Initial_Year_Month}
                    onChange={this.handleChange}
                    name={"Open_Initial_Year_Month"}
                    data-test="Open_Initial_Year_Month"
                    multiple
                  >
                    {" "}
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type === "Open_Initial_Year_Month" ? (
                        <MenuItem
                          key={y["Open_Initial_Year_Month"]}
                          value={y["Open_Initial_Year_Month"]}
                        >
                          {y["Open_Initial_Year_Month"]}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ),
        Qualified_Date_Initial_Fiscal_Quarter: (
          <Grid
            container
            item
            style={{ padding: 5, paddingTop: 20 }}
            xs={6}
            sm={2}
          >
            <Grid
              item
              spacing={2}
              xs={12}
              container
              component={Paper}
              style={{}}
            >
              {/* Qualified Initial Fiscal Quarter Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={
                    this.state.Qualified_Date_Initial_Calendar_Month[0] !==
                      "All" ||
                    this.state.Qualified_Date_Initial_Calendar_Year[0] !==
                      "All" ||
                    this.state.Qualified_Initial_Year_Month[0] !== "All"
                  }
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state[
                        this.variableCols.Qualified_Date_Initial_Fiscal_Quarter
                      ].includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-qualified-fiscal-quarter-label"
                  >
                    Qualified Initial Fiscal Qtr.:
                  </InputLabel>
                  <Select
                    value={
                      this.state[
                        this.variableCols.Qualified_Date_Initial_Fiscal_Quarter
                      ]
                    }
                    onChange={this.handleChange}
                    name={
                      this.variableCols.Qualified_Date_Initial_Fiscal_Quarter
                    }
                    data-test="Qualified_Date_Initial_Fiscal_Quarter"
                    multiple
                  >
                    {" "}
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type ===
                      this.variableCols
                        .Qualified_Date_Initial_Fiscal_Quarter ? (
                        <MenuItem
                          key={
                            y[
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter
                            ]
                          }
                          value={
                            y[
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter
                            ]
                          }
                        >
                          {
                            y[
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter
                            ]
                          }
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {/* Qualified Initial Year Month Filter */}
              <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={
                    this.state.Qualified_Date_Initial_Fiscal_Quarter[0] !==
                    "All"
                  }
                >
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state.Qualified_Initial_Year_Month.includes(
                        "All"
                      )
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-qualified-year-month-label"
                  >
                    Qualified Initial Month:
                  </InputLabel>
                  <Select
                    value={this.state.Qualified_Initial_Year_Month}
                    onChange={this.handleChange}
                    name={"Qualified_Initial_Year_Month"}
                    data-test="Qualified_Initial_Year_Month"
                    multiple
                  >
                    <MenuItem key={"All"} value={"All"}>
                      None
                    </MenuItem>
                    {this.props.PipelineProgressionFilters.map((y) =>
                      y.type === "Qualified_Initial_Year_Month" ? (
                        <MenuItem
                          key={y["Qualified_Initial_Year_Month"]}
                          value={y["Qualified_Initial_Year_Month"]}
                        >
                          {y["Qualified_Initial_Year_Month"]}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ),
      };

      console.log("this.state.milestone_stage", this.state.milestone_stage);
      MileStoneComponent =
        milestoneFilters[
          this.props.milestoneInfo[
            this.props.PipelineProgressionFilters.filter(
              (it) =>
                it.type === "milestone_stage" &&
                it.sequence === this.state.milestone_stage
            )[0].milestone_stage
          ].quarter
        ];
      console.log(
        "milesont",
        this.props.PipelineProgressionFilters.filter(
          (it) => it.type === "milestone_stage"
        ),
        MileStoneComponent
      );
    }
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid className={classes.grid} item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Created Mix Analysis</Typography>
                    <Typography variant='body1'>
                    Analyze the pipeline created across multiple dimensions to spot trends and patterns.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Qualified Pipeline Cohort Analysis",
          "Analyze cohorts of qualified opportunities and examine where they currently are"
        )}
        {this.props.PipelineProgressionFilters !== "" &&
        this.state.milestone_stage !== "" ? (
          <>
            <Grid
              container
              item
              style={{ padding: 5, paddingTop: 20 }}
              xs={12}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Milestone Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.milestone_stage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Milestone Stage:"
                      name="milestone_stage"
                      data-test="milestine_stage"
                    >
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (it) => it.type === "milestone_stage"
                        ).map((y) => {
                          console.log("y_milesstone_stage");
                          return (
                            y.milestone_stage && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.milestone_stage}
                              </MenuItem>
                            )
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {console.log(MileStoneComponent, "MileStoneComponent")}
            {this.props.PipelineProgressionFilters !== "" &&
              this.props.milestoneInfo[
                this.props.PipelineProgressionFilters.filter(
                  (it) =>
                    it.type === "milestone_stage" &&
                    it.sequence === this.state.milestone_stage
                )[0].milestone_stage
              ].quarter === "Created_Fiscal_Quarter" && (
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={6}
                  sm={3}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Created_Year[0] !== "All" ||
                          this.state.Created_Month[0] !== "All" ||
                          this.state.Created_Year_Month[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Created_Fiscal_Quarter
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Created Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={
                            this.state[this.variableCols.Created_Fiscal_Quarter]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols.Created_Fiscal_Quarter}
                          data-test="Created_Fiscal_Quarter"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.map((y) =>
                              y.type ===
                              this.variableCols.Created_Fiscal_Quarter ? (
                                <MenuItem
                                  key={
                                    y[this.variableCols.Created_Fiscal_Quarter]
                                  }
                                  value={
                                    y[this.variableCols.Created_Fiscal_Quarter]
                                  }
                                >
                                  {y[this.variableCols.Created_Fiscal_Quarter]}
                                </MenuItem>
                              ) : null
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Created_Fiscal_Quarter[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Created_Year_Month.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-created-year-month-label"
                        >
                          Created Month:
                        </InputLabel>
                        <Select
                          value={this.state.Created_Year_Month}
                          onChange={this.handleChange}
                          name={"Created_Year_Month"}
                          data-test="Created_Year_Month"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters.map((y) =>
                            y.type === "Created_Year_Month" ? (
                              <MenuItem
                                key={y["Created_Year_Month"]}
                                value={y["Created_Year_Month"]}
                              >
                                {y["Created_Year_Month"]}
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {this.props.PipelineProgressionFilters !== "" &&
              this.props.milestoneInfo[
                this.props.PipelineProgressionFilters.filter(
                  (it) =>
                    it.type === "milestone_stage" &&
                    it.sequence === this.state.milestone_stage
                )[0].milestone_stage
              ].quarter === "Open_Date_Initial_Fiscal_Quarter" && (
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={6}
                  sm={3}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Open_Date_Initial_Calendar_Year[0] !==
                            "All" ||
                          this.state.Open_Date_Initial_Calendar_Month[0] !==
                            "All" ||
                          this.state.Open_Initial_Year_Month[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Open_Date_Initial_Fiscal_Quarter
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                            whiteSpace: "nowrap",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Open Initial Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={
                            this.state[
                              this.variableCols.Open_Date_Initial_Fiscal_Quarter
                            ]
                          }
                          onChange={this.handleChange}
                          name={
                            this.variableCols.Open_Date_Initial_Fiscal_Quarter
                          }
                          data-test="Open_Date_Initial_Fiscal_Quarter"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.map((y) =>
                              y.type ===
                              this.variableCols
                                .Open_Date_Initial_Fiscal_Quarter ? (
                                <MenuItem
                                  key={
                                    y[
                                      this.variableCols
                                        .Open_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                  value={
                                    y[
                                      this.variableCols
                                        .Open_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                >
                                  {
                                    y[
                                      this.variableCols
                                        .Open_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                </MenuItem>
                              ) : null
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Open_Date_Initial_Fiscal_Quarter[0] !==
                          "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Open_Initial_Year_Month.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-Open-year-month-label"
                        >
                          Open Initial Month:
                        </InputLabel>
                        <Select
                          value={this.state.Open_Initial_Year_Month}
                          onChange={this.handleChange}
                          name={"Open_Initial_Year_Month"}
                          data-test="Open_Initial_Year_Month"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters.map((y) =>
                            y.type === "Open_Initial_Year_Month" ? (
                              <MenuItem
                                key={y["Open_Initial_Year_Month"]}
                                value={y["Open_Initial_Year_Month"]}
                              >
                                {y["Open_Initial_Year_Month"]}
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {this.props.PipelineProgressionFilters !== "" &&
              this.props.milestoneInfo[
                this.props.PipelineProgressionFilters.filter(
                  (it) =>
                    it.type === "milestone_stage" &&
                    it.sequence === this.state.milestone_stage
                )[0].milestone_stage
              ].quarter === "Qualified_Date_Initial_Fiscal_Quarter" && (
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={6}
                  sm={3}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    // style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state
                            .Qualified_Date_Initial_Calendar_Month[0] !==
                            "All" ||
                          this.state.Qualified_Date_Initial_Calendar_Year[0] !==
                            "All" ||
                          this.state.Qualified_Initial_Year_Month[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Qualified Initial Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={
                            this.state[
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter
                            ]
                          }
                          onChange={this.handleChange}
                          name={
                            this.variableCols
                              .Qualified_Date_Initial_Fiscal_Quarter
                          }
                          data-test="Qualified_Date_Initial_Fiscal_Quarter"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.map((y) =>
                              y.type ===
                              this.variableCols
                                .Qualified_Date_Initial_Fiscal_Quarter ? (
                                <MenuItem
                                  key={
                                    y[
                                      this.variableCols
                                        .Qualified_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                  value={
                                    y[
                                      this.variableCols
                                        .Qualified_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                >
                                  {
                                    y[
                                      this.variableCols
                                        .Qualified_Date_Initial_Fiscal_Quarter
                                    ]
                                  }
                                </MenuItem>
                              ) : null
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state
                            .Qualified_Date_Initial_Fiscal_Quarter[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              !this.state.Qualified_Initial_Year_Month.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-qualified-year-month-label"
                        >
                          Qualified Initial Month:
                        </InputLabel>
                        <Select
                          value={this.state.Qualified_Initial_Year_Month}
                          onChange={this.handleChange}
                          name={"Qualified_Initial_Year_Month"}
                          data-test="Qualified_Initial_Year_Month"
                          multiple
                        >
                          <MenuItem key={"All"} value={"All"}>
                            None
                          </MenuItem>
                          {this.props.PipelineProgressionFilters.map((y) =>
                            y.type === "Qualified_Initial_Year_Month" ? (
                              <MenuItem
                                key={y["Qualified_Initial_Year_Month"]}
                                value={y["Qualified_Initial_Year_Month"]}
                              >
                                {y["Qualified_Initial_Year_Month"]}
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={3}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.Projected_Close_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                        whiteSpace: "nowrap",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      data-test="projected-close-fiscal-quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state[this.variableCols.projectedQuarter][0] !==
                      "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Projected_Close_Year_Month.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Month:
                    </InputLabel>
                    <Select
                      value={this.state.Projected_Close_Year_Month}
                      onChange={this.handleChange}
                      name={"Projected_Close_Year_Month"}
                      data-test="projected-close-fiscal-quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.map((y) =>
                          y.type === "Projected_Close_Year_Month" ? (
                            <MenuItem
                              key={y["Projected_Close_Year_Month"]}
                              value={y["Projected_Close_Year_Month"]}
                            >
                              {y["Projected_Close_Year_Month"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={3}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.PipelineProgressionFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) => y.type === "team" && y.Display_Name !== ""
                        )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : this.props.company === "C0008"
                        ? "Product Line"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) =>
                            y.type === this.variableCols.Acct_Vertical &&
                            y[this.variableCols.Acct_Vertical] !== ""
                        )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {
                                y[
                                  this.props.company === "C0008"
                                    ? "label"
                                    : this.variableCols.Acct_Vertical
                                ]
                              }
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) =>
                            y.type === this.variableCols.CustomerType &&
                            y[this.variableCols.CustomerType] !== ""
                        )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) =>
                            y.type === this.variableCols.Industry &&
                            y[this.variableCols.Industry] !== ""
                        )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) =>
                            y.type === this.variableCols.CustomerSegment &&
                            y[this.variableCols.CustomerSegment] !== ""
                        ).map((y) => (
                          <MenuItem
                            key={y[this.variableCols.CustomerSegment]}
                            value={y[this.variableCols.CustomerSegment]}
                          >
                            {y[this.variableCols.CustomerSegment]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      ACV Range:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {console.log(
                        this.props.PipelineProgressionFilters.filter(
                          (y) => y.type === this.variableCols.ACV_Range
                        ).map((y) => y[this.variableCols.ACV_Range])
                      )}
                      {this.props.PipelineProgressionFilters !== "" &&
                        this.props.PipelineProgressionFilters.filter(
                          (y) =>
                            y.type === this.variableCols.ACV_Range &&
                            y[this.variableCols.ACV_Range] !== ""
                        ).map((y) => (
                          <MenuItem
                            key={y[this.variableCols.ACV_Range]}
                            value={y[this.variableCols.ACV_Range]}
                          >
                            {y[this.variableCols.ACV_Range]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company !== "C0003" &&
              this.props.company !== "C0013" && (
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Lead_Source
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Lead Source:
                        </InputLabel>
                        <Select
                          data-test="Lead_Source"
                          value={this.state[this.variableCols.Lead_Source]}
                          onChange={this.handleChange}
                          name={this.variableCols.Lead_Source}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.filter(
                              (y) =>
                                y.type === this.variableCols.Lead_Source &&
                                y[this.variableCols.Lead_Source] !== ""
                            ).map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Lead_Source]}
                                value={y[this.variableCols.Lead_Source]}
                              >
                                {y[this.variableCols.Lead_Source]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {/*Deal Category filter */}
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 5, paddingTop: 20 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.PipelineProgressionFilters !== "" &&
                          this.props.PipelineProgressionFilters.filter(
                            (y) =>
                              y.type === this.variableCols.deal_category__c &&
                              y[this.variableCols.deal_category__c] !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y[this.variableCols.deal_category__c]}
                              value={y[this.variableCols.deal_category__c]}
                            >
                              {y[this.variableCols.deal_category__c]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <>
                {" "}
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="cross_sell1__c"
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            ).map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            ).map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/*Opp. Type filter */}
            {this.props.company !== "C0008" &&
              this.props.company !== "C0003" &&
              this.props.company !== "C0001" &&
              this.props.company !== "C0002" &&
              this.props.company !== "C0015" &&
              this.props.company !== "C0013" &&
              this.props.PipelineProgressionFilters.filter(
                (it) => it.oppType
              ) && (
                <Grid
                  container
                  item
                  style={{ padding: 5, paddingTop: 20 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols[this.oppTypeName]
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opp. Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={
                            this.state[this.variableCols[this.oppTypeName]]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols[this.oppTypeName]}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.PipelineProgressionFilters !== "" &&
                            this.props.PipelineProgressionFilters.filter(
                              (y) =>
                                y.type ===
                                  this.variableCols[this.oppTypeName] &&
                                y[this.variableCols[this.oppTypeName]] !== ""
                            ).map((y) => (
                              <MenuItem
                                key={y[this.variableCols[this.oppTypeName]]}
                                value={y[this.variableCols[this.oppTypeName]]}
                              >
                                {y[this.variableCols[this.oppTypeName]]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {/* C0010 Opp Record Type */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Record_Type_Name__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={
                          this.state[this.variableCols.Record_Type_Name__c]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Record_Type_Name__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.PipelineProgressionFilters !== "" &&
                          this.props.PipelineProgressionFilters.filter(
                            (y) =>
                              y.type ===
                                this.variableCols.Record_Type_Name__c &&
                              y[this.variableCols.Record_Type_Name__c] !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Record_Type_Name__c]}
                              value={y[this.variableCols.Record_Type_Name__c]}
                            >
                              {y[this.variableCols.Record_Type_Name__c]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ padding: 5, paddingTop: 20 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Product_Line
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.Product_Line]}
                        onChange={this.handleChange}
                        name={this.variableCols.Product_Line}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.PipelineProgressionFilters !== "" &&
                          this.props.PipelineProgressionFilters.filter(
                            (y) =>
                              y.type === this.variableCols.Product_Line &&
                              y[this.variableCols.Product_Line] !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Product_Line]}
                              value={y[this.variableCols.Product_Line]}
                            >
                              {y["label"]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container item style={{ padding: 5, paddingTop: 20 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.PipelineProgressionFilters !== "" &&
                      this.props.PipelineProgressionFilters.filter(
                        (f) => f.type === "acv"
                      ).map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    // defaultValue={0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ACV >= :"
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustomv2,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.PipelineProgressionFilters !== "" &&
                      this.props.PipelineProgressionFilters.filter(
                        (f) => f.type === "acv"
                      ).map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ACV < :"
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== "" &&
                              this.state.acvlt !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {this.props.PipelineProgression !== "" ? (
          <Grid
            container
            justifyContent="center"
            style={{ paddingTop: "2rem" }}
          >
            {this.props.PipelineProgression.sort((a, b) => {
              const [yearA, monthA] = a[a.fqType].split("-").map(Number);
              const [yearB, monthB] = b[b.fqType].split("-").map(Number);
              // Compare years first, then months
              if (yearA !== yearB) {
                return yearB - yearA;
              }
              return monthB - monthA;
            }).map((it, idx) => (
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                style={{ paddingBottom: "2rem" }}
              >
                <Card>
                  <PipelineProgressionTableContainer
                    handleChangeLocation={this.handleChangeLocation}
                    data={it}
                    id={idx}
                    toggleValue={this.state.toggleValue}
                    handleChangeToggle={this.handleChangeToggle}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={400}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunities in Pipeline Created Mix"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Opportunities in Pipeline Created Mix"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

function NumberFormatCustomv2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        (values.value > 0 || [null, "", undefined].includes(values.value)) &&
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    pipelineCreatedMixFilters,
    pipelineCreatedMixAnalysisData,
    user,
    lkpFilters,
    PipelineProgressionDrilldown,
    currentFilters,
    ExpectedAcvData,
    PipelineProgression,
    PipelineProgressionFilters,
    milestoneInfo,
  } = state.app;
  let recentOpenDateInitialYearMonth = null;
  let recentOpenDateInitialFiscalQuarter = null;
  let recentQualifiedYearMonth = null;
  let recentQualifiedInitialFiscalQuarter = null;
  let recentCreatedYearMonth = null;
  let recentCreatedFiscalQuarter = null;
  if (PipelineProgressionFilters !== "") {
    recentOpenDateInitialYearMonth = PipelineProgressionFilters.filter(
      (it) => it.type === "Open_Initial_Year_Month"
    )[0].Open_Initial_Year_Month;
    recentOpenDateInitialFiscalQuarter = PipelineProgressionFilters.filter(
      (it) => it.type === "Open_Date_Initial_Fiscal_Quarter"
    )[0].Open_Date_Initial_Fiscal_Quarter;
    recentQualifiedYearMonth = PipelineProgressionFilters.filter(
      (it) => it.type === "Qualified_Initial_Year_Month"
    )[0].Qualified_Initial_Year_Month;
    recentQualifiedInitialFiscalQuarter = PipelineProgressionFilters.filter(
      (it) => it.type === "Qualified_Date_Initial_Fiscal_Quarter"
    )[0].Qualified_Date_Initial_Fiscal_Quarter;
    recentCreatedYearMonth = PipelineProgressionFilters.filter(
      (it) => it.type === "Created_Year_Month"
    )[0].Created_Year_Month;
    recentCreatedFiscalQuarter = PipelineProgressionFilters.filter(
      (it) => it.type === "Created_Fiscal_Quarter"
    )[0].Created_Fiscal_Quarter;
  }

  return {
    pipelineMixAnalysisData: pipelineCreatedMixAnalysisData,
    company: user.company,
    drilldownOnGraphData: PipelineProgressionDrilldown,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
    PipelineProgression,
    PipelineProgressionFilters,
    milestoneInfo,
    recentOpenDateInitialYearMonth,
    recentOpenDateInitialFiscalQuarter,
    recentQualifiedYearMonth,
    recentQualifiedInitialFiscalQuarter,
    recentCreatedYearMonth,
    recentCreatedFiscalQuarter,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getPipelineMixDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPipelineCreatedMixAnalysisDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPbiSummaryDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },

  getPipelineProgressionFilters: (company) => {
    dispatch({ type: "get_pipeline_created_mix_filters_request" });
    appService.getPipelineCreatedMixAnalysisFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_pipeline_created_mix_filters_success", json });
        dispatch({ type: "get_pipeline_created_mix_analysis_data_request" });
        //default stage filter
        const stageLength = json.message.filter(
          (f) => f.type === "milestone_stage"
        ).length;
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "milestone_stage")
        );
        console.log("milestoneStages", stageSequence);
        const defaultStages = json.message
          .filter((item) => item.sequence === stageSequence)
          .map((i) => i.milestone_stage)
          .filter(Boolean);
        let filters = [
          {
            name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "projected_close_fiscal_quarter"
            ),
            string: true,
          },
          {
            name: "Open_Date_Initial_Fiscal_Quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "projected_close_fiscal_quarter"
            ),
            string: true,
          },
          { name: "milestone_stage", string: true, value: defaultStages },
        ];
        if (stageLength === 1) {
          filters = [
            {
              name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
              value: getDefaultSelectedFiscalQuarters(
                json.message,
                "projected_close_fiscal_quarter"
              ),
              string: true,
            },
            { name: "milestone_stage", string: true, value: defaultStages },
          ];
        }
        const defaultACV = getDefaultACV(
          json.message.filter((f) => f.type === "acv")
        );
        if (defaultACV !== "") {
          filters = [
            ...filters,
            { name: "acv", value: defaultACV, string: false },
          ];
        }
        if (json.message.filter((s) => s.oppType).length > 0) {
          let oppTypeColumn = json.message.filter((i) => i.oppType)[0].oppType;
          filters =
            getDefaultSalesType(
              json.message.filter((i) => i.oppType),
              oppTypeColumn
            )[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: oppTypeColumn,
                    value: getDefaultSalesType(
                      json.message.filter((i) => i.oppType),
                      oppTypeColumn
                    ),
                  },
                ];
        }
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({
          type: "get_pipeline_created_mix_analysis_data_request",
        });

        const fromDate = json.dateFilters.prior6Month;
        const parts = fromDate.split("-");
        const formattedDate = `${parts[1]}-${parts[2]}-${parts[0]}`;
        console.log(formattedDate);

        filters = [
          ...filters,
          {
            name: "Open_Date",
            value: formattedDate,
            string: false,
          },
        ];
        dispatch({ type: "current_selected_filters", filters: filters });
        appService
          .getPipelineCreatedMixAnalysisData(
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({
                type: "get_pipeline_created_mix_analysis_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pipeline_created_mix_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_pipeline_created_mix_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_created_mix_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_pipeline_created_mix_filters_failure", error });
      }
    );
  },
  getPipelineMixAnalysisData: (company, filters = []) => {
    dispatch({ type: "get_pipeline_created_mix_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPipelineCreatedMixAnalysisData(filters).then(
      (json) => {
        dispatch({
          type: "get_pipeline_created_mix_analysis_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_created_mix_analysis_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_pipeline_created_mix_analysis_data_failure",
            error,
          });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getQualifiedPipelineCreatedAnalysisFilters: (company) => {
    dispatch({ type: "get_pipeline_progression_filters_request" });
    appService.getPipelineProgressionAnalysisFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        const milestoneInfo = json.message.milestoneInfo;
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        json.milestoneInfo = milestoneInfo;
        dispatch({ type: "get_pipeline_progression_filters_success", json });
        const stageLength = json.message.filter(
          (f) => f.type === "milestone_stage"
        ).length;
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "milestone_stage")
        );
        const defaultStages = json.message
          .filter((item) => item.sequence === stageSequence)
          .map((i) => i.milestone_stage)
          .filter(Boolean);

        let filters = [
          {
            name: milestoneInfo[defaultStages[0]].quarter,
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              milestoneInfo[defaultStages[0]].quarter
            ),
            string: true,
          },
          { name: "milestone_stage", string: true, value: defaultStages },
        ];
        if (stageLength === 1) {
          filters = [
            {
              name: milestoneInfo[defaultStages[0]].quarter,
              value: getDefaultSelectedFiscalQuarters(
                json.message,
                milestoneInfo[defaultStages[0]].quarter
              ),
              string: true,
            },
            { name: "milestone_stage", string: true, value: defaultStages },
          ];
        }
        const defaultACV = getDefaultACV(
          json.message.filter((f) => f.type === "acv")
        );
        if (defaultACV !== "") {
          filters = [
            ...filters,
            { name: "acv", value: defaultACV, string: false },
          ];
        }
        if (json.message.filter((s) => s.oppType).length > 0) {
          let oppTypeColumn = json.message.filter((i) => i.oppType)[0].oppType;
          filters =
            getDefaultSalesType(
              json.message.filter((i) => i.oppType),
              oppTypeColumn
            )[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: oppTypeColumn,
                    value: getDefaultSalesType(
                      json.message.filter((i) => i.oppType),
                      oppTypeColumn
                    ),
                  },
                ];
        }
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getPipelineProgressionAnalysis(
            filters,
            "Projected_Close_Fiscal_Quarter"
          )
          .then(
            (json) => {
              dispatch({ type: "get_pipeline_progression_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pipeline_progression_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_pipeline_progression_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_progression_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_pipeline_progression_filters_failure", error });
      }
    );
  },
  getQualifiedPipelineCreatedAnalysis: (filters, key) => {
    dispatch({ type: "get_pipeline_progression_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPipelineProgressionAnalysis(filters, key).then(
      (json) => {
        dispatch({ type: "get_pipeline_progression_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_progression_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_pipeline_progression_data_failure", error });
      }
    );
  },
  getPipelineProgressionDrilldown: (filters, key) => {
    dispatch({ type: "get_pipeline_progression_drilldown_request" });
    appService.getPipelineProgressionAnalysisDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_pipeline_progression_drilldown_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_progression_drilldown_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_pipeline_progression_drilldown_failure",
            error,
          });
      }
    );
  },
});
const PipelineProgression = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PipelineCreatedMixAnalysis));
export default PipelineProgression;
