import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import CalendarIcon from '@material-ui/icons/DateRange'
import { InputAdornment, IconButton } from '@material-ui/core';

export default function MaterialUIPickers(props) {

  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()))

  React.useEffect(() => {
    const date = props.current.split('-')
    setSelectedDate(new Date(date[2], date[0] - 1, date[1]))
  }, [props.current]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (isValidDate(date)) props.setDate(date)
  };

  const isValidDate = d => d instanceof Date && !isNaN(d)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        fullWidth
        //disableToolbar
        disabled={props.disabled}
        //variant="inline"
        format="MMM-dd-yyyy"
        margin="none"
        id="date-picker-inline"
        label={props.label}
        value={selectedDate}
        onChange={handleDateChange}
        minDate={props.min}
        maxDate={props.max}
        error={props.errorText !== ''}
        helperText={props.errorText}
        shouldDisableDate={props.shouldDisableDate}
        InputLabelProps={{ style: { color: props.disabled ? '' : '#4472c4' } }}
        InputProps={{

          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={props.disabled} style={{ padding: 0 }}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
}