import React, { Suspense } from "react";
import { Grid } from "@material-ui/core";
import WinACVAnalysis from "./WinACVAnalysis/WinACVAnalysis";
import WinACVMixGaapAnalysis from "./WinACVMixGAAPAnalysis/WinACVMixGaapAnalysis";
import PBIPipelineView from "./PBIPipelineView/PBIPipelineView";
import WonACVMekkoChart from "./PBISummaryView/WonACVMekkoChart";
import PipelineMekkoChart from "./PBIPipelineView/PipelineMekkoChart";
import PBISummaryView from "./PBISummaryView/PBISummaryView";
import NewACVMixAnalysis from "./WinACVMixAnalysis/WinACVMixAnalysis";
import AverageDealSize from "./WinACVMixAnalysis/AverageDealSize";
import PipelineMixAnalysis from "./PipelineMixAnalysis/PipelineMixAnalysis";
import PipelineCreatedAnalysis from "./PipelineMixAnalysis/PipelineCreatedAnalysis";
import PipelineCreatedMixAnalysis from "./PipelineMixAnalysis/PipelineCreatedMixAnalysis";
import BuildingPipeline from "./PipelineMixAnalysis/BuildingPipeline";
import CompetitorPerformance from "./CompetitorPerformance/CompetititorPerformance";
import { PipelineTrends } from "./PipelineTrends/PipelineTrends";
import { PipelineTrendsRolling } from "./PipelineTrends/PipelineTrendsRolling";
import ShowPlotly from "./OppotunityPyramid/ShowPlotly";
import StageProgression from "./StageProgressionAnalysis/StageProgressionAnalysis";
import QuotaMaintenance from "./Quota Maintenance/QuotaMaintenance";
// import QuotaMaintenanceV2 from "./Quota MaintenanceV2/QuotaMaintenanceV2";
import { PcdMovedAnalysis } from "./PCD Moved Analysis/PcdMovedAnalysis";
import { Funnel } from "./Funnel/Funnel";
import { DemandGen } from "./DemandGen/DemandGen";
import { KeyMetrics } from "./Key Metrics/KeyMetrics";
import { MomentumAnalysis } from "./Momentum Analysis/MomentumAnalysis";
import { Funnel as CreatedFunnel } from "./CreatedFunnel/Funnel";
import FunnelNew from "./NewFunnel/Funnel";
import { Waterfall } from "./Waterfall/Waterfall";
import { Waterfall as NewWaterfall } from "./NewWaterFall/Waterfall";
import { PerformanceByIndustry } from "./PerformanceByIndustry/PerformanceByIndustry";
import { CurrentState } from "./CurrentState/CurrentState";
import { ClosedLost } from "./ClosedLost/ClosedLost";
import { WinRate } from "./WinRate/WinRate";
import { StrengthOfSales } from "./StrengthOfSales/StrengthOfSales";
import SankeyPoc from "./SankeyPoc/SankeyPoc";
import { withStyles } from "@material-ui/core/styles";
import { ConversionEfficiencyBySource } from "../RepPerformance/TimeSeriesPrediction/ConversionEfficiencyBySource";
import { TimeSeriesPredictionv2 } from "../RepPerformance/TimeSeriesPrediction/TimeSeriesPredictionv2";
import { TimeSeriesPredictionByMonth } from "../RepPerformance/TimeSeriesPrediction/TimeSeriesPredictionByMonth";
import PSCA from "../RepPerformance/PSCA/PSCA";
import CallsCompleted from "./CallsCompleted/CallsCompleted";
import POcsSummary from "./POCsSummary/POCsSummary";
import ProductsByAccount from "./ProductsByAccount/ProductsByAccount";
import AccountExpansion from "./AccountExpansion/AccountExpansion";
import PIpelineProgression from "./PipelineProgression/PipelineProgression";
import CurrentStateOfQualifiedPipeline from "./CurrentStateOfQualifiedPipeline/CurrentStateOfQualifiedPipeline";

const ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/ProjectedPipeline")
);
const TopDealsSummary = React.lazy(() =>
  import("./ProjectedPipeline/TopDealsSummary")
);
const C0003_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0003_ProjectedPipeline")
);
const C0002_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0002_ProjectedPipeline")
);
const C0004_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0004_ProjectedPipelineAnalysis")
);
const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});
const EnhancedInsightRoutes = (props) => {
  const { classes } = props;

  return (
    <>
      {props.location ===
        `${props.Win_Rate_Prefix} Cohort Analysis – Closed Deals` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <Funnel />
          </Grid>
        </Grid>
      )}
      {props.location === "Demand Gen Effectiveness" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <DemandGen />
          </Grid>
        </Grid>
      )}
      {props.location === "Momentum Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <MomentumAnalysis />
          </Grid>
        </Grid>
      )}
      {props.location === "Pipeline Progression Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <CurrentStateOfQualifiedPipeline />
          </Grid>
        </Grid>
      )}
      {props.location === "Projected Close Date Moves" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <PcdMovedAnalysis />
          </Grid>
        </Grid>
      )}

      {props.location === "Pipeline Waterfall Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <NewWaterfall />
          </Grid>
        </Grid>
      )}
      {props.location === "Account Value Expansion" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <AccountExpansion />
          </Grid>
        </Grid>
      )}
      {props.location === "Quota Maintenance" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <QuotaMaintenance />
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Quota Maintenance V2" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <QuotaMaintenanceV2 />
          </Grid>
        </Grid>
      )} */}

      {props.location ===
        `${props.Win_Rate_Prefix} Cohort Analysis – Created Deals` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <FunnelNew />
          </Grid>
        </Grid>
      )}
      {/* {props.location === 'Win Rate Cohort Analysis – Created Deals' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <CreatedFunnel />
              </Grid>
            </Grid>
          } */}
      {/* {props.location === 'Pipeline Waterfall Analysis' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <Waterfall />
              </Grid>
            </Grid>
          } */}
      {props.location === "Performance by Industry" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PerformanceByIndustry />}
          </Grid>
        </Grid>
      )}
      {props.location ===
        "Current state of opportunities that came to Qualifying" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<CurrentState />}
          </Grid>
        </Grid>
      )}
      {props.location === "Lost Opportunities Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<ClosedLost />}
          </Grid>
        </Grid>
      )}

      {props.location === `${props.Win_Rate_Prefix} Trends` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<WinRate />}
          </Grid>
        </Grid>
      )}
      {props.location === `Won ${props.Value_Label} Mix Analysis` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<WinACVAnalysis />}
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Won MRR Mix Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {<WinACVAnalysis />}
          </Grid>
        </Grid>
      )} */}
      {props.location === "Open Pipeline Mix Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PipelineMixAnalysis />}
          </Grid>
        </Grid>
      )}
      {props.location === "Qualified Pipeline Created Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PipelineCreatedAnalysis />}
          </Grid>
        </Grid>
      )}
      {props.location === "Pipeline Created Mix Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PipelineCreatedMixAnalysis />}
          </Grid>
        </Grid>
      )}
      {props.location === "Building Pipeline to Win" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {<BuildingPipeline />}
          </Grid>
        </Grid>
      )}
      {props.location === "Pipeline by Vertical and Apps Cluster" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PBIPipelineView />}
          </Grid>
        </Grid>
      )}
      {props.location ===
        `Won ${props.Value_Label} by Vertical and Apps Cluster` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PBISummaryView />}
          </Grid>
        </Grid>
      )}
      {props.location === `Won ${props.Value_Label} Mekko Chart` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<WonACVMekkoChart />}
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Won MRR Mekko Chart" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {<WonACVMekkoChart />}
          </Grid>
        </Grid>
      )} */}
      {props.location === "Qualified Pipeline Cohort Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {<PIpelineProgression />}
          </Grid>
        </Grid>
      )}
      {props.location === "Pipeline Mekko Chart" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<PipelineMekkoChart />}
          </Grid>
        </Grid>
      )}
      {props.location === "Average Deal Size and Cycle Time" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {props.company === "C0003" ? (
              <NewACVMixAnalysis />
            ) : (
              <AverageDealSize />
            )}
          </Grid>
        </Grid>
      )}

      {props.location === "Strength of Sale" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<StrengthOfSales />}
          </Grid>
        </Grid>
      )}
      {props.location === "Opportunities with near-term close" &&
        props.company === "C0001" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <ProjectedPipeline />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Opportunities with near-term close" &&
        props.company === "C0002" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <C0002_ProjectedPipeline />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Opportunities with near-term close" &&
        props.company === "C0003" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <C0003_ProjectedPipeline />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Opportunities with near-term close" &&
        props.company === "C0004" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <C0004_ProjectedPipeline />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Opportunities with near-term close" &&
        !["C0001", "C0002", "C0003", "C0004"].includes(props.company) && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <ProjectedPipeline />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Top Deals Summary" &&
        !["C0001", "C0002", "C0003", "C0004"].includes(props.company) && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <TopDealsSummary />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Performance against Key Competitors" &&
        props.company !== "C0003" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <CompetitorPerformance />
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Pipeline Trends" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {
              <Suspense fallback={<div>Loading...</div>}>
                <PipelineTrends />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Cycle Time Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <ShowPlotly />
          </Grid>
        </Grid>
      )}
      {props.location === "Pipeline Trends Rolling" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {
              <Suspense fallback={<div>Loading...</div>}>
                <PipelineTrendsRolling />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Stage Progression Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <StageProgression />
          </Grid>
        </Grid>
      )}
      {props.location === `Won ${props.Value_Label} Mix GAAP Analysis` && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <WinACVMixGaapAnalysis />
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Won MRR Mix GAAP Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <WinACVMixGaapAnalysis />
          </Grid>
        </Grid>
      )} */}
      {props.location === "Key Metrics" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {<KeyMetrics />}
          </Grid>
        </Grid>
      )}
      {props.location === "Sankey POC" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <SankeyPoc />
          </Grid>
        </Grid>
      )}

      {props.location ===
        "Conversion Efficiency by Source + Growth Modeling" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <ConversionEfficiencyBySource />
          </Grid>
        </Grid>
      )}

{props.location ===
        "Time Series projection of Closed Won" && (
          <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <TimeSeriesPredictionv2 />
          </Grid>
        </Grid>
      )}


{props.location === "Time Series projection of Closed Won (Monthly)" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <TimeSeriesPredictionByMonth />
          </Grid>
        </Grid>
      )}

      {props.location === "Calls Completed" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <CallsCompleted />
          </Grid>
        </Grid>
      )}

      {props.location === "Pipeline Coverage and Shape Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <PSCA />
          </Grid>
        </Grid>
      )}

      {props.location === "POCs Summary" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <POcsSummary />
          </Grid>
        </Grid>
      )}
      
    {props.location === "White Space Analysis + Products by Account" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <ProductsByAccount />
          </Grid>
        </Grid>
      )}
    </>
  );
};


export default withStyles(styles)(EnhancedInsightRoutes);