import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  percentageFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const SALSQLTable = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let total;

  var uniqueQuarters = props.demandGenData
    .map((s) => s.quarter)
    .filter(getUniqueValues)
    .filter((i) => i !== "Total")
    .sort();
  var totalQuarter = props.demandGenData.filter((s) => s.quarter === "Total");
  const salsqlsCreated = ["SALs created", "SQLs created"];
  if (totalQuarter.length >= 1 && uniqueQuarters.length >= 1) {
    uniqueQuarters = [...uniqueQuarters, "Total"];
  }

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerSALS");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainerSALS"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.type}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{ fontWeight: 600, paddingLeft: 5 }}
              className={classes.fontBold}
            >
              <TableCell
                align="center"
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                rowSpan={2}
                style={{ minWidth: 150 }}
              >
                Open Fiscal Quarter
              </TableCell>
              {uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    colSpan={3}
                    key={i}
                    align="center"
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            width: "13rem",
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            width: "13rem",
                          }
                    }
                    className={classes.repHeader}
                  >
                    {i}
                  </TableCell>
                );
              })}

              {uniqueQuarters.length === 0 && (
                <TableCell
                  rowSpan={8}
                  className={classes.repHeader}
                  style={{ width: 200, textAlign: "center" }}
                >
                  No data available
                </TableCell>
              )}
            </TableRow>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              {uniqueQuarters.map((f) => {
                return (
                  <>
                    <TableCell
                      align="center"
                      style={{ minWidth: 70 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      Target
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 90 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      Actual
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 70 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      % of Target
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
            {salsqlsCreated.map((partner, i) => {
              return (
                <StyledTableRow>
                  <TableCell
                    align="left"
                    // className={classes.cellStyle}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.cellStyle}`
                        : classes.cellStyle
                    }
                    colSpan={1}
                    style={
                      isSticky && i % 2 === 0 ? { background: "#f5f5f5" } : {}
                    }
                  >
                    {partner}
                  </TableCell>
                  {uniqueQuarters.map((q) => {
                    const rowData = props.demandGenData.filter(
                      (i) => i.quarter === q
                    );
                    const actual =
                      rowData.length === 0
                        ? 0
                        : rowData[0][
                            partner === "SALs created" ? "salCount" : "sqlCount"
                          ];
                    return (
                      <>
                        <TableCell
                          align="right"
                          className={
                            q === "Total"
                              ? classes.cellStyle
                              : classes.cellStyle
                          }
                          colSpan={1}
                        ></TableCell>
                        <TableCell
                          align="right"
                          className={
                            q === "Total"
                              ? classes.cellHeader
                              : classes.cellNormal
                          }
                          colSpan={1}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? uniqueQuarters : [q],
                                partner: partner,
                              },
                              "DemandGen"
                            )
                          }
                        >
                          {![null, undefined, ""].includes(actual)
                            ? countOrACVFormatter(actual, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={
                            q === "Total"
                              ? classes.cellStyle
                              : classes.cellStyle
                          }
                          colSpan={1}
                        ></TableCell>
                      </>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SALSQLTable;
