import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import * as d3 from "d3";
import "./RepPerformanceTable.scss";
import {
  countOrACVFormatter,
  getUniqueValues,
  stageFormattter,
} from "../../../util/customFunctions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import GetApp from "@material-ui/icons/GetApp";
// import HoverableIcon from "../../../components/Buttons/HoverableIcon ";
// import html2canvas from "html2canvas";
// import PptxGenJS from "pptxgenjs";
// import * as FileSaver from "file-saver";
// import ExcelJS from "exceljs";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";
import { replaceAll, replaceWinRateLabel } from "../../../components/xlsx-export/LabelCange";
// import RepExcelExportButton from "../../../components/xlsx-export/RepTable-xlsx";

const styles = (theme) => ({
  tableHeader: {
    whiteSpace: "normal",
    textAlign: "center",
  },
  sticky: {
    position: "sticky",
    left: -1,
    zIndex: "5",
    // color: "inherit",
    background: "white",
    // backgroundColor: "inherit",
    // boxShadow: "5px 2px 5px grey",
    // borderRight: "2px solid black",
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableHeader: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "white",
    background: "#4472C4",
  },
  mainTable: {
    margin: "1rem",
    width: "98%",
  },
});
class RepPerformanceTable extends Component {
  tableRef = React.createRef();
  tableContainerRef = React.createRef();

  state = {
    isSticky: false,
    isScrollingHorizontally: false,
    oppsordering: "dsc",
    pointer: "_ACV",
    isOverflowing: false,
  };

  handleScroll = (event) => {
    console.log("handleScroll", event);
    const target = event.target;
    if (target.scrollLeft > 0) {
      this.setState({ isSticky: true });
      this.setState({
        isOverflowing: true,
      });
    } else {
      this.setState({ isSticky: false });
      this.setState({
        isOverflowing: false,
      });
    }
    console.log("isScrollingHorizontally", this.state.isSticky);
  };

  componentDidMount() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkOverflow);
  }

  checkOverflow = () => {
    const tableContainer = document.getElementById("myTableContainer");
    if (tableContainer) {
      this.setState({
        isOverflowing: tableContainer.scrollWidth > tableContainer.offsetWidth,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { isSticky } = this.state;

    let totalindex = 0;

    console.log("sticky_state", isSticky);
    console.log("isOverflowing", this.state.isOverflowing);

    const wonLabel = this.props.labels.filter((item) => item.type === "won")[0]
      .label;

    const firstLabel = this.props.labels.filter(
      (item) => item.type === "first"
    )[0].label;
    const secondLabel = this.props.labels.filter(
      (item) => item.type === "second"
    )[0].label;

    console.log("wonLabel", wonLabel);
    console.log("firstLabel", firstLabel);
    console.log("secondLabel", secondLabel);

    let sortedRepPerformance;

    if (this.state.pointer !== "Name") {
      sortedRepPerformance = this.props.repPerformanceData
        .sort((a, b) => b[`${this.state.pointer}`] - a[`${this.state.pointer}`])
        .filter((item) => item.Name !== "total");

      if (this.state.oppsordering === "asc") {
        sortedRepPerformance = this.props.repPerformanceData
          .sort(
            (a, b) => b[`${this.state.pointer}`] - a[`${this.state.pointer}`]
          )
          .filter((item) => item.Name !== "total")
          .reverse();
      }
    } else {
      sortedRepPerformance = this.props.repPerformanceData
        .sort(function (a, b) {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        })
        .filter((item) => item.Name !== "total");

      if (this.state.oppsordering === "asc") {
        sortedRepPerformance = this.props.repPerformanceData
          .sort(function (a, b) {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          })
          .filter((item) => item.Name !== "total")
          .reverse();
      }
    }

    const totalVal = this.props.repPerformanceData.filter(
      (item) => item.Name === "total"
    )[0];

    console.log("sortedRepPerformance", sortedRepPerformance);
    var tableTitle = Object.keys(sortedRepPerformance[0]).map((item) =>
      item.split("_").join(" ")
    );

    ///////////////Sort by ACV /////////////
    const handleSort = (key) => {
      console.log("clickedkey", key, this.state.pointer);
      if (key !== this.state.pointer) {
        this.setState({
          pointer: key,
          oppsordering: "dsc",
        });
        console.log("Diff_pointer");
      } else {
        this.setState({
          pointer: key,
          // oppsordering: "dsc",
        });
        console.log("same_pointer");
      }
    };

    var y = 0;
    var cumsum = sortedRepPerformance.map((d) => (y += d[`${wonLabel}_ACV_%`]));

    return (
      <div
        style={{ maxWidth: "100%", justifyContent: "center", padding: "2rem" }}
      >
        <Grid xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={this.tableRef} />
          </div>
        </Grid>
        <TableContainer
          // className={classes.mainTable}
          // id="myTableContainer"
          style={{
            width: "auto",
            borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            margin: "1rem",
            maxHeight: "86vh",
            borderTop: "0.5px solid lightgrey",
            // width: "98%",
          }}
          onScroll={this.handleScroll}
        >
          {/* {this.state.isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                // marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={this.tableRef} />
            </div>
          ) : null} */}
          <Table
            ref={this.tableRef}
            stickyHeader
            size="medium"
            aria-label="html table"
            style={{ tableLayout: "auto" }}
            data-test={"Rep-performance-table"}
            id={"Rep-performance-table"}
          >
            <TableHead
              style={{
                position: "sticky",
                top: -1,
                zIndex: 555,
                outline: "lightgrey solid 0.9px",
                outlineOffset: "-1.5px",
              }}
            >
              <TableRow
              // style={{
              //   // fontWeight: 600,
              //   // background: "#4472c4",
              //   // color: "white",
              //   position: "sticky",
              //   // outline: "lightgrey solid 0.9px",
              //   // outlineOffset: "-1.0px",
              // }}
              >
                <TableCell
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.tableHeader}`
                      : classes.tableHeader
                  }
                  align="center"
                  style={{ background: "#4472C4" }}
                >
                  Rank
                </TableCell>
                <TableCell
                  // className={classes.tableHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.tableHeader}`
                      : classes.tableHeader
                  }
                  style={{ minWidth: "250px", background: "#4472C4" }}
                  align="center"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort("Name");
                  }}
                >
                  Rep
                  {this.state.pointer === "Name" ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 90 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`${wonLabel}_ACV`);
                  }}
                >
                  {replaceAll(
                    tableTitle[1],
                    "acv",
                    `${this.props.Value_Label}`
                  )}
                  {this.state.pointer === `${wonLabel}_ACV` ||
                  this.state.pointer === "_ACV" ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 95 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`${wonLabel}_ACV_%`);
                  }}
                >
                  {/* {tableTitle[2]} */}
                  {replaceAll(
                    tableTitle[2],
                    "acv",
                    `${this.props.Value_Label}`
                  )}
                  {this.state.pointer === `${wonLabel}_ACV_%` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell className={classes.tableHeader} align="center">
                  Cumulative % of Total
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 95 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort("Pursued_ACV");
                  }}
                >
                  {/* {tableTitle[3]} */}
                  {replaceAll(
                    tableTitle[3],
                    "acv",
                    `${this.props.Value_Label}`
                  )}
                  {this.state.pointer === "Pursued_ACV" ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>

                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`Overall_Win_Rate_From_${firstLabel}`);
                  }}
                >
                  {/* {tableTitle[4]} */}
                  {replaceWinRateLabel(
                    tableTitle[4],
                    "Win Rate",
                    `${this.props.Win_Rate_Prefix}`
                  )}

                  {this.state.pointer ===
                  `Overall_Win_Rate_From_${firstLabel}` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(
                      `${firstLabel}_To_${secondLabel}_Conversion_Rate`
                    );
                  }}
                >
                  {tableTitle[5]}
                  {this.state.pointer ===
                  `${firstLabel}_To_${secondLabel}_Conversion_Rate` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`${secondLabel}_To_${wonLabel}_Conversion_Rate`);
                  }}
                >
                  {stageFormattter(secondLabel)} to {stageFormattter(wonLabel)}{" "}
                  Conversion Rate
                  {this.state.pointer ===
                  `${secondLabel}_To_${wonLabel}_Conversion_Rate` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 95 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`${wonLabel}_No_Of_Opps`);
                  }}
                >
                  {`${wonLabel.replace(/_/g, " ")} # of Opps`}
                  {this.state.pointer === `${wonLabel}_No_Of_Opps` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  style={{ minWidth: 95 }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort(`Pursued_Opps`);
                  }}
                >
                  Pursued # of Opps
                  {this.state.pointer === `Pursued_Opps` ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort("Quota");
                  }}
                >
                  Quota
                  {this.state.pointer === "Quota" ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      oppsordering:
                        this.state.oppsordering === "asc" ? "dsc" : "asc",
                    });
                    handleSort("attainment");
                  }}
                >
                  Attain. %
                  {this.state.pointer === "attainment" ? (
                    <TableSortLabel
                      active={true}
                      direction={this.state.oppsordering}
                    ></TableSortLabel>
                  ) : null}
                </TableCell>

                {/* <TableCell className={classes.tableHeader} align='center' colSpan={2}>Moved to next stage</TableCell>
                        <TableCell className={classes.tableHeader} align='center' colSpan={2}>Win Rate</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedRepPerformance.map((x, i) => {
                totalindex = totalindex + 1;
                return (
                  <StyledTableRow key={i} className="repPerformance">
                    <TableCell
                      // className={classes.table}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.table}`
                          : classes.table
                      }
                      align="right"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      // className={classes.table}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.table}`
                          : classes.table
                      }
                      style={
                        isSticky && i % 2 === 1 ? { background: "#f5f5f5" } : {}
                      }
                      align="left"
                    >
                      {x.Name}
                    </TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                      onClick={() =>
                        this.props.handleChangeLocation(
                          "drilldownOnGraph",
                          wonLabel.split("_").join(" "),
                          false,
                          "cameTo",
                          x.Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >{`$${d3.format(",")(
                      Math.round(x[`${wonLabel}_ACV`]) //Closed Won ACV
                    )}`}</TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                    >{`${Math.round(
                      x[`${wonLabel}_ACV_%`] * 100 //Closed Won ACV %
                    )}%`}</TableCell>
                    <TableCell className={classes.table} align="right">
                      {`${Math.round(x[`cumulativePercOfWinTotal`] * 100)}% `}
                    </TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                      onClick={() =>
                        this.props.handleChangeLocation(
                          "drilldownOnGraph",
                          firstLabel.split("_").join(" "),
                          false,
                          "cameTo",
                          x.Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >{`$${d3.format(",")(
                      Math.round(x.Pursued_ACV)
                    )}`}</TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                    >{`${Math.round(
                      x[`Overall_Win_Rate_From_${firstLabel}`] * 100
                    )}%`}</TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                    >{`${Math.round(
                      x[`${firstLabel}_To_${secondLabel}_Conversion_Rate`] * 100
                    )}%`}</TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                    >{`${Math.round(
                      x[`${secondLabel}_To_${wonLabel}_Conversion_Rate`] * 100
                    )}%`}</TableCell>

                    <TableCell
                      className={classes.table}
                      align="right"
                      onClick={() =>
                        this.props.handleChangeLocation(
                          "drilldownOnGraph",
                          wonLabel.split("_").join(" "),
                          false,
                          "cameTo",
                          x.Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {x[`${wonLabel}_No_Of_Opps`]}
                    </TableCell>
                    <TableCell
                      className={classes.table}
                      align="right"
                      onClick={() =>
                        this.props.handleChangeLocation(
                          "drilldownOnGraph",
                          firstLabel.split("_").join(" "),
                          false,
                          "cameTo",
                          x.Name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {x[`Pursued_Opps`]}
                    </TableCell>
                    <TableCell className={classes.table} align="right">
                      {countOrACVFormatter(x[`Quota`], true)}
                    </TableCell>
                    <TableCell className={classes.table} align="right">
                      {Math.round(x[`attainment`] * 100)}%
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              <StyledTableRow className="repPerformance">
                <TableCell className={classes.table} align="right"></TableCell>
                <TableCell
                  // className={classes.table}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.table}`
                      : classes.table
                  }
                  style={
                    isSticky && totalindex % 2 === 1
                      ? { background: "#f5f5f5" }
                      : {}
                  }
                  align="left"
                >
                  Total
                </TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                  onClick={() =>
                    this.props.handleChangeLocation(
                      "drilldownOnGraph",
                      wonLabel.split("_").join(" "),
                      false,
                      "cameTo",
                      sortedRepPerformance
                        .map((x) => x.Name)
                        .filter(getUniqueValues)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >{`$${d3.format(",")(
                  Math.round(totalVal[`${wonLabel}_ACV`])
                )}`}</TableCell>
                <TableCell className={classes.table} align="right">
                  100%
                </TableCell>
                <TableCell className={classes.table} align="right"></TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                  onClick={() =>
                    this.props.handleChangeLocation(
                      "drilldownOnGraph",
                      firstLabel.split("_").join(" "),
                      false,
                      "cameTo",
                      sortedRepPerformance
                        .map((x) => x.Name)
                        .filter(getUniqueValues)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >{`$${d3.format(",")(
                  Math.round(totalVal.Pursued_ACV)
                )}`}</TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                >{`${Math.round(
                  totalVal[`Overall_Win_Rate_From_${firstLabel}`] * 100
                )}%`}</TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                >{`${Math.round(
                  totalVal[`${firstLabel}_To_${secondLabel}_Conversion_Rate`] *
                    100
                )}%`}</TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                >{`${Math.round(
                  totalVal[`${secondLabel}_To_${wonLabel}_Conversion_Rate`] *
                    100
                )}%`}</TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                  onClick={() =>
                    this.props.handleChangeLocation(
                      "drilldownOnGraph",
                      wonLabel,
                      false,
                      "cameTo",
                      sortedRepPerformance
                        .map((x) => x.Name)
                        .filter(getUniqueValues)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {totalVal[`${wonLabel}_No_Of_Opps`]}
                </TableCell>
                <TableCell
                  className={classes.table}
                  align="right"
                  onClick={() =>
                    this.props.handleChangeLocation(
                      "drilldownOnGraph",
                      firstLabel.split("_").join(" "),
                      false,
                      "cameTo",
                      sortedRepPerformance
                        .map((x) => x.Name)
                        .filter(getUniqueValues)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {countOrACVFormatter(totalVal[`Pursued_Opps`])}
                </TableCell>
                <TableCell className={classes.table} align="right">
                  {countOrACVFormatter(totalVal[`Quota`], true)}
                </TableCell>
                <TableCell className={classes.table} align="right">
                  {Math.round(totalVal[`attainment`] * 100)}%
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);
const styledRepPerformance = withStyles(styles)(RepPerformanceTable);
export { styledRepPerformance as RepPerformanceTable };