import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  root: {},
  sticky: {
    position: "sticky",
    left: -1,
    // color: "inherit",
    background: "white",
    // backgroundColor: "inherit",
    // boxShadow: "5px 2px 5px grey",
    // borderRight: "2px solid black",
  },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    //minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));

export default function PCDMovedByQuartersMovedV2(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const tableData = props.tableData.FQ_MovedDataV2;

  const [isSticky, setIsSticky] = useState(false);

  let total;

  const uniqueQuarterMoved = tableData
    .map((item) => item.qtrsMoved)
    .filter(getUniqueValues)
    .sort((a, b) => a - b);

  const uniqueMoves = uniqueQuarterMoved.filter((i) => i > 0);

  useEffect(() => {
    const tableContainer = document.getElementById(
      "Projected Close Date Moves (Quarters Moved)"
    );

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  const totalGTE1 = tableData.filter(
    (it) => it.qtrsMoved !== "Grand Total" && it.qtrsMoved >= 1
  );
  const totalGTE1Qtrs = totalGTE1.reduce((a, b) => [...a, ...b.quarters], []);
  const totalGTE1oldQ = [];
  const totalGTE1newQ = [];

  totalGTE1Qtrs.map((qtrString) => {
    const [oldQtr, newQtr] = qtrString.split(",");
    totalGTE1oldQ.push(oldQtr);
    totalGTE1newQ.push(newQtr);
  });
  console.log(totalGTE1, "totalGTE1");

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="Projected Close Date Moves (Quarters Moved)"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={"pcd_moved_table_FQ_Moved"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    className={classes.repHeader}
                    colSpan={1}
                    style={{ minWidth: 120, maxWidth: 120 }}
                  >
                    Qtrs. Moved
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      background: "#4471c4",
                      color: "white",
                      minWidth: 100,
                      maxWidth: 100,
                    }}
                    className={classes.repHeader}
                  >
                    # of Opps{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      background: "#5b9bd5",
                      color: "white",
                      minWidth: 100,
                      maxWidth: 100,
                    }}
                    className={classes.repHeader}
                  >
                    {/* ACV{" "} */}
                    {`${props.Value_Label}`}
                  </TableCell>
                </TableRow>

                {uniqueQuarterMoved.map((row, i) => {
                  const rowData = tableData.filter(
                    (it) => row === it.qtrsMoved
                  )[0];
                  const oldQ = [];
                  const newQ = [];
                  rowData.quarters.map((qtrString) => {
                    const [oldQtr, newQtr] = qtrString.split(",");
                    oldQ.push(oldQtr);
                    newQ.push(newQtr);
                  });
                  console.log("qtrmoved_rowdata", rowData);
                  return (
                    <StyledTableRow
                      // className={row === "Grand Total" && classes.fontBold}
                      key={i}
                    >
                      <TableCell
                        align="right"
                        className={classes.repText}
                        colSpan={1}
                        style={{
                          textAlign: row !== "Grand Total" ? "right" : "left",
                          paddingRight: "3rem",
                        }}
                      >
                        {rowData.qtrsMoved}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              oldQtr: oldQ,
                              newQtr: newQ,
                              quarters: rowData.quarters,
                            },
                            "actual",
                            true
                          )
                        }
                      >
                        {rowData.opps}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              oldQtr: oldQ,
                              newQtr: newQ,
                              quarters: rowData.quarters,
                            },
                            "actual",
                            true
                          )
                        }
                      >
                        {countOrACVFormatter(rowData.acv, true)}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    className={classes.repText}
                    colSpan={1}
                  >
                    {`Total (>=1 Qtr.)`}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.repNumber}
                    colSpan={1}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        {
                          oldQtr: totalGTE1oldQ,
                          newQtr: totalGTE1newQ,
                          quarters: totalGTE1Qtrs,
                        },
                        "actual",
                        true
                      )
                    }
                  >
                    {countOrACVFormatter(
                      totalGTE1.reduce((a, b) => a + b.opps, 0),
                      false
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.repNumber}
                    colSpan={1}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        {
                          oldQtr: totalGTE1oldQ,
                          newQtr: totalGTE1newQ,
                          quarters: totalGTE1Qtrs,
                        },
                        "actual",
                        true
                      )
                    }
                  >
                    {countOrACVFormatter(
                      totalGTE1.reduce((a, b) => a + b.acv, 0),
                      true
                    )}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
