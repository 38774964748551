import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import {
  getCurrentFiscalQuarter,
  nearestCompletedFiscalQuarter,
} from "../../../util/fiscalQuarterFunctions";
import SummaryTrendsTable from "../AccountHealth/components/SummaryTrendsTable";
import { colorStages } from "../AccountHealth/utils";
import { renewalsRow } from "../RenewalsCompleted/components/utils";
import AccountHealthDrilldown from "../AccountHealth/components/AccountHealthDrilldown";
import addHeaderdescription from "../../../components/HeaderDescription";
const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});
class ChangeOverTime extends Component {
  filters = ["Status", "Region", "Account_Segment", "CS_Head", "GS_Head"];
  constructor(props) {
    super(props);

    this.state = {
      Status: ["Active"],
      Region: ["All"],
      Account_Segment: ["All"],
      CS_Head: ["All"],
      GS_Head: ["All"],
      disabled: ["year", "month", "quarter"],
      accountSegment: [...renewalsRow],
      drilldownFilters: [],
      drillStatus: ["Active"],
    };
  }
  componentDidMount() {
    this.props.getAccountHealthChangeOverTimeFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getCurrentFiscalQuarter();
      this.setState({ ...prevState, Status: ["Active"] });
      const missingSegemnt = this.props.funnelCompareFilters
        .filter(
          (y) => y.type === "Account_Segment" && y["Account_Segment"] !== ""
        )
        .map((item) => item.Account_Segment)
        .filter(function (obj) {
          return renewalsRow.indexOf(obj) == -1;
        });
      this.setState({
        ...this.state,
        accountSegment: [...renewalsRow, ...missingSegemnt],
      });
    }
  }

  passFiltersForDrilldown = () => {
    let formattedFilters = [];
    // if (!this.state.Status.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'Status', value: this.state.Status, string: true }]
    // }
    if (!this.state.Region.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "Region", value: this.state.Region, string: true },
      ];
    }
    if (!this.state.Account_Segment.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        {
          name: "Account_Segment",
          value: this.state.Account_Segment,
          string: true,
        },
      ];
    }
    if (!this.state.CS_Head.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "CS_Head", value: this.state.CS_Head, string: true },
      ];
    }
    if (!this.state.GS_Head.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "GS_Head", value: this.state.GS_Head, string: true },
      ];
    }
    return formattedFilters;
  };

  passFiltersForDrilldown2 = () => {
    let filters = [];
    if (!this.state.Status.includes("All")) {
      filters = [...filters, this.state.Status];
    }

    //   if (this.state.Status.includes('All')) {
    //      filters = [...filters, "All"]
    //  }

    return filters[0];
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, filters, metaData) => {
    // get fitlers from table and charts
    let formattedFilters = [
      {
        name: "Status_Last_week",
        value:
          typeof filters.col === "object"
            ? filters.col === null
              ? [null]
              : filters.col
            : [filters.col],
        string: true,
      },
      {
        name: "Overall_Health",
        value: typeof filters.row === "object" ? filters.row : [filters.row],
        string: true,
      },
    ];

    // get filters from page filters
    if (this.state.drillStatus) {
      formattedFilters = [
        ...formattedFilters,
        { name: "Status", value: this.state.drillStatus, string: true },
      ];
    }

    // if (!this.state.Account_Segment.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'Account_Segment', value: this.state.Account_Segment, string: true }]
    // }
    // if (!this.state.CS_Head.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'CS_Head', value: this.state.CS_Head, string: true }]
    // }
    // if (!this.state.GS_Head.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'GS_Head', value: this.state.GS_Head, string: true }]
    // }

    formattedFilters = [...formattedFilters, ...this.state.drilldownFilters];
    // make api call
    this.props.setTable("ChangeOverTime");
    this.props.getChartDrilldown("changeOverTime", formattedFilters);

    // update the state and open drilldown window
    this.setState({
      ...this.state,
      location: location,

      filtersForDrilldown: [
        ...formattedFilters
          .map((item) => ({
            ...item,
            name:
              item.name === "Overall_Health"
                ? "Current_Health"
                : item.name === "Status_Last_week"
                ? "Health_prior_week"
                : item.name,
          }))
          .map((item) => ({
            ...item,
            value: item.value.map((item) => (item === null ? " " : item)),
            title: item.name.split("_").join(" "),
          })),
      ],
    });
  };
  // handleStatusType = (e, value) => {
  //     this.setState({
  //         ...this.state,
  //         Status: [e.target.value]
  //     })
  // }
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.setState({
      ...this.state,
      drilldownFilters: this.passFiltersForDrilldown(),
      drillStatus: this.passFiltersForDrilldown2(),
    });
    this.props.getAccountHealthChangeOverTimeData(this.props.company, filters);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer} justify="center">
        {/* <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Account Health - Change over time
          </Typography>
          <Typography variant="body1">
            Analyze how the health status of accounts changed over time.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Account Health - Change over time",
          "Analyze how the health status of accounts changed over time."
        )}
        <Grid
          className={classes.gridItem}
          xs={12}
          item
          container
          justify="flex-start"
        >
          <Grid container item xs={6} md={4}>
            <Grid
              justify="flex-start"
              item
              xs={5}
              container
              component={Paper}
              style={{ padding: 10, marginRight: 10 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      color: !this.state.Status.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    Status:
                  </InputLabel>
                  <Select
                    labelId="select-vfiscalQuarter-label"
                    id="select-cfiscalQuarter"
                    value={this.state.Status}
                    onChange={this.handleChange}
                    label="Status"
                    name="Status"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters === "" && (
                      <MenuItem value={nearestCompletedFiscalQuarter()}>
                        {nearestCompletedFiscalQuarter()}
                      </MenuItem>
                    )}
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) => y.type === "Status" && y["Status"] !== ""
                        )
                        .map((y) => (
                          <MenuItem key={y["Status"]} value={y["Status"]}>
                            {y["Status"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              justify="flex-start"
              item
              xs={6}
              container
              component={Paper}
              style={{ padding: 10, marginRight: 10 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      color: !this.state.Account_Segment.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    Account Segment:
                  </InputLabel>
                  <Select
                    labelId="select-vfiscalQuarter-label"
                    id="select-cAccount_Segment"
                    value={this.state.Account_Segment}
                    onChange={this.handleChange}
                    label="Account_Segment"
                    name="Account_Segment"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters === "" && (
                      <MenuItem value={nearestCompletedFiscalQuarter()}>
                        {nearestCompletedFiscalQuarter()}
                      </MenuItem>
                    )}
                    {this.state.accountSegment.map((y) => (
                      <MenuItem key={y} value={y}>
                        {y}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4}>
            <Grid
              justify="flex-start"
              item
              xs={5}
              container
              component={Paper}
              style={{ padding: 10, marginRight: 10 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      color: !this.state.GS_Head.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    GS Head:
                  </InputLabel>
                  <Select
                    labelId="select-vRegion-label"
                    id="select-cGS_Head"
                    value={this.state.GS_Head}
                    onChange={this.handleChange}
                    label="GS_Head"
                    name="GS_Head"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters === "" && (
                      <MenuItem value={nearestCompletedFiscalQuarter()}>
                        {nearestCompletedFiscalQuarter()}
                      </MenuItem>
                    )}
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) => y.type === "GS_Head" && y["GS_Head"] !== ""
                        )
                        .map((y) => (
                          <MenuItem key={y["GS_Head"]} value={y["GS_Head"]}>
                            {y["GS_Head"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              justify="flex-start"
              item
              xs={6}
              container
              component={Paper}
              style={{ padding: 10, marginRight: 10 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      color: !this.state.Account_Segment.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    CS Head:
                  </InputLabel>
                  <Select
                    labelId="select-vfiscalQuarter-label"
                    id="select-cCS_Head"
                    value={this.state.CS_Head}
                    onChange={this.handleChange}
                    label="CS_Head"
                    name="CS_Head"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters === "" && (
                      <MenuItem value={nearestCompletedFiscalQuarter()}>
                        {nearestCompletedFiscalQuarter()}
                      </MenuItem>
                    )}
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) => y.type === "CS_Head" && y["CS_Head"] !== ""
                        )
                        .map((y) => (
                          <MenuItem key={y["CS_Head"]} value={y["CS_Head"]}>
                            {y["CS_Head"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={2}>
            <Grid
              justify="flex-start"
              item
              xs={12}
              container
              component={Paper}
              style={{ padding: 10, marginRight: 10 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      color: !this.state.Status.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    Region:
                  </InputLabel>
                  <Select
                    labelId="select-vRegion-label"
                    id="select-cRegion"
                    value={this.state.Region}
                    onChange={this.handleChange}
                    label="Region"
                    name="Region"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters === "" && (
                      <MenuItem value={nearestCompletedFiscalQuarter()}>
                        {nearestCompletedFiscalQuarter()}
                      </MenuItem>
                    )}
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) => y.type === "Region" && y["Region"] !== ""
                        )
                        .map((y) => (
                          <MenuItem key={y["Region"]} value={y["Region"]}>
                            {y["Region"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        {this.props.accountHealthChangeOverTimeData !== "" ? (
          <Grid
            className={classes.gridItem}
            item
            container
            xs={12}
            component={Card}
          >
            <Grid item style={{ padding: "1rem" }} xs={12}>
              <Typography
                align="center"
                variant="h2"
                style={{ margin: "1rem" }}
              >
                Change from prior week
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "1rem" }} container>
              <SummaryTrendsTable
                data={this.props.accountHealthChangeOverTimeData[0]}
                statusByPriorWeek={true}
                type="SFDC"
                col="Status_Last_week"
                row="Overall_Health"
                handleChangeLocation={this.handleChangeLocation}
                chartName="accountHealthChangeOverTime"
                weekOverWeekTrends={true}
                rowHeading="Health"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.gridItem} item xs={12}>
            <Card>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Account Health - Change over time`}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <AccountHealthDrilldown
                header={`Account Health - Change over time`}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    accountHealthChangeOverTimeFilters,
    accountHealthChangeOverTimeData,
    drilldownOnGraphData,
    user,
  } = state.app;

  return {
    funnelCompareFilters: accountHealthChangeOverTimeFilters,
    accountHealthChangeOverTimeData,
    drilldownOnGraphData,
    company: user.company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChartDrilldown: (key, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getChartDrilldown("C0003.CHID00000017", key, filters).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1];
        tempOpportunities = tempOpportunities.sort((a, b) =>
          a.CARR > b.CARR ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let x = {
            ...item,
            OppNo: index + 1,
          };
          return x;
        });
        // tempOpportunities.sort((a, b) => a.ACV > b.ACV ? -1 : 1)

        // tempOpportunities = tempOpportunities.map((item, index) => {
        //   let createdDateString = (item.Created_Date.split('T')[0])
        //   var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
        //   let temp = {
        //     ...item,
        //     OppNo: index + 1,
        //     age: Math.round(
        //       (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
        //       (1000 * 60 * 60 * 24)
        //     ),
        //   }
        //   return temp
        // })

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            // columns: json.columns
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getAccountHealthChangeOverTimeFilters: (company) => {
    dispatch({ type: "get_AccountHealthChangeOverTime_filters_request" });
    appService.getChartFilters("C0003.CHID00000024").then(
      (json) => {
        dispatch({
          type: "get_AccountHealthChangeOverTime_filters_success",
          json,
        });
        dispatch({ type: "get_AccountHealthChangeOverTime_data_request" });
        appService
          .getChartData("C0003.CHID00000024", [
            { name: "Status", value: ["Active"], string: true },
          ])
          .then(
            (json) => {
              dispatch({
                type: "get_AccountHealthChangeOverTime_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_AccountHealthChangeOverTime_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_AccountHealthChangeOverTime_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealthChangeOverTime_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_AccountHealthChangeOverTime_filters_failure",
            error,
          });
      }
    );
  },
  getAccountHealthChangeOverTimeData: (company, filters = []) => {
    dispatch({ type: "get_AccountHealthChangeOverTime_data_request" });
    appService.getChartData("C0003.CHID00000024", filters).then(
      (json) => {
        dispatch({
          type: "get_AccountHealthChangeOverTime_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealthChangeOverTime_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_AccountHealthChangeOverTime_data_failure",
            error,
          });
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangeOverTime));
