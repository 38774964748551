import React from 'react'
import D3Funnel from './D3Waterfall'
import {
  Card, CardHeader, CardContent, TextField, Divider, Button, Typography, Grid, Radio, RadioGroup, FormControlLabel, FormLabel, Paper
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { appService } from '../../App/app.service';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Fragment } from 'react';
import DatePicker from '../../components/DatePicker'
import * as d3 from 'd3'
import Skeleton from '@material-ui/lab/Skeleton'
import SingleLevelDrilldown from '../../components/Drilldown/SingleLevelDrilldown'
import WaterfallDrilldownBody from './WaterfallDrilldownBody'
import { customDateFormatter, customDateFormatterNew, dateFormatter, dateFormatterCalenderControl, dateFormatterMonthInWords, dateSubractorInISO, isdateGreater, isdateLesser } from '../../util/customFunctions';
const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  },
  formControl: {
    maxWidth: 250
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: '32%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: '29%',
    [theme.breakpoints.down('md')]: {
      width: '37%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0
    }
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '35%',
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    }
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: '10%',
    [theme.breakpoints.down('md')]: {
      width: '20%',
    }
  },
  buttonContainer: {
    padding: 5,
    width: '5%',
    alignSelf: 'center'

  }

})

class Funnel extends React.Component {

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  filters = ['rep', 'team']

  formatDate = (dt) => {
    let date = dt.toString()
    return date.length < 2 ? `0${date}` : date
  }

  state = {
    last: 7,
    activelast: 7,
    timeFrame: 'window',
    from: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    to: dateFormatter(this.props.waterfallMaxDate),
    fromDisplay: customDateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    toDisplay: customDateFormatter(this.props.waterfallMaxDate),
    activeFrom: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    activeTo: dateFormatter(this.props.waterfallMaxDate),
    minDateForTo: this.props.waterfallMinDate,
    maxDateForTo: this.props.waterfallMaxDate,
    minDateForFrom: this.props.waterfallMinDate,
    maxDateForFrom: this.props.waterfallMaxDate,

    errorTextFrom: '',
    errorTextTo: '',
    rep: ['All'],
    team: ['All'],
    disabled: [],
    repTeam: 'rep',

    location: 'overview',
    filtersForDrilldown: [{ title: 'Rep', value: ['All'] }],
    activeFilters: [],
    selectedDrilldownStage: '',
    selectedDrilldownStageLabel: '',
  }

  orderRepsByLastName = () => {
    let reps = this.props.waterfallFilters.reps.map(r => r.Full_Name).map(rep => {
      const res = rep.split(" ")
      return ({ firstName: res[0], lastName: res[res.length - 1], fullName: rep })
    })
    function compare(a, b) {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare)
    reps = [{ firstName: 'All', lastName: 'All', fullName: 'All', sortName: 'All' }, ...reps]
    const menuItems = reps.map((rep, index) => {
      return rep.fullName
    })
    return menuItems
  }

  componentDidMount() {
    if (this.props.waterfallMaxDate === null) this.props.getWaterfallMinDate()
    else this.props.getWaterfallData(dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, this.state.last)), dateFormatter(this.props.waterfallMaxDate))

    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    D3Funnel.destroy(this._rootNode)
    this._chart = D3Funnel.create(
      this._rootNode,
      {
        data: this.props.waterfallData,
        handleChangeLocation: this.handleChangeLocation,
        company: this.props.company,
        enableStartingEndingPipeline: true
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.waterfallMinDate !== prevProps.waterfallMinDate || this.props.waterfallMaxDate !== prevProps.waterfallMaxDate) {

      this.setState({
        ...this.state, minDateForTo: this.props.waterfallMinDate,
        maxDateForTo: this.props.waterfallMaxDate,
        minDateForFrom: this.props.waterfallMinDate,
        maxDateForFrom: this.props.waterfallMaxDate,
        from: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
        to: dateFormatter(this.props.waterfallMaxDate),
        fromDisplay: customDateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
        toDisplay: customDateFormatter(this.props.waterfallMaxDate),
        activeFrom: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
        activeTo: dateFormatter(this.props.waterfallMaxDate)
      })
    }
    if (this.props.waterfallData !== '' && prevProps.waterfallData === '') {
      this._chart = D3Funnel.create(
        this._rootNode,
        {
          data: this.props.waterfallData,
          handleChangeLocation: this.handleChangeLocation,
          company: this.props.company,
          enableStartingEndingPipeline: true
        }
      )
    }
    if (this.props.waterfallData !== prevProps.waterfallData && prevProps.waterfallData !== '') {
      // D3 Code to update the chart
      D3Funnel.destroy(this._rootNode)
      this._chart = D3Funnel.create(
        this._rootNode,
        {
          data: this.props.waterfallData,
          handleChangeLocation: this.handleChangeLocation,
          company: this.props.company,
          enableStartingEndingPipeline: true
        }
      )
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  handleChange = e => {
    if (e.target.value !== 1) {
      this.setState({ ...this.state, [e.target.name]: e.target.value, to: dateFormatter(this.props.waterfallMaxDate), from: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, e.target.value)) })
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value })
    }
  }

  handleChangeFilter = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.name === 'rep' ? [e.target.value] : e.target.value.length === 0 ? ['All'] : this.state[e.target.name].length === 1 && this.state[e.target.name].includes('All') ? e.target.value.filter(v => v !== 'All').length > 0 ? e.target.value.filter(v => v !== 'All') : ['All'] : e.target.value.includes('All') ? ['All'] : e.target.value
    })
  }



  handleChangeRepTeam = (e) => {
    this.setState({ ...this.state, repTeam: e.target.value, disabled: e.target.value === 'rep' ? [...this.state.disabled.filter(f => f !== 'rep'), 'team'] : [...this.state.disabled.filter(f => f !== 'team'), 'rep'] })
  }

  setFromDate = date => {
    // const split = this.state.to.split('-')
    // console.log(isdateGreater(dateSubractorInISO(date, 0), dateSubractorInISO(this.state.from, 0)))
    // console.log(dateSubractorInISO(date, 0) > dateSubractorInISO(this.state.to, 0))
    if (dateSubractorInISO(date, 0) > dateSubractorInISO(this.state.to, 0)) {
      this.setState({ ...this.state, from: dateFormatterCalenderControl(date), errorTextFrom: 'From date cannot be later than the To date' })
    } else {
      this.setState({ ...this.state, from: dateFormatterCalenderControl(date), errorTextFrom: '', errorTextTo: '' })
    }
    //this.props.getWaterfallData(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`, this.state.to)
  }

  setToDate = date => {
    // console.log(isdateLesser(dateSubractorInISO(date, 0), dateSubractorInISO(this.state.from, 0)))
    if (dateSubractorInISO(date, 0) < dateSubractorInISO(this.state.from, 0)) {
      this.setState({ ...this.state, to: dateFormatterCalenderControl(date), errorTextFrom: 'To date cannot be earlier than the From date' })
    } else {
      this.setState({ ...this.state, to: dateFormatterCalenderControl(date), errorTextTo: '', errorTextFrom: '' })
    }
    //this.props.getWaterfallData(this.state.from, `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`)
  }

  plot = () => {
    const fromSplit = this.state.from.split('-')
    const toSplit = this.state.to.split('-')
    let filters = this.filters.map(f => {
      return {
        name: f,
        value: f === 'rep' ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.reps.filter(r => r.Full_Name === s)[0].UserID) :
          f === 'team' ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.teams.filter(r => r.Display_Name === s)[0].Display_Name) : this.state[f]
      }
    }).filter(f => !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
    this.props.getWaterfallData(this.state.from, this.state.to, filters)
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      activeFilters: filters,
      activelast: this.state.last
    })
  }

  handleChangeLocation = (location, stage, name) => {
    this.setState(
      {
        ...this.state,
        location: location,
        selectedDrilldownStage: stage,
        selectedDrilldownStageLabel: name
      })
    if (location !== 'overview') {
      this.props.getWaterfallDrilldownOnGraph({ stage: stage, from: this.state.activeFrom, to: this.state.activeTo, filters: this.state.activeFilters })
    } else {
      this.props.clearDrilldownData()
    }
  }

  passFiltersForDrilldown = () => {
    let filtersArray = []
    if (this.state.disabled.filter(i => i === 'rep').length === 0) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }]
    }
    if (this.state.disabled.filter(i => i === 'team').length === 0) {
      filtersArray = [...filtersArray, { title: "Team", value: this.state.team }]
    }
    return filtersArray

  }
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : 'All']
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container style={{ position: 'relative' }}>
        <Grid item style={{ marginBottom: 5 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Waterfall Analysis</Typography>
          <Typography variant='body1'>
            Visualize and analyze the positive and negative changes to your pipeline over a given time frame using the classic waterfall chart.
            You can choose from pre-determined time windows or choose your own custom time window.
          </Typography>
        </Grid>

        {
          this.props.waterfallFilters !== "" ? <>
            <Grid container xs={12} alignItems='flex-start' style={{ marginBottom: 5 }}>
              <Grid item container className={classes.datesFilterContainer} component={Paper} >
                <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="select-last-label" style={{ color: '#4472c4' }}>Last:</InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChange}
                      label="Last:"
                      name='last'
                      disabled={this.state.timeFrame === 'custom'}
                    >
                      {[{ text: 'Custom', value: 1 }, { text: '7 days', value: 7 }, { text: '15 days', value: 15 }, { text: '1 month', value: 30 }, { text: '3 months', value: 90 }, { text: '6 months', value: 180 }, { text: '1 year', value: 365 }].map(y => <MenuItem key={y.text} value={y.value}>{y.text}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker label='From' setDate={(date) => this.setFromDate(date)} disabled={this.state.last !== 1} min={this.state.minDateForFrom} max={this.state.maxDateForFrom} current={this.state.from} errorText={this.state.errorTextFrom} />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker label='To' setDate={(date) => this.setToDate(date)} disabled={this.state.last !== 1} min={this.state.minDateForTo} max={this.state.maxDateForTo} current={this.state.to} errorText={this.state.errorTextTo} />
                </Grid>
              </Grid>

              <Grid item container className={classes.repTeamFilterContainer} component={Paper}  >

                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.props.waterfallFilters !== '' && this.orderRepsByLastName()}
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name='rep'
                    disabled={!this.state.team.includes('All')}
                    renderInput={(params) => <TextField  {...params} label="Rep:" variant="standard" InputLabelProps={{ style: { color: !this.state.rep.includes('All') ? '#4472c4' : '#000' } }} />}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <FormControl fullWidth className={classes.formControl} disabled={!this.state.rep.includes('All')}>
                    <InputLabel id="select-team-label" style={{ color: !this.state.team.includes('All') ? '#4472c4' : '#000' }}>Team:</InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      value={this.state.team}
                      onChange={this.handleChangeFilter}
                      label="Team"
                      name='team'
                      multiple
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      {this.props.waterfallFilters !== '' && this.props.waterfallFilters.teams.map(t => t.Display_Name).sort().map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container className={classes.buttonContainer}  >
                <Button color='primary' disabled={this.state.timeFrame === 'window'} variant='contained' onClick={this.plot} disabled={this.state.errorTextFrom !== '' || this.state.errorTextTo !== ''}>GO</Button>
              </Grid>
            </Grid></> : <Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
            <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
          </Grid>
        }

        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            {this.props.waterfallData !== '' && this.props.updatingWaterfallData === false ?
              <Card
                className={clsx(classes.root)}
                raised={true}
              >
                <CardHeader disableTypography
                  title={this.props.waterfallError ? <div style={{ color: 'red' }}>Insufficient data. Please choose different filters.</div> : this.props.alert.message === 'Error updating data' ? 'An Error occurred, please try again or choose different filters' : <Typography variant='body1' style={{ fontWeight: 600 }}>{`Change in pipeline from ${this.state.fromDisplay} to ${this.state.toDisplay}: `}<span style={this.props.trend > 0 ? { color: '#70ad47' } : this.props.trend < 0 ? { color: '#c55a11' } : { color: 'black' }}>{this.props.trend > 0 ? '+' : this.props.trend < 0 ? '-' : ''}${d3.format(",")(Math.abs(this.props.trend))}</span></Typography>} />
                <Divider />
                <CardContent className={classes.content}>
                  <div style={{ display: 'flex', position: 'relative', paddingTop: window.innerWidth < 400 ? '56.25%' : '22.32%' }}>
                    <div style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: '100%' }} className="waterfall-container" ref={this._setRef.bind(this)} />
                  </div>
                </CardContent>
              </Card>
              :
              <Card
                style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
                raised={true}
              >
                <Skeleton animation="wave" variant="text" width='60%' style={{ margin: '20px 10px', alignSelf: 'flex-start' }} />
                <Divider style={{ width: '100%' }} />
                <Skeleton animation="wave" variant="rect" width='80%' height={350} style={{ margin: '20px 0px' }} />
              </Card>}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && this.state.selectedDrilldownStageLabel !== '' &&
          <Grid item xs={12} style={{ position: 'absolute', width: '100%', marginTop: '30px' }}>


            <SingleLevelDrilldown
              header={this.state.selectedDrilldownStageLabel}
              back={this.handleChangeLocation}
              filters={[
                {
                  title: this.state.activelast !== 1 ? "Time Frame" : "Custom Date Range",
                  value: this.state.activelast !== 1 ?
                    this.state.activelast < 30 ? this.state.activelast + " days" :
                      this.state.activelast === 30 ? "1 month" :
                        this.state.activelast === 90 ? "3 months" :
                          this.state.activelast === 180 ? "6 months" :
                            this.state.activelast === 365 ? "1 year" : ''
                    : ''
                },
                { title: 'From', value: this.state.fromDisplay },
                { title: 'To', value: this.state.toDisplay },
                ...this.state.filtersForDrilldown,
              ]}>

              <WaterfallDrilldownBody body={this.props.drilldownOnGraphData} />
            </SingleLevelDrilldown>

          </Grid >
        }
      </Grid>
    )
  }
}


function mapStateToProps(state) {
  const {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    user
  } = state.app

  return {
    waterfallFilters, waterfallData, persistentAlert, waterfallMinDate, waterfallMaxDate, waterfallError, alert, drilldownOnGraphData, updatingWaterfallData,
    company: user.company,
    trend: waterfallData !== '' ? Math.round(waterfallData.filter(r => r.name === 'Ending Pipeline')[0].value - waterfallData.filter(r => r.name === 'Starting Pipeline')[0].value) : 0
  }
}

const mapDispatchToProps = (dispatch) => ({

  getWaterfallData: (from, to, filters) => {
    dispatch({ type: 'get_waterfall_data_request' })
    appService.getWaterfallData(from, to, filters)
      .then(json => {
        dispatch({ type: 'get_waterfall_data_success', json })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_waterfall_data_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_waterfall_data_failure', error })
      })
  },
  getWaterfallMinDate: () => {
    dispatch({ type: 'get_waterfall_min_date_request' })
    appService.getWaterfallMinDate()
      .then(json => {
        dispatch({ type: 'get_waterfall_min_date_success', json })
        dispatch({ type: 'get_waterfall_data_request' })
        appService.getWaterfallData(dateFormatter(dateSubractorInISO(json.message.minMax.maxDate, 7)), dateFormatter(json.message.minMax.maxDate))
          .then(json => {
            dispatch({ type: 'get_waterfall_data_success', json })
          }, error => {
            if (typeof error === 'object') dispatch({ type: 'get_waterfall_data_failure', error: 'Something went wrong' })
            else
              dispatch({ type: 'get_waterfall_data_failure', error })
          })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_waterfall_min_date_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_waterfall_min_date_failure', error })
      })
  },
  getWaterfallDrilldownOnGraph: filters => {
    dispatch({ type: 'get_drilldown_on_graph_request' })
    appService.waterfallDrilldownOnGraph(filters)
      .then(json => {
        json.message.columns.map(i => {
          if (i.type === "currency") {
            json.message.opportunities.map(j => {
              j[i.field] = parseFloat(j[i.field])
            })
          }
        })


        let tempOpportunities = json.message.opportunities.map((item, index) => {
          let x = {
            ...item,
            stage_change_date: item.stage_change_date,
            acvAtStage: parseInt(item.acvAtStage),
          }
          return x
        })

        let acvChangeExists = false
        json.message.columns.map(x => {
          if (x.field === 'acv_change_amount')
            acvChangeExists = true
        })
        if (acvChangeExists) {
          tempOpportunities.sort((a, b) => a.acv_change_amount > b.acv_change_amount ? -1 : 1)
        } else {
          tempOpportunities.sort((a, b) => a.acvAtStage > b.acvAtStage ? -1 : 1)
        }
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1
          }
          return temp
        })

        if (filters.stage === 'Starting Pipeline' || filters.stage === 'Ending Pipeline') {
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              age: Math.ceil(
                (Date.now() - Date.parse(item.Created_Date)) /
                (1000 * 60 * 60 * 24)
              ),
            }
            return temp
          })
        }

        json = {
          ...json,
          message: {
            ...json.message,
            opportunities: tempOpportunities
          }
        }
        dispatch({ type: 'get_drilldown_on_graph_success', json })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_drilldown_on_graph_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_drilldown_on_graph_failure', error })
      })
  },
  clearDrilldownData: () => {
    dispatch({ type: 'clear_drilldown_data' })
  }


})

const connectedFunnel = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Funnel))
export { connectedFunnel as Waterfall }