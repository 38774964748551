import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import MediaCard from "../components/InsightCardV2";
import EnhancedInsightRoutes from "./EnhancedInsightRoutes";
import { appService } from "../App/app.service";
import LoadingSkeleton from "./NewWaterFall/LoadingSkeleton";
import ConfifmModal from "../Favourites/ConfirmModal";
import BackdropContainer from "../components/Backdrop";
import NavItemMenuComponent from "../components/NavItemMenuComponent";
import { useHistory } from "react-router-dom";
const styles = (theme) => ({});
const EnhancedInsightsV2 = (props) => {
  const history = useHistory();
  const [charts, setCharts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [confirmSignal, setConfirmSignal] = React.useState(false);
  const [selectedDashboard, setSelectedDashboard] = React.useState({});
  useEffect(() => {
    setCharts(props.charts);
  }, [props.charts]);
  const cleanAfterConfirm = () => {
    setConfirmSignal(false);
    setSelectedDashboard({});
  };
  const handleOpen = ({ dashboard_id, Chart_Name }) => {
    setSelectedDashboard({ dashboard_id, Chart_Name });
    setOpen(true);
  };
  const handleConfirmClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setConfirmSignal(false);
    setOpen(false);
  };
  useEffect(() => {
    props.getUser();
  }, []);
  const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
  const { classes } = props;
  const handleLocationChange = (loc) => {
    console.log(loc, "lco");
    // if (loc.navLink.startsWith("/app")) {
    //   history.push(loc.navLink);
    //   window.location.reload();
    // }
    // props.changeEnhancedInsightsPage(loc.Chart_Name);
    props.changeEnhancedInsightsPage(loc);
  };

  return (
    <NavItemMenuComponent
      location={props.location}
      isDeleting={props.isDeleting}
      routesComponent={
        <EnhancedInsightRoutes
          company={props.company}
          location={props.location}
          Value_Label={props.Value_Label}
          Win_Rate_Prefix={props.Win_Rate_Prefix}
        />
      }
      removeFavourite={props.removeFavourite}
      handleConfirmClose={handleConfirmClose}
      setConfirmSignal={setConfirmSignal}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      charts={charts}
      setCharts={setCharts}
      handleLocationChange={handleLocationChange}
      baseURL={baseURL}
      selectedDashboard={selectedDashboard}
      cleanAfterConfirm={cleanAfterConfirm}
      confirmSignal={confirmSignal}
      addFavourite={props.addFavourite}
    />
  );
};
const mapStateToProps = (state) => {
  const { enhancedInsightsPage, user, chartsLoaded, isDeleting } = state.app;

  return {
    location: enhancedInsightsPage,
    company: user.company,
    Value_Label: user.Value_Label,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    isDeleting,
    chartsLoaded,
    charts: user.elements
      .filter((item) => item.navItem === "Enhanced Insights")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUser: () => {
    dispatch({ type: "get_user_data_request_page" });
    appService.getUser().then(
      (json) => {
        dispatch({ type: "get_user_data_success", user: json.user });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_user_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },
  addFavourite: (chartID) => {
    dispatch({
      type: "add_favourite_request",
      payload: chartID,
    });
    appService.putFavourite(chartID).then(
      (json) => {
        // console.log(json);
        dispatch({ type: "add_favourite_success", json });
      },
      (error) => {
        if (typeof error === "object") {
          console.log("add_favourite_failure", error);
          dispatch({
            type: "add_favourite_failure",
            error: "Something went wrong",
          });
        } else dispatch({ type: "add_favourite_failure", error });
      }
    );
  },
  removeFavourite: (chartID, navItem) => {
    dispatch({
      type: "delete_favourite_request",
      payload: chartID,
    });
    appService.deleteFavourite(chartID).then(
      (json) => {
        // console.log(json);
        dispatch({
          type: "delete_favourite_success",
          json,
          payload: { dashboard_id: chartID, navItem },
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "delete_favourite_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "delete_favourite_failure", error });
      }
    );
  },
  changeEnhancedInsightsPage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_enhancedInsightsPage", loc });
    localStorage.setItem("skygeni-user-enhancedPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
    localStorage.removeItem("skygeni-user-SettingsPage");
  },
});
const connectedEnhancedInsights = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EnhancedInsightsV2));
export { connectedEnhancedInsights as EnhancedInsightsV2 };
export default EnhancedInsightsV2;
