import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  // import tableStyle from '../../CustomerSuccess/styles/tableStyle'
  import {
    countOrACVFormatter,
    customDateFormatter,
    getUniqueValues,
    StyledTableRow,
  } from "../../util/customFunctions";
  
  import { makeStyles } from "@material-ui/core";
  import isThisQuarter from "date-fns/esm/isThisQuarter/index";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  // import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
  import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";
  
  const tableStyle = makeStyles((theme) => ({
    root: {},
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      // outline: "0.5px solid lightgrey",
      // outlineOffset: "-1.5px",
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "1.5px", // Adjust the thickness as needed
        background: "lightgrey", // Adjust the color as needed
      },
    },
    stickyHeader: {
      position: "sticky",
      top: -1,
      background: "white",
      // outline: "0.2px solid lightgrey",
      // outlineOffset: "-1.5px",
      outline: "lightgrey solid 1px",
      outlineOffset: "-1.5px",
      zIndex: 5,
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        // background: "lightgrey", // Adjust the color as needed
      },
    },
    content: { width: "100%" },
    table: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repText: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "left",
      minWidth: "169px",
      fontWeight: "inherit",
    },
    repNumber: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
      fontWeight: "inherit",
    },
    repNumberACV: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
      minWidth: "92px",
      fontWeight: "inherit",
    },
    repNumberPercent: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
      minWidth: "90px",
      fontWeight: 600,
      cursor: "pointer",
    },
    repTextH: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "left",
      fontWeight: 600,
    },
    repNumberH: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
      fontWeight: 600,
    },
    tableHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      width: "5%",
    },
    repHeaderNew: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      width: "5%",
    },
    inner: {
      height: 375,
      minWidth: 500,
    },
    chart: {
      height: "100%",
    },
    tableHeader: {
      fontSize: "15px",
      whiteSpace: "normal",
      textAlign: "center",
    },
    tableCell: {
      whiteSpace: "normal",
    },
    indentName: {
      paddingLeft: 30,
    },
    blackLabel: {
      color: "black",
    },
    inputFieldContainers: {
      marginBottom: 10,
      padding: 10,
      [theme.breakpoints.up("lg")]: {
        paddingRight: 0,
      },
    },
    fontBold: {
      fontWeight: 600,
    },
    stickyColumn: {
      minWidth: 200,
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 1,
    },
  }));
  
  function DataTable(props) {
    const tableRef = React.createRef();
  
    const tableContainerRef = useRef();
  
    const [isOverflowing, setIsOverflowing] = useState(false);
  
    const [isSticky, setIsSticky] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    // const [globalExpandArrow, setglobalExpandArrow] = useState(false);
    const [rowPin, setrowPin] = useState(false);
    const [parentWidth, setparentWidth] = useState();
  
    let globalExpand = [];
  
    // const uniqueTeams = props.data.data
    //   .map((f) => f.Team)
    //   .filter(getUniqueValues)
    //   .sort();
  
    const handleExpandClick = (rowId) => {
      setExpandedRows((prevExpandedRows) => {
        const isRowExpanded = prevExpandedRows.includes(rowId);
        if (isRowExpanded) {
          return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
        } else {
          return [...prevExpandedRows, rowId];
        }
      });
    };
  
    useEffect(() => {
      setExpandedRows(globalExpand);
    }, []);
  
    const globalExpandData = (con) => {
      if (con === true) {
        // console.log(globalExpand, 'EXPAND')
        setExpandedRows(globalExpand);
        // setglobalExpandArrow(true);
      } else {
        setExpandedRows([]);
        // setglobalExpandArrow(false);
      }
    };
  
    let countinc = 0;
  
    // let countinc = 0;
  
    const classes = tableStyle();
  
    useEffect(() => {
      const tableContainer = document.getElementById("myTableContainer-Second");
  
      const handleOverflowCheck = () => {
        if (tableContainer) {
          setIsOverflowing(
            tableContainer.scrollWidth > tableContainer.offsetWidth
          );
        }
      };
  
      // Check for overflow initially and whenever the component updates
      handleOverflowCheck();
  
      // Attach a resize event listener to check for overflow when the window is resized
      window.addEventListener("resize", handleOverflowCheck);
  
      return () => {
        // Clean up the event listener when the component unmounts
        window.removeEventListener("resize", handleOverflowCheck);
      };
    }, []);
    
  
    useEffect(() => {
      const tableContainer = tableContainerRef.current;
  
      const handleScroll = () => {
        if (tableContainer.scrollLeft > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
  
        if (tableContainer.scrollTop > 0) {
          setrowPin(true);
        } else {
          setrowPin(false);
        }
      };
  
      // Attach a scroll event listener to the TableContainer
      tableContainer.addEventListener("scroll", handleScroll);
  
      return () => {
        // Clean up the event listener when the component unmounts
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }, [classes.sticky]);
  
    useEffect(() => {
      // Get the width of the parent TableContainer
      const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
      setparentWidth(parentWidth);
    }, [parentWidth]);

    const QTR = props.data.filter(
      (item) => item['fiscal_quarter'] !== "Total" && item['fiscal_quarter'] !== null
    );


  


    const uniqueTeamAttainment = props.teamData
    .filter((item) => item['fiscal_quarter'] === "Total")
    .sort((a, b) =>
      b.attainmentRaw === a.attainmentRaw
        ? b.won - a.won
        : b.attainmentRaw - a.attainmentRaw
    );

    const uniqueAttainment = props.data
    .filter((item) => item['fiscal_quarter'] === "Total")
    .sort((a, b) =>
      b.attainmentRaw === a.attainmentRaw
        ? b.won - a.won
        : b.attainmentRaw - a.attainmentRaw
    );

    let uniqueOwner = uniqueAttainment
    .map((item) => item.rep)
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  uniqueOwner =
    props.toggleValue === "PercSort" ? uniqueOwner : uniqueOwner.sort();

    let uniqueTeams = uniqueTeamAttainment
    .map((item) => item['team'])
    .filter(getUniqueValues)
    .filter((item) => item !== null);

  uniqueTeams =
    props.toggleValue === "PercSort"
      ? uniqueTeams
      :  [
        ...props.logicalTeams.filter((team) => uniqueTeams.includes(team)),
        ...uniqueTeams.filter((team) => !props.logicalTeams.includes(team)),
      ];
      
      // props.logicalTeams
      //     .filter((e) => uniqueTeams.indexOf(e) !== -1)
      //     .filter(Boolean);
      const ast = (x) => (
        <>
          <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
        </>
      );
      const ast2 = (x) => (
        <>
          <span style={{ color: "#ed7d31" }}>*</span>&nbsp; <span>{x}</span>{" "}
        </>
      );

  
    return (
      <div
        style={{ maxWidth: "100%", justifyContent: "center", padding: "2rem" }}
      >
        <Grid xs={12}>
          {parentWidth !== undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
                width: `${
                  tableContainerRef.current.getBoundingClientRect().width
                }px`,
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
        </Grid>
        <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
              style={{marginBottom: '1rem'}}
            >
              <Typography variant="body2">
              Note: Team level Quota may not match the the sum of the quotas of the reps shown. Team level Actual may include attainment from reps without an explicit quota but still mapped to a team.
              </Typography>
            </Grid>
            
        <TableContainer
          style={{
            width: "auto",
            borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            borderTop: rowPin ? "0.5px solid lightgrey" : "",
            maxHeight: "85vh",
          }}
          id="myTableContainer-Second"
          ref={tableContainerRef}
        >
          {/* {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null} */}
          <Table ref={tableRef} data-test={props.name} id={props.dataID}>
            <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
              <TableRow
                style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
              >
                <TableCell
                  // className={classes.colHeader}
                  className={
                    isSticky
                      ? `${classes.sticky} ${classes.repHeader}`
                      : classes.repHeader
                  }
                  style={{
                    minWidth: 150,
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                  align="center"
                >
                  {"Quarter"}
                </TableCell>
                {props.quarters.map((col, index) => (
                  <TableCell
                    colSpan={5}
                    style={
                      index % 2 === 0
                        ? { background: "#4471c4", color: "white" }
                        : { background: "#5b9bd5", color: "white" }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
              <StyledTableRow
                style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
              >
                <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{
                      outline: "lightgrey solid 0.5px",
                      outlineOffset: "-1.5px",
                      background: "white",
                    }}
                  >
                  <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "2.5px", marginLeft: "30px" }}>
                        {"Rep"}
                      </div>
                      {props.showReps === true ? (
                        <>
                          <CustomWidthTooltip
                            title={"Expand All"}
                            placement="top-start"
                            style={{ marginTop: "13rem" }}
                          >
                            <KeyboardArrowDownIcon
                              onClick={() => globalExpandData(true)}
                              style={{ color: "gray" }}
                            />
                          </CustomWidthTooltip>
                          <CustomWidthTooltip
                            title={"Collapse All"}
                            placement="top-start"
                          >
                            <KeyboardArrowUpIcon
                              onClick={() => globalExpandData(false)}
                              style={{ color: "gray" }}
                            />
                          </CustomWidthTooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </div>  
                </TableCell>
                {props.quarters.map((col, idx) =>
                  ["Stage 1 or higher pipe at Qtr. Start", "Quota", "Coverage Ratio", "Won", "Quota Attainment %"].map((item) => (
                    <TableCell
                      align="center"
                      style={{ background: "white", width: '6.25rem' }}
                      
                      className={classes.repHeader}
                      colSpan={1}
                      
                    >
                      {item}
                    </TableCell>
                  ))
                )}
              </StyledTableRow>
            </TableHead>
  
            <TableBody>
             
              {uniqueTeams.map((team, i) => {
                globalExpand.push(i);
                countinc = countinc + 1;
                const teamData = props.teamData;
                let repData = props.data
                .filter((item) => item['team'] === team)
                .sort((a, b) =>
                  b.attainment === a.attainment
                    ? b.won - a.won
                    : b.attainment - a.attainment
                );
                let reps = props.data
                        .filter(
                          (item) =>
                            item['team'] === team 
                        )
                        .sort((a, b) =>
                          b.attainment === a.attainment
                            ? b.won - a.won
                            : b.attainment - a.attainment
                        )
                        .map((item) => item.rep)
                        .filter(getUniqueValues);
  
                return (
                  <>
                    {" "}
                    <StyledTableRow
                            key={i}
                            style={
                              props.showReps === true
                                ? { background: "#dae3f3" }
                                : {}
                            }
                          >
                            <TableCell
                              align="left"
                              // className={`${classes.table} ${classes.repHeader}`}
                              className={
                                isSticky
                                  ? `${classes.sticky} ${classes.table} ${classes.repHeader}`
                                  : `${classes.table} ${classes.repHeader}`
                              }
                              // style={
                              //   isSticky && countinc % 2 === 0
                              //     ? {
                              //         background: "#f5f5f5",
                              //         paddingLeft: "25px",
                              //       }
                              //     : { paddingLeft: "25px" }
                              // }
                              style={
                                props.showReps === true
                                  ? { background: "#dae3f3" }
                                  : isSticky && countinc % 2 === 0
                                  ? { background: "#f5f5f5" }
                                  : {}
                              }
                              colSpan={1}
                              onClick={() => handleExpandClick(i)}
                            >
                              {/* {team} */}
                              <div style={{ display: "flex" }}>
                                {props.showReps === true ? (
                                  expandedRows.includes(i) ? (
                                    <KeyboardArrowUpIcon
                                      style={{ color: "gray" }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      style={{ color: "gray" }}
                                    />
                                  )
                                ) : null}
                                <div style={{ marginTop: "3px" }}>{team}</div>
                              </div>
                            </TableCell>
                      {
                        props.quarters.map(quarter => {
                            const d = props.teamData.filter(f => f.fiscal_quarter === quarter && f.team === team)
                            const pipeline = d.length > 0 ? countOrACVFormatter(d.map(f => f.begOfQtrQualifiedPipe).reduce((a,b) => a+b, 0), true) : '-'
                            const won = d.length > 0 ? countOrACVFormatter(d.map(f => f.won).reduce((a,b) => a+b, 0), true) : '-'
                            const quota = d.length > 0 ? countOrACVFormatter(d.map(f => f.quota).reduce((a,b) => a+b, 0), true) : '-'
                            const coverageRatio = d.length > 0 ? d[0].coverageRatio : 'N/A'
                            const attainmnet = d.length > 0 ? d[0].attainment : 'N/A'

                            return <>
                            <TableCell
                        // className={classes.colHeader}
                        align="right"
                        className={
                          classes.repHeader
                        }
                        style={
                         {cursor: 'pointer'}
                        }
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph2",
                            {
                              row: '',
                              cell: quarter,
                              uniqueQuarters: props.quarters.filter(f => f!== 'Total'),
                            },
                            team,
                            'team',
                            'beg'
                          )
                        }
                      >
                        {pipeline}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.colHeader}
                        className={
                          classes.repHeader
                        }
                        style={
                         {}
                        }
                        align="right"
                      >
                        {quota}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.colHeader}
                        className={
                          classes.repHeader
                        }
                        style={
                         {}
                        }
                       align="right"
                      >
                        {coverageRatio}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.colHeader}
                        className={
                          classes.repHeader
                        }
                        style={
                         {cursor: 'pointer'}
                        }
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph2",
                            {
                              row: '',
                              cell: quarter,
                              uniqueQuarters: props.quarters.filter(f => f!== 'Total'),
                            },
                            team,
                            'team'
                          )
                        }
                      >
                        {won}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.colHeader}
                        className={
                          classes.repHeader
                        }
                        style={
                         {}
                        }
                     align="right"
                      >
                        {attainmnet}
                       
                      </TableCell>
                            </>
                        })
                      }
                      
                    </StyledTableRow>
                    {expandedRows.includes(i) &&
                            props.showReps === true &&(
                              reps.map(rep => {
                                return (
                                  <StyledTableRow key={i}>
                                     <TableCell
                                        align="left"
                                        // className={`${classes.table} ${classes.indentName}`}
                                        className={
                                          isSticky
                                            ? `${classes.sticky} ${classes.table} ${classes.indentName}`
                                            : `${classes.table} ${classes.indentName}`
                                        }
                                        style={
                                          isSticky && countinc % 2 === 0
                                            ? {
                                                background: "#f5f5f5",
                                                paddingLeft: "25px",
                                              }
                                            : { paddingLeft: "25px" }
                                        }
                                        colSpan={1}
                                      >
                                       {QTR.filter(
                                          (x) =>
                                            x.rep === rep && x.quota !== null
                                        ).length < props.quarters.filter(f => f !== 'Total').length
                                          ? ast(rep)
                                          : rep}
                                      </TableCell>
                                      {
                        props.quarters.map(quarter => {
                            const d = props.data.filter(f => f.fiscal_quarter === quarter && f.rep === rep)
                            const pipeline = d.length > 0 ? countOrACVFormatter(d.map(f => f.begOfQtrQualifiedPipe).reduce((a,b) => a+b, 0), true) : '-'
                            const won = d.length > 0 ? countOrACVFormatter(d.map(f => f.won).reduce((a,b) => a+b, 0), true) : '-'
                            const quota = d.length > 0 ? countOrACVFormatter(d.map(f => f.quota).reduce((a,b) => a+b, 0), true) : '-'
                            const coverageRatio = d.length > 0 ? d[0].coverageRatio : 'N/A'
                            const attainmnet = d.length > 0 ? d[0].attainment : 'N/A'

                            return <>
                            <TableCell
                        // className={classes.colHeader}
                        align="right"
                        className={
                         classes.repHeaderNew
                        }
                        style={
                         {cursor: 'pointer'}
                        }
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph2",
                            {
                              row: rep,
                              cell: quarter,
                              uniqueQuarters: props.quarters.filter(f => f!== 'Total'),
                            },
                            team,
                            undefined,
                            'beg'
                          )
                        }
                      >
                        {pipeline}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.repHeader}
                        className={
                         classes.repHeaderNew
                        }
                        style={
                         {}
                        }
                        align="right"
                      >
                        {quota}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.repHeader}
                        className={
                         classes.repHeaderNew
                        }
                        style={
                         {}
                        }
                       align="right"
                      >
                        {coverageRatio}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.repHeader}
                        className={
                         classes.repHeaderNew
                        }
                        style={{ cursor: "pointer" }}
                        align="right"
                      
                                              onClick={() =>
                                                props.handleChangeLocation(
                                                  "drilldownOnGraph2",
                                                  {
                                                    row: rep,
                                                    cell: quarter,
                                                    uniqueQuarters: props.quarters.filter(f => f!== 'Total'),
                                                  },
                                                  team,
                                                )
                                              }
                      >
                        {won}
                       
                      </TableCell>
                      <TableCell
                        // className={classes.repHeader}
                        className={
                         classes.repHeaderNew
                        }
                        style={
                         {}
                        }
                     align="right"
                      >
                        {attainmnet}
                       
                      </TableCell>
                            </>
                        })
                      }
                                    </StyledTableRow>
                              )
                              })
                             
                           
               ) }           
                </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {uniqueOwner
          .map((owner) =>
            QTR.filter((x) => x.rep === owner && x.quota !== null)
              .length < props.quarters.filter(f => f !== 'Total').length
              ? QTR.filter(
                  (x) => x.rep === owner && x.quota !== null
                ).length
              : 0
          )
          .reduce((a, b) => a + b, 0) !== 0 &&
          uniqueOwner
            .map((owner) =>
              QTR.filter((x) => x.rep === owner && x.quota !== null)
                .length < props.quarters.filter(f => f !== 'Total').length
                ? QTR.filter(
                    (x) => x.rep === owner && x.quota !== null
                  ).length
                : 0
            )
            .reduce((a, b) => a + b, 0) !== 0 &&
          props.showReps === true && (
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
              style={{marginTop: '1rem'}}
            >
              <Typography variant="body2">
                <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded from
                analysis for time periods where no quota data is available.
              </Typography>
            </Grid>
          )}
      </div>
    );
  }
  
  export default DataTable;
  
  