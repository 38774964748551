import React from "react";
import D3Funnel from "./D3Funnel";
import D3FunnelACV from "./D3FunnelACV";
import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import FunnelDrilldownBody from "./FunnelDrilldownBody";
import OpenDrilldownBody from "./OpenDrilldownBody";
import OpenDrilldownBody2 from "./OpenDrilldownBody2";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { _isNaN } from "../../util/customFunctions";

// import FunnelDrilldownOnOpenBody from './FunnelDrilldownOnOpenBody'

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  tableHeader: {
    whiteSpace: "normal",
    textAlign: "center",
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableHeader: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "white",
    background: "#4472C4",
  },
});

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  if (currentMonth < 3) {
    return `${year - 1}-Q4`;
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q1`;
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q2`;
  } else {
    return `${year}-Q3`;
  }
};

class Funnel extends React.Component {
  state = {
    year: ["All"],
    month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    cfq:
      this.props.funnelFilters === ""
        ? [nearestCompletedFiscalQuarter()]
        : [this.props.funnelFilters.createdFiscalQuarters[1]],
    region: ["All"],
    industry: ["All"],
    leadSource: ["All"],
    oppCreatedBy: ["All"],
    solutionCat: ["All"],
    custType: ["All"],
    custSeg: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [],
    repTeam: "rep",
    acv: 0,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: [
      {
        name: "cfq",
        value:
          this.props.funnelFilters === ""
            ? [nearestCompletedFiscalQuarter()]
            : [this.props.funnelFilters.createdFiscalQuarters[1]],
      },
    ],
    filtersForDrilldown:
      this.props.company !== "C0003"
        ? [
            {
              title: "Open Fiscal Quarter",
              value:
                this.props.funnelFilters === ""
                  ? [nearestCompletedFiscalQuarter()]
                  : [this.props.funnelFilters.createdFiscalQuarters[1]],
            },
          ]
        : [
            {
              title: "Open Fiscal Quarter",
              value:
                this.props.funnelFilters === ""
                  ? [nearestCompletedFiscalQuarter()]
                  : [this.props.funnelFilters.createdFiscalQuarters[1]],
            },
          ],
  };

  filters = [
    "year",
    "month",
    "quarter",
    "rep",
    "cfq",
    "team",
    "region",
    "industry",
    "custType",
    "custSeg",
    "leadSource",
    "oppCreatedBy",
    "solutionCat",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  staticCustSegment = [
    { value: "<=$25M", displayValue: "<=$25M" },
    { value: "$25-50M", displayValue: "$25M-50M" },
    { value: "$50-250M", displayValue: "$50M-250M" },
    { value: "$250-500M", displayValue: "$250M-500M" },
    { value: "$500M-2B", displayValue: "$500M-2B" },
    { value: ">$2B", displayValue: ">$2B" },
  ];

  componentDidMount() {
    if (this.props.funnelFilters === "") this.props.getFunnelFilters();
    else
      this.props.getFunnelDataNew([
        {
          name: "cfq",
          value: [
            this.props.funnelFilters.createdFiscalQuarters[1],
          ] /* [nearestCompletedFiscalQuarter()] */,
        },
      ]);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    D3Funnel.destroy(this._rootNode);
    D3Funnel.destroy(this._rootNode2);
    this._chart = D3Funnel.create(this._rootNode, {
      data: this.props.funnelData,
      handleChangeLocation: this.handleChangeLocation,
    });
    this._chart = D3FunnelACV.create(this._rootNode2, {
      data: this.props.funnelData,
      handleChangeLocation: this.handleChangeLocation,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      this.setState({
        ...prevState,
        cfq: [this.props.funnelFilters.createdFiscalQuarters[1]],
        filtersForDrilldown: [
          {
            title: "Open Fiscal Quarter",
            value: [this.props.funnelFilters.createdFiscalQuarters[1]],
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Open Fiscal Quarter"
          ),
        ],
        activeFilters: [
          {
            name: "cfq",
            value: [this.props.funnelFilters.createdFiscalQuarters[1]],
          },
        ],
      });
    }
    if (this.props.funnelData !== "" && prevProps.funnelData === "") {
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
      });
      this._chart = D3FunnelACV.create(this._rootNode2, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
      });
    }
    if (
      this.props.funnelData !== prevProps.funnelData &&
      prevProps.funnelData !== ""
    ) {
      // D3 Code to update the chart
      D3Funnel.destroy(this._rootNode);
      D3Funnel.destroy(this._rootNode2);
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
      });
      this._chart = D3FunnelACV.create(this._rootNode2, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
      });
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    this.props.getFunnelDataNew(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) => f !== "year" && f !== "month" && f !== "quarter"
              ),
              "cfq",
            ]
          : [
              ...this.state.disabled.filter((f) => f !== "cfq"),
              "year",
              "month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, stage, lastStage) => {
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      lastStage: lastStage,
    });
    if (location === "drilldownOnGraph") {
      this.props.getCreatedDrilldownOnGraph({
        stage: stage,
        filters: this.state.activeFilters,
      });
    } else if (location === "drilldownOnOpen" && stage === "stage1") {
      this.props.getCreatedDrilldownOnOpen({
        filters: this.state.activeFilters,
      });
    } else if (location === "drilldownOnOpen" && stage && stage !== "stage1") {
      this.props.getCreatedDrilldownOnOpen2({
        stage: stage,
        filters: this.state.activeFilters,
      });
    } else {
      this.props.clearDrilldownData();
      this.props.clearDrilldownOpenData();
      this.props.clearDrilldownOpen2Data();
    }
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      selectedDrilldownStage: "stage1",
    });
    this.props.clearDrilldownOpen2Data();
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.cfq.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Open Fiscal Quarter", value: this.state.cfq },
      ];
    }

    if (!this.state.year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Open Calendar Year", value: this.state.year },
      ];
    }

    if (!this.state.month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Open Calendar Month",
          value: this.state.month.map((i) => this.months[i - 1]),
        },
      ];
    }

    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: "ACV Greater Than",
          value:
            "$ " +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.industry,
        },
      ];

    if (!this.state.custSeg.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Customer Segment", value: this.state.custSeg },
      ];

    if (!this.state.region.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state.region,
        },
      ];

    if (!this.state.leadSource.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.leadSource },
      ];

    if (!this.state.oppCreatedBy.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.oppCreatedBy },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.solutionCat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.solutionCat },
      ];

    return filtersArray;
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };
  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={1} style={{ position: "relative" }}>
        {/* Caption and description */}
        <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
            Win Rate Cohort Analysis – Created Deals
          </Typography>
          <Typography variant="body1">
            The Win Rate cohort analysis for Created Deals enables you to
            examine all the deals that were created during a given time frame
            and analyze the win rate as opportunities moved down the funnel.
            Visualizing where opportunities drop off can pinpoint specific
            challenges that need corrective action. This analysis provides you
            insights by opportunity count as well as ACV.
          </Typography>
        </Grid>

        {this.props.funnelFilters !== "" ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.year[0] !== "All" ||
                      this.state.month[0] !== "All"
                    }
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.cfq.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Open Fiscal Quarter:
                    </InputLabel>
                    <Select
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.cfq}
                      onChange={this.handleChange}
                      label="Open Fiscal Quarter"
                      name="cfq"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.createdFiscalQuarters.map(
                          (y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.cfq[0] !== "All"}
                  >
                    <InputLabel
                      id="select-year-label"
                      style={{
                        color: !this.state.year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Open Calendar Year:
                    </InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-year"
                      value={this.state.year}
                      onChange={this.handleChange}
                      label="Year"
                      name="year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.years
                          .sort()
                          .reverse()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.cfq[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      id="select-month-label"
                      style={{
                        color: !this.state.month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Open Calendar Month:
                    </InputLabel>
                    <Select
                      labelId="select-month-label"
                      id="select-month"
                      value={this.state.month}
                      onChange={this.handleChange}
                      label="Month"
                      name="month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.funnelFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rep:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Team:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      label="Team"
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.teams
                          .map((t) => t.Display_Name)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.company === "C0001"
                        ? [
                            "0",
                            "5,000",
                            "10,000",
                            "15,000",
                            "20,000",
                            "30,000",
                            "50,000",
                            "100,000",
                          ]
                        : [
                            "0",
                            "50,000",
                            "100,000",
                            "250,000",
                            "500,000",
                            "750,000",
                            "1,000,000",
                          ]
                    }
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ACV >= :"
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* extra filters 1 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.industry.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.industry}
                      onChange={this.handleChange}
                      name="industry"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.industries.sort().map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.custSeg.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.custSeg}
                      onChange={this.handleChange}
                      name="custSeg"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.company === "C0003" &&
                        this.props.funnelFilters.custSeg.sort().map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters &&
                        this.props.company !== "C0003" &&
                        this.staticCustSegment.map((y) => (
                          <MenuItem key={y} value={y.value}>
                            {y.displayValue}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.region.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.region}
                      onChange={this.handleChange}
                      name="region"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.regions.sort().map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* extra filters 2 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={this.props.company === "C0003" ? 4 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.leadSource.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.leadSource}
                      onChange={this.handleChange}
                      name="leadSource"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.leadSource.sort().map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.oppCreatedBy.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Opportunity Created By:
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.oppCreatedBy}
                      onChange={this.handleChange}
                      name="oppCreatedBy"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.oppCreatedBy
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company !== "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-industry-label"
                        style={{
                          color: !this.state.solutionCat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-industry-label"
                        id="select-industry"
                        value={this.state.solutionCat}
                        onChange={this.handleChange}
                        name="solutionCat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.solutionCat
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid
          item
          container
          spacing={3}
          style={{ maxWidth: "calc(100% + 9px)" }}
          justify="space-around"
        >
          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
            {this.props.tableData && this.props.updatingFunnelData == false ? (
              <Card raised style={{ margin: "10px 0px" }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Opportunities</TableCell>
                      <TableCell align="right">
                        {d3.format(",")(this.props.tableData[0].count)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {this.props.tableData
                      .filter((t) => t.current_status !== "total")
                      .map((tr) => (
                        <TableRow key={tr.current_status}>
                          <TableCell>
                            {tr.current_status === "Open" ? (
                              <Link
                                component="button"
                                variant="h4"
                                underline="always"
                                onClick={() =>
                                  this.handleChangeLocation(
                                    "drilldownOnOpen",
                                    "stage1",
                                    false
                                  )
                                }
                              >
                                {tr.current_status}
                              </Link>
                            ) : (
                              tr.current_status
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(",")(tr.count)}
                          </TableCell>
                          <TableCell align="right">
                            {(this.props.tableData[0].count > 0
                              ? tr.count / this.props.tableData[0].count > 0
                                ? (tr.count / this.props.tableData[0].count) *
                                  100
                                : 0
                              : 0
                            ).toFixed(1)}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            ) : (
              <Card
                raised
                style={{
                  margin: "10px 0px",
                  height: 132,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                  <Skeleton
                    key={n}
                    animation="wave"
                    variant="text"
                    width="30%"
                  />
                ))}
              </Card>
            )}
            {/* <Typography variant='h6'>Win Rate by opportunity count: {this.props.funnelData && Math.round(this.props.funnelData[this.props.funnelData.length - 1].rate * 100)}%</Typography> */}
            {this.props.tableData && this.props.updatingFunnelData == false ? (
              <Card className={clsx(classes.root)} raised={true}>
                <CardHeader
                  disableTypography
                  title={`Win Rate by opportunity count: ${
                    this.props.funnelData &&
                    Math.round(
                      this.props.funnelData[this.props.funnelData.length - 1]
                        .rate * 100
                    )
                  }%`}
                />
                <Divider />
                <CardContent className={classes.content}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      paddingTop: "33.73%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                      }}
                      className="line-container"
                      ref={this._setRef.bind(this)}
                    />
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={280}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
            {this.props.tableData && this.props.updatingFunnelData == false ? (
              <Card raised style={{ margin: "10px 0px" }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total ACV</TableCell>
                      <TableCell align="right">
                        ${" "}
                        {d3.format(",")(
                          Math.round(this.props.tableData[0].acv)
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {this.props.tableData
                      .filter((t) => t.current_status !== "total")
                      .map((tr) => (
                        <TableRow key={tr.current_status}>
                          <TableCell>
                            {tr.current_status === "Open" ? (
                              <Link
                                component="button"
                                variant="h4"
                                underline="always"
                                onClick={() =>
                                  this.handleChangeLocation(
                                    "drilldownOnOpen",
                                    "stage1",
                                    false
                                  )
                                }
                              >
                                {tr.current_status}
                              </Link>
                            ) : (
                              tr.current_status
                            )}
                          </TableCell>
                          <TableCell align="right">
                            $ {d3.format(",")(Math.round(tr.acv))}
                          </TableCell>
                          <TableCell align="right">
                            {(this.props.tableData[0].acv > 0
                              ? tr.acv / this.props.tableData[0].acv > 0
                                ? (tr.acv / this.props.tableData[0].acv) * 100
                                : 0
                              : 0
                            ).toFixed(1)}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            ) : (
              <Card
                raised
                style={{
                  margin: "10px 0px",
                  height: 132,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                  <Skeleton
                    key={n}
                    animation="wave"
                    variant="text"
                    width="30%"
                  />
                ))}
              </Card>
            )}
            {/*  <Typography variant='h6'>Win Rate by ACV: {this.props.funnelData && Math.round(this.props.funnelData[this.props.funnelData.length - 1].acvRate * 100)}%</Typography> */}
            {this.props.tableData && this.props.updatingFunnelData == false ? (
              <Card className={clsx(classes.root)} raised={true}>
                <CardHeader
                  disableTypography
                  title={`Win Rate by ACV: ${
                    this.props.funnelData &&
                    Math.round(
                      this.props.funnelData[this.props.funnelData.length - 1]
                        .acvRate * 100
                    )
                  }%`}
                />
                <Divider />
                <CardContent className={classes.content}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      paddingTop: "33.73%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                      }}
                      className="line-container"
                      ref={this._setRef2.bind(this)}
                    />
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={280}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>

        <Grid item container style={{ marginTop: 10 }} justify="space-around">
          <Grid item xs={12} sm={8}>
            {this.props.funnelData && this.props.updatingFunnelData == false ? (
              <TableContainer className={classes.mainTable} component={Paper}>
                <Table
                  size="small"
                  aria-label="html table"
                  style={{ tableLayout: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} align="center">
                        Stage
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        align="center"
                        colSpan={2}
                      >
                        Came to Stage
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        align="center"
                        colSpan={2}
                      >
                        Lost at Stage
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        align="center"
                        colSpan={2}
                      >
                        Moved to next stage
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        align="center"
                        colSpan={2}
                      >
                        Win Rate
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={classes.table}
                        align="center"
                      ></TableCell>
                      <TableCell className={classes.table} align="center">
                        # of Opps
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        ACV
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        # of Opps
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        ACV
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        # of Opps
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        ACV
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        By Opp. count
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        By ACV
                      </TableCell>
                    </TableRow>
                    {this.props.funnelData.map((x, i) => (
                      <StyledTableRow>
                        <TableCell>{x.label}</TableCell>
                        <TableCell className={classes.table} align="right">
                          {x.count}
                        </TableCell>
                        <TableCell
                          className={classes.table}
                          align="right"
                        >{`$${d3.format(",")(Math.round(x.acv))}`}</TableCell>
                        <TableCell className={classes.table} align="right">
                          {this.props.funnelData[i + 1]
                            ? x.count - this.props.funnelData[i + 1].count
                            : ""}
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          {this.props.funnelData[i + 1]
                            ? `$${d3.format(",")(
                                Math.round(
                                  x.acv - this.props.funnelData[i + 1].acv
                                )
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          {this.props.funnelData[i + 1]
                            ? this.props.funnelData[i + 1].count
                            : ""}
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          {this.props.funnelData[i + 1]
                            ? `$${d3.format(",")(
                                Math.round(this.props.funnelData[i + 1].acv)
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.table} align="right">{`${
                          Math.min(
                            ...this.props.funnelData.map((d) => d.count)
                          ) === 0 || x.count === 0
                            ? 0
                            : Math.round(
                                (Math.min(
                                  ...this.props.funnelData.map((d) => d.count)
                                ) /
                                  x.count) *
                                  100
                              )
                        }%`}</TableCell>
                        <TableCell className={classes.table} align="right">{`${
                          Math.min(
                            ...this.props.funnelData.map((d) => d.acv)
                          ) === 0 || x.acv === 0
                            ? 0
                            : Math.round(
                                (Math.min(
                                  ...this.props.funnelData.map((d) => d.acv)
                                ) /
                                  x.acv) *
                                  100
                              )
                        }%`}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={280}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" &&
          this.state.selectedDrilldownStage !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={
                  this.state.lastStage
                    ? `${this.state.selectedDrilldownStage} Opportunities`
                    : `Opportunities lost at ${
                        this.state.selectedDrilldownStage &&
                        this.state.selectedDrilldownStage
                      } stage`
                }
                back={this.handleChangeLocation}
                filters={this.state.filtersForDrilldown}
              >
                <FunnelDrilldownBody
                  data={this.props.drilldownOnGraphData}
                  header={
                    this.state.lastStage
                      ? `${this.state.selectedDrilldownStage} Opportunities`
                      : `Opportunities lost at ${
                          this.state.selectedDrilldownStage &&
                          this.state.selectedDrilldownStage
                        } stage`
                  }
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.location === "drilldownOnOpen" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Open Opportunities by stage ${
                this.state.selectedDrilldownStage &&
                this.state.selectedDrilldownStage !== "stage1"
                  ? " - " + this.state.selectedDrilldownStage
                  : ""
              }`}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
              stepBack={
                this.state.selectedDrilldownStage &&
                this.state.selectedDrilldownStage !== "stage1"
                  ? this.stepBack
                  : null
              }
            >
              {this.state.selectedDrilldownStage === "stage1" ? (
                <OpenDrilldownBody
                  data={this.props.drilldownOnOpenData}
                  handleChangeLocation={this.handleChangeLocation}
                />
              ) : (
                <OpenDrilldownBody2 data={this.props.drilldownOnOpenData2} />
              )}
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

function mapStateToProps(state) {
  const {
    createdFunnelFilters,
    createdFunnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    drilldownOnOpenData,
    drilldownOnOpenData2,
  } = state.app;

  return {
    funnelFilters: createdFunnelFilters,
    funnelData: createdFunnelData.message,
    tableData: createdFunnelData.table,
    persistentAlert,
    company: user.company,
    drilldownOnGraphData,
    drilldownOnOpenData,
    drilldownOnOpenData2,
    updatingFunnelData,
    updatingDrillDownData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_created_funnel_filters_request" });
    appService.getCreatedFunnelFiltersNew().then(
      (json) => {
        dispatch({ type: "get_created_funnel_filters_success", json });
        dispatch({ type: "get_created_funnel_data_request" });
        appService
          .getCreatedFunnelDataNew([
            {
              name: "cfq",
              value: [
                json.message.createdFiscalQuarters[1],
              ] /* [nearestCompletedFiscalQuarter()] */,
            },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_created_funnel_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_created_funnel_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_created_funnel_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_created_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_created_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters) => {
    dispatch({ type: "get_created_funnel_data_request" });
    appService.getCreatedFunnelDataNew(filters).then(
      (json) => {
        dispatch({ type: "get_created_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_created_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_created_funnel_data_failure", error });
      }
    );
  },
  getCreatedDrilldownOnGraph: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.createdDrilldownOnGraph(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities.map(
          (item, index) => {
            let x = {
              ...item,
              Created_Date: item.Created_Date.split("T")[0],
              closed_date:
                item.closed_date !== null && item.closed_date !== undefined
                  ? item.closed_date.split("T")[0]
                  : "",
              cycle_time_closed_orders: _isNaN(
                parseInt(item.cycle_time_closed_orders)
              )
                ? ""
                : parseInt(item.cycle_time_closed_orders),
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            opportunities: tempOpportunities,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getCreatedDrilldownOnOpen: (filters) => {
    dispatch({ type: "get_drilldown_on_open_request" });
    appService.createdDrilldownOnOpen(filters).then(
      (json) => {
        dispatch({ type: "get_drilldown_on_open_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_open_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_open_failure", error });
      }
    );
  },
  getCreatedDrilldownOnOpen2: (filters) => {
    dispatch({ type: "get_drilldown_on_open2_request" });
    appService.createdDrilldownOnOpen2(filters).then(
      (json) => {
        dispatch({ type: "get_drilldown_on_open2_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_open2_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_open2_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  clearDrilldownOpenData: () => {
    dispatch({ type: "clear_drilldown_data_open" });
  },
  clearDrilldownOpen2Data: () => {
    dispatch({ type: "clear_drilldown_data_open2" });
  },
});

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export { connectedFunnel as Funnel };
