import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  withStyles,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@mui/material/Link";
import {
  countOrACVFormatter,
  StyledTableRow,
  _isNaN,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const styles = makeStyles({
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 5px",
    color: "inherit",
  },
  nonScoreCell: {
    fontWeight: 400,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  repHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    fontWeight: 600,
  },
  repHeader1: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  repHeaderDrilldown: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    cursor: "pointer",
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
  "& .MuiTableCell-sizeSmall": {
    padding: "6px 24px 6px 10px;",
  },
});

export default function MockupTable(props) {
  const classes = styles();
  const tableRef = React.createRef();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.4rem",
    },
  }))(Tooltip);

  const [tableLimit, setTableLimit] = useState(2);

  let tableData = props.data.filter(
    (d) => d.high < d.remainingCurrentQuarter && d.name !== "team"
  );
  tableData.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  const setdataLimit = (val) => {
    if (val === "showMore") {
      setTableLimit(tableData.length);
    } else {
      setTableLimit(2);
    }
  };

  return props.text ? (
    <TableContainer className={classes.mainTable}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <Table
        ref={tableRef}
        id="Enough_HQ_Pipeline_table"
        size="small"
        aria-label="html table"
        style={{ tableLayout: "auto" }}
        data-test={"ms-pipeline-coverage"}
      >
        <TableHead></TableHead>
        <TableBody>
          <StyledTableRow>
            <TableCell
              align="center"
              style={{ background: "#FCE3D7", height: 90, width: "15%" }}
              rowspan={2}
              className={classes.repHeader}
              colSpan={1}
            >
              {`QP ${props.Value_Label} as a factor of remaining Current Qtr. Quota`}
            </TableCell>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              rowspan={2}
              style={
                props.company !== "C0003"
                  ? { background: "#4472C4", color: "white", width: "7%" }
                  : { background: "#BF8F00", color: "white", width: "7%" }
              }
            >
              Goal
            </TableCell>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              rowspan={2}
              style={
                props.company !== "C0003"
                  ? { background: "#4472C4", color: "white", width: "7%" }
                  : { background: "#70AD47", color: "white", width: "7%" }
              }
            >
              {props.company === "C0003" ? "Won" : "Actual"}
            </TableCell>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={2}
              style={{ background: "#70AD47", color: "white" }}
            >{`QP Current Qtr. - Total`}</TableCell>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              rowspan={2}
              style={{ background: "#BF8F00", color: "white", width: "7%" }}
            >{`Remaining Current Qtr. Quota`}</TableCell>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={3}
              style={{ background: "#70AD47", color: "white" }}
            >
              Expected Yield by end of Current Qtr.{" "}
              <span
                style={{
                  background: "#fee599ff",
                  padding: "0 0.4rem",
                  margin: "0 0.5rem",
                  border: "1px solid lightgrey",
                  color: "black",
                }}
              >
                Beta
              </span>
            </TableCell>
          </StyledTableRow>
          <TableRow>
            <TableCell
              align="center"
              style={{ background: "#70AD47", color: "white", width: "6%" }}
              className={classes.repHeader}
            >
              # of Opps
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#70AD47", color: "white", width: "7%" }}
              className={classes.repHeader}
            >
              {/* ACV */}
              {`${props.Value_Label}`}
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#70AD47", color: "white", width: "7%" }}
              className={classes.repHeader}
            >
              Low
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#70AD47", color: "white", width: "7%" }}
              className={classes.repHeader}
            >
              Mid
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#70AD47", color: "white", width: "7%" }}
              className={classes.repHeader}
            >
              High
            </TableCell>
          </TableRow>
          <StyledTableRow>
            <TableCell
              align="center"
              rowspan={2}
              className={classes.repHeader}
              colSpan={9}
            >
              {props.text}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      <TableContainer className={classes.mainTable}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          id="Enough_HQ_Pipeline_table"
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={"ms-pipeline-coverage"}
        >
          <TableHead></TableHead>
          <TableBody>
            <StyledTableRow>
              <TableCell
                align="center"
                style={{ background: "#FCE3D7", height: 90, width: "15%" }}
                rowspan={2}
                className={classes.repHeader}
                colSpan={1}
              >
                {`QP ${props.Value_Label} as a factor of remaining Current Qtr. Quota`}
              </TableCell>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                rowspan={2}
                style={
                  props.company !== "C0003"
                    ? { background: "#4472C4", color: "white", width: "7%" }
                    : { background: "#BF8F00", color: "white", width: "7%" }
                }
              >
                Goal
              </TableCell>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                rowspan={2}
                style={
                  props.company !== "C0003"
                    ? { background: "#4472C4", color: "white", width: "7%" }
                    : { background: "#70AD47", color: "white", width: "7%" }
                }
              >
                {props.company === "C0003" ? "Won" : "Actual"}
              </TableCell>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={2}
                style={{ background: "#70AD47", color: "white" }}
              >{`QP Current Qtr. - Total`}</TableCell>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={1}
                rowspan={2}
                style={{ background: "#BF8F00", color: "white", width: "7%" }}
              >{`Remaining Current Qtr. Quota`}</TableCell>
              <TableCell
                align="center"
                className={classes.repHeader}
                colSpan={3}
                style={{ background: "#70AD47", color: "white" }}
              >
                Expected Yield by end of Current Qtr.{" "}
                <span
                  style={{
                    background: "#fee599ff",
                    padding: "0 0.4rem",
                    margin: "0 0.5rem",
                    border: "1px solid lightgrey",
                    color: "black",
                  }}
                >
                  Beta
                </span>
              </TableCell>
            </StyledTableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{ background: "#70AD47", color: "white", width: "6%" }}
                className={classes.repHeader}
              >
                # of Opps
              </TableCell>
              <TableCell
                align="center"
                style={{ background: "#70AD47", color: "white", width: "7%" }}
                className={classes.repHeader}
              >
                {/* ACV */}
                {`${props.Value_Label}`}
              </TableCell>
              <TableCell
                align="center"
                style={{ background: "#70AD47", color: "white", width: "7%" }}
                className={classes.repHeader}
              >
                Low
              </TableCell>
              <TableCell
                align="center"
                style={{ background: "#70AD47", color: "white", width: "7%" }}
                className={classes.repHeader}
              >
                Mid
              </TableCell>
              <TableCell
                align="center"
                style={{ background: "#70AD47", color: "white", width: "7%" }}
                className={classes.repHeader}
              >
                High
              </TableCell>
            </TableRow>
            {tableData.slice(0, tableLimit).map((data, i) => (
              <StyledTableRow key={i}>
                <TableCell align="left" className={classes.repHeader}>
                  {data.name}
                </TableCell>
                <TableCell align="right" className={classes.repHeader}>
                  {props.company === "C0003"
                    ? countOrACVFormatter(Math.round(data.goal), true)
                    : data.coverageGoal}
                </TableCell>
                <TableCell
                  align="right"
                  style={
                    props.company === "C0003"
                      ? { background: data.won > data.goal ? "#E2EFDA" : "" }
                      : {
                          background:
                            data.actual > +data.coverageGoal ? "#E2EFDA" : "",
                        }
                  }
                  className={classes.repHeader}
                >
                  {props.company === "C0003"
                    ? countOrACVFormatter(Math.round(data.won), true)
                    : data.actual.toFixed(2)}
                </TableCell>
                <TableCell align="right" className={classes.repHeader1}>
                  {data.count}
                </TableCell>
                <TableCell align="right" className={classes.repHeader1}>
                  {countOrACVFormatter(Math.round(data.acv), true)}
                </TableCell>
                <TableCell align="right" className={classes.repHeader1}>
                  {countOrACVFormatter(
                    Math.round(data.remainingCurrentQuarter),
                    true
                  )}
                </TableCell>
                <TableCell align="right" className={classes.repHeader1}>
                  {countOrACVFormatter(Math.round(data.low), true)}
                </TableCell>
                <TableCell align="right" className={classes.repHeader1}>
                  {countOrACVFormatter(Math.round(data.mid), true)}
                </TableCell>
                <HtmlTooltip
                  title={
                    <div
                      style={{
                        borderTop: "solid #f4b183",
                        borderRadius: "1rem",
                        padding: "0px",
                        margin: 0,
                        borderWidth: "0.6rem",
                      }}
                    >
                      <div
                        className="tooltip-box"
                        style={{ padding: "0px", fontSize: "1rem" }}
                      >
                        <h3
                          className="heading-style"
                          style={{ padding: "0.5rem" }}
                        >
                          {data.name}
                        </h3>
                        <div
                          style={{
                            paddingLeft: "0.8rem",
                            paddingRight: "0.8rem",
                          }}
                        >
                          <hr />
                        </div>
                        <div
                          className="container"
                          style={{ padding: "0.5rem", fontSize: "0.8rem" }}
                        >
                          <h4 className="result-style">
                            {" "}
                            Expected Pipeline Yield (High) :{" "}
                            <span style={{ color: "rgb(31,119,180)" }}>
                              ${countOrACVFormatter(data.high)}
                            </span>{" "}
                          </h4>
                          <h4
                            style={{ paddingTop: "0.3rem" }}
                            className="result-style"
                          >
                            {" "}
                            <u>less than</u> &nbsp; Remaining Quota :{" "}
                            <span style={{ color: "rgb(31,119,180)" }}>
                              ${countOrACVFormatter(data.quota)}
                            </span>
                          </h4>
                        </div>
                        <div
                          className="container"
                          style={{
                            margin: "0px",
                            paddingBottom: "0.3rem",
                            paddingLeft: "0.8rem",
                            paddingRight: "0.8rem",
                            fontSize: "1rem",
                          }}
                        >
                          <h4
                            style={{
                              backgroundColor: "#f4b183",
                              padding: "0.1rem",
                            }}
                            className="heading-style"
                          >
                            At risk of missing quota
                          </h4>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <TableCell
                    align="right"
                    style={{ background: "#F4B183" }}
                    className={classes.repHeader1}
                  >
                    {countOrACVFormatter(Math.round(data.high), true)}
                  </TableCell>
                </HtmlTooltip>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.length > 2 ? (
        <p style={{ paddingLeft: "0.4rem" }}>
          {tableData.length > tableLimit ? (
            <>
              <Link
                onClick={() => setdataLimit("showMore")}
                style={{ cursor: "pointer" }}
                id="Enough_HQ_Pipeline_showmore"
              >
                Show More
              </Link>
            </>
          ) : (
            <>
              <Link
                id="Enough_HQ_Pipeline_showless"
                onClick={() => setdataLimit("showLess")}
                style={{ cursor: "pointer" }}
              >
                Show Less
              </Link>
            </>
          )}
        </p>
      ) : (
        <></>
      )}
    </>
  );
}
