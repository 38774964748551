import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";

const ClosedFunnelComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem("skygeni-user-page", "Enhanced Insights");
      await localStorage.setItem(
        "skygeni-user-enhancedPage",
        "Win Rate Cohort Analysis – Closed Deals"
      );
      await localStorage.removeItem("skygeni-user-repPage");
      await localStorage.setItem(
        "params",
        JSON.stringify(props.match.params.filters)
      );
      history.push("/");
      window.location.reload();
    })();
  }, []);

  return <div style={{ margin: "1rem auto" }}>Loading...</div>;
};
let routerAddedClosedFunnelComponent = withRouter(ClosedFunnelComponent);
export default routerAddedClosedFunnelComponent;
