import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  customDateFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import isThisQuarter from "date-fns/esm/isThisQuarter/index";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      // outline: "0.5px solid lightgrey",
      // outlineOffset: "-1.5px",
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        background: "lightgrey", // Adjust the color as needed
      },
    },
    stickyHeader: {
      position: "sticky",
      top: -1,
      background: "white",
      outline: "0.2px solid lightgrey",
      outlineOffset: "-1.5px",
      zIndex: 5,
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        // background: "lightgrey", // Adjust the color as needed
      },
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      // cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function DataTable(props) {
  const tableRef = React.createRef();

  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  // const [globalExpandArrow, setglobalExpandArrow] = useState(false);
  const [rowPin, setrowPin] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  let globalExpand = [];

  const uniqueTeams = props.data.data
    .map((f) => f.Team)
    .filter(getUniqueValues)
    .sort();

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      // console.log(globalExpand, 'EXPAND')
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  let countinc = 0;

  // let countinc = 0;

  const classes = tableStyle();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  return (
    <div
      style={{ maxWidth: "100%", justifyContent: "center", padding: "2rem" }}
    >
      <Grid xs={12}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${
                tableContainerRef.current.getBoundingClientRect().width
              }px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
          borderTop: rowPin ? "0.5px solid lightgrey" : "",
          maxHeight: "85vh",
        }}
        id="myTableContainer-Second"
        ref={tableContainerRef}
      >
        {/* {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null} */}
        <Table ref={tableRef} data-test={props.name} id={props.dataID}>
          <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
            <TableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={{
                  minWidth: 150,
                  whiteSpace: "nowrap",
                  background: "white",
                }}
              >
                {""}
              </TableCell>
              {props.columns.map((col, index) => (
                <TableCell
                  colSpan={3}
                  style={
                    index % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
            <StyledTableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={{
                  // outline: "lightgrey solid 1px",
                  // outlineOffset: "-1.5px",
                  background: "white",
                }}
              >
                {/* Team / AE */}
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "2.5px" }}>Team / AE</div>

                  <CustomWidthTooltip
                    title={"Expand All"}
                    placement="top-start"
                    style={{ marginTop: "13rem" }}
                  >
                    <KeyboardArrowDownIcon
                      onClick={() => globalExpandData(true)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                  <CustomWidthTooltip
                    title={"Collapse All"}
                    placement="top-start"
                  >
                    <KeyboardArrowUpIcon
                      onClick={() => globalExpandData(false)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                </div>
              </TableCell>
              {props.columns.map((col, idx) =>
                ["Goal", "Actual", "Gap"].map((item) => (
                  <TableCell
                    align="center"
                    style={{ background: "white" }}
                    className={classes.colHeader}
                  >
                    {item === "Goal"
                      ? `Goal (${
                          props.quartersCoverageRatioMap[
                            props.fiscalQuartersIdxMap[col]
                          ]
                        }x)`
                      : item}
                  </TableCell>
                ))
              )}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow
              key={12345}
              className={classes.fontBold}
              style={{ background: "#dae3f3" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : `${classes.colHeader}`
                }
                style={{
                  // outline: "lightgrey solid 1px",
                  // outlineOffset: "-1.5px",
                  background: "white",
                }}

                // onClick={() => handleExpandClick(i)}
              >
                Company{" "}
              </TableCell>
              {props.columns.map((col) => {
                const compData = props.data.companyData.filter(
                  (f) => f.qtr === col
                );
                const goal =
                  compData.length > 0
                    ? [null, undefined, ""].includes(
                        compData.map((a) => a.goal).reduce((a, b) => a + b, 0)
                      )
                      ? "-"
                      : countOrACVFormatter(
                          compData
                            .map((a) => a.goal)
                            .reduce((a, b) => a + b, 0),
                          true
                        )
                    : "-";
                const acv =
                  compData.length > 0
                    ? [null, undefined, ""].includes(
                        compData.map((a) => a.actual).reduce((a, b) => a + b, 0)
                      )
                      ? "-"
                      : countOrACVFormatter(
                          compData
                            .map((a) => a.actual)
                            .reduce((a, b) => a + b, 0),
                          true
                        )
                    : "-";
                let gap =
                  compData.length > 0
                    ? [null, undefined, ""].includes(compData[0].gap)
                      ? "-"
                      : countOrACVFormatter(
                          compData.map((a) => a.gap).reduce((a, b) => a + b, 0),
                          true
                        )
                    : "-";
                return (
                  <>
                    <TableCell
                      align="right"
                      // className={classes.colHeader}
                      className={classes.drilldownCell}
                      style={{
                        // outline: "lightgrey solid 1px",
                        // outlineOffset: "-1.5px",
                        background: "white",
                      }}

                      // onClick={() => handleExpandClick(i)}
                    >
                      {goal}{" "}
                    </TableCell>
                    <TableCell
                      align="right"
                      // className={classes.colHeader}
                      className={classes.drilldownCell}
                      style={{
                        // outline: "lightgrey solid 1px",
                        // outlineOffset: "-1.5px",
                        background: "white",
                      }}

                      // onClick={() => handleExpandClick(i)}
                    >
                      {acv}{" "}
                    </TableCell>
                    <TableCell
                      align="right"
                      // className={classes.colHeader}
                      className={classes.drilldownCell}
                      style={{
                        // outline: "lightgrey solid 1px",
                        // outlineOffset: "-1.5px",
                        background: "white",
                      }}

                      // onClick={() => handleExpandClick(i)}
                    >
                      {gap}{" "}
                    </TableCell>
                  </>
                );
              })}
            </StyledTableRow>
            {uniqueTeams.map((team, i) => {
              globalExpand.push(i);
              const teamData = props.data.data.filter((it) => it.Team === team);

              const repData = props.data.repData.filter(
                (it) => it.Team === team
              );

              const reps = repData
                .map((f) => f.Owner_Name)
                .filter(getUniqueValues)
                .sort();

              return (
                <>
                  {" "}
                  <StyledTableRow
                    key={i}
                    className={classes.fontBold}
                    style={{ background: "#dae3f3" }}
                  >
                    <TableCell
                      // className={classes.colHeader}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.colHeader}`
                          : `${classes.colHeader}`
                      }
                      style={
                        isSticky && countinc % 2 === 1
                          ? { background: "#dae3f3" }
                          : { background: "#dae3f3" }
                      }
                      onClick={() => handleExpandClick(i)}
                    >
                      {/* {team} */}
                      <div style={{ display: "flex" }}>
                        {team !== "Grand Total" ? (
                          expandedRows.includes(i) ? (
                            <KeyboardArrowUpIcon style={{ color: "gray" }} />
                          ) : (
                            <KeyboardArrowDownIcon style={{ color: "gray" }} />
                          )
                        ) : null}
                        <div style={{ marginTop: "3px" }}>{team}</div>
                      </div>
                    </TableCell>
                    {props.columns.map((col) => {
                      const data = teamData.filter((f) => f.qtr === col);
                      const goal = [null, undefined, ""].includes(data[0].goal)
                        ? "-"
                        : countOrACVFormatter(data[0].goal, true);
                      const acv = [null, undefined, ""].includes(data[0].actual)
                        ? "-"
                        : countOrACVFormatter(data[0].actual, true);
                      let gap = [null, undefined, ""].includes(data[0].gap)
                        ? "-"
                        : countOrACVFormatter(data[0].gap, true);

                      return (
                        <>
                          <TableCell
                            align="right"
                            // className={classes.colHeader}
                            className={classes.drilldownCell}
                            style={{ background: "#dae3f3" }}
                          >
                            {goal}
                          </TableCell>
                          <TableCell
                            align="right"
                            // className={classes.colHeader}
                            className={classes.drilldownCell}
                            style={{ background: "#dae3f3" }}
                          >
                            {acv}
                          </TableCell>
                          <TableCell
                            align="right"
                            // className={classes.colHeader}
                            className={classes.drilldownCell}
                            style={{ background: "#dae3f3" }}
                          >
                            {gap}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                  {expandedRows.includes(i) &&
                    reps.map((rep, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            // style={
                            //   isSticky && countinc % 2 === 1
                            //     ? { background: "#f5f5f5", paddingLeft: "25px" }
                            //     : { paddingLeft: "25px" }
                            // }
                            style={
                              isSticky && countinc % 2 === 1
                                ? {
                                    background: "#f5f5f5",
                                    paddingLeft: "25px",
                                    whiteSpace: "nowrap",
                                  }
                                : { paddingLeft: "25px", whiteSpace: "nowrap" }
                            }
                          >
                            {rep}
                          </TableCell>
                          {props.columns.map((col) => {
                            const data = repData.filter(
                              (f) => f.qtr === col && f.Owner_Name === rep
                            );
                            let goal = "-";
                            let acv = "-";
                            let gap = "-";
                            if (data.length > 0) {
                              goal = [null, undefined, ""].includes(
                                data[0].goal
                              )
                                ? "-"
                                : countOrACVFormatter(data[0].goal, true);
                              acv = [null, undefined, ""].includes(
                                data[0].actual
                              )
                                ? "-"
                                : countOrACVFormatter(data[0].actual, true);
                              gap = [null, undefined, ""].includes(data[0].gap)
                                ? "-"
                                : countOrACVFormatter(data[0].gap, true);
                            }

                            return (
                              <>
                                <TableCell
                                  align="right"
                                  // className={classes.colHeader}
                                  className={classes.drilldownCell}
                                >
                                  {goal}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  // className={classes.colHeader}
                                  className={classes.drilldownCell}
                                >
                                  {acv}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  // className={classes.colHeader}
                                  className={classes.drilldownCell}
                                >
                                  {gap}
                                </TableCell>
                              </>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DataTable;

//   <TableBody>
//               {props.teamNames.map((team, i) => {
//                 globalExpand.push(i);
//                 countinc = countinc + 1;
//                 const teamData = props.data.filter(
//                   (item) => item.Owner_Name === "team" && item.team === team
//                 );
//                 const repData = props.data.filter((item) => item.team === team);
//                 const reps = props.data
//                   .filter(
//                     (item) =>
//                       item.team === team &&
//                       !["team", "total"].includes(item.Owner_Name)
//                   )
//                   .map((item) => item.Owner_Name)
//                   .filter(getUniqueValues)
//                   .sort();
//                 let teamThreshold = thresholds.filter(
//                   (item) => item.Team === team
//                 );
//                 teamThreshold =
//                   teamThreshold.length >= 1
//                     ? ![null, undefined, ""].includes(
//                         teamThreshold[0]["Threshold"]
//                       )
//                       ? teamThreshold[0]["Threshold"]
//                       : undefined
//                     : undefined;
//                 return (
//                   <>
//                     {" "}
//                     <StyledTableRow
//                       key={i}
//                       className={classes.fontBold}
//                       style={{ background: "#dae3f3" }}
//                     >
//                       <TableCell
//                         // className={classes.colHeader}
//                         className={
//                           isSticky
//                             ? `${classes.sticky} ${classes.colHeader}`
//                             : `${classes.colHeader}`
//                         }
//                         style={
//                           isSticky && countinc % 2 === 1
//                             ? { background: "#dae3f3" }
//                             : { background: "#dae3f3" }
//                         }
//                         onClick={() => handleExpandClick(i)}
//                       >
//                         {/* {team} */}
//                         <div style={{ display: "flex" }}>
//                           {team !== "Grand Total" ? (
//                             expandedRows.includes(i) ? (
//                               <KeyboardArrowUpIcon style={{ color: "gray" }} />
//                             ) : (
//                               <KeyboardArrowDownIcon style={{ color: "gray" }} />
//                             )
//                           ) : null}
//                           <div style={{ marginTop: "3px" }}>{team}</div>
//                         </div>
//                       </TableCell>
//                       <TableCell align="right" className={classes.colHeader}>
//                         {teamThreshold !== undefined
//                           ? countOrACVFormatter(teamThreshold, true)
//                           : "-"}
//                       </TableCell>
//                       {props.columns.map((col) => {
//                         const cellData = teamData.filter(
//                           (item) => item[props.timeFrameEnding] === col
//                         )[0];
//                         var metaData = [
//                           {
//                             label: "Avg. deal size",
//                             type: "ACV",
//                             value: Math.round(
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Average_Acv"
//                                     : `trailing${cellData.weekEnding}${props.avgCol3}`
//                                 }`
//                               ]
//                             ),
//                           },
//                           {
//                             label: "# of Opps",
//                             type: "count",
//                             value:
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Number_of_Opps"
//                                     : `trailing${cellData.weekEnding}WeeksAverageCount`
//                                 }`
//                               ],
//                           },
//                           {
//                             label: "ACV",
//                             type: "ACV",
//                             value: Math.round(
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Acv_at_Qualfied"
//                                     : `trailing${cellData.weekEnding}${props.avgCol2}`
//                                 }`
//                               ]
//                             ),
//                           },
//                           {
//                             label: "week Ending",
//                             value:
//                               cellData.weekEnding === "Twelve"
//                                 ? 12
//                                 : cellData.weekEnding === "Eight"
//                                 ? 8
//                                 : 4,
//                           },
//                         ];
//                         return props.isColDate ? (
//                           <>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {cellData === undefined
//                                 ? 0
//                                 : cellData[`Number_of_Opps`]}
//                             </TableCell>
//                             <TableCell
//                               style={
//                                 ![undefined, 0].includes(teamThreshold)
//                                   ? teamThreshold <= cellData[props.acvCol]
//                                     ? { backgroundColor: "#e2efda", minWidth: 90 }
//                                     : { minWidth: 90 }
//                                   : { minWidth: 90 }
//                               }
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[props.acvCol],
//                                 true
//                               )}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[props.avgCol],
//                                 true
//                               )}
//                             </TableCell>
//                           </>
//                         ) : (
//                           <>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {cellData === undefined
//                                 ? 0
//                                 : cellData[
//                                     `trailing${cellData.weekEnding}WeeksAverageCount`
//                                   ].toFixed(1)}
//                             </TableCell>
//                             <TableCell
//                               style={
//                                 ![undefined, 0].includes(teamThreshold)
//                                   ? teamThreshold <=
//                                     cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol2}`
//                                     ]
//                                     ? { backgroundColor: "#e2efda", minWidth: 90 }
//                                     : { minWidth: 90 }
//                                   : { minWidth: 90 }
//                               }
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol2}`
//                                     ],
//                                 true
//                               )}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol3}`
//                                     ],
//                                 true
//                               )}
//                             </TableCell>
//                           </>
//                         );
//                       })}
//                     </StyledTableRow>
//                     {expandedRows.includes(i) &&
//                       reps.map((rep, i) => {
//                         countinc = countinc + 1;
//                         const repsData = repData.filter(
//                           (item) => item.Owner_Name === rep
//                         );
//                         let repThreshold = thresholds.filter(
//                           (item) => item.Team === rep
//                         );
//                         repThreshold =
//                           repThreshold.length >= 1
//                             ? ![null, undefined, ""].includes(
//                                 repThreshold[0]["Threshold"]
//                               )
//                               ? repThreshold[0]["Threshold"]
//                               : undefined
//                             : undefined;
//                         console.log(repThreshold);
//                         return (
//                           <StyledTableRow key={i}>
//                             <TableCell
//                               // className={`${classes.dataCell} ${classes.indentName}`}
//                               className={
//                                 isSticky
//                                   ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
//                                   : `${classes.dataCell} ${classes.indentName}`
//                               }
//                               // style={
//                               //   isSticky && countinc % 2 === 1
//                               //     ? { background: "#f5f5f5", paddingLeft: "25px" }
//                               //     : { paddingLeft: "25px" }
//                               // }
//                               style={
//                                 isSticky && countinc % 2 === 1
//                                   ? {
//                                       background: "#f5f5f5",
//                                       paddingLeft: "25px",
//                                       whiteSpace: "nowrap",
//                                     }
//                                   : { paddingLeft: "25px", whiteSpace: "nowrap" }
//                               }
//                             >
//                               {rep}
//                             </TableCell>
//                             <TableCell
//                               align="right"
//                               className={classes.colHeader}
//                             >
//                               {repThreshold !== undefined
//                                 ? countOrACVFormatter(repThreshold, true)
//                                 : "-"}
//                             </TableCell>
//                             {props.columns.map((col) => {
//                               const cellData = repsData.filter(
//                                 (item) => item[props.timeFrameEnding] === col
//                               )[0];
//                               var metaData = [
//                                 {
//                                   label: "Avg. deal size",
//                                   type: "ACV",
//                                   value: Math.round(
//                                     cellData[
//                                       `${
//                                         props.isColDate
//                                           ? "Average_Acv"
//                                           : `trailing${cellData.weekEnding}${props.avgCol3}`
//                                       }`
//                                     ]
//                                   ),
//                                 },
//                                 {
//                                   label: "# of Opps",
//                                   type: "count",
//                                   value:
//                                     cellData[
//                                       `${
//                                         props.isColDate
//                                           ? "Number_of_Opps"
//                                           : `trailing${cellData.weekEnding}WeeksAverageCount`
//                                       }`
//                                     ],
//                                 },
//                                 {
//                                   label: "ACV",
//                                   type: "ACV",
//                                   value: Math.round(
//                                     cellData[
//                                       `${
//                                         props.isColDate
//                                           ? "Acv_at_Qualfied"
//                                           : `trailing${cellData.weekEnding}${props.avgCol2}`
//                                       }`
//                                     ]
//                                   ),
//                                 },
//                                 {
//                                   label: "week Ending",
//                                   value:
//                                     cellData.weekEnding === "Twelve"
//                                       ? 12
//                                       : cellData.weekEnding === "Eight"
//                                       ? 8
//                                       : 4,
//                                 },
//                               ];
//                               return props.isColDate ? (
//                                 <>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         col,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {cellData === undefined
//                                       ? 0
//                                       : cellData[`Number_of_Opps`]}
//                                   </TableCell>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         col,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     style={
//                                       ![undefined, 0].includes(repThreshold)
//                                         ? repThreshold <= cellData[props.acvCol]
//                                           ? {
//                                               backgroundColor: "#e2efda",
//                                               minWidth: 90,
//                                             }
//                                           : { minWidth: 90 }
//                                         : { minWidth: 90 }
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {countOrACVFormatter(
//                                       cellData === undefined
//                                         ? 0
//                                         : cellData[props.acvCol],
//                                       true
//                                     )}
//                                   </TableCell>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         col,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {countOrACVFormatter(
//                                       cellData === undefined
//                                         ? 0
//                                         : cellData[props.avgCol],
//                                       true
//                                     )}
//                                   </TableCell>
//                                 </>
//                               ) : (
//                                 <>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         cellData.weekEnding,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {cellData === undefined
//                                       ? 0
//                                       : cellData[
//                                           `trailing${cellData.weekEnding}WeeksAverageCount`
//                                         ].toFixed(1)}
//                                   </TableCell>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         cellData.weekEnding,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     style={
//                                       ![undefined, 0].includes(repThreshold)
//                                         ? repThreshold <=
//                                           cellData[
//                                             `trailing${cellData.weekEnding}${props.avgCol2}`
//                                           ]
//                                           ? {
//                                               backgroundColor: "#e2efda",
//                                               minWidth: 90,
//                                             }
//                                           : { minWidth: 90 }
//                                         : { minWidth: 90 }
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {countOrACVFormatter(
//                                       cellData === undefined
//                                         ? 0
//                                         : cellData[
//                                             `trailing${cellData.weekEnding}${props.avgCol2}`
//                                           ],
//                                       true
//                                     )}
//                                   </TableCell>
//                                   <TableCell
//                                     onClick={() =>
//                                       handleDrilldown(
//                                         [rep],
//                                         cellData.weekEnding,
//                                         props.isAverage,
//                                         props.isAverage ? metaData : null
//                                       )
//                                     }
//                                     align="right"
//                                     className={classes.drilldownCell}
//                                   >
//                                     {countOrACVFormatter(
//                                       cellData === undefined
//                                         ? 0
//                                         : cellData[
//                                             `trailing${cellData.weekEnding}${props.avgCol3}`
//                                           ],
//                                       true
//                                     )}
//                                   </TableCell>
//                                 </>
//                               );
//                             })}
//                           </StyledTableRow>
//                         );
//                       })}
//                   </>
//                 );
//               })}
//               {props.data
//                 .filter((item) => item.Owner_Name === "total")
//                 .map((item) => item.Owner_Name)
//                 .filter(getUniqueValues)
//                 .map((item, i) => {
//                   const totalData = props.data.filter(
//                     (item) => item.Owner_Name === "total"
//                   );
//                   let totalThreshold = thresholds.filter(
//                     (item) => item.Team === "Company"
//                   );
//                   totalThreshold =
//                     totalThreshold.length >= 1
//                       ? ![null, undefined, ""].includes(
//                           totalThreshold[0]["Threshold"]
//                         )
//                         ? totalThreshold[0]["Threshold"]
//                         : undefined
//                       : undefined;
//                   //console.log(totalData)
//                   return (
//                     <StyledTableRow
//                       key={i}
//                       className={classes.fontBold}
//                       style={{ background: "#dae3f3" }}
//                     >
//                       <TableCell
//                         // className={classes.colHeader}
//                         className={
//                           isSticky
//                             ? `${classes.sticky} ${classes.colHeader}`
//                             : classes.colHeader
//                         }
//                         style={
//                           isSticky && (countinc + 1) % 2 === 1
//                             ? { background: "#dae3f3", paddingLeft: "25px" }
//                             : { paddingLeft: "25px", background: "#dae3f3" }
//                         }
//                       >
//                         Total
//                       </TableCell>
//                       <TableCell align="right" className={classes.colHeader}>
//                         {totalThreshold !== undefined
//                           ? countOrACVFormatter(totalThreshold, true)
//                           : "-"}
//                       </TableCell>
//                       {props.columns.map((col) => {
//                         const cellData = totalData.filter(
//                           (item) => item[props.timeFrameEnding] === col
//                         )[0];
//                         //const reps=props.data.filter(item=>item.Owner_Name !='team' || item.Owner_Name !='total')
//                         const reps = props.data
//                           .filter(
//                             (f) =>
//                               f.Owner_Name !== "total" && f.Owner_Name !== "team"
//                           )
//                           .map((item) => item.Owner_Name)
//                           .filter(getUniqueValues);
//                         //console.log(reps)
//                         var metaData = [
//                           {
//                             label: "Avg. deal size",
//                             type: "ACV",
//                             value: Math.round(
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Average_Acv"
//                                     : `trailing${cellData.weekEnding}${props.avgCol3}`
//                                 }`
//                               ]
//                             ),
//                           },
//                           {
//                             label: "# of Opps",
//                             type: "count",
//                             value:
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Number_of_Opps"
//                                     : `trailing${cellData.weekEnding}WeeksAverageCount`
//                                 }`
//                               ],
//                           },
//                           {
//                             label: "ACV",
//                             type: "ACV",
//                             value: Math.round(
//                               cellData[
//                                 `${
//                                   props.isColDate
//                                     ? "Acv_at_Qualfied"
//                                     : `trailing${cellData.weekEnding}${props.avgCol2}`
//                                 }`
//                               ]
//                             ),
//                           },
//                           {
//                             label: "week Ending",
//                             value:
//                               cellData.weekEnding === "Twelve"
//                                 ? 12
//                                 : cellData.weekEnding === "Eight"
//                                 ? 8
//                                 : 4,
//                           },
//                         ];

//                         return props.isColDate ? (
//                           <>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {cellData === undefined
//                                 ? 0
//                                 : cellData[`Number_of_Opps`]}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               style={
//                                 ![undefined, 0].includes(totalThreshold)
//                                   ? totalThreshold <= cellData[props.acvCol]
//                                     ? { backgroundColor: "#e2efda", minWidth: 90 }
//                                     : { minWidth: 90 }
//                                   : { minWidth: 90 }
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[props.acvCol],
//                                 true
//                               )}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   col,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[props.avgCol],
//                                 true
//                               )}
//                             </TableCell>
//                           </>
//                         ) : (
//                           <>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {cellData === undefined
//                                 ? 0
//                                 : cellData[
//                                     `trailing${cellData.weekEnding}WeeksAverageCount`
//                                   ].toFixed(1)}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               style={
//                                 ![undefined, 0].includes(totalThreshold)
//                                   ? totalThreshold <=
//                                     cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol2}`
//                                     ]
//                                     ? { backgroundColor: "#e2efda", minWidth: 90 }
//                                     : { minWidth: 90 }
//                                   : { minWidth: 90 }
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol2}`
//                                     ],
//                                 true
//                               )}
//                             </TableCell>
//                             <TableCell
//                               onClick={() =>
//                                 handleDrilldown(
//                                   reps,
//                                   cellData.weekEnding,
//                                   props.isAverage,
//                                   props.isAverage ? metaData : null
//                                 )
//                               }
//                               align="right"
//                               className={classes.drilldownCell}
//                             >
//                               {countOrACVFormatter(
//                                 cellData === undefined
//                                   ? 0
//                                   : cellData[
//                                       `trailing${cellData.weekEnding}${props.avgCol3}`
//                                     ],
//                                 true
//                               )}
//                             </TableCell>
//                           </>
//                         );
//                       })}
//                     </StyledTableRow>
//                   );
//                 })}
//             </TableBody>
