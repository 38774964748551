import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  TextField,
  withStyles,
} from "@material-ui/core/";
import HomeIcon from "@material-ui/icons/Home";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ButtonBase,
} from "@material-ui/core";
import Logo from "../components/Logo";
import MenuIcon from "@material-ui/icons/Menu";
import MessageIcon from "@material-ui/icons/Message";
import AIWindow from "../components/ConversationalAI/AIWindowV2";
import { appService } from "../App/app.service";
import { connect } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import StarsIcon from "@material-ui/icons/Stars";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import DescriptionIcon from "@material-ui/icons/Description";

const drawerWidth = 240;
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: "none",
    fontSize: "1rem",
    color: "black",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});
const CustomToolTip = withStyles({
  tooltip: {
    fontSize: "1rem",
  },
})(Tooltip);
const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2rem",
      },
    },
  },
  root: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    /* transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
},
appBarShift: {
    [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    } */
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    //[theme.breakpoints.down('xs')]: {
    position: "absolute",
    //}
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "visible",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "visible",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      paddingLeft: 88,
    },
  },
  avatar: {
    textTransform: "none",
    height: 64,
    display: "flex",
    flexDirection: "column",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover, &$focusVisible": {
      background: "rgba(0, 0, 0, 0.04)",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  menuPaper: {
    marginTop: "52px",
    width: "20%",
  },
  avatarIcon: {
    margin: "auto",
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    /* [theme.breakpoints.down('md')]: {
      //fontSize: '3.5vw',
      marginLeft: 0
    } */
  },
  logout: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  geni: {
    [theme.breakpoints.up(1280)]: {
      display: "none",
    },
  },
  resize: {
    paddingLeft: 8,
  },
  geniToolbar: {
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 40,
    [theme.breakpoints.down(1280)]: {
      display: "none",
    },
  },
  collapseButton: {
    position: "absolute",
    top: 82,
    left: 60,
    border: "1px solid",
    zIndex: 999999999999,
    background: "white",
    padding: 1,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover, &$focusVisible": {
      background: "#4472c4",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  collapseButtonShift: {
    position: "absolute",
    top: 82,
    left: 225,
    border: "1px solid",
    zIndex: 999999999999,
    background: "white",
    padding: 1,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover, &$focusVisible": {
      background: "#4472c4",
      color: "white",
    },
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getNavBarIconMapping = (text) => (
    <CustomWidthTooltip title={text} placement="right-start">
      <ListItem
        id={`navlink_${text.split(" ").join("_")}`}
        button
        key={text}
        selected={props.currentPage === text}
        onClick={() => props.changePage(text)}
      >
        <ListItemIcon>{navIconMapping[text]}</ListItemIcon>
        <ListItemText
          primary={<Typography variant="body1">{text}</Typography>}
        />
      </ListItem>
    </CustomWidthTooltip>
  );
  const navIconMapping = {
    Home: <HomeIcon />,
    "Enhanced Insights": <AssessmentIcon />,
    "Rep Performance": <AccountCircleIcon />,
    Favorites: <StarsIcon />,
    "Product Insights": <ControlPointDuplicateIcon />,
    "Customer Success":
      props.company === "C0001" ? <ThumbUpIcon /> : <ThumbUpIcon />,
    "Sales Compensation": <DescriptionIcon />,
  };
  const navMenu =
    props.company === "C0003"
      ? [
          "Home",
          "Enhanced Insights",
          "Rep Performance",
          "Customer Success",
          "Settings",
        ]
      : props.company === "C0001"
      ? [
          "Home",
          "Enhanced Insights",
          "Rep Performance",
          "Customer Success",
          "Product Insights",
          "Settings",
        ]
      : ["Home", "Enhanced Insights", "Rep Performance", "Settings"];
  return (
    <div className={classes.root}>
      <ErrorBoundary>
        {props.app.openConversationalAIWindow && <AIWindow />}
        <CssBaseline />
        <AppBar
          position="fixed"
          color="secondary"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <div className={classes.logo}>
              <Logo height="64px" width="120px" />
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <React.Fragment>
              <div className={classes.geniToolbar}>
                <IconButton
                  aria-label="open message"
                  onClick={props.toggleConversationalAIWindow}
                >
                  <MessageIcon />
                </IconButton>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="I'm Geni. Ask me a question."
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      props.addChatMessage(false, e.target.value);
                      // props.askQuestion(e.target.value);
                      props.toggleConversationalAIWindow();
                      e.target.value = "";
                    }
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  style={{
                    minWidth: 210,
                  }}
                />
              </div>
            </React.Fragment>
            {console.log(props.logo)}
            <Typography className={classes.title} variant="h1" component="h1">
              Growth Insights Platform for{" "}
              {
                <div
                  style={{
                    background: `url(https://skygeniwebapp.blob.core.windows.net/logos/${props.logo})center left / contain no-repeat`,
                    height: props.height,
                    width: props.width,
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    marginLeft: 10,
                  }}
                />
              }
            </Typography>
            <div>
              <ButtonBase
                className={classes.avatar}
                focusRipple
                onClick={handleClick}
                aria-controls="profile-menu"
                aria-haspopup="true"
                data-test="avatar"
              >
                <Avatar className={classes.avatarIcon}>
                  {props.firstName.slice(0, 1).toUpperCase() +
                    props.lastName.slice(0, 1).toUpperCase()}
                </Avatar>
                <Typography
                  variant="body1"
                  style={{
                    overflowWrap: "anywhere",
                    textAlign: "center",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  {props.firstName.slice(0, 1).toUpperCase() +
                    props.firstName.slice(1, props.firstName.length) +
                    " " +
                    props.lastName.slice(0, 1).toUpperCase()}
                </Typography>
              </ButtonBase>

              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menuPaper }}
              >
                <MenuItem
                  data-test="sign-out"
                  button
                  key={"signout"}
                  onClick={props.signout}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" data-test="sign out">
                        {"Sign Out"}
                      </Typography>
                    }
                  />
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}></div>
          <Divider />
          <List style={{ overflowX: "hidden" }}>
            {props.navItems
              .filter((text) => text !== "Settings")
              .map((text, index) => getNavBarIconMapping(text))}
            {/* {props.company === "C0002" || props.company === "C0010" ? (
              <CustomWidthTooltip
                title="Sales Compensation"
                placement="right-start"
              >
                <ListItem
                  id="navlink_Sales Comp"
                  button
                  key={"Sales Comp"}
                  selected={props.currentPage === "Sales Comp"}
                  onClick={() => props.changePage("Sales Comp")}
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        Sales Compensation
                      </Typography>
                    }
                  />
                </ListItem>
              </CustomWidthTooltip>
            ) : (
              ""
            )} */}
            <CustomWidthTooltip title="Settings" placement="right-start">
              <ListItem
                button
                id="navlink_Settings"
                key={"Settings"}
                selected={props.currentPage === "Settings"}
                onClick={() => props.changePage("Settings")}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body1">Settings</Typography>}
                />
              </ListItem>
            </CustomWidthTooltip>
            {
              <ListItem
                className={classes.logout}
                button
                onClick={props.toggleConversationalAIWindow}
              >
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body1">{"Geni"}</Typography>}
                />
              </ListItem>
            }
            {
              <CustomWidthTooltip
                title="Signout"
                placement="right-start"
                data-test="sign-out-conatiner"
              >
                <ListItem
                  className={classes.logout}
                  button
                  onClick={props.signout}
                  data-test="sign-out"
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" data-test="sign out">
                        {"Sign Out"}
                      </Typography>
                    }
                  />
                </ListItem>
              </CustomWidthTooltip>
            }
          </List>
          <IconButton
            onClick={handleDrawerOpen}
            className={clsx(classes.collapseButton, {
              [classes.collapseButtonShift]: open,
            })}
          >
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          {props.children}
        </main>
      </ErrorBoundary>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.app;

  return {
    ...state,
    navItems: user.elements.map((item) => item.navItem),
    logo: user.logo,
    height: user.logo_height,
    width: user.logo_width,
  };
};
const mapDispatchToProps = (dispatch) => ({
  toggleConversationalAIWindow: () => {
    dispatch({ type: "toggle_conversational_ai_window" });
  },
  askQuestion: (question) => {
    // dispatch({ type: "ask_question" })

    return appService.askQuestion(question).then(
      (resp) =>
        dispatch({
          type: "add_chat_message",
          botMessage: true,
          text: resp.text,
        }),
      (err) => err
    );
  },

  addChatMessage: (botMessage, text) => {
    dispatch({
      type: "add_chat_message_undocked",
      botMessage: botMessage,
      text: text,
      unProcessed: true,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
