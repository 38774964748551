import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  percentFormatter,
  countOrACVFormatterRoundToNearestN,
  countOrACVFormatterMillionNew,
  countOrACVFormatterMillion,
  countOrACVFormatterMillionFixedOne,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    minWidth: "130px",
    fontWeight: "inherit",
    fontWeight: 600,
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  fontArrow: {
    verticalAlign: "middle",
    height: "1.1rem",
    marginBottom: "0.2rem",
  },
}));
export default function EnablementTable(props) {
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  // const [parentWidth, setparentWidth] = useState();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  // useEffect(() => {
  //   // Get the width of the parent TableContainer
  //   const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
  //   setparentWidth(parentWidth);
  // }, [parentWidth]);

  const uniqueQuarters = props.data
    .map((f) => f.fiscal_quarter)
    .filter(getUniqueValues)
    .sort();
  const classes = useStyle();

  const uniqueRows = props.data.map((f) => f.Metric).filter(getUniqueValues);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CardContent
        className={classes.content}
        style={{ padding: 10, marginBottom: "1rem" }}
        align="center"
      >
        <Grid container justify="center">
          {isOverflowing ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
          <TableContainer
            className={classes.mainTable}
            id="myTableContainer-Second"
            ref={tableContainerRef}
            style={{ width: "auto" }}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              data-test={"EG-Data-Table"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              ref={tableRef}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    colSpan={1}
                    style={{ minWidth: "200px", background: "none" }}
                  ></TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return props.enablementQuarter.includes(i) ? (
                      <TableCell
                        key={i}
                        align="center"
                        style={{
                          background: "#BF8F00",
                          color: "white",
                          width: "16rem",
                        }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        <span>{"Enablement"}</span>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{ width: "16rem", background: "none" }}
                      ></TableCell>
                    );
                  })}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="left"
                    className={classes.repText}
                    colSpan={1}
                    style={{ minWidth: "200px" }}
                  >
                    {"Metric"}
                    {props.primaryLabel} <span>&#8595;</span> /{" "}
                    {"Fiscal_Quarter"} <span>&#8594;</span>
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                width: "16rem",
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                width: "16rem",
                              }
                        }
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        <span>{i}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>

                {uniqueRows.map((row, i) => {
                  // const rowData = props.data.filter(f => f.SeriesName === row)
                  // let module = ''
                  // let count = '-'
                  // let avgComp = 0
                  // let avgScore = 0
                  // if(rowData.length > 0){
                  //      module = rowData[0].ModuleName
                  //      count = [null, undefined, ''].includes(rowData[0].noOfModules) ? '-' : countOrACVFormatter(rowData[0].noOfModules)
                  //      avgComp = [null, undefined, ''].includes(rowData[0].avgCompletion) ? '0%' : `${Math.round(rowData[0].avgCompletion)}%`
                  //      avgScore = [null, undefined, ''].includes(rowData[0].avgScore) ? '0%' : Math.round(rowData[0].avgScore)
                  // }
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ minWidth: "200px" }}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((qtr) => {
                        const rowData = props.data.filter(
                          (f) => f.fiscal_quarter === qtr && f.Metric === row
                        );
                        let value = "-";

                        if (rowData.length > 0) {
                          const t = rowData[0].type;
                          value =
                            t === "acv"
                              ? countOrACVFormatter(
                                  rowData
                                    .map((f) => f.value)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                )
                              : t === "count"
                              ? countOrACVFormatter(
                                  rowData
                                    .map((f) => f.value)
                                    .reduce((a, b) => a + b, 0),
                                  false
                                )
                              : `${Math.round(
                                  rowData
                                    .map((f) => f.value)
                                    .reduce((a, b) => a + b, 0) * 100
                                )}%`;
                        }
                        return (
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            style={
                              row === "Won ACV" ? { cursor: "pointer" } : {}
                            }
                            onClick={() =>
                              row === "Won ACV" &&
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                qtr
                              )
                            }
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
