import { Grid, Table, TableBody, TableCell, TableRow,Card, CardContent } from '@material-ui/core'
import React from 'react'

const CountOpps = ({oldQuarters,newQuarters,data}) => {
  return (
    <Grid container>
        <Card>
            <CardContent>
            <Grid item>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Row Labels
                        </TableCell>
                        {
                            oldQuarters.map(quarter=><TableCell>{quarter}</TableCell>)
                        }
                    </TableRow>
                    {
                        newQuarters.map((quarter,index)=> {
                            const quarterData = data.filter(item=> item.Projected_Close_Date_New_Fiscal_Quarter === quarter)
                            return <TableRow key={index}>
                                <TableCell>
                                    {quarter}
                                </TableCell>
                                {
                                    oldQuarters.map((oldQuarter,idx)=>{
                                        const cellData = quarterData.filter(item=> item.Projected_Close_Date_Old_Fiscal_Quarter === oldQuarter)[0]
                                        return <TableCell>
                                            {cellData.count}
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
            </CardContent>
        </Card>
    </Grid>
  )
}

export default CountOpps