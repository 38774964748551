import * as d3 from "d3"
import './D3RepPerformanceBar.scss'
import { customTickFormat, stageFormattter } from '../../../util/customFunctions'
const D3RepPerformanceBar = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3RepPerformanceBar.create = (el, config) => {
  d3.selection.prototype.last = function () { return d3.select(this.nodes()[this.size() - 1]) };

  if (config.data) {
    config.data = config.data.filter(item => item.Name !== "total")
    config.barColor = '#70ad47';
    config.barHeight = 10;
    var marginLeftCalc = config.data;

    config.margin = {
      top: 20,
      right: 20,
      bottom: 60,
      left: marginLeftCalc.sort((a, b) => b['name'].length - a['name'].length)[0]['name'].length * 7
    };
    if (!config.width) {
      config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 50;
    }
    config.height = config.data.length * 30
    //const wonLabel = config.label.filter(item => item.type === "won")[0].label

    config.data = config.data.map(item => ({
      closedWon: _isNaN(parseFloat(item.FYWins.value)) ? 0 : parseFloat(item.FYWins.value),
      rep: item['name'],
      wp: item['weightedPipeline'],
      quota: item['repQuota'],
      attainment: item['repAttainment'],
      weighted: item['projectedAttainment'],
      sort: item['projectedFYWins']
    })).filter(item => item.rep !== "total")
    var data = config.data.sort((a, b) => b.sort - a.sort)


    const y = d3
      .scaleBand()
      .rangeRound([0, config.height])
      .padding(0.534482775);

    const x = d3
      .scaleLinear()
      .range([config.width, 0]);

    const xAxis = d3.axisBottom(x).tickFormat(d => d !== 0 ? `$${d3.format(".2s")(d)}`.toUpperCase() : null).ticks(8)


    const yAxis = d3.axisLeft(y);
    y.domain(data.map((d) => {
      return d.rep;
    }));

    x.domain([
      d3.max([d3.max([...data.map(d => d.closedWon)]) + d3.max([...data.map(d => d.wp)]), ...data.map(d => d.quota)]), 0
    ]);

    function make_x_gridlines() {
      return d3.axisBottom(x).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }

    var xAxisHeight = config.height
    const chart = d3
      .select(el)
      .append('svg')
      .attr('width', config.width + config.margin.left + config.margin.right)
      .attr('height', config.height + config.margin.top + config.margin.bottom)
      .append('g')
      .attr('transform', `translate(${config.margin.left},${config.margin.top})`);
    var legend = d3.select(el).append("div").attr('class', 'legend')
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + config.barHeight + ")")
      .attr("z-index", -1)
      .call(make_x_gridlines().tickSize(xAxisHeight).tickFormat(""));

    // add the Y gridlines
    // chart
    //     .append("g")
    //     .attr("class", "grid")
    //     .attr("z-index", -1)
    //     .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart.append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(0," + xAxisHeight + ")")
      .call(xAxis);

    chart.append("g")
      .attr("class", "yaxis")
      .call(yAxis);
    var bars = chart.selectAll(".bar")
      .data(data)
      .enter()
      .append("g")

    //append rects
    bars.append("rect")
      //.attr("class", "bar")
      .attr("stroke", '#00000')
      .attr("fill", "#BF9000")
      .attr("opacity", 0.3)
      .attr("y", function (d) {
        return y(d.rep) - 4;
      })
      .attr("height", (y.bandwidth() / 2.25) + 16)
      .attr("x", 0)
      // .transition()
      .attr("width", function (d) {
        return x(d.quota);
      });

    bars.append("rect")
      //.attr("class", "bar")
      .attr("stroke", '#00000')
      .attr("fill", '#A9D18E')
      .attr("opacity", 0.75)
      .attr("y", function (d) {
        return y(d.rep) - 2;
      })
      .attr("height", (y.bandwidth() / 2.25) + 12)
      .attr("x", d => x(d.closedWon))
      // .transition()
      .attr("width", function (d) {
        return x(d.wp);
      });

    bars.append("rect")
      .attr("class", "bar")
      .attr("stroke", '#00000')
      .attr("fill", '#70AD47')
      //.attr("opacity", 0.75)
      .attr("y", function (d) {
        return y(d.rep) - 2;
      })
      .attr("height", (y.bandwidth() / 2.25) + 12)
      .attr("x", 0)
      // .transition()
      .attr("width", function (d) {
        return x(d.closedWon);
      });


    //add a value label to the right of each bar
    bars.append("text")
      .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
        return y(d.rep) + y.bandwidth() / 2.25 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
        return 2;
      })
      .text(d => `${_isNaN(d.attainment) ? (0) : (Math.round(d.attainment * 100))}%`)
      .style("font-weight", 500)
      .attr("fill", 'white');

    bars.append("text")
      .attr("class", "label")
      .attr("text-anchor", d => (d.weighted * 100) + (d.attainment * 100) > 15 ? "end" : "start")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
        return y(d.rep) + y.bandwidth() / 2.25 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
        return (x(d.wp + d.closedWon));
      })
      .text(d => `${_isNaN(d.weighted) ? (0) : (Math.round(d.weighted * 100))}%`)
      .style("font-weight", 500)
    //.attr("fill", 'white');

    chart
      .append("text")
      .attr("y", xAxisHeight + 30)
      .attr("x", (config.width / 2) - 80)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
    //.text(`${(wonLabel).split("_").join(" ")} ACV`);
    var legends = legend.selectAll('div').data([{ text: `FY ${config.currentFY} Wins YTD`, color: '#70AD47' }, { text: 'Weighted Pipeline', color: '#A9D18E' }, { text: `FY ${config.currentFY} Quota`, color: '#BF9000' }]).enter().append('div').attr("class", "legend-flex")
    legends.append('div').attr("class", "legends").style('align-items', 'end')
      .style("background-color", d => d.color)

    legends

      .append('div')
      .text(d => d.text)
      // .style('text-align','center')
      .style('padding-left', '1rem')
      .style('min-width', '10rem').style('max-width', '10rem')

    legend
      .attr('width', config.width)
      .attr('height', config.height)
      .attr("class", "legend-flex")
      .style('justify-content', 'center')
      .selectAll(".legends")
      .data(['one', 'two', 'three'])
      .enter()
      .append("div")
      .attr("class", "legends")
      .style("background-color", d => 'blue')
      .html(d => `<div></div>`)
  }
}
D3RepPerformanceBar.destroy = el => {
  d3.select(el).selectAll('svg').remove();
  d3.select('.legend-flex').remove();
}
export default D3RepPerformanceBar;