import * as d3 from "d3"
import { updatedScheme10 } from "../../../util/chartColorScheme";
import { getUniqueValues } from "../../../util/customFunctions";
import './D3RepPerformanceStack.scss'
const D3RepPerformanceStack = {};
D3RepPerformanceStack.create = (el, config) => {
    function wrap(text, width) {
        text.each(function () {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                }
            }
        });
    }
    if (config.data) {
        const wonLabel = config.label !== undefined ? config.label.filter(item => item.type === "won")[0].label : null
        config.data = config.data.filter(item => item.Name !== "total").sort((a, b) => b[`${wonLabel}_ACV_%`] - a[`${wonLabel}_ACV_%`])
        config.barColor = '#70ad47';
        config.barHeight = 50;
        config.margin = {
            top: 0,
            right: 70,
            bottom: 10,
            left: 70
        };
        config.axisTicks = { qty: 5, outerSize: 0, dateFormat: "%m-%d" };
        config.barPadding = 0.2

        if (!config.width) {
            config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 100;
        }
        config.height = config.data ? 110 : 100
        var graphData;
        if (config.type === "RepPerformance") {
            var graphDataTemp = config.data.reduce(
                (obj, item) => Object.assign(obj, { [item.Name]: parseFloat(item[`${wonLabel}_ACV_%`]) * 100 }), {});
            graphDataTemp.group = "% of Closed Won ACV"
            var subGroups = config.data.map(item => item.Name)
            graphData = [
                graphDataTemp
            ]
        } else if (config.type === "Segment") {
            let uniqueStages = config.data.filter(item => item.acv !== null).sort((a, b) => b.acv - a.acv).map(item => item.Display_Name).filter(getUniqueValues).filter(item => item !== null)
            var sortedUniquestages = {}
            uniqueStages.map(item => sortedUniquestages[item] = config.data.filter(row => row.Display_Name !== null && row.acv !== null).filter(row => row.Display_Name === item).map(row => row.ELV).reduce((a, b) => a + b, 0))
            const sortable = Object.fromEntries(
                Object.entries(sortedUniquestages).sort(([, a], [, b]) => a - b).reverse()
            );
            const uniqueSortedRegions = Object.keys(sortable)
            config.data = config.data.filter(item => item.acv !== null)
            config.data = uniqueSortedRegions.map(region => ({
                Name: region,
                acv_percent: config.data.filter(item => item.Display_Name === region).map(item => item.contributionToToalPercent).reduce((a, b) => a + b, 0),
                acv: config.data.filter(item => item.Display_Name === region).map(item => item.ELV).reduce((a, b) => a + b, 0),
                contributionToToalPercent:config.data.filter(item => item.Display_Name === region).map(item => item.contributionToToalPercent).reduce((a, b) => a + b, 0)
            }))

            var graphDataTemp = config.data.reduce(
                (obj, item) => Object.assign(obj, { [item.Name]: parseFloat(item.acv_percent)}), {});
            graphDataTemp.group = "% of Closed Won ACV"
            var subGroups = config.data.map(item => item.Name)
            graphData = [
                graphDataTemp
            ]
        }
        var groups = d3.map(graphData, function (d) { return (d.group) }).keys()
        var stackedData = d3.stack()
            .keys(subGroups)
            (graphData)
        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width',  config.width + config.margin.left + config.margin.right)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
        var chart = chartContainer
            .append('g')
            .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");
        var tooltip = d3
            .select(el)
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);
        var legend = d3.select(el).append("div").attr('class', 'legend')
        var x = d3.scaleLinear()
            .domain([0, config.type === 'Segment'? Math.round(config.data.map(i=>i.contributionToToalPercent).reduce((a, b) => a + b, 0)/10) * 10 : 100])
            .range([0, config.width])
        chart.append("g")
            .attr("transform", "translate(0," + config.height + ")")
            .style("font-size", "0.85rem")
            .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(i => i + "%"));

        var y = d3.scaleBand()
            .domain(groups)
            .range([config.height, 0])
            .padding([0.2])
        var color = d3.scaleOrdinal(updatedScheme10);




console.log(stackedData,'stackedData')

        chart.append("g")
            .selectAll("g")
            // Enter in the stack data = loop key per key = group per group
            .data(stackedData)
            .enter().append("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            // enter a second time = loop subgroup per subgroup to add all rectangles
            .data(function (d) { return d; })
            .enter().append("rect")
            .attr("y", function (d) { return y(d.data.group); })
            .attr("x", function (d) { return x(d[0]); })
            // .attr("width", function (d) { return (x(d[1]) - x(d[0]/2)); })
            .attr("width", function (d) { return (x(d[1]) - x(d[0])); })
            .attr("height", 75)
            .on("mousemove", (d, i) => {
                let temp = Math.round(d[1] - d[0])
                const repName = config.data.filter(item => {
                    if (config.type === "RepPerformance") {
                        if (item[`${wonLabel}_ACV_%`] !== null)
                            return (+(item[`${wonLabel}_ACV_%`]).toFixed(5) === +((d[1] - d[0]) * 0.01).toFixed(5))
                    } else if (config.type === "Segment") {
                        if (item.acv_percent !== null)
                            return (+(item.acv_percent) === +(d[1] - d[0]))
                    }
                })[0]
                tooltip.transition().duration(200).style("opacity", 1)
                tooltip
                    .html(`
                    <div style="border-top:10px solid ${color(repName.Name)};border-radius:1rem;">
                        <div class="tooltip-box">
                            <h3 class="heading-style">${repName.Name}</h3>
                            <hr>
                            <div class="container">
                                <h4 class="result-style">
                                Contribution Percent :<span style="color:${color(repName.Name)}"> ${config.type === "RepPerformance" ? repName[`${wonLabel}_ACV_%`] !== null ? (repName[`${wonLabel}_ACV_%`] * 100).toFixed(2) : 0 : config.type === 'Segment' ?repName.acv_percent !== null ? (repName.acv_percent) : 0 : repName.acv_percent !== null ? (repName.acv_percent * 100).toFixed(2) : 0}%</span>                    
                                </h4>                                
                            </div>
                            <hr>
                            <div class="container">
                                <h3 class="heading-style">$${d3.format(",")(Math.round(repName[config.type === "RepPerformance" ? `${wonLabel}_ACV` : `acv`]))}</h3>
                            </div>
                        </div>                        
                    </div>
                    `)
                    //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
                    .style("top", d + "px")
                    //  .attr("class", "tool")
                    .style("left", d3.event.pageX + "px")
                    .style("top", d3.event.pageY - 28 + "px");

            })
            .on("mouseout", () => {
                tooltip.transition().duration(300).style("opacity", 0);
            })
        let barLabels = [...Array(stackedData.length).keys()].map(i => {

            chart.selectAll(`.bartext${i}`)
                .data(stackedData[i])
                .enter()
                .append("text")
                .attr("class", `.bartext${i}`)
                .attr("text-anchor", "middle")
                .attr("fill", "white")
                .style("font-weight", 600)
                .style("font-size", "0.85rem")

                .attr("y", function (d) { return (y(d.data.group)) + 40; })
                .attr("x", function (d) { return x((d[1] + d[0]) / 2) })
                .text(function (d) {
                    let temp = Math.round(d[1] - d[0])

                    if (temp >= 3)
                        return `${temp}%`

                });
        })
        var it = d3.select(el).node().getBoundingClientRect() !== null ? d3.select(el).node().getBoundingClientRect().width < 1100 ? 6 : 9 : 5
        // it = d3.select(el).node().getBoundingClientRect().width < 800 ? 4 : it
        var legends = legend.selectAll('div').data(config.data).enter().append('div').attr("class", "legend-flex")
        legends.append('div').attr("class", "legends").style('align-items', 'end')
            .style("background-color", d => color(d.Name))
        legends

            .append('div')
            .text(d => d.Name)
            // .style('text-align','center')
            .style('padding-left', '1rem')
            .style('min-width', d => config.type === "RepPerformance" ? '14rem' : config.type === 'Segment' ? '14rem' : '4rem').style('max-width', d => config.type === "RepPerformance" ? '14rem' : '14rem')



        legend
            .attr('width', config.width)
            .attr('height', config.height)
            .attr("class", "legend-flex")
            .style('justify-content', 'left')
            .selectAll(".legends")
            .data(config.data)
            .enter()
            .append("div")
            .attr("class", "legends")
            .style("background-color", d => color(d.Name))
            .html(d => `<div>${d.Name}</div>`)
    }

}
D3RepPerformanceStack.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
    d3.select('.tooltip').remove();
    d3.select('.legend-flex').remove();
};
export default D3RepPerformanceStack;