import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import {
  getCurrentFiscalQuarter,
  nearestCompletedFiscalQuarter,
} from "../../../util/fiscalQuarterFunctions";
import QuarterTrendsTable from "../../components/QuarterTrendsTable";

const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});
class QBROnBoard extends Component {
  filters = ["Onboarding_Fiscal_Qtr", "Account_Type"];
  state = {
    Onboarding_Fiscal_Qtr: ["2021-Q4", "2021-Q3", "2021-Q2"],
    Account_Type: ["Customer"],
    disabled: ["year", "month", "quarter"],
  };
  componentDidMount() {
    this.props.getQBROnBoardFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getCurrentFiscalQuarter();
      this.setState({
        ...prevState,
        Onboarding_Fiscal_Qtr: ["2021-Q3", "2021-Q2", "2021-Q1"],
        Account_Type: ["Customer"],
      });
    }
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeLocation = (location, data, type, quarter) => {
    console.log(location);
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.props.getQBROnBoardData(this.props.company, filters);
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Onboarding Events
          </Typography>
          <Typography variant="body1">
            Examine the QBRs completed by account segment and CARR.
          </Typography>
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Onboarding_Fiscal_Qtr.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  QBR Fiscal Quarter:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.Onboarding_Fiscal_Qtr}
                  onChange={this.handleChange}
                  label="QBR Fiscal Quarter"
                  name="Onboarding_Fiscal_Qtr"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter(
                        (y) =>
                          y.type === "QBR_Fiscal_Qtr" &&
                          y["Onboarding_Fiscal_Qtr"] !== "" &&
                          y["Onboarding_Fiscal_Qtr"] !== null
                      )
                      .map((y) => (
                        <MenuItem
                          key={y["Onboarding_Fiscal_Qtr"]}
                          value={y["Onboarding_Fiscal_Qtr"]}
                        >
                          {y["Onboarding_Fiscal_Qtr"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Account_Type.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Account Type:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.Account_Type}
                  onChange={this.handleChange}
                  label="Account Type"
                  name="Account_Type"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={"Customer"}>{"Customer"}</MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter(
                        (y) =>
                          y.type === "Account_Type" && y["Account_Type"] !== ""
                      )
                      .map((y) => (
                        <MenuItem
                          key={y["Account_Type"]}
                          value={y["Account_Type"]}
                        >
                          {y["Account_Type"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          {this.props.QBROnBoardData !== "" ? (
            <DataVisualizer
              title={`Onboarding Events`}
              type={"Account_Segment"}
              chartType="CARR"
              chartName="QBR"
              winACVMixAnalysisData={this.props.QBROnBoardData.map((item) => ({
                ...item,
                acv: item.CARR,
              }))}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"Onboarding_Fiscal_Qtr"}
              unit="acv"
              page="QBRSuccess"
              tableComponent={
                <QuarterTrendsTable
                  type="QBR"
                  data={this.props.QBROnBoardData}
                  row="Account_Segment"
                  column="Onboarding_Fiscal_Qtr"
                  countKey="count"
                  countColumnHeading="# of Onboardings"
                  currencyColumnHeading="CARR"
                />
              }
            />
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const { QBROnBoardFilters, QBROnBoardData, user } = state.app;
  return {
    funnelCompareFilters: QBROnBoardFilters,
    QBROnBoardData,
    company: user.company,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getQBROnBoardFilters: (company) => {
    dispatch({ type: "get_QBROnBoard_filters_request" });
    appService.getChartFilters("C0003.CHID00000021").then(
      (json) => {
        dispatch({ type: "get_QBROnBoard_filters_success", json });
        dispatch({ type: "get_QBROnBoard_data_request" });
        appService
          .getChartData("C0003.CHID00000021", [
            {
              name: "Onboarding_Fiscal_Qtr",
              value: ["2021-Q4", "2021-Q3", "2021-Q2"],
              string: true,
            },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_QBROnBoard_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_QBROnBoard_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_QBROnBoard_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_QBROnBoard_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_QBROnBoard_filters_failure", error });
      }
    );
  },
  getQBROnBoardData: (company, filters = []) => {
    dispatch({ type: "get_QBROnBoard_data_request" });
    appService.getChartData("C0003.CHID00000021", filters).then(
      (json) => {
        dispatch({ type: "get_QBROnBoard_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_QBROnBoard_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_QBROnBoard_data_failure", error });
      }
    );
  },
});

const connectedQBROnBoard = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QBROnBoard));
export default connectedQBROnBoard;
