import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
} from "../../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";

const useStyle = makeStyles((theme) => ({
  root: {},
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function AverageDealSizeTable(props) {
  console.log(props);
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  let acvLabel = "ACV";
  if (props.Value_Label) {
    acvLabel = props.Value_Label;
  }

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let totalindex;

  const uniqueQuarters = props.winAcvData
    .map((item) => item[props.quarter])
    .filter(getUniqueValues)
    .sort();
  let uniqueStages = props.winAcvData
    .map((item) => item[props.type])
    .filter(getUniqueValues);
  var sortedUniquestages = {};
  uniqueStages.map(
    (item) =>
      (sortedUniquestages[item] = props.winAcvData
        .filter((f) => f[props.type] === item)
        .map((i) => i.acv)
        .reduce((a, b) => a + b, 0))
  );
  const sortable = Object.fromEntries(
    Object.entries(sortedUniquestages)
      .sort(([, a], [, b]) => a - b)
      .reverse()
  );
  uniqueStages = Object.keys(sortable);
  console.log(uniqueStages);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerAVG");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        marginBottom: "1rem",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            className={classes.mainTable}
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainerAVG"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={`${getMixKey(props.type, "ACV")} avgDealSize`} //If data-test props.type contains 'ACV Range' text we need pass ACV
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {deSnake(props.quarter)}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {getMixKey(props.type)}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          # of Opps
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 90 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {/* ACV */}
                          {`${acvLabel}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          Avg. Deal Size
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70 }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 90 }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {/* ACV */}
                        {`${acvLabel}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70 }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        Avg. Deal Size
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
                {uniqueStages.map((row, i) => {
                  totalindex = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        const cellData = props.winAcvData.filter(
                          (item) =>
                            item[props.quarter] === cell &&
                            item[props.type] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `${cellData.count}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `$${d3.format(",")(Math.round(cellData.acv))}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `$${d3.format(",")(
                                    Math.round(cellData.avgAcv)
                                  )}`
                                : "-"}
                            </TableCell>
                            {/* <TableCell style={{ cursor: "pointer", fontWeight: 600 }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { row, cell }, props.type, props.quarter)} align='right' className={classes.repNumber} colSpan={1}>{cellData !== undefined ?
                                                            `${_isNaN(Math.round((cellData.avgAcv /
                                                                props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)) * 100)) ? '-' : `${(Math.round((cellData.acv /
                                                                    props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)) * 100))}%`}` : "-"
                                                        }</TableCell> */}
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row, cell: "Total" },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`${props.winAcvData
                            .filter((item) => item[props.type] === row)
                            .map((item) => item.count)
                            .reduce((a, b) => a + b, 0)}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row, cell: "Total" },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`$${d3.format(",")(
                            Math.round(
                              props.winAcvData
                                .filter((item) => item[props.type] === row)
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                          )}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row, cell: "Total" },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`$${d3.format(",")(
                            Math.round(
                              props.winAcvData
                                .filter((item) => item[props.type] === row)
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                props.winAcvData
                                  .filter((item) => item[props.type] === row)
                                  .map((item) => item.count)
                                  .reduce((a, b) => a + b, 0)
                            )
                          )}`}</TableCell>
                          {/* <TableCell style={{ cursor: "pointer", fontWeight: 600 }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { row, cell: 'Total' }, props.type, props.quarter)} align='right' className={classes.repNumberACV} colSpan={1} >{
                                                        `${_isNaN(Math.round((props.winAcvData.filter(item => item[props.type] === row).map(item => item.acv).reduce((a, b) => a + b, 0) /
                                                            props.winAcvData.map(item => item.acv).reduce((a, b) => a + b, 0)) * 100)) ? '-' : (Math.round((props.winAcvData.filter(item => item[props.type] === row).map(item => item.acv).reduce((a, b) => a + b, 0) /
                                                                props.winAcvData.map(item => item.acv).reduce((a, b) => a + b, 0)) * 100))}%`
                                                    }</TableCell> */}
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && totalindex % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const totalAcv =
                      props.clusterTotal.length >= 1
                        ? props.clusterTotal
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0)
                        : props.winAcvData
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0);
                    const totalAcvAVG =
                      props.clusterTotal.length >= 1
                        ? props.clusterTotal
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.avgAcv)
                            .reduce((a, b) => a + b, 0)
                        : props.winAcvData
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.avgAcv)
                            .reduce((a, b) => a + b, 0);
                    const totalOpps =
                      props.clusterTotal.length >= 1
                        ? props.clusterTotal
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.count)
                            .reduce((a, b) => a + b, 0)
                        : props.winAcvData
                            .filter((item) => item[props.quarter] === cell)
                            .map((item) => item.count)
                            .reduce((a, b) => a + b, 0);
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalAcv !== undefined ? `${totalOpps}` : "-"}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalAcv !== undefined
                            ? `$${d3.format(",")(Math.round(totalAcv))}`
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalAcvAVG !== undefined
                            ? `$${d3.format(",")(Math.round(totalAcvAVG))}`
                            : "-"}
                        </TableCell>
                        {/* <TableCell style={{ cursor: "pointer", fontWeight: 600 }} align='right' onClick={() => props.handleChangeLocation("drilldownOnGraph", { row: "Total", cell }, props.type, props.quarter)} align='right' className={classes.repNumber} colSpan={1}>{totalAcv !== undefined ?
                                                        `100%` : "-"
                                                    }</TableCell> */}
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: "Total" },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.clusterTotal.length >= 1
                          ? props.clusterTotal
                              .map((item) => item.count)
                              .reduce((a, b) => a + b, 0)
                          : `${props.winAcvData
                              .map((item) => item.count)
                              .reduce((a, b) => a + b, 0)}`}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: "Total" },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.clusterTotal.length >= 1
                          ? `$${d3.format(",")(
                              Math.round(
                                props.clusterTotal
                                  .map((item) => item.acv)
                                  .reduce((a, b) => a + b, 0)
                              )
                            )}`
                          : `$${d3.format(",")(
                              Math.round(
                                props.winAcvData
                                  .map((item) => item.acv)
                                  .reduce((a, b) => a + b, 0)
                              )
                            )}`}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            { row: "Total", cell: "Total" },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.clusterTotal.length >= 1
                          ? `$${d3.format(",")(
                              Math.round(
                                props.clusterTotal
                                  .map((item) => item.acv)
                                  .reduce((a, b) => a + b, 0) /
                                  props.clusterTotal
                                    .map((item) => item.count)
                                    .reduce((a, b) => a + b, 0)
                              )
                            )}`
                          : `$${d3.format(",")(
                              Math.round(
                                props.winAcvData
                                  .map((item) => item.acv)
                                  .reduce((a, b) => a + b, 0) /
                                  props.winAcvData
                                    .map((item) => item.count)
                                    .reduce((a, b) => a + b, 0)
                              )
                            )}`}
                      </TableCell>
                      {/* <TableCell style={{ cursor: "pointer", fontWeight: 600 }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { row: "Total", cell: "Total" }, props.type, props.quarter)} align='right' className={classes.repNumberACV} colSpan={1} >{
                                                    `100%`
                                                }</TableCell> */}
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
