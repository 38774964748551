import * as d3 from 'd3'
import * as d3Array from "d3-array"
import { updatedScheme10 } from '../../util/chartColorScheme'
import "./D3PBIMekkoChart.scss"
var D3MekkoChart = {}
D3MekkoChart.create = (el, config) => {
    if (config.data.length >=1) {
        config.margin = {
            top: 70,
            right: config.data
                ? d3.select(el).node().getBoundingClientRect().width < 400
                    ? 30
                    : 10
                : 10,
            bottom: 20,
            left: config.data
                ? d3.select(el).node().getBoundingClientRect().width < 400
                    ? 100
                    : 100
                : 10,
        };
        if (!config.width) {
            config.width = d3.select(el).node()
                ? d3.select(el).node().getBoundingClientRect().width -
                config.margin.left -
                config.margin.right
                : 100;
        }
        config.height = config.data ? 700 : 100;
        const treemap = data => d3.treemap()
            .round(true)
            .tile(d3.treemapSliceDice)
            .size([
                config.width - config.margin.left - config.margin.right,
                config.height - config.margin.top - config.margin.bottom
            ])
            (d3.hierarchy(d3Array.group(data, d => d.x, d => d.y)).sum(d => d.value))
            .each(d => {
                d.x0 += config.margin.left;
                d.x1 += config.margin.left;
                d.y0 += config.margin.top;
                d.y1 += config.margin.top;
            })
        const format = d => { return d.toLocaleString() }
        const color = d3.scaleOrdinal(d3.schemeCategory10).domain(config.data.map(d => d.y))
        const root = treemap(config.data)

        var x = d3.scaleLinear()
            .range([0, config.width - (config.margin.left + config.margin.right)]);

        var y = d3.scaleLinear()
            .range([0, config.height - (config.margin.top + config.margin.bottom)]);



        var z = d3.scaleOrdinal(updatedScheme10);


        var n = d3.format(",d"),
            p = d3.format("%");
        var chartContainer = d3.select(el)
            .append("svg")
            .attr("width", config.width + config.margin.left + config.margin.right)
            .attr("height", config.height + config.margin.top + config.margin.bottom);
        var chart = chartContainer.append("g")
            .attr(
                "transform",
                "translate(" + config.margin.left + "," + config.margin.top + ")"
            );
        var tooltip = d3.select(el)
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);
        var legend = d3.select(el).append("div").attr('class', 'legend')
        var offset = 0;

        // Nest values by segment. We assume each segment+market is unique.
        var segments = d3.nest()
            .key(d => d.y)
            .entries(config.data);

        // Compute the total sum, the per-segment sum, and the per-market offset.
        // You can use reduce rather than reduceRight to reverse the ordering.
        // We also record a reference to the parent segment for each market.
        var sum = segments.reduce((v, p) => {
            return (p.offset = v) + (p.sum = p.values.reduceRight((v, d) => {
                d.parent = p;
                return (d.offset = v) + d.value;
            }, 0));
        }, 0);

        // Add x-axis ticks.
        var xtick = chart.selectAll(".x")
            .data(x.ticks(10))
            .enter().append("g")
            .attr("class", "x")
            .attr("transform", d => "translate(" + x(d) + "," + y(1) + ")");

        xtick.append("line")
            .attr("y2", 6)
            .style("stroke", "#000");

        xtick.append("text")
            .attr("y", 8)
            .attr("text-anchor", "middle")
            .attr("dy", ".71em")
            .text(p => `${Math.round(p * 100)}%`);

        // Add y-axis ticks.
        var ytick = chart.selectAll(".y")
            .data(y.ticks(10))
            .enter().append("g")
            .attr("class", "y")
            .attr("transform", d => "translate(0," + y(1 - d) + ")");

        ytick.append("line")
            .attr("x1", -6)
            .style("stroke", "#000");

        ytick.append("text")
            .attr("x", -8)
            .attr("text-anchor", "end")
            .attr("dy", ".35em")
            .text(p => `${Math.round(p * 100)}%`);

        // Add a group for each segment.
        var segmentsContainer = chart.selectAll(".segment")
            .data(segments)
            .enter().append("g")
            .attr("class", "segment")
            .attr("xlink:title", d => d.key)
            .attr("transform", d => "translate(" + x(d.offset / sum) + ")")
        chart.selectAll(".label-heading")
            .data(segments)
            .enter()
            .append("text")
            .style('margin', "2px")
            .attr("text-anchor", "middle")
            .attr("class", "label-heading")
            .attr("y", -30)
            .attr("x", d => x(d.offset / sum) + (x(d.sum / sum) / 2) - 5)
            //.text(d => { return Math.round(x(d.sum / sum) / 2) > 30 ?  d.key : null })
            .text(d => { return Math.round(x(d.sum / sum) / 2) > 30 ? Math.round(x(d.sum / sum) / 2) < 100 ? d.key.length > 12 ? d.key.substring(0,12)+'...' : d.key : d.key  : null })

        chart.selectAll(".label-percent")
            .data(segments)
            .enter()
            .append("text")
            .style('margin', "2px")
            .attr("text-anchor", "middle")
            .attr("class", "label-percent")
            .attr("y", -10)
            .attr("x", d => x(d.offset / sum) + (x(d.sum / sum) / 2) - 5)
            .text(d => { return Math.round(x(d.sum / sum) / 2) > 30 ? `(${Math.round((d.sum / sum) * 100)}%)` : null })
        // Add a rect for each market.

        var widthSum = 0;
        var markets = segmentsContainer.selectAll(".market")
            .data(d => d.values)
            .enter().append("a")
            .attr("class", "market")
            .attr("xlink:title", d => d.x + " " + d.parent.key + ": " + "$" + n(d.value))
            .append("rect")
            .style("stroke", "#fff")
            .style("stroke-width", "1px")
            .style("cursor","pointer")
            .attr("y", d => y(d.offset / d.parent.sum))
            .attr("height", d => y(d.value / d.parent.sum))
            .attr("width", d => x(d.parent.sum / sum))
            .style("fill", d => z(d.x))
            //.on("mouseover", d => console.log(d))
            .on("click", d => {
					config.handleChangeLocation('drilldownOnGraph',config.type ==='generic' ? [d.x] : d.x, config.type ==='generic' ? [d.y] : d.y)
			})


        var verticalLegends=config.labels.vertical
        var legend = d3.select(el).append("div").attr('class', 'legend')
        var legends = legend.selectAll('div').data(config.labels.vertical).enter().append('div').attr("class", "legend-flex").style('margin-top', -20)
        legends.append('div').attr("class", "legends").style('align-items', 'end')
            .style("background-color", d => z(d))
        legends

            .append('div')
            .text(d => d)
            // .style('text-align','center')
            .style('padding-left', '0.5rem')
            .style('min-width', verticalLegends.length > 4 ? '7rem' : '10rem').style('max-width', verticalLegends.length > 4 ? '7rem' : '10rem')

        legend
            .attr('width', config.width)
            .attr('height', config.height)
            .attr("class", "legend-flex-mekko")
            .style('justify-content', 'center')
            .selectAll(".legends")
            .data(config.labels.vertical)
            .enter()
            .append("div")
            .attr("class", "legends")
            .style("background-color", d => z(d))

        chart
            .append("text")
            .attr("dy", "1em")
            .attr("font-weight", "400")
            .attr("y", config.margin.left - 165)
            .attr("x", -(config.height / 2) + 20)
            .attr("transform", "rotate(-90)") // although axis is rotated, text is not
            .attr("fill", "#00000")
            .text(config.type ==='generic' ? config.yLabel !== undefined ? config.yLabel : '' : "Apps Cluster");
        chart
            .append("text")
            .attr("y", config.height - 60)
            .attr("x", (config.width / 2) - 90)
            .attr("dy", "1em")
            //.attr("class", "axis-label")
            .attr("font-weight", "400")
            .attr("fill", "#000000")
            .text(config.type ==='generic' ? config.xLabel !== undefined ? config.xLabel : '' :"Vertical");


        segments.forEach((element, e) => {
            chart.selectAll(`.bartext_${e}`)
                .data(element.values)
                .enter()
                .append("text")
                .attr("class", `.bartext_${e}`)
                .attr("text-anchor", "middle")
                .attr("fill", "white")
                .style("font-weight", 600)
                .style("font-size", "0.85rem")
                .style("cursor","pointer")
                .attr("y", (d, i) => i === 0 ? ((620 + y(d.offset / d.parent.sum)) / 2) + 5 : ((y(d.parent.values[i - 1].offset / d.parent.sum) + y(d.offset / d.parent.sum)) / 2) + 5)
                .attr("x", d => x(d.parent.offset / sum) + (x(d.parent.sum / sum) / 2))
                .text(d => y(d.value / d.parent.sum) > 20 && (x(d.parent.sum / sum) / 2) > 15 ? `${Math.round((d.value / d.parent.sum) * 100)}%` : null)
                .on("click", d => {
					config.handleChangeLocation('drilldownOnGraph',config.type ==='generic' ? [d.x] : d.x,config.type ==='generic' ? [d.y] : d.y)
			});


        })
    }
}
D3MekkoChart.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll('svg').remove();
    d3.select('.legend-flex-mekko').remove();
};
export default D3MekkoChart;