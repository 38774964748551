import { Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";

const LoadingData = () => {
  return (
    <Grid item container justifyContent="center">
      <Skeleton width={"90%"} height={150} />
    </Grid>
  );
};

export default LoadingData;
