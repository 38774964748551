export const equalizeArraySizes = (parentOpps, currentOpps) => {
  const maxLength = Math.max(parentOpps.length, currentOpps.length);

  // Extend both arrays to the maximum length by filling with nulls
  while (parentOpps.length < maxLength) {
    parentOpps.push(null);
  }
  while (currentOpps.length < maxLength) {
    currentOpps.push(null);
  }
  return { parentOpps, currentOpps };
};
/**
 *
 * @param {Object} accounts
 * @param {string} column
 * @param {"asc"|"desc"} ascending
 * @returns
 */
export function sortAccountIdsByColumn(accounts, column, ascending) {
  const isAscending = ascending === "asc";
  // Convert the object to an array of entries
  const entries = Object.entries(accounts);

  // Sort the entries based on the specified column
  entries.sort((a, b) => {
    let valueA = a[1][column];
    let valueB = b[1][column];

    if (typeof valueA === "string" && typeof valueB === "string") {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }

    if (valueA < valueB) {
      return isAscending ? -1 : 1;
    }
    if (valueA > valueB) {
      return isAscending ? 1 : -1;
    }
    return 0;
  });

  // Extract the sorted account IDs
  const sortedAccountIds = entries.map((entry) => entry[0]);

  return sortedAccountIds;
}
export function accountExpansionSummaryData(data) {
  const unqiueAccounts = Object.keys(data.accountInfo);
  const numAccounts = unqiueAccounts.length;
  const accountTotalDelta = unqiueAccounts.reduce((acc, value) => {
    return acc + data.accountInfo[value].delta;
  }, 0);
  let currentTotalOpps = 0;
  let currentTotalACV = 0;
  for (const accountId in data.currentOppsMap) {
    const numOpps = data.currentOppsMap[accountId].numOpps;
    const totalACV = data.currentOppsMap[accountId].totalACV;

    currentTotalOpps += numOpps;
    currentTotalACV += totalACV;
  }
  let parentTotalOpps = 0;
  let parentTotalACV = 0;
  for (const accountId in data.parentOppsMap) {
    const numOpps = data.parentOppsMap[accountId].numOpps;
    const totalACV = data.parentOppsMap[accountId].totalACV;

    parentTotalOpps += numOpps;
    parentTotalACV += totalACV;
  }
  return {
    account: {
      numAccounts,
      accountTotalDelta,
    },
    currentTotalOpps,
    currentTotalACV,
    parentTotalOpps,
    parentTotalACV,
  };
}
