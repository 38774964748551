import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid, CardHeader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  outer: {
    width: `90%`,
    height: "16rem",
    //paddingTop: `${(90 * 0.5625)}%`,
    margin: "0.625rem 0rem",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: "88%",
      height: "16rem",
      // paddingTop: `${35 * 0.5625}%`,
      margin: "1.25rem 1.87rem",
      // border: "1.5px solid",
      // borderColor: "blue",
    },
  },
  Border_outer: {
    width: `90%`,
    //paddingTop: `${(90 * 0.5625)}%`,
    margin: "0.625rem 0rem",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: "88%",
      height: "16rem",
      // paddingTop: `${35 * 0.5625}%`,
      margin: "1.25rem 1.87rem",
      border: "1.5px solid",
      borderColor: "rgb(68, 113, 196)",
    },
  },
  header: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      margin: "5px",
      padding: "5px 2px",
    },
  },
  inner: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: "100%",
    },
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();

  const [starred, setStarred] = useState();
  useEffect(() => {
    setStarred(props.Position !== null);
  }, [props]);
  const handleStarClick = (event) => {
    event.stopPropagation();
    const curr_val = !starred;

    if (curr_val === true) {
      // add to favourites api call
      console.log("add to favourites api call", props.chartID);
      props.addFavourite(props.dashboard_id);
      setStarred(curr_val);
    } else {
      // remove from favourites api call
      props.handleOpen({
        dashboard_id: props.dashboard_id,
        Chart_Name: props.header,
      });
    }
  };
  // console.log('Insight Card',props)
  useEffect(() => {
    if (
      props.confirmSignal &&
      props.selectedDashboard.dashboard_id === props.dashboard_id
    ) {
      console.log(
        "confirmSignal",
        props.confirmSignal,
        props.dashboard_id,
        props.selectedDashboard
      );
      const curr_val = !starred;
      setStarred(curr_val);
      props.removeFavourite(props.dashboard_id, props.navItem);
      props.cleanAfterConfirm();
    }
  }, [props.confirmSignal]);

  return (
    <div
      onClick={() =>
        props.dragdisabled
          ? props.changeLocation(props.header, props.Nav_Item)
          : ""
      }
      style={{ position: "relative", zIndex: 1 }}
    >
      <Card
        raised={true}
        className={props.dragdisabled ? classes.outer : classes.Border_outer}
      >
        <CardHeader
          style={{
            padding: 0,
            margin: 0,
            cursor: props.dragdisabled ? "pointer" : "grab",
            display: "flex",
            alignItems: "center", // Center the content vertically
          }}
          title={
            <Typography
              style={{
                cursor: props.dragdisabled ? "pointer" : "grab",
                paddingTop: 10,
                margin: "auto", // Center the content horizontally
              }}
              className={classes.header}
              variant="h2"
              component="h2"
              align="center"
            >
              {props.header}
            </Typography>
          }
          action={
            <IconButton
              style={{ padding: "0.43rem 0.31rem", margin: "0.625rem 1.25rem" }}
              onClick={(event) => handleStarClick(event)}
            >
              {starred ? (
                <StarIcon style={{ color: "#FFD700" }} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          }
        />

        <CardContent
          style={{
            width: "100%",
            cursor: props.dragdisabled ? "pointer" : "grab",
          }}
        >
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" color="textSecondary">
                {props.text}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <CardMedia
                className={classes.media}
                image={props.image}
                title={props.header}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
