import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
// import MediaCard from "../components/InsightCardV2";

import { appService } from "../App/app.service";
import EnhancedInsightRoutes from "../EnhancedInsights/EnhancedInsightRoutes";
import RepPerformanceRoutes from "../RepPerformance/RepPerformanceRoutes";
import LoadingSkeleton from "../EnhancedInsights/NewWaterFall/LoadingSkeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfifmModal from "./ConfirmModal";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import "./Favourites.css";
import FavMediaCard from "./FavoriteMediaCard";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import BackdropContainer from "../components/Backdrop";

const styles = (theme) => ({
  containerFav: {
    display: "flex",
    /* touch-action: none; */
    /* width: 63%; */
    margin: "1rem auto",
    [theme.breakpoints.down("md")]: {
      padding: "0rem 3.5rem",
    },
  },
  // dashOuter: {
  //   display: "flex",
  //   flexGrow: 1,
  //   margin: "auto",
  // },
  // dashInner: {
  //   display: "flex",
  //   flexGrow: 1,
  //   flexDirection: "column",
  // },
  // graph1: {
  //   flexGrow: 1,
  //   height: 250,
  //   margin: 10,
  // },
});

const Favourites = (props) => {
  const oldArr = props.charts;
  const [drag, setDrag] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const [charts, setCharts] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [confirmSignal, setConfirmSignal] = React.useState(false);
  const [selectedDashboard, setSelectedDashboard] = React.useState({});

  const cleanAfterConfirm = () => {
    setConfirmSignal(false);
    setSelectedDashboard({});
  };

  useEffect(() => {
    setCharts(props.charts);
  }, [props.charts]);

  const handleOpen = ({ dashboard_id, Chart_Name }) => {
    setSelectedDashboard({ dashboard_id, Chart_Name });
    setOpen(true);
  };
  const handleConfirmClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setConfirmSignal(false);
    setOpen(false);
  };
  const history = useHistory();
  useEffect(() => {
    props.getUser();
  }, []);
  const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
  const { classes } = props;

  const handleLocationChange = async (loc, Nav_Item) => {
    console.log(loc, "lco");
    // props.changeFavouritesPage(loc);
    if (Nav_Item === "Enhanced Insights") {
      localStorage.setItem("skygeni-user-page", "Enhanced Insights");
      localStorage.setItem("skygeni-user-enhancedPage", loc);
      localStorage.removeItem("skygeni-user-repPage");
      localStorage.removeItem("skygeni-user-productPage");
      localStorage.removeItem("skygeni-user-SettingsPage");
    } else if (Nav_Item === "Rep Performance") {
      localStorage.setItem("skygeni-user-page", "Rep Performance");
      localStorage.setItem("skygeni-user-repPage", loc);
      localStorage.removeItem("skygeni-user-enhancedPage");
      localStorage.removeItem("skygeni-user-productPage");
      localStorage.removeItem("skygeni-user-SettingsPage");
    }
    // await localStorage.setItem('params', JSON.stringify(props.match.params.filters))
    history.push("/");
    window.location.reload();
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    // console.log("idsbased", sourceId, sourceIndex, targetIndex, targetId);

    // const updatedData = [...charts]; // Create a copy of the data array
    // updatedData[sourceIndex] = {
    //   ...updatedData[sourceIndex],
    //   Position: targetIndex,
    // }; // Update the Position value at index 2

    // console.log("updation", updatedData);

    const result = swap(charts, sourceIndex, targetIndex);

    const resultarr = result.map((item, currIdx) => ({
      ...item,
      Position: item.Position - 1 !== currIdx ? currIdx + 1 : item.Position,
    }));

    setCharts(resultarr);
  }

  const handleData = () => {
    if (drag === false) {
      const payload = charts.map((data) => {
        return {
          Position: data.Position,
          dashboard_id: data.dashboard_id,
        };
      });

      props.setChartPosition(payload);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    opacity: isHovered ? 1 : 0.6,
    transition: "opacity 0.3s ease",
    display: "inline-block",
    // marginBottom: "25px",
  };

  let remHeight = "20rem";
  if (charts.length > 0) {
    const row = charts.length / 2;
    if (row > 10) {
      remHeight = `${15 * (row + 3)}rem`;
    } else {
      remHeight = `${15 * (row + 2)}rem`;
    }
  }
  console.log("cards_length", remHeight);

  return (
    <Grid>
      {drag === false && (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              // display: "flex",
              justifyContent: "center",
              zIndex: 9999,
              position: "absolute",
              // marginLeft: "56rem",
            }}
          >
            <Typography
              style={{
                background: "rgb(254, 229, 153)",
                padding: "0px 0.4rem 0px 0.7rem",
                margin: "0px 0.5rem",
                border: "1px solid lightgrey",
                color: "black",
                fontWeight: "medium",
                fontSize: 16,
              }}
            >
              In Edit mode
            </Typography>
          </div>
        </div>
      )}
      {/* <div style={{ maxWidth: 1900 }}> */}
      <div style={{ position: "fixed", top: 85, right: 30, zIndex: 99 }}>
        <div style={{ display: "flex" }}>
          <div style={{ display: "inline-block" }}>
            {drag ? (
              <Typography
                style={{
                  display: "inline-block",
                  width: "9.1rem",
                  color: "#adacac",
                }}
              >
                To rearrange your favorites click here.
              </Typography>
            ) : (
              <Typography style={{ display: "inline-block", width: "10.9rem" }}>
                Drag and drop cards to rearrange. Press save after rearranging.
              </Typography>
            )}
          </div>
          <div style={{ display: "inline-block" }}>
            <Button
              color="primary"
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              variant="text"
              onClick={() => {
                setDrag(!drag);
                handleData();
              }}
            >
              {drag == true ? (
                <EditIcon fontSize="large" />
              ) : (
                <SaveIcon fontSize="large" />
              )}
            </Button>
          </div>
        </div>
      </div>
      {/* {drag === false && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <Typography
            style={{
              background: "rgb(254, 229, 153)",
              padding: "0px 0.4rem 0px 0.7rem",
              margin: "0px 0.5rem",
              border: "1px solid lightgrey",
              color: "black",
              fontWeight: "medium",
              fontSize: 16,
            }}
          >
            In Edit mode
          </Typography>
        </div>
      )} */}
      {props.location === "home" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {charts.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6.25rem",
              }}
            >
              <Typography
                variant="h2"
                style={{ color: "rgb(128, 128, 128)", textAlign: "center" }}
              >
                No favorites. Click on the <StarBorderIcon /> to add dashboards
                to your favorites list.
              </Typography>
            </div>
          )}
        </div>
      ) : null}
      <GridContextProvider onChange={onChange} id={"test1"}>
        <div
          className={classes.containerFav}
          style={
            props.location === "home"
              ? { maxWidth: 955, marginTop: "2rem" }
              : { maxWidth: window.innerWidth <= 1368 ? 1600 : 1200 }
          }
          // style={{ width: window.innerWidth <= 820 ? "80%" : "63%" }}
          id={"test2"}
        >
          <GridDropZone
            // className="dropzone left"
            id="left"
            boxesPerRow={window.innerWidth <= 820 ? 1 : 2}
            rowHeight={window.innerWidth > 2600 ? 295 : 263}
            disableDrag={drag}
            style={{ height: remHeight, flex: 1 }}
          >
            {charts
              .sort((a, b) => a.Position - b.Position)
              .map((it, index) => {
                return (
                  <GridItem key={index} id={"test3"}>
                    <div
                      className={drag ? "pointer" : "grid-item"}
                      id={"test4"}
                    >
                      <div
                        className="grid-item-content"
                        // style={{ width: "100%" }}
                        id={"test5"}
                      >
                        <FavMediaCard
                          favourites={{}}
                          chartID={it.ChartID}
                          header={it.Chart_Name}
                          text={it.Description}
                          image={`${baseURL}${it.URL_for_Thumbnail}`}
                          changeLocation={handleLocationChange}
                          width={35}
                          Nav_Item={it.Nav_Item}
                          dashboard_id={it.dashboard_id}
                          addFavourite={props.addFavourite}
                          removeFavourite={props.removeFavourite}
                          Position={it.Position}
                          handleOpen={handleOpen}
                          navItem={it.Nav_Item}
                          confirmSignal={confirmSignal}
                          selectedDashboard={selectedDashboard}
                          cleanAfterConfirm={cleanAfterConfirm}
                          dragdisabled={drag}
                        />
                      </div>
                    </div>
                  </GridItem>
                );
              })}
          </GridDropZone>
        </div>
      </GridContextProvider>
      <EnhancedInsightRoutes location={props.location} />
      <RepPerformanceRoutes location={props.location} />
      <ConfifmModal
        selectedDashboard={selectedDashboard}
        handleConfirmClose={handleConfirmClose}
        setConfirmSignal={setConfirmSignal}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <BackdropContainer showLoading={props.isDeleting} />
      {/* </div> */}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { favouritesPage, user, chartsLoaded, isDeleting, isAdding } =
    state.app;
  const favouriteCharts = user.elements
    .filter((item) => item.navItem === "Favorites")[0]
    .charts.sort((a, b) => a.Position - b.Position);
  console.log(favouriteCharts);
  return {
    location: favouritesPage,
    company: user.company,
    chartsLoaded,
    charts: favouriteCharts,
    isDeleting,
    isAdding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFavourite: (chartID) => {
    dispatch({
      type: "add_favourite_request",
      payload: chartID,
    });
    appService.putFavourite(chartID).then(
      (json) => {
        dispatch({ type: "add_favourite_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "add_favourite_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "add_favourite_failure", error });
      }
    );
  },
  removeFavourite: (dashboard_id, navItem) => {
    dispatch({
      type: "delete_favourite_request",
      payload: dashboard_id,
    });

    appService.deleteFavourite(dashboard_id).then(
      (json) => {
        console.log(json);
        dispatch({
          type: "delete_favourite_success",
          json,
          payload: { dashboard_id: dashboard_id, navItem },
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "delete_favourite_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "delete_favourite_failure", error });
      }
    );
  },
  getUser: () => {
    dispatch({ type: "get_user_data_request_page" });
    appService.getUser().then(
      (json) => {
        dispatch({ type: "get_user_data_success", user: json.user });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_user_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },

  setChartPosition: (payload) => {
    dispatch({ type: "get_user_data_request_page" });
    appService.updateChartPosition(payload).then(
      (json) => {
        dispatch({ type: "get_nav_items_check_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nav_items_check_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },

  changeFavouritesPage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_favouritesPage", loc });
    localStorage.setItem("skygeni-user-enhancedPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
    localStorage.removeItem("skygeni-user-SettingsPage");
  },
});

const connectedFavourites = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Favourites));

export { connectedFavourites as Favourites };
export default Favourites;
