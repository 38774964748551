import React, { Fragment, Suspense } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../App/app.service";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import InsightsCard from "../components/InsightsCard";
import { Funnel } from "./Funnel/Funnel";
import { DemandGen } from "./DemandGen/DemandGen";
import { KeyMetrics } from "./Key Metrics/KeyMetrics";
import { MomentumAnalysis } from "./Momentum Analysis/MomentumAnalysis";
import { Funnel as CreatedFunnel } from "./CreatedFunnel/Funnel";
import FunnelNew from "./NewFunnel/Funnel";
import { Waterfall } from "./Waterfall/Waterfall";
import { Waterfall as NewWaterfall } from "./NewWaterFall/Waterfall";
import { PerformanceByIndustry } from "./PerformanceByIndustry/PerformanceByIndustry";
import { CurrentState } from "./CurrentState/CurrentState";
import { ClosedLost } from "./ClosedLost/ClosedLost";
import { WinRate } from "./WinRate/WinRate";
import { StrengthOfSales } from "./StrengthOfSales/StrengthOfSales";
import { version } from "../../package.json";
import Alert from "@material-ui/lab/Alert";
import WinACVAnalysis from "./WinACVAnalysis/WinACVAnalysis";
import WinACVMixGaapAnalysis from "./WinACVMixGAAPAnalysis/WinACVMixGaapAnalysis";
import PBIPipelineView from "./PBIPipelineView/PBIPipelineView";
import WonACVMekkoChart from "./PBISummaryView/WonACVMekkoChart";
import PipelineMekkoChart from "./PBIPipelineView/PipelineMekkoChart";
import PBISummaryView from "./PBISummaryView/PBISummaryView";
import NewACVMixAnalysis from "./WinACVMixAnalysis/WinACVMixAnalysis";
import AverageDealSize from "./WinACVMixAnalysis/AverageDealSize";
import PipelineMixAnalysis from "./PipelineMixAnalysis/PipelineMixAnalysis";
import PipelineCreatedAnalysis from "./PipelineMixAnalysis/PipelineCreatedAnalysis";
import PipelineCreatedMixAnalysis from "./PipelineMixAnalysis/PipelineCreatedMixAnalysis";
import CompetitorPerformance from "./CompetitorPerformance/CompetititorPerformance";
import { PipelineTrends } from "./PipelineTrends/PipelineTrends";
import { PipelineTrendsRolling } from "./PipelineTrends/PipelineTrendsRolling";
import ShowPlotly from "./OppotunityPyramid/ShowPlotly";
import StageProgression from "./StageProgressionAnalysis/StageProgressionAnalysis";
import QuotaMaintenance from "./Quota Maintenance/QuotaMaintenance";
import { PcdMovedAnalysis } from "./PCD Moved Analysis/PcdMovedAnalysis";

import SankeyPoc from "./SankeyPoc/SankeyPoc";
const ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/ProjectedPipeline")
);
const C0003_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0003_ProjectedPipeline")
);
const C0002_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0002_ProjectedPipeline")
);
const C0004_ProjectedPipeline = React.lazy(() =>
  import("./ProjectedPipeline/C0004_ProjectedPipelineAnalysis")
);
const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});

class EnhancedInsights extends React.Component {
  componentDidMount() {}

  handleLocationChange = (loc) => {
    this.props.changeEnhancedInsightsPage(loc);
  };

  render() {
    const { classes } = this.props;
    const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
    const charts = [
      {
        title: "Win Rate Cohort Analysis – Closed Deals",
        component: (
          <InsightsCard
            header="Win Rate Cohort Analysis – Closed Deals"
            text="Examine the cohort of deals that closed in a given time frame and analyze the win rate as opportunities moved down the funnel."
            image={`${baseURL}funnel.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Win Rate Cohort Analysis – Created Deals (New)",
        component: (
          <InsightsCard
            header="Win Rate Cohort Analysis – Created Deals"
            text="Examine the cohort of deals that were created in a given time frame and analyze the win rate as opportunities moved down the funnel."
            image={`${baseURL}Color_coded_Created_Funnel.PNG`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Win Rate Cohort Analysis – Created Deals",
        component: (
          <InsightsCard
            header="Win Rate Cohort Analysis – Created Deals"
            text="Examine the cohort of deals that were created in a given time frame and analyze the win rate as opportunities moved down the funnel."
            image={`${baseURL}funnel.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      // {
      //   title: 'Pipeline Waterfall Analysis',
      //   component: <InsightsCard header='Pipeline Waterfall Analysis'
      //     text='Visualize and analyze the increases and decreases to the pipeline over a given time frame using the classic waterfall chart.'
      //     image='waterfall.png' changeLocation={this.handleLocationChange}
      //     width={35} />
      // },
      {
        title: "Pipeline Waterfall Analysis (New)",
        component: (
          <InsightsCard
            header="Pipeline Waterfall Analysis"
            text="Visualize and analyze the changes to the pipeline for any combination of projected close quarter, past any stage over any time frame using the classic waterfall chart."
            image={`${baseURL}waterfall.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Opportunities with near-term close",
        component: (
          <InsightsCard
            header="Opportunities with near-term close"
            text="Improve forecast accuracy by analyzing opportunities with close dates on the horizon."
            image={`${baseURL}Opportunities with near-term close.PNG`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Pipeline Trends",
        component: (
          <InsightsCard
            header="Pipeline Trends"
            text="Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Pipeline Trends Rolling",
        component: (
          <InsightsCard
            header="Pipeline Trends Rolling"
            text="Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Win Rate Trends",
        component: (
          <InsightsCard
            header="Win Rate Trends"
            text="Examine win rates from various stages over time and across dimensions."
            image={`${baseURL}Win Rate Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Performance by Industry",
        component: (
          <InsightsCard
            header="Performance by Industry"
            text="Explore how various product categories are performing by industry."
            image={`${baseURL}IndustryPerformance.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Lost Opportunities Analysis",
        component: (
          <InsightsCard
            header="Lost Opportunities Analysis"
            text="Analyze when and where opportunities were lost from across multiple dimensions."
            image={`${baseURL}Lost Opp Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Current state of opportunities that came to Qualifying",
        component: (
          <InsightsCard
            header="Current state of opportunities that came to Qualifying"
            text="Current state of opportunities that came to Qualifying."
            image={`${baseURL}IndustryPerformance.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },

      {
        title: "Strength of Sale",
        component: (
          <InsightsCard
            header="Strength of Sale"
            text="Strength of Sale"
            image={`${baseURL}IndustryPerformance.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Demand Gen Effectiveness",
        component: (
          <InsightsCard
            header="Demand Gen Effectiveness"
            text="Explore the alignment between where demand is generated and where the sales teams are able to win."
            image={`${baseURL}IndustryPerformance.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Key Metrics",
        component: (
          <InsightsCard
            header="Key Metrics"
            text="Examine a range of key operational metrics across various dimensions."
            image={`${baseURL}IndustryPerformance.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Momentum Analysis",
        component: (
          <InsightsCard
            header="Momentum Analysis"
            text="Examine how quickly opportunities are won after being sales qualified."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Won ACV Mix Analysis",
        component: (
          <InsightsCard
            header="Won ACV Mix Analysis"
            text="Analyze won ACV across multiple dimensions to spot trends and patterns."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Won ACV by Vertical and Apps Cluster",
        component: (
          <InsightsCard
            header="Won ACV by Vertical and Apps Cluster"
            text="Discover which Vertical segments and Apps clusters drove Won ACV with the classic Mekko chart."
            image={`${baseURL}Mekko Chart.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Won ACV Mekko Chart",
        component: (
          <InsightsCard
            header="Won ACV Mekko Chart"
            text="Examine what drove the won ACV with the classic Mekko chart."
            image={`${baseURL}Mekko Chart.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Open Pipeline Mix Analysis",
        component: (
          <InsightsCard
            header="Open Pipeline Mix Analysis"
            text="Examine the open pipeline across multiple dimensions to spot trends and patterns."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Qualified Pipeline Created Analysis",
        component: (
          <InsightsCard
            header="Qualified Pipeline Created Analysis"
            text="Examine the open pipeline across multiple dimensions to spot trends and patterns."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Pipeline Created Mix Analysis",
        component: (
          <InsightsCard
            header="Pipeline Created Mix Analysis"
            text="Analyze the pipeline created across multiple dimensions to spot trends and patterns."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Pipeline by Vertical and Apps Cluster",
        component: (
          <InsightsCard
            header="Pipeline by Vertical and Apps Cluster"
            text="Dissect the pipeline by Vertical segment and Apps Cluster to determine where to focus."
            image={`${baseURL}Mekko Chart.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Pipeline Mekko Chart",
        component: (
          <InsightsCard
            header="Pipeline Mekko Chart"
            text="Dissect the open pipeline to discover the major areas of focus."
            image={`${baseURL}Mekko Chart.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Average Deal Size and Cycle Time",
        component: (
          <InsightsCard
            header="Average Deal Size and Cycle Time"
            text="Explore trends in average deal sizes and cycle times across regions and opportunity types."
            image={`${baseURL}Avg Deal Size and Cycle Time.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Performance against Key Competitors",
        component: (
          <InsightsCard
            header="Performance against Key Competitors"
            text="Explore win / loss performance against key competitors across various time frames"
            image={`${baseURL}Avg Deal Size and Cycle Time.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Projected Close Date Moves",
        component: (
          <InsightsCard
            header="Projected Close Date Moves"
            text="Explore win / loss performance against key competitors across various time frames"
            image={`${baseURL}Avg Deal Size and Cycle Time.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Cycle Time Analysis",
        component: (
          <InsightsCard
            header="Cycle Time Analysis"
            text="Analyze the cycle time for Won and Lost opportunities by ACV band"
            image={`${baseURL}Cycle Time Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Stage Progression Analysis",
        component: (
          <InsightsCard
            header="Stage Progression Analysis"
            text="Analyze the progression of opportunities across various stages."
            image={`${baseURL}Stage Progression Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Won ACV Mix GAAP Analysis",
        component: (
          <InsightsCard
            header="Won ACV Mix GAAP Analysis"
            text="Analyze the progression of opportunities across various stages."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Quota Maintenance",
        component: (
          <InsightsCard
            header="Quota Maintenance"
            text="Analyze the progression of opportunities across various stages."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
    ];
    return (
      <Fragment>
        <div
          className={classes.dashOuter}
          style={
            this.props.location === "home"
              ? { maxWidth: 1100 }
              : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }
          }
        >
          {this.props.location === "home" && (
            <Grid
              container
              className={classes.grid}
              wrap="wrap"
              justify="flex-start"
              alignItems="center"
            >
              {/* <InsightsCard header='Sankey POC'
            text='Analyze the progression of opportunities across various stages.'
            image={`${baseURL}Mix Analysis.png`} changeLocation={this.handleLocationChange}
            width={35} /> */}

              {charts !== undefined &&
                this.props.charts.map((it) =>
                  charts.filter((chart) => chart.title === it.Chart_Name)[0] !==
                  undefined
                    ? charts.filter((chart) => chart.title === it.Chart_Name)[0]
                        .component
                    : null
                )}
            </Grid>
          )}
          {this.props.location ===
            "Win Rate Cohort Analysis – Closed Deals" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <Funnel />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Demand Gen Effectiveness" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <DemandGen />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Momentum Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <MomentumAnalysis />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Projected Close Date Moves" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <PcdMovedAnalysis />
              </Grid>
            </Grid>
          )}

          {this.props.location === "Pipeline Waterfall Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <NewWaterfall />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Quota Maintenance" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QuotaMaintenance />
              </Grid>
            </Grid>
          )}

          {this.props.location ===
            "Win Rate Cohort Analysis – Created Deals" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <FunnelNew />
              </Grid>
            </Grid>
          )}
          {/* {this.props.location === 'Win Rate Cohort Analysis – Created Deals' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <CreatedFunnel />
              </Grid>
            </Grid>
          } */}
          {/* {this.props.location === 'Pipeline Waterfall Analysis' &&
            <Grid
              container
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
              >
                <Waterfall />
              </Grid>
            </Grid>
          } */}
          {this.props.location === "Performance by Industry" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PerformanceByIndustry />}
              </Grid>
            </Grid>
          )}
          {this.props.location ===
            "Current state of opportunities that came to Qualifying" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<CurrentState />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Lost Opportunities Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<ClosedLost />}
              </Grid>
            </Grid>
          )}

          {this.props.location === "Win Rate Trends" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<WinRate />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Won ACV Mix Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<WinACVAnalysis />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Open Pipeline Mix Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PipelineMixAnalysis />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Qualified Pipeline Created Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PipelineCreatedAnalysis />}
              </Grid>
            </Grid>
          )}
             {this.props.location === "Pipeline Created Mix Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PipelineCreatedMixAnalysis />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Pipeline by Vertical and Apps Cluster" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PBIPipelineView />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Won ACV by Vertical and Apps Cluster" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PBISummaryView />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Won ACV Mekko Chart" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<WonACVMekkoChart />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Pipeline Mekko Chart" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<PipelineMekkoChart />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Average Deal Size and Cycle Time" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {this.props.company === "C0003" ? (
                  <NewACVMixAnalysis />
                ) : (
                  <AverageDealSize />
                )}
              </Grid>
            </Grid>
          )}

          {this.props.location === "Strength of Sale" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<StrengthOfSales />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Opportunities with near-term close" &&
            this.props.company === "C0001" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <ProjectedPipeline />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Opportunities with near-term close" &&
            this.props.company === "C0002" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <C0002_ProjectedPipeline />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Opportunities with near-term close" &&
            this.props.company === "C0003" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <C0003_ProjectedPipeline />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Opportunities with near-term close" &&
            this.props.company === "C0004" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <C0004_ProjectedPipeline />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Opportunities with near-term close" &&
            !["C0001", "C0002", "C0003", "C0004"].includes(
              this.props.company
            ) && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <ProjectedPipeline />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Performance against Key Competitors" &&
            this.props.company !== "C0003" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <CompetitorPerformance />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Pipeline Trends" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {
                  <Suspense fallback={<div>Loading...</div>}>
                    <PipelineTrends />
                  </Suspense>
                }
              </Grid>
            </Grid>
          )}
          {this.props.location === "Cycle Time Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <ShowPlotly />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Pipeline Trends Rolling" &&
             (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense fallback={<div>Loading...</div>}>
                      <PipelineTrendsRolling />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {this.props.location === "Stage Progression Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <StageProgression />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Won ACV Mix GAAP Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <WinACVMixGaapAnalysis />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Key Metrics" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                {<KeyMetrics />}
              </Grid>
            </Grid>
          )}
          {this.props.location === "Sankey POC" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <SankeyPoc />
              </Grid>
            </Grid>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { enhancedInsightsPage, user } = state.app;

  return {
    location: enhancedInsightsPage,
    company: user.company,
    charts: user.elements
      .filter((item) => item.navItem === "Enhanced Insights")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    localStorage.removeItem("skygeni-user");
  },
  changeEnhancedInsightsPage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_enhancedInsightsPage", loc });
    localStorage.setItem("skygeni-user-enhancedPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
  },
});

const connectedEnhancedInsights = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EnhancedInsights));
export { connectedEnhancedInsights as EnhancedInsights };
