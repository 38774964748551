import * as d3 from "d3";
import { updatedScheme10 } from "../../util/chartColorScheme";
import {
  countOrACVFormatter,
  getUniqueValues,
  _isNaN,
} from "../../util/customFunctions";
import { formatDataValuesNew } from "../../util/dataFormatter";

import "../../Dashboard/OpenOpportunities/D3OpenOppurtunities.scss";
import "../WinACVMixAnalysis/D3LineChart.scss";
import "./D3Funnel.scss";
import { countOrAcvComparer } from "../../util/comparator";
import {
  customHelperTooltip,
  customHelperTooltipUsingArray,
  customHelperTooltipUsingArrayWinRate,
  newFunnelTooltip,
} from "../../util/CustomTooltips";
import { dsv } from "d3";
import { getResponsiveWidth } from "./utils";

const D3FunnelStack = {};

D3FunnelStack.create = (el, config) => {
  if (config.data) {
    const uniqueStages = config.data
      .map((item) => item.stage)
      .filter(getUniqueValues);
    let firstStage = uniqueStages[0],
      wonLabel = config.wonLabel;

    config.barColor = "#70ad47";
    config.barHeight =
      d3.select(el).node().getBoundingClientRect().height /
      (config.data.length * 2);
    config.margin = {
      top: 0,
      right: 85,
      bottom: 20,
      left: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 10
          : Math.max(...config.data.map((d) => d.stage.length)) * 6 + 20
        : 100,
    };
    const rawData = config.data;
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    config.height = d3.select(el).node().getBoundingClientRect().height;
    var x = d3.scaleBand().domain([0, 2]).range([0, config.width]);
    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom);
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );
    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    var rateRectsGroup = chart
      .selectAll("g.rates")
      .data(config.data)
      .enter()
      .append("g")
      .attr("class", "rates")
      .attr("transform", function (d, i) {
        var xPos,
          yPos = config.barHeight * i * 2;
        xPos = 0;
        return "translate(" + xPos + "," + yPos + ")";
      });
    // grey bars
    var rateRectsFull = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", config.width)
      .attr("class", "compare-bar")
      .attr("fill", "#bfbfbf")
      // .style("cursor", "pointer")
      // .on("click", i => {

      //     config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      // })
      .on("mouseover", function (d, i) {
        d3.select(this).transition().style("opacity", "0.5");
      })

      .on("mouseout", function (d, i) {
        d3.select(this).transition().style("opacity", "1");
      })
      .attr("transform", "translate(0, 0)");

    // closed won green bars
    var rateRects = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", function (d) {
        return d.stage === wonLabel
          ? (config.width *
              d[
                `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
              ]) /
              100
          : 0;
      })
      .attr("transform", (d) => {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;

        return `translate(${(offset / 2 / 100) * config.width},0)`;
      })
      .attr("class", "actual-bar")
      .style("cursor", "pointer")
      .style("fill", "#548236")
      .style("opacity", 0.9)
      .attr("data-test", (i) => i.stage)
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "",
              d.stage,
              config.type,
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ],
              "#548236",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .on("click", (i) => {
        config.handleChangeLocationNew(
          "drilldownOnGraph",
          i.stage,
          i.stage === wonLabel ? "closedWon" : `movedForward${i.stage}`
        );
      });
    // .attr("transform", function (d) {
    //     console.log((d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]) / 100)
    //     return d.stage !== firstStage ? 'translate(' + (config.width - (config.width * (d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]) / 100)) / 2 + ', 0)' : 'translate(' + (config.width - (config.width * 1)) / 2 + ', 0)';
    // });
    // green bars
    var rateRects = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", function (d) {
        return d.stage !== firstStage
          ? (((config.width *
              d[
                `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
              ]) /
              100) *
              d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
          : (config.width *
              1 *
              d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
      })
      .attr("class", "actual-bar")
      .style("cursor", "pointer")
      .style("fill", "#70ad47")
      .attr("data-test", (i) => i.stage)
      .style("opacity", 0.9)
      .attr("transform", (d) => {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;

        const blueXpos =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        const blueWidth =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        var xPos = blueXpos + blueWidth;

        return `translate(${xPos + (offset / 2 / 100) * config.width},0)`;
      })
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "Moved forward from",
              d.stage,
              config.type,
              d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`],
              "#70ad47",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })

      .on("click", (i) => {
        config.handleChangeLocationNew(
          "drilldownOnGraph",
          i.stage,
          `movedForward${i.stage}`
        );
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      });
    // red bars
    var rateRects = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", function (d) {
        return d.stage !== firstStage
          ? (((config.width *
              d[
                `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
              ]) /
              100) *
              d[
                `lostDisqualifiedFromStage${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
          : (config.width *
              1 *
              d[
                `lostDisqualifiedFromStage${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
      })
      .attr("class", "actual-bar")
      .style("cursor", "pointer")
      .style("fill", "#c65911")
      .attr("data-test", (i) => i.stage)
      .style("opacity", 0.9)
      .attr("transform", function (d, i) {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;

        return `translate(${(offset / 2 / 100) * config.width},0)`;
      })
      .on("click", (i) => {
        config.handleChangeLocationNew("drilldownOnGraph", i.stage, "orange");
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      })
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "Lost / Disqualified from",
              d.stage,
              config.type,
              d[
                `lostDisqualifiedFromStage${
                  config.type === "acv" ? "_acv" : ""
                }`
              ],
              "#c65911",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    // blue bars
    var rateRects = rateRectsGroup
      .append("rect")
      .attr("height", config.barHeight)
      .attr("width", function (d) {
        return d.stage !== firstStage
          ? (((config.width *
              d[
                `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
              ]) /
              100) *
              d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
          : (config.width *
              1 *
              d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
      })
      .attr("class", "actual-bar")
      .style("cursor", "pointer")
      .style("fill", "#4472c4")
      .attr("data-test", (i) => i.stage)
      .style("opacity", 0.9)
      .attr("transform", function (d, i) {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;
        var xPos =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];

        return `translate(${xPos + (offset / 2 / 100) * config.width},0)`;
      })
      .on("click", (i) => {
        config.handleChangeLocationNew("drilldownOnGraph", i.stage, "blue");
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      })
      .on("mousemove", (d) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "Active at",
              d.stage,
              config.type,
              d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`],
              "#4472c4",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
    // stage names in the left of the funnel chart
    var categoryLabels = rateRectsGroup
      .append("text")
      .attr("class", "bar-text")
      .attr(
        "text-anchor",
        d3.select(el).node().getBoundingClientRect().width < 400
          ? "start"
          : "end"
      )
      .attr("transform", function (d, i) {
        var xPos =
            d3.select(el).node().getBoundingClientRect().width < 400 ? 5 : -10,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7;
        return "translate(" + xPos + "," + yPos + ")";
      })
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 400)
      .attr(
        "fill",
        d3.select(el).node().getBoundingClientRect().width < 400
          ? "white"
          : "black"
      )
      .text(function (d) {
        return d.stage;
      });
    // winrate percents in the right of the funnel chart
    var categoryLabels2 = rateRectsGroup
      .append("text")
      .attr("class", "bar-text")
      .attr("text-anchor", "start")
      .attr("transform", function (d, i) {
        var xPos = config.width + 10,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7;
        return "translate(" + xPos + "," + yPos + ")";
      })
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 600)
      .attr("fill", "black")
      .style("cursor", "default")
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            customHelperTooltipUsingArrayWinRate(
              config.showTeamData
                ? [
                    {
                      helperText: `${config.Win_Rate_Prefix} from <span class="font-bold">${d.stage}</span>`,
                      value: `${Math.round(
                        d[
                          `winRateFromStage${
                            config.type === "acv" ? "_acv" : ""
                          }`
                        ]
                      )}%`,
                    },
                    {
                      helperText: `Company Average`,
                      value: `${Math.round(
                        config.companyData.filter(
                          (i) => i.stageCompany === d.stage
                        )[0][
                          `winRateFromStage${
                            config.type === "acv" ? "Company_acv" : "Company"
                          }`
                        ]
                      )}%`,
                    },
                  ]
                : [
                    {
                      helperText: `${config.Win_Rate_Prefix} from <span class="font-bold">${d.stage}</span>`,
                      value: `${Math.round(
                        d[
                          `winRateFromStage${
                            config.type === "acv" ? "_acv" : ""
                          }`
                        ]
                      )}%`,
                    },
                  ]
            )
          )

          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .text(function (d) {
        return `${Math.round(
          d[`winRateFromStage${config.type === "acv" ? "_acv" : ""}`]
        )}% `;
      })
      .append("tspan")
      .style("fill", "#7f7f7f")
      .text(function (d) {
        return `${
          config.showTeamData
            ? "(" +
              Math.round(
                config.companyData.filter((i) => i.stageCompany === d.stage)[0][
                  `winRateFromStage${
                    config.type === "acv" ? "Company_acv" : "Company"
                  }`
                ]
              ) +
              "%)"
            : ""
        }`;
      });

    // conversion rate and (suspect to stage) conversion rate
    var ratePercentageLabel = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value-percentage")
      .attr("transform", function (d, i) {
        var xPos = config.width / 2,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? -config.barHeight
              : config.barHeight * 0.9 + 4;
        return "translate(" + xPos + "," + yPos + ")";
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', -20)')
      .append("tspan")
      .attr("dy", config.data.map((s) => s.stage).length > 7 ? "0.87em" : "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 600)
      .style("cursor", "default")
      .on("mousemove", (d, i) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            customHelperTooltipUsingArray(
              config.showTeamData
                ? [
                    {
                      helperText: `<span class="font-bold">${
                        config.data[i].stage
                      }</span> to <span class="font-bold">${
                        config.data[i + 1].stage
                      }</span> Conv. Rate`,
                      value: `${Math.round(
                        d[
                          `stageToStageConvRate${
                            config.type === "acv" ? "_acv" : ""
                          }`
                        ]
                      )}%`,
                    },
                    {
                      helperText: `Company Average`,
                      value: `${Math.round(
                        config.companyData.filter(
                          (i) => i.stageCompany === d.stage
                        )[0][
                          `stageToStageConvRate${
                            config.type === "acv" ? "Company_acv" : "Company"
                          }`
                        ]
                      )}%`,
                    },
                  ]
                : [
                    {
                      helperText: `<span class="font-bold">${
                        config.data[i].stage
                      }</span> to <span class="font-bold">${
                        config.data[i + 1].stage
                      }</span> Conv. Rate`,
                      value: `${Math.round(
                        d[
                          `stageToStageConvRate${
                            config.type === "acv" ? "_acv" : ""
                          }`
                        ]
                      )}%`,
                    },
                  ]
            )
          )
          // .html(customHelperTooltipUsingArray([{ helperText: '', value: '' }]))

          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) => {
            if (window.innerWidth > 4000) {
              // 4000 and above
              return `calc(${d3.event.pageX - 180 + "px"} - 73%)`;
            } else if (window.innerWidth > 3700) {
              // 4000 px to 3700px
              return `calc(${d3.event.pageX - 180 + "px"} - 63%)`;
            } else if (window.innerWidth > 3400) {
              //  3700 to 3400
              return `calc(${d3.event.pageX - 180 + "px"} - 57%)`;
            } else if (window.innerWidth > 3250) {
              // 3400 to  3250
              return `calc(${d3.event.pageX - 180 + "px"} - 50%)`;
            } else if (window.innerWidth > 2900) {
              // 3250 to 2900
              return `calc(${d3.event.pageX - 180 + "px"} - 40%)`;
            } else if (window.innerWidth > 2600) {
              // 2900 to 2600
              return `calc(${d3.event.pageX - 180 + "px"} - 30%)`;
            } else if (window.innerWidth > 2300) {
              // 2600 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 20%)`;
            } else if (window.innerWidth > 2100) {
              // 1900 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 10%)`;
            } else if (window.innerWidth > 1900) {
              // 1900 to 2300
              return `calc(${d3.event.pageX - 180 + "px"} - 5%)`;
            } else {
              // less than 1900
              return `calc(${d3.event.pageX - 180 + "px"})`;
            }
          })
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .text(function (d, i) {
        return d.stage !== wonLabel
          ? `${Math.round(
              d[`stageToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            )}% `
          : "";
      })
      .append("tspan")
      .style("fill", "#7f7f7f")
      .text(function (d) {
        return d.stage !== wonLabel
          ? `${
              config.showTeamData
                ? "(" +
                  Math.round(
                    config.companyData.filter(
                      (i) => i.stageCompany === d.stage
                    )[0][
                      `stageToStageConvRate${
                        config.type === "acv" ? "Company_acv" : "Company"
                      }`
                    ]
                  ) +
                  "%)"
                : ""
            }`
          : "";
      });

    // red label
    var rateRectsLabel1 = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value")
      .attr("transform", function (d, i) {
        let offset =
            d.stage !== firstStage
              ? 100 -
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]
              : 0,
          yPos =
            d3.select(el).node().getBoundingClientRect().width < 400
              ? config.barHeight / 2 - 5
              : config.barHeight / 2 - 7,
          barWidth =
            d.stage !== firstStage
              ? (((config.width *
                  d[
                    `suspectToStageConvRate${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                  100) *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]
              : (config.width *
                  1 *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ];

        return `translate(${
          (offset / 2 / 100) * config.width + barWidth / 2
        },${yPos})`;
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', 0)')
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 600)
      .attr("cursor", "pointer")
      .attr("fill", "white")
      .text((d) =>
        config.type === "acv"
          ? (d.stage !== firstStage
              ? (((config.width *
                  d[
                    `suspectToStageConvRate${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                  100) *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]
              : (config.width *
                  1 *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) >= 45
            ? formatDataValuesNew(
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ],
                config.type,
                config.company
              )
            : ""
          : (d.stage !== firstStage
              ? (((config.width *
                  d[
                    `suspectToStageConvRate${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                  100) *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]
              : (config.width *
                  1 *
                  d[
                    `lostDisqualifiedFromStage${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) >= 12
          ? formatDataValuesNew(
              d[
                `lostDisqualifiedFromStage${
                  config.type === "acv" ? "_acv" : ""
                }`
              ],
              config.type,
              config.company
            )
          : ""
      )
      .on("click", (i) => {
        config.handleChangeLocationNew("drilldownOnGraph", i.stage, "orange");
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      })
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "Lost / Disqualified from",
              d.stage,
              config.type,
              d[
                `lostDisqualifiedFromStage${
                  config.type === "acv" ? "_acv" : ""
                }`
              ],
              "#c65911",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
    // blue label
    var rateRectsLabel2 = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value")
      .attr("transform", function (d, i) {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;
        var xPos =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        let yPos =
          d3.select(el).node().getBoundingClientRect().width < 400
            ? config.barHeight / 2 - 5
            : config.barHeight / 2 - 7;
        let barWidth =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];

        return `translate(${
          xPos + (offset / 2 / 100) * config.width + barWidth / 2
        },${yPos})`;
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', 0)')
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 600)
      .attr("cursor", "pointer")
      .attr("fill", "white")
      .text((d) =>
        config.type === "acv"
          ? (d.stage !== firstStage
              ? (((config.width *
                  d[
                    `suspectToStageConvRate${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                  100) *
                  d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]
              : (config.width *
                  1 *
                  d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) >= 40
            ? formatDataValuesNew(
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`],
                config.type,
                config.company
              )
            : ""
          : (d.stage !== firstStage
              ? (((config.width *
                  d[
                    `suspectToStageConvRate${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) /
                  100) *
                  d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]
              : (config.width *
                  1 *
                  d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) >= 12
          ? formatDataValuesNew(
              d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`],
              config.type,
              config.company
            )
          : ""
      )
      .on("click", (i) => {
        config.handleChangeLocationNew("drilldownOnGraph", i.stage, "blue");
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      })
      .on("mousemove", (d) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            newFunnelTooltip(
              "Active at",
              d.stage,
              config.type,
              d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`],
              "#4472c4",
              config.Value_Label
            )
          )
          // .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", (d) =>
            getResponsiveWidth(window.innerWidth, d3.event.pageX)
          )
          .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
    // won label or green label
    var rateRectsLabel3 = rateRectsGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("class", "category-value")
      .attr("transform", function (d, i) {
        let offset =
          d.stage !== firstStage
            ? 100 -
              d[`suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`]
            : 0;

        const blueXpos =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[
                  `lostDisqualifiedFromStage${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        const blueWidth =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[`activeAtStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        var xPos = blueXpos + blueWidth;
        let barWidth =
          d.stage !== firstStage
            ? (((config.width *
                d[
                  `suspectToStageConvRate${config.type === "acv" ? "_acv" : ""}`
                ]) /
                100) *
                d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ]
            : (config.width *
                1 *
                d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`]) /
              d[
                `cameToStageAndmovedForward${
                  config.type === "acv" ? "_acv" : ""
                }`
              ];
        let yPos =
          d3.select(el).node().getBoundingClientRect().width < 400
            ? config.barHeight / 2 - 5
            : config.barHeight / 2 - 7;
        return `translate(${
          _isNaN(xPos + (offset / 2 / 100) * config.width + barWidth / 2)
            ? config.width / 2
            : xPos + (offset / 2 / 100) * config.width + barWidth / 2
        },${yPos})`;
      })
      //.attr('transform', 'translate(' + config.width / 2 + ', 0)')
      .append("tspan")
      .attr("dy", "1em")
      .style("font-size", "0.85rem")
      .attr("font-weight", 600)
      .attr("cursor", "pointer")
      .attr("fill", "white")
      .text((d) => {
        if (d.stage === wonLabel) {
          return formatDataValuesNew(
            d[
              `cameToStageAndmovedForward${config.type === "acv" ? "_acv" : ""}`
            ],
            config.type,
            config.company
          );
        } else {
          return config.type === "acv"
            ? (d.stage !== firstStage
                ? (((config.width *
                    d[
                      `suspectToStageConvRate${
                        config.type === "acv" ? "_acv" : ""
                      }`
                    ]) /
                    100) *
                    d[
                      `movedToNextStage${config.type === "acv" ? "_acv" : ""}`
                    ]) /
                  d[
                    `cameToStageAndmovedForward${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]
                : (config.width *
                    1 *
                    d[
                      `movedToNextStage${config.type === "acv" ? "_acv" : ""}`
                    ]) /
                  d[
                    `cameToStageAndmovedForward${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) >= 40
              ? formatDataValuesNew(
                  d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`],
                  config.type,
                  config.company
                )
              : ""
            : (d.stage !== firstStage
                ? (((config.width *
                    d[
                      `suspectToStageConvRate${
                        config.type === "acv" ? "_acv" : ""
                      }`
                    ]) /
                    100) *
                    d[
                      `movedToNextStage${config.type === "acv" ? "_acv" : ""}`
                    ]) /
                  d[
                    `cameToStageAndmovedForward${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]
                : (config.width *
                    1 *
                    d[
                      `movedToNextStage${config.type === "acv" ? "_acv" : ""}`
                    ]) /
                  d[
                    `cameToStageAndmovedForward${
                      config.type === "acv" ? "_acv" : ""
                    }`
                  ]) >= 12
            ? formatDataValuesNew(
                d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`],
                config.type,
                config.company
              )
            : "";
        }
      })
      .on("click", (i) => {
        config.handleChangeLocationNew(
          "drilldownOnGraph",
          i.stage,
          i.stage === wonLabel ? "closedWon" : `movedForward${i.stage}`
        );
        // config.handleChangeLocation('drilldownOnGraph', i.stage, wonLabel === i.stage ? true : false)
      })
      .on("mousemove", (d) => {
        // console.log(d3.event.pageY - 85  + "px" )
        tooltip.transition().duration(200).style("opacity", 1);
        if (d.stage === wonLabel) {
          tooltip
            .html(
              newFunnelTooltip(
                "",
                d.stage,
                config.type,
                d[
                  `cameToStageAndmovedForward${
                    config.type === "acv" ? "_acv" : ""
                  }`
                ],
                "#548236",
                config.Value_Label
              )
            )
            // .style("top", d + "px")
            //  .attr("class", "tool")
            .style("left", (d) => {
              if (window.innerWidth > 4000) {
                // 4000 and above

                return `calc(${d3.event.pageX - 180 + "px"} - 73%)`;
              } else if (window.innerWidth > 3700) {
                // 4000 px to 3700px
                return `calc(${d3.event.pageX - 180 + "px"} - 63%)`;
              } else if (window.innerWidth > 3400) {
                //  3700 to 3400
                return `calc(${d3.event.pageX - 180 + "px"} - 57%)`;
              } else if (window.innerWidth > 3250) {
                // 3400 to  3250
                return `calc(${d3.event.pageX - 180 + "px"} - 50%)`;
              } else if (window.innerWidth > 2900) {
                // 3250 to 2900
                return `calc(${d3.event.pageX - 180 + "px"} - 40%)`;
              } else if (window.innerWidth > 2600) {
                // 2900 to 2600
                return `calc(${d3.event.pageX - 180 + "px"} - 30%)`;
              } else if (window.innerWidth > 2300) {
                // 2600 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 20%)`;
              } else if (window.innerWidth > 2100) {
                // 1900 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 10%)`;
              } else if (window.innerWidth > 1900) {
                // 1900 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 5%)`;
              } else {
                // less than 1900
                return `calc(${d3.event.pageX - 180 + "px"})`;
              }
            })
            .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
        } else {
          tooltip
            .html(
              newFunnelTooltip(
                "Moved forward from",
                d.stage,
                config.type,
                d[`movedToNextStage${config.type === "acv" ? "_acv" : ""}`],
                "#70ad47",
                config.Value_Label
              )
            )
            // .style("top", d + "px")
            //  .attr("class", "tool")
            .style("left", (d) => {
              if (window.innerWidth > 4000) {
                // 4000 and above

                return `calc(${d3.event.pageX - 180 + "px"} - 73%)`;
              } else if (window.innerWidth > 3700) {
                // 4000 px to 3700px
                return `calc(${d3.event.pageX - 180 + "px"} - 63%)`;
              } else if (window.innerWidth > 3400) {
                //  3700 to 3400
                return `calc(${d3.event.pageX - 180 + "px"} - 57%)`;
              } else if (window.innerWidth > 3250) {
                // 3400 to  3250
                return `calc(${d3.event.pageX - 180 + "px"} - 50%)`;
              } else if (window.innerWidth > 2900) {
                // 3250 to 2900
                return `calc(${d3.event.pageX - 180 + "px"} - 40%)`;
              } else if (window.innerWidth > 2600) {
                // 2900 to 2600
                return `calc(${d3.event.pageX - 180 + "px"} - 30%)`;
              } else if (window.innerWidth > 2300) {
                // 2600 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 20%)`;
              } else if (window.innerWidth > 2100) {
                // 1900 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 10%)`;
              } else if (window.innerWidth > 1900) {
                // 1900 to 2300
                return `calc(${d3.event.pageX - 180 + "px"} - 5%)`;
              } else {
                // less than 1900
                return `calc(${d3.event.pageX - 180 + "px"})`;
              }
            })
            .style("top", `calc(${d3.event.pageY - 80 + "px"}) `);
        }
      })
      .on("mouseout", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
  }
};
D3FunnelStack.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
};

export default D3FunnelStack;
