import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { filter } from "d3";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import {
  getCurrentFiscalQuarter,
  nearestCompletedFiscalQuarter,
} from "../../../util/fiscalQuarterFunctions";
import { accountHealthColors, colorStages } from "./utils";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import CurrentStatusTable from "./components/CurrentStatusTable";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import { getHeaderForDrilldown } from "../../../util/customFunctions";
import AccountHealthDrilldown from "./components/AccountHealthDrilldown";
import RenewalDrilldownBody from "../RenewalsCompleted/components/RenewalDrilldownBody";
import addHeaderdescription from "../../../components/HeaderDescription";
const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});
class AccountHealth extends Component {
  filters = ["Status", "Region"];
  state = {
    Status: ["Active"],
    Region: ["All"],
    disabled: ["year", "month", "quarter"],
    location: "overview",
    filterStatus: ["Active"],
    filterRegion: [],
  };
  componentDidMount() {
    this.props.getAccountHealthFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getCurrentFiscalQuarter();
      this.setState({ ...prevState, Status: ["Active"] });
    }
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeLocation = (location, data, type, quarter) => {};
  handleStatusType = (e, value) => {
    this.setState({
      ...this.state,
      Status: [e.target.value],
    });
  };

  passFiltersForDrilldown = () => {
    let filters = [];
    if (!this.state.Status.includes("All")) {
      filters = [...filters, this.state.Status];
    }

    if (this.state.Status.includes("All")) {
      filters = [...filters, "All"];
    }

    return filters[0];
  };

  passFiltersForDrilldown2 = () => {
    let formattedFilters = [];
    //     if (!this.state.Status.includes('All')) {
    //        formattedFilters = [...formattedFilters, { name: 'Status', value: this.state.Status, string: true }]
    //        // formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, { name: 'Status', value: this.state.Status, string: true }]
    //    }
    if (!this.state.Region.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "Region", value: this.state.Region, string: true },
      ];
      // formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, { name: 'Account_Segment', value: this.state.Account_Segment, string: true }]
    }
    return formattedFilters;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.setState({
      ...this.state,
      filterStatus: this.passFiltersForDrilldown(),
      filterRegion: this.passFiltersForDrilldown2(),
    });
    this.props.getAccountHealthData(this.props.company, filters);
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, filters, type, countType) => {
    if (countType === "rawValsTotalDonutOverAll") {
      let newFilters = [
        { name: "Overall_Health", value: filters.row, string: true },
        { name: "Account_Segment", value: filters.cell, string: true },
      ];
      filters = newFilters;
    }
    if (type === "rawVals" || countType === "rawVals") {
      let newFilters = [
        { name: "Overall_Health", value: [filters.row], string: true },
        { name: "Account_Segment", value: [filters.cell], string: true },
      ];
      filters = newFilters;
    }
    if (countType === "rawValsTotal") {
      let newFilters = [
        { name: "Overall_Health", value: filters.row, string: true },
        { name: "Account_Segment", value: [filters.cell], string: true },
      ];
      filters = newFilters;
    }
    if (countType === "rawValsTotalDonut") {
      let newFilters = [
        { name: "Overall_Health", value: [filters.row], string: true },
        { name: "Account_Segment", value: filters.cell, string: true },
      ];
      filters = newFilters;
    }

    // let drillLabelFilters = [...filters]

    if (!this.state.filterStatus.includes("All")) {
      filters = [
        ...filters,
        { name: "Status", value: this.state.filterStatus, string: true },
      ];
    }

    // if (!this.state.Region.includes('All')) {
    //     filters = [...filters, { name: 'Region', value: this.state.Region, string: true }]
    // }

    filters = [...filters, ...this.state.filterRegion];
    // formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, ...this.state.filterRegion]

    this.props.setTable(type);
    this.props.getChartDrilldown("renewals", filters);
    this.setState({
      ...this.state,
      location: location,

      filtersForDrilldown: [
        ...filters
          .filter((item) => item.name !== "Status")
          .map((item) => ({ ...item, title: item.name.split("_").join(" ") })),
      ],
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer} justify="center">
        {/* <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Account Health – Current Status
          </Typography>
          <Typography variant="body1">
            Examine the current health status of accounts by region and segment.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Account Health – Current Status",
          "Examine the current health status of accounts by region and segment."
        )}
        <Grid
          className={classes.gridItem}
          xs={12}
          item
          container
          justify="flex-start"
        >
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Status.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Status:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.Status}
                  onChange={this.handleChange}
                  label="Status"
                  name="Status"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter((y) => y.type === "Status" && y["Status"] !== "")
                      .map((y) => (
                        <MenuItem key={y["Status"]} value={y["Status"]}>
                          {y["Status"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Status.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Region:
                </InputLabel>
                <Select
                  labelId="select-vRegion-label"
                  id="select-cRegion"
                  value={this.state.Region}
                  onChange={this.handleChange}
                  label="Region"
                  name="Region"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter((y) => y.type === "Region" && y["Region"] !== "")
                      .map((y) => (
                        <MenuItem key={y["Region"]} value={y["Region"]}>
                          {y["Region"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        {this.props.accountHealthData !== "" ? (
          <Grid item xs={12} className={classes.gridItem}>
            <DataVisualizer
              title={`By Segment - Number of Accounts`}
              type={"Overall_Health"}
              chartType="number"
              chartName="AccountHealth"
              winACVMixAnalysisData={this.props.accountHealthData[0].map(
                (item) => ({ ...item, acv: item.SFDC })
              )}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"Account_Segment"}
              page="CustomerSuccess"
              unit="SFDC"
              colors={accountHealthColors}
              isDrilldown={true}
              tableComponent={
                <CurrentStatusTable
                  data={this.props.accountHealthData[0]}
                  type="SFDC"
                  col="Account_Segment"
                  row="Overall_Health"
                  chartName="accountHealthCurrentStatus"
                  handleChangeLocation={this.handleChangeLocation}
                />
              }
            />
          </Grid>
        ) : (
          <Grid className={classes.gridItem} item xs={12}>
            <Card>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          </Grid>
        )}
        {this.props.accountHealthData !== "" ? (
          <Grid item xs={12} className={classes.gridItem}>
            <DataVisualizer
              title={`By Segment - CARR`}
              type={"Overall_Health"}
              chartType="CARR"
              winACVMixAnalysisData={this.props.accountHealthData[1].map(
                (item) => ({ ...item, acv: item.CARR })
              )}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"Account_Segment"}
              page="CustomerSuccess"
              chartName="AccountHealth"
              colors={accountHealthColors}
              unit="acv"
              isDrilldown={true}
              tableComponent={
                <CurrentStatusTable
                  data={this.props.accountHealthData[1]}
                  type="CARR"
                  col="Account_Segment"
                  row="Overall_Health"
                  chartName="accountHealthCurrentStatus"
                  handleChangeLocation={this.handleChangeLocation}
                />
              }
            />
          </Grid>
        ) : (
          <Grid className={classes.gridItem} item xs={12}>
            <Card>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          </Grid>
        )}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Account Health Current Status`}
              back={this.handleChangeLocationBack}
              filters={[
                { title: "Status", value: this.state.filterStatus },
                ...this.state.filtersForDrilldown,
              ]}
            >
              <AccountHealthDrilldown
                header={`Account Health Current Status`}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const {
    accountHealthFilters,
    accountHealthData,
    drilldownOnGraphData,
    user,
  } = state.app;

  return {
    funnelCompareFilters: accountHealthFilters,
    accountHealthData,
    drilldownOnGraphData,
    company: user.company,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChartDrilldown: (key, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getChartDrilldown("C0003.CHID00000017", key, filters).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1];
        tempOpportunities = tempOpportunities.sort((a, b) =>
          a.CARR > b.CARR ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let x = {
            ...item,
            OppNo: index + 1,
          };
          return x;
        });
        // tempOpportunities.sort((a, b) => a.ACV > b.ACV ? -1 : 1)

        // tempOpportunities = tempOpportunities.map((item, index) => {
        //   let createdDateString = (item.Created_Date.split('T')[0])
        //   var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
        //   let temp = {
        //     ...item,
        //     OppNo: index + 1,
        //     age: Math.round(
        //       (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
        //       (1000 * 60 * 60 * 24)
        //     ),
        //   }
        //   return temp
        // })

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            // columns: json.columns
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getAccountHealthFilters: (company) => {
    dispatch({ type: "get_AccountHealth_filters_request" });
    appService.getChartFilters("C0003.CHID00000017").then(
      (json) => {
        dispatch({ type: "get_AccountHealth_filters_success", json });
        dispatch({ type: "get_AccountHealth_data_request" });
        appService
          .getChartData("C0003.CHID00000017", [
            { name: "Status", value: ["Active"], string: true },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_AccountHealth_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_AccountHealth_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_AccountHealth_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealth_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_AccountHealth_filters_failure", error });
      }
    );
  },
  getAccountHealthData: (company, filters = []) => {
    dispatch({ type: "get_AccountHealth_data_request" });
    appService.getChartData("C0003.CHID00000017", filters).then(
      (json) => {
        dispatch({ type: "get_AccountHealth_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealth_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_AccountHealth_data_failure", error });
      }
    );
  },
});

const connectedAccountHealth = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountHealth));
export default connectedAccountHealth;
