import { Card, Divider, Grid, makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import { getNextQuarter } from '../../util/fiscalQuarterFunctions';
const useStyle = makeStyles(theme => ({
    fontBold: {
        fontWeight: 600
    },
}))
export default function DescriptionTable(props) {
    const classes = useStyle();
    return (
        <Grid container justify="center">
            {props.isScoreCardDataLoaded ? (
                <Grid item xs={12} md={5} style={{ margin: '1rem' }} component={Card} raised={true}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ margin: 0, padding: 0, paddingRight: 5 }} align='right'>{props.msDesc ? props.teamOrRep : 'Team'} :</TableCell>
                                <TableCell style={{ paddingLeft: 0 }} className={classes.fontBold} align='left' >{props.selectedTeam}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ paddingRight: 5 }} align='right'>As of :</TableCell>
                                <TableCell style={{ paddingLeft: 0 }} align='left' className={classes.fontBold}>{props.msDesc ? props.settings : props.settings.lastPull !== "" ? props.settings.lastPull : "-"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            ) : (
                    <Card
                        style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: '2rem', alignItems: 'center', flexDirection: 'column', width: '30%' }}
                        raised={true}
                    >
                        <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                        <Divider style={{ width: '100%' }} />
                        <Skeleton animation="wave" variant="rect" width='80%' height={100} style={{ margin: '20px 0px' }} />
                    </Card>
                )}
            {props.isScoreCardDataLoaded ? (<Grid item xs={12} md={3} style={{ margin: '1rem' }} component={Card} raised={true}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ paddingRight: 5 }} align='right'>Current Quarter : </TableCell>
                            <TableCell style={{ paddingLeft: 0 }} align='left' className={classes.fontBold}>{props.msDesc ? props.qtdQuarter : props.qtdQuarter !== undefined ? props.qtdQuarter.map(y => y.Fiscal_quarter_year).toString() : "-"}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ paddingRight: 5, width: 130 }} align="right">Next Quarter : </TableCell>
                            <TableCell style={{ paddingLeft: 0 }} align='left' className={classes.fontBold}>{props.msDesc ? props.nextQuarter :props.qtdQuarter !== undefined ? getNextQuarter(props.qtdQuarter.map(y => y.Fiscal_quarter_year).toString()) : "-"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            ) : (
                    <Card
                        style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: '2rem', alignItems: 'center', flexDirection: 'column', width: '30%' }}
                        raised={true}
                    >
                        <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                        <Divider style={{ width: '100%' }} />
                        <Skeleton animation="wave" variant="rect" width='80%' height={100} style={{ margin: '20px 0px' }} />
                    </Card>
                )}

        </Grid>
    )
}