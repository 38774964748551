import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import React from "react";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { countOrACVFormatter, StyledTableRow } from "../../util/customFunctions";

  const useStyle = makeStyles((theme) => ({
    headerCell: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    dataCell: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    drilldownCell: {
      cursor: "pointer",
    },
  }));
  export default function MeanMedianTable(props) {
    const classes = useStyle();
    const tableRef = React.createRef();
  
    const uniqueCols = ['Won'];
    return (
      <div>
        <TableContainer
          style={{
            padding: "1rem",
            paddingBottom: "3rem",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
            align="center"
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
          <Table
            ref={tableRef}
            size="small"
            aria-label="html table"
            style={{ tableLayout: "auto" }}
            data-test={props.dataTest}
          >
            <TableHead></TableHead>
            <TableBody>
           
              <TableRow>
              <TableCell
                      align="center"
                      className={classes.headerCell}
                      style={{ minWidth: 200 }}
                    ></TableCell>
                {uniqueCols.map((l, i) => {
                  return (
                    <TableCell
                      align="center"
                      className={classes.headerCell}
                      colSpan={2}
                      
                      style={{ background: "#70AD47", color: "white" }}
                      
                    >
                      {l}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow style={{ fontWeight: 600 }}>
                    <TableCell
                      align="center"
                      className={classes.headerCell}
                      style={{ minWidth: 200 }}
                    ></TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            style={{
                              border: "0.5px solid lightgrey",
                              minWidth: 120,
                              maxWidth: 120,
                            }}
                          >
                            Avg.
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            style={{
                              border: "0.5px solid lightgrey",
                              minWidth: 120,
                              maxWidth: 120,
                            }}
                          >
                            Median
                          </TableCell>
                  </TableRow>

                  {props.data.map((d, i) => {
                    return (
                      <>
                        <StyledTableRow key={i} style={{ fontWeight: 600 }}>
                          <TableCell
                            align="left"
                            className={classes.headerCell}
                            colSpan={1}
                          >
                            {d.label}
                          </TableCell>
                          {["won"].map((v) => (
                            <>
                              <TableCell
                                align="right"
                                className={`${classes.repHeader}`}
                                style={{ border: "0.5px solid lightgrey" }}
                              >
                                {![null, undefined, ""].includes(d[v].average)
                                  ? Math.round(d[v].average)
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={`${classes.repHeader}`}
                                style={{ border: "0.5px solid lightgrey" }}
                              >
                                {![null, undefined, ""].includes(d[v].median)
                                  ? Math.round(d[v].median)
                                  : "-"}
                              </TableCell>
                            </>
                          ))}
                        </StyledTableRow>
                      </>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  