import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import NoDataCell from './NoDataCell'
import { coloredCells } from '../utils'
export default function QuarterGetWellTable(props) {
    const classes = tableStyle()

    const rows = props.data.map(item => item.Qtr_for_Green).filter(getUniqueValues).map(item => item === null ? "-" : item)
    let column2ndLevel = props.columns !== undefined ? props.columns : props.data.map(item => item.Overall_Health).filter(getUniqueValues).map(item => item === null ? "-" : item)
    column2ndLevel = props.chartName === "getWellAccounts" ? column2ndLevel.filter(item => item !== "Green" && item !== "Green +") : column2ndLevel
    let columns = props.data.map(item => item.AH_Team).filter(getUniqueValues).map(item => item === null ? "-" : item)
    const drilldownHandler = (col, row, meta, it) => {

        if (props.chartName === "getWellAccounts") {
            props.handleChangeLocation("drilldownOnGraph", { col, row }, { meta }, it)
        }
    }

    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ?
                            <>
                                <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Team</TableCell>
                                    {columns.map((item, index) => <TableCell colSpan={column2ndLevel.length + 1} align="center" style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{item}</TableCell>)}

                                </TableRow>
                                <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Well by Qtr.</TableCell>
                                    {columns.map(col => <>{column2ndLevel.map(item => <TableCell style={{ backgroundColor: coloredCells[item], color: 'black' }} align="center" className={classes.colHeader}>{item}</TableCell>)}<TableCell align="center" className={classes.colHeader}>{`${col} Total`}</TableCell></>)}
                                    <TableCell className={classes.colHeader} >Total</TableCell>
                                </TableRow>
                                {
                                    rows.map(row =>
                                        <>
                                            <StyledTableRow>
                                                <TableCell align="left" style={{ minWidth: 100 }} className={classes.dataCell}>{row}</TableCell>
                                                {columns.map(col => {
                                                    const cellData = props.data.filter(item => item.AH_Team === col && item.Qtr_for_Green === row)
                                                    return <>{
                                                        column2ndLevel.map(it => {

                                                            return <TableCell align="right" onClick={() => drilldownHandler(col, row, null, it)} className={classes.drilldownCell}>{cellData.filter(item => item.Overall_Health === it)[0] === undefined ? "-" : cellData.filter(item => item.Overall_Health === it)[0].SFDC}</TableCell>
                                                        })
                                                    }
                                                        <TableCell align="right" onClick={() => drilldownHandler(col, row, 'totalCol1', column2ndLevel)} className={classes.drilldownCell}>{props.data.filter(item => item.AH_Team === col && item.Qtr_for_Green === row).map(item => item.SFDC).reduce((a, b) => a + b, 0)}</TableCell>
                                                    </>

                                                })}
                                                <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol', null)} className={classes.drilldownCell} >{props.data.filter(item => item.Qtr_for_Green === row).map(item => item.SFDC).reduce((a, b) => a + b, 0)}</TableCell>
                                            </StyledTableRow>
                                        </>)

                                }
                                {
                                    <StyledTableRow>
                                        <TableCell align="left" className={classes.dataCell}>Total</TableCell>
                                        {columns.map(col => {
                                            const cellData = props.data.filter(item => item.AH_Team === col)
                                            return <>{
                                                column2ndLevel.map(it => {

                                                    return <TableCell align="right" onClick={() => drilldownHandler(col, rows, null, it)} className={classes.drilldownCell} >{cellData.filter(item => item.Overall_Health === it).reduce((a, b) => a + b.SFDC, 0)}</TableCell>
                                                })
                                            }
                                                <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalCol1', column2ndLevel)} className={classes.drilldownCell} >{props.data.filter(item => item.AH_Team === col).map(item => item.SFDC).reduce((a, b) => a + b, 0)}</TableCell>
                                            </>

                                        })}
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalCol', null)} className={classes.drilldownCell}>{props.data.map(item => item.SFDC).reduce((a, b) => a + b, 0)}</TableCell>
                                    </StyledTableRow>
                                }
                            </>
                            : <NoDataCell />
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}
