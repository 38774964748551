import * as d3Sankey from "d3-sankey";
import * as d3 from "d3";
import * as d3Arr from "d3-array";
import {
  updatedScheme10,
  updatedScheme10Sankey,
} from "../../util/chartColorScheme";
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/sankey-diagram
export function D3SankeyChart(
  {
    nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
    links, // an iterable of link objects (typically [{source, target}, …])
  },
  {
    stageGroupingColorMap,
    format = ",", // a function or format specifier for values in titles
    align = "justify", // convenience shorthand for nodeAlign
    nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
    nodeGroup, // given d in nodes, returns an (ordinal) value for color
    nodeGroups, // an array of ordinal values representing the node groups
    nodeLabel, // given d in (computed) nodes, text to label the associated rect
    nodeTitle = (d) => `${d.id}\n${format(d.value)}`, // given d in (computed) nodes, hover text
    nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
    nodeWidth = 20, // width of node rects
    nodePadding = 15, // vertical separation between adjacent nodes
    nodeLabelPadding = 6, // horizontal separation between node and label
    nodeStroke = "currentColor", // stroke around node rects
    nodeStrokeWidth, // width of stroke around node rects, in pixels
    nodeStrokeOpacity, // opacity of stroke around node rects
    nodeStrokeLinejoin, // line join for stroke around node rects
    linkSource = ({ source }) => source, // given d in links, returns a node identifier string
    linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
    linkValue = ({ value }) => value, // given d in links, returns the quantitative value
    linkPath = d3Sankey.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
    linkTitle = (d) =>
      `${d.source.id.split("|")[1]} ${d.source.id.split("|")[3]} → ${
        d.target.id.split("|")[1]
      } ${d.target.id.split("|")[3]}\n${format(d.value)}`, // given d in (computed) links
    linkColor = "source", // source, target, source-target, or static color
    linkStrokeOpacity = 0.5, // link stroke opacity
    linkMixBlendMode = "multiply", // link blending mode
    colors = updatedScheme10Sankey, // array of colors
    width = 1100, // outer width, in pixels
    height = 400, // outer height, in pixels
    marginTop = 5, // top margin, in pixels
    marginRight = 1, // right margin, in pixels
    marginBottom = 5, // bottom margin, in pixels
    marginLeft = 1, // left margin, in pixels
  } = {}
) {
  console.log("sankey_data", links);
  // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
  if (typeof nodeAlign !== "function")
    nodeAlign =
      {
        left: d3Sankey.sankeyLeft,
        right: d3Sankey.sankeyRight,
        center: d3Sankey.sankeyCenter,
      }[nodeAlign] ?? d3Sankey.sankeyJustify;

  // Compute values.
  const LS = d3Arr.map(links, linkSource).map(intern);
  const LT = d3Arr.map(links, linkTarget).map(intern);
  const LV = d3Arr.map(links, linkValue);
  console.log(LS, LT, LV, "sankey_data");
  if (nodes === undefined)
    nodes = Array.from(d3Arr.union(LS, LT), (id) => ({ id }));
  const N = d3Arr.map(nodes, nodeId).map(intern);
  const G =
    nodeGroup == null ? null : d3Arr.map(nodes, (d) => d.id).map(intern);
  console.log(G, nodeGroup, nodes, nodeGroups, d3Arr.map(nodes, nodeGroup));
  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3Arr.map(nodes, (_, i) => ({ id: N[i] }));
  links = d3Arr.map(links, (_, i) => ({
    source: LS[i],
    target: LT[i],
    value: LV[i],
  }));

  // Ignore a group-based linkColor option if no groups are specified.
  if (!G && ["source", "target", "source-target"].includes(linkColor))
    linkColor = "currentColor";

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = G;
  console.log(nodeGroups);
  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);
  console.log(nodes, "sankey_data");
  // Compute the Sankey layout.
  d3Sankey
    .sankey()
    .nodeId(({ index: i }) => {
      // console.log(N[i])
      return N[i];
    })
    .nodeAlign(nodeAlign)
    .nodeWidth(nodeWidth)
    .nodePadding(nodePadding)
    .extent([
      [marginLeft, marginTop],
      [width - marginRight, height - marginBottom],
    ])({ nodes, links });

  // Compute titles and labels using layout nodes, so as to access aggregate values.
  if (typeof format !== "function") format = d3.format(format);
  const Tl =
    nodeLabel === undefined
      ? N
      : nodeLabel == null
      ? null
      : d3Arr.map(nodes, nodeLabel);
  const Tt = nodeTitle == null ? null : d3Arr.map(nodes, nodeTitle);
  const Lt = linkTitle == null ? null : d3Arr.map(links, linkTitle);
  // console.log(Tt, Lt, links, linkTitle);

  // A unique identifier for clip paths (to avoid conflicts).
  const uid = `O-${Math.random().toString(16).slice(2)}`;

  const svg = d3
    .create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
  console.log(nodes, "sankey_data");
  const node = svg
    .append("g")
    .attr("stroke", nodeStroke)
    .attr("stroke-width", nodeStrokeWidth)
    .attr("stroke-opacity", nodeStrokeOpacity)
    .attr("stroke-linejoin", nodeStrokeLinejoin)
    .selectAll("rect")
    .data(nodes)
    .join("rect")
    .attr("x", (d) => d.x0)
    .attr("y", (d) => d.y0)
    .attr("height", (d) => d.y1 - d.y0)
    .attr("width", (d) => d.x1 - d.x0);

  if (G)
    node.attr("fill", (d) => {
      // console.log(d, "sankey_text");
      return stageGroupingColorMap[d.id.split("|")[3]];
    });
  if (Tt) node.append("title").text(({ index: i }) => Tt[i]);

  const link = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke-opacity", linkStrokeOpacity)
    .selectAll("g")
    .data(links)
    .join("g")
    .style("mix-blend-mode", linkMixBlendMode);

  if (linkColor === "source-target")
    link
      .append("linearGradient")
      .attr("id", (d) => `${uid}-link-${d.index}`)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", (d) => d.source.x1)
      .attr("x2", (d) => d.target.x0)
      .call((gradient) =>
        gradient
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-color", (d) => {
            // console.log("sankey_text", d);
            return stageGroupingColorMap[d.source.id.split("|")[3]];
          })
      )
      .call((gradient) =>
        gradient
          .append("stop")
          .attr("offset", "100%")
          .attr("stop-color", (d) => {
            // console.log("sankey_text", d);
            return stageGroupingColorMap[d.target.id.split("|")[3]];
          })
      );

  link
    .append("path")
    .attr("d", linkPath)
    .attr(
      "stroke",
      linkColor === "source-target"
        ? ({ index: i }) => `url(#${uid}-link-${i})`
        : linkColor === "source"
        ? (d) => {
            console.log("sankey_text", d);
            return color(G[d.source.index]);
          }
        : linkColor === "target"
        ? (d) => {
            console.log("sankey_text", d);
            return color(G[d.target.index]);
          }
        : linkColor
    )
    .attr("stroke-width", ({ width }) => Math.max(1, width))
    .call(
      Lt
        ? (path) => path.append("title").text(({ index: i }) => Lt[i])
        : () => {}
    );

  if (Tl)
    svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 14)
      .selectAll("text")
      .data(nodes)
      .join("text")
      .attr("x", (d) =>
        d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding
      )
      .attr("y", (d) => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", (d) => (d.x0 < width / 2 ? "start" : "end"))
      .text(({ index: i }) => `${Tl[i].split("|")[1]} ${Tl[i].split("|")[3]}`);

  function intern(value) {
    return value !== null && typeof value === "object"
      ? value.valueOf()
      : value;
  }

  return Object.assign(svg.node(), { scales: { color } });
}
