export const getNextQuarter = quarter => {
  const [year, quart] = quarter.split("-")
  if (quart === "Q4") {
    return `${parseInt(year) + 1}-Q1`
  } else if (quart === "Q3") {
    return `${parseInt(year)}-Q4`
  } else if (quart === "Q2") {
    return `${parseInt(year)}-Q3`
  } else if (quart === "Q1") {
    return `${parseInt(year)}-Q2`
  }

}
export const getPriorQuarter = quarter => {
  const [year, qtr] = quarter.split("-")
  if (qtr === "Q1") {
    return `${parseInt(year) - 1}-Q4`
  } else if (qtr === "Q2") {
    return `${year}-Q1`
  } else if (qtr === "Q3") {
    return `${year}-Q2`
  } else {
    return `${year}-Q3`
  }
}
export const getNPriorQuarters = (quarter, n) => {
  let quarters = [];
  let temp = quarter
  for (let i = 0; i < n; i++) {
    quarters.push(getPriorQuarter(temp))
    temp = getPriorQuarter(temp)
  }
  return quarters
}
export const getNNextQuarters = (quarter, n) => {
  let quarters = [];
  let temp = quarter
  for (let i = 0; i < n; i++) {
    quarters.push(getNextQuarter(temp))
    temp = getNextQuarter(temp)
  }
  return quarters
}

export const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear()
  let currentMonth = new Date().getMonth()
  if (currentMonth < 3) {
    return `${year - 1}-Q4`
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q1`
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q2`
  } else {
    return `${year}-Q3`
  }
}
export const getCurrentFiscalQuarter = () => {
  let year = new Date().getFullYear()
  let currentMonth = new Date().getMonth()
  if (currentMonth < 3) {
    return `${year}-Q1`
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q2`
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q3`
  } else {
    return `${year}-Q4`
  }
}
export const nearestFutureFiscalQuarter = () => {
  let year = new Date().getFullYear()
  let currentMonth = new Date().getMonth()
  if (currentMonth < 3) {
    return `${year}-Q2`
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q3`
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q4`
  } else {
    return `${year + 1}-Q1`
  }
}