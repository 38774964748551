import 'date-fns';
import React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './DatePicker_v2.css'

export default function MaterialUIPickersv2(props) {
    const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));
    const [error, setError] = React.useState(false); // Track error state
  
    React.useEffect(() => {
      const date = props.current.split('-');
      if (props.current === '') {
        setSelectedDate(null); // Set selectedDate to null for empty props.current
        setError(false); // Reset error state when props.current is empty
      } else {
        setSelectedDate(new Date(date[2], date[0] - 1, date[1]));
        setError(!isValidDate(new Date(date[2], date[0] - 1, date[1])));
      }
    }, [props.current]);
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
      if (isValidDate(date)) {
        setError(false); // Reset error state when a valid date is selected
        props.setDate(date);
      } else {
        setError(true); // Set error state if the selected date is invalid
      }
    };
  
    const isValidDate = (d) => d instanceof Date && !isNaN(d);
  
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          allowSameDateSelection
          showToolbar
          disabled={props.disabled}
          inputFormat="MMM-dd-yyyy"
          margin="none"
          id="date-picker-inline"
          label={props.label}
          value={selectedDate}
          disableHighlightToday
          onChange={handleDateChange}
          minDate={props.min}
          maxDate={props.max}
          showToolbar
          shouldDisableDate={props.shouldDisableDate}
          InputLabelProps={{ style: { color: props.disabled ? '' : '#4472c4' } }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              helperText={error && props.errorText !== '' ? props.errorText : ''}
              error={error && props.errorText !== ''} // Set error state for TextField
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
  
  