// LEGENDS

import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Box from "@material-ui/core/Box";

// const useStyle = makeStyles((theme) => ({
//   square: {
//     height: 10,
//     width: 10,
//   },
// }));
export default function Legend(props) {
  const { legends } = props;
  // const classes = useStyle();
  return (
    // <Grid container justifyContent="left" style={{ marginLeft: "8rem" }}>
    //   {legends.map((item, i) => (
    //     <Grid key={i} item xs={4}>
    //       <Grid
    //         item
    //         style={{
    //           marginTop:
    //             legends.length >= 6
    //               ? "2.2rem"
    //               : item.stage_name.length > 20
    //               ? "2.2rem"
    //               : "1.5rem",
    //         }}
    //       >
    //         <Grid
    //           item
    //           style={{
    //             height: 15,
    //             width: 15,
    //             background: item.backward_color_hash,
    //           }}
    //         >
    //           <Grid
    //             item
    //             style={{
    //               width: "10rem",
    //             }}
    //           >
    //             <Box display="flex" style={{ width: "11rem" }}>
    //               <Typography style={{ marginLeft: "2rem" }}>
    //                 {item.stage_name}
    //                 {console.log("length_check", legends.length)}
    //               </Typography>
    //             </Box>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   ))}
    // </Grid>
    <div style={{ marginBottom: "2rem", width: "95%" }}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "2rem",
          // justifyContent: "center",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        {legends.map((item, i) => (
          <div
            style={{
              flexDirection: "row",
              width: "11rem",
              // justifyContent: "space-around",
              marginBottom: item.stage_name.length > 15 ? "3rem" : "auto",
              // marginBottom: "3rem",
            }}
          >
            <Grid item xs={2}>
              <div
                style={{
                  height: 15,
                  width: 15,
                  background: item.backward_color_hash,
                }}
              >
                <Box
                  display="flex"
                  style={
                    legends.length >= 7
                      ? { width: "10rem" }
                      : { width: "16rem" }
                  }
                >
                  <Typography style={{ marginLeft: "2rem" }}>
                    {item.stage_name}
                  </Typography>
                </Box>
              </div>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
}