import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { appService } from "../../App/app.service";
import { AgGridReact } from "ag-grid-react";
import "./PerformanceByIndustry.scss";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  getDefaultSelectedFiscalQuarters,
  getUniqueValues,
  getDefaultSalesType,
} from "../../util/customFunctions";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 1200,
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  selected: {
    background: "#4472c4",
    color: "white",
  },
});

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (params) => {
  if (_isNaN(params.value)) return 0;
  return `$${d3.format(",")(Math.round(params.value))}K`;
};

const countFormatter = (params) => {
  if (_isNaN(params.value)) return 0;
  return d3.format(",")(Math.round(params.value));
};

const percentageFormatter = (params) => {
  if (_isNaN(params.value)) return d3.format(".1%")(0);
  return d3.format(".1%")(params.value);
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      allowNegative={false}
    />
  );
}

class PerformanceByIndustry extends React.Component {
  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    Type: ["All"],
    tab: 0,
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
      sortingOrder: ["desc", "asc"],
    },
    rowStyle: { fontSize: 5 },
    columnDefs: [],
    rowData: [],
    Closed_Year_Month: ["All"],
    closed_year: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    projected_close_date_year: ["All"],
    projected_close_date_month: ["All"],
    projected_close_date_fiscal_quarter: ["All"],
    open_calendar_year: ["All"],
    open_calendar_month: ["All"],
    open_fiscal_quarter: ["All"],
    validated_year: ["All"],
    validated_month: ["All"],
    validated_fiscal_quarter: ["All"],
    rep: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Region: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvlt: 0,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",

    // data: {
    //     stages: [
    //         { label: 'Suspect', noOfOpps: 419, ACV: 17575757, avgACV: 41656, avgDaysOpen: 196 },
    //         { label: 'Qualified', noOfOpps: 193, ACV: 9845574, avgACV: 50451, avgDaysOpen: 140 },
    //         { label: 'Proposed', noOfOpps: 94, ACV: 2789457, avgACV: 29547, avgDaysOpen: 140 },
    //         { label: 'Selected', noOfOpps: 284, ACV: 50556656, avgACV: 1780, avgDaysOpen: 139 },
    //         { label: 'Comitted', noOfOpps: 45, ACV: 452205, avgACV: 11245, avgDaysOpen: 77 },
    //     ]
    // },
  };
  variableCols =
    this.props.company === "C0003"
      ? {
          Acct_Vertical: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        }
      : {
          Acct_Vertical: "Solution_Cat",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
        };

  filters = [
    "closed_year",
    "closed_month",
    "closed_fiscal_quarter",
    "Closed_Year_Month",
    this.variableCols.year,
    this.variableCols.month,
    this.variableCols.quarter,
    "rep",
    "team",
    this.variableCols.CustomerSegment,
    this.variableCols.CustomerType,
    this.variableCols.LeadSource,
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
    "Type",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  orderRepsByLastName = () => {
    let temp = this.props.performance_by_industryFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  componentDidMount = () => {
    this.props.getPerformanceByIndustryFilters(this.props.company);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.performance_by_industryFilters !== "" &&
      prevProps.performance_by_industryFilters === ""
    ) {
      let temp = getDefaultSelectedFiscalQuarters(
        this.props.performance_by_industryFilters,
        "closed_fiscal_quarter"
      );
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.performance_by_industryFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.performance_by_industryFilters.filter(
            (s) => s.type === "Type"
          ),
          "Type"
        );
      }
      this.setState({
        ...prevState,
        closed_fiscal_quarter: temp,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Solution_Cat: this.props.performance_by_industryFilters
          .filter((f) => f.sub_type === "default_filters")
          .map((d) => d.Solution_Cat),
      });
    }

    if (
      prevProps.performance_by_industryData !==
      this.props.performance_by_industryData
    ) {
      let industries = this.props.performance_by_industryData.rows;
      var sortedUniquestages = {};
      industries.map(
        (item) =>
          (sortedUniquestages[item] =
            this.props.performance_by_industryData.data
              .filter((f) => f.row === item)
              .map((i) => i.Pursued)
              .reduce((a, b) => a + b, 0))
      );
      const sortable = Object.fromEntries(
        Object.entries(sortedUniquestages)
          .sort(([, a], [, b]) => a - b)
          .reverse()
      );
      industries = Object.keys(sortable);
      let countIndustries = this.props.performance_by_industryData.rows;
      var countsortedUniquestages = {};
      countIndustries.map(
        (item) =>
          (countsortedUniquestages[item] =
            this.props.performance_by_industryData.dataCount
              .filter((f) => f.row === item)
              .map((i) => i.Pursued)
              .reduce((a, b) => a + b, 0))
      );
      const countsortable = Object.fromEntries(
        Object.entries(countsortedUniquestages)
          .sort(([, a], [, b]) => a - b)
          .reverse()
      );
      countIndustries = Object.keys(countsortable);
      const solution_categories_all =
        this.props.performance_by_industryData.columns;
      const solution_categories =
        this.props.performance_by_industryData.columns;
      //const max = this.props.pbiNew.max
      const lastRow = this.props.performance_by_industryData.lastRow;
      //const column = this.props.pbiNew.column
      //const row = this.props.pbiNew.row
      const columnLabel = this.props.performance_by_industryData.columnName;
      const rowLabel = this.props.performance_by_industryData.rowName;
      //const settings = this.props.pbiNew.settings
      this.setState({
        columnDefs: [
          {
            headerName: `${columnLabel}`,
            children: [
              {
                headerName: `${rowLabel}`,
                field: rowLabel,
                minWidth: 150,
                sortable: true,
                filter: true,
                pinned: "left",
                sortingOrder: ["asc", "desc"],
              },
            ],
          },
          ...solution_categories_all.map((c) => {
            return {
              headerName: c,
              children: [
                {
                  headerName: `Won`,
                  field: `Won_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: formatter,
                },
                {
                  headerName: `%Won`,
                  field: `%Won_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: percentageFormatter,
                },
                {
                  headerName: `Pursued`,
                  field: `Targeted_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: formatter,
                },
                {
                  headerName: `%Total`,
                  field: `%Total_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: percentageFormatter,
                },
              ],
            };
          }),
        ],
        rowData: [
          ...industries.map((c) => {
            const head = {
              [rowLabel]: c,
            };
            solution_categories.map((s) => {
              const total = this.props.performance_by_industryData.data.filter(
                (d) => d.row === c && d.column === s
              )[0]
                ? this.props.performance_by_industryData.data.filter(
                    (d) => d.row === c && d.column === s
                  )[0].Pursued / 1000
                : 0;

              const won = this.props.performance_by_industryData.data.filter(
                (d) => d.row === c && d.column === s
              )[0]
                ? this.props.performance_by_industryData.data.filter(
                    (d) => d.row === c && d.column === s
                  )[0].Won / 1000
                : 0;

              const percTotal =
                this.props.performance_by_industryData.data.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.data.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% of Total"]
                  : 0;

              const percWon =
                this.props.performance_by_industryData.data.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.data.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% Won"]
                  : 0;

              head[`Won_${s}`] = won;
              head[`Targeted_${s}`] = total;
              head[`%Won_${s}`] = percWon;
              head[`%Total_${s}`] = percTotal;
            });
            return head;
          }),
        ],
        pinnedBottomRowData: [
          ...[`Total for ${columnLabel}`].map((c) => {
            const head = {
              [rowLabel]: c,
            };
            solution_categories.map((s) => {
              const total =
                this.props.performance_by_industryData.lastRow.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRow.filter(
                      (d) => d.row === c && d.column === s
                    )[0].Pursued / 1000
                  : 0;

              const won = this.props.performance_by_industryData.lastRow.filter(
                (d) => d.row === c && d.column === s
              )[0]
                ? this.props.performance_by_industryData.lastRow.filter(
                    (d) => d.row === c && d.column === s
                  )[0].Won / 1000
                : 0;

              const percTotal =
                this.props.performance_by_industryData.lastRow.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRow.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% of Total"]
                  : 0;

              const percWon =
                this.props.performance_by_industryData.lastRow.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRow.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% Won"]
                  : 0;

              head[`Won_${s}`] = won;
              head[`Targeted_${s}`] = total;
              head[`%Won_${s}`] = percWon;
              head[`%Total_${s}`] = percTotal;
            });
            return head;
          }),
        ],

        columnDefs2: [
          {
            headerName: `${columnLabel}`,
            children: [
              {
                headerName: `${rowLabel}`,
                field: rowLabel,
                minWidth: 150,
                sortable: true,
                filter: true,
                pinned: "left",
                sortingOrder: ["asc", "desc"],
              },
            ],
          },
          ...solution_categories_all.map((c) => {
            return {
              headerName: c,
              children: [
                {
                  headerName: `Won`,
                  field: `Won_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: countFormatter,
                },
                {
                  headerName: `%Won`,
                  field: `%Won_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: percentageFormatter,
                },
                {
                  headerName: `Pursued`,
                  field: `Targeted_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: countFormatter,
                },
                {
                  headerName: `%Total`,
                  field: `%Total_${c}`,
                  minWidth: 60,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  valueFormatter: percentageFormatter,
                },
              ],
            };
          }),
        ],
        rowData2: [
          ...countIndustries.map((c) => {
            const head = {
              [rowLabel]: c,
            };
            solution_categories.map((s) => {
              const total =
                this.props.performance_by_industryData.dataCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.dataCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0].Pursued
                  : 0;

              const won =
                this.props.performance_by_industryData.dataCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.dataCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0].Won
                  : 0;

              const percTotal =
                this.props.performance_by_industryData.dataCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.dataCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% of Total"]
                  : 0;

              const percWon =
                this.props.performance_by_industryData.dataCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.dataCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% Won"]
                  : 0;

              head[`Won_${s}`] = won;
              head[`Targeted_${s}`] = total;
              head[`%Won_${s}`] = percWon;
              head[`%Total_${s}`] = percTotal;
            });
            return head;
          }),
        ],
        pinnedBottomRowData2: [
          ...[`Total for ${columnLabel}`].map((c) => {
            const head = {
              [rowLabel]: c,
            };
            solution_categories.map((s) => {
              const total =
                this.props.performance_by_industryData.lastRowCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRowCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0].Pursued
                  : 0;

              const won =
                this.props.performance_by_industryData.lastRowCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRowCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0].Won
                  : 0;

              const percTotal =
                this.props.performance_by_industryData.lastRowCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRowCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% of Total"]
                  : 0;

              const percWon =
                this.props.performance_by_industryData.lastRowCount.filter(
                  (d) => d.row === c && d.column === s
                )[0]
                  ? this.props.performance_by_industryData.lastRowCount.filter(
                      (d) => d.row === c && d.column === s
                    )[0]["% Won"]
                  : 0;

              head[`Won_${s}`] = won;
              head[`Targeted_${s}`] = total;
              head[`%Won_${s}`] = percWon;
              head[`%Total_${s}`] = percTotal;
            });
            return head;
          }),
        ],
      });
    }
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  /*   handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
      let filters = this.filters.map(f => {
        return {
          name: f,
          value: this.state[f]
        }
      }).filter(f => !Array.isArray(f.value) || !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
      this.props.getPerformanceByIndustryData(filters)
    } */

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.performance_by_industryFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "Record_Type_Name__c"
              ? true
              : this.props.performance_by_industryFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.performance_by_industryFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    var solutionCategory;
    if (this.props.company !== "C0003") {
      if (this.state.Solution_Cat.includes("All")) {
        solutionCategory = this.props.performance_by_industryFilters
          .filter(
            (y) =>
              y.type === this.variableCols.Acct_Vertical &&
              y[this.variableCols.Acct_Vertical] !== ""
          )
          .filter((s) => s.sub_type !== "default_filters")
          .map((d) => d[this.variableCols.Acct_Vertical]);
      } else {
        solutionCategory = this.state.Solution_Cat;
      }
    }
    this.props.getPerformanceByIndustryData(filters, solutionCategory);
  };

  gridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  resetGridFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      ...this.state,
      tab: newValue,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 20 }} xs={12}>
          <Typography variant='h2' align='center'>Performance by Industry</Typography>
          <Typography variant='body1'>
            Explore how various product categories are performing by industry.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Performance by Industry",
          "Explore how various product categories are performing by industry."
        )}
        {this.props.performance_by_industryFilters !== "" ? (
          <>
            {/* closed dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.closed_year[0] !== "All" ||
                      this.state.closed_month[0] !== "All" ||
                      this.state.Closed_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Quarter:
                    </InputLabel>
                    <Select
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters.map((y) =>
                          y.type === "closed_fiscal_quarter" ? (
                            <MenuItem
                              key={y["closed_fiscal_quarter"]}
                              value={y["closed_fiscal_quarter"]}
                            >
                              {y["closed_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.closed_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-year-label"
                    >
                      Closed Month:
                    </InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-Closed_Year_Month"
                      value={this.state.Closed_Year_Month}
                      onChange={this.handleChange}
                      name="Closed_Year_Month"
                      data-test="Closed_Year_Month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {/* {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters.map((y) =>
                          y.type === "Closed_Year_Month" ? (
                            <MenuItem
                              key={y["Closed_Year_Month"]}
                              value={y["Closed_Year_Month"]}
                            >
                              {y["Closed_Year_Month"]}
                            </MenuItem>
                          ) : null
                        )} */}
                      {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters
                          .filter((y) => y.type === "Closed_Year_Month")
                          .sort()
                          .reverse()
                          .map((y) => (
                            <MenuItem
                              key={y["Closed_Year_Month"]}
                              value={y["Closed_Year_Month"]}
                            >
                              {y["Closed_Year_Month"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Rep Team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.performance_by_industryFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Adding Acv Value */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              md={4}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                style={{ paddingTop: 5 }}
                component={Paper}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.performance_by_industryFilters !== "" &&
                      this.props.performance_by_industryFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.performance_by_industryFilters !== "" &&
                      this.props.performance_by_industryFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            {/* <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.performance_by_industryFilters !== "" &&
                      this.props.performance_by_industryFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            {/* projected close dates */}
            {/*
        <Grid container item style={{ marginBottom: 10, padding: 10 }} xs={12} lg={6} >
          <Grid item spacing={2} xs={12} container component={Paper} justify='space-around'>

            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} disabled={this.state.projected_close_calendar_year[0] !== 'All' || this.state.projected_close_calendar_month[0] !== 'All'}>
                <InputLabel className={classes.filterLabel} id="select-fiscalQuarter-label">Proj. Close Fiscal Quarter:</InputLabel>
                <Select
                  value={this.state[this.variableCols.projectedQuarter]}
                  onChange={this.handleChange}
                  name={this.variableCols.projectedQuarter}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters.map(y => y.type === this.variableCols.projectedQuarter ? <MenuItem key={y[this.variableCols.projectedQuarter]} value={y[this.variableCols.projectedQuarter]}>{y[this.variableCols.projectedQuarter]}</MenuItem> : null)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} disabled={this.state.projected_close_fiscal_quarter[0] !== 'All'}>
                <InputLabel className={classes.filterLabel} id="select-year-label">Proj. Close Calendar Year:</InputLabel>
                <Select
                  value={this.state[this.variableCols.projectedYear]}
                  onChange={this.handleChange}
                  name={this.variableCols.projectedYear}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters.map(y => y.type === this.variableCols.projectedYear ? <MenuItem key={y[this.variableCols.projectedYear]} value={y[this.variableCols.projectedYear]}>{y[this.variableCols.projectedYear]}</MenuItem> : null)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl disabled={this.state.projected_close_fiscal_quarter[0] !== 'All'} fullWidth className={classes.formControl}>
                <InputLabel className={classes.filterLabel} id="select-month-label">Proj. Close Calendar Month:</InputLabel>
                <Select
                  value={this.state[this.variableCols.projectedMonth]}
                  onChange={this.handleChange}
                  name={this.variableCols.projectedMonth}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(y => <MenuItem key={y} value={y}>{this.months[y - 1]}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Grid> */}
            {/* Opp_Type Filter Added */}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* C0010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChange}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === "Record_Type_Name__c" &&
                                y.Record_Type_Name__c !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Record_Type_Name__c}
                                value={y.Record_Type_Name__c}
                              >
                                {y.Record_Type_Name__c}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0017" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter((y) => y.type === "Type" && y.Type !== "")
                            .map((y) => (
                              <MenuItem key={y.Type} value={y.Type}>
                                {y.Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* industry, customer segment, customer type */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={this.props.company === "C0003" ? 4 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters &&
                        this.props.performance_by_industryFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.performance_by_industryFilters !== "" &&
                        this.props.performance_by_industryFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                {this.props.company !== "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Acct_Vertical
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Vertical:"
                          : "Solution Category:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Acct_Vertical]}
                        onChange={this.handleChange}
                        name={this.variableCols.Acct_Vertical}
                        data-test={this.variableCols.Acct_Vertical}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Acct_Vertical &&
                                y[this.variableCols.Acct_Vertical] !== ""
                            )
                            .filter((s) => s.sub_type !== "default_filters")
                            .sort((a, b) =>
                              a[this.variableCols.Acct_Vertical] <
                              b[this.variableCols.Acct_Vertical]
                                ? -1
                                : a[this.variableCols.Acct_Vertical] >
                                  b[this.variableCols.Acct_Vertical]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Acct_Vertical]}
                                value={y[this.variableCols.Acct_Vertical]}
                              >
                                {y[this.variableCols.Acct_Vertical]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={12}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                justify="space-between"
                xs={4}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.cross_sell1__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state[this.variableCols.cross_sell1__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.cross_sell1__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.performance_by_industryFilters !== "" &&
                          this.props.performance_by_industryFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs
              /* xs={2} xl={1} */ container
              justify="flex-start"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGo}
                disabled={this.state.acvError !== ""}
                style={{ marginRight: 5 }}
              >
                GO
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.resetGridFilters}
              >
                reset grid
              </Button>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {/* Open/Validated dates */}
        {/* 
        <Grid container item style={{ marginBottom: 10, padding: 10 }} xs={12} lg={6}>
          <Grid item spacing={2} xs={12} container component={Paper} justify='space-around'>

            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} disabled={this.state.open_calendar_year[0] !== 'All' || this.state.open_calendar_month[0] !== 'All'}>
                <InputLabel className={classes.filterLabel} id="select-fiscalQuarter-label">{this.props.company === "C0003" ? 'Stage E ' : 'Open '} Fiscal Quarter:</InputLabel>
                <Select
                  value={this.state[this.variableCols.quarter]}
                  onChange={this.handleChange}
                  name={this.variableCols.quarter}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters.map(y => y.type === this.variableCols.quarter ? <MenuItem key={y[this.variableCols.quarter]} value={y[this.variableCols.quarter]}>{y[this.variableCols.quarter]}</MenuItem> : null)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} disabled={this.state.open_fiscal_quarter[0] !== 'All'}>
                <InputLabel className={classes.filterLabel} id="select-year-label">{this.props.company === "C0003" ? 'Stage E ' : 'Open '} Calendar Year:</InputLabel>
                <Select
                  value={this.state[this.variableCols.year]}
                  onChange={this.handleChange}
                  name={this.variableCols.year}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters.map(y => y.type === this.variableCols.year ? <MenuItem key={y[this.variableCols.year]} value={y[this.variableCols.year]}>{y[this.variableCols.year]}</MenuItem> : null)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
              <FormControl disabled={this.state.open_fiscal_quarter[0] !== 'All'} fullWidth className={classes.formControl}>
                <InputLabel className={classes.filterLabel} id="select-month-label">{this.props.company === "C0003" ? 'Stage E ' : 'Open '} Calendar Month:</InputLabel>
                <Select
                  value={this.state[this.variableCols.month]}
                  onChange={this.handleChange}
                  name={this.variableCols.month}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(y => <MenuItem key={y} value={y}>{this.months[y - 1]}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Grid> */}

        {/* Lead source, opportunity created by, solution cat */}
        {/* 
        <Grid container item className={classes.inputFieldContainers} justify='space-between' xs={12} lg={this.props.company === 'C0003' ? 4 : 6} >
          <Grid item spacing={2} xs={12} container component={Paper}>
            
            <Grid item xs={12} sm={this.props.company === 'C0003' ? 6 : 4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} >
                <InputLabel className={classes.filterLabel} id="select-region-label">Lead Source:</InputLabel>
                <Select
                  value={this.state[this.variableCols.LeadSource]}
                  onChange={this.handleChange}
                  name={this.variableCols.LeadSource}
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters
                    .filter(y => y.type === this.variableCols.LeadSource && y[this.variableCols.LeadSource] !== '')
                    .sort((a, b) => a[this.variableCols.LeadSource] < b[this.variableCols.LeadSource] ? -1 : a[this.variableCols.LeadSource] > b["Lead_Source"] ? 1 : 0)
                    .map(y => <MenuItem key={y[this.variableCols.LeadSource]} value={y[this.variableCols.LeadSource]}>{y[this.variableCols.LeadSource]}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={this.props.company === 'C0003' ? 6 : 4} style={{ paddingTop: 0 }}>
              <FormControl fullWidth className={classes.formControl} >
                <InputLabel className={classes.filterLabel} id="select-industry-label">Opportunity created by:</InputLabel>
                <Select
                  value={this.state.Opp_Created_By}
                  onChange={this.handleChange}
                  name='Opp_Created_By'
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters
                    .filter(y => y.type === "Opp_Created_By" && y["Opp_Created_By"] !== '')
                    .sort((a, b) => a["Opp_Created_By"] < b["Opp_Created_By"] ? -1 : a["Opp_Created_By"] > b["Opp_Created_By"] ? 1 : 0)
                    .map(y => <MenuItem key={y['Opp_Created_By']} value={y['Opp_Created_By']}>{y['Opp_Created_By']}</MenuItem>)
                  }                                </Select>
              </FormControl>
            </Grid>
            {this.props.company === 'C0001' &&
              <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                <FormControl fullWidth className={classes.formControl} >
                  <InputLabel className={classes.filterLabel} id="select-industry-label">Solution Category:</InputLabel>
                  <Select

                    value={this.state.Solution_Cat}
                    onChange={this.handleChange}
                    name='Solution_Cat'
                    multiple
                  >
                    <MenuItem value={'All'} >All</MenuItem>
                    {this.props.performance_by_industryFilters !== '' && this.props.performance_by_industryFilters
                      .filter(y => y.type === "Solution_Cat" && y["Solution_Cat"] !== '')
                      .sort((a, b) => a["Solution_Cat"] < b["Solution_Cat"] ? -1 : a["Solution_Cat"] > b["Solution_Cat"] ? 1 : 0)
                      .map(y => <MenuItem key={y['Solution_Cat']} value={y['Solution_Cat']}>{y['Solution_Cat']}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
        </Grid> */}
        <Grid item xs={12} id="grid-container">
          <Paper style={{ marginBottom: 5 }}>
            <Tabs
              value={this.state.tab}
              onChange={this.handleChangeTab}
              //centered
              indicatorColor="primary"
              //textColor="primary"
            >
              <Tab
                label={`BY ${this.props.Value_Label}`}
                {...a11yProps(0)}
                classes={{ selected: classes.selected }}
              />
              <Tab
                label="By Count"
                {...a11yProps(1)}
                classes={{ selected: classes.selected }}
              />
            </Tabs>
          </Paper>
          <TabPanel value={this.state.tab} index={0}>
            <div
              className="ag-theme-custom-react-pbi" /* style={{ width: this.state.containerWidth - 10 }} */
            >
              {this.props.performance_by_industryData !== "" &&
              this.props.persistentAlert.message !== "Updating data..." ? (
                <AgGridReact
                  onGridReady={this.gridReady}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  domLayout="autoHeight"
                  enableBrowserTooltips={true}
                  pinnedBottomRowData={this.state.pinnedBottomRowData}
                  onFirstDataRendered={this.onFirstDataRendered}
                  defaultColDef={this.state.defaultColDef}
                  colResizeDefault={"shift"}
                  suppressNoRowsOverlay={true}
                  autoHeight={true}
                ></AgGridReact>
              ) : (
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  raised={true}
                >
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="80%"
                    style={{ margin: "20px 0px" }}
                  />
                  <Divider style={{ width: "100%" }} />
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="80%"
                    height={280}
                    style={{ margin: "20px 0px" }}
                  />
                </Card>
              )}
            </div>
          </TabPanel>
          <TabPanel value={this.state.tab} index={1}>
            <div
              className="ag-theme-custom-react-pbi" /* style={{ width: this.state.containerWidth - 10 }} */
            >
              {this.props.performance_by_industryData !== "" &&
              this.props.persistentAlert.message !== "Updating data..." ? (
                <AgGridReact
                  onGridReady={this.gridReady}
                  columnDefs={this.state.columnDefs2}
                  rowData={this.state.rowData2}
                  domLayout="autoHeight"
                  enableBrowserTooltips={true}
                  pinnedBottomRowData={this.state.pinnedBottomRowData2}
                  onFirstDataRendered={this.onFirstDataRendered}
                  defaultColDef={this.state.defaultColDef}
                  colResizeDefault={"shift"}
                  suppressNoRowsOverlay={true}
                  autoHeight={true}
                ></AgGridReact>
              ) : (
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  raised={true}
                >
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="80%"
                    style={{ margin: "20px 0px" }}
                  />
                  <Divider style={{ width: "100%" }} />
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="80%"
                    height={280}
                    style={{ margin: "20px 0px" }}
                  />
                </Card>
              )}
            </div>
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    performance_by_industryFilters,
    performance_by_industryData,
    persistentAlert,
    pbiNew,
    user,
  } = state.app;

  return {
    performance_by_industryFilters,
    performance_by_industryData,
    persistentAlert,
    pbiNew,
    company: user.company,
    Value_Label: user.Value_Label,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPerformanceByIndustryData: (filters, solutionCategory) => {
    dispatch({ type: "get_performance_by_industry_data_request" });
    appService.getPerformanceByIndustryData(filters, solutionCategory).then(
      (json) => {
        dispatch({ type: "get_performance_by_industry_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_performance_by_industry_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_performance_by_industry_data_failure", error });
      }
    );
  },
  getPerformanceByIndustryFilters: (company) => {
    dispatch({ type: "get_performance_by_industry_filters_request" });
    appService.getPerformanceByIndustryFilters().then(
      (json) => {
        dispatch({ type: "get_performance_by_industry_filters_success", json });
        dispatch({ type: "get_performance_by_industry_data_request" });
        var filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        var solutionCategory =
          company !== "C0003"
            ? json.message
                .filter((f) => f.sub_type === "default_filters")
                .map((d) => d.Solution_Cat)
            : undefined;
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        appService.getPerformanceByIndustryData(filters, solutionCategory).then(
          (json) => {
            dispatch({
              type: "get_performance_by_industry_data_success",
              json,
            });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_performance_by_industry_data_failure",
                error: "Something went wrong",
              });
            else
              dispatch({
                type: "get_performance_by_industry_data_failure",
                error,
              });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_performance_by_industry_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_performance_by_industry_filters_failure",
            error,
          });
      }
    );
  },
});

const connectedPerformanceByIndustry = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PerformanceByIndustry));
export { connectedPerformanceByIndustry as PerformanceByIndustry };
