import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableContainer,
  Typography,
  Grid,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Link,
} from "@material-ui/core";
import {
  countOrACVFormatter,
  isNullOrZero,
  StyledTableRow,
  _isNaN,
} from "../../util/customFunctions";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedYieldPage from "./ExpectedYieldPage";
import MockupTable from "./Table";
import NQPTable from "./NQPTable";
import "./mockup.scss";
import * as d3 from "d3";
import { appService } from "../../App/app.service";
import ManagerSummarySection from "./ManagerSummaryDataSection";
import MovedOutSection from "./MovedOutSection";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  arrowDiv: {
    color: "#4D81BF",
    borderLeft: "20px solid",
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    display: "inline-block",
    position: "relative",
    background: "#4D81BF",
    lineHeight: "40px",
    height: "40px",
  },
  arrowSpan: {
    color: "white",
    fontWeight: "bold",
    paddingLeft: "10px",
    fontSize: "11px",

    top: 0,
    right: "-20px",
  },
});

class ManagerSummary extends React.Component {
  state = {
    location: "",
  };

  componentDidMount = () => {
    this.props.getManagerSummaryTeams();
  };

  componentWillUnmount() {
    this.props.clearManagerSummaryData();
  }

  handleChangeLocation = (location) => {
    this.setState({
      ...this.state,
      location: location,
    });
  };

  handleChangeLocationBack = (location, rep, drilldown) => {
    console.log("clicked");
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 10 }}>
            Manager Summary
          </Typography>
        </Grid> */}
        {addHeaderdescription("Manager Summary", "")}
        <Grid item container spacing={2} justify="space-around">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {this.props.managerSummaryCustomData !== "" ? (
                <ManagerSummarySection
                  data={this.props.managerSummaryCustomData.teams}
                  Value_Label={this.props.Value_Label}
                  currentAndPriorFiscalQuarters={
                    this.props.managerSummaryCustomData
                      .currentAndPriorFiscalQuarters
                  }
                  noOfGoalWeeks={
                    this.props.managerSummaryCustomData.noOfGoalWeeks
                  }
                />
              ) : (
                <Skeleton
                  variant="react"
                  animation="wave"
                  height={120}
                  width="100%"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1rem", padding: "1rem" }}>
          <Card className={clsx(classes.root)} raised={true}>
            <CardContent className={classes.content}>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              >
                <MovedOutSection />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { managerSummaryCustomData, user } = state.app;

  return {
    managerSummaryCustomData,
    Value_Label: user.Value_Label,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getManagerSummaryTeams: () => {
    dispatch({ type: `get_managerSummary_custom_data_request` });
    appService.getManagerSummaryCustomData().then(
      (json) => {
        dispatch({ type: `get_managerSummary_custom_data_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_managerSummary_custom_data_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  clearManagerSummaryData: () => {
    dispatch({ type: "clear_manager_summary_data" });
  },
});

const connectedBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManagerSummary));
export { connectedBar as ConnectedManagerSummary };
