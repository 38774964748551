import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React from "react";
import { nearestCompletedFiscalQuarter } from "../util/fiscalQuarterFunctions";

function YearMonthFilterCombo(props) {
  const isFilterActive = (value) =>
    Array.isArray(value) && value.length > 0 && !value.includes("All");

  const disableOtherFilters = () =>
    isFilterActive(props.closedFiscalQuarterState) ||
    isFilterActive(props.Closed_Year_Month) ||
    isFilterActive(props.closedFiscalYear);

  const shouldDisable = (filterValue) =>
    disableOtherFilters() && !isFilterActive(filterValue);

  return (
    <Grid
      item
      spacing={2}
      xs={12}
      container
      component={Paper}
      style={{ paddingTop: 8 }}
    >
      {/* Closed Fiscal Quarter Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={shouldDisable(props.closedFiscalQuarterState)}
          id="filter_cfq"
        >
          <InputLabel
            style={{
              color:
                props.activeTimeFilter !== "Closed_Year_Month"
                  ? "#4472c4"
                  : "#000",
            }}
            id="slect_cfq_label"
          >
            Closed Fiscal Quarter:
          </InputLabel>
          <Select
            labelId="select-vRenewal_Contract_Eff_Dt_Fiscal_Quarter-label"
            value={props.closedFiscalQuarterState}
            onChange={props.handleChange}
            label="closed_fiscal_quarter"
            name="closed_fiscal_quarter"
            data-test="closed-fiscal-quarter"
            multiple
            id={"select-closed_fiscal_quarter"}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                id: "filter_cfq_values",
              },
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "closed_fiscal_quarter" &&
                    y["closed_fiscal_quarter"] !== "" &&
                    y["closed_fiscal_quarter"] !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y["closed_fiscal_quarter"]}
                    value={y["closed_fiscal_quarter"]}
                  >
                    {y["closed_fiscal_quarter"]}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Closed Month Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl
          fullWidth
          disabled={shouldDisable(props.Closed_Year_Month)}
        >
          <InputLabel
            style={{
              color:
                props.activeTimeFilter === "Closed_Year_Month"
                  ? "#4472c4"
                  : "#000",
            }}
            id="select-vYearMonth-label"
          >
            Closed Month:
          </InputLabel>
          <Select
            labelId="select-vprojected_close_year_month-label"
            id="select-cClosed_Year_Month"
            value={props.Closed_Year_Month}
            onChange={props.handleChange}
            label="Closed_Year_Month"
            name="Closed_Year_Month"
            data-test="closed-year-month"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters === "" && (
              <MenuItem value={nearestCompletedFiscalQuarter()}>
                {nearestCompletedFiscalQuarter()}
              </MenuItem>
            )}
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "Closed_Year_Month" &&
                    y["Closed_Year_Month"] !== "" &&
                    y["Closed_Year_Month"] !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y["Closed_Year_Month"]}
                    value={y["Closed_Year_Month"]}
                  >
                    {y["Closed_Year_Month"]}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Closed Fiscal Year Filter */}
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <FormControl fullWidth disabled={shouldDisable(props.closedFiscalYear)}>
          <InputLabel
            style={{
              color:
                props.activeTimeFilter === "closed_fiscal_year"
                  ? "#4472c4"
                  : "#000",
            }}
            id="select-vfiscalYear-label"
          >
            Closed Fiscal Year:
          </InputLabel>
          <Select
            labelId="select-vprojected_close_fiscal_year-label"
            id="select-cclosed_fiscal_year"
            value={props.closedFiscalYear}
            onChange={props.handleChange}
            label="closed_fiscal_year"
            name="closed_fiscal_year"
            data-test="closed-fiscal-year"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {props.funnelCompareFilters === "" && (
              <MenuItem value={nearestCompletedFiscalQuarter()}>
                {nearestCompletedFiscalQuarter()}
              </MenuItem>
            )}
            {props.funnelCompareFilters !== "" &&
              props.funnelCompareFilters
                .filter(
                  (y) =>
                    y.type === "closed_fiscal_year" &&
                    y["closed_fiscal_year"] !== "" &&
                    y["closed_fiscal_year"] !== null
                )
                .map((y) => (
                  <MenuItem
                    key={y["closed_fiscal_year"]}
                    value={y["closed_fiscal_year"]}
                  >
                    {y["closed_fiscal_year"]}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default YearMonthFilterCombo;
