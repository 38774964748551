import { useInput } from "@mui/material";
import { buildDict } from "./data";

export const formattedString = (metricWanted, userInput) => {
  // call extract dashboards
  const dict = buildDict();
  const listOfDashboards = [];

  // console.log(words);
  // console.log("LIST OF DASHBOARDS ------");
  // console.log(listOfDashboards);

  // get the relevant dashboards
  // include the dashboard in the final string that's outputted
  // ACV is 400 in 2019. Dashboard 1

  // AARNAV: FOUND THE HOLY GRAIL THIS IS WHERE WE ADD THE DASHBOARD STUFF
  // okay, so we check if userInput['dashboards'] contains any matching ones, and if so,
  // return them
  // console.log("DEBUG: userInput: ", userInput);
  console.log(metricWanted, userInput, "metric_extraction");
  let ret = "";
  if ("rep" in userInput) {
    ret += `${userInput["rep"]["Full_Name"]}'s ${userInput["metric"]} in `;
  } else if ("team" in userInput && typeof userInput["team"] !== "boolean") {
    const namelist = userInput["team"].map((t) => t["Display_Name"]);
    ret += `${namelist.join(", ")} team's ${userInput["metric"]} in `;
  } else {
    ret += `${userInput["metric"]} for all reps and teams in `;
  }
  if ("cfq" in userInput) {
    ret += `${userInput["cfq"].join(", ")} `;
  } else if ("month" in userInput) {
    userInput["month"]["month"] = userInput["month"]["month"].map((x) =>
      x.length === 3
        ? new Date(x).toLocaleString("default", { month: "long" })
        : x
    );
    ret += `${userInput["month"]["month"].join(", ")} `;
  }
  if ("year" in userInput) {
    const strYears = userInput["year"].map((x) => x.toString());
    ret += `${strYears.join(", ")} `;
  }
  if (
    !("cfq" in userInput) &&
    !("month" in userInput) &&
    !("year" in userInput)
  ) {
    ret += "all quarters ";
  }
  if ("industry" in userInput) {
    ret += `from ${userInput["industry"].join(", ")} `;
    // ret += `from ${userInput['industry'].join(", ")} ${userInput['q_industry']} `;
  }
  if ("acvPreset" in userInput) {
    ret += `greater than $${userInput["acvPreset"].toLocaleString()} `;
  }

  if (listOfDashboards.length > 0) {
    const toBeAdded = `\n The following dashboards may be of interest to you data: \n`;
    ret += `${toBeAdded}${listOfDashboards.join(" ")}`;
  }

  // console.log("RET IS " + ret);
  return `${ret}is ${metricWanted}. `;
};
