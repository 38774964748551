import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
  dateFormatterMomentV2,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function RepCertificationTable2(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.1rem",
    },
  }))(Tooltip);

  // tooltip
  const rampUpTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #70AD47",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >{`Hire Date  +  ${props.rampDays} days`}</Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );

  const reps = props.data.map((s) => s.Rep).filter(getUniqueValues);
  //const toStages=props.StageProgressionData.tostages

  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );

  return (
    <TableContainer style={{ margin: "2rem", marginBottom: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <Table
        ref={tableRef}
        size="small"
        aria-label="html table"
        style={{ tableLayout: "auto" }}
        data-test={props.dataTest}
      >
        <TableHead></TableHead>
        <TableBody>
          <TableRow
            style={{
              fontWeight: 600,
              background: "#4472c4",
              color: "white",
              paddingLeft: 5,
              height: 60,
            }}
          >
            <TableCell
              align="center"
              style={{
                background: "#4472c4",
                border: "0.5px solid lightgrey",
                minWidth: 120,
              }}
              rowspan={2}
              className={classes.colHeader}
            >
              Hire Date
            </TableCell>
            <TableCell
              align="center"
              style={{
                background: "#4472c4",
                border: "0.5px solid lightgrey",
                minWidth: 140,
              }}
              rowspan={2}
              className={classes.colHeader}
            >
              Rep
            </TableCell>
            <TableCell
              align="center"
              style={{
                background: "#4472c4",
                border: "0.5px solid lightgrey",
                minWidth: 90,
              }}
              rowspan={2}
              className={classes.colHeader}
            >
              Tenure <br /> (Mos. Days)
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#4472c4", border: "0.5px solid lightgrey" }}
              colspan={3}
              className={classes.colHeader}
            >
              Certification Completion
            </TableCell>
            <HtmlTooltip title={rampUpTooltip}>
              <TableCell
                align="center"
                style={{
                  background: "#70AD47",
                  border: "0.5px solid lightgrey",
                }}
                rowspan={2}
                className={classes.colHeader}
              >
                Expected Ramp-up date
              </TableCell>
            </HtmlTooltip>
            <TableCell
              align="center"
              style={{ background: "#4472c4", border: "0.5px solid lightgrey" }}
              rowspan={2}
              className={classes.colHeader}
            >
              Time Remaining <br /> (Mos. Days)
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#4472c4", border: "0.5px solid lightgrey" }}
              rowspan={2}
              className={classes.colHeader}
            >
              Date of first Won from opps. assigned after Hire Dt.
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#4472c4", border: "0.5px solid lightgrey" }}
              rowspan={2}
              className={classes.colHeader}
            >
              Actual Ramp-up <br /> (Days)
            </TableCell>
            <TableCell
              align="center"
              style={{ background: "#4472c4", border: "0.5px solid lightgrey" }}
              rowspan={2}
              className={classes.colHeader}
            >
              Actual Ramp-up <br /> (Mos. Days)
            </TableCell>
          </TableRow>
          <TableRow
            style={{
              fontWeight: 600,
              background: "#4472c4",
              color: "white",
              paddingLeft: 5,
            }}
          >
            {Array(1)
              .fill()
              .map((stage, index) => (
                <>
                  <TableCell
                    align="center"
                    style={{
                      background: "#4472c4",
                      border: "0.5px solid lightgrey",
                    }}
                    className={classes.colHeader}
                  >
                    # of modules
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      background: "#4472c4",
                      border: "0.5px solid lightgrey",
                    }}
                    className={classes.colHeader}
                  >
                    Avg. Completion
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      background: "#4472c4",
                      border: "0.5px solid lightgrey",
                    }}
                    className={classes.colHeader}
                  >
                    Avg. Score
                  </TableCell>
                </>
              ))}
          </TableRow>
          {reps.map((rep, id) => {
            let repData = props.data.filter((s) => s.Rep === rep)[0];
            return (
              <StyledTableRow key={rep}>
                <TableCell
                  align="right"
                  style={{ border: "0.5px solid lightgrey" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.Hire_Date)
                    ? "-"
                    : dateFormatterMomentV2(repData.Hire_Date)}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    border: "0.5px solid lightgrey",
                    paddingRight: "5px",
                  }}
                  className={classes.datacell}
                >
                  {repData.showAsterisk ? ast(rep) : rep}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    border: "0.5px solid lightgrey",
                    paddingLeft: "2px",
                    paddingRight: "15px",
                  }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.tenure)
                    ? "-"
                    : repData.tenure}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnGraph",
                      repData.SFDC_Owner_ID,
                      rep,
                      repData.Hire_Date,
                      "Certification"
                    )
                  }
                  style={{ border: "0.5px solid lightgrey", cursor: "pointer" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.noOfModules)
                    ? "0"
                    : Math.round(repData.noOfModules)}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnGraph",
                      repData.SFDC_Owner_ID,
                      rep,
                      repData.Hire_Date,
                      "Certification"
                    )
                  }
                  style={{ border: "0.5px solid lightgrey", cursor: "pointer" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.avgCompletion)
                    ? "0"
                    : Math.round(repData.avgCompletion)}
                  %
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnGraph",
                      repData.SFDC_Owner_ID,
                      rep,
                      repData.Hire_Date,
                      "Certification"
                    )
                  }
                  style={{
                    border: "0.5px solid lightgrey",
                    cursor: "pointer",
                    background: repData.thresholdColor,
                    color: ["#70AD47", "#FF0000"].includes(
                      repData.thresholdColor
                    )
                      ? "white"
                      : "",
                  }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.avgScore)
                    ? "0"
                    : Math.round(repData.avgScore)}
                  %
                </TableCell>
                <TableCell
                  align="right"
                  style={{ border: "0.5px solid lightgrey" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.expectedRampUpDate)
                    ? "-"
                    : dateFormatterMomentV2(repData.expectedRampUpDate)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ border: "0.5px solid lightgrey" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.timeRemaining)
                    ? "-"
                    : repData.timeRemaining}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnGraph",
                      repData.SFDC_Owner_ID,
                      rep,
                      repData.Hire_Date,
                      "Won"
                    )
                  }
                  style={{ border: "0.5px solid lightgrey", cursor: "pointer" }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.firstWonDate)
                    ? "-"
                    : dateFormatterMomentV2(repData.firstWonDate)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    border: "0.5px solid lightgrey",
                    background: repData.bgColorForRampUpRow,
                    color: ["#70AD47", "#FF0000"].includes(
                      repData.bgColorForRampUpRow
                    )
                      ? "white"
                      : "",
                  }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.actualRampUpDays)
                    ? "-"
                    : repData.actualRampUpDays}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    border: "0.5px solid lightgrey",
                    background: repData.bgColorForRampUpRow,
                    color: ["#70AD47", "#FF0000"].includes(
                      repData.bgColorForRampUpRow
                    )
                      ? "white"
                      : "",
                  }}
                  className={classes.datacell}
                >
                  {["", undefined, null].includes(repData.actualRampUpMosDays)
                    ? "-"
                    : repData.actualRampUpMosDays}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
