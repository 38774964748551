import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  percentFormatter,
  countOrACVFormatterRoundToNearestN,
  countOrACVFormatterMillionNew,
  countOrACVFormatterMillion,
  countOrACVFormatterMillionFixedOne,
  dateFormatterMomentV2,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    fontWeight: 600,
    width: "130px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    width: "130px",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  fontArrow: {
    verticalAlign: "middle",
    height: "1.1rem",
    marginBottom: "0.2rem",
  },
}));
export default function DataTable(props) {
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  // const [parentWidth, setparentWidth] = useState();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-First");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  // useEffect(() => {
  //   // Get the width of the parent TableContainer
  //   const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
  //   setparentWidth(parentWidth);
  // }, [parentWidth]);

  const uniqueQuarters = [
    "Series",
    "Module",
    "# of Learners",
    "# of Modules",
    "Avg. Completion",
    "Avg, Score",
    "Start Date",
    "End Date",
  ];
  const classes = useStyle();

  const uniqueRows = props.data
    .map((f) => f.SeriesName)
    .filter(getUniqueValues);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CardContent
        className={classes.content}
        style={{ padding: 10, marginBottom: "1rem" }}
        align="center"
      >
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            className={classes.mainTable}
            id="myTableContainer-First"
            ref={tableContainerRef}
            style={{ width: "auto" }}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              data-test={"EG-Data-Table"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              ref={tableRef}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        <span>{i}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>

                {uniqueRows.map((row, i) => {
                  const rowData = props.data.filter(
                    (f) => f.SeriesName === row
                  );
                  let module = "";
                  let count = "-";
                  let avgComp = 0;
                  let avgScore = 0;
                  let startDate = "-";
                  let endDate = "-";
                  let learnerCount = "-";
                  let stDate = "";
                  let edDate = "";
                  if (rowData.length > 0) {
                    module = rowData[0].ModuleName;
                    count = [null, undefined, ""].includes(
                      rowData[0].noOfModules
                    )
                      ? "-"
                      : countOrACVFormatter(rowData[0].noOfModules);
                    learnerCount = [null, undefined, ""].includes(
                      rowData[0].noOfLearners
                    )
                      ? "-"
                      : countOrACVFormatter(rowData[0].noOfLearners);
                    avgComp = [null, undefined, ""].includes(
                      rowData[0].avgCompletion
                    )
                      ? "0%"
                      : `${Math.round(rowData[0].avgCompletion)}%`;
                    avgScore = [null, undefined, ""].includes(
                      rowData[0].avgScore
                    )
                      ? "0%"
                      : Math.round(rowData[0].avgScore);
                    startDate = [null, undefined, ""].includes(
                      rowData[0].startDate
                    )
                      ? "-"
                      : dateFormatterMomentV2(rowData[0].startDate);
                    endDate = [null, undefined, ""].includes(rowData[0].endDate)
                      ? "-"
                      : dateFormatterMomentV2(rowData[0].endDate);
                    stDate = [null, undefined, ""].includes(
                      rowData[0].startDate
                    )
                      ? "-"
                      : rowData[0].startDate.split("T")[0];
                    edDate = [null, undefined, ""].includes(rowData[0].endDate)
                      ? "-"
                      : rowData[0].endDate.split("T")[0];
                  }
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{
                          minWidth: "21rem",
                          maxWidth: "22rem",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {row}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{
                          minWidth: "21rem",
                          maxWidth: "22rem",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {module}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {learnerCount}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {count}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {avgComp}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {avgScore}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {startDate}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            row,
                            module,
                            stDate,
                            edDate
                          )
                        }
                      >
                        {endDate}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
