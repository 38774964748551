import {
    Box,
    Grid,
    Modal,
    CircularProgress,
    Skeleton,
    IconButton,
    Button,
  } from "@mui/material";
  import React from "react";
  
  import CloseIcon from "@material-ui/icons/Close";
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  
  const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "none",
    paddingTop: "40px"
  };
  
  const HelpTextContainer = ({ helpTextFile, handleClose, open }) => {
    const [loading, setLoading] = React.useState(true);
  
    const handleIframeLoad = () => setLoading(false);
    const handleIframeError = () => setLoading(false); // You can handle errors differently if needed
  
    return (
      <Grid container item justifyContent={"flex-end"}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton> */}
            <Button
        style={{ position: "absolute", top: 15, right: 15 }}
        variant="contained"
        color="primary"
        data-test="drilldownCloseButton"
        onClick={handleClose}
      >
        <CloseIcon />
      </Button>
            {loading && (
              <Grid
                item
                container
                style={{ margin: "1rem" }}
                justifyContent="center"
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={120}
                  width="90%"
                  style={{ margin: "1rem" }}
                />
              </Grid>
            )}
            <iframe
              src={`https://skygeniwebapp.blob.core.windows.net/help-templates/${helpTextFile}`}
              title="Help Content"
              style={iframeStyle}
              onLoad={handleIframeLoad}
              onError={handleIframeError}
              type="application/pdf"
            />
          </Box>
        </Modal>
      </Grid>
    );
  };
  
  export default HelpTextContainer;