import React, { useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { tooltipClasses } from "@mui/material/Tooltip"; // Import tooltipClasses

// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: "8rem",
//     fontSize: "1rem",
//     color: "black",
//     textAlign: "center",
//     background: "white",
//     border: "0.5px solid lightgrey",
//     // Set a fixed width to force text wrapping
//     // width: "150px", // Adjust the width as needed
//   },
// });

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

export default CustomWidthTooltip;
