import {
  Grid,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  capitalize,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function BenchmarkingTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  console.log(props.data);
  var teams = props.data
    .map((item) => item.rep.Team)
    .filter(getUniqueValues)
    .sort((a, b) => (a.team < b.team ? -1 : 1));
  const uniqueReps = props.data
    .map((r) => r.rep.Full_Name)
    .filter(getUniqueValues);
  console.log(teams, uniqueReps);

  useEffect(() => {
    const tableContainer = document.getElementById(
      `myTableContainer${props.titleLine2}`
    );

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);
  return (
    <Grid container item xs={12} component={Card}>
      <Grid item xs={12} style={{ padding: "1rem" }}>
        <Typography align="center" variant="h2">
          {props.title}
        </Typography>
        {props.titleLine2 && (
          <Typography
            variant="body1"
            style={{ fontWeight: 600 }}
            align="center"
          >
            {props.titleLine2}
          </Typography>
        )}
      </Grid>
      <Grid container item xs={12} justify="center" style={{ padding: "1rem" }}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.2rem",

              width: `${
                tableContainerRef.current.getBoundingClientRect().width
              }px`,
              // width: `${parentWidthwith}`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <TableContainer
          style={{ padding: "1rem" }}
          id={`myTableContainer${props.titleLine2}`}
          ref={tableContainerRef}
        >
          {/* {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null} */}
          <Table ref={tableRef} data-test={props.title}>
            <TableHead></TableHead>
            <TableBody>
              {props.benchmarkConversionRates !== undefined
                ? props.benchmarkConversionRates
                : null}
              <TableRow className={classes.fontBold}>
                {props.titleColumns.map((item) => (
                  <TableCell
                    colSpan={
                      item.label !== "" ? props.primaryColumns.length : 1
                    }
                    align="center"
                    className={classes.colHeaderNew}
                    style={{ background: item.background, color: item.color }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow
                className={`${classes.colHeaderNew} ${classes.fontBold} ${classes.primaryColumnColor}`}
              >
                {props.primaryColumns.map((item) => (
                  <TableCell
                    style={{ width: "5%" }}
                    align="center"
                    className={classes.colHeaderNew}
                  >
                    {props.isConversionTable
                      ? `${item.from} to ${item.to}`
                      : item}
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  style={{ width: "20%" }}
                  className={classes.colHeaderNew}
                >
                  Rep
                </TableCell>
                {props.primaryColumns.map((item) => (
                  <TableCell
                    style={{ width: "5%" }}
                    align="center"
                    className={classes.colHeaderNew}
                  >
                    {props.isConversionTable
                      ? `${item.from} to ${item.to}`
                      : item}
                  </TableCell>
                ))}
              </TableRow>
              {teams.map((team) => {
                const teamData = props.data.filter(
                  (it) => it.rep.Team === team
                );
                const reps = teamData
                  .map((item) => item.rep.Full_Name)
                  .sort()
                  .filter(getUniqueValues);
                return (
                  <>
                    {team !== "total" && (
                      <StyledTableRow
                        className={classes.fontBold}
                        style={{ background: "#8ea9db", color: "white" }}
                      >
                        {props.primaryColumns.map((stage) => {
                          var teamTotal =
                            props.teamData !== undefined
                              ? props.teamData.filter(
                                  (it) =>
                                    it.Team.team === team &&
                                    it.stage ===
                                      `${
                                        props.isConversionTable
                                          ? stage.from
                                          : stage
                                      }`
                                )[0]
                              : null;

                          return (
                            <TableCell
                              align="right"
                              onClick={
                                props.isDrilldown === true
                                  ? () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        props.isConversionTable === true
                                          ? [stage.from]
                                          : [stage],
                                        [stage.to],
                                        [team],
                                        true
                                      )
                                  : ""
                              }
                              className={`${classes.colHeaderNew} ${
                                props.isDrilldown === true
                                  ? classes.drilldownCell
                                  : ""
                              }`}
                            >
                              {[null, undefined].includes(teamTotal)
                                ? ""
                                : props.isConversionTable
                                ? `${Math.round(teamTotal.count * 100)}%`
                                : countOrACVFormatter(teamTotal.count, false)}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="left"
                          className={classes.colHeaderNew}
                          id="rep"
                        >{`${team}`}</TableCell>
                        {props.primaryColumns.map((stage) => {
                          var teamTotal =
                            props.teamData !== undefined
                              ? props.teamData.filter(
                                  (it) =>
                                    it.Team.team === team &&
                                    it.stage ===
                                      `${
                                        props.isConversionTable
                                          ? stage.from
                                          : stage
                                      }`
                                )[0]
                              : null;
                          return (
                            <TableCell
                              align="right"
                              onClick={
                                props.isDrilldown === true
                                  ? () =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        props.isConversionTable === true
                                          ? [stage.from]
                                          : [stage],
                                        [stage.to],
                                        [team],
                                        true
                                      )
                                  : ""
                              }
                              className={`${classes.colHeaderNew} ${
                                props.isDrilldown === true
                                  ? classes.drilldownCell
                                  : ""
                              }`}
                            >
                              {[null, undefined].includes(teamTotal)
                                ? ""
                                : props.isConversionTable
                                ? `${Math.round(teamTotal.acv * 100)}%`
                                : countOrACVFormatter(teamTotal.acv, true)}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    )}
                    {reps.map((repName) => {
                      const repData = teamData.filter(
                        (t) => t.rep.Full_Name === repName
                      );
                      return (
                        <StyledTableRow
                          className={
                            repName === "total" ? classes.fontBold : ""
                          }
                        >
                          {props.primaryColumns.map((stage) => {
                            var repInfo = repData.filter((rep) => {
                              return (
                                rep.stage ===
                                `${
                                  props.isConversionTable ? stage.from : stage
                                }`
                              );
                            })[0];
                            repInfo =
                              repInfo === undefined
                                ? { count: 0, acv: 0 }
                                : repInfo;
                            return (
                              <TableCell
                                align="right"
                                onClick={
                                  props.isDrilldown === true
                                    ? () =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          props.isConversionTable === true
                                            ? [stage.from]
                                            : [stage],
                                          [stage.to],
                                          repName === "total"
                                            ? uniqueReps
                                            : [repName],
                                          false
                                        )
                                    : ""
                                }
                                style={
                                  props.highlightOnThreshold
                                    ? props.benchmarkingCondition(
                                        Math.round(repInfo.count * 100)
                                      )
                                    : {}
                                }
                                className={
                                  props.isDrilldown === true
                                    ? classes.drilldownCell
                                    : classes.dataCell
                                }
                              >
                                {props.isConversionTable
                                  ? repInfo.count === ""
                                    ? ""
                                    : `${Math.round(repInfo.count * 100)}%`
                                  : countOrACVFormatter(repInfo.count, false)}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            align="left"
                            className={classes.colHeaderNew}
                            id="rep"
                          >
                            {capitalize(repName)}
                          </TableCell>
                          {props.primaryColumns.map((stage) => {
                            var repInfo = repData.filter((rep) => {
                              return (
                                rep.stage ===
                                `${
                                  props.isConversionTable ? stage.from : stage
                                }`
                              );
                            })[0];
                            repInfo =
                              repInfo === undefined
                                ? { count: 0, acv: 0 }
                                : repInfo;
                            return (
                              <TableCell
                                onClick={
                                  props.isDrilldown === true
                                    ? () =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          props.isConversionTable === true
                                            ? [stage.from]
                                            : [stage],
                                          [stage.to],
                                          repName === "total"
                                            ? uniqueReps
                                            : [repName],
                                          false
                                        )
                                    : ""
                                }
                                align="right"
                                style={
                                  props.highlightOnThreshold
                                    ? props.benchmarkingCondition(
                                        Math.round(repInfo.acv * 100)
                                      )
                                    : {}
                                }
                                className={
                                  props.isDrilldown === true
                                    ? classes.drilldownCell
                                    : classes.dataCell
                                }
                              >
                                {props.isConversionTable
                                  ? repInfo.count === ""
                                    ? ""
                                    : `${Math.round(repInfo.acv * 100)}%`
                                  : countOrACVFormatter(repInfo.acv, true)}
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default BenchmarkingTable;