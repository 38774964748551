import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow, _isNaN } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import NoDataCell from './NoDataCell'
import { coloredCells } from '../utils'
import { renewalsRow } from '../../RenewalsCompleted/components/utils'
import { isThisMinute } from 'date-fns'
export default function CurrentStatusTable(props) {
    const classes = tableStyle()

    let rows = props.rows !== undefined ? props.rows : props.data.map(item => item[props.row]).filter(getUniqueValues)
    let columns = props.columns !== undefined ? props.columns : props.data.map(item => item[props.col]).filter(getUniqueValues)
    columns = props.weekOverWeekTrends ? ["Churned", 'Red', 'Yellow', 'Orange', 'Green', 'Green +', null] : columns
    rows = props.chartName === "accountHealthCurrentStatus" || props.chartName === "accountHealthChangeOverTime" ? ["Red", "Yellow", "Orange", "Green", "Green +", "Churned"] : rows
    if (props.chartName === "accountHealthCurrentStatus") {
        const missingCols = columns.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; });
        columns = [...renewalsRow, ...missingCols]
    }

    if (props.chartName === "QBRSummary") {
        const missingRows = rows.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; });
        rows = [...renewalsRow, ...missingRows]
    }
    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            {
                                props.statusByPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell style={{ background: 'white' }}></TableCell>


                                    <TableCell align="center" colSpan={columns.length} style={{ backgroundColor: '#bf8f00' }} className={classes.colHeader}>Status Prior Week</TableCell>
                                </TableRow> : null
                            }
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Account Segment</TableCell>

                                {columns.map((item, index) => <TableCell style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} colSpan={2} align="center" className={classes.colHeader}>{item}</TableCell>)}
                                <TableCell colSpan={2} align="center" className={classes.colHeader}>Total</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Health</TableCell>

                                {columns.map(item => <><TableCell align="center" className={classes.colHeader}>{props.type === "SFDC" ? "# of Accounts" : props.type}</TableCell><TableCell align="center" className={classes.colHeader}>{`% of Total`}</TableCell></>)}
                                <><TableCell align="center" className={classes.colHeader}>{props.type === "SFDC" ? "# of Accounts" : props.type}</TableCell><TableCell align="center" className={classes.colHeader}>% of Total</TableCell></>
                            </TableRow>
                            {
                                rows.map(row =>
                                    <StyledTableRow>
                                        <TableCell align="left" className={classes.dataCell} style={{ backgroundColor: coloredCells[row], color: 'black' }}>{row}</TableCell>
                                        {columns.map(col => {
                                            const cellData = props.data.filter(item => item[props.col] === col && item[props.row] === row)[0] !== undefined ? props.data.filter(item => item[props.col] === col && item[props.row] === row)[0][props.type] : "-"
                                            const totalData = props.data.filter(item => item[props.col] === col).map(item => item[props.type]).reduce((a, b) => a + b, 0)

                                            return <><TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: [col], string: true }, { name: 'Overall_Health', value: [row], string: true }])} className={classes.drilldownCell}>{cellData === "-" ? cellData : countOrACVFormatter(cellData, props.type === "CARR")}</TableCell><TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: [col], string: true }, { name: 'Overall_Health', value: [row], string: true }])} className={classes.drilldownCell}>{_isNaN(Math.round((cellData / totalData) * 100)) ? "0%" : `${Math.round((cellData / totalData) * 100)}%`}</TableCell></>
                                        })}
                                        {
                                            <>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: 'Overall_Health', value: [row], string: true }, { name: "Account_Segment", value: columns, string: true }])} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>
                                                <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: 'Overall_Health', value: [row], string: true }, { name: "Account_Segment", value: columns, string: true }])} className={classes.drilldownCell}>{_isNaN(Math.round((props.data.filter(item => item[props.row] === row).map(item => item[props.type]).reduce((a, b) => a + b, 0) / props.data.map(item => item[props.type]).reduce((a, b) => a + b, 0)) * 100)) ? "0%" : `${Math.round((props.data.filter(item => item[props.row] === row).map(item => item[props.type]).reduce((a, b) => a + b, 0) / props.data.map(item => item[props.type]).reduce((a, b) => a + b, 0)) * 100)}%`}</TableCell>
                                            </>
                                        }
                                    </StyledTableRow>)
                            }
                            <StyledTableRow key={'total'}>
                                <TableCell align="left" className={classes.dataCell}>Total</TableCell>
                                {
                                    columns.map(col => <>
                                        <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: [col], string: true }, { name: 'Overall_Health', value: rows, string: true }])} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.col] === col).map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>
                                        <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: [col], string: true }, { name: 'Overall_Health', value: rows, string: true }])} className={classes.drilldownCell}>100%</TableCell></>)
                                }
                                <>
                                    <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: columns, string: true }, { name: 'Overall_Health', value: rows, string: true }])} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>
                                    <TableCell align="right" onClick={() => props.handleChangeLocation("drilldownOnGraph", [{ name: "Account_Segment", value: columns, string: true }, { name: 'Overall_Health', value: rows, string: true }])} className={classes.drilldownCell}>100%</TableCell>
                                </>
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
