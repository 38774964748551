import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import D3ErrorBoundary from "../../components/D3ErrorComponent.js";
import d3SankeyChartBuilder from "../SankeyPoc/D3SankeyChart";

const D3SankeyContainer = (props) => {
  const [isChartDrawn, setIsChartDrawn] = useState(false);
  useEffect(() => {
    const svgContainer = document.getElementById("svgContainer");
    let data =
      props.toggleValue === "count"
        ? props.tableData.sankeyFormattedData
        : props.tableData.sankeyFormattedDataACV;

    data = data.map((it) => ({
      ...it,
      value: it[props.toggleValue === "count" ? "value" : "acv"],
    }));
    data = data.map((it) => ({
      ...it,
      source: `source|${it.source}`,
      target: `target|${it.target}`,
    }));
    const options = {
      label: props.toggleValue === "count" ? "# of Opps" : "ACV",
      isAcv: props.toggleValue === "count" ? "" : "$",
    };
    // console.log(data,'data')
    // console.log(data.map(it=>({...it,value:it.acv})))
    const result = d3SankeyChartBuilder.create(data, svgContainer, options);
    setIsChartDrawn(result);
    return () => {
      const el = document.getElementById("svgContainer");
      d3SankeyChartBuilder.remove(el);
    };
  }, [props.tableData, props.toggleValue, isChartDrawn]);
  console.log(isChartDrawn);
  return (
    <>
      {isChartDrawn && (
        <D3ErrorBoundary>
          <Card raised>
            <CardHeader
              title={
                <Grid container item xs={12}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography align="center" variant="h2">
                      {`Projected Close Date Moves (Net Moves)`}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ padding: "1rem", height: "2rem" }}>
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={props.toggleValue}
                      exclusive
                      size="small"
                      onChange={props.handleChangeToggle}
                    >
                      <ToggleButton
                        style={{
                          background:
                            props.toggleValue === "count" ? "#4472C4" : "",
                          color: props.toggleValue === "count" ? "white" : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="count"
                      >
                        #
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          background:
                            props.toggleValue === "acv" ? "#4472C4" : "",
                          color: props.toggleValue === "acv" ? "white" : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="acv"
                      >
                        $
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              }
            ></CardHeader>

            <CardContent style={{ display: "flex", justifyContent: "center" }}>
              {!isChartDrawn && (
                <Typography align="center" variant="h2">
                  Chart Not Loaded
                </Typography>
              )}
              <div
                style={{ display: "flex", justifyContent: "center" }}
                id={"svgContainer"}
              ></div>
            </CardContent>
          </Card>
        </D3ErrorBoundary>
      )}
      {!isChartDrawn && (
        <D3ErrorBoundary>
          <Card raised>
            <CardHeader
              title={
                <Grid container item xs={12}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography align="center" variant="h2">
                      {`Projected Close Date Moves (Net Moves)`}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ padding: "1rem", height: "2rem" }}>
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={props.toggleValue}
                      exclusive
                      size="small"
                      onChange={props.handleChangeToggle}
                    >
                      <ToggleButton
                        style={{
                          background:
                            props.toggleValue === "count" ? "#4472C4" : "",
                          color: props.toggleValue === "count" ? "white" : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="count"
                      >
                        #
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          background:
                            props.toggleValue === "acv" ? "#4472C4" : "",
                          color: props.toggleValue === "acv" ? "white" : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="acv"
                      >
                        $
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              }
            ></CardHeader>

            <CardContent style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                id={"svgContainer"}
              ></div>
            </CardContent>
          </Card>
        </D3ErrorBoundary>
      )}
    </>
  );
};

export default D3SankeyContainer;