import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  customDateFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import isThisQuarter from "date-fns/esm/isThisQuarter/index";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import { boolean } from "mathjs/lib/cjs/entry/pureFunctionsAny.generated";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      // outline: "0.5px solid lightgrey",
      // outlineOffset: "-1.5px",
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        background: "lightgrey", // Adjust the color as needed
      },
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function DataTable(props) {
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isSticky, setIsSticky] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [globalExpandArrow, setglobalExpandArrow] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [parentWidth, setparentWidth] = useState();
  const [rowPin, setrowPin] = useState(false);

  let globalExpand = [];

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  let countinc = 0;

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  const uniqueTimeFrames = [...props.timeFrames, "Weekly Avg."].filter(
    getUniqueValues
  );
  const uniqueTeams = [
    ...props.teamRep.map((f) => f.Team),
    "Grand Total",
  ].filter(getUniqueValues);
  const classes = tableStyle();

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  return (
    <div
      style={{ maxWidth: "100%", justifyContent: "center", padding: "1rem" }}
    >
      <Grid xs={12}>
        {/* {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null} */}
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${
                tableContainerRef.current.getBoundingClientRect().width
              }px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        className={classes.mainTable}
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
          borderTop: rowPin ? "0.5px solid lightgrey" : "",
          maxHeight: "85vh",
        }}
        id="myTableContainer-Second"
        ref={tableContainerRef}
      >
        {/* {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null} */}
        <Table ref={tableRef} data-test={props.dataTest}>
          <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
            <TableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : `${classes.colHeader}`
                }
                style={{ minWidth: 150, background: "white" }}
              >
                {"Week Ending"}
              </TableCell>
              <TableCell
                align="center"
                className={classes.colHeader}
                style={{ background: "white" }}
              >
                {""}
              </TableCell>
              {uniqueTimeFrames.map((col, index) => (
                <TableCell
                  colSpan={3}
                  style={
                    index % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          minWidth: "7rem",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          minWidth: "7rem",
                        }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {col !== "Weekly Avg." ? customDateFormatter(col) : col}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              style={{ position: "sticky", outline: "lightgrey solid 0.5px" }}
            >
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : `${classes.colHeader}`
                }
                style={{ background: "white" }}
              >
                {/* Team / AE */}
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "2.5px" }}>Team / AE</div>

                  <CustomWidthTooltip
                    title={"Expand All"}
                    placement="top-start"
                    style={{ marginTop: "13rem" }}
                  >
                    <KeyboardArrowDownIcon
                      onClick={() => globalExpandData(true)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                  <CustomWidthTooltip
                    title={"Collapse All"}
                    placement="top-start"
                  >
                    <KeyboardArrowUpIcon
                      onClick={() => globalExpandData(false)}
                      style={{ color: "gray" }}
                    />
                  </CustomWidthTooltip>
                </div>
              </TableCell>
              <TableCell
                align="center"
                className={classes.colHeader}
                style={{ background: "white" }}
              >
                {"Goal"}
              </TableCell>
              {uniqueTimeFrames.map((col) =>
                ["Opp", "Not Opp", "Tot."].map((item) => (
                  <TableCell
                    align="center"
                    style={{ background: "white" }}
                    className={classes.colHeader}
                  >
                    {item}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueTeams.map((team, i) => {
              globalExpand.push(i);
              // console.log("Global_data", globalExpand);
              countinc = countinc + 1; //For team background colour
              const teamData = props.data.filter((item) => item.Team === team);
              const team2Data = props.dataNotLinnked.filter(
                (item) => item.Team === team
              );
              const team3Data = props.dataTotal.filter(
                (item) => item.Team === team
              );
              const teamGoal =
                team === "Grand Total"
                  ? props.teamRep.map((g) => g.Goal).reduce((a, b) => a + b, 0)
                  : props.teamRep
                      .filter((f) => f.Team === team)
                      .map((g) => g.Goal)
                      .reduce((a, b) => a + b, 0);
              const repData = props.data.filter((item) => item.Team === team);
              const repDataNotLinked = props.dataNotLinnked.filter(
                (item) => item.Team === team
              );
              const repDataTotal = props.dataTotal.filter(
                (item) => item.Team === team
              );
              let reps = props.teamRep
                .filter((item) => item.Team === team)
                .map((item) => item.Rep)
                .filter(getUniqueValues)
                .sort();
              reps = reps.filter((f) => f !== "Grand Total");
              const drilldownTeam =
                team === "Grand Total"
                  ? uniqueTeams.filter((f) => f !== "Grand Total")
                  : team;
              const drilldownReps =
                team === "Grand Total"
                  ? props.teamRep
                      .filter((f) => f.Rep !== "Grand Total")
                      .map((f) => f.Rep)
                      .filter(getUniqueValues)
                  : reps;

              return (
                <>
                  {" "}
                  <StyledTableRow
                    key={i}
                    className={classes.fontBold}
                    style={{ background: "#dae3f3" }}
                  >
                    <TableCell
                      // className={classes.colHeader}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.colHeader}`
                          : `${classes.colHeader}`
                      }
                      style={{ background: "#dae3f3" }}
                      onClick={() => handleExpandClick(i)}
                    >
                      {/* {team} */}
                      <div style={{ display: "flex" }}>
                        {team !== "Grand Total" ? (
                          expandedRows.includes(i) ? (
                            <KeyboardArrowUpIcon style={{ color: "gray" }} />
                          ) : (
                            <KeyboardArrowDownIcon style={{ color: "gray" }} />
                          )
                        ) : null}
                        <div style={{ marginTop: "3px" }}>{team}</div>
                      </div>
                    </TableCell>
                    <TableCell align="right" className={classes.colHeader}>
                      {teamGoal}
                    </TableCell>
                    {uniqueTimeFrames.map((timeFrame) => {
                      const tempData = teamData.filter(
                        (f) => f.Week_Ending === timeFrame
                      );
                      const temp2Data = team2Data.filter(
                        (f) => f.Week_Ending === timeFrame
                      );
                      const temp3Data = team3Data.filter(
                        (f) => f.Week_Ending === timeFrame
                      );
                      // console.log(tempData, 'Temp')
                      const count =
                        tempData.length > 0
                          ? tempData.reduce((a, b) => a + b.count, 0)
                          : null;
                      const count2 =
                        temp2Data.length > 0
                          ? temp2Data.reduce((a, b) => a + b.count, 0)
                          : null;
                      const count3 =
                        temp3Data.length > 0
                          ? temp3Data.reduce((a, b) => a + b.count, 0)
                          : null;
                      const drilldownnTimeFrame =
                        timeFrame === "Weekly Avg."
                          ? uniqueTimeFrames.filter((f) => f !== "Weekly Avg.")
                          : timeFrame;
                      return (
                        <>
                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                drilldownnTimeFrame,
                                "Y",
                                drilldownReps,
                                drilldownTeam
                              )
                            }
                          >
                            {[null, undefined, ""].includes(count)
                              ? "-"
                              : timeFrame === "Weekly Avg."
                              ? count.toFixed(1)
                              : countOrACVFormatter(count, false)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                drilldownnTimeFrame,
                                "N",
                                drilldownReps,
                                drilldownTeam
                              )
                            }
                          >
                            {[null, undefined, ""].includes(count2)
                              ? "-"
                              : timeFrame === "Weekly Avg."
                              ? count2.toFixed(1)
                              : countOrACVFormatter(count2, false)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                drilldownnTimeFrame,
                                undefined,
                                drilldownReps,
                                drilldownTeam
                              )
                            }
                            style={
                              count3 >= teamGoal && timeFrame !== "Grand Total"
                                ? {
                                    backgroundColor: "#e2efda",
                                    cursor: "pointer",
                                  }
                                : { cursor: "pointer" }
                            }
                          >
                            {[null, undefined, ""].includes(count3)
                              ? "-"
                              : timeFrame === "Weekly Avg."
                              ? count3.toFixed(1)
                              : countOrACVFormatter(count3, false)}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                  {expandedRows.includes(i) &&
                    reps.map((rep, i) => {
                      countinc = countinc + 1;

                      const repsData = repData.filter(
                        (item) => item.Rep === rep
                      );

                      const repsDataNotLinked = repDataNotLinked.filter(
                        (item) => item.Rep === rep
                      );

                      const repsDataTotal = repDataTotal.filter(
                        (item) => item.Rep === rep
                      );
                      const repGoal = props.teamRep
                        .filter((f) => f.Rep === rep)
                        .map((g) => g.Goal)
                        .reduce((a, b) => a + b, 0);

                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.dataCell} ${classes.indentName}`
                                : `${classes.dataCell} ${classes.indentName}`
                            }
                            style={
                              isSticky && countinc % 2 === 1
                                ? {
                                    background: "#f5f5f5",
                                    paddingLeft: "45px",
                                    whiteSpace: "nowrap",
                                  }
                                : { paddingLeft: "45px", whiteSpace: "nowrap" }
                            }
                          >
                            {rep}
                          </TableCell>
                          <TableCell align="right" className={classes.dataCell}>
                            {repGoal}
                          </TableCell>
                          {uniqueTimeFrames.map((timeFrame) => {
                            const tempData = repsData.filter(
                              (f) => f.Week_Ending === timeFrame
                            );
                            // console.log(tempData, 'Temp')
                            const count =
                              tempData.length > 0
                                ? tempData.reduce((a, b) => a + b.count, 0)
                                : null;
                            const temp2Data = repsDataNotLinked.filter(
                              (f) => f.Week_Ending === timeFrame
                            );
                            // console.log(tempData, 'Temp')
                            const count2 =
                              temp2Data.length > 0
                                ? temp2Data.reduce((a, b) => a + b.count, 0)
                                : null;
                            const temp3Data = repsDataTotal.filter(
                              (f) => f.Week_Ending === timeFrame
                            );
                            // console.log(tempData, 'Temp')
                            const count3 =
                              temp3Data.length > 0
                                ? temp3Data.reduce((a, b) => a + b.count, 0)
                                : null;
                            const drilldownnTimeFrame =
                              timeFrame === "Weekly Avg."
                                ? uniqueTimeFrames.filter(
                                    (f) => f !== "Weekly Avg."
                                  )
                                : timeFrame;
                            return (
                              <>
                                <TableCell
                                  align="right"
                                  className={classes.dataCell}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      drilldownnTimeFrame,
                                      "Y",
                                      rep,
                                      drilldownTeam
                                    )
                                  }
                                >
                                  {[null, undefined, ""].includes(count)
                                    ? "-"
                                    : timeFrame === "Weekly Avg."
                                    ? count.toFixed(1)
                                    : countOrACVFormatter(count, false)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.dataCell}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      drilldownnTimeFrame,
                                      "N",
                                      rep,
                                      drilldownTeam
                                    )
                                  }
                                >
                                  {[null, undefined, ""].includes(count2)
                                    ? "-"
                                    : timeFrame === "Weekly Avg."
                                    ? count2.toFixed(1)
                                    : countOrACVFormatter(count2, false)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.dataCell}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      drilldownnTimeFrame,
                                      undefined,
                                      rep,
                                      drilldownTeam
                                    )
                                  }
                                  style={
                                    count3 >= repGoal
                                      ? {
                                          backgroundColor: "#e2efda",
                                          cursor: "pointer",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                >
                                  {[null, undefined, ""].includes(count3)
                                    ? "-"
                                    : timeFrame === "Weekly Avg."
                                    ? count3.toFixed(1)
                                    : countOrACVFormatter(count3, false)}
                                </TableCell>
                              </>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DataTable;
