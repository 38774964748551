import { Table, TableContainer } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CopyTablesObject from "../xlsx-export/CopyTablesObject";

export const CopyToClipboardTableContainer = (props) => {
  // console.log("propsdata", props);
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  // console.log("React_Children", props);
  const [parentWidth, setparentWidth] = useState();

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);
  return (
    <>
      {parentWidth !== undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "0.6rem",
            // marginRight: "1rem",
            width: `${parentWidth}px`,
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <TableContainer
        ref={tableContainerRef}
        style={{
          padding: "0.7px 0.5rem 0rem 0.5rem",
          borderTop: "2px",
          maxHeight: "85vh",
        }}
      >
        <Table ref={tableRef} data-test={props.datatest}>
          {props.children}
        </Table>
      </TableContainer>
    </>
  );
};
