import React from "react";

function Logo(props) {
  return (
    <div
      style={{
        background: `url(https://skygeniwebapp.blob.core.windows.net/logos/SkyGeni.png)`,
        width: props.width,
        height: props.height,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    />
  );
}

export default Logo;
