import { Grid,TableContainer,Table,TableBody,TableCell,TableRow,withStyles,Tooltip,Typography } from '@material-ui/core'
import React from 'react'
import { StyledTableRow, _isNaN,getUniqueValues } from '../../util/customFunctions'
import PrimaryColorTableHeader from '../ScoreCard/ExpectedAcv/PrimaryColorTableHeader'
import Legend from '../AsOfDate/Legend'

import { makeStyles } from "@material-ui/core";

const tableStyle = makeStyles(theme => ({
    root: {
        margin: '0.5rem',
        padding: '0.5rem'
    },
    colHeaderNew: {
        fontSize: '0.85rem',
        border: '0.5px solid lightgrey',
        padding: '7px 10px',
        color: 'inherit',
        width: 120,
        fontWeight: 'inherit',
        backgroundColor: 'inherit'
    },
    colHeader: {
        fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120, fontWeight: 600
    },
    dataCell: {
        fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
        fontWeight: 'inherit',
    },
    drilldownCell: {
        fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
        fontWeight: 'inherit',
        cursor: 'pointer'
    },
    drilldownCellNQP: {
        fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: '3%',
        fontWeight: 'inherit',
        cursor: 'pointer',
    },
    cellMargin: {
        fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
        fontWeight: 'inherit',
        paddingLeft: '2rem'
    },
    fontBold: {
        fontWeight: 600
    },
    indentName: {
        paddingLeft: 30
    },
    noBorder: {
        border: 'none'
    },
    primaryColumnColor: {
        backgroundColor: '#4472C4', color: 'white'
    },
    secondaryColumnColor: {
        backgroundColor: '#70ad47', color: 'white'
    },
    tertiaryColumnColor: {
        backgroundColor: '#bf8f00', color: 'white'
    }

}), {index: 1})

const ExpectedAcvCategory = (props) => {
  const classes = tableStyle()
  const data=[{"dimension":"Rep Win Rate","value":"Reidar Alcock","score":16,"isLimitingFactor":false,"daysFlag":"","category":"Time-Agnostic","sequence":1},{"dimension":"Industry Win Rate","value":"Manufacturing","score":21,"isLimitingFactor":false,"daysFlag":"","category":"Time-Agnostic","sequence":2},{"dimension":"Days at Stage","value":205,"score":8,"isLimitingFactor":false,"daysFlag":"days","category":"Momentum","sequence":1},{"dimension":"Days since Qualified","value":205,"score":8,"isLimitingFactor":false,"daysFlag":"days","category":"Momentum","sequence":2},{"dimension":"Days till end of proj. qtr.","value":29,"score":38,"isLimitingFactor":false,"daysFlag":"days","category":"Time Left","sequence":1},{"dimension":"Time-Agnostic","score":18,"isLimitingFactor":false,"daysFlag":"","category":null,"sequence":1},{"dimension":"Momentum","score":8,"isLimitingFactor":true,"daysFlag":"","category":null,"sequence":2},{"dimension":"Time Left","score":38,"isLimitingFactor":false,"daysFlag":"days","category":null,"sequence":3},{"dimension":"Solution Category","value":"Cloud Compress","score":20,"isLimitingFactor":false,"daysFlag":"","category":"Time-Agnostic","sequence":3},{"dimension":"Times Proj. Close Dt. moved across qtrs. at Stage","value":1,"score":11,"isLimitingFactor":false,"daysFlag":"","category":"Momentum","sequence":3}]
  const uniqueCategories=data.filter(i=>i.category == null).sort((a, b) => a.sequence < b.sequence ? -1 : 1)
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
      <TableContainer>
      <Table>
        <TableBody>
          <TableRow className={`${classes.primaryColumnColor} ${classes.fontBold}`}>
            <PrimaryColorTableHeader title='Category' width="15%" />
            <PrimaryColorTableHeader title='Dimension' />
            <PrimaryColorTableHeader title='Value'  width="20%"/>
            <PrimaryColorTableHeader title='Probability Sub-Score' width="10%"/>
            <PrimaryColorTableHeader title='Probability Score' width="10%"/>
           </TableRow>

           {uniqueCategories.map((cell, j) => {
               const dimensions=data.filter(i=>i.category === cell.dimension && i.value !== undefined && i.score !== undefined).sort((a, b) => a.sequence < b.sequence ? -1 : 1)
                return (
                    <React.Fragment key={j}>
                        <StyledTableRow key={j} style={{background:cell.isLimitingFactor ? '#fbe5d6ff' : ''}}>
                        <TableCell className={classes.dataCell} rowSpan={dimensions.length} align="center"  >{cell.dimension}</TableCell>
                        <TableCell className={classes.dataCell}  align="left"  >{dimensions[0].dimension}</TableCell> 
                        <TableCell className={classes.dataCell}  align={_isNaN(dimensions[0].value) ? 'left' : 'right'}  >{`${dimensions[0].value} ${dimensions[0].daysFlag}`}</TableCell>
                        <TableCell className={classes.dataCell}  align={_isNaN(dimensions[0].score) ? 'left' : 'right'}  >{`${_isNaN(dimensions[0].score) ? dimensions[0].score : Math.round(dimensions[0].score) }`}</TableCell>
                        <TableCell className={classes.dataCell} rowSpan={dimensions.length}  align="right">{cell.score !== undefined ? Math.round(cell.score) : ''}</TableCell>
                        </StyledTableRow>
                        {
             dimensions.length > 1 && dimensions.slice(1).map(s=><StyledTableRow key={j} style={{background:cell.isLimitingFactor ? '#fbe5d6ff' : ''}}>
                   <TableCell className={classes.dataCell}  align="left"  >{s.dimension}</TableCell>
                   <TableCell className={classes.dataCell}  align={_isNaN(s.value) ? 'left' : 'right'}  >{`${s.value} ${s.daysFlag}`}</TableCell>
                   <TableCell className={classes.dataCell}  align={_isNaN(s.score) ? 'left' : 'right'}  >{`${_isNaN(s.score) ? s.score : Math.round(s.score) }`}</TableCell>
             </StyledTableRow>)
         }
                    </React.Fragment>
            )
        })}
        </TableBody>
      </Table>
    </TableContainer> 
      </Grid>
    <Grid item xs={12} container justify="flex-start">
    <Legend square={15} just="flex-start" type="AsOfDate" legends={[{ text:`Limiting Category` , color: '#fbe5d6ff' }]} />
    </Grid>
    </Grid>
  )
}

export default ExpectedAcvCategory