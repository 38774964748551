import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import {
  getCSVFileNameString,
  csvDateFormatter,
  dateFormatterv2,
  dateFormatterv3,
} from "../../util/customFunctions";
import GetApp from "@material-ui/icons/GetApp";
import "./Drilldown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";
import { replaceDynamicValueLabel } from "../../util/utils";
import Tooltip from "@material-ui/core/Tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(255,255,255,0.8)",
    color: "black",
    fontFamily: "roboto",
    fontSize: theme.typography.pxToRem(14),
    borderRadius: "1rem",
    padding: "0px",
    boxShadow: "0 0.1rem 0.1rem 0",
    marginTop: "0.4rem",
  },
}))(Tooltip);

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
  datacell: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "5px",
    paddingRight: "0px",
    border: "none",
  },
  headerCell: {
    border: "0.5px solid lightgrey",
    fontWeight: 600,
  },
  tableSpacer: {
    margin: "1rem",
    marginTop: "0rem",
  },
}));

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const currencyFormatter = (params) => {
  if (params.value === "Outlier Opp.") return "Outlier Opp.";
  if (_isNaN(params.value)) return 0;
  return `$ ${d3.format(",")(Math.round(params.value))}`;
};
const dateAndTimeFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(params.value));
};
const dateFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  }).format(new Date(params.value));
};

const newOppDisplayValue = {
  new: "New",
  new_resurrected: "Resurrected",
  new_resurrected_won_to_lost: "Resurrected Won to Lost",
  new_resurrected_won_to_won: "Resurrected Won to Won",
  new_resurrected_lost_to_lost: "Resurrected Lost to Lost",
  new_resurrected_lost_to_won: "Resurrected Lost to Won",
};
const newOwpFormatter = (params) => {
  return newOppDisplayValue[params.value];
};

const percFormatter = (params) => {
  return `${params.value}%`;
};

const percFormatterv2 = (params) => {
  return params.value === "Outlier Opp."
    ? "Outlier Opp."
    : [null, undefined, ""].includes(params.value)
    ? "0%"
    : `${Math.round(params.value * 100)}%`;
};

const compareOultiers = (data1, data2) => {
  const arr = data1.map((item) => {
    const item2 = data2.find((i2) => i2.OppName === item.OppName);
    // console.log(item2, item)
    return item2
      ? { ...item, ...item2 }
      : {
          ...item,
          ML_Expected_ACV: "Outlier Opp.",
          ML_Final_Probability_Score: "Outlier Opp.",
        };
  });

  return arr;
};

const DrilldownSLD = (props) => {
  console.log("DrilldownSLD", props);
  const classes = useStyle();

  //projected yield toottip
  const projectedYieldTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >{`SkyGeni Projected Yield from opps. in ${props.header}`}</Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );

  //projected attainment tooltip
  const projectedAttainmentTooltip = (
    <>
      {Array(1)
        .fill()
        .map((item) => (
          <>
            <div
              style={{
                borderTop: "solid #4471C4",
                borderRadius: "0.3rem",
                padding: "0px",
                margin: 0,
                borderWidth: "0.3rem",
              }}
            >
              <div className="container">
                <Typography
                  style={{ padding: "0.1rem" }}
                  className="heading-style"
                >{`Won + SkyGeni Projected Yield from opps. in ${props.header}`}</Typography>
              </div>
            </div>
          </>
        ))}
    </>
  );

  let acvLabel = props.acvName ? `${props.acvName}:` : "ACV";
  if (props.Value_Label) {
    acvLabel = replaceDynamicValueLabel(acvLabel, props.Value_Label);
  }

  const containerHeight = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";

  const convertedData =
    props.body &&
    (props.toggleValue === true
      ? compareOultiers(
          props.body.opportunities,
          props.body.message.opportunities[3]
        )
      : props.body.opportunities);

  // props.toggleValue === true ?  <Typography variant="body1" style={{ fontWeight: '600' }}>Number of opportunities: <span data-test='count'>{d3.format(",")(props.body.count_acv[0].count)}</span>
  //                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: $ <span data-test='acv'>{d3.format(",")(Math.round(props.body.count_acv[0].acv).toString())}</span>
  //                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exc. ACV: $ <span data-test='acv'>{d3.format(",")(Math.round([null, undefined, ''].includes(props.body.message.opportunities[2][0].acv) ? 0 : props.body.message.opportunities[2][0].acv).toString())}</span>
  //                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Expected Yield &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Low: $ <span data-test='low'>{d3.format(",")(Math.round(props.body.message.expectedYield.low).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mid: $ <span data-test='mid'>{d3.format(",")(Math.round(props.body.message.expectedYield.mid).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; High: $ <span data-test='high'>{d3.format(",")(Math.round(props.body.message.expectedYield.high).toString())}</span>
  //                      </Typography> :

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    filter: true,
    cellClass: classes.tableCell,
    headerClass: classes.tableHeader,

    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };
  const onCellClicked = (e) =>
    props.handleSecondLevelDrilldown("drilldownOppId", e.data.Opp_ID);

  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        // if(cellParams && rowIndex === "projected_close_date" ) {
        //     return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        //   } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name" || rowIndex === "Owner_Name") {
        //     return `"${cellParams.value}"`; //apply your string formatter
        //   } else if(cellParams && rowIndex === "ACV" || rowIndex === "acv"){
        //     return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
        //   }
        const dateTypes = props.body.columns
          .filter((f) => f.type === "date")
          .map((f) => f.field);
        const dateTimeTypes = props.body.columns
          .filter((f) => f.type === "datetime")
          .map((f) => f.field);
        const stringTypes = props.body.columns
          .filter((f) => f.type === "string")
          .map((f) => f.field);
        const integerTypes = props.body.columns
          .filter((f) => f.type === "integer")
          .map((f) => f.field);
        const currencyTypes = props.body.columns
          .filter((f) => f.type === "currency")
          .map((f) => f.field);
        // console.log(dateTypes,dateTimeTypes,stringTypes,integerTypes,currencyTypes, 'BODY123')
        if (cellParams && dateTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv2(cellParams.value) : null; //apply your timestamp formatter
        } else if (cellParams && stringTypes.includes(rowIndex)) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && dateTimeTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv3(cellParams.value) : null; //apply your timestamp formatter
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid
      container
      className={classes.root}
      data-test={props.type ? `drilldown-${props.type}` : "drilldown"}
    >
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={11}
              justify="flex-start"
              alignItems="flex-end"
            >
              {props.showLowMidHigh ? (
                props.pipeline ? (
                  <>
                    <Grid item container xs={10}>                   
                      <Grid
                        item
                        xs={3}
                        className={classes.tableSpacer}
                        style={{ marginLeft: "0rem" }}
                      >
                        <TableContainer
                          className={classes.mainTable}
                          style={{ width: "95%" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto", width: "100%" }}
                            data-test="pipeloine-projection-detail-table"
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={2}
                                  style={
                                    2 % 2 === 0
                                      ? {
                                          background: "#4471c4",
                                          color: "white",
                                        }
                                      : {
                                          background: "#5b9bd5",
                                          color: "white",
                                        }
                                  }
                                >
                                  Qualified Pipeline
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  # of Opps
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  {/* ACV */}
                                  {`${acvLabel}`}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="count"
                                >
                                  {d3.format(",")(
                                    props.body.count_acv[0].count
                                  )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="acv"
                                >
                                  {d3.format(",")(
                                    Math.round(
                                      props.body.count_acv[0].acv
                                    ).toString()
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs={4} className={classes.tableSpacer}>
                    
                        <TableContainer
                          className={classes.mainTable}
                          style={{ width: "95%" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto", width: "100%" }}
                            data-test="pipeloine-projection-detail-table"
                          >
                            <TableBody>
                              <TableRow>
                                <HtmlTooltip title={projectedYieldTooltip}>
                                  <TableCell
                                    align="center"
                                    className={classes.headerCell}
                                    colSpan={3}
                                    style={
                                      2 % 2 === 0
                                        ? {
                                            background: "#4471c4",
                                            color: "white",
                                          }
                                        : {
                                            background: "#5b9bd5",
                                            color: "white",
                                          }
                                    }
                                  >
                                    Projected Yield
                                  </TableCell>
                                </HtmlTooltip>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  Low
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  Mid
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  High
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="low"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.body.message.expectedYield.low
                                  ).toString()
                                )}`}</TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="mid"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.body.message.expectedYield.mid
                                  ).toString()
                                )}`}</TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="high"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.body.message.expectedYield.high
                                  ).toString()
                                )}`}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs={4} className={classes.tableSpacer}>
                        <TableContainer
                          className={classes.mainTable}
                          style={{ width: "95%" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto", width: "100%" }}
                            data-test="pipeloine-projection-detail-table"
                          >
                            <TableBody>
                              <TableRow>
                                <HtmlTooltip title={projectedAttainmentTooltip}>
                                  <TableCell
                                    align="center"
                                    className={classes.headerCell}
                                    colSpan={3}
                                    style={
                                      2 % 2 === 0
                                        ? {
                                            background: "#4471c4",
                                            color: "white",
                                          }
                                        : {
                                            background: "#5b9bd5",
                                            color: "white",
                                          }
                                    }
                                  >
                                    Projected Attainment
                                  </TableCell>
                                </HtmlTooltip>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  Low
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  Mid
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.headerCell}
                                  colSpan={1}
                                >
                                  High
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="projected-low"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.pipeline.projectedAttainmentLow
                                  ).toString()
                                )}`}</TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="projected-mid"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.pipeline.projectedAttainmentMid
                                  ).toString()
                                )}`}</TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.headerCell}
                                  colSpan={1}
                                  data-test="projected-high"
                                >{`$${d3.format(",")(
                                  Math.round(
                                    props.pipeline.projectedAttainmentHigh
                                  ).toString()
                                )}`}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>

                    {/* <Typography variant="body1" style={{ fontWeight: '600' }}>Number of opportunities: <span data-test='count'>{d3.format(",")(props.body.count_acv[0].count)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: $ <span data-test='acv'>{d3.format(",")(Math.round(props.body.count_acv[0].acv).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Expected Yield &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Low: $ <span data-test='low'>{d3.format(",")(Math.round(props.body.message.expectedYield.low).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mid: $ <span data-test='mid'>{d3.format(",")(Math.round(props.body.message.expectedYield.mid).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; High: $ <span data-test='high'>{d3.format(",")(Math.round(props.body.message.expectedYield.high).toString())}</span>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proj. Attain. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Low: $ <span data-test='proj-low'>{d3.format(",")(Math.round(props.pipeline.projectedAttainmentLow).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mid: $ <span data-test='mid'>{d3.format(",")(Math.round(props.pipeline.projectedAttainmentMid).toString())}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; High: $ <span data-test='high'>{d3.format(",")(Math.round(props.pipeline.projectedAttainmentHigh).toString())}</span>
                         </Typography>            */}
                  </>
                ) : (
                  <Typography variant="body1" style={{ fontWeight: "600" }}>
                    Number of opportunities:{" "}
                    <span data-test="count">
                      {d3.format(",")(props.body.count_acv[0].count)}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {props.body.count_acv[0].Total_Quantity !== undefined
                    ? `Seats: `
                    : ""}{" "}
                  {props.body.count_acv[0].Total_Quantity !== undefined ? (
                    <span data-test="seats">
                     {d3.format(",")(props.body.count_acv[0].Total_Quantity)}
                    </span>
                  ) : (
                    ""
                  )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {`${acvLabel}: $`}{" "}
                    <span data-test="acv">
                      {d3.format(",")(
                        Math.round(props.body.count_acv[0].acv).toString()
                      )}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Expected Yield &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Low: ${" "}
                    <span data-test="low">
                      {d3.format(",")(
                        Math.round(
                          props.body.message.expectedYield.low
                        ).toString()
                      )}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mid: ${" "}
                    <span data-test="mid">
                      {d3.format(",")(
                        Math.round(
                          props.body.message.expectedYield.mid
                        ).toString()
                      )}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; High: ${" "}
                    <span data-test="high">
                      {d3.format(",")(
                        Math.round(
                          props.body.message.expectedYield.high
                        ).toString()
                      )}
                    </span>
                  </Typography>
                )
              ) : (
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  Number of opportunities:{" "}
                  <span data-test="count">
                    {d3.format(",")(props.body.count_acv[0].count)}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {props.body.count_acv[0].Total_Quantity !== undefined
                    ? `Seats: `
                    : ""}{" "}
                  {props.body.count_acv[0].Total_Quantity !== undefined ? (
                    <span data-test="seats">
                     {d3.format(",")(props.body.count_acv[0].Total_Quantity)}
                    </span>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {`${acvLabel}: $`}{" "}
                  <span data-test="acv">
                    {d3.format(",")(
                      Math.round(props.body.count_acv[0].acv).toString()
                    )}
                  </span>
                </Typography>
              )}
            </Grid>
            <Grid
              direction="column"
              item
              container
              xs={1}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                            color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
            data-test={
              props.type ? `drilldown-${props.type}-table` : "drilldown-table"
            }
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  sortable: false,
                  filter: false,
                  cellClass: classes.tableCell,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    direction: "rtl",
                    "padding-right": "3px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                ...props.body.columns.map((c) => {
                  return {
                    headerName: c.label,
                    field: c.field,
                    filter:
                      c.type === "currency" ? "agNumberColumnFilter" : true,
                    sortingOrder:
                      c.type === "currency" ? ["desc", "asc"] : ["asc", "desc"],
                    valueFormatter:
                      (c.type === "currency" && currencyFormatter) ||
                      (c.label === "Date & Time" && dateAndTimeFormatter) ||
                      (c.label === "Projected Close Date" && dateFormatter) ||
                      (c.label === "Proj. Close Dt." && dateFormatter) ||
                      (c.label === "Created Date" && dateFormatter) ||
                      (c.label === "Closed Date" && dateFormatter) ||
                      (c.field === "createEventType" && newOwpFormatter) ||
                      (c.field === "ML_Final_Probability_Score" &&
                        percFormatterv2),
                    type:
                      c.type === "currency" ||
                      c.type === "integer" ||
                      c.type === "date"
                        ? "rightAligned"
                        : "leftAligned",
                    cellStyle:
                      c.type === "currency" || c.type === "integer" || c.type === "date"
                        ? ![undefined, false].includes(props.showSLD)
                          ? { "padding-right": "5px", cursor: "pointer" }
                          : { "padding-right": "5px" }
                        : ![undefined, false].includes(props.showSLD)
                        ? { "padding-left": "5px", cursor: "pointer" }
                        : { "padding-left": "5px" },
                    headerClass: classes.tableHeader,
                    filterParams:
                      c.type === "currency"
                        ? { defaultOption: "greaterThanOrEqual" }
                        : {},
                    flex: c.flex,
                  };
                }),
              ]}
              sizeColumnsToFit={true}
              headerHeight="55"
              rowData={convertedData}
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={props.showSLD ? onCellClicked : null}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              domLayout={domLayout}
              onColumnResized={
                convertedData.length <= 10 ? onColumnResized : null
              }
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              debounceVerticalScrollbar={true}
              onGridReady={gridReady}
            ></AgGridReact>
          </Grid>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default DrilldownSLD;
