import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom';

const BalancedScoreCardComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
        await  localStorage.setItem('skygeni-user-page', 'Rep Performance')
        await  localStorage.setItem('skygeni-user-repPage', "Performance Scorecard")
        await  localStorage.removeItem('skygeni-user-enhancedPage')
      await localStorage.setItem('params', props.match.params.tab)
      history.push('/')
      window.location.reload()
    })();
  }, [])

  return (
    <div style={{ margin: "1rem auto" }}>Loading...</div>)
}
let routerAddedBalancedScoreCard = withRouter(BalancedScoreCardComponent)
export default routerAddedBalancedScoreCard
