import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(1),
    margin: "1rem 0.5rem",
    width: "fit-content",
    maxWidth: "98%",
    border: "0.1px solid #979797",
    // borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backdropFilter: "blur(0px)",
    filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
  },
  userMessage: {
    backgroundColor: "#DAF3D8", // Light green for user messages
    alignSelf: "flex-end",
    borderRadius: "10px 0px 10px 10px",
  },
  contactMessage: {
    backgroundColor: "#FFFFFF", // White for contact messages
    alignSelf: "flex-start",
    borderRadius: "0px 10px 10px 10px",
  },
  underline: {
    textDecoration: "underline",
  },
}));
const MessageTemplate = ({ userStages, currentFiscalQuarter }) => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.message} ${classes.contactMessage}`}>
      <Grid item>
        <Typography style={{ fontWeight: 400 }} variant="body1">
          Hello there! I can answer questions like:
          <ul style={{ paddingLeft: "20px", margin: "0" }}>
            <li>
              What is &lt;rep&gt;'s win rate from {userStages[0].stage_name}{" "}
              <span className={classes.underline}>by ACV</span> this quarter?
            </li>
            <li>
              What is the &lt;team&gt;{" "}
              <span className={classes.underline}>team's</span> conversion rate
              from {userStages[1].stage_name} to {userStages[2].stage_name}{" "}
              <span className={classes.underline}>by count</span> in{" "}
              {currentFiscalQuarter}?
            </li>
            <li>How much pipeline was moved out this quarter?</li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MessageTemplate;
